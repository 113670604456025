import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// ngrx
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Store
import * as fromDocuments from 'app/portal/modules/document-management/store';

// Components
import { ConfirmationRequiredDialogComponent } from 'app/shared/components/confirmation-required-dialog/confirmation-required-dialog.component';
import { TemplateDialogComponent } from 'app/portal/modules/document-management/dialogs/template-dialog/template-dialog.component';
import { getColumnNumber, onPageResize } from 'app/shared/components/grid-container/grid-container.component';
import { BaseComponent } from 'app/shared/base/base-component';
import { PageComponent } from 'app/connect/components/page/page.component';

// Models
import { FilterConfiguration } from 'app/models/filter/filter-configuration.model';
import { Filter } from 'app/models/filter/filter.model';
import { Template } from 'app/portal/modules/document-management/models';
import { TemplateDialogData } from 'app/portal/modules/document-management/models/template-dialog-data.model';
import { Breadcrumb } from 'app/models/breadcrumb.model';

// Enums
import { ActiveInactiveOptions, getActiveInactiveOptionsLabel, getTemplateSortingLabel, TemplateSorting, DocumentType } from 'app/portal/modules/document-management/enums';
import { getYeNoOptionsLabel, YesNoOptions, Modules } from 'app/shared/enums';
import { SignatureType } from 'app/portal/modules/document-management/enums/signature-type.enum';
import { DocumentTemplateType } from 'app/portal/modules/document-management/enums/document-template-type.enum';

// Services
import { FileSaverService } from 'ngx-filesaver';
import { TemplateService } from 'app/portal/modules/document-management/services';

@Component({
    selector: 'app-template-index',
    templateUrl: './template-index.component.html',
    styleUrls: ['./template-index.component.scss']
})

export class TemplateIndexComponent extends BaseComponent implements OnInit {
    @ViewChild('page', { static: true }) page: PageComponent;
    loading$: Observable<boolean>;
    allDocuments: Array<Template>;
    activeDocuments: Array<Template>;
    inactiveDocuments: Array<Template>;
    exampleDocument: Template;
    configuration: FilterConfiguration;
    columnNum: number;
    Modules = Modules;

    DocumentTemplateType = DocumentTemplateType;

    private requiresSigningOption: string = 'Requires Signing';
    private statusOption: string = 'Status';
    private sortOption: string = 'Sort';

    constructor(
        private store: Store<fromDocuments.DocumentsState>,
        private templateService: TemplateService,
        private dialog: MatDialog,
        private fileSaverService: FileSaverService) {
        super();
    }

    ngOnInit(): void {
        this.store.pipe(
            select(fromDocuments.getDocuments),
            takeUntil(this.ngUnsubscribe))
            .subscribe(documents => {
                if (documents) {
                    this.allDocuments = documents;
                    this.activeDocuments = documents.filter(d => d.enabled);
                    this.inactiveDocuments = documents.filter(d => !d.enabled);
                }
            });

        this.loading$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromDocuments.getLoading));

        this.getAll();
        this.initBreadCrumb();
        this.createFilter();

        this.columnNum = getColumnNumber();

        this.exampleDocument = this.getExampleDocument();
        this.page.setTitleFromModule(Modules.CustomisationCentre);
        this.page.setFilterConfiguration(this.configuration);
    }

    getExampleDocument(): Template {
        this.exampleDocument = new Template();

        this.exampleDocument.id = '1';
        this.exampleDocument.name = 'Example Document';
        this.exampleDocument.revision = 1;
        this.exampleDocument.signatureType = SignatureType.NoSigning;
        this.exampleDocument.dateCreated = new Date();
        this.exampleDocument.dateUpdated = new Date();
        this.exampleDocument.enabled = true;
        this.exampleDocument.canDelete = false;
        this.exampleDocument.text = 'Example Document Description';
        this.exampleDocument.documentType = DocumentType.GeneralPolicy;
        this.exampleDocument.isOwner = true;

        return this.exampleDocument;
    }

    onResize() {
        this.columnNum = onPageResize();
    }

    search(currentFilter: Filter): void {
        if (this.hasData()) {
            const requiresSigningTypeId = currentFilter.getFilterOptionId(this.requiresSigningOption);
            const requiresSigningType = requiresSigningTypeId === null || requiresSigningTypeId === undefined ? null : parseInt(requiresSigningTypeId, 10);

            const statusOptionId = currentFilter.getFilterOptionId(this.statusOption);
            const statusOption = statusOptionId === null || statusOptionId === undefined ? null : parseInt(statusOptionId, 10);

            const sortOrderId = currentFilter.getFilterOptionId(this.sortOption);
            const sortOrder = sortOrderId === null || sortOrderId === undefined ? TemplateSorting.Name : parseInt(sortOrderId, 10);

            const searchText = !currentFilter.searchText ? '' : currentFilter.searchText.toLocaleLowerCase();

            this.doSearch(searchText, requiresSigningType, sortOrder, statusOption);
        }
    }

    getAll(): void {
        this.store.dispatch(new fromDocuments.GetDocuments());
    }

    hasData(): boolean {
        return this.allDocuments && this.allDocuments.length > 0;
    }

    hasActiveDocuments(): boolean {
        return this.activeDocuments && this.activeDocuments.length > 0;
    }

    hasInactiveDocuments(): boolean {
        return this.inactiveDocuments && this.inactiveDocuments.length > 0;
    }

    addTemplateClick(templateType: DocumentTemplateType): void {
        const newTemplate = new Template();
        this.openTemplateDialog(newTemplate, templateType);
    }

    editTemplateClick(template: Template): void {
        this.openTemplateDialog(template, template.documentTemplateType);
    }

    openTemplateDialog(template: Template, documentTemplateType: DocumentTemplateType): void {
        const templateDialog = this.dialog.open(TemplateDialogComponent, {
            data: new TemplateDialogData(template, documentTemplateType)
        });

        templateDialog.afterClosed().subscribe((refreshData: boolean) => {
            if (refreshData) {
                this.getAll();
            }
        });
    }

    downloadTemplateClick(template: Template): void {
        this.templateService.getPreview(template.id, null)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((response: Blob) => {
                const filename = `${template.name} (rev ${template.revision}).pdf`;
                this.fileSaverService.save(response, filename);
            });
    }

    deleteTemplateClick(template: Template): void {
        const confirmDialog = this.dialog.open(ConfirmationRequiredDialogComponent, {
            data: {
                confirmationMessage: `Are you sure you want to delete the template '${template.name}'?`
            },
            disableClose: true
        });

        confirmDialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed === true) {
                this.store.dispatch(new fromDocuments.DeleteDocument(template.id));
            }
        });
    }

    protected initBreadCrumb(): void {
        const breadcrumbs: Array<Breadcrumb> = [
            new Breadcrumb(Modules.CustomisationCentre),
            new Breadcrumb(Modules.ConnectPortalTemplateLibrary)
        ];

        this.page.setBreadcrumbs(breadcrumbs);
    }

    private createFilter(): void {
        if (!this.configuration) {

            this.configuration = new FilterConfiguration('Search by name');

            const requiresSigningOption = this.configuration.addOption(this.requiresSigningOption);
            requiresSigningOption.addValue(YesNoOptions.Yes.toString(), getYeNoOptionsLabel(YesNoOptions.Yes));
            requiresSigningOption.addValue(YesNoOptions.No.toString(), getYeNoOptionsLabel(YesNoOptions.No));

            const statusOption = this.configuration.addOption(this.statusOption);
            statusOption.addValue(ActiveInactiveOptions.Active.toString(), getActiveInactiveOptionsLabel(ActiveInactiveOptions.Active));
            statusOption.addValue(ActiveInactiveOptions.InActive.toString(), getActiveInactiveOptionsLabel(ActiveInactiveOptions.InActive));

            const sortOption = this.configuration.addOption(this.sortOption);
            sortOption.addValue(TemplateSorting.Name.toString(), getTemplateSortingLabel(TemplateSorting.Name));
            sortOption.addValue(TemplateSorting.DateCreatedAsc.toString(), getTemplateSortingLabel(TemplateSorting.DateCreatedAsc));
            sortOption.addValue(TemplateSorting.DateCreatedDesc.toString(), getTemplateSortingLabel(TemplateSorting.DateCreatedDesc));
            sortOption.addValue(TemplateSorting.DateUpdatedAsc.toString(), getTemplateSortingLabel(TemplateSorting.DateUpdatedAsc));
            sortOption.addValue(TemplateSorting.DateUpdatedDesc.toString(), getTemplateSortingLabel(TemplateSorting.DateUpdatedDesc));
        }
    }

    private doSearch(searchText: string, requiresSigningType: YesNoOptions, sortOrder: TemplateSorting, statusOption: ActiveInactiveOptions): void {

        let filteredDocuments: Array<Template> = this.allDocuments;

        filteredDocuments = filteredDocuments.filter(o => o.name.toLocaleLowerCase().indexOf(searchText) > -1);

        filteredDocuments = filteredDocuments.filter(o => {
            switch (requiresSigningType) {
                case YesNoOptions.Yes:
                    return o.signatureType !== SignatureType.NoSigning;
                case YesNoOptions.No:
                    return o.signatureType === SignatureType.NoSigning;
                default:
                    return true;
            }
        });

        filteredDocuments.sort((a, b) => {
            switch (sortOrder) {
                case TemplateSorting.Name:
                    return a.name.localeCompare(b.name);

                case TemplateSorting.DateCreatedAsc:
                    return a.dateCreated > b.dateCreated ? 1 : -1;

                case TemplateSorting.DateCreatedDesc:
                    return b.dateCreated > a.dateCreated ? 1 : -1;

                case TemplateSorting.DateUpdatedAsc:
                    return a.dateUpdated > b.dateUpdated ? 1 : -1;

                case TemplateSorting.DateUpdatedDesc:
                    return b.dateUpdated > a.dateUpdated ? 1 : -1;
            }
        });

        if (statusOption) {
            switch (statusOption) {
                case ActiveInactiveOptions.Active:
                    this.inactiveDocuments = null;
                    this.activeDocuments = filteredDocuments.filter(d => d.enabled);
                    break;

                case ActiveInactiveOptions.InActive:
                    this.activeDocuments = null;
                    this.inactiveDocuments = filteredDocuments.filter(d => !d.enabled);
                    break;
            }
        } else {
            this.activeDocuments = filteredDocuments.filter(d => d.enabled);
            this.inactiveDocuments = filteredDocuments.filter(d => !d.enabled);
        }
    }
}
