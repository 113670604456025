<div *ngIf="badges" class="badges-container" fxLayout="column" fxLayoutGap="20px">
    <app-card-container>
        <mat-grid-list cols="{{columnNum}}" rowHeight="300px" (window:resize)="onResize($event)" gutterSize="10px">
            <mat-grid-tile *ngFor="let badge of badges | orderBy: ['title']">
                <app-badge-card
                    class="cards"
                    [badge]="badge"
                    (enabledChanged)="handleBadgeEnabledChanged(badge, $event)"
                    [iconSource]="badge.isPublic ? 'assets/logos/credas-logo.png' : iconSource"
                    [isLocked]="isLocked(badge)">
                </app-badge-card>
            </mat-grid-tile>
        </mat-grid-list>
    </app-card-container>
</div>