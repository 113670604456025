// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Store
import { EffectsModule } from '@ngrx/effects';
import { effects } from './store';

// Modules
import { SharedModule } from 'app/shared/shared.module';

// Components
import { ZohoExportButtonComponent } from 'app/shared/modules/zoho-export/components/zoho-export-button/zoho-export-button.component';
import { ZohoExportDialogComponent } from 'app/shared/modules/zoho-export/components/zoho-export-dialog/zoho-export-dialog.component';

// Services
import { ZohoExportApiService } from './services/zoho-export-api.service';
import { ZohoExportService } from './services/zoho-export.service';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        EffectsModule.forFeature(effects)
    ],
    declarations: [
        ZohoExportButtonComponent,
        ZohoExportDialogComponent
    ],
    exports: [
        ZohoExportButtonComponent
    ],
    providers: [
        ZohoExportService,
        ZohoExportApiService
    ],
    entryComponents: [
        ZohoExportDialogComponent
    ]
})

export class ZohoExportModule { }