// ngrx
import { Action } from '@ngrx/store';

// Models
import { OrganisationTitle } from 'app/shared/models/organisation-title.model';

// Action types
export const GET_ENTITIES_TO_SHARE_WITH = '[Group List Sharing] Get entities to share with';
export const GET_ENTITIES_TO_SHARE_WITH_SUCCESS = '[Group List Sharing] Get entities to share with success';
export const GET_ENTITIES_TO_SHARE_WITH_FAIL = '[Group List Sharing] Get entities to share with fail';

export const GET_CLIENTS_TO_SHARE_WITH = '[Group List Sharing] Get clients to share with';
export const GET_CLIENTS_TO_SHARE_WITH_SUCCESS = '[Group List Sharing] Get clients to share with success';
export const GET_CLIENTS_TO_SHARE_WITH_FAIL = '[Group List Sharing] Get clients to share with fail';

// Action initialisers
export class GetEntitiesToShareWith implements Action {
    readonly type = GET_ENTITIES_TO_SHARE_WITH;
    constructor(public payload: boolean) { }
}

export class GetEntitiesToShareWithSuccess implements Action {
    readonly type = GET_ENTITIES_TO_SHARE_WITH_SUCCESS;
    constructor(public payload: OrganisationTitle[]) { }
}

export class GetEntitiesToShareWithFail implements Action {
    readonly type = GET_ENTITIES_TO_SHARE_WITH_FAIL;
}

export class GetClientsToShareWith implements Action {
    readonly type = GET_CLIENTS_TO_SHARE_WITH;
    constructor(public payload: boolean) { }
}

export class GetClientsToShareWithSuccess implements Action {
    readonly type = GET_CLIENTS_TO_SHARE_WITH_SUCCESS;
    constructor(public payload: OrganisationTitle[]) { }
}

export class GetClientsToShareWithFail implements Action {
    readonly type = GET_CLIENTS_TO_SHARE_WITH_FAIL;
}

export type Actions
    = GetEntitiesToShareWith
    | GetEntitiesToShareWithSuccess
    | GetEntitiesToShareWithFail
    | GetClientsToShareWith
    | GetClientsToShareWithSuccess
    | GetClientsToShareWithFail;