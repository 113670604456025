import { Component, EventEmitter, Input, Output, OnInit, ViewEncapsulation } from '@angular/core';

// Models
import { Template } from 'app/portal/modules/document-management/models';

// Enums
import { getDocumentTypeLabel } from 'app/portal/modules/document-management/enums';

@Component({
    selector: 'app-document-card',
    templateUrl: './document-card.component.html',
    styleUrls: ['./document-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DocumentCardComponent implements OnInit {

    @Input()
    document: Template;

    @Output()
    downloadClicked: EventEmitter<Template> = new EventEmitter();

    @Output()
    deleteClicked: EventEmitter<Template> = new EventEmitter();

    @Output()
    cardClicked: EventEmitter<Template> = new EventEmitter();

    sharingTitle: string;
    getDocumentTypeLabel = getDocumentTypeLabel;

    ngOnInit() {
        if (this.document.isOwner) {
            this.sharingTitle = `Shared with ${this.document.sharedWithOrganisations.length} organisation${this.document.sharedWithOrganisations.length !== 1 ? 's' : ''}`;
        } else {
            this.sharingTitle = `Shared by ${this.document.ownerOrganisation.title}`;
        }
    }

    openDocument(): void {
        this.cardClicked.emit(this.document);
    }

    downloadDocument(): void {
        this.downloadClicked.emit(this.document);
    }

    deleteDocument(): void {
        this.deleteClicked.emit(this.document);
    }
}