import { Component, Input, OnInit } from '@angular/core';

// ngrx | rxjs
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// store
import * as fromStore from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { ModuleDetails } from 'app/models/module-details.model';
import { CustomMenu } from 'app/models/custom-menu.model';

// enums
import { ViewType } from 'app/connect/enums/view-type.enum';
import { Modules } from 'app/shared/enums';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends BaseComponent implements OnInit {
    showShowMoreButton$: Observable<boolean>;
    allowSwitchView$: Observable<boolean>;
    showNewButton$: Observable<boolean>;
    showHeader$: Observable<boolean>;
    services$: Observable<ModuleDetails[]>;
    allowExport$: Observable<boolean>;
    currentViewType: ViewType;
    hideNavigation$: Observable<boolean>;

    application$: Observable<ModuleDetails>;
    service$: Observable<ModuleDetails>;
    pageTitle$: Observable<string>;

    customMenus: CustomMenu[];
    currentMenu: CustomMenu;

    ViewType = ViewType;

    @Input()
    showAdditionalOptions: boolean = false;

    constructor(
        private store: Store<fromStore.ConnectStoreState>) {
            super();
    }

    ngOnInit(): void {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getPageViewType))
            .subscribe((viewType: ViewType) => {
                this.currentViewType = viewType;
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getCustomMenus))
            .subscribe((menus: CustomMenu[]) => {
                if (menus?.length > 0) {
                    this.customMenus = menus;
                    this.currentMenu = menus.find(x => x.selected) ?? menus[0];
                    this.store.dispatch(fromStore.CustomMenuClicked({menu: this.currentMenu}));
                }
            });

        this.showShowMoreButton$ = this.store.select(fromStore.getShowShowMoreButton);
        this.allowSwitchView$ = this.store.select(fromStore.getAllowSwitchView);
        this.showNewButton$ = this.store.select(fromStore.getShowNewButton);
        this.services$ = this.store.select(fromStore.getServices);

        this.application$ = this.store.select(fromStore.getApplication);
        this.service$ = this.store.select(fromStore.getService);
        this.pageTitle$ = this.store.select(fromStore.getPageTitle);

        this.allowExport$ = this.store.select(fromStore.getAllowExport);
        this.showHeader$ = this.store.select(fromStore.getShowHeader);
        this.hideNavigation$ = this.store.select(fromStore.getHideNavigation);
    }

    handleSwitchViewClick(viewType: ViewType): void {
        this.currentViewType = viewType;
        this.store.dispatch(fromStore.SetPageViewType({viewType: this.currentViewType}));
    }

    onShowMoreButtonClick(): void {
        this.store.dispatch(fromStore.SetShowMore({showMore: true}));
    }

    onNewButtonClick(): void {
        this.store.dispatch(fromStore.NewButtonClicked());
    }

    onServiceClick(serviceId: Modules): void {
        this.store.dispatch(fromStore.SelectService({ serviceId, performRedirect: true }));
    }

    onCustomMenuClick(menu: CustomMenu): void {
        this.currentMenu = menu;
        this.store.dispatch(fromStore.CustomMenuClicked({menu}));
    }

    handleExportClick(): void {
        this.store.dispatch(fromStore.ExportClicked());
    }
}