import { createAction, props } from '@ngrx/store';

// models
import { IdentityVerificationRequest } from 'app/shared/modules/identity-verification/models/identity-verification-request.model';
import { IdentityVerificationResponse } from 'app/shared/modules/identity-verification/models/identity-verification-response.model';
import { IdentityVerificationConfiguration } from 'app/shared/modules/identity-verification/models/identity-verification-configuration.model';

export const SetIdvData = createAction(
    '[Identity Verification] Set IDV data',
    props<{idvData: IdentityVerificationResponse}>()
);



export const GetIdvData = createAction(
    '[Identity Verification] Get IDV Data',
    props<{ observationId: string; token: string}>()
);

export const GetIdvDataSuccess = createAction(
    '[Identity Verification] Get IDV Data Success',
    props<{ response: IdentityVerificationResponse}>()
);

export const GetIdvDataFail = createAction(
    '[Identity Verification] Get IDV Data fail'
);



export const GetIdvConfiguration = createAction(
    '[Identity Verification] Get IDV Configuration',
    props<{ setId: string; itemId: string; observationId: string; token: string}>()
);

export const GetIdvConfigurationSuccess = createAction(
    '[Identity Verification] Get IDV Configuration Success',
    props<{ response: IdentityVerificationConfiguration}>()
);

export const GetIdvConfigurationFail = createAction(
    '[Identity Verification] Get IDV Configuration fail'
);



export const AddSelfie = createAction(
    '[Identity Verification] Add selfie',
    props<{ request: IdentityVerificationRequest; token: string}>()
);

export const AddSelfieSuccess = createAction(
    '[Identity Verification] Add selfie success',
    props<{ response: IdentityVerificationResponse}>()
);

export const AddSelfieFail = createAction(
    '[Identity Verification] Add selfie fail'
);



export const AddUap = createAction(
    '[Identity Verification] Add UAP',
    props<{ request: IdentityVerificationRequest; token: string}>()
);

export const AddUapSuccess = createAction(
    '[Identity Verification] Add UAP success',
    props<{ response: IdentityVerificationResponse}>()
);

export const AddUapFail = createAction(
    '[Identity Verification] Add UAP fail'
);



export const AddIdDocument = createAction(
    '[Identity Verification] Add ID Document',
    props<{ request: IdentityVerificationRequest; token: string}>()
);

export const AddIdDocumentSuccess = createAction(
    '[Identity Verification] Add ID Document success',
    props<{ response: IdentityVerificationResponse}>()
);

export const AddIdDocumentFail = createAction(
    '[Identity Verification] Add ID Document fail'
);
