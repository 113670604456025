import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ImageUploadService } from 'app/shared/services';

// rxjs
import { catchError, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-bg-image',
    template: `
        <div
          *ngIf="dataUrl"
          [style.background-image]="'url(' + dataUrl + ')'"
          style="width: 100%; height: 100%; background-size: {{size}}; background-position:center; background-repeat: no-repeat;"
          (click)="onClicked($event)">
        </div>
    `
  })
  export class SecureBackgroundImageComponent implements OnChanges  {
    @Input()
    src: string;

    @Input()
    notFoundSrc: string;

    @Input()
    notFoundBackgroundSize = 'cover';

    @Input()
    backgroundSize = 'cover';

    size: string;

    @Output()
    imageClick: EventEmitter<any> = new EventEmitter();

    dataUrl: string;

    constructor(
        private httpClient: HttpClient,
        private imageService: ImageUploadService) {
    }

    ngOnChanges(): void {
      this.loadImage();
    }

    onClicked($event: any): void {
      this.imageClick.emit($event);
    }

    private loadImage(): void {
        this.httpClient.get<Blob>(
          this.src,
          { responseType: 'blob' as 'json', observe: 'response'}).pipe(
              map((response: HttpResponse<Blob>) => {
                  if (!response?.body?.size) {
                      this.setNotFound();
                  } else {
                      this.imageService.getImageDataUrl(response.body, result => {
                        this.dataUrl = result;
                        this.size = this.backgroundSize;
                      });
                  }
              }),
              catchError(() => of(this.setNotFound()))).subscribe();
    }

    private setNotFound(): void {
        this.dataUrl = this.notFoundSrc;
        this.size = this.notFoundBackgroundSize;
    }
}