<div fxLayout="column" *ngIf="address">
    <div *ngIf="address.addressLine1">
        {{address.addressLine1}}
    </div>
    <div *ngIf="address.addressLine2">
        {{address.addressLine2}}
    </div>
    <div *ngIf="address.town">
        {{address.town}}
    </div>
    <div *ngIf="address.county">
        {{address.county}}
    </div>
    <div *ngIf="address.postcode">
        {{address.postcode}}
    </div>
</div>