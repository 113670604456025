export enum FileExtensions {
    Any = '*',
    Csv = 'csv',
    Jpg = 'jpg',
    Jpeg = 'jpeg',
    Gif = 'gif',
    Png = 'png',
    Doc = 'doc',
    Docx = 'docx',
    Xlsx = 'xlsx',
    Svg = 'svg',
    Pdf = 'pdf',
    Json = 'json'
}

export const jpgExtensions = [FileExtensions.Jpg, FileExtensions.Jpeg];

export function isJpg(extension: string): boolean {
    if (extension && jpgExtensions.some(x => x === extension)) {
        return true;
    }

    return false;
}

export function isPng(extension: string): boolean {
    if (extension && extension === 'png') {
        return true;
    }

    return false;
}

export function getAllowedFileExtensions() {
    return [
        FileExtensions.Jpeg,
        FileExtensions.Jpg,
        FileExtensions.Png
    ];
}