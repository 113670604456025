// ngrx
import { createFeatureSelector, createSelector } from '@ngrx/store';

// store
import * as reducers from 'app/connect/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<reducers.ConnectStoreState>(StoreNames.connectCommon);
const reducerState = createSelector(storeState, (state: reducers.ConnectStoreState) => state.application);

export const getApplicationState = reducerState;
export const getApplications = createSelector(reducerState, (state: reducers.ApplicationState) => state.applications);
export const getApplication = createSelector(reducerState, (state: reducers.ApplicationState) => state.application);
export const getClient = createSelector(reducerState, (state: reducers.ApplicationState) => state.client);
export const getServices = createSelector(reducerState, (state: reducers.ApplicationState) => state.services);
export const getService = createSelector(reducerState, (state: reducers.ApplicationState) => state.service);
export const getUser = createSelector(reducerState, (state: reducers.ApplicationState) => state.user);
export const getUserGroup = createSelector(reducerState, (state: reducers.ApplicationState) => state.userGroup);
