<app-dialog id="signature-dialog" dialogTitle="Sign the document">

    <div body [formGroup]="form" fxFlex fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="16px">
        <div fxLayout="column">
            <mat-tab-group (selectedIndexChange)="handleTabChanged()">
                <mat-tab label="Draw">
                    <ng-template matTabContent>
                        <div fxLayout="column" fxLayoutGap="8px" class="canvas-container">
                            <app-canvas [color]="color" (imageChanged)="handleDrawnSignatureChanged($event)"></app-canvas>
                            <div fxLayout="row" fxLayoutAlign="start space-between">
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                                    <div class="color blue" [ngClass]="{'selected': signatureColor === SignatureColor.Blue }" (click)="selectSignatureColor(SignatureColor.Blue)"></div>
                                    <div class="color black" [ngClass]="{'selected': signatureColor === SignatureColor.Black }" (click)="selectSignatureColor(SignatureColor.Black)"></div>
                                    <div class="color green" [ngClass]="{'selected': signatureColor === SignatureColor.Green }" (click)="selectSignatureColor(SignatureColor.Green)"></div>
                                    <div class="color purple" [ngClass]="{'selected': signatureColor === SignatureColor.Purple }" (click)="selectSignatureColor(SignatureColor.Purple)"></div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Type">
                    <ng-template matTabContent>
                        <div id="signature-typed" fxLayout="column" fxLayoutGap="6px">
                            <div *ngIf="!typedSignatureSelected" fxFlex fxLayout="column" fxLayoutGap="6px">
                                <div>Select your signature</div>
                                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-xs="space-between center">
                                    <div class="signature" (click)="selectTypedSignature(1)">
                                        <div class="inner" *ngIf="!redraw">
                                            <div class="text style-1" [ngStyle]="{'color': color}" fittext [activateOnResize]="true" [maxFontSize]="48">{{form.get('name').value}}</div>
                                        </div>
                                    </div>
                                    <div class="signature" (click)="selectTypedSignature(2)">
                                        <div class="inner" *ngIf="!redraw">
                                            <div class="text style-2" [ngStyle]="{'color': color}"  [fittext]="true" [activateOnResize]="true" [maxFontSize]="48">{{form.get('name').value}}</div>
                                        </div>
                                    </div>
                                    <div class="signature" (click)="selectTypedSignature(3)">
                                        <div class="inner" *ngIf="!redraw">
                                            <div class="text style-3" [ngStyle]="{'color': color}"  [fittext]="true" [activateOnResize]="true" [maxFontSize]="48">{{form.get('name').value}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-xs="space-between center">
                                    <div class="signature" (click)="selectTypedSignature(4)">
                                        <div class="inner" *ngIf="!redraw">
                                            <div class="text style-4" [ngStyle]="{'color': color}"  [fittext]="true" [activateOnResize]="true" [maxFontSize]="48">{{form.get('name').value}}</div>
                                        </div>
                                    </div>
                                    <div class="signature" (click)="selectTypedSignature(5)">
                                        <div class="inner" *ngIf="!redraw">
                                            <div class="text style-5" [ngStyle]="{'color': color}"  [fittext]="true" [activateOnResize]="true" [maxFontSize]="48">{{form.get('name').value}}</div>
                                        </div>
                                    </div>
                                    <div class="signature" (click)="selectTypedSignature(6)">
                                        <div class="inner" *ngIf="!redraw">
                                            <div class="text style-6" [ngStyle]="{'color': color}"  [fittext]="true" [activateOnResize]="true" [maxFontSize]="48">{{form.get('name').value}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                                    <div class="color blue" [ngClass]="{'selected': signatureColor === SignatureColor.Blue }" (click)="selectSignatureColor(SignatureColor.Blue)"></div>
                                    <div class="color black" [ngClass]="{'selected': signatureColor === SignatureColor.Black }" (click)="selectSignatureColor(SignatureColor.Black)"></div>
                                    <div class="color green" [ngClass]="{'selected': signatureColor === SignatureColor.Green }" (click)="selectSignatureColor(SignatureColor.Green)"></div>
                                    <div class="color purple" [ngClass]="{'selected': signatureColor === SignatureColor.Purple }" (click)="selectSignatureColor(SignatureColor.Purple)"></div>
                                </div>
                            </div>

                            <div *ngIf="typedSignatureSelected" id="selected-typed-signature">
                                <div class="inner">
                                    <div id="typed-signature" class="text style-{{selectedTypedSignatureId}}" [ngStyle]="{'color': color}" [fittext]="true" [activateOnResize]="true" [maxFontSize]="128">{{form.get('name').value}}</div>
                                </div>
                                
                            </div>
                            <div>
                                <button *ngIf="typedSignatureSelected"  mat-raised-button  (click)="changeTypedSignature()">Change Signature</button>
                            </div>
                        </div>

                    </ng-template>
                </mat-tab>
                <mat-tab label="Upload">
                    <ng-template matTabContent>
                        <div id="signature-upload" fxLayout="column" fxLayoutAlign="">

                            <div fxFlex="300px" fxLayout="column" fxLayoutAlign="center center">
                                <img *ngIf="form.get('base64Image').value" [attr.src]="form.get('base64Image').value | safe: 'resourceUrl'">
                            </div>
                    
                            <app-file-upload
                                [allowedFileExtensions]="allowedFileExtensions"
                                [simpleView]="true"
                                (fileUploaded)="handleSignatureUploaded($event)">
                            </app-file-upload>
                        </div>
                    </ng-template>
                </mat-tab>

            </mat-tab-group>
        </div>

        <div>
            <div class="font-question-label">Name</div>
            <mat-form-field appearance="outline">
                <input matInput formControlName="name" />
                <mat-error *ngIf="form.get('name').hasError">Required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div footer-actions>
        <button mat-raised-button class="ghost" mat-dialog-close>Back</button>
        <button [disabled]="form.invalid" mat-raised-button  (click)="sign()">Sign</button>
    </div>
</app-dialog>
