import { Component } from '@angular/core';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-text-only',
    styleUrls: ['./text-only-observation.component.scss'],
    templateUrl: './text-only-observation.component.html',
    outputs: ['valueUpdated', 'selectedChanged']
})
export class TextOnlyObservationComponent extends ObservationComponent {

    constructor() {
        super();
    }

    init(): void {

    }
}
