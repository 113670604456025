import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

// Models
import { DocumentLibrarySignature } from 'app/shared/modules/set-observations/models/document-library-signature.model';
import { DocumentLibraryItem } from 'app/shared/modules/set-observations/models/document-library-item.model';

// Enums
import { getDocumentTypeLabel } from 'app/portal/modules/document-management/enums';

// Services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

const OPEN_BUTTON_TITLE_SIGN = 'SIGN NOW';
const OPEN_BUTTON_TITLE_OPEN = 'OPEN';

const SIGNATURE_STATUS_AWAITING_SIGNATURE = 'Awaiting Signature';
const SIGNATURE_STATUS_SIGNED = 'Signed';

@Component({
    selector: 'app-document-library-card',
    templateUrl: './document-library-card.component.html',
    styleUrls: ['./document-library-card.component.scss']
})

export class DocumentLibraryCardComponent implements OnInit {
    @Input()
    document: DocumentLibraryItem;

    @Input()
    showFooter = true;

    @Output()
    downloadClicked: EventEmitter<DocumentLibraryItem> = new EventEmitter();

    @Output()
    deleteClicked: EventEmitter<DocumentLibraryItem> = new EventEmitter();

    @Output()
    cardClicked: EventEmitter<DocumentLibraryItem> = new EventEmitter();

    @Output()
    signClicked: EventEmitter<DocumentLibraryItem> = new EventEmitter();

    sharingTitle: string;
    hasAdditionalActions: boolean;
    getDocumentTypeLabel = getDocumentTypeLabel;


    organisationId: Guid;

    constructor(authenticationTokenService: AuthenticationTokenService) {
        this.organisationId = authenticationTokenService.clientId();
    }

    ngOnInit() {
        if (this.document.isOwner) {
            this.sharingTitle = `Shared with ${this.document.sharedWithOrganisations.length} organisation${this.document.sharedWithOrganisations.length !== 1 ? 's' : ''}`;
        } else {
            this.sharingTitle = `Shared by ${this.document.ownerOrganisation.title}`;
        }

        this.hasAdditionalActions = this.document && (this.document.canDelete || this.isAwaitingSignature);
    }

    get pendingSignatureByCurrentOrganisation(): DocumentLibrarySignature {
        if (!this.document || !this.document.documentSignatures) {
            return null;
        }

        return this.document.documentSignatures.find(s => s.organisation.id === this.organisationId && !s.signedDate);
    }

    get isSignatory(): boolean {
        if (!this.document || !this.document.documentSignatures) {
            return null;
        }

        return this.document.documentSignatures.filter(s => s.organisation.id === this.organisationId).length > 0;
    }

    get isAwaitingSignature(): boolean {
        if (!this.document) {
            return false;
        }

        const signature = this.pendingSignatureByCurrentOrganisation;
        const doc = this.document;
        return doc.isSignatory && (!signature || !signature.signedDate);
    }

    get isSigned(): boolean {
        if (!this.document) {
            return false;
        }

        return this.isSignatory && !this.isAwaitingSignature;
    }

    get isSharedWith(): boolean {
        if (!this.document || !this.document.sharedWithOrganisations) {
            return false;
        }

        const shared = this.document.sharedWithOrganisations.find(s => s.id === this.organisationId);

        return !!shared;
    }

    get openButtonTitle(): string {
        if (this.isAwaitingSignature) {
            return OPEN_BUTTON_TITLE_SIGN;
        }

        return OPEN_BUTTON_TITLE_OPEN;
    }

    get signatureStatus(): string {
        const signatures = this.document.documentSignatures || [];
        if (signatures.length === 0) {
            return SIGNATURE_STATUS_AWAITING_SIGNATURE;
        } else {
            const signedCount = signatures.filter(s => s.requiredToSign && !!s.signedDate).length;
            if (signedCount === signatures.length) {
                return SIGNATURE_STATUS_SIGNED;
            } else {
                return `${SIGNATURE_STATUS_AWAITING_SIGNATURE} (${signedCount} of ${signatures.length})`;
            }
        }
    }

    get signatureStatusClass(): string {
        if (this.isAwaitingSignature) {
            return 'red';
        } else if (this.isSigned) {
            return 'green';
        }

        return '';
    }

    signaturesComplete(): number {
        if (this.document.documentSignatures) {
            return this.document.documentSignatures.filter(s => s.signedDate && s.signedDate !== null).length;
        }
        return 0;
    }

    openDocument(): void {
        if (this.isAwaitingSignature) {
            this.signDocument();
        } else {
            this.cardClicked.emit(this.document);
        }
    }

    downloadDocument(): void {
        this.downloadClicked.emit(this.document);
    }

    deleteDocument(): void {
        this.deleteClicked.emit(this.document);
    }

    signDocument(): void {
        this.signClicked.emit(this.document);
    }
}
