import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/shared/modules/group/store/reducers';
import * as fromManageAssessmentSets from 'app/shared/modules/group/store/reducers/manage-assessment-set.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getGroupsState = createFeatureSelector<fromFeature.GroupsState>(StoreNames.groups);
const getManageAssessmentSetState = createSelector(getGroupsState, (state: fromFeature.GroupsState) => state.manageAssessmentSetState);

export const getAssessmentSetsLoading = createSelector(getManageAssessmentSetState, (state: fromManageAssessmentSets.ManageAssessmentSetState) => state.assessmentSetsLoading);
export const getAssessmentSets = createSelector(getManageAssessmentSetState, (state: fromManageAssessmentSets.ManageAssessmentSetState) => state.assessmentSets);
export const getBadges = createSelector(getManageAssessmentSetState, (state: fromManageAssessmentSets.ManageAssessmentSetState) => state.badges);
export const getBadgesLoading = createSelector(getManageAssessmentSetState, (state: fromManageAssessmentSets.ManageAssessmentSetState) => state.badgesLoading);
