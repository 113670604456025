import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, tap } from 'rxjs/operators';

// store
import * as actions from 'app/authentication-v2/store/actions';
import * as ActiveUserActions from 'app/store/actions/active-user.actions';
import * as ConnectActions from 'app/connect/store/actions/application.actions';
import * as HelpFaqActions from 'app/connect/store/actions/help-faq.actions';
import * as TooltipActions from 'app/connect/store/actions/tooltip.actions';

// services
import { AuthenticationV2Service } from 'app/authentication-v2/services/authentication-v2.service';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';
import { AuthenticationEventTrackingService } from 'app/authentication-v2/services/authentication-event-tracking.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// models
import { User } from 'app/models/user.model';
import { TokenResponse } from 'app/shared/models/token-response.model';
import { LogoutOptions } from 'app/models/logout-options.model';
import { TemporaryPasswordReset } from 'app/authentication-v2/models/temporary-password-reset.model';

// components
import { TimeoutExplanationDialogComponent } from 'app/authentication-v2/dialogs/timeout-explanation-dialog/timeout-explanation-dialog.component';

@Injectable()
export class AuthEffects {

    timeoutPopup: MatDialogRef<TimeoutExplanationDialogComponent>;

    login$ = createEffect(() => this.actions$.pipe(
        ofType(actions.Login),
        mergeMap(action =>
            this.authService.login(action.request).pipe(
                map((user: User) => {
                    if (user) {
                        if (user.token.password_reset_required) {
                            const request = new TemporaryPasswordReset(user.email, user.token.reset_password_token);
                            return actions.PasswordResetRequired({request});
                        }

                        if(user.clients.every(client => client.locked)) {
                            this.authenticationEventTrackingService.loginFailed();
                            return actions.LoginFail({message: 'Your account has been locked. Please contact your company administrator for more information.'});
                        }

                        return actions.LoginSuccess({user});
                    } else {
                        this.authenticationEventTrackingService.loginFailed();
                        return actions.LoginFail({ message: 'There was an error signing you in, please check and try again.'});
                    }
                }),
                catchError(() => of(actions.LoginFail({ message: 'There was an error signing you in, please check and try again.'}))))
        )));

    loginSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(actions.LoginSuccess),
        switchMap((action) => {
            const newActions: any[] = [
                actions.SetToken({ token: action.user.token}),
                ConnectActions.SetUser({ user: action.user, performRedirect: true, setServiceFromUrl: false}),
                HelpFaqActions.GetShowHelpFaqsOnNavigation(),
                TooltipActions.GetShowTooltipsTourOnNavigation()
            ];
            return newActions;
        })));


    rehydrateUser$ = createEffect(() => this.actions$.pipe(
        ofType(actions.RehydrateUser),
        switchMap(() => this.authService.rehydrateUser().pipe(
                map((user: User) => actions.RehydrateUserSuccess({user})),
                catchError(() => of(actions.RehydrateUserFail()))
            ))
    ));

    rehydrateUserSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.RehydrateUserSuccess),
        switchMap((action) => {
            const clientId = this.authenticationToken.clientId();
            const userGroupId = this.authenticationToken.userGroupId();
            this.authenticationToken.setAuthToken(action.user.token);
            const client = action.user.clients?.find(c => c.id === clientId);
            let userGroup = null;
            if (userGroupId && client && client.userGroups) {
                userGroup = client.userGroups.find(g => g.id === userGroupId);
            }
            return [
                ConnectActions.RehydrateUserSuccess({user: action.user, client, userGroup }),
                HelpFaqActions.GetShowHelpFaqsOnNavigation(),
                TooltipActions.GetShowTooltipsTourOnNavigation()
            ];
        }
    )));

    rehydrateUserFail$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.RehydrateUserFail),
        map(() => actions.Logout({ options: new LogoutOptions(false, true, null)})
    )));

    refreshToken$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.RefreshToken),
        mergeMap((action) => {
            if (action.request.accessToken === 'undefined' ||
                action.request.refreshToken === 'undefined' ||
                action.request.userId === 'undefined') {
                    return of(actions.RefreshTokenFail());
                }

            return this.authService.refreshToken(action.request).pipe(
                map((tokenResponse: TokenResponse) => {
                    if (tokenResponse && tokenResponse.success) {
                        return actions.RefreshTokenSuccess({token: tokenResponse});
                    }
                    return actions.RefreshTokenFail();
                }),
                catchError(() => of(actions.RefreshTokenFail())));
            }
        ),
        catchError(() => of(actions.RefreshTokenFail()))));

    refreshTokenSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.RefreshTokenSuccess),
        switchMap((action) => [actions.SetToken({token: action.token})])));

    refreshTokenFailed$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.RefreshTokenFail),
        switchMap(() => {
            if (!this.timeoutPopup) {
                this.timeoutPopup = this.dialog.open(TimeoutExplanationDialogComponent, {
                    disableClose: true
                });
                this.timeoutPopup.afterClosed().subscribe(() => this.timeoutPopup = null);
            }
            return [actions.Logout({ options: new LogoutOptions(false, true, null)})];
        })));

    setToken$ = createEffect(() =>   this.actions$.pipe(
        ofType(actions.SetToken),
        tap((action) => this.authenticationToken.setAuthToken(action.token))),
        { dispatch: false });

    logout$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.Logout),
        switchMap((action) => {
            const newActions: any[] = [];
            newActions.push(
                new ActiveUserActions.EmptyState(),
                actions.LogoutSuccess({options : action.options})
            );

            return newActions;
        })));

    logoutSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.LogoutSuccess),
        tap((action) => {
            this.authenticationToken.clear();
            if (action.options.redirectUrl) {
                window.location.href = action.options.redirectUrl;
            } else if (action.options.redirectToLoginPage) {
                window.location.href = '/auth-v2/login';
            }
        })),
        { dispatch: false });

    setPassword$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.SetPassword),
        switchMap(action => this.authService.resetPassword(action.request).pipe(
                map((user: User) => {
                    if (user) {
                        return actions.SetPasswordSuccess({user});
                    }
                    return actions.SetPasswordFail();
                }),
                catchError(() => of(actions.SetPasswordFail()))
            ))
    ));

    setPasswordSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.SetPasswordSuccess),
        switchMap(action => {
            const doRedirect = window.location.pathname.startsWith('/auth/');
            return [
                actions.SetToken({token: action.user.token}),
                ConnectActions.SetUser({ user: action.user, performRedirect: doRedirect, setServiceFromUrl: false }),
                HelpFaqActions.GetShowHelpFaqsOnNavigation(),
                TooltipActions.GetShowTooltipsTourOnNavigation()
            ];
        })
    ));

    setPasswordFail$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.SetPasswordFail),
        tap(() => this.alertService.error('We were unable to set your password at this time.')
        )
    ),
    { dispatch: false });

    resetPassword$ = createEffect(() => this.actions$.pipe(
        ofType(actions.PasswordResetRequired),
        tap(() => this.router.navigate(['/auth-v2/reset-password']))),
        { dispatch: false });


    checkPasswordLink$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.CheckPasswordLink),
        switchMap(action => this.authService.isPasswordLinkValid(action.email).pipe(
                map((valid: boolean) => actions.CheckPasswordLinkSucccess({valid})),
                catchError(() => of(actions.CheckPasswordLinkFail()))
            ))
    ));

    checkPasswordLinkSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.CheckPasswordLinkSucccess),
        tap((action) => {
            if (!action.valid) {
                this.router.navigate(['/auth-v2/login']);
            }
    })), { dispatch: false });

    tokenLogin$ = createEffect(() => this.actions$.pipe(
        ofType(actions.TokenLogin),
        mergeMap(action =>
            this.authService.tokenLogin(action.request).pipe(
                map((user: User) => {
                    const client = user.clients.find(c => c.id === action.request.clientId);
                    if (user && client) {
                        return ConnectActions.SetUserForTokenLogin({user, client, tokenDetails: action.request});
                    } else {
                        return actions.LoginFail({ message: 'Token is invalid or expired.'});
                    }
                }),
                catchError(() => of(actions.LoginFail({ message: 'Error occurred on login attempt.'}))))
        )));


    constructor(
        private dialog: MatDialog,
        private actions$: Actions,
        private authService: AuthenticationV2Service,
        private router: Router,
        private alertService: AlertService,
        private authenticationToken: AuthenticationTokenService,
        private authenticationEventTrackingService: AuthenticationEventTrackingService) { }
}
