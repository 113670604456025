import { Modules } from 'app/shared/enums/modules.enum';
import { UserActivityType } from 'app/shared/enums/user-activity-type.enum';

export class UserActivityRequest {
    userId: string;
    activityType: UserActivityType;
    organisationId: string;
    moduleId: Modules;
    dateCreated: Date;

    constructor(
        activityType: UserActivityType,
        moduleId: Modules = null,
        dateCreated: Date = null
    ) {
        this.activityType = activityType;
        this.moduleId = moduleId;
        this.dateCreated = dateCreated;
    }
}