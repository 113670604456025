<app-brand-panel *ngIf="visible$ | async" id="user-group-selector" [expandable]="true" fxLayout="column" fxLayoutGap="24px" [showLogo]="false" (closeClicked)="closeBrandPanel()">
    
    <div fxLayout="column" fxLayoutAlign="centre center">
        <div class="title" fxLayout="column" fxLayoutGap="24px">
            <div class="title-text">Select User Group</div>
            
            <mat-form-field appearance="outline" class="ghost small">
                <input matInput placeholder="Search" (input)="handleSearch(searchInput.value)" #searchInput/>
            </mat-form-field>
        </div>
    </div>

    <div fxFlex fxLayout="column" fxLayoutGap="24px" class="user-group-container">
        <div *ngIf="client?.userGroups?.length" fxLayout="column" fxLayoutGap="24px">
            <div fxLayout="row grid" fxLayoutGap="grid">
                <div class="user-group primary-bg primary-reverse-text pointer"
                    fxFlex.lt-md="33%"
                    fxLayout="column" fxLayoutAlign="center center"
                    *ngFor="let userGroup of filterUserGroups(client.userGroups) | orderBy: 'name'"
                    (click)="handleUserGroupSelected(userGroup)">
                    <div class="group-icon"><mat-icon>supervised_user_circle</mat-icon></div>
                    <div class="name text font-body-xsmall">{{userGroup.name}}</div>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
        <div class="pointer back-link" (click)="handleBackLinkClicked()" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>chevron_left</mat-icon>
            <div>{{backLabel}}</div>
        </div>
    </div>
</app-brand-panel>