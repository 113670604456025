import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-image-card',
    templateUrl: './image-card.component.html',
    styleUrls: ['./image-card.component.scss']
})
export class ImageCardComponent {

    @Input() id = '';
    @Input() src: string;
    @Input() header: string;
    @Input() canDownload = false;
    @Input() canRemove = false;
    @Input() fixedHeader = false;
    @Input() greyscale = false;

    @Output()
    imageDownloadClicked: EventEmitter<string> = new EventEmitter();

    @Output()
    imageRemoveClicked: EventEmitter<string> = new EventEmitter();

    removeClicked() {
        this.imageRemoveClicked.emit(this.id);
    }

    downloadClicked() {
        this.imageDownloadClicked.emit(this.id);
    }
}
