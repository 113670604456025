import { TermsOverviewType } from 'app/shared/modules/terms-obsolete/enums/terms-overview-type.enum';

export class GetTermsOverviewRequest {
    termsOverviewType: TermsOverviewType;
    groupId: string;
    organisationId: string;

    constructor(termsOverviewType: TermsOverviewType, groupId: string = null, organisationId: string = null) {
        this.termsOverviewType = termsOverviewType;
        this.groupId = groupId;
        this.organisationId = organisationId;
    }
}