import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Components
import { BaseComponent } from 'app/shared/base/base-component';
import { SignatureDialogComponent } from 'app/shared/components/signature-dialog/signature-dialog.component';

// Models
import { Signature } from 'app/shared/models/signature.model';

@Component({
    selector: 'app-esign-signature',
    templateUrl: './esign-signature.component.html',
    styleUrls: ['esign-signature.component.scss']
})
export class ESignSignatureComponent extends BaseComponent {

    @Input()
    alreadySigned: boolean;

    @Input()
    signed: boolean;

    @Input()
    signatureName: string;

    @Input()
    signatureDate: Date;

    @Output()
    signatureCaptured: EventEmitter<Signature> = new EventEmitter();

    constructor(private dialogs: MatDialog) {
        super();
    }

    sign(): void {
        const dialog = this.dialogs.open(SignatureDialogComponent, {
            data: this.signatureName
        });

        dialog.afterClosed().subscribe((signature: Signature) => {
            if (signature) {
                this.signatureCaptured.emit(signature);
            }
        });
    }
}