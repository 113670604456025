// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { Client } from 'app/models/client.model';
import { ModuleDetails } from 'app/models/module-details.model';
import { User } from 'app/models/user.model';
import { LogoutOptions } from 'app/models/logout-options.model';

// enums
import { Modules } from 'app/shared/enums';
import { TokenLoginDetails } from 'app/authentication-v2/models/token-login-details.model';
import { UserGroup } from 'app/models/user-group.model';

export const RehydrateUser = createAction(
    '[Connect Application] Rehydrate the application data'
);

export const RehydrateUserSuccess = createAction(
    '[Connect Application] Rehydrate the application data success',
    props<{user: User; client: Client; userGroup: UserGroup}>()
);

export const SetUser = createAction(
    '[Connect Application] Set the user',
    props<{user: User; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SelectClient = createAction(
    '[Connect Application] Select the client',
    props<{clientId: string; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetClient = createAction(
    '[Connect Application] Set the client',
    props<{client: Client; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetAndPopulateClient = createAction(
    '[Connect Application] Set and populate the client',
    props<{client: Client; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetAndPopulateClientSuccess = createAction(
    '[Connect Application] Set and populate the client success',
    props<{client: Client; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetClientFail = createAction(
    '[Connect Application] Set the client fail'
);

export const SetClientToken = createAction(
    '[Connect Application] Set the client token',
    props<{client: Client; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetTokenSuccess = createAction(
    '[Connect Application] Set the token success',
    props<{performRedirect: boolean; setServiceFromUrl: boolean; isClient: boolean}>()
);

export const SelectEntityClient = createAction(
    '[Connect Application] Select the entity client',
    props<{clientId: string}>()
);

export const SetApplications = createAction(
    '[Connect Application] Set applications',
    props<{applications: ModuleDetails[]; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetServices = createAction(
    '[Connect Application] Set services',
    props<{services: ModuleDetails[]; performRedirect: boolean}>()
);

export const SelectApplication = createAction(
    '[Connect Application] Select an application',
    props<{applicationId: Modules; performRedirect: boolean}>()
);

export const SetApplication = createAction(
    '[Connect Application] Set the selected application',
    props<{application: ModuleDetails; performRedirect: boolean}>()
);

export const SelectService = createAction(
    '[Connect Application] Select the service',
    props<{serviceId: Modules; performRedirect: boolean}>()
);

export const SetService = createAction(
    '[Connect Application] Set the selected service success',
    props<{service: ModuleDetails; performRedirect: boolean}>()
);

export const LogoutUser = createAction(
    '[Connect Application] Log out user',
    props<{options: LogoutOptions}>()
);

export const SetUserForTokenLogin = createAction(
    '[Connect Application] Set for token login',
    props<{user: User; client: Client; tokenDetails: TokenLoginDetails}>()
);

export const NoPermissionsForClientUser = createAction(
    '[Connect Application] No permissions found for Client User',
    props<{ clientName: string}>()
);

export const SetUserGroup = createAction(
    '[Connect Application] Set the user group',
    props<{clientId: string; userGroup: UserGroup; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetUserGroupFail = createAction(
    '[Connect Application] Set the user group fail'
);

export const SetUserGroupToken = createAction(
    '[Connect Application] Set the user group token',
    props<{clientId: string; userGroup: UserGroup; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const ClearUserGroup = createAction(
    '[Connect Application] Clear the user group',
    props<{performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const ClearUserGroupFail = createAction(
    '[Connect Application] Clear the user group fail'
);

export const RefreshToken = createAction(
    '[Connect Application] Refresh the token'
);