import { Component } from '@angular/core';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

// models
import { Option } from 'app/shared/models/option.model';

// enums
import { ButtonSize } from 'app/shared/modules/set-observations/enums/button-size.enum';
import { ItemType } from 'app/shared/enums/item-type.enum';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-multi-option-single',
    templateUrl: './multi-option-single-observation.component.html',
    outputs: ['valueUpdated', 'selectedChanged']
})
export class MultiOptionSingleObservationComponent extends ObservationComponent {

    options: Option[];
    buttonSize: ButtonSize;

    constructor() {
        super();
    }

    init() {
        this.form.get('observation').valueChanges.subscribe((value: number) => this.updateNumberValue(value));
        this.options = [];
        this.item.multiOptionValues.sort((a, b) => a.order - b.order);
        for (const multiOptionValue of this.item.multiOptionValues) {
            this.options = [...this.options, new Option(multiOptionValue.value, multiOptionValue.value)];
        }

        this.buttonSize = this.item.type === ItemType.MultiOptionSingle
            ? ButtonSize.Multi
            : ButtonSize.Standard;
    }

    onValueChanged($event) {
        this.form.get('observation').setValue($event);
    }
}
