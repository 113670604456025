import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

// Modules
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { ConnectModule } from 'app/connect/connect.module';

// Store
import { effects, reducers } from './store';

// Dialog components
import { ManageAssessmentSetsDialogComponent } from './dialogs/manage-assessment-sets-dialog/manage-assessment-sets-dialog.component';
import { BadgeSelectionTabComponent } from './dialogs/manage-assessment-sets-dialog/tabs/badge-selection-tab/badge-selection-tab.component';

// Services
import { GroupService } from './services/group.service';
import { GroupDialogService } from './services/group-dialog.service';
import { GroupSharingService } from './services/group-sharing.service';
import { ManageAssessmentSetService } from './services/manage-assessment-set.service';

// Components
import { BadgeCardComponent } from './components/badge-card/badge-card.component';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const dialogs = [
    ManageAssessmentSetsDialogComponent,
    ManageAssessmentSetsDialogComponent
];

const components = [
    BadgeCardComponent,
    BadgeSelectionTabComponent
];

const services = [
    GroupDialogService,
    GroupService,
    GroupSharingService,
    ManageAssessmentSetService
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        EffectsModule.forFeature(effects),
        StoreModule.forFeature(StoreNames.groups, reducers),
        ConnectModule
    ],
    declarations: [
        ...dialogs,
        ...components
    ],
    entryComponents: [
        ...dialogs,
        BadgeSelectionTabComponent
    ],
    providers: [
        ...services
    ]
})
export class GroupModule { }