<div [formGroup]="form">
        <app-option-selector 
            [options]="options"
            (valueChanged)="onValueChanged($event)"
            [value]="itemObservation.value"
            [mandatory]="item.mandatory"
            [label]="item.name"
            [buttonSize]="buttonSize"
            [readonly]="item.readonly"
            [description]="item.description"></app-option-selector>
    </div>
