import { Component } from '@angular/core';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

// models
import { Option } from 'app/shared/models/option.model';

// enums
import { ButtonSize } from 'app/shared/modules/set-observations/enums/button-size.enum';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-net-promoter-score',
    styleUrls: ['./net-promoter-score-observation.component.scss'],
    templateUrl: './net-promoter-score-observation.component.html',
    outputs: ['valueUpdated', 'selectedChanged']
})
export class NetPromoterScoreObservationComponent extends ObservationComponent {

    options: Option[];
    buttonSize: ButtonSize = ButtonSize.Small;

    constructor() {
        super();
    }

    init(): void {
        this.form.get('observation').valueChanges.subscribe((value: number) => this.updateNumberValue(value));
        this.options = [
            new Option('0', '0'),
            new Option('1', '1'),
            new Option('2', '2'),
            new Option('3', '3'),
            new Option('4', '4'),
            new Option('5', '5'),
            new Option('6', '6'),
            new Option('7', '7'),
            new Option('8', '8'),
            new Option('9', '9'),
            new Option('10', '10')
        ];
    }

    onValueChanged($event): void {
        this.form.get('observation').setValue($event);
    }
}
