import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

// Components
import { BaseComponent } from 'app/shared/base/base-component';
import { getColumnNumberThree, onPageResizeThree } from 'app/shared/components/grid-container/grid-container.component';

// NgRx/RxJs
import { Store, select, ActionsSubject } from '@ngrx/store';
import { takeUntil, filter } from 'rxjs/operators';

// Store
import * as state from 'app/shared/modules/group/store/reducers';
import * as actions from 'app/shared/modules/group/store/actions';
import * as selectors from 'app/shared/modules/group/store/selectors';

// Models
import { UpdateAssessmentsRequest } from 'app/shared/modules/group/models/update-assessments-request.model';
import { AssessmentSetSummary } from 'app/shared/modules/group/models/assessment-set-summary.model';
import { FilterConfiguration } from 'app/models/filter/filter-configuration.model';
import { Filter } from 'app/models/filter/filter.model';
import { BadgeSummary } from 'app/shared/modules/group/models/badge-summary.model';
import { ManageAssessmentSetInfo } from 'app/shared/modules/group/models/manage-assessment-set-info.model';

// Enums
import { ManageAssessmentSetDialogType } from 'app/shared/modules/group/enums/manage-assessment-set-dialog-type.enum';

@Component({
    templateUrl: './manage-assessment-sets-dialog.component.html',
    styleUrls: ['./manage-assessment-sets-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ManageAssessmentSetsDialogComponent extends BaseComponent implements OnInit {
    groupId: string;
    entityId: string;
    saving: boolean = false;

    assessmentSets: AssessmentSetSummary[] = [];
    visibleAssessmentSets: AssessmentSetSummary[] = [];

    badges: BadgeSummary[];

    dialogData: ManageAssessmentSetInfo;

    assessmentSetsLoading: boolean;
    badgesLoading: boolean;
    loading: boolean;

    columnNum: number;

    iconSource: string;

    assessmentSearchText: string = '';
    alertSearchText: string = '';
    type: ManageAssessmentSetDialogType;
    ManageAssessmentSetDialogType = ManageAssessmentSetDialogType;

    assessmentFilterConfiguration: FilterConfiguration = new FilterConfiguration('Search Assessments', true);

    constructor(
        private dialogRef: MatDialogRef<ManageAssessmentSetsDialogComponent>,
        private store: Store<state.GroupsState>,
        private actionSubject: ActionsSubject,
        @Inject(MAT_DIALOG_DATA) data: ManageAssessmentSetInfo,
        private router: Router) {
        super();

        this.dialogData = data;
        this.groupId = data.groupId;
        this.entityId = data.entityId;
        this.type = data.type;
    }

    ngOnInit(): void {
        this.router.navigate([], {
            queryParams: {
                ['s']: null
            },
            queryParamsHandling: 'merge'
        });

        this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectors.getAssessmentSets))
            .subscribe((assessmentSets: AssessmentSetSummary[]) => {
                if (assessmentSets) {
                    this.assessmentSets = assessmentSets;
                    this.setCollections();
                }
            });

        this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectors.getBadges))
            .subscribe((badges: BadgeSummary[]) => {
                if (badges) {
                    this.badges = badges;
                }
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(selectors.getAssessmentSetsLoading))
            .subscribe((assessmentSetsLoading: boolean) => {
                this.assessmentSetsLoading = assessmentSetsLoading;
                this.setIsLoading();
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(selectors.getBadgesLoading))
            .subscribe((badgesLoading: boolean) => {
                this.badgesLoading = badgesLoading;
                this.setIsLoading();
            });

        this.store.dispatch(new actions.ClearManageAssessmentSetLists());

        this.loadData();

        this.actionSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === actions.UPDATE_ASSESSMENT_SETS_SUCCESS))
            .subscribe(() => {
                this.dialogRef.close(true);
            });

        this.columnNum = getColumnNumberThree();
    }

    onResize() {
        this.columnNum = onPageResizeThree();
    }

    save() {
        this.saving = true;
        const request = new UpdateAssessmentsRequest(
            this.type,
            this.groupId,
            this.entityId,
            this.getSelectedAssessmentSets().map(a => a.id),
            this.badges.filter(b => b.enabled).map(b => b.id));

        this.store.dispatch(new actions.UpdateAssessmentSets(request));
    }

    searchAssessments(currentFilter: Filter): void {
        this.assessmentSearchText = null;
        if (currentFilter && currentFilter.searchText) {
            this.assessmentSearchText = currentFilter.searchText.toLocaleLowerCase().trim();
        }

        this.setCollections();
    }

    searchAlerts(currentFilter: Filter): void {
        this.alertSearchText = currentFilter.searchText.toLocaleLowerCase().trim();

        this.setCollections();
    }

    handleAssessmentSetEnabledChanged(assessmentSet: AssessmentSetSummary, enabled: boolean): void {
        assessmentSet.enabled = enabled;

        this.setCollections();
    }

    handleBadgeEnabledChanged(badge: BadgeSummary): void {
        const includedSets = this.assessmentSets.filter(a => badge.assessmentSetIds.includes(a.id) && !a.isCore);
        for (const includedSet of includedSets) {
            includedSet.enabled = this.enabledInBadge(includedSet);
        }

        this.setCollections();
    }

    enabledInBadge(assessmentSet: AssessmentSetSummary): boolean {
        if (!this.badges) {
            return false;
        }

        for (const badge of this.badges.filter(b => b.enabled)) {
            if (badge.assessmentSetIds.includes(assessmentSet.id)) {
                return true;
            }
        }

        return false;
    }

    private loadData(): void {
        switch (this.type) {
            case ManageAssessmentSetDialogType.Group:
                this.loadGroupData();
                break;
            case ManageAssessmentSetDialogType.Profile:
                this.loadProfileData();
                break;
            case ManageAssessmentSetDialogType.Invite:
                this.loadInviteData();
                break;
            case ManageAssessmentSetDialogType.IndividualInvite:
                this.loadIndividualInviteData();
                break;
        }
    }

    private loadGroupData(): void {
        this.store.dispatch(new actions.GetAssessmentSets(this.groupId));
        this.store.dispatch(new actions.GetBadges(this.groupId));
    }

    private loadProfileData(): void {
        this.store.dispatch(new actions.GetProfileAssessmentSets());
        this.store.dispatch(new actions.GetProfileBadges());
    }

    private loadInviteData(): void {
        this.store.dispatch(new actions.GetInviteAssessmentSets(this.groupId));
        this.store.dispatch(new actions.GetInviteBadges(this.groupId));
    }

    private loadIndividualInviteData(): void {
        if (this.dialogData.entityId) {
            this.store.dispatch(new actions.GetIndividualInviteAssessmentSets(this.dialogData));
            this.store.dispatch(new actions.GetIndividualInviteBadges(this.dialogData));
        }
    }

    private assessmentVisible(assessmentSet: AssessmentSetSummary): boolean {
        if (!this.assessmentSearchText || !this.assessmentSearchText || this.assessmentSearchText === '') {
            return true;
        }

        return assessmentSet.title.toLocaleLowerCase().indexOf(this.assessmentSearchText) >= 0 ||
            (assessmentSet.description && assessmentSet.description.toLocaleLowerCase().indexOf(this.assessmentSearchText) >= 0);
    }

    private getSelectedAssessmentSets(): AssessmentSetSummary[] {
        return this.assessmentSets.filter(a => !a.isCore && a.enabled);
    }

    private setCollections(): void {
        if (!this.assessmentSets) {
            return;
        }

        this.visibleAssessmentSets = this.assessmentSets.filter(a => this.assessmentVisible(a));
    }

    private setIsLoading(): void {
        this.loading = this.assessmentSetsLoading || this.badgesLoading;
    }
}
