// ngrx
import { ActionReducerMap } from '@ngrx/store';

// store
import * as fromIdv from './identity-verification.reducer';

export interface IdentityVerificationStoreState {
    identityVerification: fromIdv.IdentityVerificationState;
}

export const reducers: ActionReducerMap<IdentityVerificationStoreState> = {
    identityVerification: fromIdv.reducer
};

export { IdentityVerificationState } from './identity-verification.reducer';
