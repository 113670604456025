import { IdDocumentType } from 'app/shared/enums/id-document-type.enum';
import { ItemType } from 'app/shared/enums/item-type.enum';

export class QrCodeData {
    organisationId: string;
    setId: string;
    itemId: string;
    observationId: string;
    itemType: ItemType;
    idDocumentType: IdDocumentType;
    stepId: string;

    constructor(organisationId: string,
                setId: string,
                itemId: string,
                observationId: string,
                itemType: ItemType,
                stepId: string = null) {
        this.organisationId = organisationId;
        this.setId = setId;
        this.itemId = itemId;
        this.observationId = observationId;
        this.itemType = itemType;
        this.stepId = stepId;
    }
}
