import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-profile-status',
    templateUrl: 'profile-status.component.html',
    styleUrls: ['profile-status.component.scss']
})
export class ProfileStatusComponent {

    @Input()
    profileCompletePercentage: number = 0;

    @Input()
    hasAlerts: boolean = false;

    @Input()
    alertsCount: number = 0;

    @Input()
    label: string = 'Your profile';

    @Input()
    icon: string;

    @Input()
    readonly: boolean;

    @Output()
    completeNowClicked: EventEmitter<void> = new EventEmitter();

    handleCompleteNowClicked(): void {
        this.completeNowClicked.emit();
    }

    get isComplete(): boolean {
        return this.profileCompletePercentage === 100;
    }

    get isEditable(): boolean {
        return !this.isComplete && !this.readonly;
    }
}