<app-page #page (search)="search($event)" [showAdditionalOptions]="true">
    <div toolbar id="sub-header">
        <button mat-menu-item (click)="addTemplateClick(DocumentTemplateType.Word)" aria-label="Add Template">Upload Template</button>
        <button mat-menu-item (click)="addTemplateClick(DocumentTemplateType.RichText)" aria-label="Add Template">Add Text Template</button>
        <button mat-menu-item (click)="addTemplateClick(DocumentTemplateType.PDFExport)" aria-label="Add Export Template">Add PDF Export Template</button>
    </div>
    <div content fxLayout="column" fxFlex>
        <app-loading-card *ngIf="(loading$ | async)"></app-loading-card>
        <div *ngIf="!(loading$ | async)" class="">

            <app-page-information-text
                text="Create documents such as contracts or policies and then share them with other users in your network.">
            </app-page-information-text>

            <div *ngIf="hasActiveDocuments()">
                <h3>Active</h3>
                <app-card-container *ngIf="activeDocuments">
                    <mat-grid-list cols="{{columnNum}}" rowHeight="288px" (window:resize)="onResize($event)"
                        gutterSize="15px">
                        <mat-grid-tile *ngFor="let document of activeDocuments">
                            <app-document-card class="cards" [document]="document"
                                (downloadClicked)="downloadTemplateClick($event)"
                                (deleteClicked)="deleteTemplateClick($event)"
                                (cardClicked)="editTemplateClick($event)">
                            </app-document-card>
                        </mat-grid-tile>
                    </mat-grid-list>
                </app-card-container>
            </div>

            <div *ngIf="hasInactiveDocuments()">
                <h3>Inactive</h3>
                <app-card-container *ngIf="inactiveDocuments">
                    <mat-grid-list cols="{{columnNum}}" rowHeight="288px" (window:resize)="onResize($event)"
                        gutterSize="15px">
                        <mat-grid-tile *ngFor="let document of inactiveDocuments">
                            <app-document-card class="cards" [document]="document"
                                (downloadClicked)="downloadTemplateClick($event)"
                                (deleteClicked)="deleteTemplateClick($event)"
                                (cardClicked)="editTemplateClick($event)">
                            </app-document-card>
                        </mat-grid-tile>
                    </mat-grid-list>
                </app-card-container>
            </div>

            <app-no-information-message *ngIf="!hasData()"></app-no-information-message>
            <app-card-container *ngIf="!hasData()">
                <h3>Example Document</h3>
                <mat-grid-list cols="{{columnNum}}" rowHeight="288px" (window:resize)="onResize($event)"
                    gutterSize="15px">
                    <mat-grid-tile>
                        <app-document-card class="cards" [document]="exampleDocument">
                        </app-document-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </app-card-container>
        </div>
    </div>
</app-page>