import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/shared/modules/group/store/reducers';
import * as fromFolders from 'app/shared/modules/group/store/reducers/group-list-sharing.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getGroupsState = createFeatureSelector<fromFeature.GroupsState>(StoreNames.groups);
const getGroupListSharingState = createSelector(getGroupsState, (state: fromFeature.GroupsState) => state.groupListSharing);

export const getEntities = createSelector(getGroupListSharingState, (state: fromFolders.GroupListSharingState) => state.entities);
export const getClients = createSelector(getGroupListSharingState, (state: fromFolders.GroupListSharingState) => state.clients);