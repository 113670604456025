import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/shared/modules/terms-obsolete/store/reducers';
import { TermsState } from 'app/shared/modules/terms-obsolete/store/reducers/terms.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

// States
const getTermsManagementState = createFeatureSelector<fromFeature.TermsManagementState>(StoreNames.termsManagement);
const getTermsState = createSelector(getTermsManagementState, (state: fromFeature.TermsManagementState) => state.termsState);

// Selectors
export const getTerms = createSelector(getTermsState, (state: TermsState) => state.terms);
export const getOrganisationTerms = createSelector(getTermsState, (state: TermsState) => state.organisationTerms);
export const getLoading = createSelector(getTermsState, (state: TermsState) => state.loading);
export const getLoaded = createSelector(getTermsState, (state: TermsState) => state.loaded);
export const getSaving = createSelector(getTermsState, (state: TermsState) => state.saving);
export const getSaved = createSelector(getTermsState, (state: TermsState) => state.saved);
export const getWorking = createSelector(getTermsState, (state: TermsState) => state.saving || state.loading);