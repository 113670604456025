import { Injectable } from '@angular/core';

// models
import { Modules } from 'app/shared/enums/modules.enum';
import { ModuleDetails } from 'app/models/module-details.model';
import { User } from 'app/models/user.model';
import { Client } from 'app/models/client.model';
import { UserGroup } from 'app/models/user-group.model';

@Injectable()
export class ModuleService {

    private static userModules: ModuleDetails[] = null;
    private static clientModules: ModuleDetails[] = null;
    private static allModules: ModuleDetails[] = null;
    private static client: Client;
    static isSuperUser: boolean = false;

    static clear(): void {
        this.userModules = null;
        this.clientModules = null;
        this.allModules = null;
        this.isSuperUser = false;
    }

    static getClientModules(): ModuleDetails[] {
        return this.clientModules.filter(x => !x.superUserOnly);
    }

    static getAllModules(): ModuleDetails[] {
        return this.allModules;
    }

    static populate(user: User): void {
        this.allModules = user.modules;
        this.isSuperUser = user.isSuperUser;
    }

    static setClient(client: Client): void {
        this.client = client;
        if (this.isSuperUser) {
            this.clientModules = this.allModules.map(m => {
                const clientModule = client.modules.filter(c => c.id === m.id)[0];
                if (clientModule) {
                    return clientModule;
                }

                return m;
            });
        } else {
           ModuleService.clientModules = client.modules
            .map(m => ({
                ...m,
                title: m.title ?? this.allModules.filter(am => am.id === m.id)[0]?.title,
                description: m.description ?? this.allModules.filter(am => am.id === m.id)[0]?.description
            }));

            ModuleService.userModules = ModuleService.clientModules.filter(m => ModuleService.clientModules.find(cm => cm.id === m.id && this.client.userModules.find(um => um === m.id)));
        }
    }

    static setUserGroup(userGroup: UserGroup) {
        if (userGroup) {
            ModuleService.userModules = ModuleService.userModules.filter(um => userGroup.modules.includes(um.id));
        } else {
            ModuleService.userModules = ModuleService.clientModules.filter(m => ModuleService.clientModules.find(cm => cm.id === m.id && this.client.userModules.find(um => um === m.id)));
        }
    }

    static isEnabledForClient(moduleId: Modules): boolean {
        return ModuleService.clientModules.filter(m => m.id === moduleId).length > 0;
    }

    static getTitle(moduleId: Modules): string {
        if (ModuleService.clientModules) {
            const clientModule = ModuleService.clientModules.filter(m => m.id === moduleId && m.title)[0];
            if (clientModule) {
                return clientModule.title;
            }
        }

        return ModuleService.allModules.filter(m => m.id === moduleId)[0].title;
    }

    static getUrl(moduleId: Modules): string {
        return ModuleService.allModules.filter(m => m.id === moduleId)[0].url;
    }

    static isEnabled(moduleId: Modules, mustHaveTheModule: boolean = false): boolean {
        if (ModuleService.isSuperUser) {
            return true;
        }

        if (!ModuleService.userModules) {
            if (!mustHaveTheModule) {
                return true;
            } else {
                return false;
            }
        }

        return ModuleService.userModules.filter(m => m.id === moduleId).length > 0;
    }

    static anyPermissions(): boolean {
        if (ModuleService.isSuperUser) {
            return true;
        }

        if (!ModuleService.userModules?.some(m => !m.parentId)) {
            return false;
        }

        const applications = ModuleService.userModules.filter(m => !m.parentId);
        for (const application of applications) {
            if (ModuleService.userModules.some(m => m.parentId === application.id && m.showOnNavigation)) {
                return true;
            }
        }
        return false;
    }
}