import { Component, EventEmitter, Input, Output } from '@angular/core';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromRoot from 'app/store';
import * as fromMobile from 'app/shared/modules/mobile-photo/store';

// models
import { IdentityVerificationConfiguration } from 'app/shared/modules/identity-verification/models/identity-verification-configuration.model';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-idv-complete-step',
    templateUrl: './complete-step.component.html',
    styleUrls: ['./complete-step.component.scss']
})
export class CompleteStepComponent {
    @Output()
    retakeSelfieClicked: EventEmitter<void> = new EventEmitter();

    @Output()
    addIdDocumentClicked: EventEmitter<void> = new EventEmitter();

    @Input()
    configuration: IdentityVerificationConfiguration;

    isMobileDevice$: Observable<boolean>;
    token: string;
    returnedToDesktop = false;

    @Input()
    showRetakeButtons: boolean;

    showReturnToDesktop = false;

    constructor(
        rootStore: Store<fromRoot.State>,
        route: ActivatedRoute,
        private mobileStore: Store<fromMobile.MobilePhotoStoreState>) {

            this.isMobileDevice$ = rootStore.select(fromRoot.isMobileDevice);
            this.token = route.snapshot.queryParams.token;
            this.showReturnToDesktop = this.token !== undefined;
    }

    handleRetakeSelfieClick(): void {
        this.retakeSelfieClicked.emit();
    }

    handleAddIdDocumentClicked(): void {
        this.addIdDocumentClicked.emit();
    }

    handleCloseSwitchToMobile(): void {
        this.mobileStore.dispatch(fromMobile.ClearMobileToken({ token: this.token }));
        this.returnedToDesktop = true;
    }
}