import { Injectable } from '@angular/core';

// ngrx
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

// Store
import * as fromActions from 'app/shared/modules/group/store/actions/group-list-sharing.actions';

// Services
import { GroupSharingService } from 'app/shared/modules/group/services/group-sharing.service';

// Models
import { OrganisationTitle } from 'app/shared/models/organisation-title.model';

@Injectable()
export class GroupListSharingEffects {


    getEntitiesToShareWith$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_ENTITIES_TO_SHARE_WITH),
        map((action: fromActions.GetEntitiesToShareWith) => action.payload),
        switchMap((includeAll: boolean) => this.groupSharingService.getEntities(includeAll).pipe(
                map((organisations: OrganisationTitle[]) => new fromActions.GetEntitiesToShareWithSuccess(organisations)),
                catchError(() => of(new fromActions.GetEntitiesToShareWithFail()))))));


    getClientsToShareWith$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_CLIENTS_TO_SHARE_WITH),
        map((action: fromActions.GetClientsToShareWith) => action.payload),
        switchMap((includeAll: boolean) => this.groupSharingService.getClients(includeAll).pipe(
                map((organisations: OrganisationTitle[]) => new fromActions.GetClientsToShareWithSuccess(organisations)),
                catchError(() => of(new fromActions.GetClientsToShareWithFail()))))));

    constructor(
        private actions$: Actions,
        private groupSharingService: GroupSharingService) { }
}