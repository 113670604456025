<app-card-display [cardTitle]="document.name" class="document-library-card">
    <div menu-items>
        <button mat-menu-item title="Download" (click)="downloadDocument()" class="download-button">
            <mat-icon aria-label="Download">archive</mat-icon>
            <span>Download</span>
        </button>
        <button mat-menu-item *ngIf="document.isSignatory" (click)="signDocument()" [title]="'Sign ' + document.name">
            <mat-icon>assignment_turned_in</mat-icon>
            <span>Sign</span>
        </button>
        <button mat-menu-item *ngIf="document.canDelete" (click)="deleteDocument()" [title]="'Delete ' + document.name">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
        </button>
    </div>

    <mat-card-content card-content>
        <div class="document-card-content">
            <div class="card-header">
                <div class="title-container">
                    <div class="subtitle">{{document.ownerOrganisation.title}}</div>
                </div>
            </div>

            <div class="separator"></div>

            <div class="signature-status" [ngClass]="signatureStatusClass">
                {{ signatureStatus }}
            </div>

            <div class="button-section">
                <button mat-raised-button class="small call-to-action" (click)="openDocument()">{{openButtonTitle}}</button>
            </div>
        </div>
    </mat-card-content>
</app-card-display>

