import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFeature from 'app/portal/modules/document-library-v1/store/reducers';
import * as fromOrganisationDocument from 'app/portal/modules/document-library-v1/store/reducers/organisation-document.reducer';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getDocumentsFeatureState = createFeatureSelector<fromFeature.DocumentsState>(StoreNames.documentLibrary);
const getOrganisationDocumentState = createSelector(getDocumentsFeatureState, (state: fromFeature.DocumentsState) => state.organisationDocumentState);

export const getCommonDocuments = createSelector(getOrganisationDocumentState, (state: fromOrganisationDocument.OrganisationDocumentState) => state.commonDocuments);
