import { Pipe, PipeTransform } from '@angular/core';

// enums
import { FileExtensions } from 'app/shared/enums/file-extensions.enum';
import { isDocument, isImageType } from 'app/shared/enums/item-type.enum';

// models
import { AssessmentProfileItem } from 'app/shared/modules/set-observations/models/responses/assessment-profile-item-response.model';
import { AssessmentProfileObservation } from 'app/shared/modules/set-observations/models/responses/assessment-profile-observation-response.model';

@Pipe({ name: 'canShowObservationImage' })
export class CanShowObservationImagePipe implements PipeTransform {
    private imageExtensions = [
        FileExtensions.Jpeg,
        FileExtensions.Jpg,
        FileExtensions.Png,
        FileExtensions.Gif
    ];

    transform(observation: AssessmentProfileObservation, item: AssessmentProfileItem) {
        return observation && item && (isImageType(item.type) || this.isDocumentWithValidImage(observation, item));
    }

    private isDocumentWithValidImage(observation: AssessmentProfileObservation, item: AssessmentProfileItem): boolean {
        return isDocument(item.type) && observation.value && !!this.imageExtensions.find(fe => observation.value.toLocaleLowerCase().endsWith(`.${fe}`));
    }
}