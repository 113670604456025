import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { EmailTemplateResponse } from 'app/shared/models/email-template-response.model';

@Injectable()
export class EmailTemplatesService {

    private baseUrl = '/api/organisations';

    constructor(private http: HttpClient) { }

    getEmailTemplates(organisationId: string, isPublic: boolean): Observable<EmailTemplateResponse[]> {
        const url = `${this.baseUrl}/${organisationId}/sendgrid-templates?isPublic=${isPublic}`;
        return this.http.get<EmailTemplateResponse[]>(url);
    }
}