import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { QrCodeDialogComponent } from 'app/shared/modules/mobile-photo/components/qr-code-dialog/qr-code-dialog.component';

// ngrx | rxjs
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// store
import * as fromIdv from 'app/shared/modules/identity-verification/store';
import * as fromRoot from 'app/store';

// models
import { IdDocumentResponse } from 'app/shared/modules/set-observations/models/responses/id-document-response.model';
import { IdentityVerificationConfiguration } from 'app/shared/modules/identity-verification/models/identity-verification-configuration.model';
import { IdentityVerificationResponse } from 'app/shared/modules/identity-verification/models/identity-verification-response.model';
import { IdentityVerificationRequest } from 'app/shared/modules/identity-verification/models/identity-verification-request.model';
import { QrCodeData } from 'app/shared/modules/mobile-photo/models/qr-code-data.model';

// enums
import { CheckResult } from 'app/shared/enums';
import { IdDocumentType } from 'app/shared/enums/id-document-type.enum';
import { IdentityVerificationType } from 'app/shared/modules/identity-verification/enumerations/identity-verification-type.enum';
import { ItemType } from 'app/shared/enums/item-type.enum';

@Component({
    selector: 'app-identity-verification',
    templateUrl: './identity-verification.component.html',
    styleUrls: ['./identity-verification.component.scss']
})
export class IdentityVerificationComponent extends BaseComponent implements OnInit {

    static switchedToMobile = false;

    @Input()
    idvData: IdentityVerificationResponse;

    @Input()
    setId: string;

    @Input()
    itemId: string;

    @Input()
    observationId: string;

    @Input()
    organisationId: string;

    @Input()
    configuration: IdentityVerificationConfiguration;

    isSwitchToMobileMode = false;
    token: string;

    recordingSelfie$: Observable<boolean>;
    recordingUap$: Observable<boolean>;
    recordingIdDocument$: Observable<boolean>;
    idDocumentAttempts = 0;

    private selfieStep = 0;
    private selfieConfirmStep = 1;
    private uapStep = 2;
    private uapConfirmStep = 3;
    private idDocumentStep = 4;
    private idDocumentConfirmStep = 5;
    private idDocumentRejectedStep = 6;
    private completeStep = 7;

    stepIndex = -1;
    selfieBase64Image: string;
    uapBase64Image: string;
    idDocumentBase64Image: string;
    selectedDocumentType: IdDocumentType;
    isMobileDevice: boolean;
    showRetakeButtons = true;

    constructor(
        private rootStore: Store<fromRoot.State>,
        private store: Store<fromIdv.IdentityVerificationStoreState>,
        private actionsSubject: ActionsSubject,
        private dialogs: MatDialog,
        private route: ActivatedRoute) {
            super();
    }

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParams.token;
        this.isSwitchToMobileMode = this.token !== undefined;

        if (this.isSwitchToMobileMode) {
            this.showRetakeButtons = false;
            this.store.dispatch(fromIdv.GetIdvConfiguration({ setId: this.setId, itemId: this.itemId, observationId: this.observationId, token: this.token}));
            this.store.dispatch(fromIdv.GetIdvData({ observationId: this.observationId, token: this.token}));
            this.store.pipe(
                takeUntil(this.ngUnsubscribe),
                select(fromIdv.getIdentityVerification))
                .subscribe((idvData: IdentityVerificationResponse) => this.idvData = idvData);

            this.store.pipe(
                takeUntil(this.ngUnsubscribe),
                select(fromIdv.getConfiguration))
                .subscribe((configuration: IdentityVerificationConfiguration) => {
                    this.configuration = configuration;
                    if (this.configuration) {
                        this.setStartingStep();
                    }
                });
        } else {
            this.store.dispatch(fromIdv.SetIdvData({ idvData: this.idvData }));
            if (!this.idvData) {
                this.showRetakeButtons = false;
            } else {
                this.showRetakeButtons = !IdentityVerificationComponent.switchedToMobile;
            }

            IdentityVerificationComponent.switchedToMobile = false;
            this.setStartingStep();
        }

        this.recordingSelfie$ = this.store.select(fromIdv.getRecordingSelfie);
        this.recordingUap$ = this.store.select(fromIdv.getRecordingUap);
        this.recordingIdDocument$ = this.store.select(fromIdv.getRecordingIdDocument);

        this.rootStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromRoot.isMobileDevice))
            .subscribe((isMobileDevice: boolean) => this.isMobileDevice = isMobileDevice);

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            ofType(fromIdv.AddSelfieSuccess))
            .subscribe(action => {
                this.idvData = action.response;
                this.moveNext();
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            ofType(fromIdv.AddUapSuccess))
            .subscribe(action => {
                this.idvData = action.response;
                this.moveNext();
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            ofType(fromIdv.AddIdDocumentSuccess))
            .subscribe(action => {
                this.idvData = action.response;

                const retryCount = this.configuration.idDocumentRetries;

                if (this.idDocumentAttempts >= retryCount) {
                    this.moveNext();
                } else {
                    // Grab the latest document and check the status
                    let latestDocument: IdDocumentResponse;
                    this.idvData.idDocuments.forEach(idDocument => {
                        if (!latestDocument || idDocument.dateCreated > latestDocument.dateCreated) {
                            latestDocument = idDocument;
                        }
                    });

                    if (this.idDocumentAttempts === this.configuration.idDocumentRetries) {
                        this.moveNext();
                    } else if (this.idDocumentAttempts === 1 && latestDocument.documentStatus === CheckResult.Pass) {
                        this.moveNext();
                    } else if (this.idDocumentAttempts === 2 && (latestDocument.documentStatus === CheckResult.Pass || latestDocument.documentStatus === CheckResult.Refer)) {
                        this.moveNext();
                    } else {
                        this.stepIndex = this.idDocumentRejectedStep;
                    }
                }
            });
    }

    handleSelfieTaken(base64Image: string): void {
        this.selfieBase64Image = base64Image;
        this.moveNext();
    }

    handleSelfieAccepted(): void {
        const request: IdentityVerificationRequest = {
            base64File: this.selfieBase64Image,
            idDocumentType: undefined,
            itemId: this.itemId,
            observationId: this.observationId,
            organisationId: this.organisationId,
            type: IdentityVerificationType.Selfie
        };

        this.store.dispatch(fromIdv.AddSelfie({ request, token: this.token }));
    }

    handleSelfieRejected(): void {
        this.movePrevious();
    }

    handleUapTaken(base64Image: string): void {
        this.uapBase64Image = base64Image;
        this.moveNext();
    }

    handleUapAccepted(): void {
        const request: IdentityVerificationRequest = {
            base64File: this.uapBase64Image,
            idDocumentType: undefined,
            itemId: this.itemId,
            observationId: this.observationId,
            organisationId: this.organisationId,
            type: IdentityVerificationType.Uap
        };

        this.store.dispatch(fromIdv.AddUap({ request, token: this.token }));
    }

    handleUapRejected(): void {
        this.movePrevious();
    }

    handleIdDocumentTypeSelected(idDocumentType: IdDocumentType): void {
        this.selectedDocumentType = idDocumentType;
    }

    handleIdDocumentTaken(base64Image: string): void {
        this.idDocumentBase64Image = base64Image;
        this.moveNext();
    }

    handleIdDocumentAccepted(): void {
        this.idDocumentAttempts++;
        const request: IdentityVerificationRequest = {
            base64File: this.idDocumentBase64Image,
            idDocumentType: this.selectedDocumentType,
            itemId: this.itemId,
            observationId: this.observationId,
            organisationId: this.organisationId,
            type: IdentityVerificationType.IdDocument
        };

        this.store.dispatch(fromIdv.AddIdDocument({ request, token: this.token }));
    }

    handleIdDocumentRejected(): void {
        this.movePrevious();
    }

    handleRetakeIdDocument(): void {
        this.showRetakeButtons = false;
        this.stepIndex = this.idDocumentStep;
    }

    handleRetakeSelfieClicked(): void {
        this.showRetakeButtons = false;
        this.stepIndex = this.selfieStep;
    }

    handleAddIdDocumentClicked(): void {
        this.showRetakeButtons = false;
        this.idDocumentAttempts = 0;
        this.stepIndex = this.idDocumentStep;
    }

    handleSwitchToMobile(): void {
        IdentityVerificationComponent.switchedToMobile = true;
        this.dialogs.open(QrCodeDialogComponent, {
            disableClose: true,
            data: new QrCodeData(this.organisationId, this.setId, this.itemId, this.observationId, ItemType.IdentityVerification, this.stepIndex.toString())
        });
    }

    private setStartingStep(): void {
        if (this.route.snapshot.queryParams.step) {
            this.stepIndex = parseInt(this.route.snapshot.queryParams.step, 10);
            return;
        }

        if (this.configuration.captureSelfie && (!this.idvData || !this.idvData.hasSelfie)) {
            this.stepIndex = this.selfieStep;
        } else if (this.configuration.captureSelfie && !this.idvData.passiveLivenessPassed && !this.idvData.hasUap) {
            this.stepIndex = this.uapStep;
            return;
        } else if (this.configuration.captureIdDocuments && (!this.idvData || this.idvData.idDocuments.length === 0)) {
            this.stepIndex = this.idDocumentStep;
        } else {
            this.stepIndex = this.completeStep;
        }
    }

    private moveNext(): void {
        switch (this.stepIndex) {
            case this.selfieStep:
                if (this.isMobileDevice) {
                    this.handleSelfieAccepted();
                }

                this.stepIndex = this.selfieConfirmStep;
                break;
            case this.selfieConfirmStep:
                if (!this.configuration.usePassiveLiveness || !this.idvData.passiveLivenessPassed) {
                    this.stepIndex = this.uapStep;
                } else {
                    if (!this.configuration.captureIdDocuments || this.idvData.idDocuments.length > 0) {
                        this.stepIndex = this.completeStep;
                    } else {
                        this.stepIndex = this.idDocumentStep;
                    }
                }
                break;
            case this.uapStep:
                if (this.isMobileDevice) {
                    this.handleUapAccepted();
                }

                this.stepIndex = this.uapConfirmStep;
                break;
            case this.uapConfirmStep:
                if (!this.configuration.captureIdDocuments || this.idvData.idDocuments.length > 0) {
                    this.stepIndex = this.completeStep;
                } else {
                    this.stepIndex = this.idDocumentStep;
                }
                break;
            case this.idDocumentStep:
                if (this.isMobileDevice) {
                    this.handleIdDocumentAccepted();
                }

                this.stepIndex = this.idDocumentConfirmStep;
                break;
            case this.idDocumentConfirmStep:
                this.stepIndex = this.completeStep;
                break;
        }
    }

    private movePrevious(): void {
        switch (this.stepIndex) {
            case this.selfieConfirmStep:
                this.stepIndex = this.selfieStep;
                break;
            case this.uapStep:
                this.stepIndex = this.selfieStep;
                break;
            case this.uapConfirmStep:
                this.stepIndex = this.uapStep;
                break;
            case this.idDocumentStep:
                this.stepIndex = this.uapStep;
                break;
            case this.idDocumentConfirmStep:
                this.stepIndex = this.idDocumentStep;
                break;
        }
    }
}