import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/shared/modules/terms-obsolete/store/reducers';
import { TermsOverviewState } from 'app/shared/modules/terms-obsolete/store/reducers/terms-overview.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

// States
const getTermsManagementState = createFeatureSelector<fromFeature.TermsManagementState>(StoreNames.termsManagement);
const getTermsOverviewState = createSelector(getTermsManagementState, (state: fromFeature.TermsManagementState) => state.termsOverviewState);

// Selectors
export const getTermsAccepted = createSelector(getTermsOverviewState, (state: TermsOverviewState) => state.termsAccepted);
export const getTermsLoading = createSelector(getTermsOverviewState, (state: TermsOverviewState) => state.termsLoading);
export const getTermsOverview = createSelector(getTermsOverviewState, (state: TermsOverviewState) => state.termsOverview);
export const getMyTermsOverview = createSelector(getTermsOverviewState, (state: TermsOverviewState) => state.myTermsOverview);