// Store
import * as TermsOverviewActions from 'app/shared/modules/terms-obsolete/store/actions/terms-overview.actions';

// Models
import { TermsOverview } from 'app/shared/modules/terms-obsolete/models/responses/terms-overview.model';
import { TermsOverviewUtility } from 'app/shared/modules/terms-obsolete/utils/terms-overview.utility';

export type Action = TermsOverviewActions.AllTermsOverviewActions;

export interface TermsOverviewState {
    termsAccepted: boolean;
    termsLoading: boolean;
    termsOverview: TermsOverview;
    myTermsOverview: TermsOverview;
}

const initialState: TermsOverviewState = {
    termsAccepted: false,
    termsLoading: false,
    termsOverview: null,
    myTermsOverview: null,
};

export function reducer(state = initialState, action: Action): TermsOverviewState {
    switch (action.type) {
        case TermsOverviewActions.ACCEPT_PENDING_TERMS_SUCCESS: {
            return {
                ...state,
                termsAccepted: true
            };
        }

        case TermsOverviewActions.GET_TERMS_OVERVIEW:
        case TermsOverviewActions.GET_TERMS_OVERVIEW_ADMIN:
        case TermsOverviewActions.GET_TERMS_OVERVIEW_FAIL: {
            return {
                ...state,
                termsLoading: true,
                termsOverview: null
            };
        }

        case TermsOverviewActions.GET_TERMS_OVERVIEW_SUCCESS: {
            return {
                ...state,
                termsLoading: false,
                termsOverview: action.payload
            };
        }

        case TermsOverviewActions.GET_MY_TERMS_OVERVIEW:
        case TermsOverviewActions.GET_MY_TERMS_OVERVIEW_FAIL: {
            return {
                ...state,
                termsLoading: false,
                myTermsOverview: null
            };
        }

        case TermsOverviewActions.GET_MY_TERMS_OVERVIEW_SUCCESS: {
            return {
                ...state,
                myTermsOverview: action.payload
            };
        }

        case TermsOverviewActions.RESET_STATE: {
            return {
                ...state,
                termsAccepted: false,
                termsOverview: null,
                myTermsOverview: null,
            };
        }

        case TermsOverviewActions.ACCEPT_PENDING_TERM_SUCCESS: {
            if (!state.myTermsOverview) {
                return state;
            }

            const newTermsOverview: TermsOverview = {
                ...state.myTermsOverview,
                systemTerms: TermsOverviewUtility.tryUpdateTermsInCollection(state.myTermsOverview.systemTerms, action.payload)
            };

            return {
                ...state,
                myTermsOverview: newTermsOverview
            };
        }
    }

    return state;
}
