import { SetResponse } from './responses/set-response.model';

export class SetSummary {
    id: string;
    name: string;
    ownerId: string;
    score: number;

    constructor(setResponse: SetResponse) {
        this.id = setResponse.id;
        this.name = setResponse.name;
        this.ownerId = setResponse.ownerId;
        this.score = setResponse.score;
    }
}