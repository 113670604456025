// Store
import * as actions from 'app/authentication/store/actions/auth.actions';

// Models
import { InviteDetails } from 'app/authentication/models/invite-details.model';
import { TemporaryPasswordReset } from 'app/authentication/models/temporary-password-reset.model';

// Enums
import { RegistrationStatus } from 'app/authentication/enums/registration-status.enum';

export interface LoggedInSessionState {
    working: boolean;
    authenticationMessage: string;
    inviteDetails: InviteDetails;
    registrationTermsText: string;
    registered: boolean;
    validating: boolean;
    registrationError: string;
    passwordResetOnLogin: TemporaryPasswordReset;
}

const initialState: LoggedInSessionState = {
    working: null,
    authenticationMessage: null,
    inviteDetails: null,
    registrationTermsText: null,
    registered: false,
    validating: false,
    registrationError: null,
    passwordResetOnLogin: null
};

export function reducer(state = initialState, action: actions.All): LoggedInSessionState {
    switch (action.type) {
        case actions.LOGIN: {
            return {
                ...state,
                working: true,
                authenticationMessage: null
            };
        }
        case actions.LOGIN_FAIL: {
            return {
                ...state,
                working: false,
                authenticationMessage: action.payload
            };
        }

        case actions.LOGIN_SUCCESS:
        case actions.REHYDRATE_USER_SUCCESS: {
            return {
                ...state,
                working: false
            };
        }
        case actions.GET_REGISTRATION_TERM_SUCCESS: {
            return {
                ...state,
                registrationTermsText: action.payload
            };
        }
        case actions.LOGOUT: {
            return {
                ...state,
                working: false,
                registered: false
            };
        }
        case actions.GET_INVITE_DETAILS:
        case actions.GET_INVITE_DETAILS_FAIL: {
            return {
                ...state,
                inviteDetails: null
            };
        }
        case actions.GET_INVITE_DETAILS_SUCCESS: {
            return {
                ...state,
                inviteDetails: action.payload
            };
        }
        case actions.REGISTER_SUCCESS: {
            return {
                ...state,
                registered: true
            };
        }
        case actions.CLEAR_REGISTRATION_STATE: {
            return {
                ...state,
                registered: false
            };
        }

        case actions.VALIDATE_EMAIL_ADDRESS:
        case actions.VALIDATE_REGISTRATION_CODE: {
            return {
                ...state,
                validating: true,
                registrationError: null
            };
        }

        case actions.VALIDATE_REGISTRATION_CODE_SUCCESS: {
            return {
                ...state,
                validating: false,
                registrationError: action.payload === RegistrationStatus.Invalid ? 'The registration code is not valid' : null
            };
        }

        case actions.VALIDATE_EMAIL_ADDRESS_SUCCESS: {
            return {
                ...state,
                validating: false,
                registrationError: action.payload ? null : 'The email address has already been registered'
            };
        }

        case actions.PASSWORD_RESET_REQUIRED: {
           return {
               ...state,
               working: false,
               passwordResetOnLogin: action.payload
           };
       }

       case actions.CLEAR_PASSWORD_RESET_STATE: {
           return {
               ...state,
               passwordResetOnLogin: null
           };
       }

        default: {
            return state;
        }
    }
}

