import { EventEmitter, Input, OnInit, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ItemObservation } from 'app/shared/modules/set-observations/models/item-observation.model';
import { Item } from 'app/shared/modules/set-observations/models/item.model';
import { BaseComponent } from 'app/shared/base/base-component';
import { ImageView } from 'app/shared/models/images/image-view.model';
import { QrCodeData } from 'app/shared/modules/mobile-photo/models/qr-code-data.model';

@Component({ template: '' })
export abstract class ObservationComponent extends BaseComponent implements OnInit {

    @Input()
    item: Item;

    @Input()
    itemObservation: ItemObservation;

    @Input()
    form: FormGroup;

    valueUpdated: EventEmitter<any> = new EventEmitter();
    selectedChanged: EventEmitter<any> = new EventEmitter();
    valueDeleted: EventEmitter<any> = new EventEmitter();

    constructor() {
        super();
    }

    abstract init(): void;

    ngOnInit(): void {
        this.init();
    }

    updateValue(value: string): void {
        this.itemObservation.setValue(value);
        this.valueUpdated.emit(this.itemObservation);
    }

    updateNumberValue(value: number): void {
        this.itemObservation.setValue(value);
        this.valueUpdated.emit(this.itemObservation);
    }

    updateImageValue(value: ImageView): void {
        if (!value) {
            this.itemObservation.clearFile();
            this.valueUpdated.emit(this.itemObservation);
        } else {
            this.itemObservation.setFile(value.uploadedBase64, value.description, 'image/jpeg');
            this.valueUpdated.emit(this.itemObservation);
        }
    }

    updateFileValue(value: File): void {
        if (!value) {
            this.itemObservation.clearFile();
            this.valueUpdated.emit(this.itemObservation);
        } else {
            this.getBase64(value).then((base64File: string) => {
                const data = base64File.slice(base64File.indexOf('base64,') + 7);
                this.itemObservation.setFile(data, value.name, 'application/octet-stream');
                this.valueUpdated.emit(this.itemObservation);
            });
        }
    }

    updateMetaData(key: string, value: string): void {
        this.itemObservation.updateMetaData(key, value);
    }

    getBase64(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    setSelected(): void {
        this.selectedChanged.emit(this.item.id);
    }

    setValueDeleted(): void {
        this.valueDeleted.emit(this.itemObservation);
    }

    getQrCodeData(): QrCodeData {
        return new QrCodeData(
            this.itemObservation.ownerId,
            this.item.setId,
            this.item.id,
            this.itemObservation.observationId,
            this.item.type);
    }
}