import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileSaverModule } from 'ngx-filesaver';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { EffectsModule } from '@ngrx/effects';
import { effects, reducers } from './store';
import { StoreModule } from '@ngrx/store';

// feature components
import { SharedModule } from 'app/shared/shared.module';
import { DocumentManagementRoutingModule } from './document-management-routing.module';
import { ConnectModule } from 'app/connect/connect.module';
import { RichTextEditorModule } from 'app/shared/modules/rich-text-editor/rich-text-editor.module';

// components
import { TemplateIndexComponent } from './components/template-index/template-index.component';
import { TemplateDialogComponent } from './dialogs/template-dialog/template-dialog.component';
import { DocumentCardComponent } from './components/document-card/document-card.component';
import { TemplatePreviewDialogComponent } from './dialogs/template-preview-dialog/template-preview-dialog.component';
import { TemplateFileUploadComponent } from './components/template-file-upload/template-file-upload.component';

// services
import { TemplateService } from './services';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        DocumentManagementRoutingModule,
        FileSaverModule,
        RichTextEditorModule,
        PdfJsViewerModule,
        EffectsModule.forFeature(effects),
        StoreModule.forFeature(StoreNames.documents, reducers),
        ConnectModule
    ],
    declarations: [
        DocumentCardComponent,
        TemplateIndexComponent,
        TemplateDialogComponent,
        TemplateFileUploadComponent,
        TemplatePreviewDialogComponent
    ],
    providers: [
        TemplateService
    ],
    entryComponents: [
        TemplateDialogComponent,
        TemplatePreviewDialogComponent
    ]
})
export class DocumentManagementModule {}
