import { ApplicationEffects } from './application.effects';
import { BrandingEffects } from './branding.effects';
import { BreadcrumbEffects } from './breadcrumb.effects';
import { UserInterfaceEffects } from './user-interface.effects';
import { HelpFaqEffects } from './help-faq.effects';
import { TooltipsEffects } from './tooltip.effects';

export const effects: any[] = [
    ApplicationEffects,
    BrandingEffects,
    BreadcrumbEffects,
    UserInterfaceEffects,
    HelpFaqEffects,
    TooltipsEffects
];

export * from './application.effects';
export *  from './branding.effects';
export * from './breadcrumb.effects';
export * from './user-interface.effects';
export * from './help-faq.effects';
export * from './tooltip.effects';
