import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { UapImage } from 'app/shared/modules/set-observations/models/responses/uap-image.model';
import { UploadImageRequest } from 'app/shared/modules/mobile-photo/models/upload-image-request-model';

@Injectable()
export class MobilePhotoService {

    constructor(private http: HttpClient) { }

    getMobileToken$(organisationId: string): Observable<string> {
        const url = `api/mobile-token/${organisationId}`;
        return this.http.get<string>(url, { responseType: 'text' as 'json'});
    }

    checkMobileToken$(token: string): Observable<boolean> {
        const url = `api/mobile-token/valid?token=${token}`;
        return this.http.get<boolean>(url);
    }

    clearToken$(token: string): Observable<void> {
        const url = `api/mobile-token/?token=${token}`;
        return this.http.delete<void>(url);
    }

    // from observation controller - need to pass in token
    uploadImage$(token: string, request: UploadImageRequest): Observable<void> {
        const url = `api/observations/mobile/${request.observationId}?token=${token}`;
        return this.http.post<void>(url, request, { headers: { timeout: '600000' }});
    }

    // from observation controller - need to pass in token
    getUapImage$(token: string): Observable<UapImage> {
        const url = `api/observations/mobile-uap-image?token=${token}`;
        return this.http.get<UapImage>(url);
    }
}
