import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Components
import { BaseComponent } from 'app/shared/base/base-component';
import { LegalDialogsService } from 'app/shared/services/legal-dialogs.service';

@Component({
    selector: 'app-terms-acceptance-form',
    templateUrl: 'terms-acceptance-form.component.html',
    styleUrls: ['terms-acceptance-form.component.scss']
})
export class TermsAcceptanceFormComponent extends BaseComponent {
    @Input()
    form: FormGroup;

    constructor(private legalDialogsService: LegalDialogsService) {
        super();
    }

    openTerms() {
        this.legalDialogsService.openTerms();
    }

    openPrivacy() {
        this.legalDialogsService.openPrivacy();
    }
}