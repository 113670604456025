import { Injectable } from '@angular/core';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { interval, of, Subject, timer } from 'rxjs';
import { catchError, map, mapTo, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

// store
import * as fromMobilePhoto from 'app/shared/modules/mobile-photo/store';

// services
import { MobilePhotoService } from 'app/shared/modules/mobile-photo/services/mobile-photo.service';


@Injectable()
export class MobilePhotoEffects {

    private pollingUntil$: Subject<boolean> = new Subject<boolean>();

    getMobileToken$ = createEffect(() => this.actions$.pipe(
        ofType(fromMobilePhoto.GetMobileToken),
        mergeMap((action) => this.mobilePhotoService.getMobileToken$(action.organisationId).pipe(
                map(token => fromMobilePhoto.GetMobileTokenSuccess({ token })),
                catchError(() => of(fromMobilePhoto.GetMobileTokenFail()))
            ))
    ));

    pollAnswer$ = createEffect(() => this.actions$.pipe(
        ofType(fromMobilePhoto.PollMobileToken),
        switchMap(pollAction => interval(pollAction.pollIntervalMs).pipe(
            takeUntil(timer(pollAction.pollEndMs)),
            takeUntil(this.pollingUntil$),
            mapTo(pollAction),
            switchMap(() =>
                this.mobilePhotoService.checkMobileToken$(pollAction.token).pipe(
                map((tokenStillValid: boolean) => {
                    if (!tokenStillValid) {
                        this.pollingUntil$.next(true);
                    }
                    return tokenStillValid;
                })
                )
            ),
            map((tokenStillValid: boolean) => {
                if (!tokenStillValid) {
                    return fromMobilePhoto.MobileTokenUsed();
                } else {
                    return pollAction;
                }
            }),
            catchError(() => of(fromMobilePhoto.PollMobileTokenFail()))
            )),
        ));

    stopPolling$ = createEffect(() => this.actions$.pipe(
            ofType(fromMobilePhoto.StopPolling),
            tap(() => {
                this.pollingUntil$.next(true);
            })
        ), { dispatch: false });

    uploadImage$ = createEffect(() => this.actions$.pipe(
        ofType(fromMobilePhoto.UploadImage),
        mergeMap((action) => this.mobilePhotoService.uploadImage$(action.token, action.request).pipe(
                map(() => fromMobilePhoto.UploadImageSuccess()),
                catchError(() => of(fromMobilePhoto.UploadImageFail()))
            ))
    ));

    getUapImage$ = createEffect(() => this.actions$.pipe(
        ofType(fromMobilePhoto.GetUapImage),
        mergeMap(action => this.mobilePhotoService.getUapImage$(action.token).pipe(
                map(uapImage => fromMobilePhoto.GetUapImageSuccess({ uapImage })),
                catchError(() => of(fromMobilePhoto.GetUapImageFail()))
            ))
    ));

    clearMobileToken$ = createEffect(() => this.actions$.pipe(
        ofType(fromMobilePhoto.ClearMobileToken),
        mergeMap(action => this.mobilePhotoService.clearToken$(action.token).pipe(
                map(() => fromMobilePhoto.ClearMobileTokenSuccess()),
                catchError(() => of(fromMobilePhoto.ClearMobileTokenFail()))
            ))
    ));

    constructor(
        private actions$: Actions,
        private mobilePhotoService: MobilePhotoService) { }
}
