import { OnInit, OnDestroy, Component, AfterViewInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

// ngrx / rxjs
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

// store
import * as fromConnect from 'app/connect/store';

// Services
import { DeviceDetectionService } from './shared/services';
import { AuthenticationTokenService } from './shared/services/authentication-token.service';
import { GoogleTagManagerService } from './shared/services/google-tag-manager.service';

// models
import { LogoutOptions } from 'app/models/logout-options.model';
import { ModuleDetails } from './models/module-details.model';
import { Tooltip } from './connect/models/tooltip.model';

// enums
import { TooltipPage } from './connect/enums/tooltip-page.enum';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject();
    showHeader$: Observable<boolean>;

    private service: ModuleDetails;
    private tooltipsPage: TooltipPage;
    private tooltips: Tooltip[];
    private isTooltipsTour = false;
    private tooltipsLoaded = false;

    constructor(
        private deviceDetectionService: DeviceDetectionService,
        private titleService: Title,
        private metaService: Meta,
        private translate: TranslateService,
        private authenticationTokenService: AuthenticationTokenService,
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private router: Router,
        private locationStrategy: LocationStrategy,
        private gtmService: GoogleTagManagerService) { }

    ngOnInit(): void {
        this.showHeader$ = this.connectStore.select(fromConnect.getShowHeader);

        this.appInit();

        this.router.events
            .pipe(
                filter((event: NavigationEvent) => (event instanceof NavigationStart))
            )
            .subscribe((event: NavigationStart) => {
                if (event.restoredState) {
                    this.appInit();
                }
            });

        // Check if there is a token
        if (this.authenticationTokenService.getToken()) {
            // if the selected path is an auth page, dispatch the logout call
            // else rehydrate the user details from the token.
            if (window.location.pathname.startsWith('/auth')) {
                this.connectStore.dispatch(fromConnect.LogoutUser({options: new LogoutOptions(true, false, null)}));
            } else {
                this.connectStore.dispatch(fromConnect.RehydrateUser());
            }
        }

        // prevent BACK on browser to target auth pages
        this.locationStrategy.onPopState((locationChangeListener: any) => {
            const path = locationChangeListener.target.document.location.pathname;
            if (path.startsWith('/auth')) {
                history.go(1);
            }
        });

        this.setUpTooltips();
    }

    appInit(): void {
        this.deviceDetectionService.init();

        this.translate.setDefaultLang(`en-GB_${environment.translationPack}`);
        this.translate.onLangChange.subscribe(() => {
            this.updateTitle();
        });

        this.gtmService.addGoogleTagManager();
    }

    ngAfterViewInit(): void {
        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getTooltips))
            .subscribe((tooltips: Tooltip[]) => {
                this.tooltips = tooltips;

                if (this.tooltipsLoaded) {
                    setTimeout(() => {
                        if (this.tooltips === null || this.tooltips === undefined) {
                            return;
                        }

                        const firstTooltip = this.tooltips.find(t => document.getElementById(t.tag));

                        if (firstTooltip) {
                            this.connectStore.dispatch(fromConnect.SetTooltipVisibility({ id: firstTooltip.id, visible: true }));
                            this.connectStore.dispatch(fromConnect.CreateTooltipUserRecord({ tooltip: firstTooltip }));
                        } else {
                            if (this.tooltipsPage === TooltipPage.ClientPortalPage && TooltipPage[this.service?.id.valueOf()]) {
                                this.connectStore.dispatch(fromConnect.GetTooltips({ page: this.service.id.valueOf(), isTour: this.isTooltipsTour }));
                            }
                        }
                    }, 1000);
                }
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private updateTitle(): void {
        this.translate.get('titles.platform').subscribe((text: string) => {
            this.titleService.setTitle(text);
        });

        this.translate.get('descriptions.metaDescription').subscribe((text: string) => {
            this.metaService.updateTag({ name: 'description', content: text }, `name='description'`);
        });
    }

    private setUpTooltips() {
        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getService))
            .subscribe((service: ModuleDetails) => {
                this.service = service;

                if (service) {
                    this.connectStore.dispatch(fromConnect.GetTooltips({ page: TooltipPage.ClientPortalPage }));
                    this.connectStore.dispatch(fromConnect.SetTooltipsIsTour({ isTour: false }));
                }
            });

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getTooltipsPage))
            .subscribe((tooltipsPage: TooltipPage) => this.tooltipsPage = tooltipsPage);

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getTooltipsIsTour))
            .subscribe((isTour: boolean) => this.isTooltipsTour = isTour);

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getTooltipsLoaded))
            .subscribe((loaded: boolean) => this.tooltipsLoaded = loaded);
    }
}
