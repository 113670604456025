import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// ngrx
import { Store } from '@ngrx/store';

// store
import * as fromConnect from 'app/connect/store';

// models
import { TimeConstants } from 'app/connect/constants/time-constants.model';
import { LogoutOptions } from 'app/models/logout-options.model';

@Component({
    templateUrl: './session-timeout-dialog.component.html'
})
export class SessionTimeoutDialogComponent {

    public logoutTimer: NodeJS.Timeout;

    constructor(
        private store: Store<fromConnect.ConnectStoreState>,
        private dialog: MatDialogRef<SessionTimeoutDialogComponent>) {
            this.logoutTimer = setTimeout(() => {
                clearTimeout(this.logoutTimer);
                this.logoutTimer = null;
                this.onLogoutClicked();
            }, TimeConstants.LogoutTimeoutInMilliseconds);
        }

    onStayLoggedInClicked(): void {
        clearTimeout(this.logoutTimer);
        this.logoutTimer = null;

        this.dialog.close();
        this.store.dispatch(fromConnect.RefreshToken());
    }

    onLogoutClicked(): void {
        clearTimeout(this.logoutTimer);
        this.logoutTimer = null;

        this.dialog.close();
        const options = new LogoutOptions(true, true, null);
        this.store.dispatch(fromConnect.LogoutUser({ options }));
    }
}