export enum CheckResult {
    Unknown = 0,
    Pass = 1,
    Refer = 2,
    Fail = 3
}

export function getCheckResultTitle(checkResult: CheckResult): string {
    switch (checkResult) {
        case CheckResult.Pass: return 'Pass';
        case CheckResult.Refer: return 'Refer';
        case CheckResult.Fail: return 'Fail';
        default: return 'Unknown';
    }
}
