<div class="photo-input" fxLayout="column" fxLayoutGap="32px" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayoutAlign="start start" fxLayoutAlign.xs="start center">
    <div fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="column">
            <div>
                <ng-content></ng-content>
            </div>
            <div class="padding-8" *ngIf="!isMobileDevice && !settings.disableUpload" fxLayout="row" fxLayoutGap="8px">
                <button mat-raised-button *ngIf="stage === PhotoCaptureStage.Intro"  (click)="onUploadClicked()" aria-label="Upload">Upload</button>
                <button mat-raised-button *ngIf="webcamAccess && stage === PhotoCaptureStage.Intro" class="ghost"  [disabled]="!canContinue" (click)="onSubmitClicked()" aria-label="Use Webcam">Use Webcam</button>
                <button mat-raised-button *ngIf="stage === PhotoCaptureStage.Intro" class="ghost"  [disabled]="!canContinue" (click)="onSwitchToMobileClicked()" aria-label="Switch to Mobile">Use Mobile</button>
            </div>
            <div class="options" *ngIf="isMobileDevice && !settings.disableUpload">
                <button mat-raised-button  (click)="onUploadClicked()" aria-label="Upload">
                    Upload
                </button>
            </div>
        </div>
        <div class="confirm" [class.hide]="settings.alwaysHidePreview">
            <div fxLayout="column">
                <div *ngIf="showImagePreview" fxLayout="row">
                    <app-image-card
                        fxFlex
                        (imageDownloadClicked)="onDownloadImageClicked()"
                        (imageRemoveClicked)="onImageRemovedClicked()"
                        [canDownload]="downloadEnabled"
                        [canRemove]="removeEnabled"
                        [src]="imageSrc">
                    </app-image-card>
                </div>
                <div class="hide" fxLayout="row">
                    <canvas #canvasElement></canvas>
                </div>  
           </div>    
        </div>
    </div>
    <div class="error">
        <div *ngIf="invalidFileExtension">Invalid file type</div>
        <div *ngIf="invalidFileSize && !invalidFileExtension">Invalid file size, must be smaller than {{maximumFileSizeMb}}MB</div>
        <div *ngIf="processingError">An error occurred during image processing [{{processingErrorCode}}], please try again.</div>
    </div>

    
</div>

<div class="capture" [class.hide]="isMobileDevice || !cameraStarted" fxLayout="column" fxLayoutAlign="center center">
    <div class="capture-inner primary-bg" fxLayout="column" fxLayoutGap="48px">
        <div class="camera-container" fxFlex fxLayout="column">
            <video #videoDisplay autoplay class="camera" fxFlex>
            </video>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
            <button mat-raised-button *ngIf="stage === PhotoCaptureStage.Capture"  (click)="onBackClicked()" aria-label="Back">Back</button>
            <button mat-raised-button *ngIf="webcamAccess && stage === PhotoCaptureStage.Capture" [disabled]="!canContinue" (click)="onSubmitClicked()" aria-label="Take a Snap">Take a Snap</button>
            <button mat-raised-button *ngIf="webcamAccess && cameraStarted && multipleDevices" (click)="onSwitchWebcamClicked()" aria-label="Switch Camera">Switch Camera</button>
        </div>
        <input #imageUpload class="hide" [accept]="imageFileTypes" type="file" (change)="onUploadImage($event)" />
    </div>
</div>