import { Injectable } from '@angular/core';

// ngrx/rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';

// Store
import * as actions from 'app/store/actions/user-activity.actions';

// Services
import { UserActivityService } from 'app/shared/services/user-activity.service';

// Models
import { UserActivityDetails } from 'app/shared/models/user-activity-details.model';
import { UserActivityRequest } from 'app/shared/models/user-activity-request.model';


@Injectable()
export class UserActivityEffects {

    getOrganisationUserActivity$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(actions.GET_ORGANISATION_USER_ACTIVITY),
        map((action: actions.GetOrganisationUserActivity) => action.payload),
        switchMap((organisationId: string) =>
            this.userActivityService.getOrganisationActivity(organisationId).pipe(
                map((userActivity: UserActivityDetails[]) => new actions.GetActivitySuccess(userActivity)),
                catchError(() => of(new actions.GetActivityFail()))
            )
        )
    ));


    getUserActivity$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(actions.GET_USER_ACTIVITY),
        map((action: actions.GetUserActivity) => action.payload),
        switchMap((userId: string) =>
            this.userActivityService.getUserActivity(userId).pipe(
                map((userActivity: UserActivityDetails[]) => new actions.GetActivitySuccess(userActivity)),
                catchError(() => of(new actions.GetActivityFail()))
            )
        )
    ));


    logUserActivity$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(actions.LOG_USER_ACTIVITY),
        map((action: actions.LogUserActivity) => action.payload),
        switchMap((request: UserActivityRequest) => this.userActivityService.logUserActivity(request).pipe(
                map(() => new actions.LogUserActivitySuccess()),
                catchError(() => of(new actions.LogUserActivityFail()))
            ))));

    constructor(
        private actions$: Actions,
        private userActivityService: UserActivityService
    ) { }
}