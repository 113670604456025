export class MultiOptionValue {
    id: string;
    order: number;
    value: string;
    score: number;
    code: string;

    constructor(value: string, score: number, order: number, code: string, id: string = null) {
        this.id = id;
        this.value = value;
        this.score = score;
        this.order = order;
        this.code = code;
    }
}