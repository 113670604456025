// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Environment
import { environment } from 'environments/environment';

// Models
import { DocumentLibraryItem } from 'app/shared/modules/set-observations/models/document-library-item.model';
import { CreateDocumentRequest } from 'app/portal/modules/document-library-v1/models/create-document-request.model';
import { User } from 'app/shared/models/user.model';
import { setTimeoutHeader } from 'app/shared/utilities/request-utilities';

@Injectable()
export class DocumentLibraryService {
    private baseEndpoint = 'api/document-library/documents';

    constructor(private http: HttpClient) {}

    getAllDocuments(): Observable<DocumentLibraryItem[]> {
        const url = `${this.baseEndpoint}`;
        return this.http.get<DocumentLibraryItem[]>(url);
    }

    getOrganisationDocuments(organisationId: string): Observable<DocumentLibraryItem[]> {
        const url = `${this.baseEndpoint}/get-organisation-documents?organisationId=${organisationId}`;
        return this.http.get<DocumentLibraryItem[]>(url);
    }

    createDocument(request: CreateDocumentRequest) {
        const url = `${this.baseEndpoint}`;
        return this.http.post<DocumentLibraryItem[]>(url, request, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    deleteDocument(documentId: string): Observable<void> {
        const url = `${this.baseEndpoint}/${documentId}`;
        return this.http.delete<void>(url);
    }

    downloadDocument(documentId: string): Observable<Blob> {
        const url = `${this.baseEndpoint}/${documentId}/pdf`;
        return this.http.get<Blob>(url, { responseType: 'blob' as 'json', observe: 'response', headers: { timeout: environment.createSetsTimeout } })
            .pipe(map((response: HttpResponse<Blob>) => response.body));
    }

    getOrganisationUsers(organisationId: string): Observable<User[]> {
        const url = `api/organisations/${organisationId}/users`;
        return this.http.get<User[]>(url);
    }

    getEsignDocument(esignDocumentId: string): Observable<Blob> {
        const url = `${this.baseEndpoint}/${esignDocumentId}/esign-document`;
        return this.http.get<Blob>(url, { responseType: 'blob' as 'json', observe: 'response', headers: { timeout: environment.createSetsTimeout }  }).pipe(map((response: HttpResponse<Blob>) => response.body));
    }
}
