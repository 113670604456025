import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Ngrx
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

// Store
import * as fromAuthState from 'app/authentication/store/reducers';
import * as fromAuthActions from 'app/authentication/store/actions';
import * as fromAuth from 'app/authentication/store';

// enums
import { TermsType } from 'app/shared/enums/terms-type.enum';

@Component({
    templateUrl: './privacy-policy-dialog.component.html'
})
export class PrivacyPolicyDialogComponent extends BaseComponent implements OnInit {
    policyText: string = 'Loading, please wait...';

    constructor(public dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>,
        private store: Store<fromAuthState.AuthenticationState>
    ) {
        super();
    }

    ngOnInit() {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getRegistrationTermsText))
            .subscribe((policyText: string) => {
                if (policyText) {
                    this.policyText = policyText;
                }
            });

        this.store.dispatch(new fromAuthActions.GetRegistrationTerm(TermsType.PlatformPrivacyPolicy));
    }
}
