import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControlOptions } from '@angular/forms';

// Services
import { ValidationService } from 'app/shared/services';
import { AuthenticationService } from 'app/authentication/services/authentication.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// Models
import { BaseComponent } from 'app/shared/base/base-component';
import { AuthenticatedUser, RegExRules } from 'app/shared/models';
import { UserProfileUpdate, PasswordValidation, UserProfileUpdateResult } from 'app/authentication/models';

@Component({
    templateUrl: './user-profile-dialog.component.html'
})
export class UserProfileDialogComponent extends BaseComponent implements OnInit {

    userDetailsForm: FormGroup;
    passwordForm: FormGroup;
    user: AuthenticatedUser;
    regexRules: RegExRules = new RegExRules();

    hideCurrent: boolean = true;
    hideNew: boolean = true;
    hideConfirm: boolean = true;

    constructor(public dialogRef: MatDialogRef<UserProfileDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private authService: AuthenticationService,
        public validationService: ValidationService,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
    ) {
        super();
        this.user = data.user;
    }

    ngOnInit(): void {

        this.userDetailsForm = this.formBuilder.group({
            id: [''],
            firstname: ['', [Validators.required, Validators.pattern(this.regexRules.name), Validators.minLength(2), Validators.maxLength(60)]],
            surname: ['', [Validators.required, Validators.pattern(this.regexRules.name), Validators.minLength(2), Validators.maxLength(60)]]
        });

        this.passwordForm = this.formBuilder.group({
            currentPassword: [''],
            password: ['', [Validators.pattern(this.regexRules.password), Validators.minLength(9)]],
            confirmPassword: ['', [Validators.pattern(this.regexRules.password), Validators.minLength(9)]],
        }, <AbstractControlOptions>{
            validators: PasswordValidation.matchPassword
        });

        this.loadData();
    }

    saveDisabled(): boolean {
        return this.userDetailsForm.invalid || this.passwordForm.invalid || !this.passwordFieldsValid();
    }

    save() {
        const userProfileUpdate = this.getData();
        this.authService.updateUser(userProfileUpdate).subscribe((result: UserProfileUpdateResult) => {
            if (result.errorUpdatingPassword || result.errorUpdatingUser) {
                this.displayError(result);
            } else {
                this.alertService.success('Your account details have been updated.');
                this.dialogRef.close();
            }
        });
    }

    private displayError(result: UserProfileUpdateResult) {
        let errorMessage = '';
        if (result.errorUpdatingPassword) {
            errorMessage = `Unable to reset password: ${result.passwordUpdateMessage}`;
        }
        if (result.errorUpdatingUser) {
            errorMessage = `${errorMessage} ${result.errorUpdatingUser}`;
        }
        this.alertService.error(errorMessage);
    }

    private loadData() {
        this.userDetailsForm.get('id').setValue(this.user.id);
        this.userDetailsForm.get('firstname').setValue(this.user.firstName);
        this.userDetailsForm.get('surname').setValue(this.user.surname);
    }

    private getData(): UserProfileUpdate {
        const userProfile = new UserProfileUpdate();
        userProfile.id = this.userDetailsForm.get('id').value;
        userProfile.firstName = this.userDetailsForm.get('firstname').value;
        userProfile.surname = this.userDetailsForm.get('surname').value;
        userProfile.currentPassword = this.passwordForm.get('currentPassword').value;
        userProfile.newPassword = this.passwordForm.get('password').value;
        userProfile.updatePassword = this.passwordForm.dirty;
        return userProfile;
    }

    private passwordFieldsValid(): boolean {
        return (this.passwordHasValue('currentPassword') && this.passwordHasValue('password') && this.passwordHasValue('confirmPassword')) ||
               (!this.passwordHasValue('currentPassword') && !this.passwordHasValue('password') && !this.passwordHasValue('confirmPassword'));
    }

    private passwordHasValue(fieldName: string): boolean {
        return this.passwordForm.get(fieldName).value !== '';
    }
}
