import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

// Store
import { Store, select, ActionsSubject } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil, delay, filter } from 'rxjs/operators';
import * as fromStore from 'app/shared/modules/set-observations/store';

// Components
import { BaseComponent } from 'app/shared/base/base-component';
import { PagingNavigationComponent } from 'app/shared/components/paging-navigation/paging-navigation.component';
import { BadgeSummaryDialogComponent } from 'app/shared/modules/set-observations/components/badge-summary-dialog/badge-summary-dialog.component';
import { SetObservationsComponent } from 'app/shared/modules/set-observations/components/observations/set-observations/set-observations.component';

// Models
import { RecordObservationsRequest } from 'app/shared/modules/set-observations/models/requests/record-observations-request.model';
import { AssessmentProfileSetResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-set-response.model';
import { PagingNavigationPage } from 'app/shared/models/paging-navigation-page.models';
import { Item } from 'app/shared/modules/set-observations/models/item.model';
import { BadgeSummary } from 'app/shared/modules/set-observations/models/responses/badge-summary.model';
import { ReloadSetRequest } from 'app/shared/modules/set-observations/models/requests/reload-set-request.model';

// Enums
import { PagingNavigationPageType } from 'app/shared/enums/paging-navigation-page-type.enum';

// Utilities
import { ItemObservationMapperUtility } from 'app/shared/modules/set-observations/utils/item-observation-mapper.utility';

@Component({
    templateUrl: './assessment-set-dialog.component.html',
    styleUrls: ['./assessment-set-dialog.component.scss']
})

export class AssessmentSetDialogComponent extends BaseComponent implements OnInit {

    @ViewChild('setObservations') private setObservations: SetObservationsComponent;
    @ViewChild('paging', { static: true }) private paging: PagingNavigationComponent;
    @ViewChild('top', { static: true }) top: ElementRef;

    addingWorker = false;
    thirdPartySetId: string;
    ownerId: string;
    title: string;
    ownerTitle: string;
    entityName: string;
    currentSet: AssessmentProfileSetResponse;
    sets: AssessmentProfileSetResponse[];
    items: Item[];
    saving$: Observable<boolean>;
    inWizard: boolean;
    currentSetIndex: number = 0;
    observationDescription: string;
    initialisedPaging: boolean = false;
    backClicked: boolean = false;
    observationsLoaded: boolean = false;
    recordingImmediateObservation$: Observable<boolean>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogs: MatDialog,
        private dialogRef: MatDialogRef<AssessmentSetDialogComponent>,
        private actionsSubject: ActionsSubject,
        private store: Store<fromStore.SetObservationsState>) {

        super();

        this.entityName = data.entityName;
        this.ownerTitle = data.ownerTitle;
        this.sets = data.sets;

        this.sets = this.sets.sort((s1, s2) => {
            if (s1.order && s2.order) {
                return s1.order - s2.order;
            }

            if (s1.order) {
                return 1;
            }

            if (s2.order) {
                return -1;
            }

            return 0;
        });
    }

    ngOnInit(): void {
        this.recordingImmediateObservation$ = this.store.select(fromStore.getRecordingImmediateObservation);
        this.store.dispatch(new fromStore.ClearBadgeSummaries());

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            delay(0),
            select(fromStore.getItems))
            .subscribe((items: Item[]) => {
                this.items = items;

                if (!this.items) {
                    this.initialisedPaging = false;
                    this.paging.setPageCount(1);
                }

                if (this.items && !this.initialisedPaging) {
                    this.initialisedPaging = true;

                    let pageCount = 1;

                    for (const item of items) {
                        if (item.pageNumber > pageCount) {
                            pageCount = item.pageNumber;
                        }
                    }

                    this.paging.setPageCount(pageCount);

                    for (let i = 1; i <= pageCount; i++) {
                        this.setPageType(i);
                    }

                    this.updateTitle();

                    if (this.backClicked) {
                        this.backClicked = false;
                        this.paging.moveLast();
                    }

                } else if (this.initialisedPaging) {

                    for (let i = 1; i <= this.paging.pageCount; i++) {
                        this.setPageType(i);
                    }
                }
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            delay(0),
            select(fromStore.getLoaded))
            .subscribe((value: boolean) => {
                this.observationsLoaded = value;
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getBadgeSummaries))
            .subscribe((badgeSummaries: BadgeSummary[]) => {
                if (badgeSummaries && badgeSummaries.length) {
                    // display the badge summaries here
                    this.dialogs.open(BadgeSummaryDialogComponent, {
                        data: badgeSummaries,
                        disableClose: false
                    });
                }
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getBadgeSummariesRetrieved))
            .subscribe((badgesRetrieved: boolean) => {
                if (badgesRetrieved) {
                    this.dialogRef.close();
                }
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromStore.SAVE_SET_BEFORE_SWITCH_TO_MOBILE))
            .subscribe((action: fromStore.SaveSetBeforeSwitchToMobile) => {
                if (this.currentSet && this.currentSet.thirdPartyId === action.payload) {
                    this.saveObservations();
                }
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromStore.RELOAD_SET_REQUIRED))
            .subscribe((action: fromStore.ReloadSetRequired) => {
                if (this.currentSet && this.currentSet.thirdPartyId === action.payload) {
                    const currentPage = this.paging.currentPage.pageNumber;
                    this.store.dispatch(new fromStore.ReloadSet(new ReloadSetRequest(action.payload, currentPage)));
                }
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromStore.RELOAD_SET_SUCCESS))
            .subscribe((action: fromStore.ReloadSetSuccess) => {
               this.paging.moveToPage(action.payload);
            });

        this.saving$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getSaving));
        this.inWizard = this.sets.length > 1;
        this.editSet();
    }

    setPageType(pageNumber: number): void {
        if (this.items) {
            const pageItems = this.items.filter(io => io.isEditable() && io.pageNumber === pageNumber && io.enabled);
            const completeItems = pageItems.filter(io => io.hasValue());

            if (pageItems.length === completeItems.length) {
                this.paging.setPageType(pageNumber, PagingNavigationPageType.Info);
            } else {
                const incompleteMandatoryItems = pageItems.filter(io => !io.hasValue() && io.mandatory);
                if (incompleteMandatoryItems.length > 0) {
                    this.paging.setPageType(pageNumber, PagingNavigationPageType.Warning);
                } else {
                    this.paging.setPageType(pageNumber, PagingNavigationPageType.Standard);
                }
            }
        }
    }

    editSet(): void {
        this.currentSet = this.sets[this.currentSetIndex];
        this.ownerId = this.currentSet.ownerId;
        this.thirdPartySetId = this.currentSet.thirdPartyId;
        this.updateTitle();
    }

    updateTitle(): void {
        if (this.currentSet) {
            const pagingTitle = this.paging.pageCount > 1 ? ` (${this.paging.currentPage.pageNumber} of ${this.paging.pageCount})` : '';
            this.title = `${this.currentSet.name}${pagingTitle}`;
        }
    }

    handleNextClicked(): void {
        if (!this.canMovePage(true)) {
            this.saveAndClose();
            return;
        }

        this.saveObservations();

        if (!this.isLastPage()) {
            this.paging.moveNext();
        } else {
            this.currentSetIndex++;
            this.initialisedPaging = false;
            this.paging.moveFirst();
            this.editSet();
        }
    }

    handleBackClicked(): void {
        if (!this.canMovePage(false)) {
            this.saveAndClose();
            return;
        }

        this.saveObservations();

        if (!this.isFirstPage()) {
            this.paging.movePrevious();
        } else {
            this.currentSetIndex--;
            this.initialisedPaging = false;
            this.backClicked = true;
            this.editSet();
        }
    }

    saveAndClose(): void {
        if (this.saveObservations()) {
            this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getSaved)).subscribe((saved: boolean) => {
                if (saved) {
                    this.getBadgeSummaries();
                }
            });
        } else {
            this.getBadgeSummaries();
        }
    }

    onCloseClicked(): void {
        this.getBadgeSummaries();
    }

    isFirstSet(): boolean {
        return this.currentSetIndex === 0;
    }

    isLastSet(): boolean {
        return this.currentSetIndex === this.sets.length - 1;
    }

    isFirstPage(): boolean {
        return this.paging.isFirstPage;
    }

    isLastPage(): boolean {
        return this.paging.isLastPage;
    }

    saveObservations(): boolean {
        if (this.items) {
            const changedObservations = ItemObservationMapperUtility.setPaging(this.items);

            if (changedObservations.length === 0) {
                return false;
            }

            const request = new RecordObservationsRequest(
                this.thirdPartySetId,
                this.ownerId,
                this.ownerId,
                changedObservations);

            this.store.dispatch(new fromStore.SaveObservations(request));

            for (const changedObservation of changedObservations) {
                const item = this.items.filter(i => i.id === changedObservation.itemId)[0];
                item.setAsSaved();
            }

            return true;
        } else {
            return false;
        }
    }

    handlePageChanged(page: PagingNavigationPage): void {
        this.saveObservations();

        if (this.items && this.setObservations && page) {
            this.setObservations.setPage(page.pageNumber);
            this.updateTitle();
            this.top.nativeElement.closest('.dialog-content').scrollTop = 0;
        }
    }

    handleSaveClicked(): void {
        this.handleNextClicked();
    }

    onFormStatusChanged($event: boolean): void {
        this.paging.setInvalid($event);
    }

    get anyItemsChanged(): boolean {
        return this.items?.find(i => i.changed) !== undefined;
    }

    private canMovePage(directionForward: boolean): boolean {
        if (directionForward && this.isLastPage() && (this.isLastSet() || !this.inWizard)) {
            return false;
        } else if (!directionForward && this.isFirstPage() && (this.isFirstSet() || !this.inWizard)) {
            return false;
        }
        return true;
    }

    private getBadgeSummaries(): void {
        this.store.dispatch(new fromStore.GetBadgeSummaries(this.sets.map(s => s.id)));
    }
}