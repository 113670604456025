// Enums
import { ManageAssessmentSetDialogType } from 'app/shared/modules/group/enums/manage-assessment-set-dialog-type.enum';

export class UpdateAssessmentsRequest {
    type: ManageAssessmentSetDialogType;
    groupId: string;
    entityId: string;
    selectedAssessmentSetIds: string[];
    selectedBadgeIds: string[];

    constructor(
        type: ManageAssessmentSetDialogType,
        groupId: string,
        entityId: string,
        selectedAssessmentSetIds: string[],
        selectedBadgeIds: string[]) {

        this.type = type;
        this.groupId = groupId;
        this.entityId = entityId;
        this.selectedAssessmentSetIds = selectedAssessmentSetIds;
        this.selectedBadgeIds = selectedBadgeIds;
    }
}