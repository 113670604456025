<div id="authentication" fxLayout="row" fxLayoutAlign="start stretch">
    <div *ngIf="backgroundUrl$ | async" class="splash-panel" fxFlex fxLayout="column" fxLayoutAlign="start center" [style.background-image]="'url(' + (backgroundUrl$ | async) + ')'">
        <div fxFlex="25%"></div>
        <div class="splash-inner" fxLayout="column" fxFlex="75%" fxLayoutGap="64px">
            <div>
                <div class="secondary-text title">
                    {{title$ | async}}
                </div>
                <div class="sub-title">
                    {{tagLine$ | async}}
                </div>
            </div>
        </div>
        
    </div>
    
    <div fxFlex.lt-md="100%" fxFlex.gt-sm="480px">
        <ng-content></ng-content>
    </div>
    
</div>