import * as fromActions from 'app/portal/modules/document-library-v1/store/actions';

// Models
import { DocumentLibraryItem } from 'app/shared/modules/set-observations/models/document-library-item.model';

export interface OrganisationDocumentState {
    commonDocuments: DocumentLibraryItem[];
}

const initialState: OrganisationDocumentState = {
    commonDocuments: null,
};

export function reducer(state = initialState, action: fromActions.AllOrganisationDocumentActions): OrganisationDocumentState {
    switch (action.type) {
        case fromActions.GET_COMMON_DOCUMENTS: {
            return {
                ...state,
                commonDocuments: null
            };
        }
        case fromActions.GET_COMMON_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                commonDocuments: action.payload,
            };
        }
        case fromActions.GET_COMMON_DOCUMENTS_FAIL: {
            return {
                ...state,
                commonDocuments: null,
            };
        }
        default: {
            return state;
        }
    }
}
