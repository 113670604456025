import { Component, Input, ElementRef, AfterViewInit, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';

// ngrx/rxjs
import { select, Store } from '@ngrx/store';
import { fromEvent } from 'rxjs';
import { pairwise, switchMap, takeUntil } from 'rxjs/operators';

// store
import * as fromRoot from 'app/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

@Component({
    selector: 'app-canvas-input',
    templateUrl: './canvas-input.component.html',
    styleUrls: ['./canvas-input.component.scss']
})
export class CanvasInputComponent extends BaseComponent implements AfterViewInit, OnChanges {

    @ViewChild('canvas')
    canvas: ElementRef;

    @Input()
    color = '#68676c';

    @Output()
    imageChanged: EventEmitter<string> = new EventEmitter();

    private points = [];
    private cx: CanvasRenderingContext2D;
    private changeTimeout: any;

    private heightAndWidthNotAvailable = false;

    constructor(private store: Store<fromRoot.State>) {
        super();
    }

    ngOnChanges(): void {
        if (this.cx && this.cx.strokeStyle !== this.color) {
            this.cx.strokeStyle = this.color;
            this.redraw();
        }
    }

    ngAfterViewInit(): void {
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        this.cx = canvasEl.getContext('2d');

        this.cx.lineWidth = 2;
        this.cx.lineCap = 'round';
        this.cx.strokeStyle = this.color;

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromRoot.isMobileDevice))
            .subscribe((isMobileDevice: boolean) => this.captureEvents(canvasEl, isMobileDevice));

        fromEvent(window, 'resize').pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                setTimeout(() => {
                    this.setHeightAndWidth(canvasEl);
                    this.cx.strokeStyle = this.color;
                    this.redraw();
                }, 0);
            });

        this.setHeightAndWidth(canvasEl);
    }

    setHeightAndWidth(canvasEl: HTMLCanvasElement): void {
        // TODO: remove magic numbers
        if (this.heightAndWidthNotAvailable || !canvasEl.parentElement.clientWidth) {
            this.heightAndWidthNotAvailable = true;
            canvasEl.width = screen.width > 800 ? 752 : screen.width - 48;
            canvasEl.height = 200;
        } else {
            canvasEl.width = canvasEl.parentElement.clientWidth;
            canvasEl.height = canvasEl.parentElement.clientHeight <= 200 ? canvasEl.parentElement.clientHeight : 200;
        }
    }

    clear(): void {
        this.cx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        this.points = [];
        this.imageChanged.emit(null);
    }

    private changed(): void {
        clearTimeout(this.changeTimeout);

        this.changeTimeout = setTimeout(() => {
            this.imageChanged.emit(this.canvas.nativeElement.toDataURL());
        }, 500);
    }

    private captureEvents(canvasEl: HTMLCanvasElement, isMobileDevice: boolean): void {
        if (isMobileDevice) {
            this.captureMobileEvents(canvasEl);
        } else {
            this.captureDesktopEvents(canvasEl);
        }
    }

    private captureDesktopEvents(canvasEl: HTMLCanvasElement) {
        fromEvent(canvasEl, 'mousedown')
            .pipe(
                switchMap(() => fromEvent(canvasEl, 'mousemove').pipe(
                        takeUntil(fromEvent(canvasEl, 'mouseup')),
                        takeUntil(fromEvent(canvasEl, 'mouseleave')),
                        pairwise()
                    )))
            .subscribe((res: [MouseEvent, MouseEvent]) => {
                const rect = canvasEl.getBoundingClientRect();

                const prevPos = {
                    x: res[0].clientX - rect.left,
                    y: res[0].clientY - rect.top
                };

                const currentPos = {
                    x: res[1].clientX - rect.left,
                    y: res[1].clientY - rect.top
                };

                this.drawOnCanvas(prevPos, currentPos);
          });
        }

    private captureMobileEvents(canvasEl: HTMLCanvasElement) {

        fromEvent(canvasEl, 'touchstart', { passive: true })
            .pipe(
                switchMap(() => fromEvent(canvasEl, 'touchmove', { passive: true }).pipe(
                        takeUntil(fromEvent(canvasEl, 'touchend')),
                        pairwise()
                    )))
            .subscribe((res: [TouchEvent, TouchEvent]) => {
                const rect = canvasEl.getBoundingClientRect();

                const prevPos = {
                    x: res[0].touches[0].clientX - rect.left,
                    y: res[0].touches[0].clientY - rect.top
                };

                const currentPos = {
                    x: res[1].touches[0].clientX - rect.left,
                    y: res[1].touches[0].clientY - rect.top
                };

                this.drawOnCanvas(prevPos, currentPos);
          });
    }

    private drawOnCanvas(
        prevPos: { x: number; y: number },
        currentPos: { x: number; y: number }): void {

            this.points.push({ prevPos, currentPos });

            if (!this.cx) {
 return;
}

            this.cx.beginPath();

            if (prevPos) {
                this.cx.moveTo(prevPos.x, prevPos.y);
                this.cx.lineTo(currentPos.x, currentPos.y);
                this.cx.stroke();
                this.changed();
            }
    }

    private redraw(): void {
        if (!this.points || this.points.length === 0) {
            return;
        }

        const oldPoints = JSON.parse(JSON.stringify(this.points));
        this.clear();
        for (const oldPoint of oldPoints) {
            this.drawOnCanvas(oldPoint.prevPos, oldPoint.currentPos);
        }
    }
}
