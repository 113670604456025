<mat-form-field appearance="outline" class="observation-field" [formGroup]="form">
    <textarea matInput name="observation" 
        formControlName="observation" 
        (blur)="updateValue(textOnly.value)" 
        (focus)="setSelected()"
        autocomplete="off"
        cdkTextareaAutosize
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="10"
        #textOnly
        [readonly]="item.readonly"></textarea>
</mat-form-field>