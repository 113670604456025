import { Component, ViewChild } from '@angular/core';

// ngrx
import { Store, ActionsSubject, select } from '@ngrx/store';
import { takeUntil, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Store
import * as fromSetsStore from 'app/shared/modules/set-observations/store';

// Services
import { ImageUploadService } from 'app/shared/services';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';
import { PhotoCaptureComponent } from 'app/shared/components/photo-capture/photo-capture.component';

// Models
import { ImageView } from 'app/shared/models/images/image-view.model';
import { SetDocumentDownloadClick } from 'app/shared/models/set-document-download-click.model';
import { UapImage } from 'app/shared/modules/set-observations/models/responses/uap-image.model';
import { QrCodeData } from 'app/shared/modules/mobile-photo/models/qr-code-data.model';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-uap',
    templateUrl: './uap-observation.component.html',
    styleUrls: ['./uap-observation.component.scss'],
    outputs: ['valueUpdated', 'selectedChanged']
})
export class UapObservationComponent extends ObservationComponent {

    @ViewChild(PhotoCaptureComponent, { static: true }) imageUpload: PhotoCaptureComponent;

    capturedImage: ImageView;
    loading = false;
    uapImage: UapImage;
    recordingObservation$: Observable<boolean>;
    qrCodeData: QrCodeData;

    constructor(
        private store: Store<fromSetsStore.SetObservationsState>,
        private actionsSubject: ActionsSubject,
        private imagesService: ImageUploadService) {
        super();
    }

    init(): void {
        this.loading = true;
        this.recordingObservation$ = this.store.select(fromSetsStore.getRecordingImmediateObservation);

        if (this.itemObservation.ownerId) {
            this.store.dispatch(new fromSetsStore.GetUapImageUrl(this.itemObservation.ownerId));
        }

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromSetsStore.getUapImage))
            .subscribe((uapImage: UapImage) => this.uapImage = uapImage);

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromSetsStore.GET_DOCUMENT_SUCCESS))
            .subscribe((action: fromSetsStore.GetDocumentSuccess) => {
                if (action.payload.observationId === this.itemObservation.observationId) {
                    this.imageUpload.setImage(action.payload.document, this.itemObservation.value);
                }
            });

        if (this.itemObservation.hasValue()) {
            if (this.itemObservation.changed || this.itemObservation.base64File) {
                this.imageUpload.setImageBase64(`data:image/jpeg;base64,${this.itemObservation.base64File}`, this.itemObservation.value);
            } else {
                this.store.dispatch(new fromSetsStore.GetDocument(this.itemObservation.observationId));
            }
        }

        this.qrCodeData = this.getQrCodeData();
    }

    onDestroy() {
        if (this.imageUpload) {
            this.imageUpload.onDestroy();
        }
    }

    imageLoading(isLoading: boolean) {
        this.loading = isLoading;
    }

    imageUploaded(image: ImageView) {
        if (!image || !image.uploadedBase64) {
            image = null;
        }

        this.capturedImage = image;

        this.imagesService.compressFile(image, (newImage: ImageView) => {
            this.capturedImage = newImage;
            this.updateImageValue(this.capturedImage);
        });
    }

    imageDownloadClicked() {
        this.store.dispatch(new fromSetsStore.DownloadDocument(new SetDocumentDownloadClick(this.itemObservation.value, this.itemObservation.observationId)));
    }
}
