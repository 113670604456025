import { ActionReducerMap } from '@ngrx/store';

import * as fromGroupInvites from './group-invite.reducers';
import * as fromManageAssessmentSets from './manage-assessment-set.reducers';
import * as fromGroupListSharing from './group-list-sharing.reducers';
import * as fromOrganisationTags from './organisation-tag.reducers';
import * as fromOrganisationTagManagement from './organisation-management-tags.reducers';

export interface GroupsState {
    groupInviteState: fromGroupInvites.GroupInviteState;
    manageAssessmentSetState: fromManageAssessmentSets.ManageAssessmentSetState;
    groupListSharing: fromGroupListSharing.GroupListSharingState;
    organisationTagState: fromOrganisationTags.OrganisationTagState;
    organisationTagManagementState: fromOrganisationTagManagement.OrganisationManagementTagsState;
}

export const reducers: ActionReducerMap<GroupsState> = {
    groupInviteState: fromGroupInvites.reducer,
    manageAssessmentSetState: fromManageAssessmentSets.reducer,
    groupListSharing: fromGroupListSharing.reducer,
    organisationTagState: fromOrganisationTags.reducer,
    organisationTagManagementState: fromOrganisationTagManagement.reducer
};
