import { Action } from '@ngrx/store';

// Models
import { DocumentLibraryItem } from 'app/shared/modules/set-observations/models/document-library-item.model';
import { CreateDocumentRequest } from 'app/portal/modules/document-library-v1/models/create-document-request.model';
import { GeneratePreviewRequest } from 'app/portal/modules/document-library-v1/models/generate-preview-request.model';
import { OrganisationUsers } from 'app/portal/modules/document-library-v1/models/organisation-users.model';

// action types
export const GET_ALL_DOCUMENTS = '[Document Library] Get the documents from the document library';
export const GET_ALL_DOCUMENTS_SUCCESS = '[Document Library] Get the documents from the document library success';
export const GET_ALL_DOCUMENTS_FAIL = '[Document Library] Get the documents from the document library fail';

export const GET_ORGANISATION_DOCUMENTS = '[Document Library] Get the organisation documents from the document library';
export const GET_ORGANISATION_DOCUMENTS_SUCCESS = '[Document Library] Get the organisation documents from the document library success';
export const GET_ORGANISATION_DOCUMENTS_FAIL = '[Document Library] Get the organisation documents from the document library fail';


export const CREATE_DOCUMENT = '[Document Library] Create a new document';
export const CREATE_DOCUMENT_SUCCESS = '[Document Library] Create a new document success';
export const CREATE_DOCUMENT_FAIL = '[Document Library] Create a new document fail';

export const GENERATE_PREVIEW = '[Document Library] Generate preview';
export const GENERATE_PREVIEW_SUCCESS = '[Document Library] Generate preview success';
export const GENERATE_PREVIEW_FAIL = '[Document Library] Generate preview fail';

export const DELETE_DOCUMENT = '[Document Library] Delete a Document';
export const DELETE_DOCUMENT_SUCCESS = '[Document Library] Delete a Document success';
export const DELETE_DOCUMENT_FAIL = '[Document Library] Delete a Document fail';

export const DOWNLOAD_DOCUMENT = '[Document Library] Download a Document';
export const DOWNLOAD_DOCUMENT_SUCCESS = '[Document Library] Download a Document success';
export const DOWNLOAD_DOCUMENT_FAIL = '[Document Library] Download a Document fail';

export const GET_ORGANISATION_USERS = '[DocumentLibrary] Get the organisation users';
export const GET_ORGANISATION_USERS_SUCCESS = '[DocumentLibrary] Get the organisation users success';
export const GET_ORGANISATION_USERS_FAIL = '[DocumentLibrary] Get the organisation users fail';

export const GET_ESIGN_DOCUMENT = '[Document Library] Get the esign document';
export const GET_ESIGN_DOCUMENT_SUCCESS = '[Document Library] Get the esign document success';
export const GET_ESIGN_DOCUMENT_FAIL = '[Document Library] Get the esign document fail';

export class GetAllDocuments implements Action {
    readonly type = GET_ALL_DOCUMENTS;
}

export class GetAllDocumentsSuccess implements Action {
    readonly type = GET_ALL_DOCUMENTS_SUCCESS;

    constructor(public payload: DocumentLibraryItem[]) { }
}

export class GetAllDocumentsFail implements Action {
    readonly type = GET_ALL_DOCUMENTS_FAIL;
}

export class GetOrganisationDocuments implements Action {
    readonly type = GET_ORGANISATION_DOCUMENTS;
    constructor(public payload: string) { }
}

export class GetOrganisationDocumentsSuccess implements Action {
    readonly type = GET_ORGANISATION_DOCUMENTS_SUCCESS;

    constructor(public payload: DocumentLibraryItem[]) { }
}

export class GetOrganisationDocumentsFail implements Action {
    readonly type = GET_ORGANISATION_DOCUMENTS_FAIL;
}

export class CreateDocument implements Action {
    readonly type = CREATE_DOCUMENT;
    constructor(public payload: CreateDocumentRequest) { }
}

export class CreateDocumentSuccess implements Action {
    readonly type = CREATE_DOCUMENT_SUCCESS;
}

export class CreateDocumentFail implements Action {
    readonly type = CREATE_DOCUMENT_FAIL;
}

export class GeneratePreview implements Action {
    readonly type = GENERATE_PREVIEW;
    constructor(public payload: GeneratePreviewRequest) { }
}

export class GeneratePreviewSuccess implements Action {
    readonly type = GENERATE_PREVIEW_SUCCESS;
    constructor(public payload: Blob) { }
}

export class GeneratePreviewFail implements Action {
    readonly type = GENERATE_PREVIEW_FAIL;
}

export class DeleteDocument implements Action {
    readonly type = DELETE_DOCUMENT;
    constructor(public payload: string) { }
}

export class DeleteDocumentSuccess implements Action {
    readonly type = DELETE_DOCUMENT_SUCCESS;
    constructor(public payload: string) { }
}

export class DeleteDocumentFail implements Action {
    readonly type = DELETE_DOCUMENT_FAIL;
}

export class DownloadDocument implements Action {
    readonly type = DOWNLOAD_DOCUMENT;
    constructor(public payload: string) {}
}

export class DownloadDocumentSuccess implements Action {
    readonly type = DOWNLOAD_DOCUMENT_SUCCESS;
    constructor(public payload: Blob) {}
}

export class DownloadDocumentFail implements Action {
    readonly type = DOWNLOAD_DOCUMENT_FAIL;
}

export class GetOrganisationUsers implements Action {
    readonly type = GET_ORGANISATION_USERS;
    constructor(public payload: string) { }
}

export class GetOrganisationUsersSuccess implements Action {
    readonly type = GET_ORGANISATION_USERS_SUCCESS;
    constructor(public payload: OrganisationUsers) { }
}

export class GetOrganisationUsersFail implements Action {
    readonly type = GET_ORGANISATION_USERS_FAIL;
}

export class GetEsignDocument implements Action {
    readonly type = GET_ESIGN_DOCUMENT;
    constructor(public payload: string) { }
}

export class GetEsignDocumentSuccess implements Action {
    readonly type = GET_ESIGN_DOCUMENT_SUCCESS;
    constructor(public payload: Blob) { }
}

export class GetEsignDocumentFail implements Action {
    readonly type = GET_ESIGN_DOCUMENT_FAIL;
}

export type AllDocumentsActions
    = GetAllDocuments
    | GetAllDocumentsSuccess
    | GetAllDocumentsFail
    | GetOrganisationDocuments
    | GetOrganisationDocumentsSuccess
    | GetOrganisationDocumentsFail
    | CreateDocument
    | CreateDocumentSuccess
    | CreateDocumentFail
    | GeneratePreview
    | GeneratePreviewSuccess
    | GeneratePreviewFail
    | DeleteDocument
    | DeleteDocumentSuccess
    | DeleteDocumentFail
    | DownloadDocument
    | DownloadDocumentSuccess
    | DownloadDocumentFail
    | GetOrganisationUsers
    | GetOrganisationUsersSuccess
    | GetOrganisationUsersFail
    | GetEsignDocument
    | GetEsignDocumentSuccess
    | GetEsignDocumentFail;
