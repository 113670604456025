import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher, DateAdapter } from '@angular/material/core';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxImageCompressService } from 'ngx-image-compress';

// ngrx/rxjs
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, DefaultRouterStateSerializer } from '@ngrx/router-store';

// ngx-translate
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// Security Guards
import { AuthGuard, ModuleGuard, ShortUrlGuard } from './shared/guards';

// feature modules
import { AppRoutingModule } from './app-routing.module';

// feature components
import { AppComponent } from './app.component';

// services
import { AuthenticationService } from './authentication/services/authentication.service';
import { AuthenticationV2Service } from './authentication-v2/services/authentication-v2.service';
import { TermsService } from './shared/modules/terms-obsolete/services/terms.service';
import { AuthenticationTokenService } from './shared/services/authentication-token.service';
import { GlobalErrorHandler } from './shared/services/global-error-handler.service';

// store
import * as fromStore from './store';
import { effects } from './store/effects';
import { RouterEffects } from './store/effects/router.effects';
import { TermsEffects } from './shared/modules/terms-obsolete/store/effects/terms.effects';
import * as fromAuthreducers from './authentication/store/reducers';
import * as authEffects from 'app/authentication/store/effects';
import * as fromAuthV2reducers from './authentication-v2/store/reducers';
import * as authV2Effects from 'app/authentication-v2/store/effects';

// App Modules
// TODO CONNECT - Any area modules should not be being consumed like this - this will be rectified when all the modules are in the right place
import { DocumentManagementModule } from './portal/modules/document-management/document-management.module';
import { DocumentLibraryModule } from './portal/modules/document-library-v1/document-library.module';

import { TermsModule } from 'app/shared/modules/terms-obsolete/terms.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { AuthenticationV2Module } from './authentication-v2/authentication-v2.module';
import { SharedModule } from './shared/shared.module';
import { ConnectModule } from './connect/connect.module';

import { environment } from 'environments/environment';
import { GbDateAdapter } from 'app/shared/models/gb-date-adapter';
import { RequestInterceptor, DEFAULT_TIMEOUT } from './shared/interceptors/request-interceptor';
import { ContentComponent } from './shared/components/content/content.component';

// models
import { StoreNames } from './connect/models/store-names.model';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,

        // TODO CONNECT - When we rewrite/rework we need to make the stores properly immutable, then we can remove these overrides
        StoreModule.forRoot(fromStore.reducers, { runtimeChecks: { strictStateImmutability: false, strictActionSerializability: false, strictActionImmutability: false, strictStateSerializability: false }}),

        StoreModule.forFeature(StoreNames.authentication, fromAuthreducers.reducers),
        StoreModule.forFeature(StoreNames.authenticationV2, fromAuthV2reducers.reducers),
        StoreModule.forFeature(StoreNames.shared, fromStore.reducers),

        EffectsModule.forRoot([
            ...effects,
            ...authEffects.effects, // once all "sections" have their own store setup this will be the only one required in app
            ...authV2Effects.effects,
            RouterEffects,
            TermsEffects
        ]),
        environment.production === 'true'
            ? []
            : StoreDevtoolsModule.instrument({
                maxAge: 50 //  Retains last 50 states (used by Redux DevTools Chrome extension)
            }),
        AppRoutingModule,
        SharedModule,
        StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
        DocumentManagementModule,
        PdfJsViewerModule,
        TermsModule,
        AuthenticationModule,
        AuthenticationV2Module,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DocumentLibraryModule, // This is necessary as it is consumed by the set-observations module but also consumes that module
        ConnectModule
    ],
    declarations: [
        AppComponent,
        ContentComponent
    ],
    providers: [
        {
            provide: ErrorStateMatcher,
            useClass: ShowOnDirtyErrorStateMatcher
        },
        AuthGuard,
        AuthenticationService,
        AuthenticationV2Service,
        ModuleGuard,
        {
            provide: DateAdapter,
            useClass: GbDateAdapter
        },
        AuthenticationTokenService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
        {
            provide: DEFAULT_TIMEOUT,
            useValue: 120000
        },
        TermsService,
        Title,
        Meta,
        NgxImageCompressService,
        ShortUrlGuard,
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule { }

// required for AOT compilation (ngx-translate)
export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
