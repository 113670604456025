// Actions
import * as OrganisationTagActions from 'app/shared/modules/group/store/actions/organisation-tag.actions';
export type AllOrganisationTagActions = OrganisationTagActions.AllOrganisationTagActions;

import { Tag } from 'app/shared/models/entities/tag.model';

export interface OrganisationTagState {
    loading: boolean;
    loaded: boolean;
    saving: boolean;
    saved: boolean;
    tags: Tag[];
}

const initialState: OrganisationTagState = {
    loading: false,
    loaded: false,
    saving: false,
    saved: false,
    tags: null
};

export function reducer(state = initialState, action: AllOrganisationTagActions): OrganisationTagState {
    switch (action.type) {
        case OrganisationTagActions.GET_AVAILABLE_TAGS_SUCCESS: {
            return {
                ...state,
                loading: false,
                loaded: true,
                tags: [...action.payload]
            };
        }

        case OrganisationTagActions.GET_AVAILABLE_TAGS:
        case OrganisationTagActions.GET_AVAILABLE_TAGS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: true
            };
        }
    }

    return state;
}

export const getAvailableTags = (state: OrganisationTagState) => state.tags;
export const getLoading = (state: OrganisationTagState) => state.loading;
export const getLoaded = (state: OrganisationTagState) => state.loaded;
export const getSaving = (state: OrganisationTagState) => state.saving;
export const getSaved = (state: OrganisationTagState) => state.saved;