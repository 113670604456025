// Angular
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Models
import { AssessmentProfileBadgeResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-badge-response.model';
import { AssessmentProfileCategoryResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-category-response.model';
import { AssessmentProfileObservation } from 'app/shared/modules/set-observations/models/responses/assessment-profile-observation-response.model';
import { AssessmentProfileResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-response.model';
import { IdDocumentsResponse } from 'app/shared/modules/set-observations/models/responses/id-documents-response.model';
import { AssessmentProfileSetResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-set-response.model';
import { ExportFile } from 'app/shared/modules/zoho-export/models/export-file.model';
import { ZohoExportDialogOptions } from 'app/shared/modules/zoho-export/models/zoho-export-dialog-options.model';
import { AssessmentProfileItem } from 'app/shared/modules/set-observations/models/responses/assessment-profile-item-response.model';

// Enums
import { Modules } from 'app/shared/enums';
import { ItemType } from 'app/shared/enums/item-type.enum';
import { ExportStatus } from 'app/shared/modules/zoho-export/models/export-status.enum';

// Services
import { ModuleService } from 'app/shared/services/module.service';

// Components
import { ZohoExportDialogComponent } from 'app/shared/modules/zoho-export/components/zoho-export-dialog/zoho-export-dialog.component';

@Injectable()
export class ZohoExportService {

    badge: AssessmentProfileBadgeResponse;
    entityId: string;
    entityName: string;
    clientId: string;
    clientName: string;

    constructor(
        private dialogs: MatDialog
    ) { }

    hasZohoExportEnabled(): boolean {
        return ModuleService.isEnabledForClient(Modules.ConnectPortalEntitiesZohoExport);
    }

    showBadgeExportDialog(
        badge: AssessmentProfileBadgeResponse,
        entityId: string,
        entityName: string,
        clientId: string,
        clientName: string,
        ownerId: string): void {
            this.badge = badge;
            this.entityId = entityId;
            this.entityName = entityName;
            this.clientId = clientId;
            this.clientName = clientName;
            const categories = badge.categories || [];

            this.showExportDialog(categories, entityId, ownerId);
    }

    showProfileExportDialog(profile: AssessmentProfileResponse, organisationId: Guid): void {
        const categories = profile.categories || [];

        this.showExportDialog(categories, organisationId, profile.information.ownerId);
    }

    private showExportDialog(categories: AssessmentProfileCategoryResponse[], organisationId: Guid, ownerId: string) {
        const files: ExportFile[] = [];

        categories.forEach(c => {
            const sets = c.sets || [];

            sets.forEach(s => {
                const items = s.items || [];
                const eligbleItems = items.filter(i => this.isItemEligibleForExport(i.type));

                eligbleItems.forEach(i => {
                    const observations = i.observations || [];

                    observations.forEach(o => {
                        const observationFiles = this.getObservationFiles(
                            s,
                            i,
                            o,
                            this.entityId,
                            this.entityName,
                            this.clientId,
                            this.clientName,
                            this.badge.id,
                            this.badge.title);
                        files.push(...observationFiles);
                    });
                });
            });
        });

        const credasPdf: ExportFile = {
            filename: 'credas-summary.pdf',
            status: ExportStatus.Ready,
            attemptCount: 0,
            observationId: null,
            itemType: null,
            entityId: this.entityId,
            entityName: this.entityName,
            clientId: this.clientId,
            clientName: this.clientName,
            processId: this.badge.id,
            processName: this.badge.title
        };

        files.push(credasPdf);

        const options: ZohoExportDialogOptions = {
            fileContainer: {
                files
            },
            ownerId,
            processId: this.badge.id,
            entityId: this.entityId
        };

        this.dialogs.open(ZohoExportDialogComponent, {
            data: options,
            disableClose: true
        });
    }

    private getObservationFiles(
        set: AssessmentProfileSetResponse,
        item: AssessmentProfileItem,
        observation: AssessmentProfileObservation,
        entityId: Guid,
        entityName: string,
        clientId: Guid,
        clientName: string,
        processId: string,
        processName: string
    ): ExportFile[] {
        const results: ExportFile[] = [];

        if (item.type === ItemType.IdDocuments) {
            const metadata = JSON.parse(observation.value) as IdDocumentsResponse;
            const idDocs = metadata.documents || [];

            idDocs.forEach(d => {
                const filename = `${set.name} - ${d.description}.jpg`;
                results.push({
                    filename,
                    status: ExportStatus.Ready,
                    attemptCount: 0,
                    observationId: d.documentId,
                    itemType: item.type,
                    clientId,
                    clientName,
                    entityId,
                    entityName,
                    processId,
                    processName
                });
            });
        } else {
            const filename = this.getFilename(item.type, observation.value);
            results.push({
                filename,
                status: ExportStatus.Ready,
                attemptCount: 0,
                observationId: observation.id,
                itemType: item.type,
                clientId,
                clientName,
                entityId,
                entityName,
                processId,
                processName
            });
        }

        return results;
    }

    private getFilename(itemType: ItemType, filename: string): string {
        if (itemType === ItemType.Image || itemType === ItemType.ImageList) {
            const extIndex = filename.lastIndexOf('.');
            if (extIndex === -1) {
                return filename + '.jpg';
            }
        }
        return filename;
    }

    private isItemEligibleForExport(itemType: ItemType): boolean {
        switch (itemType) {
            case ItemType.Document:
            case ItemType.DocumentList:
            case ItemType.Image:
            case ItemType.ImageList:
            case ItemType.IdDocuments:
                return true;

            default:
                return false;
        }
    }
}