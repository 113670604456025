<app-dialog [dialogTitle]="title" id="image-popup" defaultWidth="800px" defaultHeight="550px">
    <div class="zoom-container" body fxLayout="row" fxLayoutGap="16px">
        <div class="image-container">
            <img #origImage [src]="imageUrl | safe: 'resourceUrl'" (load)="onLoad($event)" (mouseover)="onMouseEnter()" (mouseout)="onMouseLeave()"/>
        </div>
        <div #resultImage class="zoom-result" 
            [ngStyle]="{'backgroundImage': 'url(' + imageUrl + ')', 
                        'backgroundSize': zoomWidth + 'px ' + zoomHeight + 'px', 
                        'backgroundPosition': zoomX + 'px ' + zoomY + 'px'}"></div>
    </div>
    <div footer-actions>
        <button mat-raised-button class="ghost" mat-dialog-close>Close</button>
    </div>
</app-dialog>