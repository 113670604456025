<div id="qr-code-dialog" class="padding-24">
    <div fxLayout="row">
        <div fxflex="300px" fxLayout="column" fxLayoutAlign="start start">
            <img fxFlex="40px" src="assets/connect/icons/switch-to-mobile.svg"/>
            <h1>Switch to Mobile</h1>
            <div>Use the camera on your mobile device to scan the QR code.</div>
            <div><strong>Note: You can return to the desktop at any time.</strong></div>
        </div>
        <div fxflex="140px">
            <div *ngIf="qrCodeUrl" fxFlex>
                <qrcode [qrdata]="qrCodeUrl" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
            <app-loading-card *ngIf="loadingToken$ | async"></app-loading-card>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button  (click)="closeDialog()">Close Window</button>
    </div>
</div>