import { ItemType } from 'app/shared/enums/item-type.enum';

export class ObservationRequest {
    id: string;
    itemId: string;
    value: string;
    base64File: string;
    contentType: string;
    type: ItemType;
    metaData: any;

    constructor(id: string, itemId: string, value: string, base64File: string, type: ItemType, contentType: string, metaData: any) {
        this.id = id;
        this.itemId = itemId;
        this.value = value === '' ? null : value;
        this.base64File = base64File === '' ? null : base64File;
        this.contentType = contentType === '' ? null : contentType;
        this.type = type;
        this.metaData = metaData;
    }
}