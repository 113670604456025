import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LightenDarkenColor } from 'lighten-darken-color';

// ngrx
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';

// store
import * as fromStore from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-connect-branding',
    template: `<div [innerHTML]="styles"></div>`
})
export class BrandingComponent extends BaseComponent implements OnInit {

    styles: SafeHtml;
    headerColor: string;
    primaryColor: string;
    primaryReverseColor: string;
    textColor: string;
    secondaryColor: string;
    secondaryColorDark: string;
    accentColor: string;
    credasLogoColor: string;
    favIcon: string;
    tableAndTabColor: string;
    tableAndTabTextColor: string;

    constructor(
        private sanitizer: DomSanitizer,
        private store: Store<fromStore.ConnectStoreState>,
        private actionsSubject: ActionsSubject) {
        super();
     }

    ngOnInit(): void {
        this.store.dispatch(fromStore.GetDefaultBranding());

        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getHeaderColor)).subscribe((color: string) => this.headerColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getPrimaryColor)).subscribe((color: string) => this.primaryColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getPrimaryReverseColor)).subscribe((color: string) => this.primaryReverseColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getTextColor)).subscribe((color: string) => this.textColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getSecondaryColor)).subscribe((color: string) => this.secondaryColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getSecondaryColorDark)).subscribe((color: string) => this.secondaryColorDark = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getAccentColor)).subscribe((color: string) => this.accentColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getCredasLogoColor)).subscribe((color: string) => this.credasLogoColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getFavIcon)).subscribe((favIcon: string) => this.favIcon = favIcon);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getTableAndTabColor)).subscribe((color: string) => this.tableAndTabColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getTableAndTabTextColor)).subscribe((color: string) => this.tableAndTabTextColor = color);

        this.buildStyles();

        this.actionsSubject.pipe(
            ofType(fromStore.SetVersion),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.buildStyles());

        this.actionsSubject.pipe(
            ofType(fromStore.ClearBranding),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.buildStyles());

        this.actionsSubject.pipe(
            ofType(fromStore.SetBranding),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.buildStyles());

        this.actionsSubject.pipe(
            ofType(fromStore.GetDefaultBrandingSuccess),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.buildStyles());
    }

    private buildStyles(): void {
        setTimeout(() => {

            const favIcon: HTMLLinkElement = document.querySelector('#site-icon');
            if (favIcon) {
                favIcon.href = this.favIcon;
            }

            this.styles = this.sanitizer.bypassSecurityTrustHtml(`
            <style>
                #content-container {
                    color: ${this.textColor};
                }

                ${this.getBaseClasses()}
                ${this.getHeaderColors()}
                ${this.getMaterialColors()}
                ${this.getButtonColors()}
                ${this.getTableColors()}
                ${this.getIconColors()}
                ${this.getBrandPanelColors()}
                ${this.getSectionWrapperColors()}
                ${this.getDialogColors()}
                ${this.getProcessColors()}
            </style>
        `);
        }, 1);

    }

    private getBaseClasses(): string {
        return `
            body { color: ${this.textColor}; }
            .header-bg { background-color: ${this.headerColor ?? this.primaryColor}; }
            .accent-text { color: ${this.accentColor}; }
            .accent-bg { background-color: ${this.accentColor}; }
            .primary-text { color: ${this.primaryColor}; }
            .primary-bg { background-color: ${this.primaryColor}; }
            .primary-bg::-webkit-scrollbar-thumb { background-color: ${this.primaryReverseColor}; }
            .primary-border { border-color: ${this.primaryColor}; }
            .primary-reverse-text { color: ${this.primaryReverseColor}; }
            .primary-reverse-bg { background-color: ${this.primaryReverseColor}; }
            .secondary-text { color: ${this.secondaryColor}; }
            .secondary-bg { background-color: ${this.secondaryColor}; }
            .secondary-border { border-color: ${this.secondaryColor}; }
            .secondary-glow {  box-shadow: 0px 0px 3px 6px ${this.secondaryColor}; }
            .table-and-tab-bg { background-color: ${this.tableAndTabColor}; }
            .table-and-tab-text { color: ${this.tableAndTabTextColor}; }
            .entity-profile-card-head { background-color: ${this.headerColor ?? this.primaryColor}; }
            .entity-profile-card-head * { color: ${this.primaryReverseColor}; }
            .entity-profile-card-body .item .label { background-color: ${this.headerColor ?? this.primaryColor}30; }
            a { color: ${this.textColor}; }
            *::-webkit-scrollbar-thumb { background-color: ${this.primaryColor}; }
        `;
    }

    private getBrandPanelColors(): string {
        return `
            #brand-panel.expandable { border-left: 1px solid ${this.primaryColor}; }
            #brand-panel .expand-button {
                border-left: 1px solid ${this.primaryColor};
                border-bottom: 1px solid ${this.primaryColor};
                border-top: 1px solid ${this.primaryColor};
            }
        `;
    }

    private getHeaderColors(): string {
        return `
            #connect-header #user-initials { border-color: ${this.secondaryColorDark}!important;}
            #toolbar .service-link.active { 
                background-color: ${this.tableAndTabColor}!important;
                border-color: ${this.tableAndTabColor}!important;
                color: ${this.tableAndTabTextColor}!important;
            }

            #toolbar .service-link:not(.active):hover { 
                background-color: ${this.secondaryColor}!important;
                border-color: ${this.secondaryColor}!important;
                color: ${this.textColor}!important;
                font-weight: 700;
            }
        `;
    }

    private getButtonColors(): string {
        return `
            button.mat-raised-button:not(:disabled):not(.ghost):not(.borderless) {
                background-color: ${this.secondaryColor};
                color: ${this.textColor};
                border-color: ${this.secondaryColor};
            }

            button.mat-raised-button:active:not(:disabled):not(.ghost):not(.borderless) {
                background-color: ${this.darken(this.secondaryColor)};
                border-color: ${this.darken(this.secondaryColor)};
            }

            button.mat-raised-button.selected:not(:disabled):not(.borderless) {
                background-color: ${this.darken(this.secondaryColor)};
                border-color: ${this.darken(this.secondaryColor)};
            }
            
            button.mat-raised-button:hover:not(:disabled):not(.ghost):not(.borderless) {
                background-color: ${this.lighten(this.secondaryColor)};
                border-color: ${this.lighten(this.secondaryColor)};
            }

            button.mat-raised-button:not(.selected).ghost:not(:disabled) {
                border-color: ${this.secondaryColor};
            }

            button.mat-raised-button:not(.selected).ghost:active:not(:disabled) {
                border-color: ${this.darken(this.secondaryColor)};
                background-color: #FFFFFF;
            }

            button.mat-raised-button:not(.selected).ghost:hover:not(:disabled) {
                border-color: ${this.lighten(this.secondaryColor)};
                background-color: #FFFFFF;
            }

            button.mat-raised-button:not(.selected).borderless:active:not(:disabled) {
                color: ${this.darken(this.secondaryColor)};
                border-color: #FFFFFF;
                background-color: #FFFFFF;
            }

            button.mat-raised-button:not(.selected).borderless:hover:not(:disabled) {
                color: ${this.lighten(this.secondaryColor)};
                border-color: #FFFFFF;
                background-color: #FFFFFF;
            }

            #help-faq-search .content .search_items .search_item:hover,
            #help-faq-search .content .search_items .search_item.active {
                background-color: ${this.secondaryColor};
                color: ${this.textColor};
            }

            .tabs .tab.active { 
                background-color: ${this.tableAndTabColor}!important;
                border-color: ${this.tableAndTabColor}!important;
                color: ${this.tableAndTabTextColor}!important;
            }

            #tabs .tab:not(.active):hover { 
                background-color: ${this.secondaryColor}!important;
                border-color: ${this.secondaryColor}!important;
                color: ${this.textColor}!important;
                font-weight: 700;
            }
        `;
    }

    private getTableColors(): string {
        return `
            .mat-table {
                color: ${this.textColor};
            }

            .mat-table .mat-header-cell {
                background-color: ${this.tableAndTabColor};
                color: ${this.tableAndTabTextColor};
            }

            .mat-table .mat-header-cell .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-frame {
                background-color: ${this.tableAndTabColor} !important;
                border-color: ${this.tableAndTabTextColor}!important;
            }

            .mat-table .mat-header-cell .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-checkmark-path {
                stroke: ${this.tableAndTabTextColor}!important;
            }

            .mat-table .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-frame { border-color: ${this.textColor}!important; }
            .mat-table .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-checkmark-path { stroke: ${this.textColor}!important; }

            .mat-table mat-header-cell .mat-sort-header-arrow {
                color: ${this.tableAndTabTextColor};
            }

            .mat-table mat-header-cell .mat-sort-header-pointer-left,
            .mat-table mat-header-cell .mat-sort-header-pointer-right {
                background: ${this.tableAndTabTextColor};
            }
        `;
    }

    private getIconColors(): string {
        return `
            .mat-icon.primary {
                color: ${this.textColor};
            }

            .mat-icon.secondary {
                color: ${this.secondaryColor};
            }
        `;
    }

    private getMaterialColors(): string {
        return `

            .mat-form-field .mat-form-field-label { color: ${this.textColor}!important; }
            .mat-form-field.mat-focused .mat-form-field-label { color: ${this.textColor}!important; }
            .mat-form-field:not(.mat-form-field-disabled):not(.mat-form-field-should-float):not(.light):not(.mat-form-field-invalid) .mat-form-field-outline .mat-form-field-outline-gap {
                border-top: 1px solid ${this.textColor};
            }

            .mat-form-field:not(.mat-form-field-disabled):not(.mat-form-field-invalid):not(.light) .mat-form-field-outline .mat-form-field-outline-start {
                border-left: 1px solid ${this.textColor};
                border-top: 1px solid ${this.textColor};
                border-bottom: 1px solid ${this.textColor};
            }

            .mat-form-field:not(.mat-form-field-disabled):not(.mat-form-field-invalid):not(.light) .mat-form-field-outline .mat-form-field-outline-gap {
                border-bottom: 1px solid ${this.textColor};
            }

            .mat-form-field:not(.mat-form-field-disabled):not(.mat-form-field-invalid):not(.light) .mat-form-field-outline .mat-form-field-outline-end {
                border-right: 1px solid ${this.textColor};
                border-top: 1px solid ${this.textColor};
                border-bottom: 1px solid ${this.textColor};
            }

            .mat-tab-group .mat-tab-label { color: ${this.primaryReverseColor}!important; }
            .mat-tab-group .mat-ink-bar { background-color: ${this.secondaryColor}!important; }
            .mat-tab-group .mat-tab-list { background-color: ${this.primaryColor}; }

            .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent { background-color: ${this.accentColor}; }

            .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background { background-color: ${this.primaryColor}; }
            .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element { background-color: ${this.primaryColor}; }

            .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-frame { border-color: ${this.secondaryColor}!important; }
            .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-checkmark-path { stroke: ${this.secondaryColor}!important; }

            .mat-radio-button:not(.mat-radio-disabled) .mat-radio-outer-circle { border-color: ${this.secondaryColor}!important; }
            .mat-radio-button:not(.mat-radio-disabled) .mat-radio-inner-circle { background-color: ${this.secondaryColor}!important; }

            .mat-progress-spinner circle, .mat-spinner circle { stroke: ${this.secondaryColor}; }
        `;
    }

    private getSectionWrapperColors(): string {
        return `
            .section-wrapper { border: 1px solid ${this.primaryColor}; }
        `;
    }

    private getDialogColors(): string {
        return `
            .dialog-container .dialog-content { border-color: ${this.accentColor}!important; }
        `;
    }

    private getProcessColors(): string {
        return `
            #forms-dialog .form { border-color: ${this.primaryColor}!important; }
            #process-sections-panel .task {
                border-color: ${this.primaryColor};
            }
            #process-sections-panel .selected {
                border-color: ${this.secondaryColor};
            }

            .process-progress-spinner circle {
                stroke: ${this.secondaryColor};
            }
        `;
    }

    private darken(color: string): string {
        return this.lightenDarken(color, -25);
    }

    private lighten(color: string): string {
        return this.lightenDarken(color, 25);
    }

    private lightenDarken(color: string, amount: number): string {
        if (color) {
            const hasHash = color.startsWith('#');
            if (hasHash) {
                color = color.substring(1);
            }

            let newColor = <string>LightenDarkenColor(color, amount);
            while(newColor.length < 6) {
                newColor = '0' + newColor;
            }
            return (hasHash ? '#' : '') + newColor;
        }
        return color;
    }
}
