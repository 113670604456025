<div class="image-observation">
    <app-photo-capture #imageUpload 
        (imageUploaded)="imageUploaded($event)"
        (imageLoading)="imageLoading($event)"
        (imageDownloadClicked)="imageDownloadClicked()"
        (imageRemoveClicked)="imageRemoveClicked()"
        [downloadEnabled]="false"
        [removeEnabled]="!item.readonly"
        [disableUpload]="item.readonly"
        [qrCodeData]="qrCodeData"></app-photo-capture>
</div>
