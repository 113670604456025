import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// Store
import { reducers, effects } from './store';

// Components
import { SetObservationsComponent } from './components/observations/set-observations/set-observations.component';
import { ObservationWrapperComponent } from './components/observations/observation-wrapper/observation-wrapper.component';
import { TextOnlyObservationComponent } from './components/observations/text-only-observation/text-only-observation.component';
import { NetPromoterScoreObservationComponent } from './components/observations/net-promoter-score-observation/net-promoter-score-observation.component';
import { DateObservationComponent } from './components/observations/date-observation/date-observation.component';
import { DocumentObservationComponent } from './components/observations/document-observation/document-observation.component';
import { ShortTextObservationComponent } from './components/observations/short-text-observation/short-text-observation.component';
import { MultiOptionSingleObservationComponent } from './components/observations/multi-option-single-observation/multi-option-single-observation.component';
import { MultiOptionListObservationComponent } from './components/observations/multi-option-list-observation/multi-option-list-observation.component';
import { AssessmentSetDialogComponent } from './components/assessment-set-dialog/assessment-set-dialog.component';
import { ESignDocumentObservationComponent } from './components/observations/esign-observations/esign-document-observation/esign-document-observation.component';
import { ESignSignatureComponent } from './components/observations/esign-observations/esign-signature/esign-signature.component';
import { ESignDocumentDownloadComponent } from './components/observations/esign-observations/esign-document-download/esign-document-download.component';
import { SeparatorObservationComponent } from './components/observations/separator-observation/separator-observation.component';
import { AdviceTextObservationComponent } from './components/observations/advice-text-observation/advice-text-observation.component';
import { ImageObservationComponent } from './components/observations/image-observation/image-observation.component';
import { PassReviewFailObservationComponent } from './components/observations/pass-review-fail-observation/pass-review-fail-observation.component';
import { RightToWorkObservationComponent } from './components/observations/right-to-work-check-observation/right-to-work-check-observation.component';
import { PostcodeObservationComponent } from './components/observations/postcode-observation/postcode-observation.component';
import { IncompleteMandatoryBadgesDialogComponent } from './components/incomplete-mandatory-badges-dialog/incomplete-mandatory-badges-dialog.component';
import { NumberObservationComponent } from './components/observations/number-observation/number-observation.component';
import { BadgeSummaryDialogComponent } from './components/badge-summary-dialog/badge-summary-dialog.component';
import { SelfieObservationComponent } from './components/observations/selfie-observation/selfie-observation.component';
import { UapObservationComponent } from './components/observations/uap-observation/uap-observation.component';
import { IdDocumentsObservationComponent } from './components/observations/id-documents-observation/id-documents-observation.component';
import { RichTextObservationComponent } from './components/observations/rich-text-observation/rich-text-observation.component';
import { ImageObservationListComponent } from './components/observations/image-observation-list/image-observation-list.component';
import { CourseSelectorObservationComponent } from './components/observations/course-selector-observation/course-selector-observation.component';
import { IdentityVerificationObservationComponent } from './components/observations/identity-verification-observation/identity-verification-observation.component';
import { BadgeProfileComponent } from './components/badge-profile/badge-profile.component';
import { BadgeProfileDialogComponent } from './components/badge-profile-dialog/badge-profile-dialog.component';
import { AssessmentProfileComponent } from './components/assessment-profile-wrapper/assessment-profile-wrapper.component';
import { ProfileQuestionSetsComponent } from './components/profile-sections/profile-question-sets/profile-question-sets.component';
import { ProfileStatusComponent } from './components/profile-sections/profile-status/profile-status.component';
import { ProfileBadgeCardComponent } from './components/profile-sections/profile-badge-card/profile-badge-card.component';
import { DocumentLibraryCardComponent } from './components/document-library-card/document-library-card.component';
import { ProfileGeneralInformationComponent } from './components/profile-sections/profile-general-information/profile-general-information.component';
import { ProfileBadgesComponent } from './components/profile-sections/profile-badges/profile-badges.component';
import { ProfileTagsComponent } from './components/profile-sections/profile-tags/profile-tags.component';
import { AssessmentProfileAssessmentSetComponent } from './components/assessment-profile-assessment-set/assessment-profile-assessment-set.component';
import { GeneralInformationDialogComponent } from './components/general-information-dialog/general-information-dialog.component';
import { ViewPostcodeObservationComponent } from './components/view-postcode-observation/view-postcode-observation.component';

// Services
import { SetObservationService } from './services/set-observation.service';
import { IncompleteBadgeDialogService } from './services/incomplete-badge-dialog.service';

// Modules
import { SharedModule } from 'app/shared/shared.module';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { RichTextEditorModule } from 'app/shared/modules/rich-text-editor/rich-text-editor.module';
import { IdentityVerificationModule } from 'app/shared/modules/identity-verification/identity-verification.module';
import { MobilePhotoModule } from 'app/shared/modules/mobile-photo/mobile-photo.module';
import { GroupModule } from 'app/shared/modules/group/group.module';
import { ZohoExportModule } from 'app/shared/modules/zoho-export/zoho-export.module';
import { ConnectModule } from 'app/connect/connect.module';
import { InputsModule } from 'app/portal/modules/inputs/inputs.module';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

// pipes
import { CanShowObservationImagePipe } from './pipes/can-show-observation-image.pipe';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        GroupModule,
        ZohoExportModule,
        ConnectModule,
        SharedModule,
        ReactiveFormsModule,
        PdfJsViewerModule,
        RichTextEditorModule,
        IdentityVerificationModule,
        MobilePhotoModule,
        InputsModule,
        StoreModule.forFeature(StoreNames.setObservations, reducers),
        EffectsModule.forFeature(effects)
    ],
    providers: [
        SetObservationService,
        IncompleteBadgeDialogService
    ],
    exports: [
        BadgeProfileComponent,
        BadgeProfileDialogComponent,
        ObservationWrapperComponent,
        MultiOptionListObservationComponent,
        AssessmentProfileComponent,
        ProfileQuestionSetsComponent,
        ProfileStatusComponent,
        CanShowObservationImagePipe,
        ProfileBadgeCardComponent,
        DocumentLibraryCardComponent
    ],
    declarations: [
        AssessmentProfileComponent,
        ProfileGeneralInformationComponent,
        ProfileBadgesComponent,
        ProfileQuestionSetsComponent,
        ProfileTagsComponent,
        AssessmentProfileAssessmentSetComponent,
        ProfileBadgeCardComponent,
        ProfileStatusComponent,
        CanShowObservationImagePipe,
        PostcodeObservationComponent,
        GeneralInformationDialogComponent,
        DocumentLibraryCardComponent,
        BadgeProfileComponent,
        BadgeProfileDialogComponent,
        SetObservationsComponent,
        ObservationWrapperComponent,
        TextOnlyObservationComponent,
        NetPromoterScoreObservationComponent,
        DateObservationComponent,
        DocumentObservationComponent,
        ShortTextObservationComponent,
        MultiOptionSingleObservationComponent,
        MultiOptionListObservationComponent,
        AssessmentSetDialogComponent,
        SeparatorObservationComponent,
        ESignSignatureComponent,
        ESignDocumentObservationComponent,
        ESignDocumentDownloadComponent,
        AdviceTextObservationComponent,
        ImageObservationComponent,
        PassReviewFailObservationComponent,
        RightToWorkObservationComponent,
        PostcodeObservationComponent,
        IncompleteMandatoryBadgesDialogComponent,
        NumberObservationComponent,
        BadgeSummaryDialogComponent,
        SelfieObservationComponent,
        UapObservationComponent,
        IdDocumentsObservationComponent,
        RichTextObservationComponent,
        ImageObservationListComponent,
        CourseSelectorObservationComponent,
        IdentityVerificationObservationComponent,
        ViewPostcodeObservationComponent
    ],
    entryComponents: [
        BadgeProfileComponent,
        BadgeProfileDialogComponent,
        AssessmentSetDialogComponent,
        IncompleteMandatoryBadgesDialogComponent,
        BadgeSummaryDialogComponent,
        AssessmentProfileComponent,
        GeneralInformationDialogComponent,
        DocumentLibraryCardComponent
    ]
})
export class SetObservationsModule { }
