<mat-form-field appearance="outline" [formGroup]="form" class="observation-field">
    <input matInput name="observation" 
        formControlName="observation" 
        (input)="updateValue(shortText.value)" 
        (focus)="setSelected()"
        autocomplete="off"
        #shortText
        [errorStateMatcher]="matcher"
        [readonly]="item.readonly">
    <mat-error *ngIf="form.invalid">        
        {{ this.item.regexValidationMessage }}
    </mat-error>
</mat-form-field>