import { getBase64Icon } from 'app/shared/utilities/string-utilities';

export class Base64Blob {
    fileName: string;
    contentType: string;
    fileLength: string;
    base64Data: string;
}

export function getBase64Image(base64Blob: Base64Blob): string {
    return getBase64Icon(base64Blob.contentType, base64Blob.base64Data);
}