// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { UapImage } from 'app/shared/modules/set-observations/models/responses/uap-image.model';
import { UploadImageRequest } from 'app/shared/modules/mobile-photo/models/upload-image-request-model';

export const GetMobileToken = createAction(
    '[Mobile Photo] Get mobile token',
    props<{organisationId: string}>()
);

export const GetMobileTokenSuccess = createAction(
    '[Mobile Photo] Get mobile token success',
    props<{token: string}>()
);

export const GetMobileTokenFail = createAction(
    '[Mobile Photo] Get mobile token fail'
);

export const PollMobileToken = createAction(
    '[Profile Form Task] Poll mobile token',
    props<{token: string; pollIntervalMs: number; pollEndMs: number}>()
);

export const MobileTokenUsed = createAction(
    '[Profile Form Task] Mobile token used'
);

export const PollMobileTokenFail = createAction(
    '[Profile Form Task] Poll mobile token fail'
);

export const StopPolling = createAction(
    '[Profile Form Task] Stop polling mobile token'
);

export const UploadImage = createAction(
    '[Mobile Photo] Upload image',
    props<{token: string; request: UploadImageRequest}>()
);

export const UploadImageSuccess = createAction(
    '[Mobile Photo] Upload image success'
);

export const UploadImageFail = createAction(
    '[Mobile Photo] Upload image fail'
);

export const GetUapImage = createAction(
    '[Mobile Photo] Get UAP image',
    props<{token: string}>()
);

export const GetUapImageSuccess = createAction(
    '[Mobile Photo] Get UAP image success',
    props<{uapImage: UapImage}>()
);

export const GetUapImageFail = createAction(
    '[Mobile Photo] Get UAP image fail',
);

export const ClearMobileToken = createAction(
    '[Mobile Photo] Clear mobile token',
    props<{token: string}>()
);

export const ClearMobileTokenSuccess = createAction(
    '[Mobile Photo] Clear mobile token success'
);

export const ClearMobileTokenFail = createAction(
    '[Mobile Photo] Clear mobile token fail'
);
