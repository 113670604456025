<mat-card fxLayout="column"
    class="group-platform-card badge-card" 
    [title]="badge.title"
    [ngClass]="{'disabled': !badge.enabled}">
    
    <mat-card-title fxLayout="row" fxLayoutGap="10px">
        <div class="text" fxFlex>{{badge.title}}</div>
        <mat-checkbox *ngIf="!isLocked" [checked]="badge.enabled" (change)="onEnabledChanged(enabledCheckbox.checked)" #enabledCheckbox></mat-checkbox>
        <mat-icon *ngIf="isLocked" title="This badge is already selected.">verified_user</mat-icon>
    </mat-card-title>
    <mat-card-content fxFlex>
        <div class="icon" fxLayoutAlign="center center">
            <img [src]="iconSource" />
        </div>
    </mat-card-content>

    <mat-card-footer fxLayoutAlign="start center" fxLayout="row">
        <div>{{badge.description}}</div>
    </mat-card-footer>
</mat-card>