import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-document-wizard-step-4',
    templateUrl: './document-wizard-step-4.component.html',
    styleUrls: ['./document-wizard-step-4.component.scss']
})
export class DocumentWizardStep4Component {
    @Input()
    preview: Blob;

    @Input()
    loading: boolean;
}
