import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray, ValidatorFn } from '@angular/forms';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { takeUntil, filter } from 'rxjs/operators';

// store
import { Store, select, ActionsSubject } from '@ngrx/store';
import * as fromDocuments from 'app/portal/modules/document-management/store';

// Components
import { BaseComponent } from 'app/shared/base/base-component';
import { TemplatePreviewDialogComponent } from 'app/portal/modules/document-management/dialogs/template-preview-dialog/template-preview-dialog.component';

// Models
import { Template } from 'app/portal/modules/document-management/models';
import { CustomMenu } from 'app/shared/modules/rich-text-editor/model/custom-menu.model';
import { TemplatePreview } from 'app/portal/modules/document-management/models/template-preview.model';
import { TemplateDialogData } from 'app/portal/modules/document-management/models/template-dialog-data.model';
import { RegExRules } from 'app/shared/models';

// Enums
import { DocumentType, getDocumentTypeLabel } from 'app/portal/modules/document-management/enums/document-types.enum';
import { FileExtensions } from 'app/shared/enums/file-extensions.enum';
import { DocumentTemplateType } from 'app/portal/modules/document-management/enums/document-template-type.enum';
import { SignatureType } from 'app/portal/modules/document-management/enums/signature-type.enum';

// Services
import { ValidationService } from 'app/shared/services';

// Enums
import { Modules } from 'app/shared/enums/modules.enum';

@Component({
    selector: 'app-template-dialog',
    templateUrl: './template-dialog.component.html',
    styleUrls: ['./template-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TemplateDialogComponent extends BaseComponent implements OnInit {

    SignatureType = SignatureType;
    Modules = Modules;

    template: Template;
    templateFile: Blob;
    loading: boolean = false;
    addMode: boolean = false;
    saving: boolean = false;
    uploadedFileName: string;
    documentTemplateType: DocumentTemplateType;
    DocumentType = DocumentType;

     // Form
    templateForm: FormGroup;
    regexRules: RegExRules = new RegExRules();

    allowedFileExtensions: FileExtensions[] = [ FileExtensions.Doc, FileExtensions.Docx, FileExtensions.Pdf ];

    rteMenu: CustomMenu[] = [
        new CustomMenu('First Name', '<<[firstname]>>'),
        new CustomMenu('Surname', '<<[surname]>>'),
        new CustomMenu('Document', '<<[document]>>'),
        new CustomMenu('Address Line 1', '<<[address_line_1]>>'),
        new CustomMenu('Address Line 2', '<<[address_line_2]>>'),
        new CustomMenu('Town', '<<[town]>>'),
        new CustomMenu('County', '<<[county]>>'),
        new CustomMenu('Postcode', '<<[postcode]>>')
    ];

    constructor(
        private store: Store<fromDocuments.DocumentsState>,
        public dialogRef: MatDialogRef<TemplateDialogComponent>,
        public previewDialogRef: MatDialog,
        @Inject(MAT_DIALOG_DATA) private data: TemplateDialogData,
        private fb: FormBuilder,
        public validationService: ValidationService,
        private actionsSubject: ActionsSubject
       ) {
            super();
            this.template = data.template;
            this.addMode = !data.template.id;
            this.documentTemplateType = data.documentTemplateType;
    }

    ngOnInit() {
        if (this.showTextEditor()) {
            this.allowedFileExtensions = [ FileExtensions.Doc, FileExtensions.Docx ];
        }

        this.templateForm = this.fb.group({
            name: [
                this.template.name,
                [
                    Validators.required,
                    Validators.pattern(
                        this.regexRules.alpha_numeric_space_dash_dot
                    ),
                    Validators.minLength(3),
                    Validators.maxLength(200)
                ]
            ],
            templateText: [ this.template.text, [Validators.required ]],
            documentType: [this.template.documentType, [ Validators.required ]],
            versionNumber: [ this.template.revision, [Validators.min(1), Validators.pattern(new RegExRules().numeric)] ],
            signatureType: [ this.template.signatureType ?? SignatureType.NoSigning ],
            documentTemplateType: [ this.template.documentTemplateType ],
            enabled: [ this.template.enabled ],
            signatures: this.getSignaturesFormArray()
        });

        this.templateForm.get('signatureType').valueChanges.subscribe((type: SignatureType) => {
            if (type !== SignatureType.FullESign) {
                this.templateForm.get('signatures').setValidators(null);
            } else {
                this.templateForm.get('signatures').setValidators(this.validateSignatureArray());
            }
        });

        this.store.pipe(
            select(fromDocuments.getSaving),
            takeUntil(this.ngUnsubscribe))
            .subscribe(saving => {
                this.saving = saving;
            });

        this.store.pipe(
            select(fromDocuments.gettingMarkup),
            takeUntil(this.ngUnsubscribe))
            .subscribe(loading => {
                this.loading = loading;
            });

        this.store.pipe(
            select(fromDocuments.getHtml),
            takeUntil(this.ngUnsubscribe))
            .subscribe(html => {
                this.templateForm.get('templateText').patchValue(html);
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromDocuments.getPdf))
            .subscribe((file: Blob) => {
                this.templateFile = file;
                if (file) {
                    this.uploadedFileName = this.template.name;
                    this.setFormAfterFileUpload();
                }
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromDocuments.SAVE_DOCUMENT_SUCCESS))
            .subscribe(() => {
                this.dialogRef.close(true);
            });

        if (this.template.id) {
            if (this.template.documentTemplateType !== DocumentTemplateType.RichText) {
                // go and get the bytes document
                this.store.dispatch(new fromDocuments.GetPdfDocument(this.template.id));
            } else {
                this.store.dispatch(new fromDocuments.GetExistingMarkup(this.template.id));
            }
        } else {
            this.store.dispatch(new fromDocuments.ClearState());
        }
    }

    getSignaturesFormArray(): FormArray {
        const signatureFormArray: FormArray = new FormArray([]);
        let signatureCounter = 0;
        if (this.template && this.template.templateSignatures) {
            for (const signature of this.template.templateSignatures) {
                signatureCounter++;
                signatureFormArray.push(this.fb.group({
                    signatureId: [signature.id],
                    title: [signature.title],
                    signatureOrder: signatureCounter,
                    requiredToSign: [signature.requiredToSign],
                    includeInDocument: [signature.includeInDocument],
                    page: [signature.page, [Validators.min(1)]],
                    locationX: [signature.locationX, [Validators.max(100), Validators.min(0)]],
                    locationY: [signature.locationY, [Validators.max(100), Validators.min(0)]]
                }));
            }
        }
        for (let i = signatureCounter; i < 5; i++) {
            signatureCounter++;
            signatureFormArray.push(this.fb.group({
                signatureId: [null],
                title: [null],
                signatureOrder: signatureCounter,
                requiredToSign: [true],
                includeInDocument: [false],
                page: [null, [Validators.min(1)]],
                locationX: [null, [Validators.max(100), Validators.min(0)]],
                locationY: [null, [Validators.max(100), Validators.min(0)]]
            }));
        }

        signatureFormArray.controls.forEach(form => {
            form.get('requiredToSign').valueChanges.subscribe((requiredToSign: boolean) => {
                if (!requiredToSign) {
                    form.get('includeInDocument').setValue(false);
                    form.get('page').setValue(null);
                    form.get('locationX').setValue(null);
                    form.get('locationY').setValue(null);
                }
            });
        });

        return signatureFormArray;
    }

    validateSignatureArray(): ValidatorFn {
        return (formArray: FormArray): {[key: string]: any} | null => {
            let numberSignatures = 0;
            formArray.controls.forEach((x: FormGroup) => {
                if (x.get('title').value && x.get('requiredToSign').value === true) {
                    numberSignatures++;
                }
            });
            if (numberSignatures > 0) {
                return null;
            } else {
                return { noSignatures: true };
            }
        };
      }

    templateUploaded(file: File) {
        this.templateFile = file;
        this.uploadedFileName = file.name;

        // reset document template type (back end will read content and assign)
        if (!this.showTextEditor()) {
            this.documentTemplateType = DocumentTemplateType.Word;
        } else {
            this.documentTemplateType = DocumentTemplateType.RichText;
        }

        this.setFormAfterFileUpload();
    }

    setFormAfterFileUpload() {
        if (!this.showTextEditor()) {
            this.templateForm.controls.templateText.setValue('');
            this.templateForm.controls.templateText.clearValidators();
        } else {
            const formData = new FormData();
            this.loading = true;
            formData.append('file', this.templateFile);
            this.templateForm.controls.templateText.setValidators(Validators.required);
            this.store.dispatch(new fromDocuments.GetMarkupForFile(formData));
        }

        this.templateForm.controls.templateText.updateValueAndValidity();
    }

    removeFile() {
        this.templateFile = null;
        this.uploadedFileName = '';
        this.templateForm.controls.templateText.setValidators(Validators.required);
        this.templateForm.controls.templateText.updateValueAndValidity();
    }

    busy(): boolean {
        return this.loading || this.saving;
    }

    closeDialog() {
        this.dialogRef.close(false);
    }

    isPreviewAvailable(): boolean {
        const text = this.templateForm.get('templateText').value;

        return text && text.length > 0;
    }

    getDocumentTypeLabel(value: DocumentType): string {
        return getDocumentTypeLabel(value);
    }

    showTextEditor(): boolean {
        return this.documentTemplateType === DocumentTemplateType.RichText;
    }

    saveTemplate() {
        const data = this.templateForm;
        const formData = new FormData();
        formData.append('templateName', data.value.name);
        formData.append('templateText', data.value.templateText);
        formData.append('templateId', this.template.id);
        formData.append('signatureType', data.value.signatureType);
        formData.append('enabled', data.value.enabled.toString());
        formData.append('documentType', data.value.documentType);
        formData.append('file', this.templateFile);
        formData.append('version', data.value.versionNumber);
        formData.append('documentTemplateType', this.documentTemplateType.toString());
        if (data.value.signatureType === SignatureType.FullESign) {
            formData.append('templateSignatures', JSON.stringify(data.value.signatures.filter(s => s.title !== null && s.title !== '')));
        }

        if (this.addMode) {
            this.store.dispatch(new fromDocuments.AddDocument(formData));
        } else {
            this.store.dispatch(new fromDocuments.UpdateDocument(formData));
        }
    }

    preview(): void {
        const html = this.templateForm.get('templateText').value;

        this.previewDialogRef.open(TemplatePreviewDialogComponent, {
            data: new TemplatePreview(html, DocumentTemplateType.RichText, this.templateFile)
        });
    }

    getDialogTitle() {
        if (this.template.id) {
            return 'Edit Document';
        } else {
            return 'Create a New Document';
        }
    }

    getDialogCaption() {
        if (this.template.id) {
            return 'Edit your document below and insert fields using the dropdown. Do not forget to activate your document and add a signature if required.';
        } else {
            return 'Create your document below and insert fields using the dropdown. Do not forget to activate your document and add a signature if required.';
        }
    }

    clearSignatory(signature: FormGroup): void {
        signature.get('title').setValue(null);
        signature.get('requiredToSign').setValue(true);
        signature.get('includeInDocument').setValue(false);
        signature.get('page').setValue(null);
        signature.get('locationX').setValue(null);
        signature.get('locationY').setValue(null);
        signature.markAsPristine();
    }

    get signatures(): FormArray {
        return this.templateForm.get('signatures') as FormArray;
    }
}
