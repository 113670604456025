export class ESignDocumentResponse {
    setId: string;
    itemId: string;
    documentBase64: string;
    esignDocumentUrl: string;

    constructor(setId: string, itemId: string) {
        this.setId = setId;
        this.itemId = itemId;
    }
}