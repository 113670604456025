import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/shared/modules/group/store/reducers';
import * as fromOrganisationTags from 'app/shared/modules/group/store/reducers/organisation-tag.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getGroupsState = createFeatureSelector<fromFeature.GroupsState>(StoreNames.groups);
const getOrganisationTagState = createSelector(getGroupsState, (state: fromFeature.GroupsState) => state.organisationTagState);

export const getAvailableTags = createSelector(getOrganisationTagState, fromOrganisationTags.getAvailableTags);
export const getLoaded = createSelector(getOrganisationTagState, fromOrganisationTags.getLoaded);
export const getLoading = createSelector(getOrganisationTagState, fromOrganisationTags.getLoading);
export const getSaved = createSelector(getOrganisationTagState, fromOrganisationTags.getSaved);
export const getSaving = createSelector(getOrganisationTagState, fromOrganisationTags.getSaving);
export const getBusy = createSelector(getOrganisationTagState, fromOrganisationTags.getSaving || fromOrganisationTags.getLoading);
