// ngrx
import { ActionReducerMap } from '@ngrx/store';

// Store
import * as fromDocumentLibrary from './document-library.reducer';
import * as fromOrganisationDocuments from './organisation-document.reducer';

export interface DocumentsState {
   documentLibraryState: fromDocumentLibrary.DocumentLibraryState;
   organisationDocumentState: fromOrganisationDocuments.OrganisationDocumentState;
}

export const reducers: ActionReducerMap<DocumentsState> = {
    documentLibraryState: fromDocumentLibrary.reducer,
    organisationDocumentState: fromOrganisationDocuments.reducer
};
