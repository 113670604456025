import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { ResendInviteEmailRequest } from 'app/shared/modules/group/models/resend-invite-email-request.model';
import { AssessmentProfileTagResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-tag-response.model';
import { CreateEntityRequest } from 'app/portal/modules/group-list/models/create-entity-request.model';
import { EntitySummary } from 'app/shared/modules/group/models/entity-summary.model';
import { SmsTemplateExistance } from 'app/shared/modules/group/models/sms-template-existance.model';
import { TelephoneNumber } from 'app/connect-management/modules/telephone-numbers-v1/models/telephone-number.model';

@Injectable()
export class GroupService {

    private baseEndpoint = 'api/groups';


    constructor(private http: HttpClient) { }

    public resendGroupInvite(request: ResendInviteEmailRequest): Observable<void> {
        const url = `${this.baseEndpoint}/${request.groupId}/group-nodes/${request.groupNodeId}/resend-invite`;

        return this.http.post<void>(url, request);
    }

    createEntity(request: CreateEntityRequest): Observable<EntitySummary> {
        const url = 'api/entities';
        return this.http.post<EntitySummary>(url, request);
    }

    getOrganisationTags(organisationId: string): Observable<AssessmentProfileTagResponse[]> {
        const url = `api/organisations/${organisationId}/tags`;
        return this.http.get<AssessmentProfileTagResponse[]>(url);
    }

    canContactViaSms(): Observable<SmsTemplateExistance> {
        const url = 'api/sms-templates/any';
        return this.http.get<SmsTemplateExistance>(url);
    }

    getTelephoneNumbers(): Observable<TelephoneNumber[]> {
        const url = 'api/telephone-numbers?active=true';
        return this.http.get<TelephoneNumber[]>(url);
    }
}
