import { Component } from '@angular/core';

// ngrx
import { Store, ActionsSubject, select } from '@ngrx/store';
import { takeUntil, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Store
import * as fromSetsStore from 'app/shared/modules/set-observations/store';

// Services
import { ImageUploadService } from 'app/shared/services';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

// Models
import { ImageView } from 'app/shared/models/images/image-view.model';
import { IdDocumentsResponse } from 'app/shared/modules/set-observations/models/responses/id-documents-response.model';
import { AddIdDocumentRequest } from 'app/shared/modules/set-observations/models/requests/add-id-document-request.model';
import { QrCodeData } from 'app/shared/modules/mobile-photo/models/qr-code-data.model';
import { IdDocumentUrl } from 'app/shared/modules/set-observations/models/responses/id-document-url.model';

// Enums
import { IdDocumentType, getIdDocumentTypeTitle } from 'app/shared/enums/id-document-type.enum';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-id-documents',
    templateUrl: './id-documents-observation.component.html',
    styleUrls: ['./id-documents-observation.component.scss'],
    outputs: ['valueUpdated', 'selectedChanged']
})
export class IdDocumentsObservationComponent extends ObservationComponent {

    uploading$: Observable<boolean>;

    idDocuments: IdDocumentsResponse;
    idDocumentUrls: IdDocumentUrl[];
    addingDocument: boolean = false;
    addingIdDocumentType: IdDocumentType;
    addingDocumentImage: ImageView;
    isClientUser: boolean;

    selectedVis: string = 'none';
    qrCodeData: QrCodeData = null;

    constructor(
        private store: Store<fromSetsStore.SetObservationsState>,
        private actionsSubject: ActionsSubject,
        private imagesService: ImageUploadService,
        private authTokenService: AuthenticationTokenService) {
        super();
    }

    init(): void {
        this.uploading$ = this.store.select(fromSetsStore.getUploadingIdDocument);
        if (this.itemObservation.ownerId) {
            this.store.dispatch(new fromSetsStore.GetIdDocumentUrls(this.itemObservation.ownerId));
        }

        if (this.itemObservation.value) {
            this.idDocuments = JSON.parse(this.itemObservation.value);
        }

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromSetsStore.getIdDocumentUrls))
            .subscribe((urls: IdDocumentUrl[]) => {
                if (urls) {
                    this.idDocumentUrls = urls;
                }
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromSetsStore.ADD_ID_DOCUMENT_SUCCESS))
            .subscribe((action: fromSetsStore.AddIdDocumentSuccess) => {
                const response = action.payload;
                this.updateValue(JSON.stringify(response));
                this.idDocuments = response;
                this.store.dispatch(new fromSetsStore.GetIdDocumentUrls(this.itemObservation.ownerId));
            });

        this.qrCodeData = this.getQrCodeData();
        this.isClientUser = this.authTokenService.isClient();
    }

    addDocument(): void {
        if (this.itemObservation.ownerId) {
            this.addingDocument = true;
        }
    }

    getIdDocumentTypeTitle(idDocumentType: IdDocumentType): string {
        return getIdDocumentTypeTitle(idDocumentType);
    }

    documentTypeSelectionChanged(idDocumentType: IdDocumentType): void {
        this.addingIdDocumentType = idDocumentType;
        this.selectedVis = 'flex';
        this.qrCodeData.idDocumentType = idDocumentType;
    }

    imageUploaded(image: ImageView) {
        if (!image || !image.uploadedBase64) {
            image = null;
        }

        this.addingDocument = false;

        this.addingDocumentImage = image;

        this.imagesService.compressFile(image, (newImage: ImageView) => {
            this.addingDocumentImage = newImage;
            this.dispatch();
        });
    }

    private dispatch(): void {
        const request = new AddIdDocumentRequest(
            this.item.setId,
            this.addingIdDocumentType,
            this.addingDocumentImage.uploadedBase64,
            this.itemObservation.ownerId);

        this.store.dispatch(new fromSetsStore.AddIdDocument(request));
        this.addingIdDocumentType = null;
    }
}
