import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

// store
import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, timeout, switchMap, filter, take, finalize } from 'rxjs/operators';
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import * as fromAuth from 'app/authentication/store';
import * as fromAuthV2 from 'app/authentication-v2/store';
import * as fromRoot from 'app/store';

// services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';
import { AuthenticationService } from 'app/authentication/services/authentication.service';
import { AuthenticationV2Service } from 'app/authentication-v2/services/authentication-v2.service';

// models
import { RefreshTokenRequest, TokenResponse } from 'app/shared/models';
import { LogoutOptions } from 'app/models/logout-options.model';


export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    private isRefreshingToken: boolean = false;
    private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private authenticationToken: AuthenticationTokenService,
        private authService: AuthenticationService,
        private authServiceV2: AuthenticationV2Service,
        private actionsSubject: ActionsSubject,
        private store: Store<fromRoot.State>,
        @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.authenticationToken.tokenHasExpired() &&
            this.authenticationToken.getRefreshToken()?.length > 0) {
            if (this.authenticationToken.usingV2() &&
                req.url.startsWith('api/v2') &&
                !req.url.startsWith('api/v2/authentication')) {
                return this.refreshTokenV2(req, next, null);
            } else if (!this.authenticationToken.usingV2() &&
                req.url.startsWith('api/') &&
                !req.url.startsWith('api/authentication')) {
                return this.refreshTokenV1(req, next, null);
            }
        }

        const timeoutValue = Number(req.headers.get('timeout')) || this.defaultTimeout;

        const request = this.addHeaders(req);

        return next.handle(request).pipe(
            timeout(timeoutValue),
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        // TODO - this is temporary while we have 2 versions of authentication
                        if (this.authenticationToken.usingV2()) {
                            return this.refreshTokenV2(req, next, err);
                        } else {
                            return this.refreshTokenV1(req, next, err);
                        }
                    } else {
                        return observableThrowError(err);
                    }
                } else {
                    return observableThrowError(err);
                }
            }));
    }

    private addHeaders(request: HttpRequest<any>): HttpRequest<any> {

        const authToken = this.authenticationToken.getToken();

        return request.clone({ setHeaders: { Authorization: `Bearer ${authToken}` }});
    }

    private refreshTokenV1(request: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse) {
        this.actionsSubject.pipe(
            filter(action => action.type === fromAuth.REFRESH_TOKEN_FAIL))
            .subscribe(() => {
                this.isRefreshingToken = false;
            });

        if (!this.isRefreshingToken) {
            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const refreshTokenRequest = this.authenticationToken.getRefreshTokenRequest();

            if (this.isRefreshTokenRequestInvalid(refreshTokenRequest)) {
                this.isRefreshingToken = false;
                this.store.dispatch(new fromAuth.Logout(new LogoutOptions(false, true, null)));
                return observableThrowError(err);
            }

            this.isRefreshingToken = true;

            return this.authService.refreshToken(refreshTokenRequest).pipe(
                switchMap((tokenResponse: TokenResponse) => {
                    if (!tokenResponse) {
                        this.isRefreshingToken = false;
                        this.store.dispatch(new fromAuth.Logout(new LogoutOptions(false, true, null)));
                        return observableThrowError(err);
                    }

                    this.authenticationToken.setAuthToken(tokenResponse);
                    this.tokenSubject.next(tokenResponse.auth_token);
                    this.isRefreshingToken = false;
                    const resendRequest = this.addHeaders(request);
                    return next.handle(resendRequest);
                }),
                finalize(() =>  {
                    this.isRefreshingToken = false;
                }));
        } else {
            return this.tokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(() => next.handle(this.addHeaders(request)))
            );
        }
    }

    private refreshTokenV2(request: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse) {
        this.actionsSubject.pipe(
            ofType(fromAuthV2.RefreshTokenFail))
            .subscribe(() => {
                this.isRefreshingToken = false;
            });

        if (!this.isRefreshingToken) {
            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);
            const refreshTokenRequest = this.authenticationToken.getRefreshTokenRequest();

            if (this.isRefreshTokenRequestInvalid(refreshTokenRequest)) {
                this.isRefreshingToken = false;
                this.store.dispatch(fromAuthV2.Logout({options: new LogoutOptions(false, true, null)}));
                return observableThrowError(err);
            }

            this.isRefreshingToken = true;

            return this.authServiceV2.refreshToken(refreshTokenRequest).pipe(
                switchMap((tokenResponse: TokenResponse) => {
                    if (!tokenResponse) {
                        this.isRefreshingToken = false;
                        this.store.dispatch(fromAuthV2.Logout({options: new LogoutOptions(false, true, null)}));
                        return observableThrowError(err);
                    }

                    this.authenticationToken.setAuthToken(tokenResponse);
                    this.tokenSubject.next(tokenResponse.auth_token);
                    this.isRefreshingToken = false;
                    const resendRequest = this.addHeaders(request);
                    return next.handle(resendRequest);
                }),
                finalize(() =>  {
                    this.isRefreshingToken = false;
                }));
        } else {
            return this.tokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(() => next.handle(this.addHeaders(request)))
            );
        }
    }

    private isRefreshTokenRequestInvalid(request: RefreshTokenRequest): boolean {
        return (request.accessToken === 'undefined' ||
                request.refreshToken === 'undefined' ||
                request.userId === 'undefined' ||
                request.accessToken === null ||
                request.refreshToken === null ||
                request.userId === null);
    }
}
