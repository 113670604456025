<div id="authentication" fxLayout="row" fxLayoutAlign="start stretch">
    <div *ngIf="backgroundUrl$ | async" class="splash-panel" fxFlex="50%" fxHide.lt-sm fxLayout="column" fxLayoutAlign="start center" [style.background-image]="'url(' + (backgroundUrl$ | async) + ')'">
        <div fxFlex="75%"></div>
        <div class="splash-inner" fxLayout="column" fxFlex fxLayoutGap="64px">
            <div>
                <div class="title">
                    {{title$ | async}}
                </div>
                <div class="sub-title">
                    {{tagLine$ | async}}
                </div>
            </div>
        </div>
        
    </div>
    
    <div fxFlex fxLayout="row" fxLayoutAlign="center stretch">
        <div class="inner-content">
            <ng-content></ng-content>
        </div>
    </div>
    
</div>