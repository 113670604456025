import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/* eslint-disable import/no-deprecated */
@Injectable()
export class PdfViewerService {

    constructor(private sanitizer: DomSanitizer) { }

    public getPdfBase64(blob: Blob, withHeader: boolean): Observable<string> {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Observable(observer => {
            reader.onloadend = () => {
                const base64data = reader.result as string;
                if (withHeader) {
                    observer.next(base64data);
                } else {
                    observer.next(base64data.substr(base64data.indexOf(',') + 1));
                }
                observer.complete();
            };
        });
    }

    public getPdfObjectUrlFromBase64(base64: string, mimeType: string): SafeResourceUrl {
        const blob = this.base64toBlob(base64, mimeType);
        return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    }

    public base64toBlob(base64Data: string, contentType: string): Blob {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);
            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }
}
