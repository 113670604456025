import { Component } from '@angular/core';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-rich-text',
    templateUrl: './rich-text-observation.component.html',
    outputs: ['valueUpdated', 'selectedChanged']
})
export class RichTextObservationComponent extends ObservationComponent {

    constructor() {
        super();
    }

    init(): void {

    }
}
