import { GroupInviteEffects } from './group-invite.effects';
import { ManageAssessmentSetEffects } from './manage-assessment-set.effects';
import { GroupListSharingEffects } from './group-list-sharing.effects';
import { OrganisationTagEffects } from './organisation-tag.effects';
import { OrganisationManagementTagsEffects } from './organisation-management-tags.effects';

export const effects: any[] = [
    GroupInviteEffects,
    ManageAssessmentSetEffects,
    GroupListSharingEffects,
    OrganisationTagEffects,
    OrganisationManagementTagsEffects
];

export * from './group-invite.effects';
export * from './manage-assessment-set.effects';
export * from './group-list-sharing.effects';
export * from './organisation-tag.effects';
export * from './organisation-management-tags.effects';
