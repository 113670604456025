import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// modles
import { Alert } from './models/alert.model';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// enums
import { AlertType } from './enums/alert-type.enum';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['alert.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AlertComponent extends BaseComponent implements OnInit {
    public notification: Alert;
    public cssClass: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Alert) {
        super();
        this.notification = data;

        switch (this.notification.type) {
            case AlertType.Error:
                this.cssClass = 'error';
                break;
            case AlertType.Info:
                this.cssClass = 'info';
                break;
            case AlertType.Success:
                this.cssClass = 'success';
                break;
            case AlertType.Warning:
                this.cssClass = 'warning';
        }
    }

    ngOnInit() {}

    public close(): void {
    }
}
