// models
import { ItemResponse } from './responses/item-response.model';
import { MultiOptionValueResponse } from './responses/multi-option-value-response.model';
import { ItemObservation } from './item-observation.model';
import { ItemObservationResponse } from './responses/item-observation-response.model';
import { IdDocumentsConfiguration } from 'app/shared/models/id-documents-configuration.model';
import { IdentityVerificationConfiguration } from 'app/shared/modules/identity-verification/models/identity-verification-configuration.model';
import { DocumentsConfiguration } from 'app/shared/models/documents-configuration.model';

// enums
import { ItemType, isEditable } from 'app/shared/enums/item-type.enum';
import { DataConnectorSource } from 'app/shared/enums/data-connector-source.enum';
import { DataConnectorProperty } from 'app/shared/enums/data-connector-property.enum';
import { ConditionResponse } from './responses/condition-response.model';

export class Item {
    id: string;
    setId: string;
    name: string;
    description: string;
    type: ItemType;
    mandatory: boolean;
    readonly: boolean;
    excludeFromScore: boolean;
    confidential: boolean;
    multiOptionValues: MultiOptionValueResponse[];
    conditions: ConditionResponse[];
    enabled: boolean = false;
    pageNumber: number;
    order: number;
    regexExpression: string;
    regexValidationMessage: string;
    itemObservations: ItemObservation[] = [];
    documentsConfiguration: DocumentsConfiguration;
    idDocumentsConfiguration: IdDocumentsConfiguration;
    identityVerificationConfiguration: IdentityVerificationConfiguration;
    dataConnectorPropertyId: DataConnectorProperty;
    dataConnectorSourceId: DataConnectorSource;
    lookupListId;

    // Items for UI display
    documentId: string;
    selected: boolean;

    constructor(itemResponse: ItemResponse, setId: string) {
        this.id = itemResponse.id;
        this.setId = setId;
        this.type = itemResponse.type;
        this.name = itemResponse.name;
        this.description = itemResponse.description;
        this.mandatory = itemResponse.mandatory;
        this.readonly = itemResponse.readonly;
        this.excludeFromScore = itemResponse.excludeFromScore;
        this.confidential = itemResponse.confidential;
        this.multiOptionValues = itemResponse.multiOptionValues;
        this.pageNumber = itemResponse.pageNumber;
        this.order = itemResponse.order;
        this.regexExpression = itemResponse.regexExpression;
        this.regexValidationMessage = itemResponse.regexValidationMessage;
        this.idDocumentsConfiguration = itemResponse.idDocumentsConfiguration;
        this.documentsConfiguration = itemResponse.documentsConfiguration;
        this.dataConnectorSourceId = itemResponse.dataConnectorSourceId;
        this.dataConnectorPropertyId = itemResponse.dataConnectorPropertyId;
        this.identityVerificationConfiguration = itemResponse.identityVerificationConfiguration;
        this.lookupListId = itemResponse.lookupListId;

        if (itemResponse.conditions?.length) {
            this.conditions = itemResponse.conditions;
        } else {
            this.enabled = true;
        }
    }

    addItemObservation(itemObservationResponse: ItemObservationResponse): void {
        const newObservation = new ItemObservation(itemObservationResponse, this.setId, this.id, this.type);
        this.itemObservations.push(newObservation);
    }

    hasValue(value: string = null): boolean {
        return this.itemObservations !== null &&
            this.itemObservations !== undefined &&
            this.itemObservations.filter(io =>
                io.hasValue() &&
                (value === null || io.value === value)).length > 0;
    }

    isEditable(): boolean {
        return isEditable(this.type);
    }

    get changed(): boolean {
        return this.itemObservations.filter(io => io.changed).length > 0;
    }

    conditionsMet(items: Item[]): boolean {
        return this.conditions.every(condition => {
            const enabledOnItem = items.find(i => i.id === condition.enabledOnItem.id);
            const allowedValues = condition.enabledOnValue?.split('|') ?? null;

            if (enabledOnItem.type === ItemType.ShortText) {
                if (allowedValues[0] === '1') {
                    return !!enabledOnItem.itemObservations?.find(io => io.hasValue());
                } else {
                    return !enabledOnItem.itemObservations?.find(io => io.hasValue());
                }
            } else {
                return allowedValues.find(v => enabledOnItem.hasValue(v));
            }
        });
    }

    setEnabled(enabled: boolean): void {
        this.enabled = enabled;
    }

    setAsSaved(): void {
        this.itemObservations.forEach(io => io.changed = false);
    }

    resetObservations(): void {
        if (this.itemObservations.length > 0) {
            this.itemObservations.splice(1);
            this.itemObservations[0].setValue(null);
            this.itemObservations[0].changed = true;
        }
    }
}