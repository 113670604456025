import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// ngrx/rxjs
import { Observable } from 'rxjs';

// services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// Models
import { TokenResponse, RefreshTokenRequest } from 'app/shared/models';
import { LoginDetails, PasswordResetDetails } from 'app/authentication-v2/models';
import { SendResetPasswordEmailRequest } from 'app/authentication-v2/models/send-password-reset-email-request.model';
import { UserTermsOverview } from 'app/authentication-v2/models/user-terms-overview.model';
import { AcceptPlatformTerm } from 'app/authentication-v2/models/accept-platform-term.model';
import { PasswordReset } from 'app/authentication-v2/models/password-reset.model';
import { User } from 'app/shared/models/user.model';
import { ClientTokenResponse } from 'app/models/client-token-response.model';

// Enums
import { TermsType } from 'app/shared/enums/terms-type.enum';
import { TokenLoginDetails } from 'app/authentication-v2/models/token-login-details.model';

@Injectable()
export class AuthenticationV2Service {

    private baseEndpoint = 'api/v2/authentication';

    constructor(
        private http: HttpClient,
        private authenticationTokenService: AuthenticationTokenService) { }

    setClient(clientId: string): Observable<TokenResponse> {
        const params = new HttpParams().append('refreshToken', encodeURIComponent(this.authenticationTokenService.getRefreshToken()));
        const url = `${this.baseEndpoint}/set-client/${clientId}`;
        return this.http.get<TokenResponse>(url, { params });
    }

    setAndPopulateClient(clientId: string): Observable<ClientTokenResponse> {
        const params = new HttpParams().append('refreshToken', encodeURIComponent(this.authenticationTokenService.getRefreshToken()));
        const url = `${this.baseEndpoint}/set-and-populate-client/${clientId}`;
        return this.http.get<ClientTokenResponse>(url, { params });
    }

    rehydrateUser(): Observable<User> {
        const params = new HttpParams().append('refreshToken', encodeURIComponent(this.authenticationTokenService.getRefreshToken()));
        const url = `${this.baseEndpoint}/rehydrate-user`;
        return this.http.get<User>(url, { params });
    }

    refreshToken(data: RefreshTokenRequest): Observable<TokenResponse> {
        const url = `${this.baseEndpoint}/refresh-token`;
        return this.http.post<TokenResponse>(url, data);
    }

    login(data: LoginDetails): Observable<User> {
        const url = `${this.baseEndpoint}/login`;
        return this.http.post<User>(url, data);
    }

    sendPasswordResetEmail(email: string): Observable<boolean> {
        const url = `${this.baseEndpoint}/send-password-reset-email`;
        return this.http.put<boolean>(url, new SendResetPasswordEmailRequest(email));
    }

    resetPassword(data: PasswordResetDetails): Observable<User> {
        const url = `${this.baseEndpoint}/reset-password`;
        return this.http.put<User>(url, data);
    }

    checkTermsShutOutRequired(): Observable<UserTermsOverview> {
        const url = `api/user-terms/overview`;
        return this.http.get<UserTermsOverview>(url);
    }

    getRegistrationTerm(type: TermsType): Observable<string> {
        const url = `api/user-terms/text/${type}`;
        return this.http.get<string>(url);
    }

    acceptUserTerms(terms: AcceptPlatformTerm[]): Observable<void> {
        const url = `api/user-terms/accept`;
        return this.http.post<void>(url, terms);
    }

    getResetPasswordLink(email: string): Observable<PasswordReset> {
        const url = `${this.baseEndpoint}/reset-password-link`;
        return this.http.post<PasswordReset>(url, new SendResetPasswordEmailRequest(email));
    }

    isPasswordLinkValid(email: string): Observable<boolean> {
        const url = `${this.baseEndpoint}/reset-password-link/valid`;
        return this.http.post<boolean>(url, {email});
    }

    setTemporaryPassword(data: PasswordResetDetails): Observable<boolean> {
        const url = `${this.baseEndpoint}/set-temporary-password`;
        return this.http.post<boolean>(url, data);
    }

    tokenLogin(tokenDetails: TokenLoginDetails): Observable<User> {
        const url = `${this.baseEndpoint}/token-login`;
        return this.http.post<User>(url, { token: tokenDetails.token, userGroupId: tokenDetails.userGroupId });
    }

    setUserGroup(clientId: string, userGroupId: string): Observable<TokenResponse> {
        const params = new HttpParams().append('refreshToken', encodeURIComponent(this.authenticationTokenService.getRefreshToken()));
        const url = `${this.baseEndpoint}/client/${clientId}/user-group/${userGroupId}`;
        return this.http.get<TokenResponse>(url, { params });
    }

    clearUserGroup(): Observable<TokenResponse> {
        const params = new HttpParams().append('refreshToken', encodeURIComponent(this.authenticationTokenService.getRefreshToken()));
        const url = `${this.baseEndpoint}/clear-user-group`;
        return this.http.get<TokenResponse>(url, { params });
    }
}
