import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Models
import { IdDocumentsResponse } from 'app/shared/modules/set-observations/models/responses/id-documents-response.model';
import { IdDocumentResponse } from 'app/shared/modules/set-observations/models/responses/id-document-response.model';
import { ImagePopupData } from 'app/shared/models/image-popup-data.model';

// Enums
import { CheckResult, getCheckResultTitle } from 'app/shared/enums';

// Services
import { OrganisationsService } from 'app/shared/services';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// Components
import { ImagePopupComponent } from 'app/shared/components/image-popup/image-popup.component';
import { DocumentSide } from 'app/shared/modules/set-observations/enums/document-side.enum';
import { CheckMethod } from 'app/shared/enums/check-method.enum';

@Component({
    selector: 'app-id-documents-list',
    templateUrl: './id-documents-list.component.html',
    styleUrls: ['./id-documents-list.component.scss']
})
export class IdDocumentsListComponent implements OnChanges, OnInit {

    DocumentStatus = CheckResult;

    @Input()
    idDocuments: IdDocumentsResponse = null;

    @Input()
    idDocumentsJson: string = null;

    @Input()
    organisationId: string;

    viewFullDetails: boolean = false;

    loadingDocument: boolean = false;

    constructor(
        private dialogs: MatDialog,
        private organisationService: OrganisationsService,
        private authenticationTokenService: AuthenticationTokenService,
        private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.viewFullDetails = this.authenticationTokenService.isClient();
    }

    ngOnChanges(): void {
        if (this.idDocumentsJson) {
            this.idDocuments = JSON.parse(this.idDocumentsJson);
        }

        if (this.idDocuments && this.idDocuments.documents) {
            this.idDocuments.documents.sort((d1, d2) => new Date(d2.dateCreated).getTime() - new Date(d1.dateCreated).getTime());
        }
    }

    getDocumentStatusTitle(document: IdDocumentResponse): string {
        const status = getCheckResultTitle(document.documentStatus);
        if (!this.viewFullDetails) {
            return status === 'Pass' ? status : 'Successfully submitted';
        }
        if (this.isExpired(document)) {
            return 'Expired';
        }
        return status;
    }

    getClass(document: IdDocumentResponse): string {
        if (!this.viewFullDetails) {
            return 'pass';
        }
        return this.getDocumentStatusTitle(document).toLowerCase();
    }

    getIcon(document: IdDocumentResponse): string {
        if (!this.viewFullDetails) {
            return 'check_circle';
        } else {
            if (this.isExpired(document)) {
                return 'error';
            }
            switch (document.documentStatus) {
               case CheckResult.Unknown: return 'contact_support';
               case CheckResult.Pass: return 'check_circle';
               case CheckResult.Refer: return 'error';
               case CheckResult.Fail: return 'cancel';
           }
        }
    }

    getResultStatusTitle(result: boolean, documentSide: DocumentSide): string {
        if (result) {
            return 'Passed';
        } else if (documentSide === DocumentSide.Back) {
            return 'N/A';
        } else {
            return this.viewFullDetails ?  'Failed' : 'Processing';
        }
    }

    getNameCheckStatusTitle(idDocument: IdDocumentResponse): string {
        let checkMethod = '';
        switch(idDocument.nameCheckMethod) {
            case CheckMethod.Automatic:
                checkMethod = 'Auto ';
                break;
            case CheckMethod.Manual:
                checkMethod = 'Backoffice ';
                break;
            default:
                checkMethod = '';
                break;
        }

        if (idDocument.nameCheck) {
            return `${checkMethod}Verified`;
        } else if (idDocument.documentSide === DocumentSide.Back) {
            return 'N/A';
        } else {
            return this.viewFullDetails ?  `${checkMethod}Failed` : 'Processing';
        }
    }

    viewDocument(idDocument: IdDocumentResponse): void {
        if (idDocument.documentUrl) {
            this.showImagePopup(idDocument);
        } else {
            this.loadingDocument = true;
            this.changeDetectorRef.detectChanges();

            this.organisationService.getIdDocumentUrl(this.organisationId, idDocument.documentId)
            .subscribe(documentUrl => {
                this.loadingDocument = false;
                this.changeDetectorRef.detectChanges();

                idDocument.documentUrl = documentUrl;
                this.showImagePopup(idDocument);
            });
        }
    }

    isExpired(document: IdDocumentResponse): boolean {
        if (document.expiryDate) {
            const expDate: Date = new Date(document.expiryDate as any);
            const dateNow = new Date();
            if (expDate < dateNow) {
                return true;
            }
        }
        return false;
    }

    private showImagePopup(idDocument: IdDocumentResponse): void {
        this.dialogs.open(ImagePopupComponent, {
            data: new ImagePopupData(idDocument.documentUrl, `ID Document - ${idDocument.description}`)
        });
    }
}