<mat-tab-group *ngIf="showTabs">
    <mat-tab *ngFor="let category of categories">
        <ng-template mat-tab-label>
            <mat-icon *ngIf="category.hasAlerts">warning</mat-icon>
            <div>{{category.title}}</div>
        </ng-template>
        <div fxLayout="column" fxLayoutGap="10px">
            <app-assessment-profile-assessment-set
                *ngFor="let set of category.sets"
                [assessmentProfileSet]="set"
                (showAssessmentSet)="onShowAssessmentSet($event)"
                [readonly]="readonly"
                [initiallyExpanded]="initiallyExpanded">
            </app-assessment-profile-assessment-set>
        </div>
    </mat-tab>
</mat-tab-group>
<div id="profile-question-sets"  *ngIf="!showTabs">
    <div *ngFor="let category of categories">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-icon *ngIf="category.hasAlerts">warning</mat-icon>
            <h4>{{category.title}}</h4>
        </div>
        <div fxLayout="column" fxLayoutGap="10px">
            <app-assessment-profile-assessment-set
                *ngFor="let set of category.sets"
                [assessmentProfileSet]="set"
                (showAssessmentSet)="onShowAssessmentSet($event)"
                [readonly]="readonly"
                [initiallyExpanded]="initiallyExpanded">
            </app-assessment-profile-assessment-set>
        </div>
    </div>
</div>