<div class="esign-document-observation" fxLayout="column" fxLayoutGap="16px">
    <app-loading-card *ngIf="!alreadySigned && !esignDocument"></app-loading-card>

    <div *ngIf="!alreadySigned" [hidden]="!esignDocument?.documentBase64" class="pdf-viewer">
        <ng2-pdfjs-viewer #pdfViewer zoom="65"></ng2-pdfjs-viewer>
    </div>

    <div *ngIf="esignDocument?.esignDocumentUrl" class="iframe-wrapper">
        <div class="inner-container">
            <iframe scrolling="no" [src]="esignDocument.esignDocumentUrl | safe: 'resourceUrl'"></iframe>
        </div>
    </div>

    <div *ngIf="esignDocument?.documentBase64 || alreadySigned" fxLayout="column" fxLayoutGap="8px" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="start center">
        <app-esign-signature
            fxFlex
            [alreadySigned]="alreadySigned"
            [signed]="itemObservation.hasValue()"
            [signatureName]="signature ? signature.name : user.firstName + ' ' + user.surname"
            [signatureDate]="signature?.signedDate"
            (signatureCaptured)="handleSignatureCaptured($event)">
        </app-esign-signature>

        <div *ngIf="alreadySigned && esignDocument?.documentBase64" class="esign-document-download">
            <app-esign-document-download [item]="item" [itemObservation]="itemObservation"></app-esign-document-download>
        </div>
    </div>
    <div *ngIf="(recordingObservation$ | async)">
        <app-loading-card message="Please wait while we sign the document..."></app-loading-card>
    </div>
</div>