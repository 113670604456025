import { HostListener } from '@angular/core';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// Models
import { ImagePopupData } from 'app/shared/models/image-popup-data.model';

@Component({
    templateUrl: './image-popup.component.html',
    styleUrls: ['./image-popup.component.scss']
})
export class ImagePopupComponent {

    @ViewChild('origImage') origImageElement: ElementRef;
    @ViewChild('resultImage') zoomResultElement: ElementRef;

    imageUrl: any;
    title: string;

    zoomSquareSize = 50;
    zoomRatio: number;
    zoomWidth: number;
    zoomHeight: number;
    zoomX: number;
    zoomY: number;
    doZoom: boolean = false;

    origImageWidth: number;
    origImageHeight: number;
    origImageTop: number;
    origImageLeft: number;

    constructor(@Inject(MAT_DIALOG_DATA) data: ImagePopupData) {
        this.imageUrl = data.imageUrl;
        this.title = data.title;
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(event): void {
        if (this.doZoom) {
            // Calculate the position of the cursor
            const rect = this.origImageElement.nativeElement.getBoundingClientRect();
            const x = event.clientX - rect.left - (this.zoomSquareSize / 2);
            const y = event.clientY - rect.top -  (this.zoomSquareSize / 2);
            this.zoomX = - (x * this.zoomRatio) ;
            this.zoomY = - (y * this.zoomRatio);
        }
    }

    onLoad($event) {
        this.origImageWidth = $event.target.width;
        this.origImageHeight = $event.target.height;
        this.startZoom();
    }

    onMouseEnter(): void {
        this.doZoom = true;
    }

    onMouseLeave(): void {
        this.doZoom = false;
    }

    private startZoom(): void {
        // Calculate the ratio for the zoom result
        this.zoomRatio = this.zoomResultElement.nativeElement.offsetWidth / this.zoomSquareSize;
        // Get the dimensions of the original image
        this.zoomWidth = this.origImageWidth * this.zoomRatio;
        this.zoomHeight = this.origImageHeight * this.zoomRatio;
    }
}