// Actions
import * as OrganisationManagementTagsActions from 'app/shared/modules/group/store/actions/organisation-management-tags.actions';

// Models
import { AssessmentProfileTagResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-tag-response.model';

export interface OrganisationManagementTagsState {
    loading: boolean;
    tags: AssessmentProfileTagResponse[];
}

const initialState: OrganisationManagementTagsState = {
    loading: false,
    tags: null
};

export function reducer(state = initialState, action: OrganisationManagementTagsActions.AllOrganisationManagementTagActions): OrganisationManagementTagsState {
    switch (action.type) {
        case OrganisationManagementTagsActions.GET_ORGANISATION_TAGS: {
            return {
                ...state,
                loading: true,
                tags: null
            };
        }
        case OrganisationManagementTagsActions.GET_ORGANISATION_TAGS_SUCCESS: {
            return {
                ...state,
                loading: false,
                tags: action.payload
            };
        }
        case OrganisationManagementTagsActions.GET_ORGANISATION_TAGS_FAIL: {
            return {
                ...state,
                loading: false,
                tags: null
            };
        }
        default: {
            return state;
        }
    }
}