import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControlOptions } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// ngrx/rxjs
import { takeUntil, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Store
import * as fromAuth from 'app/authentication/store';
import * as fromConnect from 'app/connect/store';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Services
import { AuthenticationService } from 'app/authentication/services/authentication.service';
import { ValidationService } from 'app/shared/services';
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { AuthenticationEventTrackingService } from 'app/authentication/services/authentication-event-tracking.service';

// Models
import { RegExRules } from 'app/shared/models';
import { PasswordValidation, FirstLogin } from 'app/authentication/models';
import { User } from 'app/shared/models/user.model';

@Component({
    templateUrl: 'reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
    working$: Observable<boolean>;

    form: FormGroup;
    regexRules: RegExRules = new RegExRules();

    email: string;
    token: string;
    firstLogin: boolean = false;
    invitation: boolean = false;

    hidePassword: boolean = true;
    hideConfirmPassword: boolean = true;
    loggedIn: boolean = false;
    title = 'Set Your Password';
    tempPasswordReset: boolean = false;

    constructor(
        private store: Store<fromAuth.AuthenticationState>,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private authService: AuthenticationService,
        public validationService: ValidationService,
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private alertService: AlertService,
        private authenticationEventTrackingService: AuthenticationEventTrackingService,
        private router: Router,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        this.working$ = this.store.pipe(select(fromAuth.getAuthenticationWorking));

        this.route.url.pipe(
            takeUntil(this.ngUnsubscribe),
            map(urlSegments => {
                if (urlSegments.length === 1 && urlSegments[0].path === 'set-password') {
                    this.invitation = true;
                }

                this.buildForm();

                this.checkParameters();

            }))
            .subscribe();

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getResetPasswordOnLogin))
            .subscribe(resetPasswordDetails => {
                if (resetPasswordDetails) {
                    this.email = resetPasswordDetails.email;
                    this.token = resetPasswordDetails.token;
                    this.setEmail(this.email);
                    this.setToken(this.token);
                    this.tempPasswordReset = true;
                } else {
                    this.tempPasswordReset = false;
                }
            });

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getUser))
            .subscribe((user: User) => this.loggedIn = user !== null);

    }

    setToken(token: string) {
        this.form.get('token').setValue(token);
    }

    setEmail(email: string) {
        this.form.get('email').setValue(email);

        // TODO CONNECT - Put into a store
        this.authService.hasResetTokenBeenUsed(email).subscribe((result) => {
            if (result) {
                this.authenticationEventTrackingService.redirectingExistingUser();
                this.router.navigate(['/auth/login']);
            }
        });
    }

    resetPassword() {
        this.store.dispatch(new fromAuth.SetPassword(this.form.value));
    }

    private checkParameters(): void {
        this.route.queryParams.pipe(
            takeUntil(this.ngUnsubscribe),
            map(params => {
                if (params.token) {
                    this.token = decodeURIComponent(params.token);
                }

                if (params.email) {
                    this.email = decodeURIComponent(params.email);
                }

                if (params.invitation) {
                    this.invitation = true;
                    this.redirectIfNotFirstLogin();
                }

                if (this.email) {
                    this.setEmail(this.email);
                }

                if (this.token) {
                    this.setToken(params.token);
                }

                this.translate.get('titles.platform').subscribe((text: string) => {
                    this.title = this.invitation ? `Welcome to ${text}. To continue, please set your password.` : 'Set Your Password';
                });
            }))
            .subscribe();
    }

    private buildForm() {
        this.form = this.fb.group({
            token: [''],
            email: ['', [Validators.required]],
            termsAccepted: [false, this.invitation ? [Validators.required, Validators.requiredTrue] : null],
            password: ['', [Validators.required, Validators.pattern(this.regexRules.password), Validators.minLength(9)]],
            confirmPassword: ['', [Validators.required, Validators.pattern(this.regexRules.password), Validators.minLength(9)]],
        },
        <AbstractControlOptions>{
                validators: PasswordValidation.matchPassword
            });
    }

    private redirectIfNotFirstLogin() {
        if (!this.email) {
            return;
        }

        const request = new FirstLogin(this.email, this.invitation);

        this.authService.isFirstLogin(request).subscribe((result) => {
            if (!result) {
                this.router.navigate(['/auth/login']);
            }
        });
    }
}