import { Injectable } from '@angular/core';

// ngrx / rxjs
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

// Store
import * as TermsActions from 'app/shared/modules/terms-obsolete/store/actions/terms.actions';

// Services
import { TermsService } from 'app/shared/modules/terms-obsolete/services/terms.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// Models
import { TermsResponse } from 'app/shared/modules/terms-obsolete/models/responses/terms-response.model';
import { CreateTermsRequest } from 'app/shared/modules/terms-obsolete/models/requests/create-terms-request.model';
import { UpdateTermsRequest } from 'app/shared/modules/terms-obsolete/models/requests/update-terms-request.model';

@Injectable()
export class TermsEffects {


    getTerms$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsActions.GET_TERMS),
        map((action: TermsActions.GetTerms) => action.payload),
        mergeMap((termsId: string) =>
            this.termsService.getTerms(termsId).pipe(
                map((terms: TermsResponse) => new TermsActions.GetTermsSuccess(terms)),
                catchError(() => of(new TermsActions.GetTermsFail()))
    ))));


    getTermsFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsActions.GET_TERMS_FAIL),
        tap(() => {
            this.alertService.error('Error occurred getting terms');
        })
    ), { dispatch: false });


    getOrganisationTerms$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsActions.GET_ORGANISATION_TERMS),
        map((action: TermsActions.GetOrganisationTerms) => action.payload),
        mergeMap((organisationId: string) =>
            this.termsService.getTermsForOrganisation(organisationId).pipe(
                map((terms: TermsResponse[]) => new TermsActions.GetOrganisationTermsSuccess(terms)),
                catchError(() => of(new TermsActions.GetOrganisationTermsFail()))
    ))));


    getOrganisationTermsFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsActions.GET_TERMS_FAIL),
        tap(() => {
            this.alertService.error('Error occurred getting terms for organisation');
        })
    ), { dispatch: false });


    createTerms$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsActions.CREATE_TERMS),
        map((action: TermsActions.CreateTerms) => action.payload),
        mergeMap((request: CreateTermsRequest) =>
            this.termsService.createTerms(request).pipe(
                map((terms: TermsResponse) => new TermsActions.CreateTermsSuccess(terms)),
                catchError(() => of(new TermsActions.CreateTermsFail()))
    ))));


    createTermsSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsActions.CREATE_TERMS_SUCCESS),
        tap(() => {
            this.alertService.success('New terms created successfully');
        })
    ), { dispatch: false });


    createTermsFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsActions.CREATE_TERMS_FAIL),
        tap(() => {
            this.alertService.error('Error occurred creating terms');
        })
    ), { dispatch: false });


    updateTerms$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsActions.UPDATE_TERMS),
        map((action: TermsActions.UpdateTerms) => action.payload),
        mergeMap((request: UpdateTermsRequest) =>
            this.termsService.updateTerms(request).pipe(
                map((terms: TermsResponse) => new TermsActions.UpdateTermsSuccess(terms)),
                catchError(() => of(new TermsActions.UpdateTermsFail()))
    ))));


    updateTermsFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsActions.UPDATE_TERMS_FAIL),
        tap(() => {
            this.alertService.error('Error occurred updating terms. Make sure the version number has been updated');
        })
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private termsService: TermsService,
        private alertService: AlertService
    ) { }
}
