// ngrx
import { createFeatureSelector, createSelector } from '@ngrx/store';

// store
import * as reducers from 'app/shared/modules/mobile-photo/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<reducers.MobilePhotoStoreState>(StoreNames.mobilePhotoStore);
const reducerState = createSelector(storeState, (state: reducers.MobilePhotoStoreState) => state.mobilePhoto);

export const getMobileToken = createSelector(reducerState, (state: reducers.MobilePhotoState) => state.token);
export const getLoadingToken = createSelector(reducerState, (state: reducers.MobilePhotoState) => state.loadingToken);
export const getSavingImage = createSelector(reducerState, (state: reducers.MobilePhotoState) => state.savingImage);
export const getPolling = createSelector(reducerState, (state: reducers.MobilePhotoState) => state.polling);
export const getUapImage = createSelector(reducerState, (state: reducers.MobilePhotoState) => state.uapImage);
export const getLoadingUapImage = createSelector(reducerState, (state: reducers.MobilePhotoState) => state.loadingUapImage);
