import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAuth from 'app/authentication/store/reducers/auth.reducers';

import { AuthenticationState } from 'app/authentication/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<AuthenticationState>(StoreNames.authentication);
const reducerState = createSelector(storeState, (state: AuthenticationState) => state.loggedInSessionState);

export const getAuthenticationWorking = createSelector(reducerState, (state: fromAuth.LoggedInSessionState) => state.working);
export const getAuthenticationMessage = createSelector(reducerState, (state: fromAuth.LoggedInSessionState) => state.authenticationMessage);
export const getInviteDetails = createSelector(reducerState, (state: fromAuth.LoggedInSessionState) => state.inviteDetails);
export const getRegistrationTermsText = createSelector(reducerState, (state: fromAuth.LoggedInSessionState) => state.registrationTermsText);
export const getRegisteredFlag = createSelector(reducerState, (state: fromAuth.LoggedInSessionState) => state.registered);

export const getValidating = createSelector(reducerState, (state: fromAuth.LoggedInSessionState) => state.validating);
export const getRegistrationError = createSelector(reducerState, (state: fromAuth.LoggedInSessionState) => state.registrationError);
export const getResetPasswordOnLogin = createSelector(reducerState, (state: fromAuth.LoggedInSessionState) => state.passwordResetOnLogin);