import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Models
import { OrganisationTitle } from 'app/shared/models/organisation-title.model';
import { CreateDocumentSignatureRequest } from 'app/portal/modules/document-library-v1/models/create-document-signature.model';

// Services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

@Component({
    selector: 'app-document-wizard-step-3',
    templateUrl: './document-wizard-step-3.component.html',
    styleUrls: ['./document-wizard-step-3.component.scss']
})
export class DocumentWizardStep3Component implements OnInit {
    @Input()
    form: FormGroup;

    @Input()
    organisations: OrganisationTitle[];

    @Output()
    selectedChanged: EventEmitter<OrganisationTitle> = new EventEmitter();

    availableOrganisations: OrganisationTitle[];
    signatureOrganisations: OrganisationTitle[];
    dataOrganisations: OrganisationTitle[];

    hasDataOrganisations: boolean = false;

    constructor(private authenticationTokenService: AuthenticationTokenService) { }

    ngOnInit(): void {
        this.form.get('documentShares').valueChanges.subscribe(() => {
            this.populateOrganisations();
        });

        this.populateOrganisations();
        this.signatureOrganisations.forEach(s => this.handleSelectedChanged(true, s));
    }

    handleSelectedChanged(selected: boolean, organisation: OrganisationTitle): void {
        organisation.selected = selected;
        this.selectedChanged.emit(organisation);
    }

    private populateOrganisations(): void {
        const signatures = (<FormGroup[]>this.form.get('documentSignatures').value).map(f => <CreateDocumentSignatureRequest>f.value);
        const myOrganisationId = this.authenticationTokenService.clientId();

        this.availableOrganisations = [
            ...this.organisations.filter(o => o.selected && o.id !== myOrganisationId && signatures.filter(s => s.organisationId === o.id).length === 0),
            ...this.organisations.filter(o => !o.selected && o.id !== myOrganisationId && signatures.filter(s => s.organisationId === o.id).length === 0)];

        this.signatureOrganisations = this.organisations.filter(o =>
            signatures.filter(s => s.organisationId === o.id && s.requiredToSign).length > 0);

        this.dataOrganisations = this.organisations.filter(o =>
            signatures.filter(s => s.organisationId === o.id && !s.requiredToSign).length > 0);

        if (this.dataOrganisations.length > 0) {
            this.hasDataOrganisations = true;
        }
    }
}
