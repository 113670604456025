<div class="identity-verification" fxFlex="100">
    <mat-horizontal-stepper *ngIf="stepIndex > -1" [linear]="false" [selectedIndex]="stepIndex">
        <mat-step>
            <app-idv-selfie-step
                (selfieTaken)="handleSelfieTaken($event)"
                (switchToMobile)="handleSwitchToMobile()">
            </app-idv-selfie-step>
        </mat-step>
        <mat-step>
            <app-idv-confirmation-step
                *ngIf="!isMobileDevice && selfieBase64Image"
                [base64Image]="selfieBase64Image"
                (confirmed)="handleSelfieAccepted()"
                (rejected)="handleSelfieRejected()">
            </app-idv-confirmation-step>
        </mat-step>
        <mat-step>
            <app-idv-uap-step
                [uapImageUrl]= "idvData?.uapActionImageUrl"
                (uapTaken)="handleUapTaken($event)"
                (switchToMobile)="handleSwitchToMobile()">
            </app-idv-uap-step>
        </mat-step>
        <mat-step>
            <app-idv-confirmation-step
                *ngIf="!isMobileDevice && uapBase64Image"
                [base64Image]="uapBase64Image"
                (confirmed)="handleUapAccepted()"
                (rejected)="handleUapRejected()">
            </app-idv-confirmation-step>
        </mat-step>
        <mat-step>
            <app-idv-id-document-step
                [configuration]="configuration"
                (idDocumentTaken)="handleIdDocumentTaken($event)"
                (idDocumentTypeSelected)="handleIdDocumentTypeSelected($event)"
                (switchToMobile)="handleSwitchToMobile()">
            </app-idv-id-document-step>
        </mat-step>
        <mat-step>
            <app-idv-confirmation-step
                *ngIf="!isMobileDevice && idDocumentBase64Image"
                [base64Image]="idDocumentBase64Image"
                (confirmed)="handleIdDocumentAccepted()"
                (rejected)="handleIdDocumentRejected()">
            </app-idv-confirmation-step>
        </mat-step>
        <mat-step>
            <app-idv-document-rejected-step
                (retakeClicked)="handleRetakeIdDocument()">
            </app-idv-document-rejected-step>
        </mat-step>
        <mat-step>
            <app-idv-complete-step
                [configuration]="configuration"
                [showRetakeButtons]="showRetakeButtons"
                (retakeSelfieClicked)="handleRetakeSelfieClicked()"
                (addIdDocumentClicked)="handleAddIdDocumentClicked()">
            </app-idv-complete-step>
        </mat-step>
    </mat-horizontal-stepper>
</div>
<app-loading-card *ngIf="recordingSelfie$ | async" message="Please wait while we upload your selfie"></app-loading-card>
<app-loading-card *ngIf="recordingUap$ | async" message="Please wait while we upload your liveness action"></app-loading-card>
<app-loading-card *ngIf="recordingIdDocument$ | async" message="Please wait while we upload and analyse your ID document"></app-loading-card>