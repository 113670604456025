<div class="loading-container" *ngIf="!useSpinner" fxLayout="column" fxLayoutAlign="center center">
    <div class="inner primary-bg" fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="center center">
        <mat-progress-spinner mode="indeterminate" diameter="75"></mat-progress-spinner>
        <div class="secondary-text align-center" *ngIf="message">{{ message }}</div>
    </div>
    
    
</div>
<div class="loading-spinner" *ngIf="useSpinner">
    <mat-progress-spinner mode="indeterminate" [diameter]="spinnerSize"></mat-progress-spinner>
</div>