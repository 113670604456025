import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { showHideAnimation } from 'app/shared/utilities/animation-utilities';

@Component({
    selector: 'app-section-wrapper',
    templateUrl: 'section-wrapper.component.html',
    styleUrls: ['./section-wrapper.component.scss'],
    animations: showHideAnimation,
    encapsulation: ViewEncapsulation.None
})
export class SectionWrapperComponent {
    @Input()
    sectionTitle: string;

    @Input()
    expanded: boolean = false;

    @Input()
    showEdit: boolean = false;

    @Output()
    editClicked: EventEmitter<void> = new EventEmitter();

    handleEditClicked(): void {
        this.editClicked.emit();
    }

    get contentState(): string {
        return this.expanded ? 'show' : 'hide';
    }
}

