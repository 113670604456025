import { ActionReducerMap } from '@ngrx/store';

import * as fromActiveUsers from './active-user.reducers';
import * as fromGlobalUi from './user-interface.reducers';
import * as fromUserActivity from './user-activity.reducers';
import * as fromRouter from './router.reducers';

export interface State {
    activeUser: fromActiveUsers.State;
    globalUi: fromGlobalUi.State;
    userActivity: fromUserActivity.UserActivityState;
    router: fromRouter.State;
}

export const reducers: ActionReducerMap<State> = {
    activeUser: fromActiveUsers.reducer,
    globalUi : fromGlobalUi.reducer,
    userActivity: fromUserActivity.reducer,
    router: fromRouter.reducer
};