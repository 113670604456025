<app-card-display [cardTitle]="document.name" class="group-platform-card document-card" [ngClass]="{'active': document.enabled, 'inactive': !document.enabled}" (cardClick)="openDocument()">
    <div card-title-extras>
        <mat-icon
            *ngIf="!document.isOwner || document.sharedWithOrganisations?.length"
            [title]="sharingTitle">
            assignment_ind
        </mat-icon>
    </div>
    <div menu-items>
        <button mat-menu-item title="Download" (click)="downloadDocument()">
            <mat-icon aria-label="Download">archive</mat-icon>
            <span>Download</span>
        </button>
        <button mat-menu-item *ngIf="(document | canDisplayDocumentMenu) && document.isOwner" (click)="shareDocument()" [title]="'Share ' + document.name">
            <mat-icon>share</mat-icon>
            <span>Share</span>
        </button>
        <button mat-menu-item *ngIf="(document | canDisplayDocumentMenu) && document.canDelete" (click)="deleteDocument()" [title]="'Delete ' + document.name">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
        </button>
    </div>

    <mat-card-content card-content>
        <div class="content" fxFlex fxLayout="column" fxLayoutGap="8px">
            <div class="primary-text" fxLayout="row" fxLayoutAlign="space-between start">
                <label>Type:</label><div>{{getDocumentTypeLabel(document.documentType)}}</div>
            </div>
            <div class="primary-text" fxLayout="row" fxLayoutAlign="space-between start">
                <label>Revision:</label><div>{{document.revision}}</div>
            </div>
            <div class="primary-text" fxLayout="row" fxLayoutAlign="space-between start">
                <label>Signature Type:</label><div>{{document.signatureType | signatureTypeDescription}}</div>
            </div>
            <div class="primary-text" fxLayout="row" fxLayoutAlign="space-between start">
                <label>Date created:</label><div>{{ document.dateCreated | ukdate }}</div>
            </div>
            <div class="primary-text" fxLayout="row" fxLayoutAlign="space-between start">
                <label>Date updated:</label><div>{{ document.dateUpdated | ukdate }}</div>
            </div>
        </div>
    </mat-card-content>
</app-card-display>
