import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// services
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { VideoCaptureService } from 'app/shared/services';

// enums
import { CameraFacingMode } from 'app/shared/enums/camera-facing-mode.enum';

@Component({
    templateUrl: './take-photo-dialog.component.html',
    styleUrls: ['./take-photo-dialog.component.scss']
})
export class TakePhotoDialogComponent implements OnInit {

    @ViewChild('videoDisplay')
    videoElement: ElementRef;

    @ViewChild('canvasElement')
    canvasElement: ElementRef;

    cameraStarted = false;
    webcamAccess = false;
    multipleDevices: boolean;
    private deviceId: string;
    private videoStream: MediaStream;
    private devices: MediaDeviceInfo[];

    constructor(
        private dialogRef: MatDialogRef<TakePhotoDialogComponent>,
        private alertService: AlertService,
        private videoCaptureService: VideoCaptureService) { }

    ngOnInit(): void {
        this.initialiseWebcam();
    }

    handleSwitchWebcamClick(): void {
        this.stopWebCam();
        this.startSpecifiedWebcam(this.chooseNextDevice());
    }

    handleTakePhotoClick(): void {
        const canvas = this.canvasElement.nativeElement;
        const video = this.videoElement.nativeElement;
        const dataUrl = this.videoCaptureService.getImageFromVideo(video, canvas);
        this.stopWebCam();
        this.dialogRef.close(dataUrl);
    }

    private initialiseWebcam() {
        this.videoCaptureService.getAvailableVideoDevices()
            .subscribe((devices) => {
                this.devices = devices;
                this.webcamAccess = devices.length > 0;
                this.multipleDevices = devices.length > 1;

                this.startFrontWebcam();
            });
    }

    private startFrontWebcam() {
        this.videoCaptureService.startWebcam(CameraFacingMode.Front)
            .subscribe((stream) => {
                this.videoElement.nativeElement.srcObject = stream;
                this.videoStream = stream;
                const track = this.videoStream.getTracks()[0];
                this.deviceId = this.deviceId = track.getSettings().deviceId;
                this.cameraStarted = true;
            }, (errorMsg: any) => {
                this.alertService.error(errorMsg);
                this.cameraStarted = false;
            });
    }

    private startSpecifiedWebcam(deviceId: string) {
        this.videoCaptureService.startSpecifiedWebcam(deviceId)
            .subscribe((stream) => {
                this.videoElement.nativeElement.srcObject = stream;
                this.videoStream = stream;
                this.deviceId = deviceId;
                this.cameraStarted = true;
            }, (errorMsg: any) => {
                this.alertService.error(errorMsg);
                this.cameraStarted = false;
            });
    }

    private stopWebCam() {
        if (this.cameraStarted) {
            this.videoCaptureService.stopWebcam(this.videoElement.nativeElement, this.videoStream);
            this.cameraStarted = false;
        }
    }

    private chooseNextDevice(): string {
        const currentDeviceIndex = this.devices.findIndex(d => d.deviceId === this.deviceId);
        const nextDeviceIndex = (currentDeviceIndex + 1 === this.devices.length) ? 0 : currentDeviceIndex + 1;
        return this.devices[nextDeviceIndex].deviceId;
    }
}