import { Action } from '@ngrx/store';

// Models
import { TermsResponse } from 'app/shared/modules/terms-obsolete/models/responses/terms-response.model';
import { CreateTermsRequest } from 'app/shared/modules/terms-obsolete/models/requests/create-terms-request.model';
import { UpdateTermsRequest } from 'app/shared/modules/terms-obsolete/models/requests/update-terms-request.model';

// Action Types
export const GET_TERMS = '[Terms] Get terms';
export const GET_TERMS_SUCCESS = '[Terms] Get terms success';
export const GET_TERMS_FAIL = '[Terms] Get terms fail';

export const GET_ORGANISATION_TERMS = '[Terms] Get organisation terms';
export const GET_ORGANISATION_TERMS_SUCCESS = '[Terms] Get organisation terms success';
export const GET_ORGANISATION_TERMS_FAIL = '[Terms] Get organisation terms fail';

export const CREATE_TERMS = '[Terms] Create terms';
export const CREATE_TERMS_SUCCESS = '[Terms] Create terms success';
export const CREATE_TERMS_FAIL = '[Terms] Create terms fail';

export const UPDATE_TERMS = '[Terms] Update terms';
export const UPDATE_TERMS_SUCCESS = '[Terms] Update terms success';
export const UPDATE_TERMS_FAIL = '[Terms] Update terms fail';

// Action initiators
export class GetTerms implements Action {
    readonly type = GET_TERMS;
    constructor(public payload: string) { }
}

export class GetTermsSuccess implements Action {
    readonly type = GET_TERMS_SUCCESS;
    constructor(public payload: TermsResponse) { }
}

export class GetTermsFail implements Action {
    readonly type = GET_TERMS_FAIL;
}

export class GetOrganisationTerms implements Action {
    readonly type = GET_ORGANISATION_TERMS;
    constructor(public payload: string) { }
}

export class GetOrganisationTermsSuccess implements Action {
    readonly type = GET_ORGANISATION_TERMS_SUCCESS;
    constructor(public payload: TermsResponse[]) { }
}

export class GetOrganisationTermsFail implements Action {
    readonly type = GET_ORGANISATION_TERMS_FAIL;
}

export class CreateTerms implements Action {
    readonly type = CREATE_TERMS;
    constructor(public payload: CreateTermsRequest) { }
}

export class CreateTermsSuccess implements Action {
    readonly type = CREATE_TERMS_SUCCESS;
    constructor(public payload: TermsResponse) { }
}

export class CreateTermsFail implements Action {
    readonly type = CREATE_TERMS_FAIL;
}

export class UpdateTerms implements Action {
    readonly type = UPDATE_TERMS;
    constructor(public payload: UpdateTermsRequest) { }
}

export class UpdateTermsSuccess implements Action {
    readonly type = UPDATE_TERMS_SUCCESS;
    constructor(public payload: TermsResponse) { }
}

export class UpdateTermsFail implements Action {
    readonly type = UPDATE_TERMS_FAIL;
}

export type AllTermsActions
    = GetTerms
    | GetTermsSuccess
    | GetTermsFail
    | GetOrganisationTerms
    | GetOrganisationTermsSuccess
    | GetOrganisationTermsFail
    | CreateTerms
    | CreateTermsSuccess
    | CreateTermsFail
    | UpdateTerms
    | UpdateTermsSuccess
    | UpdateTermsFail;
