import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Models
import { CreateTermsRequest } from 'app/shared/modules/terms-obsolete/models/requests/create-terms-request.model';
import { TermsResponse } from 'app/shared/modules/terms-obsolete/models/responses/terms-response.model';
import { UpdateTermsRequest } from 'app/shared/modules/terms-obsolete/models/requests/update-terms-request.model';
import { TermsOverview } from 'app/shared/modules/terms-obsolete/models/responses/terms-overview.model';
import { TermsOverviewItem } from 'app/shared/modules/terms-obsolete/models/responses/terms-overview-item.model';
import { GetTermsOverviewRequest } from 'app/shared/modules/terms-obsolete/models/requests/get-terms-overview-request.model';
import { AcceptPendingTermRequest } from 'app/shared/modules/terms-obsolete/models/requests/accept-term-request.model';

@Injectable()
export class TermsService {

    private baseEndpoint = 'api/terms';

    constructor(private httpClient: HttpClient) { }

    createTerms(request: CreateTermsRequest): Observable<TermsResponse> {
        const url = `${this.baseEndpoint}`;
        return this.httpClient.post<TermsResponse>(url, request);
    }

    updateTerms(request: UpdateTermsRequest): Observable<TermsResponse> {
        const url = `${this.baseEndpoint}/${request.id}`;
        return this.httpClient.put<TermsResponse>(url, request);
    }

    getTerms(termsId: string): Observable<TermsResponse> {
        const url = `${this.baseEndpoint}/${termsId}`;
        return this.httpClient.get<TermsResponse>(url);
    }

    getTermsForOrganisation(organisationId: string): Observable<TermsResponse[]> {
        const url = `${this.baseEndpoint}/organisations/${organisationId}`;
        return this.httpClient.get<TermsResponse[]>(url);
    }

    getTermsOverview(request: GetTermsOverviewRequest): Observable<TermsOverview> {
        const groupIdParam = request.groupId ? `&groupId=${request.groupId}` : '';
        const organisationIdParam = request.organisationId ? `&organisationId=${request.organisationId}` : '';
        const url = `${this.baseEndpoint}/overview?type=${request.termsOverviewType}${groupIdParam}${organisationIdParam}`;

        return this.httpClient.get<TermsOverview>(url);
    }

    getTermsOverviewAdmin(organisationId: string): Observable<TermsOverview> {
        const url = `${this.baseEndpoint}/admin-overview?organisationId=${organisationId}`;

        return this.httpClient.get<TermsOverview>(url);
    }

    acceptPendingTerms(): Observable<void> {
        const url = `${this.baseEndpoint}/accept-pending-terms`;
        return this.httpClient.put<void>(url, null);
    }

    acceptPendingTerm(request: AcceptPendingTermRequest): Observable<TermsOverviewItem> {
        const url = `${this.baseEndpoint}/${request.termsId}/version/${request.termsVersionId}/accept`;
        return this.httpClient.put<TermsOverviewItem>(url, null);
    }
}
