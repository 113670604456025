import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

// store
import * as fromConnect from 'app/connect/store';

@Injectable()
export class UserInterfaceEffects {

    navigateToUrl$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.NavigateToUrl),
        tap(action => {
            if (this.router.url.split('?')[0] === action.url) {
                window.location.href = this.router.url.split('?')[0];
            } else {
                this.router.navigateByUrl(action.url);
            }
        })), { dispatch: false });

    constructor(
        private actions$: Actions,
        private router: Router) { }
}