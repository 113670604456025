<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="4px" class="paging-navigation">
    <div class="paging-content" *ngIf="pages.length > 1 || inWizard" fxLayout="row" fxFlex="grow" fxLayoutAlign="start center" fxLayoutGap="4px">
        <button mat-raised-button
            aria-label="Back"
            class="paging-button ghost small"
            (click)="back()"
            [disabled]="(currentPage.pageNumber === 1 && !handleBackAndNext) || invalid || disabled">
            Back
        </button>

        <div class="paging-number-container" *ngIf="pages.length > 1" fxLayout="row" fxLayoutGap="4px">
            <div *ngFor="let page of paginationPages" class="paging-number-div" fxFlex>
                <button mat-raised-button
                    class="small"
                    *ngIf="!displayEllipsis(page)"
                    (click)="setPage(page)"
                    [disabled]="invalid || disabled"
                    [ngClass]="{ 'ghost': currentPage.pageNumber !== page.pageNumber}">
                    {{page.pageNumber}}
                </button>
                
                <button mat-raised-button
                    class="ghost small ellipsis"
                    *ngIf="displayEllipsis(page)">
                    <mat-icon>more_horiz</mat-icon></button>
            </div>
        </div>

        <button mat-raised-button
            aria-label="Next"
            class="paging-button ghost small next-button"
            (click)="next()"
            [disabled]="(currentPage.pageNumber === pages.length && !handleBackAndNext) || invalid || disabled">
            Next
        </button>
    </div>
    <div *ngIf="showSaveButton" class="paging-footer" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.gt-xs="end stretch">
        <button mat-raised-button
            aria-label="Save"
            class="paging-button save"
            (click)="save()"
            [disabled]="invalid || disabled">
            Save
        </button>
    </div>
</div>
