export enum GroupType {
    GroupList = 0,
    Project = 1,    // not used in Connect
    ClientList = 2, // not used in Connect
    GroupListFolder = 3
}

export function getGroupTypeTranslatePath(groupType: GroupType, postfix: string = 'single'): string {
    switch (groupType) {
        case GroupType.GroupList: return 'labels.groupTypes.contractor.' + postfix;
        case GroupType.GroupListFolder: return 'labels.groupTypes.listFolder.' + postfix;
        default: return '';
    }
}

export function isGroupList(groupType: GroupType): boolean {
    switch (groupType) {
        case GroupType.GroupList:
        case GroupType.GroupListFolder: return true;
        default: return false;
    }
}