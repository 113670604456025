<div id="brand-panel" [ngClass]="{ 'expanded': expanded, 'visible': visible, 'expandable': expandable }" fxLayout="column">
    <div *ngIf="expandable" fxHide.lt-md class="expand-button pointer" (click)="handleExpandClick()" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon *ngIf="expanded">arrow_right</mat-icon>
        <mat-icon *ngIf="!expanded">arrow_left</mat-icon>
    </div>

    <a *ngIf="showCloseButton" class="close-button" (click)="onClose()">
        <mat-icon class="font-size-30">close</mat-icon>
    </a>

    <div fxLayout="column" fxFlex.gt-xs>
        <div *ngIf="showLogo" class="title primary-bg primary-reverse-text" [ngClass]="{'connect': isConnect$ | async}"  fxLayout="column" fxLayoutAlign="center center">
            <img *ngIf="!(authenticationLogoUrl$ | async) && (logoUrl$ | async)" [src]="logoUrl$ | async" />
            <img *ngIf="authenticationLogoUrl$ | async" [src]="authenticationLogoUrl$ | async" />
        </div>
        <div fxFlex.gt-xs class="content" fxLayout="column">
            <h3 *ngIf="title">{{title}}</h3>
            <ng-content></ng-content>
        </div>
    </div>
</div>