import { Action } from '@ngrx/store';
import { ExportFileDetails } from 'app/shared/modules/zoho-export/models/export-file-details.model';

export const START_EXPORT = '[ZohoExport] Start export';
export const START_EXPORT_SUCCESS = '[ZohoExport] Start export success';
export const START_EXPORT_FAIL = '[ZohoExport] Start export fail';

export const EXPORT_FILE = '[ZohoExport] Export file';
export const EXPORT_FILE_SUCCESS = '[ZohoExport] Export file success';
export const EXPORT_FILE_FAIL = '[ZohoExport] Export file fail';

export const MARK_EXPORT_SUCCESSFUL = '[ZohoExport] Mark export successful';
export const MARK_EXPORT_SUCCESSFUL_SUCCESS = '[ZohoExport] Mark export successful success';
export const MARK_EXPORT_SUCCESSFUL_FAIL = '[ZohoExport] Mark export successful fail';

export const MARK_EXPORT_FAILED = '[ZohoExport] Mark export failed';
export const MARK_EXPORT_FAILED_SUCCESS = '[ZohoExport] Mark export failed success';
export const MARK_EXPORT_FAILED_FAIL = '[ZohoExport] Mark export failed fail';

export class StartExport implements Action {
    readonly type = START_EXPORT;
    constructor(public entityId: string, public processId: string) { }
}

export class StartExportSuccess implements Action {
    readonly type = START_EXPORT_SUCCESS;
}

export class StartExportFail implements Action {
    readonly type = START_EXPORT_FAIL;
}

export class ExportFile implements Action {
    readonly type = EXPORT_FILE;
    constructor(public payload: ExportFileDetails) { }
}

export class ExportFileSuccess implements Action {
    readonly type = EXPORT_FILE_SUCCESS;
    constructor(public payload: Guid) { }
}

export class ExportFileFail implements Action {
    readonly type = EXPORT_FILE_FAIL;
    constructor(public payload: Guid) { }
}

export class MarkExportSuccessful implements Action {
    readonly type = MARK_EXPORT_SUCCESSFUL;
    constructor(public entityId: string, public processId: string) { }
}

export class MarkExportSuccessfulSuccess implements Action {
    readonly type = MARK_EXPORT_SUCCESSFUL_SUCCESS;
}

export class MarkExportSuccessfulFail implements Action {
    readonly type = MARK_EXPORT_SUCCESSFUL_FAIL;
}

export class MarkExportFailed implements Action {
    readonly type = MARK_EXPORT_FAILED;
    constructor(public entityId: string, public processId: string) { }
}

export class MarkExportFailedSuccess implements Action {
    readonly type = MARK_EXPORT_FAILED_SUCCESS;
}

export class MarkExportFailedFail implements Action {
    readonly type = MARK_EXPORT_FAILED_FAIL;
}

export type AllZohoExportActions
    = StartExport
    | StartExportSuccess
    | StartExportFail
    | ExportFile
    | ExportFileSuccess
    | ExportFileFail
    | MarkExportSuccessful
    | MarkExportSuccessfulSuccess
    | MarkExportSuccessfulFail
    | MarkExportFailed
    | MarkExportFailedSuccess
    | MarkExportFailedFail;
