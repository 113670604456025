import { Injectable } from '@angular/core';

// ngrx/rxjs
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

// Actions
import * as GroupInviteActions from 'app/shared/modules/group/store/actions/group-invite.actions';

// Services
import { GroupService } from 'app/shared/modules/group/services/group.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// Models
import { ResendInviteEmailRequest } from 'app/shared/modules/group/models/resend-invite-email-request.model';
import { CreateEntityRequest } from 'app/portal/modules/group-list/models/create-entity-request.model';
import { EntitySummary } from 'app/shared/modules/group/models/entity-summary.model';
import { SmsTemplateExistance } from 'app/shared/modules/group/models/sms-template-existance.model';

@Injectable()
export class GroupInviteEffects {


    resendGroupInvite: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GroupInviteActions.RESEND_EMAIL_INVITE),
        map((action: GroupInviteActions.ResendInviteEmail) => action.payload),
        switchMap((payload: ResendInviteEmailRequest) => this.groupService.resendGroupInvite(payload).pipe(
                map(() => new GroupInviteActions.ResendInviteEmailSuccess()),
                catchError(() => of(new GroupInviteActions.ResendInviteEmailFail()))
            ))
    ));

    resendGroupInviteSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GroupInviteActions.RESEND_EMAIL_INVITE_SUCCESS),
        tap(() => {
            this.alertService.success('Invite sent');
        })
    ), { dispatch: false });

    resendGroupInviteFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GroupInviteActions.RESEND_EMAIL_INVITE_FAIL),
        tap(() => {
            this.alertService.error(`Invite could not be sent.`);
        })
    ), { dispatch: false });

    createEntity: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GroupInviteActions.CREATE_ENTITY),
        map((action: GroupInviteActions.CreateEntity) => action.payload),
        switchMap((payload: CreateEntityRequest) => this.groupService.createEntity(payload).pipe(
                map((entity: EntitySummary) => new GroupInviteActions.CreateEntitySuccess(entity.id)),
                catchError(() => of(new GroupInviteActions.CreateEntityFail()))
            ))
    ));

    canContactViaSms: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GroupInviteActions.CAN_CONTACT_VIA_SMS),
        switchMap(() => this.groupService.canContactViaSms().pipe(
                map((canContactViaSms: SmsTemplateExistance) => new GroupInviteActions.CanContactViaSmsSuccess(canContactViaSms)),
                catchError(() => of(new GroupInviteActions.CanContactViaSmsFail()))
            ))
    ));

    getTelephoneNumbers: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GroupInviteActions.GET_ACTIVE_TELEPHONE_NUMBERS),
        switchMap(() => this.groupService.getTelephoneNumbers().pipe(
                map(telephoneNumbers => new GroupInviteActions.GetActiveTelephoneNumbersSuccess(telephoneNumbers)),
                catchError(() => of(new GroupInviteActions.GetActiveTelephoneNumbersFail()))
            ))
    ));

    constructor(
        private actions$: Actions,
        private groupService: GroupService,
        private alertService: AlertService) { }
}