import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';

// ngrx
import { select, Store } from '@ngrx/store';

// store
import * as fromStore from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// Models
import { FolderSummary } from 'app/connect/models/folder-summary.model';
import { takeUntil } from 'rxjs';
import { Modules } from 'app/shared/enums';
import { ModuleService } from 'app/shared/services/module.service';


@Component({
    selector: 'app-folder-card',
    templateUrl: './folder-card.component.html',
    styleUrls: ['./folder-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FolderCardComponent extends BaseComponent implements OnInit  {
    @Input()
    item: FolderSummary;

    @Output()
    openFolderClicked: EventEmitter<FolderSummary> = new EventEmitter();

    @Output()
    deleteFolderClicked: EventEmitter<FolderSummary> = new EventEmitter();

    @Output()
    editFolderClicked: EventEmitter<FolderSummary> = new EventEmitter();

    canEditFolders = false;
    canDeleteFolders = false;

    constructor(private store: Store<fromStore.ConnectStoreState>){
        super();
    }

    ngOnInit(): void {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getCanEditFoldersPermission))
            .subscribe((permission: Modules) => {
                if (permission) {
                    this.canEditFolders = ModuleService.isEnabled(permission);
                } else {
                    this.canEditFolders = false;
                }
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getCanDeleteFoldersPermission))
            .subscribe((permission: Modules) => {
                if (permission) {
                    this.canDeleteFolders = ModuleService.isEnabled(permission);
                } else {
                    this.canDeleteFolders = false;
                }
            });
    }

    onOpenClick(): void {
        this.openFolderClicked.emit(this.item);
    }

    onDeleteClick(): void {
        this.deleteFolderClicked.emit(this.item);
    }

    onEditClick(): void {
        this.editFolderClicked.emit(this.item);
    }
}