// enums
import { FileExtension } from 'app/portal/modules/inputs/enumerations/file-extension.enum';
import { QrCodeData } from 'app/shared/modules/mobile-photo/models/qr-code-data.model';

export class PhotoInputSettings {
    allowedFileExtensions: FileExtension[] = [FileExtension.Jpg, FileExtension.Jpeg, FileExtension.Png];
    maximumFileSizeMb = 10;
    downloadEnabled = true;
    disableUpload = false;
    convertToJpg = false;
    removeEnabled = false;
    alwaysHidePreview = false;
    compressImages = false;
    allowQrCode = true;
    qrCodeData: QrCodeData = null;
}
