import { Component, Input, OnInit } from '@angular/core';

// ngrx | rxjs
import { Store} from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromConnect from 'app/connect/store';

@Component({
    selector: 'app-authentication-layout',
    templateUrl: './authentication-layout.component.html',
    styleUrls: ['./authentication-layout.component.scss']
})
export class AuthenticationLayoutComponent implements OnInit {

    backgroundUrl$: Observable<string>;
    title$: Observable<string>;
    tagLine$: Observable<string>;

    @Input()
    title: string;

    constructor(private connectStore: Store<fromConnect.ConnectStoreState>) { }

    ngOnInit(): void {
        this.backgroundUrl$ = this.connectStore.select(fromConnect.getBackgroundUrl);
        this.title$ = this.connectStore.select(fromConnect.getTitle);
        this.tagLine$ = this.connectStore.select(fromConnect.getTagLine);

        this.connectStore.dispatch(fromConnect.SetVersion({ isConnect: true }));
    }
}