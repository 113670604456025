import { Injectable } from '@angular/core';

// NgRx/RxJs
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';

// Actions
import * as fromActions from 'app/shared/modules/group/store/actions/manage-assessment-set.actions';

// Services
import { ManageAssessmentSetService } from 'app/shared/modules/group/services/manage-assessment-set.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// Models
import { UpdateAssessmentsRequest } from 'app/shared/modules/group/models/update-assessments-request.model';
import { AssessmentSetSummary } from 'app/shared/modules/group/models/assessment-set-summary.model';
import { BadgeSummary } from 'app/shared/modules/group/models/badge-summary.model';
import { ManageAssessmentSetInfo } from 'app/shared/modules/group/models/manage-assessment-set-info.model';

@Injectable()
export class ManageAssessmentSetEffects {


    getAssessmentSets$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_ASSESSMENT_SETS),
        map((action: fromActions.GetAssessmentSets) => action.payload),
        switchMap((groupId: string) => this.manageAssessmentSetService.getAssessmentSets(groupId).pipe(
                map((assessmentSets: AssessmentSetSummary[]) => new fromActions.GetAssessmentSetsSuccess(assessmentSets)),
                catchError(() => of(new fromActions.GetAssessmentSetsFail()))
            ))
    ));


    getProfileAssessmentSets$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_PROFILE_ASSESSMENT_SETS),
        switchMap(() => this.manageAssessmentSetService.getProfileAssessmentSets().pipe(
                map((assessmentSets: AssessmentSetSummary[]) => new fromActions.GetAssessmentSetsSuccess(assessmentSets)),
                catchError(() => of(new fromActions.GetAssessmentSetsFail()))
            ))
    ));


    getInviteAssessmentSets$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_INVITE_ASSESSMENT_SETS),
        map((action: fromActions.GetInviteAssessmentSets) => action.payload),
        switchMap((groupId: string) => this.manageAssessmentSetService.getAssessmentSets(groupId).pipe(
                map((assessmentSets: AssessmentSetSummary[]) => new fromActions.GetAssessmentSetsSuccess(assessmentSets)),
                catchError(() => of(new fromActions.GetAssessmentSetsFail()))
            ))
    ));


    getIndividualInviteAssessmentSets$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_INDIVIDUAL_INVITE_ASSESSMENT_SETS),
        map((action: fromActions.GetIndividualInviteAssessmentSets) => action.payload),
        switchMap((request: ManageAssessmentSetInfo) => this.manageAssessmentSetService.getIndividualAssessmentSets(request).pipe(
                map((assessmentSets: AssessmentSetSummary[]) => new fromActions.GetAssessmentSetsSuccess(assessmentSets)),
                catchError(() => of(new fromActions.GetAssessmentSetsFail()))
            ))
    ));


    getBadges$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_BADGES),
        map((action: fromActions.GetBadges) => action.payload),
        switchMap((groupId: string) => this.manageAssessmentSetService.getBadges(groupId).pipe(
                map((badgeSummaries: BadgeSummary[]) => new fromActions.GetBadgesSuccess(badgeSummaries)),
                catchError(() => of(new fromActions.GetBadgesFail()))
            ))
    ));


    getProfileBadges$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_PROFILE_BADGES),
        map((action: fromActions.GetBadges) => action.payload),
        switchMap(() => this.manageAssessmentSetService.getProfileBadges().pipe(
                map((badgeSummaries: BadgeSummary[]) => new fromActions.GetBadgesSuccess(badgeSummaries)),
                catchError(() => of(new fromActions.GetBadgesFail()))
            ))
    ));


    getIndividualInviteBadges$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_INDIVIDUAL_INVITE_BADGES),
        map((action: fromActions.GetIndividualInviteBadges) => action.payload),
        switchMap((data: ManageAssessmentSetInfo) => this.manageAssessmentSetService.getIndividualInviteBadges(data).pipe(
                map((badgeSummaries: BadgeSummary[]) => new fromActions.GetBadgesSuccess(badgeSummaries)),
                catchError(() => of(new fromActions.GetBadgesFail()))
            ))
    ));


    getInviteBadges$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_INVITE_BADGES),
        map((action: fromActions.GetInviteBadges) => action.payload),
        switchMap((groupId: string) => this.manageAssessmentSetService.getBadges(groupId).pipe(
                map((badgeSummaries: BadgeSummary[]) => new fromActions.GetBadgesSuccess(badgeSummaries)),
                catchError(() => of(new fromActions.GetBadgesFail()))
            ))
    ));


    updateAssessmentSets$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.UPDATE_ASSESSMENT_SETS),
        map((action: fromActions.UpdateAssessmentSets) => action.payload),
        switchMap((request: UpdateAssessmentsRequest) => this.manageAssessmentSetService.updateAssessmentSets(request).pipe(
                map(() => new fromActions.UpdateAssessmentSetsSuccess()),
                catchError(() => of(new fromActions.UpdateAssessmentSetsFail()))
            ))
    ));


    updateAssessmentSetsSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.UPDATE_ASSESSMENT_SETS_SUCCESS),
        tap(() => this.alertService.success('The assessments have been updated.')),
    ), { dispatch: false });


    updateAssessmentSetsFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.UPDATE_ASSESSMENT_SETS_FAIL),
        tap(() => this.alertService.error('The assessments could not be updated.')),
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private manageAssessmentSetService: ManageAssessmentSetService,
        private alertService: AlertService) { }
}