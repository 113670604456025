import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum LivenessMethod {
    NotApplicable = 0,
    UniqueActionProcess = 1,
    Passive = 2
}

export const LivenessMethods = [
    new EnumValueTitle(LivenessMethod.NotApplicable, 'Not Applicable'),
    new EnumValueTitle(LivenessMethod.Passive, 'Passive Liveness'),
    new EnumValueTitle(LivenessMethod.UniqueActionProcess, 'Unique action Process')
];

export function getLivenessMethodTitle(livenessMethod: LivenessMethod): string {
    return LivenessMethods.find(l => l.value === livenessMethod)?.title;
}