<div class="idv-confirmation-step" fxLayout="column"fxLayoutGap="32px">
    <div fxLayout="row" fxLayoutAlign="center center">
        <img fxFlex="191px" [src]="base64Image | safe: 'resourceUrl'"/>
    </div>
    <div fxLayout="column" fxLayoutGap="16px">
        <div class="text headline align-center">Looking good?</div>
        <div class="text sub-title align-center">If you're happy with your photo, click the button to confirm.</div>
    </div>
    <div fxLayout="column" fxLayoutGap="16px">
        <button mat-raised-button  (click)="handleConfirmClicked()">Yes, looks good</button>
        <div class="text pointer body align-center" (click)="handleRetakeClicked()">Retake</div>
    </div>
</div>