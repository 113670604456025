export enum TemplateSorting {
    Name,
    DateCreatedAsc,
    DateCreatedDesc,
    DateUpdatedAsc,
    DateUpdatedDesc
}

export function getTemplateSortingLabel(value: TemplateSorting): string {
    switch (value) {
        case TemplateSorting.Name:
            return 'Name';
        case TemplateSorting.DateCreatedAsc:
            return 'Date created (asc)';
        case TemplateSorting.DateCreatedDesc:
            return 'Date created (desc)';
        case TemplateSorting.DateUpdatedAsc:
            return 'Date updated (asc)';
        case TemplateSorting.DateUpdatedDesc:
            return 'Date updated (desc)';
    }
}
