// Store
import * as fromActions from 'app/shared/modules/group/store/actions/group-list-sharing.actions';

// Models
import { OrganisationTitle } from 'app/shared/models/organisation-title.model';

export interface GroupListSharingState {
    entities: OrganisationTitle[];
    clients: OrganisationTitle[];
}

const initialState: GroupListSharingState = {
    entities: null,
    clients: null
};

export function reducer(state: GroupListSharingState = initialState, action: fromActions.Actions): GroupListSharingState {

    switch (action.type) {
        case fromActions.GET_ENTITIES_TO_SHARE_WITH:
        case fromActions.GET_ENTITIES_TO_SHARE_WITH_FAIL: {
            return {
                ...state,
                entities: null
            };
        }
        case fromActions.GET_ENTITIES_TO_SHARE_WITH_SUCCESS: {
            return {
                ...state,
                entities: action.payload
            };
        }
        case fromActions.GET_CLIENTS_TO_SHARE_WITH:
        case fromActions.GET_CLIENTS_TO_SHARE_WITH_FAIL: {
            return {
                ...state,
                clients: null
            };
        }
        case fromActions.GET_CLIENTS_TO_SHARE_WITH_SUCCESS: {
            return {
                ...state,
                clients: action.payload
            };
        }
        default: {
            return state;
        }
    }
}