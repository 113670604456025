import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

// Models
import { Option } from 'app/shared/models/option.model';
import { OptionRow } from 'app/shared/models/option-row.model';

// Enums
import { ButtonSize } from 'app/shared/modules/set-observations/enums/button-size.enum';

@Component({
    selector: 'app-option-selector',
    templateUrl: 'option-selector.component.html',
    styleUrls: ['option-selector.component.scss']
})
export class OptionSelectorComponent implements OnInit {

    @Input()
    label: string;

    @Input()
    description: string;

    @Input()
    options: Option[];

    @Input()
    value: string;

    @Input()
    values: string[];

    @Input()
    buttonSize: ButtonSize = ButtonSize.Standard;

    @Input()
    mandatory: boolean = false;

    @Input()
    readonly: boolean = false;

    @Input()
    multiple: boolean = false;

    @Output()
    valueChanged: EventEmitter<string> = new EventEmitter();

    @Output()
    valuesChanged: EventEmitter<string[]> = new EventEmitter();

    optionRows: OptionRow[];

    selectedOptions: Option[] = [];

    constructor() { }

    ngOnInit(): void {
        this.optionRows = [];
        let currentRow: OptionRow;
        for (const option of this.options) {
            if (!currentRow || (this.buttonSize === ButtonSize.Multi)) {
                currentRow = new OptionRow();
                this.optionRows.push(currentRow);
            }

            currentRow.options.push(option);
        }

        if (this.multiple && this.values) {
            for (const value of this.values) {
                const selectedOption = this.options.filter(o => o.value === value);
                if (selectedOption.length === 1) {
                    this.selectedOptions.push(selectedOption[0]);
                }
            }
        }

        if (!this.multiple && this.value) {
            const selectedOption = this.options.filter(o => o.value === this.value);
            if (selectedOption.length === 1) {
                this.selectedOptions.push(selectedOption[0]);
            }
        }
    }

    selectOption(option: Option) {
        if (this.readonly) {
            return;
        }

        if (this.selectedOptions.filter(o => o === option).length > 0) {
            this.selectedOptions = this.selectedOptions.filter(o => o !== option);
        } else {
            if (this.multiple) {
                this.selectedOptions.push(option);
            } else {
                this.selectedOptions = [option];
            }
        }

        if (this.multiple) {
            if (this.selectedOptions.length === 0) {
                this.valuesChanged.emit([]);
            } else {
                this.valuesChanged.emit(this.selectedOptions.map(o => o.value));
            }
        } else {
            if (this.selectedOptions.length === 1) {
                this.valueChanged.emit(this.selectedOptions.map(o => o.value)[0]);
            } else {
                this.valueChanged.emit(null);
            }
        }
    }

    isSelected(option: Option) {
        return this.selectedOptions && option && this.selectedOptions.filter(o => o === option).length > 0;
    }

    buttonSizeClass(): string {
        switch (this.buttonSize) {
            case ButtonSize.Small: return 'size-small';
            case ButtonSize.Multi: return 'size-multi';
            default: return 'size-standard';
        }
    }
}