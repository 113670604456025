<div class="uap-observation">
    <h3>{{item.description}}</h3>

    <div fxLayout="row" fxLayout.xs="column" class="take-uap-container" fxLayoutAlign="start start" fxLayoutAlign.xs="start center" fxLayoutGap="10px">

        <app-photo-capture #imageUpload 
            (imageUploaded)="imageUploaded($event)"
            (imageLoading)="imageLoading($event)"
            (imageDownloadClicked)="imageDownloadClicked()"
            [downloadEnabled]="false"
            [disableUpload]="item.readonly"
            [removeEnabled]="false"
            [convertToJpg]="false"
            [qrCodeData]="qrCodeData"
            [useFrontFacingCamera]="true">
        </app-photo-capture>

        <div *ngIf="uapImage" fxLayout="column" fxLayoutAlign="start center">
            <app-image-card 
                [canDownload]="false"
                [canRemove]="false"
                [src]="uapImage.url">
            </app-image-card>
            <h3>{{uapImage.description}}</h3>
        </div>
    </div>
    <div *ngIf="(recordingObservation$ | async)">
        <app-loading-card message="Please wait while we upload and check your liveness test..."></app-loading-card>
    </div>
</div>
