import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// ngrx / rxjs
import { ActionsSubject, select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';

// store
import * as fromMobilePhoto from 'app/shared/modules/mobile-photo/store';

// services
import { ImageUploadService } from 'app/shared/services/image-upload.service';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// enums
import { ItemType } from 'app/shared/enums/item-type.enum';
import { getIdDocumentTypeTitle, IdDocumentType } from 'app/shared/enums/id-document-type.enum';

// models
import { UapImage } from 'app/shared/modules/set-observations/models/responses/uap-image.model';
import { UploadImageRequest } from 'app/shared/modules/mobile-photo/models/upload-image-request-model';

// extensions
import { newId } from 'app/shared/utilities/string-utilities';

@Component({
    templateUrl: './mobile-photo-capture.component.html',
    styleUrls: ['./mobile-photo-capture.component.scss']
})

export class MobilePhotoCaptureComponent extends BaseComponent implements OnInit {

    @ViewChild('imageUpload') uploadElement: ElementRef;
    @ViewChild('canvasElement') canvasElement: ElementRef;

    imageSrc: string;
    imageBase64: string;
    imageName: string;

    token: string;
    setId: string;
    itemId: string;
    observationId: string;
    itemType: ItemType;
    idDocumentType: IdDocumentType;

    savingImage$: Observable<boolean>;
    uapImage: UapImage;
    imageSaved = false;
    error = false;
    photoTaken = false;
    useIdvControl = false;
    initialised = false;
    processComplete = true;

    ItemType = ItemType;

    constructor(
        private imageUploadService: ImageUploadService,
        private activatedRoute: ActivatedRoute,
        private store: Store<fromMobilePhoto.MobilePhotoState>,
        private actionsSubject: ActionsSubject) {
            super();
    }

    ngOnInit(): void {
        this.savingImage$ = this.store.select(fromMobilePhoto.getSavingImage);

        const queryParams = this.activatedRoute.snapshot.queryParams;
        const params = this.activatedRoute.snapshot.params;
        if (queryParams) {
            this.token = queryParams.token;
            this.itemType = Number(queryParams.itemType);
            this.useIdvControl = this.itemType === ItemType.IdentityVerification;
            if (queryParams.idDocumentType) {
                this.idDocumentType = Number(queryParams.idDocumentType);
            }
            if (queryParams.observationId) {
                this.observationId = queryParams.observationId;
            }
        }
        if (params) {
            this.setId = params.setId;
            this.itemId = params.itemId;
        }

        if (this.itemType === ItemType.UniqueActionProcess) {
            this.store.dispatch(fromMobilePhoto.GetUapImage({token: this.token}));
        } else {
            this.initialised = true;
        }

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromMobilePhoto.getUapImage))
            .subscribe((uapImage: UapImage) => {
                this.uapImage = uapImage;
                this.initialised = true;
            });

        this.actionsSubject.pipe(
            ofType(fromMobilePhoto.UploadImageSuccess),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.imageSaved = true;
                this.processComplete = (this.itemType !== ItemType.ImageList);
            });

        this.actionsSubject.pipe(
            ofType(fromMobilePhoto.UploadImageFail),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.error = true);

    }

    onUploadClicked(): void {
        this.uploadElement.nativeElement.click();
    }

    onRetakeClicked(): void {
        this.photoTaken = false;
        this.uploadElement.nativeElement.click();
    }

    onUploadImage(event: any) {
        if (event.target.files && event.target.files[0]) {
            const file: File = event.target.files[0];
            this.imageName = file.name;
            this.imageUploadService.getBase64(file).then((base64Image: string) => {
                this.photoTaken = true;
                this.imageSrc = base64Image;
                this.imageBase64 = this.imageUploadService.stripDataHeader(base64Image);
            });
        }
    }

    onImageTaken(base64string: string) {
        this.photoTaken = true;
        this.imageBase64 = base64string;
        this.imageSrc = base64string;
        this.imageName = `${ItemType[this.itemType]}.jpg`;
    }

    onAdditionalImageClicked(): void {
        this.observationId = newId();
        this.photoTaken = false;
        this.imageSaved = false;
    }

    onReturnToDesktopClicked(): void {
        this.store.dispatch(fromMobilePhoto.ClearMobileToken({ token: this.token }));
        this.processComplete = true;
    }

    getTitle(): string {
        switch (this.itemType) {
            case ItemType.IdDocuments:
                return `Take a photo of your ${getIdDocumentTypeTitle(this.idDocumentType)}`;
            default:
                return 'Take a photo of your document';
        }
    }

    getExampleImageSrc(): string {
        switch (this.itemType) {
            case ItemType.IdDocuments:
                return 'assets/idv/CredasSampleIdDocument.png';
            default:
                return 'assets/idv/SampleLetter.png';
        }
    }

    onSubmitClicked(): void {
        const request = new UploadImageRequest();
        request.token = this.token;
        request.setId = this.setId;
        request.itemId = this.itemId;
        request.observationId = this.observationId ?? newId();
        request.type = this.itemType;
        request.idDocumentType = this.idDocumentType;
        request.description = this.imageName;
        request.base64Image = this.imageBase64;
        request.mimeType = 'image/jpeg';
        this.store.dispatch(fromMobilePhoto.UploadImage({token: this.token, request}));
    }
}
