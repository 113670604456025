// ngrx
import { createReducer, on, Action } from '@ngrx/store';

// store
import * as actions from 'app/connect/store/actions/application.actions';
import { Client } from 'app/models/client.model';

// models
import { ModuleDetails } from 'app/models/module-details.model';
import { UserGroup } from 'app/models/user-group.model';
import { User } from 'app/models/user.model';

export interface ApplicationState {
    user: User;
    client: Client;
    userGroup: UserGroup;
    applications: ModuleDetails[];
    application: ModuleDetails;
    services: ModuleDetails[];
    service: ModuleDetails;
}

const initialState: ApplicationState = {
    user: null,
    client: null,
    userGroup: null,
    applications: null,
    application: null,
    services: null,
    service: null
};

const applicationReducer = createReducer(
    initialState,
    on(actions.SetUser, (state, action) => ({
        ...state,
        user: action.user,
        applications: null,
        application: null,
        services: null,
        service: null
    })),
    on(actions.SetClient, (state, action) => ({
        ...state,
        client: action.client
    })),
    on(actions.SetApplications, (state, action) => ({
        ...state,
        applications: action.applications
    })),
    on(actions.SetApplication, (state, action) => ({
        ...state,
        application: action.application,
    })),
    on(actions.SetServices, (state, action) => ({
        ...state,
        services: action.services,
    })),
    on(actions.SetService, (state, action) => ({
        ...state,
        service: action.service
    })),
    on(actions.SetAndPopulateClientSuccess, (state, action) => ({
        ...state,
        client: action.client
    })),
    on(actions.RehydrateUserSuccess, (state, action) => ({
        ...state,
        user: action.user,
        client: action.client,
        userGroup: action.userGroup,
        applications: null,
        application: null,
        services: null,
        service: null
    })),
    on(actions.SetUserForTokenLogin, (state, action) => ({
        ...state,
        user: action.user,
        client: action.client,
        applications: null,
        application: null,
        services: null,
        service: null
    })),
    on(actions.SetUserGroup, (state, action) => ({
        ...state,
        userGroup: action.userGroup
    })),
    on(actions.ClearUserGroup, (state) => ({
        ...state,
        userGroup: null
    })),
);

export function reducer(state: ApplicationState | undefined, action: Action) {
    return applicationReducer(state, action);
}
