// Models
import { AssessmentProfileCategoryResponse } from './assessment-profile-category-response.model';
import { AssessmentProfileSetResponse } from './assessment-profile-set-response.model';
import { AssessmentProfileBadgeResponse } from './assessment-profile-badge-response.model';
import { AssessmentProfileInformationResponse } from './assessment-profile-information-response.model';
import { AssessmentProfileTagResponse } from './assessment-profile-tag-response.model';

// Enums
import { ObfuscationType } from 'app/shared/modules/set-observations/enums/obfuscation-type.enum';
import { GroupNodeStatus } from 'app/shared/enums';

export class AssessmentProfileResponse {
    id: string;
    title: string;
    categories: AssessmentProfileCategoryResponse[];
    totalSetsCount: number;
    complete: boolean;
    completeSetsCount: number;
    incompleteSetsCount: number;
    mandatoryComplete: boolean;
    mandatoryCompleteSetsCount: number;
    mandatoryIncompleteSetsCount: number;
    profileCompletePercentage: number;
    profileComplete: boolean;
    isProfileOwner: boolean;
    obfuscationType: ObfuscationType;
    badges: AssessmentProfileBadgeResponse[];
    tags: AssessmentProfileTagResponse[];
    information: AssessmentProfileInformationResponse;
    status: GroupNodeStatus;
    logoutUrl: string;
}

export function getAllSets(profile: AssessmentProfileResponse): AssessmentProfileSetResponse[] {

    const sets: AssessmentProfileSetResponse[] = [];
    const addedSetIds: string[] = [];

    for (const category of profile.categories) {
        for (const set of category.sets) {
            if (!addedSetIds.includes(set.id)) {
                sets.push(set);
                addedSetIds.push(set.id);
            }
        }
    }

    for (const badge of profile.badges) {
        for (const category of badge.categories) {
            for (const set of category.sets) {
                if (!addedSetIds.includes(set.id)) {
                    sets.push(set);
                    addedSetIds.push(set.id);
                }
            }
        }
    }

    return sets;
}

export function getAllBadgeSets(badge: AssessmentProfileBadgeResponse): AssessmentProfileSetResponse[] {
    const sets: AssessmentProfileSetResponse[] = [];
    const addedSetIds: string[] = [];

    for (const category of badge.categories) {
        for (const set of category.sets) {
            if (!addedSetIds.includes(set.id)) {
                sets.push(set);
                addedSetIds.push(set.id);
            }
        }
    }

    return sets;
}

export function getIncompleteBadgeSets(badge: AssessmentProfileBadgeResponse): AssessmentProfileSetResponse[] {
    const incompleteSets = getAllBadgeSets(badge).filter(s => !s.complete);
    if (incompleteSets.length) {
        return incompleteSets;
    }

    return getAllBadgeSets(badge).filter(s => s.totalCompleteItems !== s.totalItems);
}

export function getIncompleteSets(profile: AssessmentProfileResponse): AssessmentProfileSetResponse[] {
    const incompleteSets =  getAllSets(profile).filter((s: AssessmentProfileSetResponse) => !s.complete);
    if (incompleteSets.length) {
        return incompleteSets;
    }

    return getAllSets(profile).filter(s => s.totalCompleteItems !== s.totalItems);
}