import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ngrx
import { Observable } from 'rxjs';

// Models
import { OrganisationTitle } from 'app/shared/models/organisation-title.model';

// Enums
import { GroupType } from 'app/shared/modules/group/enums/group-type.enum';

@Injectable()
export class GroupSharingService {

    private baseUrl = 'api/groups';

    constructor(private http: HttpClient) {
    }

    getEntities(includeAll: boolean): Observable<OrganisationTitle[]> {
        const url = this.getOrganisationLink(GroupType.GroupList, includeAll);
        return this.http.get<OrganisationTitle[]>(url);
    }

    getClients(includeAll: boolean): Observable<OrganisationTitle[]> {
        const url = this.getOrganisationLink(GroupType.ClientList, includeAll);
        return this.http.get<OrganisationTitle[]>(url);
    }

    private getOrganisationLink(groupType: GroupType, includeAll: boolean): string {
        return `${this.baseUrl}/types/${groupType}/${includeAll ? 'all-organisations' : 'active-organisations'}`;
    }
}