import { Component } from '@angular/core';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

@Component({
    selector: 'app-observation-separator',
    templateUrl: './separator-observation.component.html',
    styleUrls: ['./separator-observation.component.scss']
})
export class SeparatorObservationComponent extends ObservationComponent {

    constructor() {
        super();
    }

    init() { }
}