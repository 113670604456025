import { Pipe, PipeTransform } from '@angular/core';
import { ItemType } from 'app/shared/enums/item-type.enum';
import { Item } from 'app/shared/modules/set-observations/models/item.model';

@Pipe({ name: 'itemLabel' })
export class ItemLabelPipe implements PipeTransform {
    transform(item: Item): string {
        switch (item.type) {
            case ItemType.Separator:
            case ItemType.AdviceText:
                return null;
            default:
                return item.name;
        }
    }
}