import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Models
import { ItemObservation } from 'app/shared/modules/set-observations/models/item-observation.model';
import { SetDocumentDownloadClick } from 'app/shared/models/set-document-download-click.model';

// Store
import * as fromSetsStore from 'app/shared/modules/set-observations/store';

// Utlilities
import { toAlphaNumericUnderline } from 'app/shared/utilities/string-utilities';
import { Item } from 'app/shared/modules/set-observations/models/item.model';

@Component({
    selector: 'app-esign-document-download',
    templateUrl: './esign-document-download.component.html',
    styleUrls: ['./esign-document-download.component.scss']
})
export class ESignDocumentDownloadComponent extends BaseComponent {

    @Input()
    itemObservation: ItemObservation;

    @Input()
    item: Item;

    constructor(private store: Store<fromSetsStore.SetObservationsState>) {
        super();
    }

    showDownload(): boolean {
        return this.itemObservation && this.itemObservation.hasValue();
    }

    downloadDocument(): void {
        const fileName = `${toAlphaNumericUnderline(this.item.name)}-${toAlphaNumericUnderline(this.itemObservation.value)}.pdf`;
        this.store.dispatch(new fromSetsStore.DownloadDocument(new SetDocumentDownloadClick(fileName, this.itemObservation.observationId)));
    }
}