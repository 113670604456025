// Actions
import * as GroupInviteActions from 'app/shared/modules/group/store/actions/group-invite.actions';

// models
import { SmsTemplateExistance } from 'app/shared/modules/group/models/sms-template-existance.model';
import { TelephoneNumber } from 'app/connect-management/modules/telephone-numbers-v1/models/telephone-number.model';

export interface GroupInviteState {
    invitingEntity: boolean;
    invitedOrganisations: boolean;
    creatingEntity: boolean;
    canContactViaSms: SmsTemplateExistance;
    telephoneNumbers: TelephoneNumber[];
}

const initialState: GroupInviteState = {
    invitingEntity: false,
    invitedOrganisations: false,
    creatingEntity: false,
    canContactViaSms: null,
    telephoneNumbers: null
};

export function reducer(state = initialState, action: GroupInviteActions.AllGroupInviteActions): GroupInviteState {
    switch (action.type) {

        case GroupInviteActions.RESEND_EMAIL_INVITE: {
            return {
                ...state,
                invitingEntity: true,
                invitedOrganisations: false
            };
        }
        case GroupInviteActions.RESEND_EMAIL_INVITE_SUCCESS: {
            return {
                ...state,
                invitingEntity: false,
                invitedOrganisations: true
            };
        }
        case GroupInviteActions.RESEND_EMAIL_INVITE_FAIL: {
            return {
                ...state,
                invitingEntity: false,
                invitedOrganisations: false
            };
        }
        case GroupInviteActions.CREATE_ENTITY: {
            return {
                ...state,
                creatingEntity: true
            };
        }
        case GroupInviteActions.CREATE_ENTITY_SUCCESS: {
            return {
                ...state,
                creatingEntity: false
            };
        }
        case GroupInviteActions.CREATE_ENTITY_FAIL: {
            return {
                ...state,
                creatingEntity: false
            };
        }
        case GroupInviteActions.CAN_CONTACT_VIA_SMS_SUCCESS: {
            return {
                ...state,
                canContactViaSms: action.payload
            };
        }
        case GroupInviteActions.CAN_CONTACT_VIA_SMS_FAIL: {
            return {
                ...state,
                canContactViaSms: null
            };
        }
        case GroupInviteActions.GET_ACTIVE_TELEPHONE_NUMBERS: {
            return {
                ...state,
                telephoneNumbers: null
            };
        }
        case GroupInviteActions.GET_ACTIVE_TELEPHONE_NUMBERS_SUCCESS: {
            return {
                ...state,
                telephoneNumbers: action.payload
            };
        }
        case GroupInviteActions.GET_ACTIVE_TELEPHONE_NUMBERS_FAIL: {
            return {
                ...state,
                telephoneNumbers: null
            };
        }
    }

    return state;
}