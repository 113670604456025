import { ModuleDetails } from 'app/models/module-details.model';

export enum UserActivityType {
    Login = 0,
    Logout = 1,
    SessionTimeout = 2,
    SelectModule = 3,
    AgreeUserTerms = 4
}

export function userActivityTypeDescription(activityType: UserActivityType, module: ModuleDetails = null): string {
    switch (activityType) {
        case UserActivityType.Login: return 'Log in';
        case UserActivityType.Logout: return 'Log out';
        case UserActivityType.SessionTimeout: return 'Session timeout';
        case UserActivityType.SelectModule: {
            if (module) {
                return 'Selected Module \'' + module.title + '\'';
            } else {
                return 'Selected Module';
            }
        }
        case UserActivityType.AgreeUserTerms: return 'Agree User Terms';
        default:
            return '';
    }
}

export function isAuthenticationActivity(activityType: UserActivityType): boolean {
    switch (activityType) {
        case UserActivityType.Login:
        case UserActivityType.Logout:
        case UserActivityType.SessionTimeout: {
            return true;
        }
        default: {
            return false;
        }
    }
}