import { ActionReducerMap } from '@ngrx/store';

import * as fromTerms from './terms.reducers';
import * as fromTermsOverview from './terms-overview.reducers';

export interface TermsManagementState {
    termsState: fromTerms.TermsState;
    termsOverviewState: fromTermsOverview.TermsOverviewState;
}

export const reducers: ActionReducerMap<TermsManagementState> = {
    termsState: fromTerms.reducer,
    termsOverviewState: fromTermsOverview.reducer
};

