import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ngrx
import { Observable } from 'rxjs';

// Models
import { DocumentLibraryItem } from 'app/shared/modules/set-observations/models/document-library-item.model';

@Injectable()
export class OrganisationDocumentService {
    private baseUrl = 'api/organisations';

    constructor(private http: HttpClient) { }

    getCommonDocuments(organisationId: string): Observable<DocumentLibraryItem[]> {
        const url = `${this.baseUrl}/${organisationId}/common-documents`;
        return this.http.get<DocumentLibraryItem[]>(url);
    }
}
