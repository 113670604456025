import { ActionReducerMap, } from '@ngrx/store';

import * as fromAuthentication from './auth.reducers';

export interface AuthenticationState {
  authenticationState: fromAuthentication.AuthenticationState;
}

export const reducers: ActionReducerMap<AuthenticationState> = {
  authenticationState: fromAuthentication.reducer
};

