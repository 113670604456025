import { Pipe, PipeTransform } from '@angular/core';
import { ItemType } from 'app/shared/enums/item-type.enum';

@Pipe({ name: 'isItemTypeList' })
export class IsItemTypeListPipe implements PipeTransform {
    transform(typeToCheck: ItemType): boolean {
        switch (typeToCheck) {
            case ItemType.DocumentList:
            case ItemType.ShortTextList: return true;
            default: return false;
        }
    }
}