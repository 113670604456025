<div *ngIf="showUserMenu" id="user-menu" [ngClass]="{ 'expanded': expanded }" fxLayout="column" fxLayoutGap="16px" (click)="onMenuClick()">
    <div class="header primary-bg primary-reverse-text" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <img *ngIf="logoUrl$ | async" [src]="logoUrl$ | async | safe: 'resourceUrl'" />
    </div>

    <div *ngIf="client$ | async" class="sub-header">{{(client$ | async).name}}</div>
    <div *ngIf="!(client$ | async)" class="sub-header">{{ user?.firstName }} {{ user?.surname }}</div>
        
    <div fxFlex="fill" fxLayout="column" class="links">
        <div *ngIf="user?.clients?.length || user?.entityClients?.length || user?.isSuperUser" class="position-relative">
            <div (click)="onSwitchAccountClick()" class="link-item">Switch Account</div>
            <app-connect-tooltip id="client-portal-header-my-account-switch-account" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div>
        <div *ngIf="(client$ | async)?.userGroups?.length" class="position-relative">
            <div (click)="onSwitchUserGroupClick()" class="link-item">Switch User Group</div>
            <app-connect-tooltip id="client-portal-header-my-account-switch-user-group" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div>
        <div *ngIf="(client$ | async)?.userGroups?.length && user?.isSuperUser" class="position-relative">
            <div (click)="onClearUserGroupClick()" class="link-item">Clear User Group</div>
            <app-connect-tooltip id="client-portal-header-my-account-clear-user-group" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div>
        <div *ngIf="user" class="position-relative">
            <div (click)="onEditProfileClick()" class="link-item">My User Details</div>
            <app-connect-tooltip id="client-portal-header-my-account-my-user-details" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div> 
        <div class="position-relative">
            <div (click)="onShowTermsClick()" class="link-item">Terms &amp; Conditions</div>
            <app-connect-tooltip id="client-portal-header-my-account-terms-conditions" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div>
        <div *ngIf="showHelpFaqsMenu$ | async" class="position-relative">
            <div (click)="onOpenHelpFaqSearchClick()" class="link-item">Help &amp; FAQs</div>
            <app-connect-tooltip id="client-portal-header-my-account-help-faqs" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div>
    </div>
    <div class="position-relative">
        <button mat-raised-button (click)="onLogoutClick()" class="log-out-button">Log out</button>
        <app-connect-tooltip id="client-portal-header-my-account-logout" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
    </div>
</div>
<div *ngIf="showUserMenu" id="click-overlay" (click)="onMenuClick()"></div>