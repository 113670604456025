// ngrx
import { Action } from '@ngrx/store';

// models
import { TokenResponse } from 'app/shared/models';

export const SET_SELECTED_CLIENT = '[ActiveUser] Set Selected Client';
export const SET_SELECTED_CLIENT_CLICK = '[ActiveUser] Set Selected Client via Click';

export const SET_SELECTED_CLIENT_SUCCESS = '[ActiveUser] Set Selected Client Success';
export const SET_SELECTED_CLIENT_FAIL = '[ActiveUser] Set Selected Client Fail';

export const CLEAR_SELECTED_CLIENT = '[ActiveUser] Clear the selected client';

export const GET_SELECTED_ORGANISATION_ASSESSMENTS = '[ActiveUser] Get Selected Organisation Assessments';
export const GET_SELECTED_ORGANISATION_ASSESSMENTS_SUCCESS = '[ActiveUser] Get Selected Organisation Assessments Success';
export const GET_SELECTED_ORGANISATION_ASSESSMENTS_FAILED = '[ActiveUser] Get Selected Organisation Assessments Fail';

export const GET_USER_INVITE_ID = '[ActiveUser] Get invite ID';
export const GET_USER_INVITE_ID_SUCCESS = '[ActiveUser] Get invite ID Success';
export const GET_USER_INVITE_ID_FAIL = '[ActiveUser] Get invite ID Fail';

export const EMPTY_STATE = '[ActiveUser] Empty state';

export class SetSelectedClient implements Action {
    readonly type = SET_SELECTED_CLIENT;
    constructor(public clientId: string, public performRedirect: boolean, public setServiceFromUrl: boolean, public isClient: boolean) { }
}

export class SetSelectedClientSuccess implements Action {
    readonly type = SET_SELECTED_CLIENT_SUCCESS;
    constructor(public token: TokenResponse, public clientId: string) { }
}

export class SetSelectedClientFail implements Action {
    readonly type = SET_SELECTED_CLIENT_FAIL;
}

export class ClearSelectedClient implements Action {
    readonly type = CLEAR_SELECTED_CLIENT;
}

// TODO CONNECT - these need to be "Entity" only
export class GetSelectedOrganisationAssessments implements Action {
    readonly type = GET_SELECTED_ORGANISATION_ASSESSMENTS;
    constructor(public payload: any) { }
}

export class GetSelectedOrganisationAssessmentsSuccess implements Action {
    readonly type = GET_SELECTED_ORGANISATION_ASSESSMENTS_SUCCESS;
    constructor(public payload: any[]) { }
}

export class GetSelectedOrganisationAssessmentsFail implements Action {
    readonly type = GET_SELECTED_ORGANISATION_ASSESSMENTS_FAILED;
    constructor(public payload: string) { }
}

export class EmptyState implements Action {
    readonly type = EMPTY_STATE;
    constructor() { }
}

export class GetUserInviteId implements Action {
    readonly type = GET_USER_INVITE_ID;
    constructor(public payload: string) { }
}

export class GetUserInviteIdSuccess implements Action {
    readonly type = GET_USER_INVITE_ID_SUCCESS;
    constructor(public payload: string) { }
}

export class GetUserInviteIdFail implements Action {
    readonly type = GET_USER_INVITE_ID_FAIL;
}

export type AllActiveUserActions =
    SetSelectedClient
    | SetSelectedClientSuccess
    | SetSelectedClientFail
    | ClearSelectedClient
    | GetSelectedOrganisationAssessments
    | GetSelectedOrganisationAssessmentsSuccess
    | GetSelectedOrganisationAssessmentsFail
    | EmptyState
    | GetUserInviteId
    | GetUserInviteIdSuccess
    | GetUserInviteIdFail;