// ngrx
import { createFeatureSelector, createSelector } from '@ngrx/store';

// store
import * as reducers from 'app/shared/modules/identity-verification/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<reducers.IdentityVerificationStoreState>(StoreNames.identityVerification);
const reducerState = createSelector(storeState, (state: reducers.IdentityVerificationStoreState) => state.identityVerification);

export const getRecordingSelfie = createSelector(reducerState, (state: reducers.IdentityVerificationState) => state.recordingSelfie);
export const getRecordingUap = createSelector(reducerState, (state: reducers.IdentityVerificationState) => state.recordingUap);
export const getRecordingIdDocument = createSelector(reducerState, (state: reducers.IdentityVerificationState) => state.recordingIdDocument);
export const getIdentityVerification = createSelector(reducerState, (state: reducers.IdentityVerificationState) => state.identityVerification);
export const getConfiguration = createSelector(reducerState, (state: reducers.IdentityVerificationState) => state.configuration);
