<div class="course-selector" fxLayout="column" fxLayoutGap="8px">
    <div>
        <app-date-input
            label="Start Date"
            (dateChanged)="handleDateChanged($event)"
            [initialDate]="selectedCourse?.startDate">
        </app-date-input>
    </div>
    <app-loading-card *ngIf="loadingCourses$ | async"></app-loading-card>
    <h3 *ngIf="startDate && courses && courses.length === 0">There are no courses available for the start date specified.</h3>
    <div *ngIf="startDate && courses && courses.length > 0">
        <div class="font-question-label">Course</div>
        <mat-form-field appearance="outline">
            <input type="text" matInput [formControl]="courseSelectorControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getCourseDisplay" (optionSelected)="handleCourseSelected($event.option.value)">
                <mat-option></mat-option>
                <mat-option *ngFor="let course of filteredCourses | async" [value]="course">
                    {{course.frameworkTitle}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <app-loading-card *ngIf="loadingCourseDetail$ | async"></app-loading-card>
    <div *ngIf="selectedCourse" class="table-container" fxLayout="row">
        <table fxFlex>
            <tr>
                <th>Include?</th>
                <th>Activity Type</th>
                <th>Reference</th>
                <th>Ref. Used by Provider</th>
                <th>Activity Title</th>
                <th class="learning-medium-column">Learning Medium</th>
            </tr>
            <tr *ngFor="let skill of selectedCourse.skills; let i = index">
                <td>
                    <mat-checkbox [disabled]="i === 0" [checked]="skill.included" (change)="handleSkillChanged(skill, $event.checked)"></mat-checkbox>
                </td>
                <td>{{skill.activityType}}</td>
                <td>{{skill.reference}}</td>
                <td>{{skill.referenceUsedByProvider}}</td>
                <td>{{skill.activityTitle}}</td>
                <td class="learning-medium-column" fxLayout="row">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-select (selectionChange)="handleSkillMediumChanged(skill, $event.value)" [disabled]="!skill.included" [value]="skill.learningMedium">
                            <mat-option></mat-option>
                            <mat-option *ngFor="let learningType of learningTypes" [value]="learningType" [title]="learningType">{{learningType}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="current-level-input">
        <div class="label">Current Level</div>
        <mat-form-field appearance="outline"  class="observation-field">
            <input matInput name="currentLevel" autocomplete="off" [formControl]="currentLevelControl">
        </mat-form-field>
    </div>
</div>