import { Component, ViewChild } from '@angular/core';

// ngrx & rxjs
import { Store, select } from '@ngrx/store';
import { takeUntil, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';

// Store
import * as fromConnect from 'app/connect/store';
import * as fromObservations from 'app/shared/modules/set-observations/store';

// Models
import { GetESignDocumentRequest } from 'app/shared/modules/set-observations/models/requests/get-esign-document-request.model';
import { ESignDocumentResponse } from 'app/shared/modules/set-observations/models/responses/esign-document-response.model';
import { Signature } from 'app/shared/models/signature.model';
import { User } from 'app/models/user.model';

// Services
import { PdfViewerService } from 'app/shared/services';

// Enums
import { ContentType } from 'app/shared/enums/content-type.enum';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-esign-document',
    templateUrl: './esign-document-observation.component.html',
    styleUrls: ['./esign-document-observation.component.scss'],
    outputs: ['valueUpdated', 'selectedChanged']
})
export class ESignDocumentObservationComponent extends ObservationComponent {

    user: User;
    alreadySigned: boolean;
    esignDocument: ESignDocumentResponse;
    documentPreviewEl: PdfJsViewerComponent;
    signature: Signature;
    recordingObservation$: Observable<boolean>;

    @ViewChild('pdfViewer') set pdfViewer(elRef: PdfJsViewerComponent) {
        this.documentPreviewEl = elRef;
    }

    constructor(
        private observationsStore: Store<fromObservations.SetObservationsState>,
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private pdfViewerService: PdfViewerService) {
        super();
    }

    init() {
        this.recordingObservation$ = this.observationsStore.select(fromObservations.getRecordingImmediateObservation);
        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getUser))
            .subscribe((user: User) => this.user = user);

        this.alreadySigned = this.itemObservation.hasValue() && this.itemObservation.lastUpdated !== undefined;
        if (this.alreadySigned) {
            this.signature = new Signature(this.itemObservation.value, this.itemObservation.lastUpdated);
        }

        this.observationsStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromObservations.getESignDocuments),
            delay(0))
            .subscribe((esignDocuments: ESignDocumentResponse[]) => {
                if (!this.esignDocument && esignDocuments) {
                    const findDocument = esignDocuments.filter(e => e.itemId === this.item.id);
                    if (findDocument.length === 1) {
                        this.esignDocument = findDocument[0];
                    }
                }

                if (this.esignDocument && this.documentPreviewEl) {
                    if (this.documentPreviewEl) {
                        this.documentPreviewEl.pdfSrc = this.pdfViewerService.base64toBlob(this.esignDocument.documentBase64, ContentType.Pdf);
                        this.documentPreviewEl.refresh();
                    }
                }
            });

        if (this.item.setId) {
            const request = new GetESignDocumentRequest(this.item.setId, this.item.id);
            this.observationsStore.dispatch(new fromObservations.GetESignDocument(request));
        } else {
            this.esignDocument = <ESignDocumentResponse>{};
        }
    }


    handleSignatureCaptured(signature: Signature): void {
        this.esignDocument = null;
        this.signature = signature;
        if (signature) {
            this.updateValue(JSON.stringify(signature));
            this.alreadySigned = true;
        }
    }
}