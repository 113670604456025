<div class="powered-by-credas" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" [style.borderLeftColor]="color">
    <div [style.color]="color">Powered by </div>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 388.71 177.92">
        <defs>
            <style>.cls-1{fill:none;}.cls-2{clip-path:url(#clip-path)}.cls-2 * {fill: inherit;}</style>
            <clipPath id="clip-path" transform="translate(0 0)">
                <rect class="cls-1" width="388.71" height="177.92"/>
            </clipPath>
        </defs>
        <title>Layer 1</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g class="cls-2" [style.fill]="color">
                    <path d="M89,168.66a79.49,79.49,0,0,1-42.33-12.15,2.54,2.54,0,0,1,2.7-4.31A74.47,74.47,0,0,0,89,163.58a75.22,75.22,0,0,0,18-2.19,2.54,2.54,0,0,1,1.22,4.93A79.69,79.69,0,0,1,89,168.66" transform="translate(0 0)"/>
                    <path d="M35.78,147.41a2.52,2.52,0,0,1-1.75-.7A81.17,81.17,0,0,1,24.62,136a2.54,2.54,0,0,1,4.1-3,75.69,75.69,0,0,0,8.81,10,2.54,2.54,0,0,1-1.75,4.38" transform="translate(0 0)"/>
                    <path d="M18.15,122.21a2.54,2.54,0,0,1-2.33-1.53A79.71,79.71,0,0,1,82,9.56a2.54,2.54,0,0,1,.44,5.06c-38.2,3.29-68.13,36-68.13,74.34a74.24,74.24,0,0,0,6.14,29.7A2.54,2.54,0,0,1,19.16,122a2.58,2.58,0,0,1-1,.21" transform="translate(0 0)"/>
                    <path d="M140.74,34.3a2.51,2.51,0,0,1-1.7-.66,74.78,74.78,0,0,0-45.7-19.17,2.54,2.54,0,0,1,.29-5.07,79.76,79.76,0,0,1,48.82,20.48,2.54,2.54,0,0,1-1.71,4.42" transform="translate(0 0)"/>
                    <path d="M121.36,161.55a2.54,2.54,0,0,1-1.07-4.84,74.68,74.68,0,0,0,19.26-12.9l-11.19-11.2A2.54,2.54,0,1,1,132,129l13,13a2.56,2.56,0,0,1,.75,1.81,2.52,2.52,0,0,1-.76,1.8,79.64,79.64,0,0,1-22.55,15.66,2.47,2.47,0,0,1-1.06.23" transform="translate(0 0)"/>
                    <path d="M89,131.63a42.67,42.67,0,1,1,30.45-72.56,2.55,2.55,0,0,1-3.63,3.57A37.58,37.58,0,1,0,89,126.54a37.88,37.88,0,0,0,5.33-.37,2.54,2.54,0,1,1,.71,5,43.37,43.37,0,0,1-6,.43" transform="translate(0 0)"/>
                    <path d="M107,127.34a2.54,2.54,0,0,1-1.15-4.81,37.62,37.62,0,0,0,9.34-6.69,2.55,2.55,0,0,1,3.56,3.64,43,43,0,0,1-10.61,7.59,2.61,2.61,0,0,1-1.14.27" transform="translate(0 0)"/>
                    <path d="M53.15,57.5a2.54,2.54,0,0,1-1.84-4.29,52.06,52.06,0,0,1,12-9.43,2.54,2.54,0,1,1,2.51,4.42A47.32,47.32,0,0,0,55,56.71a2.52,2.52,0,0,1-1.84.79" transform="translate(0 0)"/>
                    <path d="M124.14,56.85a2.53,2.53,0,0,1-1.81-.76A46.51,46.51,0,0,0,89,42.12a47.53,47.53,0,0,0-9.08.88,2.54,2.54,0,1,1-1-5A52.07,52.07,0,0,1,126,52.52a2.54,2.54,0,0,1,0,3.6,2.58,2.58,0,0,1-1.79.73" transform="translate(0 0)"/>
                    <path d="M89,140.89a2.55,2.55,0,0,1,0-5.09,46.52,46.52,0,0,0,32.8-13.41,2.55,2.55,0,0,1,3.57,3.63A51.62,51.62,0,0,1,89,140.89" transform="translate(0 0)"/>
                    <path d="M73.45,138.4a2.42,2.42,0,0,1-.79-.13,51.44,51.44,0,0,1-13.11-6.51,2.54,2.54,0,0,1,2.88-4.19,46.63,46.63,0,0,0,11.82,5.88,2.54,2.54,0,0,1-.8,5" transform="translate(0 0)"/>
                    <path d="M50.15,122a2.53,2.53,0,0,1-2-1A51.37,51.37,0,0,1,37,89a52.05,52.05,0,0,1,5.9-24.06,2.54,2.54,0,0,1,4.5,2.36A46.94,46.94,0,0,0,42.12,89a46.39,46.39,0,0,0,10,29,2.54,2.54,0,0,1-.42,3.57,2.51,2.51,0,0,1-1.57.54" transform="translate(0 0)"/>
                    <path d="M32.72,108.16a2.54,2.54,0,0,1-2.44-1.82A61.43,61.43,0,0,1,35,60.06a2.54,2.54,0,1,1,4.48,2.4,56.27,56.27,0,0,0-4.34,42.44,2.53,2.53,0,0,1-2.44,3.26" transform="translate(0 0)"/>
                    <path d="M46,51.63a2.54,2.54,0,0,1-1.87-4.27A61.42,61.42,0,0,1,89,27.78,60.74,60.74,0,0,1,132.5,46a2.54,2.54,0,0,1-3.62,3.57A55.71,55.71,0,0,0,89,32.86a56.3,56.3,0,0,0-41.14,18,2.53,2.53,0,0,1-1.86.81" transform="translate(0 0)"/>
                    <path d="M89,150.14a61.07,61.07,0,0,1-54.15-32.68,2.54,2.54,0,1,1,4.49-2.37,56.11,56.11,0,0,0,89,13.85,2.55,2.55,0,0,1,3.57,3.63A60.85,60.85,0,0,1,89,150.14" transform="translate(0 0)"/>
                    <path d="M89,159.4a70.27,70.27,0,0,1-37.63-10.88,2.54,2.54,0,1,1,2.72-4.29,65.6,65.6,0,0,0,50.26,8.28,2.54,2.54,0,1,1,1.19,4.94,70.46,70.46,0,0,1-16.54,2" transform="translate(0 0)"/>
                    <path d="M41.83,140.39a2.55,2.55,0,0,1-1.76-.71,70.5,70.5,0,0,1-12.2-85.82,2.54,2.54,0,1,1,4.41,2.54A65.38,65.38,0,0,0,43.6,136a2.54,2.54,0,0,1-1.77,4.37" transform="translate(0 0)"/>
                    <path d="M38.75,45.79A2.52,2.52,0,0,1,37,45.12a2.54,2.54,0,0,1-.16-3.59,70.57,70.57,0,0,1,67.26-21.37,2.54,2.54,0,1,1-1.09,5A65.5,65.5,0,0,0,40.63,45a2.54,2.54,0,0,1-1.88.83" transform="translate(0 0)"/>
                    <path d="M134.16,40.84a2.54,2.54,0,0,1-1.69-.65,65.34,65.34,0,0,0-16.54-10.78,2.54,2.54,0,0,1,2.1-4.63A70.31,70.31,0,0,1,135.85,36.4a2.54,2.54,0,0,1-1.69,4.44" transform="translate(0 0)"/>
                    <path d="M117.32,153.21a2.54,2.54,0,0,1-1.06-4.85A65.09,65.09,0,0,0,128.5,141a2.54,2.54,0,1,1,3.08,4.05,69.94,69.94,0,0,1-13.2,7.93,2.47,2.47,0,0,1-1.06.23" transform="translate(0 0)"/>
                    <path d="M65.36,174.66a2.35,2.35,0,0,1-.69-.1,89.08,89.08,0,0,1-60-114.13,2.54,2.54,0,0,1,4.82,1.63A84,84,0,0,0,66.05,169.67a2.54,2.54,0,0,1-.69,5" transform="translate(0 0)"/>
                    <path d="M12.49,51.22a2.55,2.55,0,0,1-2.25-3.73A89,89,0,0,1,125.51,7.83a2.54,2.54,0,0,1-2.09,4.64A83.93,83.93,0,0,0,14.74,49.86a2.56,2.56,0,0,1-2.25,1.36" transform="translate(0 0)"/>
                    <path d="M150.33,30.66a2.52,2.52,0,0,1-1.8-.75,85.37,85.37,0,0,0-9.66-8.37,2.54,2.54,0,1,1,3-4.08,90,90,0,0,1,10.24,8.87,2.54,2.54,0,0,1-1.81,4.33" transform="translate(0 0)"/>
                    <path d="M89,177.92a89.53,89.53,0,0,1-11-.67,2.54,2.54,0,0,1,.62-5.05A84,84,0,0,0,148,148.59a2.55,2.55,0,0,1,3.58,3.62A88.46,88.46,0,0,1,89,177.92" transform="translate(0 0)"/>
                    <path d="M119.92,161.72a7.16,7.16,0,0,1-6.42-4,7.17,7.17,0,0,1,3.25-9.59L119,152.7a2.08,2.08,0,0,0,.25,3.83,2,2,0,0,0,1.59-.11l2.25,4.56a7.06,7.06,0,0,1-3.17.74" transform="translate(0 0)"/>
                    <path d="M38.51,148.18A7.17,7.17,0,0,1,33,145.66l3.88-3.29a2.07,2.07,0,1,0,3.16-2.69L44,136.39a7.16,7.16,0,0,1-5.45,11.79" transform="translate(0 0)"/>
                    <path d="M137.36,42.16a7.11,7.11,0,0,1-4.89-1.93L136,36.52a2.08,2.08,0,0,0,1.48.55,2,2,0,0,0,1.45-.65,2.12,2.12,0,0,0,.56-1.49,2,2,0,0,0-.66-1.44l3.47-3.72a7.16,7.16,0,0,1-4.65,12.39h-.24" transform="translate(0 0)"/>
                    <path d="M42.7,52.91a7.16,7.16,0,0,1-5.58-11.63l4,3.17a2.08,2.08,0,0,0,.33,2.92A2.08,2.08,0,0,0,44.34,47l4,3.17a7.1,7.1,0,0,1-4.8,2.65,7.34,7.34,0,0,1-.81,0" transform="translate(0 0)"/>
                    <path d="M47.31,67.44l-4.49-2.39a2.08,2.08,0,1,0-3.66-2L34.68,60.7a7.16,7.16,0,1,1,12.63,6.74" transform="translate(0 0)"/>
                    <path d="M154.62,68.83q0-16.38,4.12-21.79t11.73-5.4q7.6,0,10.88,3.73a13.94,13.94,0,0,1,3.08,5.38q1.68,6.26,1.69,18.7v7.78h-7.74V68.52q0-11.37-1.23-15.21t-3.48-4.86a6.64,6.64,0,0,0-2.82-.57q-4.86,0-6.73,5t-1.87,15.62v36.16q0,13.07,1.64,18.05c1.09,3.33,3.28,5,6.55,5s5.42-1.66,6.43-5,1.51-9.32,1.51-18V97.46h7.69v7.17q0,11.84-1.34,17a21.78,21.78,0,0,1-3,7.45q-3.59,4.81-11.11,4.82t-11.32-4.77q-4.67-5.84-4.66-24.43Z" transform="translate(0 0)"/>
                    <path d="M197.15,42.76h15.32q9.27,0,12.39,3.89a14.68,14.68,0,0,1,3,7.15,89,89,0,0,1,.64,12.26,38.91,38.91,0,0,1-1.87,13.14q-1.87,5.1-6.43,6.33a7.88,7.88,0,0,1,3,1.1,6.67,6.67,0,0,1,2.2,2.69q3,5.88,3,29,0,9.57.17,11a17.35,17.35,0,0,0,.95,3.56H222c-.54-1-.87-3-1-5.81s-.15-7.4-.15-13.65v-9.93q0-9.44-1.16-12T212.88,89l-8.1-.51V132.9h-7.63Zm23.72,21.77a55.28,55.28,0,0,0-.65-10q-.63-3.18-2.15-4.3a8.06,8.06,0,0,0-4.79-1.13h-8.55V83.33h8c2.6,0,4.36-.45,5.3-1.34a8.68,8.68,0,0,0,2.13-4.43q.72-3.09.72-12.37Z" transform="translate(0 0)"/>
                    <polygon points="240.4 42.76 264.21 42.76 264.21 48.7 248.03 48.7 248.03 83.94 263.24 83.94 263.24 90.14 248.03 90.14 248.03 126.86 264.06 126.86 264.06 132.9 240.4 132.9 240.4 42.76"/>
                    <path d="M274,42.76h13.88q12.15,0,15.47,9.94,2,5.79,1.95,15.77l.05,38.62q0,14.09-4,20t-13.78,5.86H274ZM297.7,67.65q0-9.83-2.26-14.18a7.46,7.46,0,0,0-7.07-4.36h-6.76v77.24h6.3q6.36,0,8.3-5.79,1.55-4.77,1.54-14.24Z" transform="translate(0 0)"/>
                    <path d="M326.21,42.76H334l16,90.14h-7.37l-3-18.28h-18l-2.72,18.28h-7.22Zm12.45,65.56-8.3-51h-.1l-7.63,51Z" transform="translate(0 0)"/>
                    <path d="M356.65,56.59q.77-7.32,4.33-11.14t11.14-3.82q7.58,0,10.55,3.79a19.84,19.84,0,0,1,2.56,4.1q1.93,4.15,2,19.77v5.07h-7.83V68.73q0-11.88-.77-14.39A15.5,15.5,0,0,0,377,50.55q-1.48-2.36-5.32-2.36-7.8,0-7.79,14.49,0,5.07,1,6.89c.65,1.22,1.14,2.16,1.46,2.85s2.5,3,6.53,7.06l4.92,5q10.9,11.22,10.91,25.74t-4.18,19.13q-4.17,4.6-12.36,4.61-12.6,0-14.5-15.21a101.1,101.1,0,0,1-.66-12.6V96.74h7.78V106q0,12.2,1.61,16.8a6.38,6.38,0,0,0,6.58,4.61c3.32,0,5.42-1,6.33-3a18.92,18.92,0,0,0,1.49-7.25c.08-2.84.12-5.87.12-9.12q0-10-7.94-17.77l-5.22-5q-6.87-6.7-9.17-11.29a20.66,20.66,0,0,1-2.3-9.32,76.71,76.71,0,0,1,.36-8.07" transform="translate(0 0)"/>
                </g>
            </g>
        </g>
    </svg>
</div>
