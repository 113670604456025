import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';

// ngrx store
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

// store
import { effects } from './store/effects';
import { reducers } from './store/reducers';

// modules
import { MobilePhotoRoutingModule } from './mobile-photo-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { IdentityVerificationModule } from 'app/shared/modules/identity-verification/identity-verification.module';

// components
import { QrCodeDialogComponent } from './components/qr-code-dialog/qr-code-dialog.component';
import { MobilePhotoCaptureComponent } from './components/mobile-photo-capture/mobile-photo-capture.component';

// services
import { MobilePhotoService } from './services/mobile-photo.service';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const components = [
    MobilePhotoCaptureComponent,
    QrCodeDialogComponent
];

@NgModule({
    imports: [
        QRCodeModule,
        CommonModule,
        SharedModule,
        MobilePhotoRoutingModule,
        IdentityVerificationModule,
        StoreModule.forFeature(StoreNames.mobilePhotoStore, reducers),
        EffectsModule.forFeature(effects)
    ],
    providers: [
        MobilePhotoService,
    ],
    declarations: [
        ...components
    ]
})
export class MobilePhotoModule { }
