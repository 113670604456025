<div class="image-observation-list">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="start center">
        <app-photo-capture #imageUpload 
            (imageUploaded)="imageUploaded($event)"
            [downloadEnabled]="false"
            [disableUpload]="item.readonly"
            [removeEnabled]="false"
            [alwaysHidePreview]="true"
            [qrCodeData]="qrCodeData">
        </app-photo-capture>
        <div class="list-container" fxFlex="100%" >
            <app-loading-card *ngIf="loading"></app-loading-card>
            <div class="inner-list-container" *ngIf="!loading" fxLayout="row">
                <div fxFlex="40px" fxLayoutAlign="center center">
                    <button mat-icon-button *ngIf="showLeftRight()" (click)="scrollLeft(list)">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                </div>
                <div #list class="list-scroll" fxFlex fxLayout="row" fxLayoutGap="10px">
                    <div *ngFor="let itemObservation of item.itemObservations?.slice()?.reverse()" class="image-card-container">
                        <app-image-card 
                            (imageRemoveClicked)="imageDeleteClicked(itemObservation.observationId)"
                            (imageDownloadClicked)="imageDownloadClicked(itemObservation.observationId)"
                            [canDownload]="false"
                            [canRemove]="true"
                            [src]="getUrl(itemObservation)">
                        </app-image-card>
                    </div>
                </div>
                <div fxFlex="40px" fxLayoutAlign="center center">
                    <button mat-icon-button *ngIf="showLeftRight()" (click)="scrollRight(list)">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
