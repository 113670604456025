export enum ActiveInactiveOptions {
    Active = 1,
    InActive = 2,
}

export function getActiveInactiveOptionsLabel(value: ActiveInactiveOptions): string {
    switch (value) {
        case ActiveInactiveOptions.Active:
            return 'Active';
        case ActiveInactiveOptions.InActive:
            return 'In-Active';
        default:
            return '';
    }
}
