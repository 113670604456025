import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

// Enums
import { ItemType, isMultiOptionSingle, isList, isShortText, isDocument, isImageType, isDate } from 'app/shared/enums/item-type.enum';

// Models
import { ItemObservation } from 'app/shared/modules/set-observations/models/item-observation.model';
import { Item } from 'app/shared/modules/set-observations/models/item.model';

@Component({
    selector: 'app-observation',
    templateUrl: './observation-wrapper.component.html',
    styleUrls: ['./observation-wrapper.component.scss']
})
export class ObservationWrapperComponent implements OnInit {

    @Input()
    item: Item;

    @Input()
    itemObservation: ItemObservation;

    @Input()
    selected: boolean;

    @Output()
    valueUpdated: EventEmitter<any> = new EventEmitter();

    @Output()
    selectedChanged: EventEmitter<any> = new EventEmitter();

    @Output()
    deleteObservationClicked: EventEmitter<string> = new EventEmitter();

    @Output()
    formStatusChanged: EventEmitter<boolean> = new EventEmitter();

    form: FormGroup;
    formControl: FormControl;

    ItemType = ItemType;

    isDate = false;
    isDocument = false;
    isImage = false;
    isShortText = false;
    isMultiOptionSingle = false;
    isList = false;
    isDeletable = false;

    constructor(private fb: FormBuilder) {
    }

    ngOnInit(): void {
        const validators = [];

        if (this.item.regexExpression && this.item.regexValidationMessage) {
            validators.push(Validators.pattern(this.item.regexExpression));
        }

        this.formControl = new FormControl(this.itemObservation.value, validators);

        this.form = this.fb.group({
            observation: this.formControl,
            setItemId: [this.item.id]
        });

        // emit invalid flag when the form changes
        this.form.statusChanges.subscribe(() => this.formStatusChanged.emit(this.form.invalid));

        // if form is invalid immediately (because item template may have changed)
        // then emit the form state straight away
        if (this.form.invalid) {
            this.formStatusChanged.emit(this.form.invalid);
        }

        this.isDate = isDate(this.item.type);
        this.isDocument = isDocument(this.item.type);
        this.isImage = isImageType(this.item.type) && this.item.type !== ItemType.Selfie && this.item.type !== ItemType.UniqueActionProcess;
        this.isShortText = isShortText(this.item.type);
        this.isMultiOptionSingle = isMultiOptionSingle(this.item.type);
        this.isList = isList(this.item.type);
        this.isDeletable = this.item.type === ItemType.ShortTextList || this.isDocument;
    }

    deleteObservation(): void {
        this.deleteObservationClicked.emit(this.itemObservation.observationId);
    }

    onValueUpdated($event): void {
        this.valueUpdated.emit($event);
    }

    onSelectedChanged($event): void {
        this.selectedChanged.emit($event);
    }

    onValueDeleted(itemObservation): void {
        this.deleteObservationClicked.emit(itemObservation.observationId);
    }
}