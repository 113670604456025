import { Component } from '@angular/core';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-number',
    templateUrl: './number-observation.component.html',
    outputs: ['valueUpdated', 'selectedChanged']
})
export class NumberObservationComponent extends ObservationComponent {

    constructor() {
        super();
    }

    init(): void {
    }
}
