import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as setReducer from './set-observation.reducers';
import * as assessmentProfileReducer from './assessment-profile-set.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

export interface SetObservationsState {
    set: setReducer.SetState;
    assessmentProfile: assessmentProfileReducer.AssessmentProfileState;
}

export const reducers: ActionReducerMap<SetObservationsState> = {
    set: setReducer.reducer,
    assessmentProfile: assessmentProfileReducer.reducer
};

export const getSetObservationsState = createFeatureSelector<SetObservationsState>(StoreNames.setObservations);
