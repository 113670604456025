import { createSelector } from '@ngrx/store';
import { SetObservationsState, getSetObservationsState } from 'app/shared/modules/set-observations/store/reducers';
import { SetState } from 'app/shared/modules/set-observations/store/reducers/set-observation.reducers';
import { AssessmentProfileState } from 'app/shared/modules/set-observations/store/reducers/assessment-profile-set.reducers';

export * from './set-observation.selectors';
export * from './assessment-set.selectors';

export const getSetState = createSelector(getSetObservationsState, (state: SetObservationsState) => state.set);

// todo these should be split out into relevant files. the index is just meant to correlate a link to all the files
// a lot of this set-obs store needs re-arranging looking at so will revisit
export const getLoading = createSelector(getSetState, (state: SetState) => state.loading);
export const getLoaded = createSelector(getSetState, (state: SetState) => state.loaded);
export const getSet = createSelector(getSetState, (state: SetState) => state.set);
export const getItems = createSelector(getSetState, (state: SetState) => state.items);
export const getSaving = createSelector(getSetState, (state: SetState) => state.saving);
export const getSaved = createSelector(getSetState, (state: SetState) => state.saved);
export const getWorking = createSelector(getSetState, (state: SetState) => state.saving || state.loading);
export const getESignDocuments = createSelector(getSetState, (state: SetState) => state.eSignDocuments);
export const getCredasRegistrationUrl = createSelector(getSetState, (state: SetState) => state.credasRegistrationUrl);
export const getBadgeSummaries = createSelector(getSetState, (state: SetState) => state.badgeSummaries);
export const getBadgeSummariesRetrieved = createSelector(getSetState, (state: SetState) => state.badgeSummariesRetrieved);
export const getUapImage = createSelector(getSetState, (state: SetState) => state.uapImage);
export const getUploadingIdDocument = createSelector(getSetState, (state: SetState) => state.uploadingIdDocument);
export const getRecordingImmediateObservation = createSelector(getSetState, (state: SetState) => state.recordingImmediateObservation);
export const getIdDocumentUrls = createSelector(getSetState, (state: SetState) => state.idDocumentUrls);
export const getIdDocumentUrlsLoading = createSelector(getSetState, (state: SetState) => state.idDocumentUrlsLoading);

export const getAssessmentProfileSetsState = createSelector(getSetObservationsState, (state: SetObservationsState) => state.assessmentProfile);

export const getAssessmentProfile = createSelector(getAssessmentProfileSetsState, (state: AssessmentProfileState) => state.profiles);
export const getAssessmentProfileLoading = createSelector(getAssessmentProfileSetsState, (state: AssessmentProfileState) => state.loading);
export const getAssessmentProfileLoaded = createSelector(getAssessmentProfileSetsState, (state: AssessmentProfileState) => state.loaded);

export const getCourses = createSelector(getSetState, (state: SetState) => state.courses);
export const getLoadingCourses = createSelector(getSetState, (state: SetState) => state.loadingCourses);
export const getCourseDetail = createSelector(getSetState, (state: SetState) => state.courseDetail);
export const getLoadingCourseDetail = createSelector(getSetState, (state: SetState) => state.loadingCourseDetail);
