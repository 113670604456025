import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Models
import { AssessmentSetGroup } from 'app/shared/models/assessment-set-group.model';

@Injectable()
export class SetTemplateGroupsService {

    private baseEndpoint = 'api/setTemplates';

    constructor(private http: HttpClient) { }

    public getAssessmentSetGroups(): Observable<AssessmentSetGroup[]> {
        return this.http.get<AssessmentSetGroup[]>(`${this.baseEndpoint}/assessmentSetGroups`);
    }
}