import { DocumentLibraryEffects } from './document-library.effects';
import { OrganisationDocumentEffects } from './organisation-document.effects';

export const effects: any[] = [
    DocumentLibraryEffects,
    OrganisationDocumentEffects
];

export * from './document-library.effects';
export * from './organisation-document.effects';
