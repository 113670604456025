import { Action } from '@ngrx/store';

// Models
import { AssessmentProfileResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-response.model';
import { GetOrganisationProfileForGroupRequest } from 'app/shared/modules/set-observations/models/requests/get-organisation-profile-for-group-request.model';
import { GetOrganisationProfileByOwnerRequest } from 'app/shared/modules/set-observations/models/requests/get-organisation-profile-by-owner-request.model';

// Action types
export const GET_ORGANISATION_ASSESSMENT_PROFILE = '[Assessment Profile] Get organisation profile';
export const GET_USER_ASSESSMENT_PROFILES = '[Assessment Profile] Get user profiles';
export const GET_ORGANISATION_ASSESSMENT_PROFILE_BY_OWNER = '[Assessment Profile] Get organisation profile by owner';
export const GET_ORGANISATION_GROUP_ASSESSMENT_PROFILE = '[Assessment Profile] Get organisation profile for group';
export const GET_USER_REGISTRATION_ASSESSMENT_PROFILE = '[Assessment Profile] Get user profile for registration';

export const GET_ASSESSMENT_PROFILES_SUCCESS = '[Assessment Profile] Get profiles success';
export const GET_ASSESSMENT_PROFILES_FAIL = '[Assessment Profile] Get profiles fail';

export const CREATE_MISSING_ORGANISATION_SETS = '[Assessment Profile] Create any missing organisation sets which have been added';

export const CHECK_USER_ASSESSMENT_PROFILE = '[Assessment Profile] Check for any incomplete user assessment sets';
export const CHECK_ASSESSMENT_PROFILE_SUCCESS = '[Assessment Profile] Check for any incomplete assessment sets success';
export const CHECK_ASSESSMENT_PROFILE_FAIL = '[Assessment Profile] Check for any incomplete assessment sets fail';

export const CLEAR_ASSESSMENT_PROFILE = '[Assessment Profile] Clear profile';

export const NEW_SETS_ADDED_TO_PROFILE = '[Sets Observations] New sets added to profile';

export class GetOrganisationAssessmentProfile implements Action {
    readonly type = GET_ORGANISATION_ASSESSMENT_PROFILE;
    constructor(public payload: string) { }
}

export class GetUserAssessmentProfiles implements Action {
    readonly type = GET_USER_ASSESSMENT_PROFILES;
}

export class GetOrganisationAssessmentProfileByOwner implements Action {
    readonly type = GET_ORGANISATION_ASSESSMENT_PROFILE_BY_OWNER;
    constructor(public payload: GetOrganisationProfileByOwnerRequest) { }
}

export class GetOrganisationGroupAssessmentProfile implements Action {
    readonly type = GET_ORGANISATION_GROUP_ASSESSMENT_PROFILE;
    constructor(public payload: GetOrganisationProfileForGroupRequest) { }
}

export class GetAssessmentProfileSuccess implements Action {
    readonly type = GET_ASSESSMENT_PROFILES_SUCCESS;
    constructor(public payload: AssessmentProfileResponse[]) { }
}

export class GetAssessmentProfileFail implements Action {
    readonly type = GET_ASSESSMENT_PROFILES_FAIL;
    constructor(public payload: string) { }
}

export class CreateMissingOrganisationSets implements Action {
    readonly type = CREATE_MISSING_ORGANISATION_SETS;
    constructor(public payload: string) { }
}

export class CheckAssessmentProfileSuccess implements Action {
    readonly type = CHECK_ASSESSMENT_PROFILE_SUCCESS;
}

export class CheckAssessmentProfileFail implements Action {
    readonly type = CHECK_ASSESSMENT_PROFILE_FAIL;
    constructor(public payload: string) { }
}

export class ClearAssessmentProfile implements Action {
    readonly type = CLEAR_ASSESSMENT_PROFILE;
}

export class CheckSetsAddedToProfile implements Action {
    readonly type = NEW_SETS_ADDED_TO_PROFILE;
    constructor(public payload: boolean) { }
}

export type AllAssessmentProfileActions
    = GetOrganisationAssessmentProfile
    | GetOrganisationAssessmentProfileByOwner
    | GetOrganisationGroupAssessmentProfile
    | GetAssessmentProfileSuccess
    | GetAssessmentProfileFail
    | CreateMissingOrganisationSets
    | CheckAssessmentProfileFail
    | ClearAssessmentProfile
    | CheckSetsAddedToProfile;