import {  createAction, props } from '@ngrx/store';

// Models
import { User } from 'app/models/user.model'; // todo make new model
import { TokenResponse, RefreshTokenRequest } from 'app/shared/models';
import { LoginDetails } from 'app/authentication-v2/models/login-details.model';
import { TemporaryPasswordReset } from 'app/authentication-v2/models/temporary-password-reset.model';
import { PasswordResetDetails } from 'app/authentication-v2/models/password-reset-details.model';
import { LogoutOptions } from 'app/models/logout-options.model';
import { TokenLoginDetails } from 'app/authentication-v2/models/token-login-details.model';

// actions
export const SetToken = createAction(
    '[Auth v2] Set token',
    props<{token: TokenResponse}>()
);

export const Login = createAction(
    '[Auth v2] Login',
    props<{request: LoginDetails}>()
);

export const LoginSuccess = createAction(
    '[Auth v2] Login success',
    props<{ user: User}>()
);

export const LoginFail = createAction(
    '[Auth v2] Login fail',
    props<{ message: string}>()
);

export const Logout = createAction(
    '[Auth v2] Logout',
    props<{options: LogoutOptions}>()
);

export const LogoutSuccess = createAction(
    '[Auth v2] Logout success',
    props<{options: LogoutOptions}>()
);

export const LogoutFail = createAction(
    '[Auth v2] Logout fail'
);

export const RefreshToken = createAction(
    '[Auth v2] Refresh token',
    props<{request: RefreshTokenRequest}>()
);

export const RefreshTokenSuccess = createAction(
    '[Auth v2] Refresh token success',
    props<{token: TokenResponse}>()
);

export const RefreshTokenFail = createAction(
    '[Auth v2] Refresh token fail'
);

export const RehydrateUser = createAction(
    '[Auth v2] Rehydrate user'
);

export const RehydrateUserSuccess = createAction(
    '[Auth v2] Rehydrate user success',
    props<{user: User}>()
);

export const RehydrateUserFail = createAction(
    '[Auth v2] Rehydrate user fail'
);

export const SetPassword = createAction(
    '[Auth v2] Set Password',
    props<{request: PasswordResetDetails}>()
);

export const SetPasswordSuccess = createAction(
    '[Auth v2] Set Password success',
    props<{user: User}>()
);

export const SetPasswordFail = createAction(
    '[Auth v2] Set Password fail'
);

export const PasswordResetRequired = createAction(
    '[Auth v2] Password reset required',
    props<{request: TemporaryPasswordReset}>()
);

export const ClearPasswordResetState = createAction(
    '[Auth v2] Clear password reset state'
);

export const CheckPasswordLink = createAction(
    '[Auth v2] Clear password link',
    props<{email: string}>()
);

export const CheckPasswordLinkSucccess = createAction(
    '[Auth v2] Check password link success',
    props<{valid: boolean}>()
);

export const CheckPasswordLinkFail = createAction(
    '[Auth v2] Check password link fail'
);

export const TokenLogin = createAction(
    '[Auth v2] Token Login',
    props<{request: TokenLoginDetails}>()
);

export const TokenLoginSuccess = createAction(
    '[Auth v2] Token login success',
    props<{ user: User; tokenLogin: TokenLoginDetails}>()
);