import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'isMatchingString' })
export class IsMatchingStringPipe implements PipeTransform {
    transform(stringToCheck: string, comparisonString: string): boolean {
        if (!stringToCheck && !comparisonString) {
            return true;
        }
        if (!stringToCheck || !comparisonString) {
            return false;
        }

        return stringToCheck.toLowerCase() === comparisonString.toLowerCase();
    }
}