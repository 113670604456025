import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// NgRx/RxJs
import { Observable } from 'rxjs';

// Models
import { UpdateAssessmentsRequest } from 'app/shared/modules/group/models/update-assessments-request.model';
import { AssessmentSetSummary } from 'app/shared/modules/group/models/assessment-set-summary.model';
import { BadgeSummary } from 'app/shared/modules/group/models/badge-summary.model';
import { ManageAssessmentSetInfo } from 'app/shared/modules/group/models/manage-assessment-set-info.model';

// Enums
import { ManageAssessmentSetDialogType } from 'app/shared/modules/group/enums/manage-assessment-set-dialog-type.enum';


@Injectable()
export class ManageAssessmentSetService {

    private baseEndpoint = 'api/manage-assessments';

    constructor(private http: HttpClient) { }

    public getAssessmentSets(groupId: string): Observable<AssessmentSetSummary[]> {
        const url = `${this.baseEndpoint}/groups/${groupId}/assessment-sets`;
        return this.http.get<AssessmentSetSummary[]>(url);
    }

    public getIndividualAssessmentSets(request: ManageAssessmentSetInfo): Observable<AssessmentSetSummary[]> {
        const url = `${this.baseEndpoint}/groups/${request.groupId}/organisation/${request.entityId}/individual-assessment-sets/`;
        return this.http.get<AssessmentSetSummary[]>(url);
    }

    public getProfileAssessmentSets(): Observable<AssessmentSetSummary[]> {
        const url = `${this.baseEndpoint}/profile/assessment-sets`;
        return this.http.get<AssessmentSetSummary[]>(url);
    }

    public getBadges(groupId: string): Observable<BadgeSummary[]> {
        const url = `${this.baseEndpoint}/groups/${groupId}/badges`;
        return this.http.get<BadgeSummary[]>(url);
    }

    public getIndividualInviteBadges(data: ManageAssessmentSetInfo): Observable<BadgeSummary[]> {
        const url = `${this.baseEndpoint}/groups/${data.groupId}/organisation/${data.entityId}/individual-badges`;
        return this.http.get<BadgeSummary[]>(url);
    }

    public getProfileBadges(): Observable<BadgeSummary[]> {
        const url = `${this.baseEndpoint}/profile/badges`;
        return this.http.get<BadgeSummary[]>(url);
    }

    public updateAssessmentSets(request: UpdateAssessmentsRequest): Observable<void> {
        const url = `${this.baseEndpoint}/${this.getSegment(request)}/assessment-sets`;

        return this.http.put<void>(url, request);
    }

    private getSegment(request: UpdateAssessmentsRequest): string {
        switch (request.type) {
            case ManageAssessmentSetDialogType.Group: return `groups/${request.groupId}`;
            case ManageAssessmentSetDialogType.Profile: return 'profile';
            case ManageAssessmentSetDialogType.Invite: return 'invite';
            case ManageAssessmentSetDialogType.IndividualInvite: return 'invite';
        }
    }
}