import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControlOptions } from '@angular/forms';

// ngrx/rxjs
import { Store, ActionsSubject, select } from '@ngrx/store';
import { takeUntil, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Store
import * as fromAuth from 'app/authentication/store';
import * as fromUser from 'app/store/actions/active-user.actions';

// Models
import { RegExRules } from 'app/shared/models';
import { PasswordValidation } from 'app/authentication/models';
import { InviteDetails } from 'app/authentication/models/invite-details.model';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Services
import { ValidationService } from 'app/shared/services';
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { AuthenticationEventTrackingService } from 'app/authentication/services/authentication-event-tracking.service';
import { AuthenticationService } from 'app/authentication/services/authentication.service';

// Enums
import { RegistrationStatus } from 'app/authentication/enums/registration-status.enum';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class RegisterComponent extends BaseComponent implements OnInit {

    inviteId: string;
    working = false;

    registerForm: FormGroup;
    regexRules: RegExRules = new RegExRules();

    hidePassword: boolean = true;
    hideConfirmPassword: boolean = true;
    title: string;
    stepIndex = 1;
    useRegistrationCode = false;

    registrationError$: Observable<string>;
    validating$: Observable<boolean>;

    constructor(
        private fb: FormBuilder,
        private store: Store<fromAuth.AuthenticationState>,
        private actionsSubject: ActionsSubject,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
        private authenticationEventTrackingService: AuthenticationEventTrackingService,
        public validationService: ValidationService,
        public authenticationService: AuthenticationService
    ) {
        super();
    }

    ngOnInit() {
        this.validating$ = this.store.select(fromAuth.getValidating);
        this.registrationError$ = this.store.select(fromAuth.getRegistrationError);

        this.registerForm = this.fb.group({
            firstName: [null, [Validators.required, Validators.pattern(this.regexRules.name), Validators.minLength(2), Validators.maxLength(60)]],
            surname: [null, [Validators.required, Validators.pattern(this.regexRules.name), Validators.minLength(2), Validators.maxLength(60)]],
            email: [null, [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(255)]],
            password: [null, [Validators.required, Validators.pattern(this.regexRules.password), Validators.minLength(9)]],
            confirmPassword: [null, [Validators.required, Validators.pattern(this.regexRules.password), Validators.minLength(9)]],
            termsAccepted: [false, [Validators.required, Validators.requiredTrue]],
            phoneNumber: [null, [Validators.required, Validators.pattern(this.regexRules.mobile_number), Validators.minLength(10), Validators.maxLength(12)]],
            registrationCode: [null]
        }, <AbstractControlOptions> { validators: PasswordValidation.matchPassword });

        this.route.params.subscribe(qp => {
                this.inviteId = qp.piid;
                this.checkIfAlreadyRegistered();

                if (!qp.piid) {
                    this.useRegistrationCode = true;
                    this.stepIndex = 0;
                    this.registerForm.get('registrationCode').setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(8)]);
                }

                this.setTitle();
            });

        this.route.queryParams.subscribe(qp => {
            if (qp.regCode) {
                this.registerForm.get('registrationCode').setValue(qp.regCode);
            }
        });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromAuth.REGISTER_SUCCESS))
            .subscribe((action: fromAuth.RegisterSuccess) => {
                this.store.dispatch(new fromUser.GetUserInviteId(action.payload.id));
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromAuth.REGISTER_FAIL))
            .subscribe(() => {
                this.working = false;
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromAuth.VALIDATE_REGISTRATION_CODE_SUCCESS))
            .subscribe((action: fromAuth.ValidateRegistrationCodeSuccess) => {
                if (action.payload === RegistrationStatus.Valid) {
                    this.next();
                } else if (action.payload === RegistrationStatus.Registered) {
                    this.router.navigate(['/auth/login']);
                }
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromAuth.VALIDATE_EMAIL_ADDRESS_SUCCESS))
            .subscribe((action: fromAuth.ValidateEmailAddressSuccess) => {
                if (action.payload) {
                    this.next();
                }
            });
    }

    checkRegistrationCode(): void {
        this.store.dispatch(new fromAuth.ValidateRegistrationCode(this.registerForm.get('registrationCode').value));
    }

    checkEmail(): void {
        this.store.dispatch(
            new fromAuth.ValidateEmailAddress(this.registerForm.get('email').value));
    }

    next(): void {
        if (this.stepIndex < 4) {
            this.stepIndex++;
            this.setTitle();
        }
    }

    back(): void {
        if (this.useRegistrationCode && this.stepIndex > 0) {
            this.stepIndex--;
            this.setTitle();
        } else if (!this.useRegistrationCode && this.stepIndex > 1) {
            this.stepIndex--;
            this.setTitle();
        }
    }

    registerUser(event: FormGroup) {
        this.authenticationEventTrackingService.registerClicked();
        this.working = true;
        event.value.inviteId = this.inviteId;
        this.store.dispatch(new fromAuth.Register(event.value));
    }

    get step1Valid(): boolean {
        return !this.registerForm.get('registrationCode').hasError('required') &&
            !this.registerForm.get('registrationCode').hasError('minlength') &&
            !this.registerForm.get('registrationCode').hasError('maxlength');
    }

    get step2Valid(): boolean {
        return !this.registerForm.get('firstName').hasError('required') &&
            !this.registerForm.get('firstName').hasError('name') &&
            !this.registerForm.get('firstName').hasError('minlength') &&
            !this.registerForm.get('firstName').hasError('maxlength') &&
            !this.registerForm.get('surname').hasError('required') &&
            !this.registerForm.get('surname').hasError('pattern') &&
            !this.registerForm.get('surname').hasError('minlength') &&
            !this.registerForm.get('surname').hasError('maxlength');
    }

    get step3Valid(): boolean {
        return !this.registerForm.get('phoneNumber').hasError('required') &&
            !this.registerForm.get('phoneNumber').hasError('mobile_number') &&
            !this.registerForm.get('phoneNumber').hasError('minlength') &&
            !this.registerForm.get('phoneNumber').hasError('maxlength') &&
            !this.registerForm.get('email').hasError('required') &&
            !this.registerForm.get('email').hasError('email') &&
            !this.registerForm.get('email').hasError('minlength') &&
            !this.registerForm.get('email').hasError('maxlength');
    }

    private setTitle(): void {
        switch (this.stepIndex) {
            case 0: this.title = 'Please enter your 8-digit registration code'; break;
            case 1: this.title = 'Please confirm your name'; break;
            case 2: this.title = 'Add your contact details'; break;
            case 3: this.title = 'Set your password'; break;
        }
    }

    private checkIfAlreadyRegistered() {
        if (this.inviteId) {
            this.authenticationService.hasInviteBeenUsed(this.inviteId).subscribe((result) => {
                if (result) {
                    this.authenticationEventTrackingService.redirectingExistingUser();
                    this.router.navigate(['/auth/login']);
                    this.alertService.info('Your invite has either been used or expired. If you have already registered, please log in, else please contact support.');
                } else {
                    this.store.dispatch(new fromAuth.GetInviteDetails(this.inviteId));
                    this.store.pipe(
                        takeUntil(this.ngUnsubscribe),
                        select(fromAuth.getInviteDetails))
                        .subscribe((inviteDetails: InviteDetails) => {
                            if (inviteDetails) {
                                const names = inviteDetails.name.split(' ');
                                const firstName = names[0];
                                const surname = inviteDetails.name.replace(firstName, '').trim();
                                this.setFormValue('firstName', firstName);
                                this.setFormValue('surname', surname);
                                this.setFormValue('email', inviteDetails.email);
                                this.setFormValue('phoneNumber', inviteDetails.phoneNumber);
                            }
                        });
                }
            });
        }
    }

    private setFormValue(field: string, value: string) {
        this.registerForm.get(field).setValue(value);
        this.registerForm.controls[field].markAsTouched();
        this.registerForm.controls[field].markAsDirty();
        this.registerForm.controls[field].updateValueAndValidity();
    }

}
