import * as SetObservationActions from 'app/shared/modules/set-observations/store/actions/set-observation.actions';

// models
import { ObservationResponse } from 'app/shared/modules/set-observations/models/responses/observation-response.model';
import { ItemObservation } from 'app/shared/modules/set-observations/models/item-observation.model';
import { SetSummary } from 'app/shared/modules/set-observations/models/set-summary.model';
import { ItemObservationResponse } from 'app/shared/modules/set-observations/models/responses/item-observation-response.model';
import { ESignDocumentResponse } from 'app/shared/modules/set-observations/models/responses/esign-document-response.model';
import { Item } from 'app/shared/modules/set-observations/models/item.model';
import { BadgeSummary } from 'app/shared/modules/set-observations/models/responses/badge-summary.model';
import { UapImage } from 'app/shared/modules/set-observations/models/responses/uap-image.model';
import { CourseSummary } from 'app/shared/modules/set-observations/models/responses/course-summary.model';
import { CourseDetail } from 'app/shared/modules/set-observations/models/responses/course-detail.model';
import { IdDocumentUrl } from 'app/shared/modules/set-observations/models/responses/id-document-url.model';

// enums
import { ItemType } from 'app/shared/enums/item-type.enum';

export interface SetState {
    set: SetSummary;
    items: Item[];
    loaded: boolean;
    loading: boolean;
    saving: boolean;
    saved: boolean;
    eSignDocuments: ESignDocumentResponse[];
    credasRegistrationUrl: string;
    badgeSummaries: BadgeSummary[];
    badgeSummariesRetrieved: boolean;
    uapImage: UapImage;
    uploadingIdDocument: boolean;
    courses: CourseSummary[];
    loadingCourses: boolean;
    courseDetail: CourseDetail;
    loadingCourseDetail: boolean;
    recordingImmediateObservation: boolean;
    idDocumentUrls: IdDocumentUrl[];
    idDocumentUrlsLoading: boolean;
}

const initialState: SetState = {
    set: null,
    items: null,
    loaded: false,
    loading: false,
    saving: false,
    saved: false,
    eSignDocuments: [],
    credasRegistrationUrl: null,
    badgeSummaries: [],
    badgeSummariesRetrieved: false,
    uapImage: null,
    uploadingIdDocument: false,
    courses: null,
    loadingCourses: false,
    courseDetail: null,
    loadingCourseDetail: false,
    recordingImmediateObservation: false,
    idDocumentUrls: null,
    idDocumentUrlsLoading: false
};

export function reducer(state = initialState, action: SetObservationActions.AllSetObservationActions): SetState {
    switch (action.type) {
        case SetObservationActions.GET_SET: {
            return {
                ...state,
                loading: true,
                loaded: false,
                set: null,
                items: null,
                eSignDocuments: []
            };
        }

        case SetObservationActions.GET_SET_SUCCESS: {
            const set = action.payload;
            const setSummary = new SetSummary(set);

            // todo this code should be happening at the server level - currently duplicated in set journey dialog
            const items: Item[] = [];

            for (const itemResponse of set.items) {
                const item = new Item(itemResponse, set.id);
                items.push(item);

                if (itemResponse.itemObservations === null || itemResponse.itemObservations === undefined || itemResponse.itemObservations.length === 0) {
                    if (itemResponse.type !== ItemType.MultiOptionList) {
                        item.addItemObservation(
                            new ItemObservationResponse(
                                new ObservationResponse(set.ownerId)));
                    }
                } else {
                    for (const itemObservation of itemResponse.itemObservations) {
                        item.addItemObservation(itemObservation);
                    }
                }
            }

            for (const item of items) {
                checkConditionalItems(item.id, items, false);
            }

            return {
                ...state,
                loading: false,
                loaded: true,
                set: setSummary,
                items,
            };
        }


        case SetObservationActions.GET_SET_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
                set: null,
                items: null
            };
        }

        case SetObservationActions.ADD_OBSERVATION: {
            const newItems = state.items.map(i => {
                if (i.id === action.payload) {
                    i.addItemObservation(new ItemObservationResponse(new ObservationResponse(state.set.ownerId)));
                }

                return i;
            });

            return {
                ...state,
                items: newItems,
            };
        }

        case SetObservationActions.ADD_MULTI_OPTION_LIST_OBSERVATION: {
            const newItems = state.items.map(i => {
                if (i.id === action.payload.itemId) {
                    const newObservation = new ItemObservation(
                        new ItemObservationResponse(
                            new ObservationResponse(state.set.ownerId)),
                        state.set.id,
                        i.id,
                        i.type);

                    newObservation.value = action.payload.value;
                    newObservation.changed = true;
                    i.itemObservations = [...i.itemObservations, newObservation];
                }

                return i;
            });

            return {
                ...state,
                items: newItems,
            };
        }

        case SetObservationActions.ADD_IMAGE_LIST_OBSERVATION: {
            const newItems = state.items.map(i => {
                if (i.id === action.payload.itemId) {
                    const newObservation = new ItemObservation(
                        new ItemObservationResponse(
                            new ObservationResponse(state.set.ownerId)),
                        state.set.id,
                        i.id,
                        i.type);

                    newObservation.setFile(action.payload.base64Image,
                        action.payload.imageFilename, action.payload.contentType);
                    newObservation.changed = true;
                    i.itemObservations = [...i.itemObservations, newObservation];
                }

                return i;
            });

            return {
                ...state,
                items: newItems,
            };
        }

        case SetObservationActions.UPDATE_OBSERVATION_CACHE: {
            const newItems = state.items.map((i: Item) => {
                if (i.itemObservations.filter(io => io.id === action.payload.id).length > 0) {
                    i.itemObservations = i.itemObservations.map((io: ItemObservation) => {
                        if (io.id === action.payload.id) {
                            action.payload.changed = true;
                            return action.payload;
                        }

                        return io;
                    });
                }

                return i;
            });

            checkConditionalItems(action.payload.itemId, newItems, true);

            return {
                ...state,
                items: newItems
            };
        }

        case SetObservationActions.DELETE_OBSERVATION: {
            const item = state.items.filter(i => i.itemObservations.filter(io => io.observationId === action.payload).length > 0)[0];
            const newItems = state.items.map(i => {
                if (i.id === item.id) {
                    if (i.itemObservations.length === 1) {
                        i.itemObservations[0].setValue(null);
                        i.itemObservations[0].clearFile();
                    } else {
                        i.itemObservations = i.itemObservations.filter(io => io.observationId !== action.payload);
                    }
                    for (const itemObservation of i.itemObservations) {
                        itemObservation.changed = true;
                    }
                }

                return i;
            });

            checkConditionalItems(item.id, newItems, true);

            return {
                ...state,
                items: newItems
            };
        }

        case SetObservationActions.SAVE_OBSERVATIONS: {
            return {
                ...state,
                badgeSummaries: null,
                saving: true,
                saved: false
            };
        }

        case SetObservationActions.SAVE_OBSERVATIONS_SUCCESS: {
            return {
                ...state,
                saving: false,
                saved: true
            };
        }

        case SetObservationActions.SAVE_OBSERVATIONS_FAIL: {
            return {
                ...state,
                badgeSummaries: null,
                saving: false,
                saved: false
            };
        }

        case SetObservationActions.GET_ESIGN_DOCUMENT_SUCCESS: {
            if (!action.payload || !state.eSignDocuments) {
                return state;
            }

            if (state.eSignDocuments.some(d => d.itemId === action.payload.itemId)) {
                return {
                    ...state,
                    eSignDocuments: state.eSignDocuments.map(e => e.itemId === action.payload.itemId ? action.payload : e)
                };
            }

            return {
                ...state,
                eSignDocuments: [...state.eSignDocuments, action.payload]
            };
        }

        case SetObservationActions.GET_ESIGN_DOCUMENT_FAIL: {
            const failedResponse = new ESignDocumentResponse(action.payload.setId, action.payload.itemId);

            return {
                ...state,
                eSignDocuments: [...state.eSignDocuments, failedResponse]
            };
        }

        case SetObservationActions.GET_BADGE_SUMMARIES: {
            return {
                ...state,
                badgeSummaries: null,
                badgeSummariesRetrieved: false
            };
        }

        case SetObservationActions.GET_BADGE_SUMMARIES_SUCCESS: {
            return {
                ...state,
                badgeSummaries: action.payload,
                badgeSummariesRetrieved: true
            };
        }

        case SetObservationActions.GET_BADGE_SUMMARIES_FAIL: {
            return {
                ...state,
                badgeSummaries: null,
                badgeSummariesRetrieved: true
            };
        }

        case SetObservationActions.CLEAR_BADGE_SUMMARIES: {
            return {
                ...state,
                badgeSummaries: null,
                badgeSummariesRetrieved: false
            };
        }

        case SetObservationActions.GET_CREDAS_REGISTRATION_URL: {
            return {
                ...state,
                credasRegistrationUrl: null
            };
        }

        case SetObservationActions.GET_CREDAS_REGISTRATION_URL_FAIL: {
            return {
                ...state,
                credasRegistrationUrl: null
            };
        }

        case SetObservationActions.GET_CREDAS_REGISTRATION_URL_SUCCESS: {
            return {
                ...state,
                credasRegistrationUrl: action.payload
            };
        }

        case SetObservationActions.GET_UAP_IMAGE_URL:
        case SetObservationActions.GET_UAP_IMAGE_URL_FAIL: {
            return {
                ...state,
                uapImage: null
            };
        }

        case SetObservationActions.GET_UAP_IMAGE_URL_SUCCESS: {
            return {
                ...state,
                uapImage: action.payload
            };
        }

        case SetObservationActions.ADD_ID_DOCUMENT: {
            return {
                ...state,
                uploadingIdDocument: true,
                recordingImmediateObservation: true
            };
        }

        case SetObservationActions.ADD_ID_DOCUMENT_SUCCESS:
        case SetObservationActions.ADD_ID_DOCUMENT_FAIL: {
            return {
                ...state,
                uploadingIdDocument: false,
                recordingImmediateObservation: false
            };
        }

        case SetObservationActions.GET_COURSES: {
            return {
                ...state,
                courses: null,
                loadingCourses: true
            };
        }

        case SetObservationActions.GET_COURSES_SUCCESS: {
            return {
                ...state,
                courses: action.payload,
                loadingCourses: false
            };
        }

        case SetObservationActions.GET_COURSES_FAIL: {
            return {
                ...state,
                courses: null,
                loadingCourses: false
            };
        }

        case SetObservationActions.GET_COURSE_DETAILS: {
            return {
                ...state,
                courseDetail: null,
                loadingCourseDetail: true
            };
        }

        case SetObservationActions.GET_COURSE_DETAILS_SUCCESS: {
            return {
                ...state,
                courseDetail: action.payload,
                loadingCourseDetail: false
            };
        }

        case SetObservationActions.GET_COURSE_DETAILS_FAIL: {
            return {
                ...state,
                courseDetail: null,
                loadingCourseDetail: false
            };
        }

        case SetObservationActions.UPDATE_OBSERVATION_IMMEDIATE: {
            return {
                ...state,
                recordingImmediateObservation: true
            };
        }

        case SetObservationActions.UPDATE_OBSERVATION_IMMEDIATE_SUCCESS:
        case SetObservationActions.UPDATE_OBSERVATION_IMMEDIATE_FAIL: {
            return {
                ...state,
                recordingImmediateObservation: false
            };
        }

        case SetObservationActions.GET_ID_DOCUMENT_URLS: {
            return {
                ...state,
                idDocumentUrlsLoading: true,
                idDocumentUrls: null
            };
        }
        case SetObservationActions.GET_ID_DOCUMENT_URLS_FAIL: {
            return {
                ...state,
                idDocumentUrlsLoading: false,
                idDocumentUrls: null
            };
        }

        case SetObservationActions.GET_ID_DOCUMENT_URLS_SUCCESS: {
            return {
                ...state,
                idDocumentUrlsLoading: false,
                idDocumentUrls: action.payload
            };
        }
    }

    return state;
}


function checkConditionalItems(itemId: string, items: Item[], updating: boolean): void {
    if (items) {
        const conditionalItems = items.filter(i => i.conditions?.find(c => c.enabledOnItem.id === itemId));

        for (const conditionalItem of conditionalItems) {
            const initiallyEnabled = conditionalItem.enabled;
            conditionalItem.setEnabled(conditionalItem.conditionsMet(items));

            if (updating && conditionalItem.enabled !== initiallyEnabled) {
                conditionalItem.resetObservations();
            }
        }
    }
}