import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// modules
import { ConnectModule } from 'app/connect/connect.module';
import { SharedModule } from 'app/shared/shared.module';

// components
import { AddressInputComponent } from 'app/portal/modules/inputs/components/address-input/address-input.component';
import { FileInputComponent } from 'app/portal/modules/inputs/components/file-input/file-input.component';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { OptionInputComponent } from 'app/portal/modules/inputs/components/option-input/option-input.component';
import { PhotoInputComponent } from 'app/portal/modules/inputs/components/photo-input/photo-input.component';
import { CanvasInputComponent } from './components/canvas-input/canvas-input.component';

// services
import { AddressLookupService } from './services/address-lookup.service';
import { ImageCaptureService } from './services/image-capture.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { VideoCaptureService } from './services/video-capture.service';


const components = [
    AddressInputComponent,
    CanvasInputComponent,
    FileInputComponent,
    ImageCardComponent,
    OptionInputComponent,
    PhotoInputComponent
];

const services = [
    AddressLookupService,
    ImageCaptureService,
    VideoCaptureService
];

@NgModule({
    imports: [
        CommonModule,
        ConnectModule,
        SharedModule
    ],
    declarations: [
        ...components
    ],
    providers: [
        ...services,
        NgxImageCompressService
    ],
    exports: [
        ...components
    ]
})
export class InputsModule { }
