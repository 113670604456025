import { Component, Input, EventEmitter, Output, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'app/shared/base/base-component';
import { ActivatedRoute } from '@angular/router';

// ngrx | rxjs
import { take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// Models
import { AssessmentProfileSetResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-set-response.model';
import { AssessmentProfileItem } from 'app/shared/modules/set-observations/models/responses/assessment-profile-item-response.model';
import { AssessmentProfileObservation } from 'app/shared/modules/set-observations/models/responses/assessment-profile-observation-response.model';
import { ItemType } from 'app/shared/enums/item-type.enum';
import { Base64Blob, getBase64Image } from 'app/shared/models/base64-blob.model';
import { IdDocumentUrl } from 'app/shared/modules/set-observations/models/responses/id-document-url.model';

// Enums
import { VerifyIdentityStage } from 'app/shared/modules/set-observations/enums/verify-identity-stage.enum';

// Utilities
import { toAlphaNumericUnderline } from 'app/shared/utilities/string-utilities';
import { showHideAnimation } from 'app/shared/utilities/animation-utilities';

// Constants
import { rightToWorkAssessmentSetId } from 'app/shared/modules/set-observations/constants/assessment-set-constants';

// Services
import { FileSaverService } from 'ngx-filesaver';
import { SetObservationService } from 'app/shared/modules/set-observations/services/set-observation.service';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// Store
import * as fromRoot from 'app/store';
import * as fromSetsStore from 'app/shared/modules/set-observations/store';

@Component({
    selector: 'app-assessment-profile-assessment-set',
    templateUrl: 'assessment-profile-assessment-set.component.html',
    styleUrls: ['assessment-profile-assessment-set.component.scss'],
    animations: showHideAnimation,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentProfileAssessmentSetComponent extends BaseComponent implements OnInit {

    @Input()
    readonly: boolean = false;

    @Input()
    initiallyExpanded: boolean = false;

    @Input()
    assessmentProfileSet: AssessmentProfileSetResponse;

    @Input()
    showOpenSessionLink: boolean = false;

    @Output()
    showAssessmentSet: EventEmitter<AssessmentProfileSetResponse> = new EventEmitter();

    ItemType = ItemType;
    aquaSets: any;
    alive: boolean = true;
    stage = VerifyIdentityStage.Initial;
    stages = VerifyIdentityStage;
    idDocumentUrls: IdDocumentUrl[];
    idDocumentUrlsLoading$: Observable<boolean>;
    isClientUser: boolean;

    constructor(
        private fileSaverService: FileSaverService,
        private setObservationService: SetObservationService,
        private authTokenService: AuthenticationTokenService,
        private changeDetector: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private store: Store<fromRoot.State>,
        private setsStore: Store<fromSetsStore.SetObservationsState>) {
        super();

        this.activatedRoute.queryParams.subscribe(params => {
            if (params.result) {
                this.stage = parseInt(params.result, 10);
            }
        });
    }

    ngOnInit(): void {
        this.assessmentProfileSet = { ...this.assessmentProfileSet, expanded: this.initiallyExpanded };
        this.isClientUser = this.authTokenService.isClient();

        this.store.pipe(
            take(1),
            select(fromRoot.getRTWShown))
            .subscribe((hasBeenShown: boolean) => {
                if (this.assessmentProfileSet.id === rightToWorkAssessmentSetId && !hasBeenShown) {
                    if ((this.stage === VerifyIdentityStage.Success || this.stage === VerifyIdentityStage.Cancelled) && this.alive) {
                        this.alive = false;
                    }
                }
            });

        if (this.assessmentProfileSet.items.some(x => x.type === ItemType.IdDocuments) && this.isClientUser) {
            this.idDocumentUrlsLoading$ = this.setsStore.select(fromSetsStore.getIdDocumentUrlsLoading);
            this.store.dispatch(new fromSetsStore.GetIdDocumentUrls(this.assessmentProfileSet.ownerId));
            this.store.pipe(
                takeUntil(this.ngUnsubscribe),
                select(fromSetsStore.getIdDocumentUrls))
                .subscribe((urls: IdDocumentUrl[]) => {
                    if (urls) {
                        this.idDocumentUrls = urls;
                    }
                });
        }
    }

    toggleExpanded() {
        this.assessmentProfileSet.expanded = !this.assessmentProfileSet.expanded;
    }

    onShowAssessmentSet() {
        this.showAssessmentSet.emit(this.assessmentProfileSet);
    }

    downloadFile($event, item: AssessmentProfileItem, observation: AssessmentProfileObservation): void {
        $event.preventDefault();
        const fileName = encodeURI(this.getDownloadFileName(item, observation));
        this.setObservationService.getDocument(observation.id).subscribe((blob: Blob) => {
            this.fileSaverService.save(blob, fileName);
        });
    }

    getObservationFileBase64(observation: AssessmentProfileObservation): void {
        this.setObservationService.getDocumentBase64(observation.id).subscribe((base64Blob: Base64Blob) => {
            observation.base64Image = getBase64Image(base64Blob);

            // Force the change detection to fire to show the image.
            this.changeDetector.markForCheck();
        });
    }

    hasImage(observation: AssessmentProfileObservation): boolean {
        if (!observation.base64Image) {
            observation.base64Image = 'pending';
            this.getObservationFileBase64(observation);
            return false;
        }

        return observation.base64Image !== 'pending';
    }

    getCourseTitle(observation: AssessmentProfileObservation): string {
        if (!observation || !observation.hasValue) {
            return null;
        }

        const course = JSON.parse(observation.value);
        return course.frameworkTitle;
    }

    private getDownloadFileName(item: AssessmentProfileItem, observation: AssessmentProfileObservation): string {
        switch (item.type) {
            case ItemType.ESignRichText:
            case ItemType.ESignDocument:
                return `${toAlphaNumericUnderline(item.name)}-${toAlphaNumericUnderline(observation.value)}.pdf`;
            default:
                return observation.value;
        }
    }
}