import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// components
import { MobilePhotoCaptureComponent } from './components/mobile-photo-capture/mobile-photo-capture.component';

const routes: Routes = [
    {
        path: 'set/:setId/item/:itemId',
        component: MobilePhotoCaptureComponent,
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MobilePhotoRoutingModule { }
