<div fxLayout="column" fxFlex id="badge-profile">
    <app-loading-card *ngIf="(badgesLoading$ | async)"></app-loading-card>
    <mat-card *ngIf="!(badgesLoading$ | async) && badge">
        <app-profile-status
            [profileCompletePercentage]="badge.percentComplete"
            [label]="label"
            [icon]="iconSource"
            [readonly]="readonly"
            (completeNowClicked)="handleCompleteNowClicked()">
        </app-profile-status>

        <app-profile-question-sets
            [categories]="badge.categories"
            [readonly]="readonly"
            [initiallyExpanded]="false"
            (showAssessmentSet)="onShowAssessmentSet($event)">
        </app-profile-question-sets>
    </mat-card>
</div>
