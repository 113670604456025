<div *ngIf="visible" class="field" (mouseover)="handleMouseOver()" [ngClass]="{'theme-background-8': selected, 'theme-hover-8': withHover()}">
    <div>
        <div class="label" *ngIf="showLabel" fxFlex fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
            <div *ngIf="description" class="item-information" fxLayoutAlign="start start">
                <app-dialog-field-information [description]="description" [title]="label"></app-dialog-field-information>
            </div>
            <div *ngIf="label">
                <div>{{label}}<span *ngIf="mandatory">&nbsp;*</span></div>
                <div class="readonly-label" *ngIf="readonly"><span>&nbsp;(Read only)</span></div>
            </div>
        </div>
    </div>
    <div>
        <ng-content></ng-content>
    </div>
</div>