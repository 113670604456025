import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// store
import { effects } from 'app/shared/modules/identity-verification/store/effects';
import { reducers } from 'app/shared/modules/identity-verification/store/reducers';

// modules
import { SharedModule } from 'app/shared/shared.module';
import { ConnectModule } from 'app/connect/connect.module';

// components
import { IdentityVerificationComponent } from './components/identity-verification/identity-verification.component';
import { SelfieStepComponent } from './components/selfie-step/selfie-step.component';
import { UapStepComponent } from './components/uap-step/uap-step.component';
import { IdDocumentStepComponent } from './components/id-document-step/id-document-step.component';
import { CompleteStepComponent } from './components/complete-step/complete-step.component';
import { TakePhotoDialogComponent } from './components/take-photo-dialog/take-photo-dialog.component';
import { ConfirmationStepComponent } from './components/confirmation-step/confirmation-step.component';
import { DocumentRejectedStepComponent } from './components/document-rejected-step/document-rejected-step.component';

// services
import { IdentityVerificationService } from './services/identity-verification.service';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        ConnectModule,
        StoreModule.forFeature(StoreNames.identityVerification, reducers),
        EffectsModule.forFeature(effects)
    ],
    declarations: [
        IdentityVerificationComponent,
        SelfieStepComponent,
        UapStepComponent,
        IdDocumentStepComponent,
        CompleteStepComponent,
        ConfirmationStepComponent,
        TakePhotoDialogComponent,
        DocumentRejectedStepComponent
    ],
    providers: [
        IdentityVerificationService
    ],
    exports: [
        IdentityVerificationComponent,
        SelfieStepComponent,
        UapStepComponent
    ],
    entryComponents: [
        TakePhotoDialogComponent
    ]
})
export class IdentityVerificationModule { }