import * as fromActions from 'app/portal/modules/document-management/store/actions';
import { Template } from 'app/portal/modules/document-management/models';

export interface DocumentListState {
    documents: Template[];
    previewDocument: string;
    loading: boolean;
    saving: boolean;
    gettingMarkup: boolean;
    html: string;
    sharingTemplateEdit: Template;
    pdf: Blob;
}

const initialState: DocumentListState = {
    documents: null,
    previewDocument: null,
    loading: false,
    saving: false,
    gettingMarkup: false,
    html: null,
    sharingTemplateEdit: null,
    pdf: null
};

export function reducer(state = initialState, action: fromActions.AllDocumentsActions): DocumentListState {
    switch (action.type) {
        case fromActions.GET_DOCUMENTS:
            return {
                ...state,
                documents: null,
                loading: true
            };
        case fromActions.GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                documents: action.payload,
                loading: false
            };
        case fromActions.GET_DOCUMENTS_FAIL:
            return {
                ...state,
                documents: null,
                loading: false
            };

        case fromActions.DELETE_DOCUMENT:
            return {
                ...state,
                loading: true
            };
        case fromActions.DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                documents: state.documents.filter(d => d.id !== action.payload),
                loading: false
            };
        case fromActions.DELETE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false
            };
        case fromActions.GET_HTML_AS_PDF:
            return {
                ...state,
                loading: true,
                previewDocument: null
            };
        case fromActions.GET_HTML_AS_PDF_SUCCESS:
            return {
                ...state,
                loading: false,
                previewDocument: action.payload
            };
        case fromActions.GET_HTML_AS_PDF_FAIL:
            return {
                ...state,
                loading: false,
                previewDocument: null
            };
        case fromActions.ADD_DOCUMENT:
            return {
                ...state,
                saving: true
            };
        case fromActions.UPDATE_DOCUMENT:
            return {
                ...state,
                saving: true
            };
        case fromActions.SAVE_DOCUMENT_SUCCESS:
            return {
                ...state,
                html: null,
                saving: false
            };
        case fromActions.SAVE_DOCUMENT_FAIL:
            return {
                ...state,
                saving: false
            };
        case fromActions.GET_EXISTING_MARKUP:
            return {
                ...state,
                gettingMarkup: true,
                html: null
            };
        case fromActions.GET_MARKUP_FOR_FILE:
                return {
                    ...state,
                    gettingMarkup: true,
                    html: null
                };
        case fromActions.GET_EXISTING_MARKUP_SUCCESS:
            return {
                ...state,
                gettingMarkup: false,
                html: action.payload
            };
        case fromActions.GET_EXISTING_MARKUP_FAIL:
            return {
                ...state,
                gettingMarkup: false,
                html: null
            };
        case fromActions.CLEAR_STATE:
            return {
                ...state,
                html: null,
                pdf: null
            };
        case fromActions.SET_SHARING_DOCUMENT_EDIT: {
            return {
                ...state,
                sharingTemplateEdit: action.payload
            };
        }
        case fromActions.GET_PDF_DOCUMENT: {
            return {
                ...state,
                gettingMarkup: true,
                pdf: null
            };
        }
        case fromActions.GET_PDF_DOCUMENT_SUCCESS: {
            return {
                ...state,
                gettingMarkup: false,
                pdf: action.payload
            };
        }
        case fromActions.GET_PDF_DOCUMENT_FAIL: {
            return {
                ...state,
                gettingMarkup: false,
                pdf: null
            };
        }
    }

    return state;
}