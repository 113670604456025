import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Models
import { UserActivityRequest } from 'app/shared/models/user-activity-request.model';
import { UserActivityDetails } from 'app/shared/models/user-activity-details.model';

// Services
import { AuthenticationTokenService } from './authentication-token.service';

// Enums
import { isAuthenticationActivity } from 'app/shared/enums/user-activity-type.enum';

@Injectable()
export class UserActivityService {

    private baseUrl: string = 'api/user-activity';

    constructor(
        private http: HttpClient,
        private authenticationTokenService: AuthenticationTokenService) { }

    getOrganisationActivity(organisationId: string): Observable<UserActivityDetails[]> {
        const url = `${this.baseUrl}/organisation/${organisationId}`;

        return this.http.get<UserActivityDetails[]>(url);
    }

    getUserActivity(userId: string): Observable<UserActivityDetails[]> {
        const url = `${this.baseUrl}/user/${userId}`;

        return this.http.get<UserActivityDetails[]>(url);
    }

    logUserActivity(request: UserActivityRequest): Observable<void> {
        const url = `${this.baseUrl}/log-activity`;

        request.userId = this.authenticationTokenService.userId();
        request.organisationId = isAuthenticationActivity(request.activityType) ? null : this.authenticationTokenService.clientId();

        return this.http.post<void>(url, request);
    }
}
