import { createFeatureSelector, createSelector } from '@ngrx/store';

// Store
import * as fromFeature from 'app/shared/modules/group/store/reducers';
import * as fromTags from 'app/shared/modules/group/store/reducers/organisation-management-tags.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getGroupsState = createFeatureSelector<fromFeature.GroupsState>(StoreNames.groups);
const getOrganisationManagementTagsState = createSelector(getGroupsState, (state: fromFeature.GroupsState) => state.organisationTagManagementState);

export const getOrganisationTagsLoading = createSelector(getOrganisationManagementTagsState, (state: fromTags.OrganisationManagementTagsState) => state.loading);
export const getOrganisationTags = createSelector(getOrganisationManagementTagsState, (state: fromTags.OrganisationManagementTagsState) => state.tags);