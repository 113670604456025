// Store
import * as UserActivityActions from 'app/store/actions/user-activity.actions';

// Models
import { UserActivityDetails } from 'app/shared/models/user-activity-details.model';

export type Action = UserActivityActions.AllUserActivityActions;

export interface UserActivityState {
    userActivityLoading: boolean;
    userActivityLoaded: boolean;
    userActivityRecords: UserActivityDetails[];
}

const initialState: UserActivityState = {
    userActivityLoading: false,
    userActivityLoaded: false,
    userActivityRecords: null
};

export function reducer(state = initialState, action: Action): UserActivityState {
    switch (action.type) {
        case UserActivityActions.GET_ORGANISATION_USER_ACTIVITY:
        case UserActivityActions.GET_USER_ACTIVITY:
            return {
                ...state,
                userActivityLoading: true,
                userActivityLoaded: false,
                userActivityRecords: null
            };

        case UserActivityActions.GET_ACTIVITY_SUCCESS:
            return {
                ...state,
                userActivityLoading: false,
                userActivityLoaded: true,
                userActivityRecords: action.payload
            };

        case UserActivityActions.GET_ACTIVITY_FAIL:
            return {
                ...state,
                userActivityLoading: false,
                userActivityLoaded: false,
                userActivityRecords: null
            };

        default:
            return state;
    }
}