import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// ngrx || rxjs
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

// store
import * as fromMobilePhotoStore from 'app/shared/modules/mobile-photo/store';
import * as fromSetsStore from 'app/shared/modules/set-observations/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { QrCodeData } from 'app/shared/modules/mobile-photo/models/qr-code-data.model';

@Component({
    templateUrl: './qr-code-dialog.component.html',
    styleUrls: ['./qr-code-dialog.component.scss']
})
export class QrCodeDialogComponent extends BaseComponent implements OnInit {
    title: string;
    data: QrCodeData;
    qrCodeUrl: string;
    token: string;

    loadingToken$: Observable<boolean>;
    polling$: Observable<boolean>;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<QrCodeDialogComponent>,
        private store: Store<fromMobilePhotoStore.MobilePhotoState>,
        private actionsSubject: ActionsSubject) {
        super();
        this.data = data;
    }

    ngOnInit(): void {
        this.title = 'Switch to Mobile';

        this.loadingToken$ = this.store.select(fromMobilePhotoStore.getLoadingToken);
        this.polling$ = this.store.select(fromMobilePhotoStore.getPolling);

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromMobilePhotoStore.getMobileToken))
            .subscribe((token: string) => {
                if (token) {
                    this.token = token;
                    this.generateUrl();
                    this.startPolling();
                }
            });

        this.actionsSubject.pipe(
            ofType(fromMobilePhotoStore.MobileTokenUsed),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.store.dispatch(new fromSetsStore.ReloadSetRequired(this.data.setId));
                this.dialogRef.close();
            });

        this.store.dispatch(new fromSetsStore.SaveSetBeforeSwitchToMobile(this.data.setId));
        this.store.dispatch(fromMobilePhotoStore.GetMobileToken({organisationId: this.data.organisationId}));
    }

    closeDialog(): void {
        this.store.dispatch(fromMobilePhotoStore.StopPolling());
        this.store.dispatch(fromMobilePhotoStore.ClearMobileToken({token: this.token}));
        this.dialogRef.close();
    }

    private startPolling(): void {
        this.store.dispatch(fromMobilePhotoStore.PollMobileToken({
                token: this.token,
                pollIntervalMs: 5000, // Poll every 5 seconds
                pollEndMs: 300000 // Stop polling after this time period (300000 = 5 mins)
            }));
    }

    private generateUrl(): void {
        const baseUrl = window.location.origin;
        this.qrCodeUrl = `${baseUrl}/mobile-photo/set/${this.data.setId}/item/${this.data.itemId}?token=${this.token}&itemType=${this.data.itemType}`;
        if (this.data.idDocumentType) {
            this.qrCodeUrl += `&idDocumentType=${this.data.idDocumentType}`;
        }
        if (this.data.observationId) {
            this.qrCodeUrl += `&observationId=${this.data.observationId}`;
        }
        if (this.data.stepId) {
            this.qrCodeUrl += `&step=${this.data.stepId}`;
        }
    }
}
