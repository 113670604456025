import { Component, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

// utilities
import { isNullOrWhitespace } from 'app/shared/utilities/string-utilities';

// services
import { AddressLookupService } from 'app/shared/services/address-lookup.service';

// models
import { AddressResult } from 'app/shared/models/address-result.model';
import { PostcodeLookupResult } from 'app/shared/models/postcode-lookup-result.model';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-postcode',
    templateUrl: './postcode-observation.component.html',
    styleUrls: ['./postcode-observation.component.scss'],
    outputs: ['valueUpdated', 'selectedChanged']
})
export class PostcodeObservationComponent extends ObservationComponent {
    public hasAddresses: boolean = false;
    public showAddress: boolean = false;
    public addresses: AddressResult[];
    public formattedAddresses: string[];
    public addressForm: FormGroup;
    public lookupError: string = '';

    @ViewChild('postcode') private postcode: ElementRef;

    constructor(
        private addressLookupService: AddressLookupService,
        private fb: FormBuilder) {
        super();
    }

    init(): void {
        this.addressForm = this.fb.group({
            addressLine1: [null, [Validators.required]],
            addressLine2: [null],
            town: [null, [Validators.required]],
            county: [null],
            postcode: [null, [
                Validators.required,
                Validators.pattern(`^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\\s?[0-9][A-Za-z]{2}))$`)
            ]]
        });

        if (this.itemObservation && this.itemObservation.value) {
            const address: AddressResult = JSON.parse(this.itemObservation.value);

            this.addressForm.patchValue(address);
            this.showAddress = true;
        }

        this.addressForm.valueChanges.subscribe(f => {
            const address: AddressResult = f;

            if (this.hasAddress(address)) {
                this.form.get('observation').patchValue(address);
                this.updateValue(JSON.stringify(address));
            } else {
                this.updateValue(null);
            }
        });
    }

    settingPostcode(): void {
        this.showAddress = false;
    }

    lookupAddress(): void {
        const postcode = this.postcode.nativeElement.value;
        this.addressLookupService
            .postCodeLookup(postcode)
            .subscribe((addressResult: PostcodeLookupResult) => {
                if (addressResult.isSuccess) {
                    if (addressResult.addresses.length === 1) {
                        this.addressForm.patchValue(addressResult.addresses[0]);
                        this.showAddress = true;
                        this.hasAddresses = false;
                    } else {
                        this.hasAddresses = true;
                        this.addresses = addressResult.addresses;
                    }
                    this.lookupError = '';
                } else {
                    this.lookupError = addressResult.errorMessage;
                }
            });
    }

    formatAddress(address: AddressResult): string {
        let formattedAddress = address.addressLine1;

        if (!isNullOrWhitespace(address.addressLine2)) {
            formattedAddress = formattedAddress + ', ' + address.addressLine2;
        }

        if (!isNullOrWhitespace(address.town)) {
            formattedAddress = formattedAddress + ', ' + address.town;
        }

        if (!isNullOrWhitespace(address.county)) {
            formattedAddress = formattedAddress + ', ' + address.town;
        }

        formattedAddress = formattedAddress + ', ' + address.postcode;

        return formattedAddress;
    }

    toggleDataEntry(isManual: boolean) {
        this.showAddress = isManual;
        this.formattedAddresses = [];
        this.hasAddresses = false;
    }

    updateAddress(address: AddressResult): void {
        this.addressForm.patchValue(address);
        this.showAddress = true;
        this.hasAddresses = false;
    }

    clearAddress() {
        this.addressForm.reset();
    }

    hasAddress(address: AddressResult): boolean {
        return !(isNullOrWhitespace(address.addressLine1) &&
            isNullOrWhitespace(address.addressLine2) &&
            isNullOrWhitespace(address.town) &&
            isNullOrWhitespace(address.county) &&
            isNullOrWhitespace(address.postcode));
    }
}