export enum SignatureType {
    NoSigning = 1,
    SingleSign = 2,
    FullESign = 3
}

export function getSignatureTypeTitle(type: SignatureType): string {
    switch(type) {
        case SignatureType.FullESign: return 'Full Esign';
        case SignatureType.NoSigning: return 'No Signature';
        case SignatureType.SingleSign: return 'Single Signature';
        default: return null;
    }
}