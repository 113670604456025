import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-powered-by-credas',
    templateUrl: './powered-by-credas.component.html',
    styleUrls: ['./powered-by-credas.component.scss']
})
export class PoweredByCredasComponent {
    @Input()
    color = '#FFFFFF';
}
