import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';

// store
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

@Injectable()
export class AuthGuard implements CanActivateChild, CanActivate {

    constructor(
        private authenticationToken: AuthenticationTokenService) { }


    canActivateChild() {
        return this.canActivate();
    }

    canActivate() {
        if (this.authenticationToken.isLoggedIn()) {
            return true;
        }

        window.location.href = this.authenticationToken.getLoginUrl();
    }
}