import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFeature from 'app/portal/modules/document-management/store/reducers';
import * as fromDocumentList from 'app/portal/modules/document-management/store/reducers/document-list.reducer';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getDocumentsFeatureState = createFeatureSelector<fromFeature.DocumentsState>(StoreNames.documents);

const getDocumentListState = createSelector(getDocumentsFeatureState, (state: fromFeature.DocumentsState) => state.documentListState);
export const getDocuments = createSelector(getDocumentListState, (state: fromDocumentList.DocumentListState) => state.documents);
export const getLoading = createSelector(getDocumentListState, (state: fromDocumentList.DocumentListState) => state.loading);
export const getPreviewDocument = createSelector(getDocumentListState, (state: fromDocumentList.DocumentListState) => state.previewDocument);
export const getSaving = createSelector(getDocumentListState, (state: fromDocumentList.DocumentListState) => state.saving);
export const getHtml = createSelector(getDocumentListState, (state: fromDocumentList.DocumentListState) => state.html);
export const gettingMarkup = createSelector(getDocumentListState, (state: fromDocumentList.DocumentListState) => state.gettingMarkup);
export const getSharingTemplateEdit = createSelector(getDocumentListState, (state: fromDocumentList.DocumentListState) => state.sharingTemplateEdit);
export const getPdf = createSelector(getDocumentListState, (state: fromDocumentList.DocumentListState) => state.pdf);