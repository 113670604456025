import { Action } from '@ngrx/store';

// Models
import { UpdateGeneralInformationRequest } from 'app/shared/models/update-general-information-request.model';
import { RemoveBadgeRequest } from 'app/shared/models/remove-badge-request.model';

// Action types
export const UPDATE_GENERAL_INFORMATION = '[Organisations] Update general information';
export const UPDATE_GENERAL_INFORMATION_SUCCESS = '[Organisations] Update general information success';
export const UPDATE_GENERAL_INFORMATION_FAIL = '[Organisations] Update general information fail';

export const REMOVE_BADGE = '[Organisations] remove badge';
export const REMOVE_BADGE_SUCCESS = '[Organisations] remove badge success';
export const REMOVE_BADGE_FAIL = '[Organisations] remove badge fail';

// Action initialisers
export class UpdateGeneralInformation implements Action {
    readonly type = UPDATE_GENERAL_INFORMATION;
    constructor(public payload: UpdateGeneralInformationRequest) { }
}

export class UpdateGeneralInformationSuccess implements Action {
    readonly type = UPDATE_GENERAL_INFORMATION_SUCCESS;
}

export class UpdateGeneralInformationFail implements Action {
    readonly type = UPDATE_GENERAL_INFORMATION_FAIL;
}

export class RemoveBadge implements Action {
    readonly type = REMOVE_BADGE;
    constructor(public payload: RemoveBadgeRequest) { }
}

export class RemoveBadgeSuccess implements Action {
    readonly type = REMOVE_BADGE_SUCCESS;
}

export class RemoveBadgeFail implements Action {
    readonly type = REMOVE_BADGE_FAIL;
}

export type AllOrganisationActions
    = UpdateGeneralInformation
    | UpdateGeneralInformationSuccess
    | UpdateGeneralInformationFail
    | RemoveBadge
    | RemoveBadgeSuccess
    | RemoveBadgeFail;