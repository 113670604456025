<div class="circle-progress">
    <div class="process-progress-spinner"
        [style.width]="size + 'px'" [style.height]="size + 'px'"
        [innerHtml]="circleSvg | safe: 'html'">
        
    </div>
    <div class="circle-progress-text" [style.top]="(size - (lineWidth * 13 / 8))/2 + 'px'">
        <div class="circle-progress-percent text font-heading-3" [style.font-size]="((lineWidth * 9 / 8) + 3) + 'px'">
            {{progress}}%
        </div>
        <div *ngIf="lineWidth > 20" class="circle-progress-completed" [style.font-size]="(lineWidth / 2) + 'px'">
            Completed
        </div>
    </div>
</div>
