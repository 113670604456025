import { Component, OnInit, Inject } from '@angular/core';
import { BaseComponent } from 'app/shared/base/base-component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { ofType, Actions } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';

// models
import { ZohoExportDialogOptions } from 'app/shared/modules/zoho-export/models/zoho-export-dialog-options.model';
import { ExportFile } from 'app/shared/modules/zoho-export/models/export-file.model';

// enums
import { ExportStatus } from 'app/shared/modules/zoho-export/models/export-status.enum';

// store
import * as fromStore from 'app/shared/modules/zoho-export/store';

const MAX_ATTEMPT_COUNT = 3;
const CANCEL_LABEL = 'Cancel';
const CLOSE_LABEL = 'Close';

@Component({
    templateUrl: './zoho-export-dialog.component.html',
    styleUrls: ['./zoho-export-dialog.component.scss']
})
export class ZohoExportDialogComponent extends BaseComponent implements OnInit {
    private _files: ExportFile[] = [];
    private _dataSource: MatTableDataSource<ExportFile>;
    private _started = false;

    exportStatus = ExportStatus;
    displayedColumns = ['filename', 'status'];

    exportDisabled = true;
    cancelDisabled = false;

    cancelLabel = CANCEL_LABEL;

    hasFiles = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public options: ZohoExportDialogOptions,
        private dialogRef: MatDialogRef<ZohoExportDialogComponent>,
        private store: Store<void>,
        private actions$: Actions
    ) {
        super();

        this._files = this.options.fileContainer.files || [];
        this._dataSource = new MatTableDataSource(this._files);
        this.hasFiles = this._files.length > 0;

        this.updateButtons();
    }

    get dataSource(): MatTableDataSource<ExportFile> {
        return this._dataSource;
    }

    ngOnInit(): void {
        this.actions$.pipe(
            takeUntil(this.ngUnsubscribe),
            ofType(fromStore.EXPORT_FILE_SUCCESS))
            .subscribe((action: fromStore.ExportFileSuccess) => {
                this.exportFileSuccess(action.payload);
            });

        this.actions$.pipe(
            takeUntil(this.ngUnsubscribe),
            ofType(fromStore.EXPORT_FILE_FAIL))
            .subscribe((action: fromStore.ExportFileFail) => {
                this.exportFileFail(action.payload);
            });
    }

    cancelClick(): void {
        this.dialogRef.close();
    }

    exportClick(): void {
        this.store.dispatch(new fromStore.StartExport(this.options.entityId, this.options.processId));
        this.exportDisabled = true;
        this.enqueueAll();
        this._started = true;
        this.processNextQueuedFile();
    }

    getExportStatusText(value: ExportStatus): string {
        switch (value) {
            case ExportStatus.Cancelled:
                return 'CANCELLED';

            case ExportStatus.Failed:
                return 'ERROR';

            case ExportStatus.InProgress:
                return 'IN-PROGRESS';

            case ExportStatus.Ready:
                return 'READY';

            case ExportStatus.Success:
                return 'DONE';

            case ExportStatus.Queued:
                return 'QUEUED';

            default:
                return 'N/A';
        }
    }

    getExportStatusClass(value: ExportStatus): string {
        switch (value) {
            case ExportStatus.Failed:
                return 'status-red';

            case ExportStatus.InProgress:
            case ExportStatus.Cancelled:
                return 'status-amber';

            case ExportStatus.Success:
                return 'status-green';

            default:
                return '';
        }
    }

    private updateButtons(): void {
        if (!this.hasFiles) {
            this.cancelDisabled = false;
            this.exportDisabled = true;
            this.cancelLabel = CLOSE_LABEL;
        } else {
            if (!this._started) {
                this.cancelDisabled = false;
                this.exportDisabled = false;
                this.cancelLabel = CANCEL_LABEL;
            } else {
                this.exportDisabled = true;
                this.cancelLabel = CLOSE_LABEL;

                const anyPending = this._files.some(x =>
                    x.status === ExportStatus.InProgress ||
                    x.status === ExportStatus.Queued);

                this.cancelDisabled = anyPending;
            }
        }
    }

    private exportFileSuccess(observationId: Guid): void {
        const found = this._files.find(x => x.observationId === observationId);

        if (found) {
            found.status = ExportStatus.Success;
        }

        this.processNextQueuedFile();
    }

    private exportFileFail(observationId: Guid): void {
        const found = this._files.find(x => x.observationId === observationId);

        if (found) {
            found.status = found.attemptCount >= MAX_ATTEMPT_COUNT ?
                ExportStatus.Cancelled :
                ExportStatus.Failed;
        }

        this.finaliseExport();
    }

    private processNextQueuedFile() {
        const files = this._files;

        const firstQueued = files.find(f => f.status === ExportStatus.Queued);

        if (firstQueued) {
            firstQueued.status = ExportStatus.InProgress;
            firstQueued.attemptCount++;

            this.store.dispatch(new fromStore.ExportFile({
                filename: firstQueued.filename,
                observationId: firstQueued.observationId,
                itemType: firstQueued.itemType,
                entityId: firstQueued.entityId,
                entityName: firstQueued.entityName,
                clientId: firstQueued.clientId,
                clientName: firstQueued.clientName,
                processId: firstQueued.processId,
                processName: firstQueued.processName
            }));
        } else {
            this.finaliseExport();
        }

        this.updateButtons();
    }

    private finaliseExport(): void {
        // Process is finished - check if all files were successful
        if (this._files.filter(f => f.status === ExportStatus.Failed).length > 0) {
            this.store.dispatch(new fromStore.MarkExportFailed(this.options.entityId, this.options.processId));
        } else {
            this.store.dispatch(new fromStore.MarkExportSuccessful(this.options.entityId, this.options.processId));
        }

        this.cancelDisabled = false;
    }

    private enqueueAll() {
        const files = this._files;

        files.forEach(f => {
            f.status = ExportStatus.Queued;
        });
    }
}