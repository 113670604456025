import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExportFileDetails } from 'app/shared/modules/zoho-export/models/export-file-details.model';

@Injectable()
export class ZohoExportApiService {
    constructor(private http: HttpClient) { }

    startExport(entityId: string, processId: string): Observable<void> {
        const url = `api/export/${entityId}/processes/${processId}/start-export`;
        return this.http.put<void>(url, null);
    }

    exportFile(fileDetails: ExportFileDetails): Observable<any> {
        const url = 'api/export/file-to-zoho';
        return this.http
            .post<any>(url, fileDetails);
    }

    markExportSuccess(entityId: string, processId: string): Observable<void> {
        const url = `api/export/${entityId}/processes/${processId}/success`;
        return this.http.put<void>(url, null);
    }

    markExportFail(entityId: string, processId: string): Observable<void> {
        const url = `api/export/${entityId}/processes/${processId}/fail`;
        return this.http.put<void>(url, null);
    }
}
