import { Component, Input, ViewEncapsulation, EventEmitter, Output, OnInit } from '@angular/core';

// Models
import { BadgeSummary } from 'app/shared/modules/group/models/badge-summary.model';

@Component({
    selector: 'app-badge-card',
    templateUrl: './badge-card.component.html',
    styleUrls: ['badge-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BadgeCardComponent implements OnInit {
    @Input()
    iconSource: string;

    @Input()
    badge: BadgeSummary;

    @Input()
    isLocked: boolean;

    @Output()
    enabledChanged: EventEmitter<boolean> = new EventEmitter();

    onEnabledChanged(enabled: boolean): void {
        this.enabledChanged.emit(enabled);
    }

    ngOnInit(): void {

    }
}