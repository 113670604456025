import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, CanActivateChild } from '@angular/router';

// ngrx | rxjs
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, delay, filter, map, take } from 'rxjs/operators';

// store
import * as fromConnect from 'app/connect/store';

// enums
import { Modules } from 'app/shared/enums';

// Services
import { ModuleService } from 'app/shared/services/module.service';

@Injectable()
export class ModuleGuard implements CanActivateChild, CanActivate {

    constructor(
        private store: Store<fromConnect.ConnectStoreState>,
        private router: Router) { }


    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.canActivate(route);
    }

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

        const requiredModule = <Modules>route.data.module;
        if (!requiredModule) {
            return of(true);
        }

        return this.waitOnModules().pipe(map(() => {
                const canNavigate = ModuleService.isEnabled(requiredModule, true);

                if (!canNavigate) {
                    this.router.navigate(['/not-found']);
                }

                return canNavigate;
            }),
            catchError(() => {
                this.router.navigate(['/not-found']);
                return of(false);
            }));
    }

    waitOnModules(): Observable<any> {
        return this.store.pipe(
            select(fromConnect.getUser),
            filter((data: any) => data?.id), // waits here until we have loaded a user (and the modules)
            delay(10), // This is necessary due to a race condition between the effect and reducer consuming SetUser
                       // The delay makes sure the modules service is populated before running the canActivate check
            take(1));
    }
}
