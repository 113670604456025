import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/store/reducers';
import * as fromUserActivity from 'app/store/reducers/user-activity.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getState = createFeatureSelector<fromFeature.State>(StoreNames.shared);
const getUserActivityState = createSelector(getState, (state: fromFeature.State) => state.userActivity);

export const getUserActivityLoading = createSelector(getUserActivityState, (state: fromUserActivity.UserActivityState) => state.userActivityLoading);
export const getUserActivityLoaded = createSelector(getUserActivityState, (state: fromUserActivity.UserActivityState) => state.userActivityLoaded);
export const getUserActivityRecords = createSelector(getUserActivityState, (state: fromUserActivity.UserActivityState) => state.userActivityRecords);