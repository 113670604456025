// ngrx
import { createReducer, on, Action } from '@ngrx/store';

// store
import * as actions from 'app/shared/modules/mobile-photo/store/actions/mobile-photo.actions';

// models
import { UapImage } from 'app/shared/modules/set-observations/models/responses/uap-image.model';

export interface MobilePhotoState {
    savingImage: boolean;
    loadingToken: boolean;
    polling: boolean;
    token: string;
    uapImage: UapImage;
    loadingUapImage: boolean;
}

const initialState: MobilePhotoState = {
    savingImage: false,
    loadingToken: false,
    polling: false,
    token: null,
    uapImage: null,
    loadingUapImage: false
};

const mobilePhotoReducer = createReducer(
    initialState,
    on(actions.GetMobileToken, (state) => ({
        ...state,
        loadingToken: true,
        token: null
    })),
    on(actions.GetMobileTokenSuccess, (state, action) => ({
        ...state,
        loadingToken: false,
        token: action.token
    })),
    on(actions.GetMobileTokenFail, (state) => ({
        ...state,
        loadingToken: false,
        token: null
    })),
    on(actions.PollMobileToken, (state) => ({
        ...state,
        polling: true
    })),
    on(actions.MobileTokenUsed, (state) => ({
        ...state,
        token: null,
        polling: false
    })),
    on(actions.PollMobileTokenFail, (state) => ({
        ...state,
        polling: false
    })),
    on(actions.StopPolling, (state) => ({
        ...state,
        polling: false,
    })),
    on(actions.UploadImage, (state) => ({
        ...state,
        savingImage: true
    })),
    on(actions.UploadImageSuccess, (state) => ({
        ...state,
        savingImage: false
    })),
    on(actions.UploadImageFail, (state) => ({
        ...state,
        savingImage: false
    })),
    on(actions.GetUapImage, (state) => ({
        ...state,
        uapImage: null,
        loadingUapImage: true
    })),
    on(actions.GetUapImageSuccess, (state, action) => ({
        ...state,
        uapImage: action.uapImage,
        loadingUapImage: false
    })),
    on(actions.GetUapImageFail, (state) => ({
        ...state,
        loadingUapImage: false
    })),
    on(actions.ClearMobileToken, (state) => ({
        ...state,
        token: null,
        polling: false
    })),
    on(actions.ClearMobileTokenSuccess, (state) => ({
        ...state,
        token: null
    })),
    on(actions.ClearMobileTokenFail, (state) => ({
        ...state,
        token: null
    })),
);

export function reducer(state: MobilePhotoState | undefined, action: Action) {
    return mobilePhotoReducer(state, action);
}
