import { Action } from '@ngrx/store';
import { Tag } from 'app/shared/models/entities/tag.model';
import { AddOrganisationTagRequest } from 'app/shared/models/add-organisation-tag-request.model';
import { DeleteOrganisationTagRequest } from 'app/shared/models/remove-organisation-tag-request';

export const GET_AVAILABLE_TAGS = '[OrganisationTag] Get available tags';
export const GET_AVAILABLE_TAGS_FAIL = '[OrganisationTag] Get available tags failure';
export const GET_AVAILABLE_TAGS_SUCCESS = '[OrganisationTag] Get available tags success';
export const ADD_ORGANISATION_TAG = '[OrganisationTag] Add organisation tags';
export const ADD_ORGANISATION_TAG_FAIL = '[OrganisationTag] Add organisation tag failure';
export const ADD_ORGANISATION_TAG_SUCCESS = '[OrganisationTag] Add organisation tag success';
export const DELETE_ORGANISATION_TAG = '[OrganisationTag] Delete organisation tags';
export const DELETE_ORGANISATION_TAG_FAIL = '[OrganisationTag] Delete organisation tag failure';
export const DELETE_ORGANISATION_TAG_SUCCESS = '[OrganisationTag] Delete organisation tag success';

export class GetAvailableTags implements Action {
    readonly type = GET_AVAILABLE_TAGS;
    constructor(public payload: string) {
    }
}

export class GetAvailableTagsSuccess implements Action {
    readonly type = GET_AVAILABLE_TAGS_SUCCESS;
    constructor(public payload: Tag[]) {
    }
}

export class GetAvailableTagsFail implements Action {
    readonly type = GET_AVAILABLE_TAGS_FAIL;
}

export class AddOrganisationTag implements Action {
    readonly type = ADD_ORGANISATION_TAG;
    constructor(public payload: AddOrganisationTagRequest) {
    }
}

export class AddOrganisationTagSuccess implements Action {
    readonly type = ADD_ORGANISATION_TAG_SUCCESS;
}

export class AddOrganisationTagFail implements Action {
    readonly type = ADD_ORGANISATION_TAG_FAIL;
}

export class DeleteOrganisationTag implements Action {
    readonly type = DELETE_ORGANISATION_TAG;
    constructor(public payload: DeleteOrganisationTagRequest) { }
}

export class DeleteOrganisationTagSuccess implements Action {
    readonly type = DELETE_ORGANISATION_TAG_SUCCESS;
}

export class DeleteOrganisationTagFail implements Action {
    readonly type = DELETE_ORGANISATION_TAG_FAIL;
}

export type AllOrganisationTagActions
    = GetAvailableTags
    | GetAvailableTagsFail
    | GetAvailableTagsSuccess
    | AddOrganisationTag
    | AddOrganisationTagFail
    | AddOrganisationTagSuccess;