import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// Components
import { LegalPolicyDialog } from 'app/shared/enums/legal-policy-dialog.enum';

@Component({
    templateUrl: './usage-dialog.component.html'
})
export class UsageDialogComponent {
    constructor(public dialogRef: MatDialogRef<UsageDialogComponent>) { }

    openPrivacy() {
        this.dialogRef.close(LegalPolicyDialog.PrivacyPolicy);
    }

    openTerms() {
        this.dialogRef.close(LegalPolicyDialog.TermsAndConditions);
    }
}
