<app-dialog
    [dialogTitle]="title"
    id="assessment-set-dialog"
    [description]="observationDescription"
    defaultWidth="80vw"
    defaultHeight="90vh"
    [showFooterActions]="true"
    [handleCloseEvent]="!(saving$ | async)"
    (Close)="onCloseClicked()">

    <div sub-header class="entity-name accent-bg">{{entityName}}</div>
    
    <div body fxFlex fxLayout="column">
        <div #top></div>
        <div class="inner-content" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="space-between">
            <app-set-observations
                #setObservations
                [setId]="thirdPartySetId"
                [ownerId]="currentSet.ownerId"
                (formStatusChanged)="onFormStatusChanged($event)">
            </app-set-observations>

            <app-paging-navigation
                #paging
                [disabled]="recordingImmediateObservation$ | async"
                [hidden]="!observationsLoaded || (saving$ | async)"
                [inWizard]="inWizard"
                [handleBackAndNext]="true"
                [showSaveButton]="anyItemsChanged"
                numberOfPagingButtons="20"
                (saveClicked)="handleSaveClicked()"
                (backClicked)="handleBackClicked()"
                (nextClicked)="handleNextClicked()"
                (pageChanged)="handlePageChanged($event)">
            </app-paging-navigation>
        </div>
        <div *ngIf="(saving$ | async)">
            <app-loading-card></app-loading-card>
        </div>

        
    </div>
</app-dialog>