import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './information-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationDialogComponent {
    informationMessage: string;
    dialogTitle: string;
    dialogWidth: string;
    buttonLabel: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<InformationDialogComponent>) {
        this.informationMessage = data.informationMessage;
        this.dialogTitle = data.dialogTitle !== '' ? data.dialogTitle : 'Information';
        this.dialogWidth = data.dialogWidth !== '' ? data.dialogWidth : '400px';
        this.buttonLabel = data.buttonLabel ?? 'OK';
     }

    confirmed() {
        this.dialogRef.close(true);
    }

    cancelled() {
        this.dialogRef.close();
    }
}
