import { newId } from 'app/shared/utilities/string-utilities';

export class ObservationResponse {
    id: string;
    ownerId: string;
    groupId: string;
    value: string;
    score: number;
    downloadLink: string;
    metaData: any;

    constructor(ownerId: string) {
        this.id = newId();
        this.ownerId = ownerId;
        this.groupId = ownerId;
        this.score = 0;
    }
}