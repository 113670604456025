import { Injectable } from '@angular/core';

// ngrx / rxjs
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, catchError, tap, switchMap, mergeMap } from 'rxjs/operators';

// Store
import * as TermsOverviewActions from 'app/shared/modules/terms-obsolete/store/actions/terms-overview.actions';

// Services
import { TermsService } from 'app/shared/modules/terms-obsolete/services/terms.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// Models
import { GetTermsOverviewRequest } from 'app/shared/modules/terms-obsolete/models/requests/get-terms-overview-request.model';
import { AcceptPendingTermRequest } from 'app/shared/modules/terms-obsolete/models/requests/accept-term-request.model';
import { TermsOverview } from 'app/shared/modules/terms-obsolete/models/responses/terms-overview.model';
import { TermsOverviewType } from 'app/shared/modules/terms-obsolete/enums/terms-overview-type.enum';
import { TermsOverviewItem } from 'app/shared/modules/terms-obsolete/models/responses/terms-overview-item.model';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

@Injectable()
export class TermsOverviewEffects {


    acceptTerms$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsOverviewActions.ACCEPT_PENDING_TERMS),
        switchMap(() =>
            this.termsService.acceptPendingTerms().pipe(
                map(() => new TermsOverviewActions.AcceptPendingTermsSuccess()),
                catchError(() => of(new TermsOverviewActions.AcceptPendingTermsFail()))
            ))
    ));


    acceptTermsSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsOverviewActions.ACCEPT_PENDING_TERMS_SUCCESS),
        tap(() => {
            this.alertService.success('All terms & conditions accepted');
        })
    ), { dispatch: false });


    getTermsOverview$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsOverviewActions.GET_TERMS_OVERVIEW),
        map((action: TermsOverviewActions.GetTermsOverview) => action.payload),
        switchMap((request: GetTermsOverviewRequest) => this.termsService.getTermsOverview(request).pipe(
                map((termsOverview: TermsOverview) => new TermsOverviewActions.GetTermsOverviewSuccess(termsOverview)),
                catchError(() => of(new TermsOverviewActions.GetTermsOverviewFail()))))));


    getTermsOverviewAdmin$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsOverviewActions.GET_TERMS_OVERVIEW_ADMIN),
        map((action: TermsOverviewActions.GetTermsOverviewAdmin) => action.payload),
        switchMap((organisationId: string) =>
            this.termsService.getTermsOverviewAdmin(organisationId).pipe(
                map((termsOverview: TermsOverview) => new TermsOverviewActions.GetTermsOverviewSuccess(termsOverview)),
                catchError(() => of(new TermsOverviewActions.GetTermsOverviewFail()))
            ))
    ));


    getMyTermsOverview$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsOverviewActions.GET_MY_TERMS_OVERVIEW),
        switchMap(() => this.termsService.getTermsOverview(new GetTermsOverviewRequest(TermsOverviewType.All)).pipe(
                map((termsOverview: TermsOverview) => new TermsOverviewActions.GetMyTermsOverviewSuccess(termsOverview)),
                catchError(() => of(new TermsOverviewActions.GetMyTermsOverviewFail()))))));


    acceptPendingTerm$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsOverviewActions.ACCEPT_PENDING_TERM),
        map((action: TermsOverviewActions.AcceptPendingTerm) => action.payload),
        mergeMap((request: AcceptPendingTermRequest) =>
            this.termsService.acceptPendingTerm(request).pipe(
                map((terms: TermsOverviewItem) => new TermsOverviewActions.AcceptPendingTermSuccess(terms)),
                catchError(() => of(new TermsOverviewActions.AcceptPendingTermFail()))
    ))));


    acceptPendingTermFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TermsOverviewActions.ACCEPT_PENDING_TERM_FAIL),
        tap(() => {
            this.alertService.error('Error occurred accepting pending term. Make sure the specified term is valid');
        })
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private termsService: TermsService,
        private alertService: AlertService,
        private authenticationTokenService: AuthenticationTokenService
    ) { }
}
