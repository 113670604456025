<div fxFlex="auto" fxLayout="column" id="activity-log">
    <div fxFlex="auto" fxLayout="column" class="container">

        <div fxFlex="auto" fxLayout="column" class="table-container">
            <mat-table [dataSource]="dataSource" class="has-paging">
                <ng-container matColumnDef="dateCreated">
                    <mat-header-cell *matHeaderCellDef fxFlex="20"> Date/Time </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxFlex="20"> {{ element.dateCreated | ukdate: 'time' }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="activity">
                    <mat-header-cell *matHeaderCellDef fxFlex> Activity </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxFlex>{{element.text}}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <mat-paginator #paginator class="mat-elevation-z2" 
                [length]="activities?.length" 
                [pageSize]="10" 
                [pageSizeOptions]="[5, 10, 20]" 
                [showFirstLastButtons]="true" 
                (page)="handlePaginatorChanged($event)"></mat-paginator>
        </div>
    </div>
</div>