<div class="item-information" *ngIf="description">
    <div class="item-button" fxLayoutAlign="start center">
        <mat-icon (click)="showDescription=!showDescription">info_outline</mat-icon>
    </div>
    <div class="item-information-container" *ngIf="showDescription" fxLayout="row">
        <div class="content">
            <mat-icon style="float: right; cursor: pointer" (click)="showDescription=!showDescription">close</mat-icon>
            <div [innerHtml]="description | safe: 'html'">
            </div>
        </div>
    </div>
</div>
