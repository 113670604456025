import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-idv-confirmation-step',
    templateUrl: './confirmation-step.component.html',
    styleUrls: ['./confirmation-step.component.scss']
})
export class ConfirmationStepComponent {
    @Input()
    base64Image: string;

    @Output()
    confirmed: EventEmitter<void> = new EventEmitter();

    @Output()
    rejected: EventEmitter<void> = new EventEmitter();

    handleConfirmClicked(): void {
        this.confirmed.emit();
    }

    handleRetakeClicked(): void {
        this.rejected.emit();
    }
}