<form class="date-input" name="dateForm" [formGroup]="dateForm" novalidate [class.disabled]="disabled">
    <label *ngIf="label" class="font-question-label date-label" [class.mat-error]="dateForm.errors">{{label + (required ? " *" : "")}}</label>
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxFlex="24%" fxLayout="column">
            <div *ngIf="!label" class="font-question-label">Day</div>
            <mat-form-field appearance="outline" [ngClass]="{'mat-form-field-invalid': dateForm.errors}">
                <input matInput type="tel" formControlName="day" placeholder="DD" #day />
            </mat-form-field>
        </div>
        <div fxFlex="24%" fxLayout="column">
            <div *ngIf="!label" class="font-question-label">Month</div>
            <mat-form-field appearance="outline" [ngClass]="{'mat-form-field-invalid': dateForm.errors}">
                <input matInput type="tel" formControlName="month" placeholder="MM" #month />
            </mat-form-field>
        </div>
        <div fxFlex="48%" fxLayout="column">
            <div *ngIf="!label" class="font-question-label">Year</div>
            <mat-form-field appearance="outline" [ngClass]="{'mat-form-field-invalid': dateForm.errors}">
                <input matInput formControlName="year" placeholder="YYYY" #year />
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="dateForm.errors?.dateTooLate" class="mat-error error">
        The date must be before {{maximumDateObject | ukdate }}
    </div> 
    <div *ngIf="dateForm.errors?.dateTooEarly" class="mat-error error">
        The date must be after {{minimumDateObject | ukdate }}
    </div>
    <div *ngIf="dateForm.errors?.dateInvalid" class="mat-error error">
        The date is not a valid date
    </div>
    <div *ngIf="dateForm.errors?.dateRequired && label" class="mat-error error">
        {{label}} is required
    </div>
    <div *ngIf="dateForm.errors?.dateRequired && !label" class="mat-error error">
        The date is required
    </div>
    <ng-content></ng-content>
</form>