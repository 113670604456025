import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// Models
import { BadgeSummary } from 'app/shared/modules/set-observations/models/responses/badge-summary.model';

@Component({
    templateUrl: 'badge-summary-dialog.component.html'
})
export class BadgeSummaryDialogComponent implements OnInit {
    public totalSummaries: number;
    public currentIndex: number = 0;
    public isLastPage: boolean = false;
    public currentSummary: BadgeSummary;

    constructor(@Inject(MAT_DIALOG_DATA) private data: BadgeSummary[]) {
    }

    ngOnInit(): void {
        this.currentSummary = this.data[0];
        this.totalSummaries = this.data.length;
        this.determineIsLastPage();
    }

    moveNextSummary(): void {
        this.currentIndex++;
        this.determineIsLastPage();

        if (this.currentIndex <= this.data.length - 1) {
            this.currentSummary = this.data[this.currentIndex];
        }
    }

    determineIsLastPage(): void {
        this.isLastPage = this.currentIndex === this.data.length - 1;
    }
}