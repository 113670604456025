export class AddImageListValueRequest {
    itemId: string;
    imageFilename: string;
    base64Image: string;
    contentType: string;

    constructor(itemId: string, imageFilename: string, base64Image: string, contentType: string) {
        this.itemId = itemId;
        this.imageFilename = imageFilename;
        this.base64Image = base64Image;
        this.contentType = contentType;
    }
}