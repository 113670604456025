<div [formGroup]="form">
    <app-option-input 
        [options]="options"
        (valueChanged)="onValueChanged($event)"
        [value]="itemObservation.value"
        [mandatory]="item.mandatory"
        [description]="item.description"
        [readonly]="item.readonly">
    </app-option-input>
</div>
