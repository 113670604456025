import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// ngrx / rxjs
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

// store
import * as fromRoot from 'app/store';

// services
import { ImageCaptureService } from 'app/portal/modules/inputs/services/image-capture.service';
import { VideoCaptureService } from 'app/portal/modules/inputs/services/video-capture.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { QrCodeDialogComponent } from 'app/shared/modules/mobile-photo/components/qr-code-dialog/qr-code-dialog.component';

// enums
import { PhotoCaptureStage } from 'app/portal/modules/inputs/enumerations/photo-capture-stage.enum';
import { CameraFacingMode } from 'app/portal/modules/inputs/enumerations/camera-facing-mode.enum';
import { FileExtension, isJpg } from 'app/portal/modules/inputs/enumerations/file-extension.enum';

// models
import { Image } from 'app/portal/modules/inputs/models/image.model';
import { ImageMetaData } from 'app/portal/modules/inputs/models/image-metadata.model';
import { PhotoInputSettings } from 'app/portal/modules/inputs/models/photo-input-settings.model';

// extensions
import { getFileExtension } from 'app/shared/utilities/string-utilities';

@Component({
    selector: 'app-photo-input',
    styleUrls: ['photo-input.component.scss'],
    templateUrl: './photo-input.component.html'
})

export class PhotoInputComponent extends BaseComponent implements OnInit {

    PhotoCaptureStage = PhotoCaptureStage;

    @Input() settings: PhotoInputSettings;
    @Output() imageUpload = new EventEmitter<Image>();
    @Output() imageDownloadClick = new EventEmitter<void>();
    @Output() imageRemoveClick = new EventEmitter<void>();

    @ViewChild('videoDisplay') videoElement: ElementRef;
    @ViewChild('canvasElement') canvasElement: ElementRef;
    @ViewChild('imageDisplay') imageElement: ElementRef;
    @ViewChild('imageUpload') uploadElement: ElementRef;

    private initialising = false;
    private imageDetails: Image;

    private videoStream: MediaStream;
    private devices: MediaDeviceInfo[];
    private deviceId: string;
    private userSelectedDevice: boolean;

    multipleDevices: boolean;
    webcamAccess = false;
    cameraStarted = false;
    isMobileDevice = false;
    stage: PhotoCaptureStage;
    isEndUser = false;

    maximumFileSizeBytes: number;
    invalidFileExtension: boolean;
    invalidFileSize: boolean;
    processingError: boolean;
    processingErrorCode: string;

    imageSrc: string;
    showImagePreview = false;
    imageFileTypes: string;

    constructor(
        private imageCaptureService: ImageCaptureService,
        private videoCaptureService: VideoCaptureService,
        private alertService: AlertService,
        private authTokenService: AuthenticationTokenService,
        private dialogs: MatDialog,
        private store: Store<fromRoot.State>) {
            super();
    }

    ngOnInit(): void {
        this.stage = PhotoCaptureStage.Intro;
        this.maximumFileSizeBytes = this.settings.maximumFileSizeMb * 1048576;

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromRoot.isMobileDevice))
            .subscribe(isMobileDevice => {
                this.isMobileDevice = isMobileDevice;
                if (!this.isMobileDevice) {
                    this.checkWebcamAccess();
                }
            });

        this.setFileTypes();
        this.isEndUser = !this.authTokenService.isClient();
    }

    onDestroy(): void {
        this.stopWebCam();
    }

    get canContinue(): boolean {
        if (this.stage === PhotoCaptureStage.Confirm) {
            return !!this.imageDetails;
        }

        return true;
    }

    get isCanvasEmpty(): boolean {
        if (!this.canvasElement) {
            return false;
        }

        const context = this.canvasElement.nativeElement.getContext('2d');
        const pixelBuffer = new Uint32Array(
            context.getImageData(0, 0, this.canvasElement.nativeElement.width, this.canvasElement.nativeElement.height).data.buffer
        );

        return !pixelBuffer.some(color => color !== 0);
    }

    setImage(image: Blob, name: string): void {
        this.initialising = true;
        this.loadImageFromBlob(image, name);
    }

    setImageBase64(base64Image: string, name: string): void {
        this.initialising = true;
        this.loadImageFromDataUrl(base64Image, name);
    }

    clearImage(): void {
        this.imageSrc = null;
        this.showImagePreview = false;
    }

    onDownloadImageClicked() {
        this.imageDownloadClick.emit();
    }

    onImageRemovedClicked() {
        this.imageRemoveClick.emit();
        this.stage = PhotoCaptureStage.Intro;
    }

    onSubmitClicked(): void {
        this.clearImageProcessingError();
        switch (this.stage) {
            case PhotoCaptureStage.Intro:
                this.stage = PhotoCaptureStage.Capture;
                this.showImagePreview = false;
                this.init();
                break;

            case PhotoCaptureStage.Capture:
                this.captureImage();
                this.stage = PhotoCaptureStage.Intro;
                this.showImagePreview = true;
                break;
        }
    }

    onUploadClicked(): void {
        this.clearImageProcessingError();
        this.uploadElement.nativeElement.click();
    }

    onBackClicked(): void {
        this.clearImageProcessingError();
        this.stage = PhotoCaptureStage.Intro;
        this.showImagePreview = true;
        this.stopWebCam();
    }

    onSwitchWebcamClicked(): void {
        this.userSelectedDevice = true;
        this.stopWebCam();
        this.startSpecifiedWebcam(this.chooseNextDevice());
    }

    onSwitchToMobileClicked(): void {
        this.dialogs.open(QrCodeDialogComponent, {
            disableClose: true,
            data: this.settings.qrCodeData
        });
    }

    onUploadImage(event: any) {
        if (event.target.files && event.target.files[0]) {

            const file: File = event.target.files[0];
            const ext = getFileExtension(file.name);
            this.invalidFileExtension = !(this.settings.allowedFileExtensions.some(x => x === FileExtension.Any) ||
                this.settings.allowedFileExtensions.some(x => x === ext));

            this.invalidFileSize = this.settings.maximumFileSizeMb !== 0 && file.size > this.maximumFileSizeBytes;

            if (this.invalidFileExtension || this.invalidFileSize) {
                this.clearImage();
            } else {
                this.showImagePreview = true;
                this.loadImageFromBlob(file, file.name);
            }
        }
    }

    private init(): void {
        this.cameraStarted = false;
        this.imageDetails = null;

        if (this.canvasElement) {
            const context = this.canvasElement.nativeElement.getContext('2d');
            context.clearRect(0, 0, this.canvasElement.nativeElement.width, this.canvasElement.nativeElement.height);
        }

        this.startWebcam();
    }

    private checkWebcamAccess() {
        this.videoCaptureService.getAvailableVideoDevices()
            .subscribe((devices) => {
                this.devices = devices;
                this.webcamAccess = devices.length > 0;
                this.multipleDevices = devices.length > 1;
            });
    }

    private startWebcam() {
        if (this.userSelectedDevice) {
            this.userSelectedDevice = true;
            this.startSpecifiedWebcam(this.deviceId);
        } else {
            this.userSelectedDevice = true;
            this.startFrontWebcam();
        }
        this.cameraStarted = true;
    }

    private startFrontWebcam() {
        this.videoCaptureService.startWebcam(CameraFacingMode.Front)
            .subscribe((stream) => {
                this.videoElement.nativeElement.srcObject = stream;
                this.videoStream = stream;
                const track = this.videoStream.getTracks()[0];
                this.deviceId = this.deviceId = track.getSettings().deviceId;
                this.cameraStarted = true;
            }, (errorMsg: any) => {
                this.alertService.error(errorMsg);
                this.cameraStarted = false;
            });
    }

    private startSpecifiedWebcam(deviceId: string) {
        this.videoCaptureService.startSpecifiedWebcam(deviceId)
            .subscribe((stream) => {
                this.videoElement.nativeElement.srcObject = stream;
                this.videoStream = stream;
                this.deviceId = deviceId;
                this.cameraStarted = true;
            }, (errorMsg: any) => {
                this.alertService.error(errorMsg);
                this.cameraStarted = false;
            });
    }

    private stopWebCam() {
        if (this.cameraStarted) {
            this.videoCaptureService.stopWebcam(this.videoElement.nativeElement, this.videoStream);
            this.cameraStarted = false;
        }
    }

    private chooseNextDevice(): string {
        const currentDeviceIndex = this.devices.findIndex(d => d.deviceId === this.deviceId);
        const nextDeviceIndex = (currentDeviceIndex + 1 === this.devices.length) ? 0 : currentDeviceIndex + 1;
        return this.devices[nextDeviceIndex].deviceId;
    }

    private captureImage() {
        const canvas = this.canvasElement.nativeElement;
        const video = this.videoElement.nativeElement;
        const dataUrl = this.videoCaptureService.getImageFromVideo(video, canvas);
        this.loadImageFromDataUrl(dataUrl, this.deviceId);
        this.imageDetails.metadata = new ImageMetaData(1, video.videoWidth, video.videoHeight);
        this.stopWebCam();
    }

    private loadImageFromDataUrl(dataUrl: string, name: string) {
        this.clearImageProcessingError();
        const imageDetails = {} as Image;

        imageDetails.description = name;
        imageDetails.uploadedDataUrl = dataUrl;
        imageDetails.uploadedBase64 = this.imageCaptureService.stripDataHeader(dataUrl);

        if (dataUrl !== null) {
            this.showImagePreview = true;
        }

        this.imageDetails = imageDetails;
        this.completeImageProcessing();
    }

    private loadImageFromBlob(imageFile: Blob, name: string) {
        this.imageCaptureService.getImageDataUrl(imageFile, (dataUrl) => {
            this.loadImageFromDataUrl(dataUrl, name);
        });
    }

    private imageProcessingError(code: string) {
        this.clearImage();
        this.processingError = true;
        this.processingErrorCode = code;
        this.stage = PhotoCaptureStage.Intro;
    }

    private clearImageProcessingError() {
        this.processingErrorCode = '';
        this.processingError = false;
    }

    private completeImageProcessing(): void {
        const extension = getFileExtension(this.imageDetails.description);
        const conversionRequired = !this.initialising &&
            this.settings.convertToJpg &&
            !isJpg(extension);

        this.imageCaptureService.getBasicImageMetadata(this.imageDetails.uploadedDataUrl)
            .then((metadata: ImageMetaData) => {
                this.imageDetails.metadata = metadata;
                if (conversionRequired) {
                    this.imageCaptureService.convertToJpeg(metadata, this.canvasElement.nativeElement, this.imageDetails.uploadedDataUrl)
                        .then((convertedImageUrl) => {
                            this.imageDetails.uploadedDataUrl = convertedImageUrl;
                            this.compressAndCompleteImageProcessing();
                        })
                        .catch((error: Error) => {
                            this.imageProcessingError(error.message);
                        });
                } else {
                    this.compressAndCompleteImageProcessing();
                }
            });
    }

    private compressAndCompleteImageProcessing() {
        if (!this.initialising && this.settings.compressImages) {
             this.imageCaptureService.compressFile(this.imageDetails, (compressedImage: Image) => {
                 this.imageDetails = compressedImage;
                 this.finaliseImageProcessing();
             });
        } else {
            this.finaliseImageProcessing();
        }
    }

    private finaliseImageProcessing(): void {
        this.imageSrc = this.imageDetails.uploadedDataUrl;

        if (!this.initialising) {
            this.imageUpload.emit(this.imageDetails);
        }

        this.initialising = false;
    }

    private setFileTypes(): void {
        this.imageFileTypes = '';
        if (this.settings.allowedFileExtensions.filter(e => e === FileExtension.Any).length > 0) {
            this.imageFileTypes = FileExtension.Any;
        } else {
            for (const type of this.settings.allowedFileExtensions) {
                if (this.imageFileTypes !== '') {
                    this.imageFileTypes += ',';
                }

                this.imageFileTypes += `image/${type}`;
            }
        }
    }
}
