<div id="page" fxLayout="column" [fxLayoutGap]="layoutSpacing" fxFlex="100">
    <div fxLayout="column" [class.margin-bottom-0]="filterConfiguration">
        <app-toolbar [showAdditionalOptions]="showAdditionalOptions">
            <ng-content select="[toolbar]"></ng-content>
            <ng-content standalone-buttons select="[standalone-buttons]"></ng-content>
        </app-toolbar>

        <app-filter
            [hidden]="!filterConfiguration"
            [configuration]="filterConfiguration"
            [visibleRecordCount]="visibleRecordCount"
            (openFolderClicked)="onOpenFolderClicked($event)"
            (deleteFolderClicked)="onDeleteFolderClicked($event)"
            (editFolderClicked)="onEditFolderClicked($event)">
        </app-filter>
    </div>
    <div class="page-content" [ngClass]="{ 'page-content-no-scroll': hideScroll }" fxFlex="fill">
        <ng-content select="[content]"></ng-content>
    </div>

    <div class="page-content-no-padding" [ngClass]="{ 'page-content-no-scroll': hideScroll }" fxFlex="fill">
        <ng-content select="[content-no-padding]"></ng-content>
    </div>
</div>