<app-dialog dialogTitle="Confirmation Required" defaultWidth="400px">
    <div body>
        <p *ngIf="confirmationMessage" [innerHTML]="confirmationMessage"></p>
        <p *ngIf="!confirmationMessage">Are you sure?</p>
    </div>

    <div footer-actions>
        <button mat-raised-button class="ghost" (click)="cancelled()">No</button>
        <button mat-raised-button  (click)="confirmed()">Yes</button>
    </div>
</app-dialog>
