<div id="document-wizard-step-2" fxLayout="column" fxLayoutGap="10px">
    <h2>Step 2 - Choose {{'labels.organisation.plural' | translate}} to sign the document.</h2>

    <div id="list-container">
        <div fxLayout="column" fxLayoutGap="10px">
            <div *ngFor="let documentSignatureForm of form.get('documentSignatures').value" [formGroup]="documentSignatureForm">
                <div fxLayout="column" fxLayoutGap="10px">
                    <h3>{{documentSignatureForm.get('title').value}}</h3>
                    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="10px">
                        <div>
                            <div class="font-question-label">Signatory</div>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="organisationId" (selectionChange)="handleSelectionChange($event.value)">
                                    <mat-option *ngFor="let organisation of organisations" [value]="organisation.id">
                                        {{organisation.title}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="documentSignatureForm.get('organisationId').hasError('required')">
                                    Signatory is a required field.</mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <div class="font-question-label">Name</div>
                            <mat-form-field appearance="outline">
                                <input matInput type="text" formControlName="name" [matAutocomplete]="auto" />
                                                    
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="handleOrganisationUserSelected($event.option, documentSignatureForm)">
                                    <mat-option *ngFor="let organisationUser of getOrganisationUsers(documentSignatureForm.get('organisationId').value)" [value]="organisationUser">
                                    {{ organisationUser.firstName }} {{ organisationUser.surname }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div>
                            <div class="font-question-label">Email</div>
                            <mat-form-field appearance="outline">
                                <input matInput type="email" formControlName="email" />
                                <mat-error *ngIf="documentSignatureForm.get('email').hasError('email')">Email must be a
                                    valid email address.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="error" *ngIf="!signatureEmailsValid">Signature emails must be unique.</div>
        </div>
    </div>
</div>