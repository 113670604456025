import { Pipe, PipeTransform } from '@angular/core';
import { ItemType } from 'app/shared/enums/item-type.enum';

@Pipe({ name: 'isItemTypeRTW' })
export class IsItemTypeRTWPipe implements PipeTransform {
    transform(typeToCheck: ItemType): boolean {
        switch (typeToCheck) {
            case ItemType.Selfie:
            case ItemType.UniqueActionProcess:
            case ItemType.IdDocuments: return true;
            default: return false;
        }
    }
}