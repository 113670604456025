import { Action } from '@ngrx/store';

// Models
import { UserActivityRequest } from 'app/shared/models/user-activity-request.model';
import { UserActivityDetails } from 'app/shared/models/user-activity-details.model';

export const GET_ORGANISATION_USER_ACTIVITY = '[User Activity] Get Organisation User Activity';
export const GET_USER_ACTIVITY = '[User Activity] Get User Activity';
export const GET_ACTIVITY_SUCCESS = '[User Activity] Get Activity Success';
export const GET_ACTIVITY_FAIL = '[User Activity] Get Activity Fail';

export const LOG_USER_ACTIVITY = '[User Activity] Log User Activity';
export const LOG_USER_ACTIVITY_SUCCESS = '[User Activity] Log User Activity Success';
export const LOG_USER_ACTIVITY_FAIL = '[User Activity] Log User Activity Fail';

export class GetOrganisationUserActivity implements Action {
    readonly type = GET_ORGANISATION_USER_ACTIVITY;
    constructor(public payload: string) { }
}

export class GetUserActivity implements Action {
    readonly type = GET_USER_ACTIVITY;
    constructor(public payload: string) { }
}

export class GetActivitySuccess implements Action {
    readonly type = GET_ACTIVITY_SUCCESS;
    constructor(public payload: UserActivityDetails[]) { }
}

export class GetActivityFail implements Action {
    readonly type = GET_ACTIVITY_FAIL;
}

export class LogUserActivity implements Action {
    readonly type = LOG_USER_ACTIVITY;
    constructor(public payload: UserActivityRequest) { }
}

export class LogUserActivitySuccess implements Action {
    readonly type = LOG_USER_ACTIVITY_SUCCESS;
}

export class LogUserActivityFail implements Action {
    readonly type = LOG_USER_ACTIVITY_FAIL;
}

export type AllUserActivityActions =
    GetOrganisationUserActivity |
    GetUserActivity |
    GetActivitySuccess |
    GetActivityFail |
    LogUserActivity |
    LogUserActivitySuccess |
    LogUserActivityFail;