import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Models
import { FileUploadDialogSettings } from 'app/shared/models/file-upload-dialog-data.model';

// Enums
import { FileExtensions } from 'app/shared/enums/file-extensions.enum';

@Component({
    templateUrl: './file-upload-dialog.component.html'
})
export class FileUploadDialogComponent {
    message: string;
    allowedFileExtensions: FileExtensions[];
    file: File;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: FileUploadDialogSettings,
        private dialogRef: MatDialogRef<FileUploadDialogComponent>) {
            this.message = data.message;
            this.allowedFileExtensions = data.allowedFileExtensions;
    }

    handleFileSelected(file: File): void {
        this.file = file;
    }

    handleOkClicked(): void {
        this.dialogRef.close(this.file);
    }
}
