<div id="support-text">
    <a class="default" href="mailto:{{'contact.supportEmail' | translate}}" *ngIf="!hasSupportText()">
        <span class="title button-class-text uppercase">Need some help?</span>
        <span>Contact Support: {{'contact.supportEmail' | translate}}</span>
    </a>

    <div *ngIf="hasSupportText()">
        <a *ngIf="hasSupportEmail()" href="mailto:{{supportEmail}}">
            <span [innerHTML]="supportText | safe: 'html'"></span>
        </a>

        <span *ngIf="!hasSupportEmail()" [innerHTML]="supportText | safe: 'html'"></span>
    </div>
</div>