<div class="option-input" fxLayout="row" fxFlex="100%">
    <ng-template [ngIf]="options.length > 5">
        <app-searchable-multi-select-input *ngIf="multiple" [options]="searchableOptions" [label]="label" [readonly]="readonly" (optionSelected)="onDropdownSelectionChange($event)" (optionRemoved)="onDropdownSelectionChange($event)" [returnMultipleSelectedOptions]="true"></app-searchable-multi-select-input>
        <app-searchable-input *ngIf="!multiple" [options]="searchableOptions" [currentValue]="value" [label]="label" [readonly]="readonly" (optionSelected)="onDropdownSelectionChange($event)"></app-searchable-input>
    </ng-template>

    <ng-template [ngIf]="options.length <= 5">
        <mat-radio-group *ngIf="!multiple" (change)="onRadioChange($event.value)" fxLayout="column" fxLayoutGap="8px" [value]="value" [disabled]="readonly">
            <mat-radio-button *ngFor="let option of options" [value]="option.value">{{option.label}}</mat-radio-button>
        </mat-radio-group>
        <div *ngIf="multiple" fxLayout="column" fxLayoutGap="8px">
            <div *ngFor="let option of options">
                <mat-checkbox (change)="onCheckboxChange($event.checked, option.value)" [checked]="values && values.includes(option.value)" [disabled]="readonly">{{option.label}}</mat-checkbox>
            </div>
        </div>
    </ng-template>
</div>