import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './confirmation-required-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationRequiredDialogComponent {
    confirmationMessage: string;

    // todo I think we can do more to make this more re-usable with less code for the consumer but to get the ball rolling...
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmationRequiredDialogComponent>) {
        this.confirmationMessage = data.confirmationMessage;
    }

    confirmed() {
        this.dialogRef.close(true);
    }

    cancelled() {
        this.dialogRef.close();
    }
}
