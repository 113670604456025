import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

// Models
import { Option } from 'app/shared/models/option.model';
import { Item } from 'app/shared/modules/set-observations/models/item.model';
import { AddMultiOptionListValueRequest } from 'app/shared/modules/set-observations/models/requests/add-multi-option-list-value-request.model';

// Enums
import { ButtonSize } from 'app/shared/modules/set-observations/enums/button-size.enum';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-multi-option-list',
    templateUrl: './multi-option-list-observation.component.html',
    outputs: ['valueUpdated', 'selectedChanged']
})
export class MultiOptionListObservationComponent implements OnInit {

    @Input()
    item: Item;

    @Output()
    observationAdded: EventEmitter<AddMultiOptionListValueRequest> = new EventEmitter();

    @Output()
    observationRemoved: EventEmitter<string> = new EventEmitter();

    options: Option[];

    values: string[];

    buttonSize: ButtonSize = ButtonSize.Multi;

    ngOnInit(): void {
        this.options = [];
        this.item.multiOptionValues.sort((a, b) => a.order - b.order);

        for (const multiOptionValue of this.item.multiOptionValues) {
            this.options = [...this.options, new Option(multiOptionValue.value, multiOptionValue.value)];
        }

        this.values = this.item.itemObservations.map(io => io.value);
    }

    onValuesChanged(values: string[]) {
        const newObservations: string[] = [];

        for (const value of values) {
            if (this.item.itemObservations.filter(io => io.value === value).length === 0) {
                newObservations.push(value);
            }
        }

        const deleteObservations = this.item.itemObservations.filter(io => io.hasValue && !values.includes(io.value));

        for (const newObservation of newObservations) {
            this.observationAdded.emit(new AddMultiOptionListValueRequest(this.item.id, newObservation));
        }

        for (const itemObservation of deleteObservations) {
            this.observationRemoved.emit(itemObservation.observationId);
        }
    }
}
