import { NgModule } from '@angular/core';

// Modules
import { FormsModule } from '@angular/forms';
import { NgxTinymceModule } from 'ngx-tinymce';

// Components
import { RichTextEditorComponent } from './rich-text-editor.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxTinymceModule.forRoot({
            baseURL: '../../../../assets/tinymce/'
        })
    ],
    declarations: [
        RichTextEditorComponent
    ],
    providers: [
    ],
    exports: [
        RichTextEditorComponent
    ],
    entryComponents: [
    ]
})
export class RichTextEditorModule { }