<div class="section-wrapper" [ngClass]="{ 'expanded': expanded }" fxLayout="column">

    
    <div class="section-wrapper-title primary-bg primary-reverse-text" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div fxFlex (click)="expanded = !expanded">{{sectionTitle}}</div>
        <div *ngIf="showEdit" (click)="handleEditClicked()"><img src="/assets/icons/edit-grey.png" /></div>
        <mat-icon (click)="expanded = !expanded">keyboard_arrow_down</mat-icon>
    </div>

    <div class="section-wrapper-content" [@slideInOut]="contentState">
        <ng-content></ng-content>
    </div>

</div>