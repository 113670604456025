import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

// Models
import { SetDocumentDownloadClick } from 'app/shared/models/set-document-download-click.model';
import { DocumentsConfiguration } from 'app/shared/models/documents-configuration.model';
import { DocumentTag } from 'app/shared/models/document-tag.model';

// Store
import * as fromSetsStore from 'app/shared/modules/set-observations/store';

// Enums
import { FileExtensions } from 'app/shared/enums/file-extensions.enum';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-document',
    styleUrls: ['./document-observation.component.scss'],
    templateUrl: './document-observation.component.html',
    outputs: ['valueUpdated', 'selectedChanged']
})
export class DocumentObservationComponent extends ObservationComponent {
    constructor(private store: Store<fromSetsStore.SetObservationsState>) {
        super();
    }

    init() {
        // todo need to look at the fact this is required
    }

    fileUploaded($event: File) {
        this.updateFileValue($event);
    }

    fileDownloadClicked() {
        this.store.dispatch(new fromSetsStore.DownloadDocument(new SetDocumentDownloadClick(this.itemObservation.value, this.itemObservation.observationId)));
    }

    showDownload(): boolean {
        return this.itemObservation.showDownloadLink;
    }

    updateDescription(event) {
        this.updateMetaData('description', event);
    }

    updateTag(event) {
        this.updateMetaData('tag', event.value);
    }

    get metaDataRequirementsMet(): boolean {
        return (!this.config?.descriptionRequired || this.itemObservation.metaData?.description?.length) &&
               (!this.config?.tagRequired || this.itemObservation.metaData?.tag?.length);
    }

    get currentDescription(): string {
        return this.itemObservation.metaData.description ?? '';
    }

    get currentTag(): string {
        return this.itemObservation.metaData.tag;
    }

    get allowedFileExtensions(): FileExtensions[] {
        if (this.config?.allowedFileExtensions?.length) {
            return this.config?.allowedFileExtensions;
        }
        return [FileExtensions.Any];
    }

    get tagsAllowed(): DocumentTag[] {
        if (this.config?.tagsAllowed?.length) {
            return this.config?.tagsAllowed;
        }
        return [];
    }

    get config(): DocumentsConfiguration {
        return this.item?.documentsConfiguration;
    }
}
