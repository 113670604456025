<div class="identity-verification-results" fxLayout="column" fxLayoutGap="16px">
    <div *ngIf="idvData.hasSelfie" class="selfie" fxLayout="column" fxLayoutGap="16px">
        <h3>Selfie</h3>
        <app-image [src]="selfieSource"></app-image>
    </div>
    
    <div *ngIf="idvData.livenessVerified">
        <h3 class="rag-green-text">Liveness verified via {{livenessMethodTitle}}</h3>
    </div>
    <div *ngIf="!idvData.livenessVerified">
        <h3 class="rag-amber-text" *ngIf="!idvData.hasSelfie">Liveness not verified - No selfie provided</h3>
        <h3 class="rag-amber-text" *ngIf="idvData.hasSelfie && !idvData.hasUap">Liveness not verified - No UAP provided</h3>
        <h3 class="rag-amber-text" *ngIf="idvData.hasSelfie && idvData.hasUap">Liveness not verified - Awaiting manual checks</h3>
    </div>

    <div *ngIf="idvData.hasSelfie && !idvData.passiveLivenessPassed && idvData.hasUap">
        <h3>Unique Action Process</h3>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start top">
            <app-image [src]="uapSource"></app-image>
            <div fxLayout="column" fxLayoutGap="8px">
                <img [src]="idvData.uapActionImageUrl | safe: 'resourceUrl'">
                <div class="text align-center">{{idvData.uapActionDescription}}</div>
            </div>
        </div>
    </div>
    
    <h3 *ngIf="idvData.idDocuments.length === 0">No ID documents provided</h3>
    <div *ngIf="idvData.idDocuments.length > 0" fxLayout="column" fxLayoutGap="16px">
        <h3>ID Documents</h3>
        <app-id-documents-full-result [idDocuments]="idvData.idDocuments" [observationId]="observationId"></app-id-documents-full-result>
    </div>
    
</div>