<div fxLayout="row" fxLayoutGap="10px">
    <div *ngIf="config?.descriptionRequired" fxFlex="50" fxLayout="column">
        <div class="font-question-label">Description</div>
        <mat-form-field appearance="outline" class="observation-field">
            <input
                matInput
                (input)="updateDescription(documentDescription.value)"
                #documentDescription
                [readonly]="item.readonly"
                [value]="currentDescription">
        </mat-form-field>
    </div>
    
    <div *ngIf="tagsAllowed.length" fxFlex="50" fxLayout="column">
        <div class="font-question-label">{{ config?.tagLabel?.length ? config?.tagLabel : 'Type of document' }}</div>
        <mat-form-field appearance="outline" class="observation-field">
            <mat-select [value]="currentTag" (selectionChange)="updateTag($event)">
                <mat-option *ngFor="let tag of tagsAllowed" [value]="tag.name">{{tag.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="document-observation">
    <app-file-upload 
        (fileUploaded)="fileUploaded($event)"
        (fileDownloadClicked)="fileDownloadClicked()"
        [allowedFileExtensions]="allowedFileExtensions"
        [fileName]="itemObservation.value"
        [disableUpload]="item.readonly || !metaDataRequirementsMet"
        [downloadEnabled]="showDownload()"
        uploadButtonLabel="UPLOAD"
        uploadText="Upload a file"
        maximumFileSizeMb="10">
    </app-file-upload>
</div>