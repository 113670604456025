import { Injectable } from '@angular/core';
import { ImageMetaData } from 'app/shared/models/images/image-metadata.model';
import * as ExifReader from 'exifreader';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageView } from 'app/shared/models/images/image-view.model';

@Injectable()
export class ImageUploadService {

    constructor(private imageCompress: NgxImageCompressService) { }

    getImageDataUrl(imageFile: Blob, callback: (result: string) => void): void {
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onloadend = () => {
            callback(reader.result.toString());
        };
    }

    getImageMetadataFromFile(imageFile: Blob): Promise<ImageMetaData> {
        return new Promise((resolve, reject) => {
            if (!imageFile) {
                reject(new Error('IMGERR1'));
                return;
            }
            const reader = new FileReader();
            reader.readAsArrayBuffer(imageFile);
            reader.onloadend = () => {
                const exifData = ExifReader.load(reader.result as ArrayBuffer);
                const metadata = new ImageMetaData(
                    exifData.Orientation ? exifData.Orientation.value : 0,
                    exifData.PixelXDimension ? exifData.PixelXDimension.value : 0,
                    exifData.PixelYDimension ? exifData.PixelYDimension.value : 0
                );
                resolve(metadata);
            };
        });
    }

    stripDataHeader(imageDataUrl: string): string {
        return imageDataUrl.substr(imageDataUrl.indexOf(',') + 1);
    }

    getBase64(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    getBasicImageMetadata(imageDataUrl: string): Promise<ImageMetaData> {
        return new Promise((resolve, reject) => {
            if (!imageDataUrl) {
                reject(new Error('IMGERR2'));
                return;
            }
            let img = new Image();

            img.onload = () => {
                const metadata = new ImageMetaData(
                    1,
                    img.width,
                    img.height
                );
                img = null;
                resolve(metadata);
            };
            img.src = imageDataUrl;
        });
    }

    convertToJpeg(metadata: ImageMetaData, canvas: HTMLCanvasElement, imageData: string): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!metadata || !canvas || !imageData) {
                reject(new Error('IMGERR3'));
            }
            const width = metadata.width;
            const height = metadata.height;

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            let img = new Image();
            const emptyCanvas = canvas.toDataURL('image/jpeg');

            img.onload = () => {
                ctx.drawImage(img, 0, 0);
                ctx.save();
                const jpgUrl = canvas.toDataURL('image/jpeg');
                if (jpgUrl === emptyCanvas) {
                    reject(new Error('IMGERR4'));
                }
                img = null;
                resolve(jpgUrl);
            };

            img.src = imageData;
        });
    }

    compressFile(image: ImageView, callback: (args: ImageView) => void): void {
        if (!image || !callback) {
            return;
        }

        const orientation = -1;
        let largestImageSide = 0;

        if (image.metadata.height > image.metadata.width) {
            largestImageSide = image.metadata.height;
        } else {
            largestImageSide = image.metadata.width;
        }

        if (largestImageSide > 2000) {
            const imageRatio = (2000 / largestImageSide) * 100;
            this.imageCompress.compressFile(image.uploadedDataUrl, orientation, imageRatio).then(
                result => {
                    const newImage = {
                        ...image,
                        uploadedDataUrl: result,
                        uploadedBase64: this.stripDataHeader(result)
                    };

                    callback(newImage);
                }
            );
        } else {
            callback(image);
        }
    }
}
