import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Models
import { Template } from 'app/portal/modules/document-management/models/template.model';

@Component({
    selector: 'app-document-wizard-step-1',
    templateUrl: './document-wizard-step-1.component.html'
})
export class DocumentWizardStep1Component {
    @Input()
    form: FormGroup;

    @Input()
    templates: Template[];

    @Output()
    templateChanged: EventEmitter<string> = new EventEmitter();

    handleTemplateChanged(templateId: string): void {
        this.templateChanged.emit(templateId);
    }
}
