import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

// ngrx | rxjs
import { Observable } from 'rxjs';

// models
import { User } from 'app/shared/models/user.model';
import { Organisation } from 'app/shared/models/organisation.model';
import { AuthenticatedUser } from 'app/shared/models';
import { ModuleDetails } from 'app/models/module-details.model';

@Injectable()
export class UserService {
    private baseEndpoint = 'api/users';

    constructor(private http: HttpClient) { }

    public getUserSummary(): Observable<AuthenticatedUser> {
        const url = `${this.baseEndpoint}/summary`;
        return this.http.get<AuthenticatedUser>(url);
    }

    public getUserOrganisations(userId: string): Observable<Array<Organisation>> {
        const url = `${this.baseEndpoint}/${userId}/organisations`;
        return this.http.get<Organisation[]>(url);
    }

    public getUserModules(userId: string): Observable<ModuleDetails[]> {
        const url = `${this.baseEndpoint}/${userId}/modules`;
        return this.http.get<ModuleDetails[]>(url);
    }

    public addNew(user: User): Observable<User> {
        const url = `${this.baseEndpoint}`;
        return this.http.post<User>(url, user);
    }

    public update(user: User): Observable<User> {
        const url = `${this.baseEndpoint}`;
        return this.http.put<User>(url, user);
    }

    public search(searchTerm: string): Observable<User[]> {
        const url = `${this.baseEndpoint}/search?searchTerm=${searchTerm}`;
        return this.http.get<User[]>(url);
    }
}