import * as Actions from 'app/shared/modules/set-observations/store/actions';

// Models
import { AssessmentProfileResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-response.model';

export interface AssessmentProfileState {
    loading: boolean;
    loaded: boolean;
    profiles: AssessmentProfileResponse[];
}

const initialState: AssessmentProfileState = {
    loading: false,
    loaded: false,
    profiles: null
};

export function reducer(state = initialState, action: Actions.AllAssessmentProfileActions): AssessmentProfileState {
    switch (action.type) {
        case Actions.GET_ORGANISATION_ASSESSMENT_PROFILE: {
            return {
                ...state,
                loading: true,
                loaded: false,
                profiles: null
            };
        }

        case Actions.GET_ORGANISATION_GROUP_ASSESSMENT_PROFILE: {
            return {
                ...state,
                loading: true,
                loaded: false,
                profiles: null
            };
        }

        case Actions.GET_ASSESSMENT_PROFILES_SUCCESS: {
            return {
                ...state,
                loading: false,
                loaded: true,
                profiles: action.payload
            };
        }

        case Actions.GET_ASSESSMENT_PROFILES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
                profiles: null
            };
        }

        case Actions.CLEAR_ASSESSMENT_PROFILE: {
            return {
                ...state,
                loading: false,
                loaded: false,
                profiles: null
            };
        }
    }

    return state;
}

// todo selector access points to go here