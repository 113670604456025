import { Component, OnInit } from '@angular/core';

// ngrx
import { Store } from '@ngrx/store';

// Store
import * as fromAuth from 'app/authentication/store';

// Models
import { LogoutOptions } from 'app/models/logout-options.model';

@Component({
    templateUrl: './outreach-landing-page.component.html',
    styleUrls: ['./outreach-landing-page.component.scss']
})
export class OutreachLandingPageComponent implements OnInit {

    constructor(private store: Store<fromAuth.AuthenticationState>) { }

    ngOnInit(): void {
        this.store.dispatch(new fromAuth.Logout(new LogoutOptions(false, false, null)));
    }
}