import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// ngrx | rxjs
import { select, Store, ActionsSubject } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

// store
import * as fromDocumentLibrary from 'app/portal/modules/document-library-v1/store';

// components
import { ConfirmationRequiredDialogComponent } from 'app/shared/components/confirmation-required-dialog/confirmation-required-dialog.component';
import { DocumentWizardDialogComponent } from 'app/portal/modules/document-library-v1/components/document-wizard-dialog/document-wizard-dialog.component';
import { DocumentDialogComponent } from 'app/portal/modules/document-library-v1/components/document-dialog/document-dialog.component';
import { getColumnNumber, onPageResize } from 'app/shared/components/grid-container/grid-container.component';
import { BaseComponent } from 'app/shared/base/base-component';
import { PageComponent } from 'app/connect/components/page/page.component';

// models
import { FilterConfiguration } from 'app/models/filter/filter-configuration.model';
import { Filter } from 'app/models/filter/filter.model';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { DocumentLibraryItem } from 'app/shared/modules/set-observations/models/document-library-item.model';
import { DocumentLibrarySignature } from 'app/shared/modules/set-observations/models/document-library-signature.model';

// services
import { DocumentLibraryService } from 'app/portal/modules/document-library-v1/services';
import { FileSaverService } from 'ngx-filesaver';

// enums
import { Modules } from 'app/shared/enums';
import { OrganisationTitle } from 'app/shared/models/organisation-title.model';
import { DocumentType, getDocumentTypeLabel } from 'app/portal/modules/document-management/enums';

@Component({
    templateUrl: './document-library.component.html',
    styleUrls: ['./document-library.component.scss']
})
export class DocumentLibraryIndexComponent extends BaseComponent implements OnInit {

    @ViewChild('page', { static: true }) page: PageComponent;
    loading$: Observable<boolean>;
    configuration: FilterConfiguration;
    allDocuments: Array<DocumentLibraryItem>;
    unsignedDocuments: Array<DocumentLibraryItem>;
    signedDocuments: Array<DocumentLibraryItem>;
    exampleDocument: DocumentLibraryItem;
    filteredDocuments: DocumentLibraryItem[];
    isSignatoryFilteredDocuments: DocumentLibraryItem[];
    notSignatoryFilteredDocuments: DocumentLibraryItem[];
    columnNum: number;

    Modules = Modules;

    DocumentTemplateKey = 'Template';
    DocumentAccessibilityKey = 'Owner or Signatory';
    DocumentTypeKey = 'Type';
    FullySignedKey = 'Fully Signed';

    constructor(
        private store: Store<fromDocumentLibrary.DocumentsState>,
        private documentLibraryService: DocumentLibraryService,
        private fileSaverService: FileSaverService,
        private dialogs: MatDialog,
        private actionsSubject: ActionsSubject) {

            super();
    }

    ngOnInit(): void {
        this.initPage();

        this.store.pipe(
            select(fromDocumentLibrary.getAllDocuments),
            takeUntil(this.ngUnsubscribe))
            .subscribe(documents => {
                if (documents) {
                    this.allDocuments = documents;
                    this.initializeFilter();
                }
            });

        this.loading$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromDocumentLibrary.getLoading));
        this.getAll();

        this.columnNum = getColumnNumber();

        this.exampleDocument = this.getExampleDocument();

        this.page.setShowNewButton(true);

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromDocumentLibrary.DELETE_DOCUMENT_SUCCESS))
            .subscribe(() => this.getAll());
    }

    onResize() {
        this.columnNum = onPageResize();
    }

    getExampleDocument(): DocumentLibraryItem {
        this.exampleDocument = new DocumentLibraryItem();

        this.exampleDocument.ownerOrganisation = new OrganisationTitle();
        this.exampleDocument.name = 'Example Document';
        this.exampleDocument.templateName = 'Example Template';
        this.exampleDocument.documentType = DocumentType.PrivacyPolicy;
        this.exampleDocument.fullySigned = true;
        this.exampleDocument.dateCreated = new Date();
        this.exampleDocument.isOwner = false;
        this.exampleDocument.documentSignatures = new Array<DocumentLibrarySignature>();

        return this.exampleDocument;

    }

    search(currentFilter: Filter): void {
        if (!currentFilter) {
            this.filteredDocuments = this.allDocuments;
        } else {
            let searchText: string = null;
            if (currentFilter.searchText) {
                searchText = currentFilter.searchText.toLocaleLowerCase();
            }

            const templateFilter = currentFilter.getFilterOptionId(this.DocumentTemplateKey);
            const accessibilityFilter = currentFilter.getFilterOptionId(this.DocumentAccessibilityKey);
            const documentTypeFilter = currentFilter.getFilterOptionId(this.DocumentTypeKey);
            const fullySignedFilter = currentFilter.getFilterOptionId(this.FullySignedKey);

            this.filteredDocuments = this.allDocuments.filter(d =>
                (!searchText || d.templateName.toLocaleLowerCase().indexOf(searchText) > -1 || d.name.toLocaleLowerCase().indexOf(searchText) > -1) &&
                (!templateFilter || d.templateId === templateFilter) &&
                (!accessibilityFilter || (accessibilityFilter === '1' && d.isOwner) || (accessibilityFilter === '2' && d.isSignatory)) &&
                (!documentTypeFilter || d.documentType === parseInt(documentTypeFilter, 10)) &&
                (!fullySignedFilter || (fullySignedFilter === '0' && !d.fullySigned) || (fullySignedFilter === '1' && d.fullySigned)));

            this.isSignatoryFilteredDocuments = this.filteredDocuments.filter(d => d.isSignatory);
            this.notSignatoryFilteredDocuments = this.filteredDocuments.filter(d => !d.isSignatory);
        }
    }

    getAll(): void {
        this.store.dispatch(new fromDocumentLibrary.GetAllDocuments());
    }

    createDocument(): void {
        const dialog = this.dialogs.open(DocumentWizardDialogComponent, {
            disableClose: true
        });

        dialog.afterClosed().subscribe((created: boolean) => {
            if (created) {
                this.getAll();
            }
        });
    }

    downloadDocumentClick(document: DocumentLibraryItem): void {
        this.documentLibraryService.downloadDocument(document.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((response: Blob) => {
                const filename = `${document.name}.pdf`;
                this.fileSaverService.save(response, filename);
            });
    }

    editDocumentClick(document: DocumentLibraryItem): void {
        this.dialogs.open(DocumentDialogComponent, {
            data: document,
            disableClose: true
        });
    }

    signDocumentClick(document: DocumentLibraryItem): void {
        this.dialogs.open(DocumentDialogComponent, {
            data: document,
            disableClose: true
        });
    }

    deleteDocumentClick(document: DocumentLibraryItem): void {
        const confirmDialog = this.dialogs.open(ConfirmationRequiredDialogComponent, {
            data: {
                confirmationMessage: `Are you sure you want to delete the document '${document.name}'?`
            },
            disableClose: true
        });

        confirmDialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed === true) {
                this.store.dispatch(new fromDocumentLibrary.DeleteDocument(document.id));
            }
        });
    }

    hasData(): boolean {
        return this.allDocuments && this.allDocuments.length > 0;
    }

    private initPage(): void {
        const breadcrumbs: Array<Breadcrumb> = [];

        breadcrumbs.push(new Breadcrumb(Modules.ConnectPortal));
        breadcrumbs.push(new Breadcrumb(Modules.ConnectPortalDocumentsV1));

        this.page.setBreadcrumbs(breadcrumbs);
        this.page.setTitleFromModule(Modules.ConnectPortal);
    }

    private initializeFilter(): void {
        if (!this.allDocuments) {
            return;
        }

        this.configuration = new FilterConfiguration('Search documents...');

        const templateOption = this.configuration.addOption(this.DocumentTemplateKey);
        for (const document of this.allDocuments) {
            templateOption.addValue(document.templateId, document.templateName);
        }

        const typeOption = this.configuration.addOption(this.DocumentAccessibilityKey);
        typeOption.addValue('1', 'Owned by me');
        typeOption.addValue('2', 'As a signatory');

        const fullySignedOption = this.configuration.addOption(this.FullySignedKey);
        fullySignedOption.addValue('0', 'Not Fully Signed');
        fullySignedOption.addValue('1', 'Fully Signed');

        const documentTypeOption = this.configuration.addOption(this.DocumentTypeKey);
        documentTypeOption.addValue(DocumentType.Contract.toString(), getDocumentTypeLabel(DocumentType.Contract));
        documentTypeOption.addValue(DocumentType.ContractAgreement.toString(), getDocumentTypeLabel(DocumentType.ContractAgreement));
        documentTypeOption.addValue(DocumentType.GeneralConditions.toString(), getDocumentTypeLabel(DocumentType.GeneralConditions));
        documentTypeOption.addValue(DocumentType.SpecialConditions.toString(), getDocumentTypeLabel(DocumentType.SpecialConditions));
        documentTypeOption.addValue(DocumentType.GeneralPolicy.toString(), getDocumentTypeLabel(DocumentType.GeneralPolicy));
        documentTypeOption.addValue(DocumentType.HandbookGeneral.toString(), getDocumentTypeLabel(DocumentType.HandbookGeneral));
        documentTypeOption.addValue(DocumentType.StaffHandbook.toString(), getDocumentTypeLabel(DocumentType.StaffHandbook));
        documentTypeOption.addValue(DocumentType.HealthAndSafety.toString(), getDocumentTypeLabel(DocumentType.HealthAndSafety));
        documentTypeOption.addValue(DocumentType.NDA.toString(), getDocumentTypeLabel(DocumentType.NDA));
        documentTypeOption.addValue(DocumentType.PrivacyPolicy.toString(), getDocumentTypeLabel(DocumentType.PrivacyPolicy));
        documentTypeOption.addValue(DocumentType.StatementOfWork.toString(), getDocumentTypeLabel(DocumentType.StatementOfWork));
        documentTypeOption.addValue(DocumentType.SiteTerms.toString(), getDocumentTypeLabel(DocumentType.SiteTerms));
        documentTypeOption.addValue(DocumentType.ToolboxTalk.toString(), getDocumentTypeLabel(DocumentType.ToolboxTalk));
        documentTypeOption.addValue(DocumentType.Briefing.toString(), getDocumentTypeLabel(DocumentType.Briefing));
        documentTypeOption.addValue(DocumentType.RAM.toString(), getDocumentTypeLabel(DocumentType.RAM));
        documentTypeOption.addValue(DocumentType.Report.toString(), getDocumentTypeLabel(DocumentType.Report));

        this.page.setFilterConfiguration(this.configuration);

        this.search(null);
    }
}
