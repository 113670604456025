import { DisplayScreen, MobileDevice } from 'app/shared/enums';
import { AssessmentSetGroup } from 'app/shared/models/assessment-set-group.model';
import { ModuleDetails } from 'app/models/module-details.model';
import * as UserInterfaceActions from 'app/store/actions/user-interface.actions';

export type Action = UserInterfaceActions.AllUserInterfaceActions;

export interface State {
    displayScreenType: DisplayScreen;
    mobileDeviceType: MobileDevice;
    isMobileDevice: boolean;
    selectedApplication: ModuleDetails;
    assessmentSetDialogShown: boolean;
    firstTimeUserLoggedIn: boolean;
    rtwShown: boolean;
    currentBadgeId: string;
    assessmentSetGroups: AssessmentSetGroup[];
}

const initialState: State = {
    displayScreenType: null,
    mobileDeviceType: null,
    isMobileDevice: false,
    selectedApplication: null,
    assessmentSetDialogShown: false,
    firstTimeUserLoggedIn: false,
    rtwShown: false,
    currentBadgeId: null,
    assessmentSetGroups: null
};

export function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case UserInterfaceActions.SET_APPLICATION: {
            return {
                ...state,
                selectedApplication: action.payload
            };
        }

        case UserInterfaceActions.SET_DISPLAY_SCREEN_TYPE: {
            return Object.assign({}, state, {
                displayScreenType: action.payload
            });
        }

        case UserInterfaceActions.SET_MOBILE_DEVICE_TYPE: {
            return Object.assign({}, state, {
                mobileDeviceType: action.payload,
                isMobileDevice: action.payload === MobileDevice.Android || action.payload === MobileDevice.iOS
            });
        }

        case UserInterfaceActions.SET_ASSESSMENT_SET_SHOWN: {
            return {
                ...state,
                assessmentSetDialogShown: true
            };
        }

        case UserInterfaceActions.EMPTY_STATE: {
            return {
                ...state,
                assessmentSetDialogShown: false,
                rtwShown: false
            };
        }

        case UserInterfaceActions.SET_USER_LOGGED_IN_FIRST_TIME: {
            return {
                ...state,
                firstTimeUserLoggedIn: true
            };
        }

        case UserInterfaceActions.SET_USER_SEEN_INTRO: {
            return {
                ...state,
                firstTimeUserLoggedIn: false
            };
        }

        case UserInterfaceActions.SET_RTW_SET_SHOWN: {
            return {
                ...state,
                rtwShown: true
            };
        }

        case UserInterfaceActions.SET_CURRENT_BADGE_ID: {
            return {
                ...state,
                currentBadgeId: action.payload
            };
        }

        case UserInterfaceActions.GET_ASSESSMENT_SET_GROUPS_LIST_SUCCESS: {
            return {
                ...state,
                assessmentSetGroups: action.payload,
            };
        }

        case UserInterfaceActions.GET_ASSESSMENT_SET_GROUPS_LIST_FAIL: {
            return {
                ...state,
                assessmentSetGroups: null,
            };
        }

        default: {
            return state;
        }
    }
}
