import * as fromActions from 'app/portal/modules/document-library-v1/store/actions';

// Models
import { OrganisationUsers } from 'app/portal/modules/document-library-v1/models/organisation-users.model';
import { DocumentLibraryItem } from 'app/shared/modules/set-observations/models/document-library-item.model';

export interface DocumentLibraryState {
    documents: DocumentLibraryItem[];
    loading: boolean;
    preview: Blob;
    previewLoading: boolean;
    documentDownload: Blob;
    organisationUsers: OrganisationUsers[];
    pdf: Blob;
}

const initialState: DocumentLibraryState = {
    documents: null,
    loading: false,
    preview: null,
    previewLoading: false,
    documentDownload: null,
    organisationUsers: null,
    pdf: null
};

export function reducer(state = initialState, action: fromActions.AllDocumentsActions): DocumentLibraryState {
    switch (action.type) {
        case fromActions.GET_ALL_DOCUMENTS: {
            return {
                ...state,
                loading: true
            };
        }
        case fromActions.GET_ALL_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                documents: action.payload,
                loading: false
            };
        }
        case fromActions.GET_ALL_DOCUMENTS_FAIL: {
            return {
                ...state,
                documents: null,
                loading: false
            };
        }
        case fromActions.GENERATE_PREVIEW: {
            return {
                ...state,
                preview: null,
                previewLoading: true
            };
        }
        case fromActions.GENERATE_PREVIEW_SUCCESS: {
            return {
                ...state,
                preview: action.payload,
                previewLoading: false
            };
        }
        case fromActions.GENERATE_PREVIEW_FAIL: {
            return {
                ...state,
                preview: null,
                previewLoading: false
            };
        }
        case fromActions.DELETE_DOCUMENT_SUCCESS: {
            if (!state.documents) {
                return state;
            }

            return {
                ...state,
                documents: state.documents.filter(d => d.id !== action.payload)
            };
        }
        case fromActions.DOWNLOAD_DOCUMENT: {
            return {
                ...state,
                documentDownload: null
            };
        }
        case fromActions.DOWNLOAD_DOCUMENT_SUCCESS: {
            return {
                ...state,
                documentDownload: action.payload
            };
        }
        case fromActions.GET_ORGANISATION_USERS_SUCCESS: {
            if (!state.organisationUsers) {
                return {
                    ...state,
                    organisationUsers: [action.payload]
                };
            }

            const newOrganisationUsers = [...state.organisationUsers.filter(o => o.organisationId !== action.payload.organisationId), action.payload];
            return {
                ...state,
                organisationUsers: newOrganisationUsers
            };
        }
        case fromActions.GET_ESIGN_DOCUMENT: {
            return {
                ...state,
                pdf: null
            };
        }
        case fromActions.GET_ESIGN_DOCUMENT_SUCCESS: {
            return {
                ...state,
                pdf: action.payload
            };
        }
        case fromActions.GET_ESIGN_DOCUMENT_FAIL: {
            return {
                ...state,
                pdf: null
            };
        }
    }

    return state;
}
