import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/shared/base/base-component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

// Models
import { Activity } from 'app/connect-management/modules/users-shared/models/activity.model';
import { PagingService } from 'app/shared/services/paging.service';

@Component({
    selector: 'app-activity-log',
    templateUrl: './activity-log.component.html'
})

export class ActivityLogComponent extends BaseComponent implements OnInit {

    @Input()
    activities: Activity[];
    dataSource = new MatTableDataSource(null);
    displayedColumns: string[];

    @ViewChild('paginator', { static: true })
    paginator: MatPaginator;

    constructor(private pagingService: PagingService) {
        super();
    }

    ngOnInit() {
        this.displayedColumns = [ 'dateCreated', 'activity'];
        this.dataSource = new MatTableDataSource(this.activities);
        this.dataSource.paginator = this.paginator;
        setTimeout(() => {
            this.pagingService.loadPagingFromQuery(this.paginator);
        });
    }

    handlePaginatorChanged(pageEvent: PageEvent): void {
        this.pagingService.handlePaginatorChanged(pageEvent);
    }
}