import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileSaverModule } from 'ngx-filesaver';

// Store
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { effects, reducers } from './store';

// modules
import { SharedModule } from 'app/shared/shared.module';
import { DocumentLibraryRoutingModule } from './document-library-routing.module';
import { DocumentManagementModule } from 'app/portal/modules/document-management/document-management.module';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ConnectModule } from 'app/connect/connect.module';
import { SetObservationsModule } from 'app/shared/modules/set-observations/set-observations.module';

// components
import { DocumentLibraryIndexComponent } from './components/document-library/document-library.component';
import { DocumentWizardDialogComponent } from './components/document-wizard-dialog/document-wizard-dialog.component';
import { DocumentWizardStep1Component } from './components/document-wizard-dialog/steps/document-wizard-step-1/document-wizard-step-1.component';
import { DocumentWizardStep2Component } from './components/document-wizard-dialog/steps/document-wizard-step-2/document-wizard-step-2.component';
import { DocumentWizardStep3Component } from './components/document-wizard-dialog/steps/document-wizard-step-3/document-wizard-step-3.component';
import { DocumentWizardStep4Component } from './components/document-wizard-dialog/steps/document-wizard-step-4/document-wizard-step-4.component';
import { DocumentWizardStep5Component } from './components/document-wizard-dialog/steps/document-wizard-step-5/document-wizard-step-5.component';
import { DocumentDialogComponent } from './components/document-dialog/document-dialog.component';

// services
import { DocumentLibraryService } from './services';
import { OrganisationDocumentService } from './services/organisation-document.service';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        DocumentLibraryRoutingModule,
        FileSaverModule,
        EffectsModule.forFeature(effects),
        StoreModule.forFeature(StoreNames.documentLibrary, reducers),
        DocumentManagementModule,
        PdfJsViewerModule,
        ConnectModule,
        SetObservationsModule
    ],
    declarations: [
        DocumentLibraryIndexComponent,
        DocumentWizardDialogComponent,
        DocumentWizardStep1Component,
        DocumentWizardStep2Component,
        DocumentWizardStep3Component,
        DocumentWizardStep4Component,
        DocumentWizardStep5Component,
        DocumentDialogComponent
    ],
    providers: [
        OrganisationDocumentService,
        DocumentLibraryService
    ],
    entryComponents: [
        DocumentLibraryIndexComponent,
        DocumentWizardDialogComponent,
        DocumentDialogComponent
    ]
})
export class DocumentLibraryModule {}
