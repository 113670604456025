import { ActiveUsersEffects } from './active-users.effects';
import { RouterEffects } from './router.effects';
import { OrganisationEffects } from './organisation.effects';
import { UserActivityEffects } from './user-activity.effects';
import { UserInterfaceEffects } from './user-interface.effects';

export const effects: any[] = [
    ActiveUsersEffects,
    RouterEffects,
    OrganisationEffects,
    UserActivityEffects,
    UserInterfaceEffects
];