import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-idv-document-rejected-step',
    templateUrl: './document-rejected-step.component.html',
    styleUrls: ['./document-rejected-step.component.scss']
})
export class DocumentRejectedStepComponent {

    @Output()
    retakeClicked: EventEmitter<void> = new EventEmitter();

    handleRetakePhotoClick(): void {
        this.retakeClicked.emit();
    }
}