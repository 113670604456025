// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { Branding } from 'app/models/branding.model';

export const SetBranding = createAction(
    '[Connect Branding] Set branding',
    props<{branding: Branding}>()
);

export const ClearBranding = createAction(
    '[Connect Branding] Clear branding'
);

export const SetVersion = createAction(
    '[Connect Branding] Set version',
    props<{isConnect: boolean}>()
);

export const GetDefaultBranding = createAction(
    '[Connect Branding] Get default branding'
);

export const GetDefaultBrandingSuccess = createAction(
    '[Connect Branding] Get default branding success',
    props<{branding: Branding}>()
);

export const GetDefaultBrandingFail = createAction(
    '[Connect Branding] Get default branding fail'
);