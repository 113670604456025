import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// rxjs / ngrx
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

// Store
import * as fromSetsStore from 'app/shared/modules/set-observations/store';
import * as fromUserInterface from 'app/store/actions/user-interface.actions';

// Models
import { getIncompleteBadgeSets } from 'app/shared/modules/set-observations/models/responses/assessment-profile-response.model';
import { AssessmentProfileBadgeResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-badge-response.model';
import { AssessmentProfileSetResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-set-response.model';

// Components
import { AssessmentSetDialogComponent } from 'app/shared/modules/set-observations/components/assessment-set-dialog/assessment-set-dialog.component';

@Component({
    selector: 'app-badge-profile',
    templateUrl: './badge-profile.component.html',
    styleUrls: ['./badge-profile.component.scss']
})
export class BadgeProfileComponent implements OnInit {

    @Input()
    entityName: string;

    @Input()
    badge: AssessmentProfileBadgeResponse;

    @Input()
    iconSource: string;

    @Input()
    readonly: boolean = true;

    @Output()
    assessmentSetsModified: EventEmitter<void> = new EventEmitter();

    badgesLoading$: Observable<boolean>;

    constructor(
        private store: Store<fromSetsStore.SetObservationsState>,
        private dialogs: MatDialog) {
    }

    ngOnInit(): void {
        this.badgesLoading$ = this.store.select(fromSetsStore.getAssessmentProfileLoading);
    }

    onShowAssessmentSet(assessmentProfileSet: AssessmentProfileSetResponse) {
        this.showSetsDialog([assessmentProfileSet]);
    }

    showSetsDialog(sets: AssessmentProfileSetResponse[]) {
        const dialog = this.dialogs.open(AssessmentSetDialogComponent, {
            autoFocus: false,
            data: {
                entityName: this.entityName,
                ownerTitle: this.badge.title,
                sets
            },
            disableClose: true
        });

        dialog.afterClosed().subscribe(() => {
            this.assessmentSetsModified.emit();
        });
    }

    handleCompleteNowClicked(): void {
        this.store.dispatch(new fromUserInterface.SetCurrentBadgeId(this.badge.id));
        this.showSetsDialog(getIncompleteBadgeSets(this.badge));
    }

    get label(): string {
        return this.readonly ?
            `${this.badge.ownerOrganisationName} ${this.badge.title} badge` :
            `Your ${this.badge.title} badge`;
    }
}