import { Action } from '@ngrx/store';

// Models
import { ResendInviteEmailRequest } from 'app/shared/modules/group/models/resend-invite-email-request.model';
import { CreateEntityRequest } from 'app/portal/modules/group-list/models/create-entity-request.model';
import { SmsTemplateExistance } from 'app/shared/modules/group/models/sms-template-existance.model';
import { TelephoneNumber } from 'app/connect-management/modules/telephone-numbers-v1/models/telephone-number.model';

// Action types
export const RESEND_EMAIL_INVITE = '[Group Invites] Resend invite email';
export const RESEND_EMAIL_INVITE_SUCCESS = '[Group Invites] Resend invite email success';
export const RESEND_EMAIL_INVITE_FAIL = '[Group Invites] Resend invite email fail';

export const CREATE_ENTITY = '[V1 Portal] Create Entity';
export const CREATE_ENTITY_SUCCESS = '[V1 Portal] Create Entity Success';
export const CREATE_ENTITY_FAIL = '[V1 Portal] Create Entity Fail';

export const CAN_CONTACT_VIA_SMS = '[V1 Portal] Can Contact Via SMS';
export const CAN_CONTACT_VIA_SMS_SUCCESS = '[V1 Portal] Can Contact Via SMS Success';
export const CAN_CONTACT_VIA_SMS_FAIL = '[V1 Portal] Can Contact Via SMS Fail';

export const GET_ACTIVE_TELEPHONE_NUMBERS = '[V1 Portal] Get Active Telephone Numbers';
export const GET_ACTIVE_TELEPHONE_NUMBERS_SUCCESS = '[V1 Portal] Get Active Telephone Numbers Success';
export const GET_ACTIVE_TELEPHONE_NUMBERS_FAIL = '[V1 Portal] Get Active Telephone Numbers Fail';

// Action initialisers
export class ResendInviteEmail implements Action {
    readonly type = RESEND_EMAIL_INVITE;
    constructor(public payload: ResendInviteEmailRequest) { }
}

export class ResendInviteEmailSuccess implements Action {
    readonly type = RESEND_EMAIL_INVITE_SUCCESS;
}

export class ResendInviteEmailFail implements Action {
    readonly type = RESEND_EMAIL_INVITE_FAIL;
}

export class CreateEntity implements Action {
    readonly type = CREATE_ENTITY;
    constructor(public payload: CreateEntityRequest) { }
}

export class CreateEntitySuccess implements Action {
    readonly type = CREATE_ENTITY_SUCCESS;
    constructor(public entityId: string) { }
}

export class CreateEntityFail implements Action {
    readonly type = CREATE_ENTITY_FAIL;
}

export class CanContactViaSms implements Action {
    readonly type = CAN_CONTACT_VIA_SMS;
}

export class CanContactViaSmsSuccess implements Action {
    readonly type = CAN_CONTACT_VIA_SMS_SUCCESS;
    constructor(public payload: SmsTemplateExistance) { }
}

export class CanContactViaSmsFail implements Action {
    readonly type = CAN_CONTACT_VIA_SMS_FAIL;
}

export class GetActiveTelephoneNumbers implements Action {
    readonly type = GET_ACTIVE_TELEPHONE_NUMBERS;
}

export class GetActiveTelephoneNumbersSuccess implements Action {
    readonly type = GET_ACTIVE_TELEPHONE_NUMBERS_SUCCESS;
    constructor(public payload: TelephoneNumber[]) { }
}

export class GetActiveTelephoneNumbersFail implements Action {
    readonly type = GET_ACTIVE_TELEPHONE_NUMBERS_FAIL;
}

export type AllGroupInviteActions
    = ResendInviteEmail
    | ResendInviteEmailSuccess
    | ResendInviteEmailFail
    | CreateEntity
    | CreateEntitySuccess
    | CreateEntityFail
    | CanContactViaSms
    | CanContactViaSmsSuccess
    | CanContactViaSmsFail
    | GetActiveTelephoneNumbers
    | GetActiveTelephoneNumbersSuccess
    | GetActiveTelephoneNumbersFail;