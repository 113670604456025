<div fxFlex="1 1 auto" class="dialog-container text"
    [ngStyle.gt-sm]="{ 'max-width': '100%', 'max-height': '100%' }"
    [ngStyle.lt-sm]="{ 'max-width': '100%', 'max-height': '100%', 'width': '100%', 'height': '100%' }"
    [ngClass]="{ 'center-align': centerAlign }">

    <div *ngIf="!borderless" fxLayout="column">
        <div  fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class="dialog-header table-and-tab-bg table-and-tab-text">
            <h3 fxFlex class="dialog-header-title">
                {{dialogTitle ? dialogTitle : ' '}}
            </h3>
            <button *ngIf="handleCloseEvent || showClose" mat-icon-button class="dialog-close" (click)="onCloseClick()">
                <mat-icon aria-label="close dialog">close</mat-icon>
            </button>
        </div>
        <ng-content select="[sub-header]"></ng-content>
    </div>

    <div fxFlex="1 1 auto" fxLayout="column" class="dialog-content" [ngClass]="{ 'no-footer': !showFooterActions, 'borderless': borderless }">
        <ng-content select="[body]"></ng-content>
        <p *ngIf="dialogSubTitle">{{ dialogSubTitle }}</p>
    </div>

    <div fxFlex="nogrow" *ngIf="showFooterActions" fxLayoutAlign="end center" fxLayoutGap="10px">
        <ng-content select="[footer-actions]" fxLayoutAlign="end center" fxLayoutGap="10px"></ng-content>
    </div>
</div>