import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LegalPolicyDialog } from 'app/shared/enums/legal-policy-dialog.enum';
import { UsageDialogComponent } from 'app/shared/components/usage-dialog/usage-dialog.component';
import { PrivacyPolicyDialogComponent } from 'app/shared/components/privacy-policy-dialog/privacy-policy-dialog.component';
import { TermsConditionsDialogComponent } from 'app/shared/components/terms-conditions-dialog/terms-conditions-dialog.component';

@Injectable()
export class LegalDialogsService {

    dialogRef: MatDialogRef<any>;

    constructor(private dialogs: MatDialog) { }

    openTerms(): void {
        this.dialogRef = this.dialogs.open(TermsConditionsDialogComponent, { disableClose: true });
        this.waitForCloseAction();
    }

    openPrivacy(): void {
        this.dialogRef = this.dialogs.open(PrivacyPolicyDialogComponent, { disableClose: true });
        this.waitForCloseAction();
    }

    openUsage(): void {
        this.dialogRef = this.dialogs.open(UsageDialogComponent, { disableClose: true });
        this.waitForCloseAction();
    }

    private waitForCloseAction(): void {
        this.dialogRef.afterClosed().subscribe((dialogToOpen: LegalPolicyDialog) => {

            switch (dialogToOpen) {
                case LegalPolicyDialog.PrivacyPolicy: this.openPrivacy(); break;
                case LegalPolicyDialog.TermsAndConditions: this.openTerms(); break;
                case LegalPolicyDialog.Usage: this.openUsage(); break;
                default: return;
            }
        });
    }
}