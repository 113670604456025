import { Pipe, PipeTransform } from '@angular/core';
import { SignatureType } from 'app/portal/modules/document-management/enums';

@Pipe({ name: 'signatureTypeDescription' })
export class SignatureTypeDescriptionPipe implements PipeTransform {
    transform(type: SignatureType): string {
        switch (type) {
            case SignatureType.NoSigning:
                return 'No Signature';
            case SignatureType.SingleSign:
                return 'Single Signature';
            case SignatureType.FullESign:
                return 'Full eSignature';
            default:
                return 'Unknown';
        }
    }
}