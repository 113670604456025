import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Models
import { OrganisationInitialisation } from 'app/shared/models/organisation-initialisation.model';
import { AssessmentSetGroup } from 'app/shared/models/assessment-set-group.model';
import { UpdateGeneralInformationRequest } from 'app/shared/models/update-general-information-request.model';
import { RemoveBadgeRequest } from 'app/shared/models/remove-badge-request.model';
import { Tag } from 'app/shared/models/entities/tag.model';
import { AddOrganisationTagRequest } from 'app/shared/models/add-organisation-tag-request.model';
import { AssessmentProfileTagResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-tag-response.model';

@Injectable()
export class OrganisationsService {
    private baseEndpoint = `api/organisations`;

    constructor(
        private http: HttpClient) { }

    getAssessmentSets(organisationId: string): Observable<AssessmentSetGroup[]> {
        const url = `${this.baseEndpoint}/${organisationId}/assessmentsets`;
        return this.http.get<AssessmentSetGroup[]>(url);
    }

    getAvailableTags(organisationId: string): Observable<Tag[]> {
        const url = `${this.baseEndpoint}/${organisationId}/available-tags`;
        return this.http.get<Tag[]>(url);
    }

    addOrganisationTag(request: AddOrganisationTagRequest): Observable<AssessmentProfileTagResponse> {
        const url = `${this.baseEndpoint}/organisation-tag`;
        return this.http.post<AssessmentProfileTagResponse>(url, request);
    }

    deleteOrganisationTag(organisationId: string, organisationTagId: string): Observable<void> {
        const url = `${this.baseEndpoint}/${organisationId}/organisation-tag/${organisationTagId}`;
        return this.http.delete<void>(url);
    }


    initialise(organisationId: string): Observable<OrganisationInitialisation> {
        const url = `${this.baseEndpoint}/${organisationId}/initialise`;
        return this.http.get<OrganisationInitialisation>(url);
    }

    updateGeneralInformation(request: UpdateGeneralInformationRequest): Observable<void> {
        const url = `${this.baseEndpoint}/${request.id}/general-information`;

        return this.http.put<void>(url, request);
    }

    getIdDocumentUrl(organisationId: string, idDocumentId: string): Observable<string> {
        const url = `${this.baseEndpoint}/${organisationId}/id-documents/${idDocumentId}`;
        return this.http.get<string>(url);
    }

    removeBadge(request: RemoveBadgeRequest): Observable<void> {
        const url = `${this.baseEndpoint}/${request.organisationId}/badges/${request.badgeId}`;
        return this.http.delete<void>(url);
    }
}