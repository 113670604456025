<div class="idv-uap-step" fxLayout="column"fxLayoutGap="32px">
    <div fxLayout="column" fxLayoutGap="32px" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start top">
        <div class="text align-center">
            <img [src]="uapImageUrl | safe: 'resourceUrl'"/>
        </div>
        <div fxLayout="column" fxLayoutGap="16px">
            <div class="text headline">Take another selfie</div>
            <div class="text sub-title">Now take another selfie copying the action shown.</div>
            <div fxLayout="row" fxLayoutAlign="start top" fxLayoutGap="16px">
                <mat-icon class="secondary-text">circle</mat-icon>
                <div class="text body">Take a clear, in-focus selfie against a plain background.</div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start top" fxLayoutGap="16px">
                <mat-icon class="secondary-text">circle</mat-icon>
                <div class="text body">You must be live and present when taking your selfie (no photos of photos).</div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start top" fxLayoutGap="16px">
                <mat-icon class="secondary-text">circle</mat-icon>
                <div class="text body">Remove any glasses or hats if you're wearing them.</div>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutGap="16px">
        <button *ngIf="(isMobileDevice$ | async) === false" mat-raised-button  (click)="handleTakePhotoClick()">Take Photo</button>
        <button *ngIf="allowSwitchToMobile && (isMobileDevice$ | async) === false" mat-raised-button  (click)="handleSwitchToMobileClick()">Switch to mobile</button>
        <button *ngIf="isMobileDevice$ | async" mat-raised-button  (click)="mobileCapture.click()">Take Photo</button>
    </div>
    <input type="file" #mobileCapture [hidden]="true" (change)="handleFileUploaded($event)" capture="user" accept="image/*">
</div>
