import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromRoot from 'app/store';

// components
import { TakePhotoDialogComponent } from 'app/shared/modules/identity-verification/components/take-photo-dialog/take-photo-dialog.component';

// services
import { ImageUploadService } from 'app/shared/services';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// models
import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';
import { IdentityVerificationConfiguration } from 'app/shared/modules/identity-verification/models/identity-verification-configuration.model';

// enums
import { IdDocumentType, IdDocumentTypes } from 'app/shared/enums/id-document-type.enum';

@Component({
    selector: 'app-idv-id-document-step',
    templateUrl: './id-document-step.component.html',
    styleUrls: ['./id-document-step.component.scss']
})
export class IdDocumentStepComponent implements OnInit {

    @Output()
    idDocumentTypeSelected: EventEmitter<IdDocumentType> = new EventEmitter();

    @Output()
    idDocumentTaken: EventEmitter<string> = new EventEmitter();

    @Output()
    switchToMobile: EventEmitter<void> = new EventEmitter();

    @Input()
    configuration: IdentityVerificationConfiguration;

    @ViewChild('upload') uploadElement: ElementRef;

    isMobileDevice$: Observable<boolean>;
    IdDocumentType = IdDocumentType;
    idDocuments: EnumValueTitle<IdDocumentType>[];
    selectedDocumentType = false;
    allowSwitchToMobile = false;

    constructor(
        rootStore: Store<fromRoot.State>,
        private imageUploadService: ImageUploadService,
        private authTokenService: AuthenticationTokenService,
        private dialogs: MatDialog) {
            this.isMobileDevice$ = rootStore.select(fromRoot.isMobileDevice);
            this.allowSwitchToMobile = !this.authTokenService.isClient();
        }

    ngOnInit(): void {
        this.idDocuments = IdDocumentTypes.filter(i => this.configuration.idDocumentTypes.includes(i.value));
    }

    handleIdDocumentTypeSelected(idDocumentType: IdDocumentType): void {
        this.selectedDocumentType = true;
        this.idDocumentTypeSelected.emit(idDocumentType);
    }

    handleFileUploaded(event: any): void {
        if (event.target.files && event.target.files[0]) {

            const file: File = event.target.files[0];
            this.imageUploadService.getBase64(file).then((base64Image: string) => {
                this.idDocumentTaken.emit(base64Image);
            });
        }

        this.uploadElement.nativeElement.value = null;
    }

    handleTakePhotoClick(): void {
        const dialog = this.dialogs.open(TakePhotoDialogComponent, { disableClose: true });
        dialog.afterClosed().subscribe((base64Image: string) => {
            if (base64Image) {
                this.idDocumentTaken.emit(base64Image);
            }
        });
    }

    handleSwitchToMobileClick(): void {
        this.switchToMobile.emit();
    }
}