export enum DocumentType {
    Contract = 1,
    ContractAgreement = 2,
    GeneralConditions = 3,
    SpecialConditions = 4,
    GeneralPolicy = 5,
    HandbookGeneral = 6,
    StaffHandbook = 7,
    HealthAndSafety = 8,
    NDA = 9,
    PrivacyPolicy = 10,
    StatementOfWork = 11,
    SiteTerms = 12,
    ToolboxTalk = 13,
    Briefing = 14,
    RAM = 15,
    Report = 16
}

export function getDocumentTypeLabel(value: DocumentType): string {
    switch (value) {
        case DocumentType.Contract:
            return 'Contract';
        case DocumentType.ContractAgreement:
            return 'Contract agreement';
        case DocumentType.GeneralConditions:
            return 'General conditions';
        case DocumentType.SpecialConditions:
            return 'Special conditions';
        case DocumentType.GeneralPolicy:
            return 'General policy';
        case DocumentType.HandbookGeneral:
            return 'Handbook general';
        case DocumentType.StaffHandbook:
            return 'Staff handbook';
        case DocumentType.HealthAndSafety:
            return 'Health and safety policy';
        case DocumentType.NDA:
            return 'NDA';
        case DocumentType.PrivacyPolicy:
            return 'Privacy policy';
        case DocumentType.StatementOfWork:
            return 'Statement of work';
        case DocumentType.SiteTerms:
            return 'Site terms';
        case DocumentType.ToolboxTalk:
            return 'Toolbox talk';
        case DocumentType.Briefing:
            return 'Briefing';
        case DocumentType.RAM:
            return 'RAM';
        case DocumentType.Report:
            return 'Report';
        default:
            return 'Unknown document type';
    }
}