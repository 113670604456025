import * as ActiveUserActions from 'app/store/actions/active-user.actions';

export type Action = ActiveUserActions.AllActiveUserActions;

export interface State {
    organisationAssessmentSets: any[];
    inviteId: string;
    isClientUser: boolean;
    selectedClientSet: boolean;
}

const initialState: State = {
    organisationAssessmentSets: null,
    inviteId: null,
    isClientUser: true,
    selectedClientSet: false
};

export function reducer(state = initialState, action: Action): State {
    switch (action.type) {

        case ActiveUserActions.GET_SELECTED_ORGANISATION_ASSESSMENTS_SUCCESS: {
            return {
                ...state,
                organisationAssessmentSets: action.payload
            };
        }

        case ActiveUserActions.EMPTY_STATE: {
            return {
                ...state,
                organisationAssessmentSets: null
            };
        }

        case ActiveUserActions.GET_USER_INVITE_ID: {
            return {
                ...state,
                inviteId: null
            };
        }

        case ActiveUserActions.GET_USER_INVITE_ID_SUCCESS: {
            return {
                ...state,
                inviteId: action.payload
            };
        }

        case ActiveUserActions.GET_USER_INVITE_ID_FAIL: {
            return {
                ...state,
                inviteId: null
            };
        }

        case ActiveUserActions.SET_SELECTED_CLIENT_FAIL:
        case ActiveUserActions.SET_SELECTED_CLIENT: {
            return {
                ...state,
                selectedClientSet: false
            };
        }

        case ActiveUserActions.SET_SELECTED_CLIENT_SUCCESS: {
            return {
                ...state,
                selectedClientSet: true
            };
        }

        default: {
            return state;
        }
    }
}
