import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/shared/modules/group/store/reducers';
import * as fromGroupInvite from 'app/shared/modules/group/store/reducers/group-invite.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getGroupsState = createFeatureSelector<fromFeature.GroupsState>(StoreNames.groups);
const getProjectInviteState = createSelector(getGroupsState, (state: fromFeature.GroupsState) => state.groupInviteState);

export const getInvitedOrganisations = createSelector(getProjectInviteState, (state: fromGroupInvite.GroupInviteState) => state.invitedOrganisations);
export const getInvitingEntity = createSelector(getProjectInviteState, (state: fromGroupInvite.GroupInviteState) => state.invitingEntity);
export const getCreatingEntity = createSelector(getProjectInviteState, (state: fromGroupInvite.GroupInviteState) => state.creatingEntity);
export const getCanContactViaSms = createSelector(getProjectInviteState, (state: fromGroupInvite.GroupInviteState) => state.canContactViaSms);
export const getTelephoneNumbers = createSelector(getProjectInviteState, (state: fromGroupInvite.GroupInviteState) => state.telephoneNumbers);