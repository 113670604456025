import { Component, OnInit } from '@angular/core';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromConnect from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { Breadcrumb } from 'app/models/breadcrumb.model';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends BaseComponent implements OnInit {

    breadcrumbs$: Observable<Breadcrumb[]>;

    constructor(private store: Store<fromConnect.ConnectStoreState>) {
        super();
    }

    ngOnInit() {
        this.breadcrumbs$ = this.store.select(fromConnect.getBreadcrumbs);
    }
}
