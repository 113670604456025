import { ObservationRequest } from './observation-request.model';

export class RecordObservationsRequest {
    setId: string;
    ownerId: string;
    groupId: string;
    observations: ObservationRequest[];

    constructor(setId: string, ownerId: string, groupId: string, observations: ObservationRequest[]) {
        this.setId = setId;
        this.ownerId = ownerId;
        this.groupId = groupId;
        this.observations = observations;
    }
}