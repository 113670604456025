import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'app-circle-progress',
    templateUrl: './circle-progress.component.html',
    styleUrls: ['./circle-progress.component.scss']
})
export class CircleProgressComponent implements OnInit, OnChanges {
    @Input()
    progress = 50;

    @Input()
    size = 168;

    @Input()
    lineWidth = 32;

    circleSvg: string;

    ngOnInit(): void {
        this.generateCircleSvg();
    }

    ngOnChanges(): void {
        this.generateCircleSvg();
    }

    private generateCircleSvg() {
        const radius = (this.size - this.lineWidth) / 2;
        const totalLine = Math.round(radius * 2 * Math.PI);
        let offset = Math.round(totalLine * (100 - this.progress) / 100);

        if (totalLine - offset > this.lineWidth && offset > 0) {
            offset += this.lineWidth / 2;
        }

        this.circleSvg = `
            <svg preserveAspectRatio="xMidYMid meet" focusable="false" aria-hidden="true" viewBox="0 0 ${this.size} ${this.size}" style="width: ${this.size}px; height: ${this.size}px;">

                <circle cx="50%" cy="50%" r="${radius}"
                    class="svg-stroke-primary"
                    style="stroke-linecap: round; stroke-dashoffset: ${offset}px; stroke-dasharray: ${totalLine}px; stroke-width: ${this.lineWidth}px; fill: transparent; transform-origin: center; transform: rotate(90deg);">
                </circle>
                <circle cx="50%" cy="50%" r="${radius}"
                    class="svg-stroke-primary"
                    style="stroke-linecap: round; stroke-dashoffset: 0px; stroke-dasharray: ${totalLine}px; stroke-width: ${this.lineWidth}px; opacity: 0.25; fill: transparent; transform-origin: center;">
                </circle>
            </svg>`;
    }
}
