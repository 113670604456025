import { Component, Input, HostListener } from '@angular/core';

@Component({
    selector: 'app-dialog-field-information',
    templateUrl: './dialog-field-information.component.html',
    styleUrls: ['./dialog-field-information.component.scss']
})
export class DialogFieldInformationComponent {

    @Input()
    public description: string;

    @Input()
    public title: string;

    showDescription: boolean = false;
    wasInside: boolean = false;

    constructor() { }

    @HostListener('click')
    clickInside() {
        this.wasInside = true;
    }

    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.showDescription = false;
        }
        this.wasInside = false;
    }
}