import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

// Models
import { Template, TemplateResult } from 'app/portal/modules/document-management/models';
import { EnabledTemplateSummary } from 'app/portal/modules/document-management/models/enabled-template-summary.model';
import { GeneratePdfPreviewRequest } from 'app/portal/modules/document-management/models/generate-pdf-preview-request.model';
import { ShareTemplateRequest } from 'app/portal/modules/document-management/models/share-template-request.model';
import { environment } from 'environments/environment';
import { setTimeoutHeader } from 'app/shared/utilities/request-utilities';
import { map } from 'rxjs/operators';

@Injectable()
export class TemplateService {
    private baseEndpoint = 'api/templates';

    constructor(
        private http: HttpClient) {}

    public addTemplate(data: FormData) {
        return this.http.post(this.baseEndpoint, data);
    }

    public updateTemplate(data: FormData) {
        return this.http.put(this.baseEndpoint, data);
    }

    public getTemplates(): Observable<Template[]> {
        return this.http.get<Template[]>(this.baseEndpoint);
    }

    public getEnabledTemplates(): Observable<EnabledTemplateSummary[]> {
        const url = `${this.baseEndpoint}/enabled`;
        return this.http.get<EnabledTemplateSummary[]>(url);
    }

    public getPreview(templateId: string, organisationIds: string[]): Observable<Blob> {
        const url = `${this.baseEndpoint}/${templateId}/preview`;
        return this.http.post<Blob>(url, organisationIds, { responseType: 'blob' as 'json', observe: 'response', headers: { timeout: environment.createSetsTimeout }  }).pipe(map((response: HttpResponse<Blob>) => response.body));
    }

    public getHighlightedPreview(templateId: string): Observable<TemplateResult> {
        const url = `${this.baseEndpoint}/${templateId}/highlightedpreview`;
        return this.http.get<TemplateResult>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public deleteDocument(templateId: string): Observable<string> {
        const url = `${this.baseEndpoint}/${templateId}`;
        return this.http.delete<string>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getDocumentPreview(generateRequest: GeneratePdfPreviewRequest): Observable<string> {
        const url = `${this.baseEndpoint}/generate-preview`;
        return this.http.post<string>(url, generateRequest, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getDocumentMarkup(data: FormData): Observable<string> {
        const url = `${this.baseEndpoint}/get-markup`;
        return this.http.post<string>(url, data, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getTemplateDocumentMarkup(templateId: string): Observable<string> {
        const url = `${this.baseEndpoint}/get-markup/${templateId}`;
        return this.http.get<string>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public addShare(request: ShareTemplateRequest): Observable<void> {
        const url = `${this.baseEndpoint}/${request.templateId}/sharing/${request.organisation.id}`;
        return this.http.put<void>(url, null);
    }

    public removeShare(request: ShareTemplateRequest): Observable<void> {
        const url = `${this.baseEndpoint}/${request.templateId}/sharing/${request.organisation.id}`;
        return this.http.delete<void>(url);
    }

    public getPdf(templateId: string): Observable<Blob> {
        const url = `${this.baseEndpoint}/get-pdf/${templateId}`;

        return this.http.get<Blob>(url, { responseType: 'blob' as 'json', observe: 'response' , headers: { timeout: environment.createSetsTimeout } }).pipe(map((response: HttpResponse<Blob>) => response.body));
    }
}