import { Injectable } from '@angular/core';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

// store
import * as ZohoExportActions from 'app/shared/modules/zoho-export/store/actions/zoho-export.actions';

// services
import { ZohoExportApiService } from 'app/shared/modules/zoho-export/services/zoho-export-api.service';

@Injectable()
export class ZohoExportEffects {


    startExport$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ZohoExportActions.START_EXPORT),
        switchMap((action: ZohoExportActions.StartExport) => this.zohoExportApiService.startExport(action.entityId, action.processId).pipe(
            map(() => new ZohoExportActions.StartExportSuccess()),
            catchError(() => of(new ZohoExportActions.StartExportFail()))
        ))));


    exportFile$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ZohoExportActions.EXPORT_FILE),
        map((action: ZohoExportActions.ExportFile) => action.payload),
        switchMap(file => this.zohoExportApiService.exportFile(file).pipe(
            map(() => new ZohoExportActions.ExportFileSuccess(file.observationId)),
            catchError(() => of(new ZohoExportActions.ExportFileFail(file.observationId)))
        ))));


    markExportSuccessful$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ZohoExportActions.MARK_EXPORT_SUCCESSFUL),
        switchMap((action: ZohoExportActions.MarkExportSuccessful) => this.zohoExportApiService.markExportSuccess(action.entityId, action.processId).pipe(
            map(() => new ZohoExportActions.MarkExportSuccessfulSuccess()),
            catchError(() => of(new ZohoExportActions.MarkExportSuccessfulFail()))
        ))));


    markExportFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ZohoExportActions.MARK_EXPORT_FAILED),
        switchMap((action: ZohoExportActions.MarkExportFailed) => this.zohoExportApiService.markExportFail(action.entityId, action.processId).pipe(
            map(() => new ZohoExportActions.MarkExportFailedSuccess()),
            catchError(() => of(new ZohoExportActions.MarkExportFailedFail()))
        ))));

    constructor(
        private actions$: Actions,
        private zohoExportApiService: ZohoExportApiService
    ) { }
}