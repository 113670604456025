import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// ngrx
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

// Store
import * as fromRoot from 'app/store';

// Components
import { IncompleteMandatoryBadgesDialogComponent } from 'app/shared/modules/set-observations/components/incomplete-mandatory-badges-dialog/incomplete-mandatory-badges-dialog.component';
import { BadgeProfileDialogComponent } from 'app/shared/modules/set-observations/components/badge-profile-dialog/badge-profile-dialog.component';

// Models
import { AssessmentProfileResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-response.model';
import { AssessmentProfileBadgeResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-badge-response.model';
import { IncompleteBadgeDialogData } from 'app/shared/modules/set-observations/models/incomplete-badge-dialog-data.model';
import { BadgeProfileDialogData } from 'app/shared/modules/set-observations/models/badge-profile-dialog-data.model';

// Enums
import { BadgeStatus } from 'app/shared/enums/accreditation/badge-status.enum';

@Injectable()
export class IncompleteBadgeDialogService {
    badgeWizardDialog: MatDialogRef<IncompleteMandatoryBadgesDialogComponent>;
    badgeProfileDialog: MatDialogRef<BadgeProfileDialogComponent>;
    badges: AssessmentProfileBadgeResponse[];
    badgeIdUrl: string;
    entityName: string;

    constructor(
        private rootStore: Store<fromRoot.State>,
        private dialogs: MatDialog,
        private activatedRoute: ActivatedRoute) {
        this.rootStore.pipe(
            take(1),
            select(fromRoot.getRTWShown))
            .subscribe((hasBeenShown: boolean) => {
                this.activatedRoute.queryParams.subscribe(params => {
                    if (params.result && params.badgeId && !hasBeenShown) {
                        this.badgeIdUrl = params.badgeId;
                    }
                });
            });
    }

    showBadgeWizardDialog(profile: AssessmentProfileResponse, ignoreState: boolean = false): void {
        if (profile &&
            ((!this.badgeWizardDialog && !this.badgeProfileDialog) || ignoreState)) {

            this.rootStore.pipe(
                take(1),
                select(fromRoot.getAssessmentSetDialogShown))
                .subscribe((hasBeenShown: boolean) => {
                    this.entityName = profile.title;
                    this.badges = profile.badges.filter(b => b.mandatory && b.status !== BadgeStatus.Complete);
                    if (this.badgeIdUrl) {
                        this.badges = profile.badges.filter(b => b.id === this.badgeIdUrl);
                    }
                    if (!hasBeenShown) {
                        this.displayDialog(false);
                    }
                });
        }
    }

    showBadgeDialog(entityName: string, badge: AssessmentProfileBadgeResponse): MatDialogRef<any> {
        this.entityName = entityName;
        if (badge) {
            this.badges = [badge ];
            return this.displayDialog(true);
        }

        return null;
    }

    closeBadgeDialog(): void {
        if (this.badgeProfileDialog) {
            this.badgeProfileDialog.close();
            this.badgeProfileDialog = null;
        }

        if (this.badgeWizardDialog) {
            this.badgeWizardDialog.close();
            this.badgeWizardDialog = null;
        }
    }

    private displayDialog(ignoreStatus: boolean): MatDialogRef<any> {
        if (this.badges.length) {
            const firstBadge = this.badges[0];
            if (firstBadge.openWizard) {
                this.badgeWizardDialog = this.dialogs.open(IncompleteMandatoryBadgesDialogComponent, {
                    width: '824px',
                    autoFocus: false,
                    data: new IncompleteBadgeDialogData(this.badges, this.entityName, ignoreStatus)
                });

                return this.badgeWizardDialog;
            } else {
                this.badgeProfileDialog = this.dialogs.open(BadgeProfileDialogComponent, {
                    autoFocus: false,
                    data: new BadgeProfileDialogData(
                        firstBadge,
                        firstBadge.ownerOrganisationId,
                        false,
                        this.entityName
                    )
                });

                return this.badgeProfileDialog;
            }
        }

        return null;
    }
}