// NGRX
import { Action } from '@ngrx/store';

// Models
import { SetResponse } from 'app/shared/modules/set-observations/models/responses/set-response.model';
import { ItemObservation } from 'app/shared/modules/set-observations/models/item-observation.model';
import { RecordObservationsRequest } from 'app/shared/modules/set-observations/models/requests/record-observations-request.model';
import { SetDocumentDownloadClick } from 'app/shared/models/set-document-download-click.model';
import { SetDocumentDownloadClickSuccess } from 'app/shared/models/set-document-download-click-success.model';
import { GetESignDocumentRequest } from 'app/shared/modules/set-observations/models/requests/get-esign-document-request.model';
import { ESignDocumentResponse } from 'app/shared/modules/set-observations/models/responses/esign-document-response.model';
import { AddMultiOptionListValueRequest } from 'app/shared/modules/set-observations/models/requests/add-multi-option-list-value-request.model';
import { GetCredasRegistrationUrlRequest } from 'app/shared/modules/set-observations/models/requests/get-credas-registration-url-request.model';
import { BadgeSummary } from 'app/shared/modules/set-observations/models/responses/badge-summary.model';
import { UapImage } from 'app/shared/modules/set-observations/models/responses/uap-image.model';
import { IdDocumentsResponse } from 'app/shared/modules/set-observations/models/responses/id-documents-response.model';
import { AddIdDocumentRequest } from 'app/shared/modules/set-observations/models/requests/add-id-document-request.model';
import { ObservationDocument } from 'app/shared/modules/set-observations/models/responses/observation-document.model';
import { AddImageListValueRequest } from 'app/shared/modules/set-observations/models/requests/add-image-list-value-request.model';
import { CourseSummary } from 'app/shared/modules/set-observations/models/responses/course-summary.model';
import { CourseDetail } from 'app/shared/modules/set-observations/models/responses/course-detail.model';
import { ReloadSetRequest } from 'app/shared/modules/set-observations/models/requests/reload-set-request.model';
import { IdDocumentUrl } from 'app/shared/modules/set-observations/models/responses/id-document-url.model';

export const GET_SET = '[Sets Observations] Get Set';
export const GET_SET_SUCCESS = '[Sets Observations] Get Set Success';
export const GET_SET_FAIL = '[Sets Observations] Get Set Fail';

export const GET_DOCUMENT = '[Sets Observations] Get Document';
export const GET_DOCUMENT_SUCCESS = '[Sets Observations] Get Document Success';
export const GET_DOCUMENT_FAIL = '[Sets Observations] Get Document Fail';

export const DOWNLOAD_DOCUMENT = '[Sets Observations] Download Document';
export const DOWNLOAD_DOCUMENT_SUCCESS = '[Sets Observations] Download Document Success';
export const DOWNLOAD_DOCUMENT_FAIL = '[Sets Observations] Download Document Fail';

export const GET_ESIGN_DOCUMENT = '[Sets Observations] Get eSign Document';
export const GET_ESIGN_DOCUMENT_SUCCESS = '[Sets Observations] Get eSign Document Success';
export const GET_ESIGN_DOCUMENT_FAIL = '[Sets Observations] Get eSign Document Fail';

export const ADD_OBSERVATION = '[Sets Observations] Add Observation';
export const DELETE_OBSERVATION = '[Sets Observations] Delete Observation';
export const ADD_MULTI_OPTION_LIST_OBSERVATION = '[Sets Observations] Add multi option list observation';
export const ADD_IMAGE_LIST_OBSERVATION = '[Sets Observations] Add image list observation';

export const UPDATE_OBSERVATION = '[Sets Observations] Update Observation';

export const UPDATE_OBSERVATION_CACHE = '[Sets Observations] Update Observation cache';

export const UPDATE_OBSERVATION_IMMEDIATE = '[Sets Observations] Update Observation immediate';
export const UPDATE_OBSERVATION_IMMEDIATE_SUCCESS = '[Sets Observations] Update Observation immediate success';
export const UPDATE_OBSERVATION_IMMEDIATE_FAIL = '[Sets Observations] Update Observation immediate fail';

export const SAVE_OBSERVATIONS = '[Sets Observations] Save Observations';
export const SAVE_OBSERVATIONS_SUCCESS = '[Sets Observations] Save Observations Success';
export const SAVE_OBSERVATIONS_FAIL = '[Sets Observations] Save Observations Fail';

export const GET_BADGE_SUMMARIES = '[Sets Observations] Get any badge summaries that may have been triggered';
export const GET_BADGE_SUMMARIES_SUCCESS = '[Sets Observations] Get any badge summaries that may have been triggered success';
export const GET_BADGE_SUMMARIES_FAIL = '[Sets Observations] Get any badge summaries that may have been triggered fail';

export const CLEAR_BADGE_SUMMARIES = '[Sets Observations] Clears the badge summaries that have been displayed';

export const GET_CREDAS_REGISTRATION_URL = '[Sets Observations] Get the credas registration url';
export const GET_CREDAS_REGISTRATION_URL_SUCCESS = '[Sets Observations] Get the credas registration url success';
export const GET_CREDAS_REGISTRATION_URL_FAIL = '[Sets Observations] Get the credas registration url fail';

export const GET_UAP_IMAGE_URL = '[Sets Observations] Get the Credas UAP image URL';
export const GET_UAP_IMAGE_URL_SUCCESS = '[Sets Observations] Get the Credas UAP image URL success';
export const GET_UAP_IMAGE_URL_FAIL = '[Sets Observations] Get the Credas UAP image URL fail';

export const ADD_ID_DOCUMENT = '[Sets Observations] Add an ID Document';
export const ADD_ID_DOCUMENT_SUCCESS = '[Sets Observations] Add an ID Document success';
export const ADD_ID_DOCUMENT_FAIL = '[Sets Observations] Add an ID Document fail';

export const GET_COURSES = '[Sets Observations] Get courses';
export const GET_COURSES_SUCCESS = '[Sets Observations] Get courses success';
export const GET_COURSES_FAIL = '[Sets Observations] Get courses fail';

export const GET_COURSE_DETAILS = '[Sets Observations] Get course details';
export const GET_COURSE_DETAILS_SUCCESS = '[Sets Observations] Get course details success';
export const GET_COURSE_DETAILS_FAIL = '[Sets Observations] Get course details fail';

export const RELOAD_SET = '[Sets Observations] Reload set';
export const RELOAD_SET_SUCCESS = '[Sets Observations] Reload set success';
export const RELOAD_SET_FAIL = '[Sets Observations] Reload set fail';

export const SAVE_SET_BEFORE_SWITCH_TO_MOBILE = '[Sets Observations] Save set before switch to mobile';
export const RELOAD_SET_REQUIRED = '[Sets Observations] Reload set required';

export const GET_ID_DOCUMENT_URLS = '[Sets Observations] Get document urls';
export const GET_ID_DOCUMENT_URLS_SUCCESS = '[Sets Observations] Get document urls success';
export const GET_ID_DOCUMENT_URLS_FAIL = '[Sets Observations] Get document urls fail';


export class GetSet implements Action {
    readonly type = GET_SET;
    constructor(public payload: string) { }
}

export class GetSetSuccess implements Action {
    readonly type = GET_SET_SUCCESS;
    constructor(public payload: SetResponse) { }
}

export class GetSetFail implements Action {
    readonly type = GET_SET_FAIL;
    constructor(public payload: string) { }
}

export class AddObservation implements Action {
    readonly type = ADD_OBSERVATION;
    constructor(public payload: string) { }
}

export class AddMultiOptionListObservation implements Action {
    readonly type = ADD_MULTI_OPTION_LIST_OBSERVATION;
    constructor(public payload: AddMultiOptionListValueRequest) { }
}

export class AddImageListObservation implements Action {
    readonly type = ADD_IMAGE_LIST_OBSERVATION;
    constructor(public payload: AddImageListValueRequest) { }
}

export class UpdateObservation implements Action {
    readonly type = UPDATE_OBSERVATION;
    constructor(public payload: ItemObservation) { }
}

export class UpdateObservationCache implements Action {
    readonly type = UPDATE_OBSERVATION_CACHE;
    constructor(public payload: ItemObservation) { }
}

export class UpdateObservationImmediate implements Action {
    readonly type = UPDATE_OBSERVATION_IMMEDIATE;
    constructor(public payload: ItemObservation) { }
}

export class UpdateObservationImmediateSuccess implements Action {
    readonly type = UPDATE_OBSERVATION_IMMEDIATE_SUCCESS;
}

export class UpdateObservationImmediateFail implements Action {
    readonly type = UPDATE_OBSERVATION_IMMEDIATE_FAIL;
}

export class DeleteObservation implements Action {
    readonly type = DELETE_OBSERVATION;
    constructor(public payload: string) { }
}

export class SaveObservations implements Action {
    readonly type = SAVE_OBSERVATIONS;
    constructor(public payload: RecordObservationsRequest) { }
}

export class SaveObservationsSuccess implements Action {
    readonly type = SAVE_OBSERVATIONS_SUCCESS;
}

export class SaveObservationsFail implements Action {
    readonly type = SAVE_OBSERVATIONS_FAIL;
    constructor(public payload: string) { }
}

export class GetDocument implements Action {
    readonly type = GET_DOCUMENT;
    constructor(public payload: string) { }
}

export class GetDocumentSuccess implements Action {
    readonly type = GET_DOCUMENT_SUCCESS;
    constructor(public payload: ObservationDocument) { }
}

export class GetDocumentFail implements Action {
    readonly type = GET_DOCUMENT_FAIL;
}

export class DownloadDocument implements Action {
    readonly type = DOWNLOAD_DOCUMENT;
    constructor(public payload: SetDocumentDownloadClick) { }
}

export class DownloadDocumentSuccess implements Action {
    readonly type = DOWNLOAD_DOCUMENT_SUCCESS;
    constructor(public payload: SetDocumentDownloadClickSuccess) { }
}

export class DownloadDocumentFail implements Action {
    readonly type = DOWNLOAD_DOCUMENT_FAIL;
}

export class GetESignDocument implements Action {
    readonly type = GET_ESIGN_DOCUMENT;
    constructor(public payload: GetESignDocumentRequest) { }
}

export class GetESignDocumentSuccess implements Action {
    readonly type = GET_ESIGN_DOCUMENT_SUCCESS;
    constructor(public payload: ESignDocumentResponse) { }
}

export class GetESignDocumentFail implements Action {
    readonly type = GET_ESIGN_DOCUMENT_FAIL;
    constructor(public payload: GetESignDocumentRequest) { }
}

export class GetBadgeSummaries implements Action {
    readonly type = GET_BADGE_SUMMARIES;
    constructor(public payload: string[]) { }
}

export class GetBadgeSummariesSuccess implements Action {
    readonly type = GET_BADGE_SUMMARIES_SUCCESS;
    constructor(public payload: BadgeSummary[]) { }
}

export class GetBadgeSummariesFail implements Action {
    readonly type = GET_BADGE_SUMMARIES_FAIL;
}

export class ClearBadgeSummaries implements Action {
    readonly type = CLEAR_BADGE_SUMMARIES;
}

export class GetCredasRegistrationUrl implements Action {
    readonly type = GET_CREDAS_REGISTRATION_URL;
    constructor(public payload: GetCredasRegistrationUrlRequest) { }
}

export class GetCredasRegistrationUrlSuccess implements Action {
    readonly type = GET_CREDAS_REGISTRATION_URL_SUCCESS;
    constructor(public payload: string) { }
}

export class GetCredasRegistrationUrlFail implements Action {
    readonly type = GET_CREDAS_REGISTRATION_URL_FAIL;
}

export class GetUapImageUrl implements Action {
    readonly type = GET_UAP_IMAGE_URL;
    constructor(public payload: string) { }
}

export class GetUapImageUrlSuccess implements Action {
    readonly type = GET_UAP_IMAGE_URL_SUCCESS;
    constructor(public payload: UapImage) { }
}

export class GetUapImageUrlFail implements Action {
    readonly type = GET_UAP_IMAGE_URL_FAIL;
}

export class AddIdDocument implements Action {
    readonly type = ADD_ID_DOCUMENT;
    constructor(public payload: AddIdDocumentRequest) { }
}

export class AddIdDocumentSuccess implements Action {
    readonly type = ADD_ID_DOCUMENT_SUCCESS;
    constructor(public payload: IdDocumentsResponse) { }
}

export class AddIdDocumentFail implements Action {
    readonly type = ADD_ID_DOCUMENT_FAIL;
}

export class GetCourses implements Action {
    readonly type = GET_COURSES;
    constructor(public payload: Date) { }
}

export class GetCoursesSuccess implements Action {
    readonly type = GET_COURSES_SUCCESS;
    constructor(public payload: CourseSummary[]) { }
}

export class GetCoursesFail implements Action {
    readonly type = GET_COURSES_FAIL;
}

export class GetCourseDetails implements Action {
    readonly type = GET_COURSE_DETAILS;
    constructor(public payload: string) { }
}

export class GetCourseDetailsSuccess implements Action {
    readonly type = GET_COURSE_DETAILS_SUCCESS;
    constructor(public payload: CourseDetail) { }
}

export class GetCourseDetailsFail implements Action {
    readonly type = GET_COURSE_DETAILS_FAIL;
}

export class ReloadSet implements Action {
    readonly type = RELOAD_SET;
    constructor(public payload: ReloadSetRequest) { }
}

export class ReloadSetSuccess implements Action {
    readonly type = RELOAD_SET_SUCCESS;
    constructor(public payload: number) { }
}

export class ReloadSetFail implements Action {
    readonly type = RELOAD_SET_FAIL;
}

export class SaveSetBeforeSwitchToMobile implements Action {
    readonly type = SAVE_SET_BEFORE_SWITCH_TO_MOBILE;
    constructor(public payload: string) { }
}

export class ReloadSetRequired implements Action {
    readonly type = RELOAD_SET_REQUIRED;
    constructor(public payload: string) { }
}

export class GetIdDocumentUrls implements Action {
    readonly type = GET_ID_DOCUMENT_URLS;
    constructor(public payload: string) { }
}

export class GetIdDocumentUrlsSuccess implements Action {
    readonly type = GET_ID_DOCUMENT_URLS_SUCCESS;
    constructor(public payload: IdDocumentUrl[]) { }
}

export class GetIdDocumentUrlsFail implements Action {
    readonly type = GET_ID_DOCUMENT_URLS_FAIL;
}

export type AllSetObservationActions
    = GetSet
    | GetSetSuccess
    | GetSetFail
    | AddObservation
    | UpdateObservation
    | UpdateObservationCache
    | UpdateObservationImmediate
    | UpdateObservationImmediateSuccess
    | UpdateObservationImmediateFail
    | DeleteObservation
    | AddMultiOptionListObservation
    | AddImageListObservation
    | SaveObservations
    | SaveObservationsSuccess
    | SaveObservationsFail
    | GetDocument
    | GetDocumentSuccess
    | GetDocumentFail
    | DownloadDocument
    | DownloadDocumentSuccess
    | DownloadDocumentFail
    | GetESignDocument
    | GetESignDocumentSuccess
    | GetESignDocumentFail
    | GetBadgeSummaries
    | GetBadgeSummariesSuccess
    | GetBadgeSummariesFail
    | ClearBadgeSummaries
    | GetCredasRegistrationUrl
    | GetCredasRegistrationUrlSuccess
    | GetCredasRegistrationUrlFail
    | GetUapImageUrl
    | GetUapImageUrlSuccess
    | GetUapImageUrlFail
    | AddIdDocument
    | AddIdDocumentSuccess
    | AddIdDocumentFail
    | GetCourses
    | GetCoursesSuccess
    | GetCoursesFail
    | GetCourseDetails
    | GetCourseDetailsSuccess
    | GetCourseDetailsFail
    | ReloadSet
    | ReloadSetSuccess
    | ReloadSetFail
    | SaveSetBeforeSwitchToMobile
    | ReloadSetRequired
    | GetIdDocumentUrls
    | GetIdDocumentUrlsSuccess
    | GetIdDocumentUrlsFail;