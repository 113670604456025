import { Component, OnInit, Output, EventEmitter, Input, forwardRef, OnDestroy, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

// models
import { CustomMenu } from './model/custom-menu.model';

// components
import { ColorPickerDialogComponent } from 'app/shared/components/color-picker-dialog/color-picker-dialog.component';

@Component({
    selector: 'app-rich-text-editor',
    templateUrl: './rich-text-editor.component.html',
    styleUrls: ['./rich-text-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => RichTextEditorComponent)
        }
    ]
})
export class RichTextEditorComponent implements ControlValueAccessor, OnInit, OnDestroy {
    readonly showBgColorDialogEventName = 'show_bg_color_dialog';

    backgroundColor: string;
    editorRef: any;

    @Input()
    text: string;

    @Input()
    height: number = 200;

    @Input()
    customMenu: CustomMenu[];

    @Input()
    allowImages = false;

    @Input()
    disabled = false;

    @Output()
    textChanged: EventEmitter<string> = new EventEmitter();

    config: any;
    showMe = false;
    showBackgroundColorDialog: () => void;

    constructor(private dialog: MatDialog, private elRef: ElementRef) {
        const rteComponent = this;

        this.showBackgroundColorDialog = (): void => {
            const colorPickerDialogComponent = this.dialog.open(ColorPickerDialogComponent, {
                data: {
                    title: 'Background Color',
                    label: 'Select a Background Color',
                    color: rteComponent.backgroundColor,
                }
            });

            colorPickerDialogComponent.afterClosed().subscribe((value: string) => {
                if (value) {
                    this.backgroundColor = value;
                    this.handleTextChanged(this.text);
                }
            });
        };

        this.elRef.nativeElement.addEventListener(this.showBgColorDialogEventName, this.showBackgroundColorDialog);
    }

    ngOnInit(): void {
        const menu = this.customMenu;
        const rteComponent = this;

        const plugins = `lists, link, image, table, print`;
        const toolbar2 = `fontselect fontsizeselect forecolor backcolor formatselect ${this.allowImages ? 'image' : ''}`;
        this.config = {
            height: this.height,
            branding: false,
            plugins,
            toolbar1: 'fullscreen bold italic underline strikethrough subscript superscript document_template_menu background_color',
            toolbar2,
            toolbar3: 'alignleft aligncenter alignright alignjustify numlist bullist indent outdent blockquote link table emoticons hr selectall removeformat undo redo',
            menubar: false,
            statusbar: false,
            mobile: {
                theme: 'silver'
            },
            cleanup: false,
            verify_html: false,
            apply_source_formatting: false,
            valid_elements: 'div[class|style]',
            extended_valid_elements: 'div[class|style]',
            valid_children: '+div[style]',
            setup(editor) {
                rteComponent.editorRef = editor;

                if (menu) {
                    editor.ui.registry.addMenuButton('document_template_menu', {
                        text: 'Insert Field',
                        fetch(callback) {
                            const items = [];

                            menu.forEach(element => {
                                items.push({
                                    type: element.type,
                                    text: element.text,
                                    onAction() {
                                        editor.insertContent(element.insertText);
                                    }
                                });
                            });

                            callback(items);
                        }
                    });
                }

                editor.ui.registry.addButton('background_color', {
                    text: 'Background Color',
                    tooltip: 'Background Color',
                    onAction() {
                        const element = editor.dom.select('.background-color');

                        if (element !== undefined && element !== null && element.length > 0) {
                            rteComponent.backgroundColor = editor.dom.toHex(editor.dom.getStyle(element, 'background-color'));
                        }

                        rteComponent.elRef.nativeElement.dispatchEvent(new CustomEvent(rteComponent.showBgColorDialogEventName));
                    }
                });
            }
        };

        setTimeout(() => {
            this.showMe = true;
        }, 0);
    }

    ngOnDestroy(): void {
        this.elRef.nativeElement.removeEventListener(this.showBgColorDialogEventName, this.showBackgroundColorDialog, false);
    }

    handleTextChanged($event) {
        let newValue: string = $event;
        if (newValue === '') {
            newValue = null;
        } else if (this.backgroundColor) {
            const element = this.editorRef.dom.select('.background-color');

            if (element !== undefined && element !== null && element.length > 0) {
                this.editorRef.dom.setStyle(element, 'background-color', this.backgroundColor);
                newValue = this.editorRef.dom.doc.body.innerHTML;
            } else {
                newValue = `<div class="background-color" style="background-color:${this.backgroundColor};padding:10px">${this.editorRef.dom.doc.body.innerHTML}</div>`;
            }

            this.backgroundColor = null;
        }

        this.textChanged.emit(newValue);

        this.text = newValue;
        this.propagateChange(this.text);
    }

    propagateChange: (change: any) => void = () => {
    };

    public writeValue(value: any): void {
        this.text = value;
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(): void {
    }

    setDisabledState?(): void {
    }
}