import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// rxjs
import { Observable } from 'rxjs';

// components
import { ImagePopupComponent } from 'app/shared/components/image-popup/image-popup.component';

// models
import { IdDocumentResponse } from 'app/shared/modules/set-observations/models/responses/id-document-response.model';
import { IdDocumentUrl } from 'app/shared/modules/set-observations/models/responses/id-document-url.model';
import { ImagePopupData } from 'app/shared/models/image-popup-data.model';

// enums
import { CheckResult } from 'app/shared/enums';
import { getIdDocumentTypeTitle } from 'app/shared/enums/id-document-type.enum';
import { DocumentSide } from 'app/shared/modules/set-observations/enums/document-side.enum';
import { VisualAnalysisResult } from 'app/shared/modules/set-observations/enums/visual-analysis-result.enum';
import { CheckMethod } from 'app/shared/enums/check-method.enum';

@Component({
    selector: 'app-id-documents-full-result',
    templateUrl: './id-documents-full-result.component.html',
    styleUrls: ['./id-documents-full-result.component.scss']
})
export class IdDocumentsFullResultComponent implements OnInit {

    @Input()
    idDocuments: IdDocumentResponse[];

    @Input()
    idDocumentsJson: string = null;

    @Input()
    observationId: string;

    @Input()
    urlImages: boolean;

    @Input()
    urls: IdDocumentUrl[];

    @Input()
    urlsLoading$: Observable<boolean>;

    CheckResult = CheckResult;
    VisualAnalysisResult = VisualAnalysisResult;
    imageLoaded = false;

    constructor(private dialogs: MatDialog) { }

    ngOnInit(): void {
        if (this.idDocumentsJson) {
            const idDocResponse = JSON.parse(this.idDocumentsJson);
            this.idDocuments = idDocResponse.documents;
        }

        if (this.idDocuments) {
            this.idDocuments.sort((d1, d2) => new Date(d2.dateCreated).getTime() - new Date(d1.dateCreated).getTime());
        }
    }

    // IDV component - document is stored in blob storage
    getBlobIdDocumentSource(idDocument: IdDocumentResponse): string {
        return `/api/observations/${this.observationId}/identity-verification/id-documents/${idDocument.documentId}`;
    }

    // ID documents using Credas urls
    getUrlIdDocumentSource(idDocument: IdDocumentResponse) {
        if (this.urls) {
            const docUrl = this.urls.find(x => x.documentId === idDocument.documentId);
            return docUrl?.lowResUrl;
        }
        return '';
    }

    getIdDocumentTypeDescription(idDocument: IdDocumentResponse): string {
        let description = getIdDocumentTypeTitle(idDocument.idDocumentType);
        if (idDocument.documentSide !== DocumentSide.Unknown) {
            description += ` (${DocumentSide[idDocument.documentSide]})`;
        }

        return description;
    }

    getCheckResultClass(checkResult: CheckResult): string {
        switch (checkResult) {
            case CheckResult.Pass: return 'pass';
            case CheckResult.Fail: return 'fail';
            default: return 'refer';
        }
    }

    getAnalysisResultClass(visualAnalysisResult: VisualAnalysisResult): string {
        switch (visualAnalysisResult) {
            case VisualAnalysisResult.Passed: return 'pass';
            case VisualAnalysisResult.Fail: return 'fail';
            case VisualAnalysisResult.NotSupported: return 'fail';
            default: return 'refer';
        }
    }

    getCheckResultDescription(checkResult: CheckResult): string {
        switch (checkResult) {
            case CheckResult.Pass: return 'Pass';
            case CheckResult.Fail: return 'Fail';
            default: return 'Refer';
        }
    }

    onImageLoaded() {
        this.imageLoaded = true;
    }

    onSecureImageClicked($event: any, idDocument: IdDocumentResponse): void {
        const url = $event.target.src;
        this.showImagePopup(url, idDocument.description);
    }

    onImageClicked(idDocument: IdDocumentResponse): void {
        if (this.urlImages) {
            const docUrl = this.urls.find(x => x.documentId === idDocument.documentId);
            if (docUrl) {
                const url = docUrl.highResUrl ?? docUrl.lowResUrl;
                this.showImagePopup(url, idDocument.description);
            }
        }
    }

    getNameCheckStatusTitle(idDocument: IdDocumentResponse): string {
        let checkMethod = '';
        switch(idDocument.nameCheckMethod) {
            case CheckMethod.Automatic:
                checkMethod = 'Auto ';
                break;
            case CheckMethod.Manual:
                checkMethod = 'Backoffice ';
                break;
            default:
                checkMethod = '';
                break;
        }

        if (idDocument.nameCheck) {
            return `${checkMethod}Verified`;
        } else if (idDocument.documentSide === DocumentSide.Back) {
            return 'N/A';
        } else {
            return `${checkMethod}Failed`;
        }
    }

    private showImagePopup(url: string, description: string): void {
        this.dialogs.open(ImagePopupComponent, {
            data: new ImagePopupData(url, `ID Document - ${description}`)
        });
    }

}