import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { PostcodeLookupResult } from 'app/shared/models/postcode-lookup-result.model';

@Injectable()
export class AddressLookupService {

    private baseUrl = 'api/postcode-lookup';

    constructor(private http: HttpClient) {}

    public postCodeLookup(postcode: string): Observable<PostcodeLookupResult> {
        const url = `${this.baseUrl}?postcode=${postcode}`;
        return this.http.get<PostcodeLookupResult>(url);
    }
}