<app-authentication-layout id="reset-password-container" id="reset-password-container" fxFlex>
    <app-brand-panel [title]="title" fxFlex.lt-sm="100" [showCloseButton]="false">
        <form name="form" [formGroup]="form" novalidate *ngIf="form && !loggedIn" fxLayout="column" fxLayoutGap="8px" fxLayoutGap.gt-xs="32px">

            <div class="advice-text" *ngIf="tempPasswordReset">
                You have been assigned a temporary password. Please now choose a new password for your account.
            </div>

            <div *ngIf="!tempPasswordReset">
                <div class="font-question-label">Email</div>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="email" type="email">
                    <mat-error *ngIf="form.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="!form.get('email').hasError('required') &&
                                        form.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>
            </div>

            <div>
                <div class="font-question-label">Password</div>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Password" formControlName="password" [type]="hidePassword ? 'password' : 'text'">
                    <mat-icon matSuffix class="pointer" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' :
                        'visibility'}}</mat-icon>
                    <mat-error *ngIf="validationService.hasError(form.controls['password'])">
                        {{ validationService.getError(form.controls['password']) }}
                    </mat-error>
                </mat-form-field>
            </div>
            
            <div>
                <div class="font-question-label">Confirm password</div>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Confirm Password" formControlName="confirmPassword" [type]="hideConfirmPassword ? 'password' : 'text'">
                    <mat-icon matSuffix class="pointer" (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ?
                        'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="validationService.hasError(form.controls['confirmPassword'])">
                        {{ validationService.getError(form.controls['confirmPassword']) }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div id="authentication-terms" *ngIf="invitation">
                <app-terms-acceptance-form [form]="form"></app-terms-acceptance-form>
            </div>

            <div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center" fxLayout="column-reverse" fxLayoutGap="16px">
                <div [routerLink]="['/auth', 'login']" class="link">
                    <mat-icon>chevron_left</mat-icon>
                    <div>Back to login</div>
                </div>
                <button mat-raised-button (click)="resetPassword()"
                    aria-label="Reset Password" [disabled]="form.invalid || (working$|async)">
                    <span *ngIf="!(working$|async)">Set Password</span>
                    <span *ngIf="working$|async">Please wait.</span>
                </button>
            </div>
        </form>
    </app-brand-panel>
    <app-client-selector *ngIf="loggedIn" [logoutOnBackClick]="true" backLabel="Back to login" [float]="false"></app-client-selector>
</app-authentication-layout>