// Models
import { ItemObservationResponse } from './responses/item-observation-response.model';

// Enums
import { ItemType } from 'app/shared/enums/item-type.enum';

export class ItemObservation {
    id: string;
    setId: string;
    itemId: string;
    itemType: ItemType;
    observationId: string;
    ownerId: string;
    groupId: string;
    value: string;
    score: number;
    base64File: string;
    contentType: string;
    showDownloadLink: boolean = false;
    changed: boolean = false;
    lastUpdated: Date;
    metaData: any;

    constructor(itemObservationResponse: ItemObservationResponse, setId: string, itemId: string, itemType: ItemType) {
        this.id = itemObservationResponse.id;
        this.setId = setId;
        this.itemId = itemId;
        this.observationId = itemObservationResponse.observation.id;
        this.ownerId = itemObservationResponse.observation.ownerId;
        this.groupId = itemObservationResponse.observation.groupId;
        this.value = itemObservationResponse.observation.value;
        this.score = itemObservationResponse.observation.score;
        this.lastUpdated = itemObservationResponse.lastUpdated;
        this.showDownloadLink =
            itemObservationResponse.observation.downloadLink !== undefined &&
            itemObservationResponse.observation.downloadLink !== null &&
            itemObservationResponse.observation.downloadLink !== '';
        this.itemType = itemType;
        this.metaData = itemObservationResponse?.observation?.metaData ?? {};
    }

    hasValue(): boolean {
        return this.value !== null && this.value !== undefined && this.value !== '';
    }

    clearFile(): void {
        this.base64File = null;
        this.value = null;
        this.showDownloadLink = false;
    }

    setFile(base64File: string, fileName: string, contentType: string): void {
        this.base64File = base64File;
        this.contentType = contentType;
        this.value = fileName;
        this.showDownloadLink = false;

        // If this is a mobile picture from camera we won't have a file
        // name, so put something into the file name so that the observation
        // has a value.
        if (!this.value || this.value.length === 0) {
            this.value = 'camera-capture';
        }
    }

    setValue(value: any): void {
        this.value = value;
        this.lastUpdated = new Date();
    }

    updateMetaData(key: string, value: string): void {
        this.metaData[key] = value;
    }
}