import { Injectable } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class PagingService {

    constructor(
        private router: Router,
        private route: ActivatedRoute) { }

    handlePaginatorChanged(pageEvent: PageEvent, defaultPageSize: number = 10): void {
        if (pageEvent.pageIndex === 0) {
            this.addParameter('pageIndex', null);
        } else {
            this.addParameter('pageIndex', pageEvent.pageIndex.toLocaleString());
        }

        if (pageEvent.pageSize === defaultPageSize) {
            this.addParameter('pageSize', null);
        } else {
            this.addParameter('pageSize', pageEvent.pageSize.toLocaleString());
        }
    }

    clearPaginator(): void {
        this.addParameter('pageIndex', null);
        this.addParameter('pageSize', null);
    }

    setListOrCard(showCardView: boolean): void {
        this.addParameter('card', showCardView ? '1' : null);
    }

    getIsCardViewFromQuery(route: ActivatedRoute): boolean {
        return !!(route.snapshot?.queryParams?.card);
    }

    loadPagingFromQuery(paginator: MatPaginator, defaultPageSize: number = 10): void {
        paginator.pageSize = this.getPageSizeFromQuery(defaultPageSize);
        paginator.pageIndex = this.getPageIndexFromQuery();
    }

    getPageSizeFromQuery(defaultPageSize: number = 10): number {
        return this.route.snapshot?.queryParams?.pageSize ? parseInt(this.route.snapshot.queryParams.pageSize, 10) : defaultPageSize;
    }

    getPageIndexFromQuery(): number {
        return this.route.snapshot?.queryParams?.pageIndex ? parseInt(this.route.snapshot.queryParams.pageIndex, 10) : 0;
    }

    private addParameter(key: string, value: string): void {
        setTimeout(() =>
            this.router.navigate([], {
                queryParams: {
                    [key]: value
                },
                queryParamsHandling: 'merge'
            }), 10);
    }
}