<app-dialog [dialogTitle]="title" id="color-picker-dialog" defaultWidth="600px">
    <div body>
        <app-color-picker class="margin-bottom-20" [label]="label" [color]="color" (colorReset)="onColorReset()" (colorSelected)="onColorSelected($event)"></app-color-picker>
    </div>

    <div footer-actions>
        <button mat-raised-button class="ghost" mat-dialog-close>Cancel</button>
        <button mat-raised-button (click)="onSaveClick()">Save</button>
    </div>
</app-dialog>
