<app-dialog dialogTitle="Journeys" id="manage-assessment-sets" defaultWidth="1000px" defaultHeight="800px">

    <div body fxFlex fxLayout="column" *ngIf="loading">
        <app-loading-card></app-loading-card>
    </div>
    
    <div body fxFlex="100" fxLayout="column" *ngIf="!loading">
        <app-page-information-text text="Select the journeys that you would like users to complete"></app-page-information-text>
        <app-badge-selection-tab
            [badges]="badges"
            (badgeSelectionChanged)="handleBadgeEnabledChanged($event)"
            [iconSource]="iconSource"
            [lockExistingBadges]="true">
        </app-badge-selection-tab>
        <app-loading-card *ngIf="saving"></app-loading-card>
    </div>

    <div footer-actions>
        <button mat-raised-button class="ghost" [disabled]="saving" mat-dialog-close>Cancel</button>
        <button mat-raised-button   [disabled]="saving" (click)="save()">Save</button>
    </div>
</app-dialog>