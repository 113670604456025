<app-authentication-layout id="register-container" >
    <app-brand-panel [title]="title" fxFlex.lt-sm="100" [showCloseButton]="false">
        <mat-horizontal-stepper *ngIf="registerForm" [linear]="false" [selectedIndex]="stepIndex">
            <mat-step>
                <div fxLayout="column"  fxLayoutGap="4px" fxLayoutGap.gt-xs="32px" [formGroup]="registerForm" novalidate >
                    <div>
                        <div class="font-question-label">Registration code</div>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="registrationCode" autocomplete="DHKIUS">
                            <mat-error *ngIf="registerForm.controls['registrationCode'].hasError">
                                Registration code must be 8 digits
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="advice-text">
                        Please enter your 8-digit registration code.
                    </div>
                    <div class="advice-text">
                        You should have received this via post or email.
                    </div>

                    <div class="registration-error" *ngIf="registrationError$ | async">
                        {{registrationError$ | async}}
                    </div>

                    <div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center" fxLayout="column-reverse" fxLayoutGap="16px" >
                        <div [routerLink]="['/auth', 'login']" class="pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon>chevron_left</mat-icon>
                            <div>Back to login</div>
                        </div>
                        <button mat-raised-button (click)="checkRegistrationCode()" [disabled]="!step1Valid">Next</button>
                    </div>
                </div>
            </mat-step>
            
            <mat-step>
                <div fxLayout="column"  fxLayoutGap="16px" fxLayoutGap.gt-xs="32px" [formGroup]="registerForm" novalidate >
                    <div>
                        <div class="font-question-label">First Name</div>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="firstName" autocomplete="off">
                            <mat-error *ngIf="registerForm.controls['firstName'].hasError">
                                {{ validationService.getError(registerForm.controls['firstName']) }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <div class="font-question-label">Surname</div>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="surname" autocomplete="off">
                            <mat-error *ngIf="registerForm.controls['surname'].hasError">
                                {{ validationService.getError(registerForm.controls['surname']) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div class="advice-text">
                        Please provide your first name and surname.
                    </div>

                    <div class="registration-error" *ngIf="registrationError$ | async">
                        {{registrationError$ | async}}
                    </div>

                    <div *ngIf="useRegistrationCode" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center" fxLayout="column-reverse" fxLayoutGap="16px" >
                        <button mat-raised-button (click)="back()" class="ghost">Back</button>
                        <button mat-raised-button (click)="next()" [disabled]="!step2Valid">Next</button>
                    </div>

                    <div *ngIf="!useRegistrationCode" fxLayout="row" fxLayoutAlign="end top"  fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center" fxLayout="column-reverse" fxLayoutGap="16px">
                        <button mat-raised-button (click)="next()" [disabled]="!step2Valid">Next</button>
                    </div>
                </div>
            </mat-step>

            <mat-step>
                <div fxLayout="column"  fxLayoutGap="16px" fxLayoutGap.gt-xs="32px" [formGroup]="registerForm" novalidate >
                    <div>
                        <div class="font-question-label">Phone Number</div>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="phoneNumber" type="tel" autocomplete="off">
                            <mat-hint>10 or 11 digits with no spaces</mat-hint>
                            <mat-error *ngIf="validationService.hasError(registerForm.controls['phoneNumber'])">
                                {{ validationService.getError(registerForm.controls['phoneNumber']) }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <div class="font-question-label">Email</div>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="email" type="email" autocomplete="off">
                            <mat-error *ngIf="validationService.hasError(registerForm.controls['email'])">
                                {{ validationService.getError(registerForm.controls['email']) }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="advice-text">
                        These details will be used to provide you with updates during the registration process.
                    </div>

                    <div class="registration-error" *ngIf="(registrationError$ | async)">
                        {{registrationError$ | async}}
                    </div>

                    <div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center" fxLayout="column-reverse" fxLayoutGap="16px" >
                        <button mat-raised-button (click)="back()" class="ghost">Back</button>
                        <button mat-raised-button (click)="checkEmail()" [disabled]="!step3Valid">Next</button>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <div fxLayout="column" fxLayoutGap="16px" fxLayoutGap.gt-xs="32px" [formGroup]="registerForm" novalidate >
                    <div>
                        <div class="font-question-label">Password</div>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'" autocomplete="off">
                            <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="pointer">{{hidePassword ? 'visibility_off' :
                                'visibility'}}</mat-icon>
                
                            <mat-error *ngIf="validationService.hasError(registerForm.controls['password'])">
                                {{ validationService.getError(registerForm.controls['password']) }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <div class="font-question-label">Confirm Password</div>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="confirmPassword" [type]="hideConfirmPassword ? 'password' : 'text'" autocomplete="off">
                            <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" class="pointer">{{hideConfirmPassword ? 'visibility_off' :
                                'visibility'}}</mat-icon>
                            <mat-error *ngIf="validationService.hasError(registerForm.controls['confirmPassword'])">
                                {{ validationService.getError(registerForm.controls['confirmPassword']) }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="advice-text">
                        Please choose a password for your account.
                    </div>

                    <div class="advice-text">
                        You will need to provide this each time you log in.
                    </div>

                    <div class="registration-error" *ngIf="registrationError$ | async">
                        {{registrationError$ | async}}
                    </div>

                    <app-terms-acceptance-form [form]="registerForm"></app-terms-acceptance-form>
                    
                    <div fxLayout="row" fxLayoutAlign="space-between top" class="button-container">
                        <button mat-raised-button (click)="back()" class="ghost" [disabled]="working">Back</button>
                        <button mat-raised-button (click)="registerUser(registerForm)" aria-label="REGISTER" [disabled]="registerForm.invalid || working">
                            <span *ngIf="!working">Register</span>
                            <span *ngIf="working">Please wait.</span>
                        </button>
                    </div>
                </div>
            </mat-step>

        </mat-horizontal-stepper>

    </app-brand-panel>
</app-authentication-layout>