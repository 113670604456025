import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';

// Store
import { Action } from '@ngrx/store';
import * as ActiveUserActions from 'app/store/actions/active-user.actions';
import * as AuthActions from 'app/authentication/store/actions/auth.actions';
import * as fromConnect from 'app/connect/store';

// Services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';
import { OrganisationsService } from 'app/shared/services';
import { AuthenticationService } from 'app/authentication/services/authentication.service';

// Enums
import { TokenResponse } from 'app/shared/models';

@Injectable()
export class ActiveUsersEffects {


    getSelectedOrganisationAssessments$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActiveUserActions.GET_SELECTED_ORGANISATION_ASSESSMENTS),
        map((action: ActiveUserActions.GetSelectedOrganisationAssessments) => action.payload),
        mergeMap(orgId =>
            this.organisationsService
                .getAssessmentSets(orgId)
                .pipe(map(assessmentSets =>
                    new ActiveUserActions.GetSelectedOrganisationAssessmentsSuccess(assessmentSets),
                    catchError(() =>
                        of(new ActiveUserActions.GetSelectedOrganisationAssessmentsFail('Error Occurred getting badges for organisation'))
                    )
                )))
    ));


    setSelectedClient$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActiveUserActions.SET_SELECTED_CLIENT),
        switchMap((action: ActiveUserActions.SetSelectedClient) => this.authenticationService.setClient(action.clientId).pipe(
                switchMap((tokenResponse: TokenResponse) => {
                    this.authenticationToken.setActiveClient(action.clientId, action.isClient);
                    return [
                        new AuthActions.SetToken(tokenResponse),
                        new ActiveUserActions.SetSelectedClientSuccess(tokenResponse, action.clientId),
                        fromConnect.SetTokenSuccess({ performRedirect: action.performRedirect, setServiceFromUrl: action.setServiceFromUrl, isClient: action.isClient })
                    ];
                }),
                catchError(() => of(new ActiveUserActions.SetSelectedClientFail()))
            ))
    ));


    getInviteId$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActiveUserActions.GET_USER_INVITE_ID),
        map((action: ActiveUserActions.GetUserInviteId) => action.payload),
        switchMap((userId: string) => this.authenticationService.getInviteId(userId).pipe(
                map((inviteId: string) => new ActiveUserActions.GetUserInviteIdSuccess(inviteId)),
                catchError(() => of(new ActiveUserActions.GetUserInviteIdFail()))
            ))
    ));

    constructor(
        private actions$: Actions,
        private organisationsService: OrganisationsService,
        private authenticationToken: AuthenticationTokenService,
        private authenticationService: AuthenticationService) { }
}
