// Angular
import { Injectable } from '@angular/core';

// ngrx/rxjs
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

// Store
import * as fromActions from 'app/portal/modules/document-library-v1/store/actions';

// Services
import { OrganisationDocumentService } from 'app/portal/modules/document-library-v1/services/organisation-document.service';

// Models
import { DocumentLibraryItem } from 'app/shared/modules/set-observations/models/document-library-item.model';


@Injectable()
export class OrganisationDocumentEffects {

    getCommonDocuments$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_COMMON_DOCUMENTS),
        map((action: fromActions.GetCommonDocuments) => action.payload),
        switchMap((organisationId: string) => this.organisationDocumentService.getCommonDocuments(organisationId).pipe(
                map((documents: DocumentLibraryItem[]) => new fromActions.GetCommonDocumentsSuccess(documents)),
                catchError(() => of(new fromActions.GetCommonDocumentsFail()))
            ))
    ));

    constructor(
        private actions$: Actions,
        private organisationDocumentService: OrganisationDocumentService) {}
}
