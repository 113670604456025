import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { AddressLookupResult } from 'app/portal/modules/inputs/models/address-lookup-result.model';

@Injectable()
export class AddressLookupService {

    private baseUrl = 'api/postcode-lookup';

    constructor(private http: HttpClient) {}

    public postCodeLookup(postcode: string): Observable<AddressLookupResult> {
        const url = `${this.baseUrl}?postcode=${postcode}`;
        return this.http.get<AddressLookupResult>(url);
    }
}
