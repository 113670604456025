import { Component, OnInit } from '@angular/core';

// ngrx | rxjs
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// store
import * as fromStore from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { Client } from 'app/models/client.model';
import { ModuleDetails } from 'app/models/module-details.model';
import { User } from 'app/models/user.model';
import { UserGroup } from 'app/models/user-group.model';

// enums
import { Modules } from 'app/shared/enums';
import { TooltipPage } from 'app/connect/enums/tooltip-page.enum';

@Component({
    selector: 'app-connect-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

    showHeader$: Observable<boolean>;
    logoUrl$: Observable<string>;
    credasLogoColor$: Observable<string>;
    applications$: Observable<ModuleDetails[]>;
    userGroup$: Observable<UserGroup>;
    showHelpFaqs$: Observable<boolean>;
    showTooltipsTour$: Observable<boolean>;

    client: Client;

    userInitials: string;
    userName: string;
    logoUrl: string;

    constructor(
        private store: Store<fromStore.ConnectStoreState>) {
        super();
    }

    ngOnInit(): void {
        this.showHeader$ = this.store.select(fromStore.getShowHeader);
        this.applications$ = this.store.select(fromStore.getApplications);
        this.logoUrl$ = this.store.select(fromStore.getLogoUrl);
        this.credasLogoColor$ = this.store.select(fromStore.getCredasLogoColor);
        this.userGroup$ = this.store.select(fromStore.getUserGroup);
        this.showHelpFaqs$ = this.store.select(fromStore.getShowHelpFaqsOnNavigation);
        this.showTooltipsTour$ = this.store.select(fromStore.getShowTooltipsTourOnNavigation);

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getLogoUrl))
            .subscribe((logoUrl: string) => {
                this.logoUrl = logoUrl;
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getClient))
            .subscribe((client: Client) => {
                this.client = client;
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getUser))
            .subscribe((user: User) => {
                if (user) {
                    this.userInitials = `${user.firstName[0]}${user.surname[0]}`.toUpperCase();
                    this.userName = `${user.firstName} ${user.surname}`;
                } else {
                    this.userInitials = null;
                    this.userName = null;
                }
            });
    }

    onApplicationClick(applicationId: Modules): void {
        this.store.dispatch(fromStore.SelectApplication({ applicationId, performRedirect: true }));
    }

    onUserMenuClick(): void {
        this.store.dispatch(fromStore.OpenUserMenu());
    }

    onLogoClicked(): void {
        // Check for a client, if we have one, re-select it, this will force the default APP for clients to load
        if (this.client) {
            this.store.dispatch(fromStore.SelectClient({ clientId: this.client.id, performRedirect: true, setServiceFromUrl: false}));
        } else {
            // If there is no client, then the user MUST be an "Entity" user. So just fire a refresh to refresh the homepage
            window.location.href = window.location.href; // eslint-disable-line no-self-assign
        }
    }

    onOpenHelpFaqSearchClick(): void {
        this.store.dispatch(fromStore.OpenHelpFaqSearch());
    }

    onShowTooltipsTourClick(): void {
        this.store.dispatch(fromStore.SetTooltipsIsTour({ isTour: true }));
        this.store.dispatch(fromStore.GetTooltips({ page: TooltipPage.ClientPortalPage, isTour: true }));
    }
}