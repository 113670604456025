import { ActionReducerMap } from '@ngrx/store';

import * as fromAuthentication from './auth.reducers';

export interface AuthenticationState {
  loggedInSessionState: fromAuthentication.LoggedInSessionState;
}

export const reducers: ActionReducerMap<AuthenticationState> = {
  loggedInSessionState: fromAuthentication.reducer
};

