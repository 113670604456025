// Angular
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';

// ngrx/rxjs
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

// Store
import * as fromStore from 'app/shared/modules/set-observations/store';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Models
import { ItemObservation } from 'app/shared/modules/set-observations/models/item-observation.model';
import { Item } from 'app/shared/modules/set-observations/models/item.model';
import { AddMultiOptionListValueRequest } from 'app/shared/modules/set-observations/models/requests/add-multi-option-list-value-request.model';
import { AddImageListValueRequest } from 'app/shared/modules/set-observations/models/requests/add-image-list-value-request.model';

// Enums
import { ItemType } from 'app/shared/enums/item-type.enum';

// Utilities
import { ItemsUtility } from 'app/shared/modules/set-observations/utils/items-paging.utility';


@Component({
    selector: 'app-set-observations',
    templateUrl: './set-observations.component.html',
    styleUrls: ['./set-observations.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SetObservationsComponent extends BaseComponent implements OnInit, OnChanges {
    @Input()
    form: FormGroup;

    @Input()
    setId: string;

    @Input()
    ownerId: string;

    @Output()
    observationHovered: EventEmitter<string> = new EventEmitter();

    @Output()
    formStatusChanged: EventEmitter<boolean> = new EventEmitter();

    ItemType = ItemType;

    loading$: Observable<boolean>;
    loaded$: Observable<boolean>;
    items: Item[];
    pagedItems: Item[];
    selectedItemId: string;
    currentPage: number = 1;

    constructor(private store: Store<fromStore.SetObservationsState>) {
        super();
    }

    ngOnInit(): void {
        this.loading$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getLoading));
        this.loaded$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getLoaded));

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getItems)
        ).subscribe((items: Item[]) => {
            this.items = items;
            this.setPage(this.currentPage);
        });
    }

    ngOnChanges(): void {
        this.store.dispatch(new fromStore.GetSet(this.setId));
    }

    public setPage(pageNumber: number): void {
        this.currentPage = pageNumber;
        this.pagedItems = ItemsUtility.setPaging(pageNumber, this.currentPage, this.items);
    }

    addObservation(item: Item): void {
        this.store.dispatch(new fromStore.AddObservation(item.id));
    }

    deleteObservation(observationId: string): void {
        this.store.dispatch(new fromStore.DeleteObservation(observationId));
    }

    onValueUpdated(itemObservation: ItemObservation) {
        this.store.dispatch(new fromStore.UpdateObservation(itemObservation));
    }

    onSelectedChanged($event: string) {
        this.selectedItemId = $event;
    }

    onMultiListValueAdded(request: AddMultiOptionListValueRequest): void {
        this.store.dispatch(new fromStore.AddMultiOptionListObservation(request));
    }

    onImageListValueAdded(request: AddImageListValueRequest): void {
        this.store.dispatch(new fromStore.AddImageListObservation(request));
    }

    handleFieldActive(description: string): void {
        this.observationHovered.emit(description);
    }

    onFormStatusChanged($event: boolean): void {
        this.formStatusChanged.emit($event);
    }
}