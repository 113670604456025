import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Store
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, effects } from './store';

// Components
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TermsAcceptanceFormComponent } from './components/terms-acceptance-form/terms-acceptance-form.component';
import { AuthenticationLayoutComponent } from './components/authentication-layout/authentication-layout.component';

// Dialogs
import { SetTemporaryPasswordDialogComponent } from './dialogs/set-temporary-password-dialog/set-temporary-password-dialog.component';
// import { UserProfileDialogComponent } from './dialogs';
import { TimeoutExplanationDialogComponent } from './dialogs/timeout-explanation-dialog/timeout-explanation-dialog.component';

// Services
import { PasswordService } from './services/password.service';

// Modules
import { AuthenticationV2RoutingModule } from './authentication-v2-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { ConnectModule } from 'app/connect/connect.module';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        AuthenticationV2RoutingModule,
        StoreModule.forFeature(StoreNames.authenticationV2, reducers),
        EffectsModule.forFeature(effects),
        SharedModule,
        ConnectModule
    ],
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        TermsAcceptanceFormComponent,
        AuthenticationLayoutComponent,
        SetTemporaryPasswordDialogComponent,
        TimeoutExplanationDialogComponent
    ],
    entryComponents: [
        TermsAcceptanceFormComponent,
        TermsAcceptanceFormComponent,
        SetTemporaryPasswordDialogComponent,
        TimeoutExplanationDialogComponent
    ],
    providers: [
        PasswordService
    ]
})
export class AuthenticationV2Module { }
