import { Action } from '@ngrx/store';
import { Template } from 'app/portal/modules/document-management/models';
import { GeneratePdfPreviewRequest } from 'app/portal/modules/document-management/models/generate-pdf-preview-request.model';
import { ShareTemplateRequest } from 'app/portal/modules/document-management/models/share-template-request.model';

// action types
export const GET_DOCUMENTS = '[Documents] Get the documents';
export const GET_DOCUMENTS_SUCCESS = '[Documents] Get the documents success';
export const GET_DOCUMENTS_FAIL = '[Documents] Get the documents fail';

export const DELETE_DOCUMENT = '[Documents] Delete a document';
export const DELETE_DOCUMENT_SUCCESS = '[Documents] Delete a document success';
export const DELETE_DOCUMENT_FAIL = '[Documents] Delete a document fail';

export const GET_HTML_AS_PDF = '[Documents] Get a PDF representation of html markup';
export const GET_HTML_AS_PDF_SUCCESS = '[Documents] Get a PDF representation of html markup success';
export const GET_HTML_AS_PDF_FAIL = '[Documents] Get a PDF representation of html markup fail';

export const ADD_DOCUMENT = '[Document] Add the document';
export const UPDATE_DOCUMENT = '[Document] Update the document';
export const SAVE_DOCUMENT_SUCCESS = '[Document] Save the document success';
export const SAVE_DOCUMENT_FAIL = '[Document] Save the document fail';

export const GET_EXISTING_MARKUP = '[Document] Get existing document markup';
export const GET_EXISTING_MARKUP_SUCCESS = '[Document] Get existing document markup success';
export const GET_EXISTING_MARKUP_FAIL = '[Document] Get existing document markup fail';

export const GET_MARKUP_FOR_FILE = '[Document] Get the markup for an uploaded file';
export const GET_MARKUP_FOR_FILE_SUCCESS = '[Document] Get the markup for an uploaded file success';
export const GET_MARKUP_FOR_FILE_FAIL = '[Document] Get the markup for an uploaded file fail';

export const SHARE_DOCUMENT = '[Document] shares a document with another organisation';
export const SHARE_DOCUMENT_SUCCESS = '[Document] shares a document with another organisation success';
export const SHARE_DOCUMENT_FAIL = '[Document] shares a document with another organisation fail';

export const UNSHARE_DOCUMENT = '[Document] unshares a document with another organisation';
export const UNSHARE_DOCUMENT_SUCCESS = '[Document] unshares a document with another organisation success';
export const UNSHARE_DOCUMENT_FAIL = '[Document] unshares a document with another organisation fail';

export const GET_PDF_DOCUMENT = '[Document] Get the pdf document for a template that already exists';
export const GET_PDF_DOCUMENT_SUCCESS = '[Document] Get the pdf document for a template that already exists success';
export const GET_PDF_DOCUMENT_FAIL = '[Document] Get the pdf document for a template that already exists fail';

export const SET_SHARING_DOCUMENT_EDIT = '[Document] Sets the document for sharing';

export const CLEAR_STATE = '[Document] Clears the state of a template';

export class GetDocuments implements Action {
    readonly type = GET_DOCUMENTS;
}

export class GetDocumentsSuccess implements Action {
    readonly type = GET_DOCUMENTS_SUCCESS;

    constructor(public payload: Template[]) { }
}

export class GetDocumentsFail implements Action {
    readonly type = GET_DOCUMENTS_FAIL;
}

export class DeleteDocument implements Action {
    readonly type = DELETE_DOCUMENT;

    constructor(public payload: string) { }
}

export class DeleteDocumentSuccess implements Action {
    readonly type = DELETE_DOCUMENT_SUCCESS;

    constructor(public payload: string) { }
}

export class DeleteDocumentFail implements Action {
    readonly type = DELETE_DOCUMENT_FAIL;
}

export class GeneratePdf implements Action {
    readonly type = GET_HTML_AS_PDF;

    constructor(public payload: GeneratePdfPreviewRequest) { }
}

export class GeneratePdfSuccess implements Action {
    readonly type = GET_HTML_AS_PDF_SUCCESS;

    constructor(public payload: string) { }
}

export class GeneratePdfFail implements Action {
    readonly type = GET_HTML_AS_PDF_FAIL;
}

export class AddDocument implements Action {
    readonly type = ADD_DOCUMENT;

    constructor(public payload: FormData) { }
}

export class UpdateDocument implements Action {
    readonly type = UPDATE_DOCUMENT;
    constructor(public payload: FormData) { }
}

export class SaveDocumentSuccess implements Action {
    readonly type = SAVE_DOCUMENT_SUCCESS;
}

export class SaveDocumentFail implements Action {
    readonly type = SAVE_DOCUMENT_FAIL;
}

export class GetExistingMarkup implements Action {
    readonly type = GET_EXISTING_MARKUP;
    constructor(public payload: string) {}
}

export class GetMarkupForFile implements Action {
    readonly type = GET_MARKUP_FOR_FILE;
    constructor(public payload: FormData) {}
}

export class GetMarkupSuccess implements Action {
    readonly type = GET_EXISTING_MARKUP_SUCCESS;
    constructor(public payload: string) {}
}

export class GetMarkupFail implements Action {
    readonly type = GET_EXISTING_MARKUP_FAIL;
}

export class ClearState implements Action {
    readonly type = CLEAR_STATE;
}

export class SetSharingDocumentEdit implements Action {
    readonly type = SET_SHARING_DOCUMENT_EDIT;

    constructor(public payload: Template) {}
}

export class ShareDocument implements Action {
    readonly type = SHARE_DOCUMENT;

    constructor(public payload: ShareTemplateRequest) {}
}

export class ShareDocumentSuccess implements Action {
    readonly type = SHARE_DOCUMENT_SUCCESS;

    constructor(public payload: ShareTemplateRequest) {}
}

export class GetPdfDocument implements Action {
    readonly type = GET_PDF_DOCUMENT;
    constructor(public payload: string) {}
}

export class GetPdfDocumentSuccess implements Action {
    readonly type = GET_PDF_DOCUMENT_SUCCESS;
    constructor(public payload: Blob) {}
}

export class GetPdfDocumentFail implements Action {
    readonly type = GET_PDF_DOCUMENT_FAIL;
}

export class ShareDocumentFail implements Action {
    readonly type = SHARE_DOCUMENT_FAIL;
}

export class UnshareDocument implements Action {
    readonly type = UNSHARE_DOCUMENT;

    constructor(public payload: ShareTemplateRequest) {}
}

export class UnshareDocumentSuccess implements Action {
    readonly type = UNSHARE_DOCUMENT_SUCCESS;

    constructor(public payload: ShareTemplateRequest) {}
}

export class UnshareDocumentFail implements Action {
    readonly type = UNSHARE_DOCUMENT_FAIL;
}

export class GetPdfExportForms implements Action {
    readonly type = GET_PDF_DOCUMENT;
    constructor(public payload: string) {}
}

export class GetPdfExportFormsSuccess implements Action {
    readonly type = GET_PDF_DOCUMENT_SUCCESS;
    constructor(public payload: Blob) {}
}

export class GetPdfExportFormsFail implements Action {
    readonly type = GET_PDF_DOCUMENT_FAIL;
}

export type AllDocumentsActions
    = GetDocuments
    | GetDocumentsSuccess
    | GetDocumentsFail
    | DeleteDocument
    | DeleteDocumentSuccess
    | DeleteDocumentFail
    | GeneratePdf
    | GeneratePdfSuccess
    | GeneratePdfFail
    | AddDocument
    | UpdateDocument
    | SaveDocumentSuccess
    | SaveDocumentFail
    | GetExistingMarkup
    | GetMarkupForFile
    | GetMarkupSuccess
    | GetMarkupFail
    | SetSharingDocumentEdit
    | ClearState
    | GetPdfDocument
    | GetPdfDocumentSuccess
    | GetPdfDocumentFail
    | ShareDocument
    | ShareDocumentSuccess
    | ShareDocumentFail
    | UnshareDocument
    | UnshareDocumentSuccess
    | UnshareDocumentFail;