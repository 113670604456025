import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { BaseComponent } from 'app/shared/base/base-component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

// NGRX / RXJS
import { Store, select } from '@ngrx/store';
import { takeUntil, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Store
import * as fromUserActivity from 'app/store/';
import { GetUserActivity, GetOrganisationUserActivity } from 'app/store/actions/user-activity.actions';

// Components
import { FilterComponent } from 'app/connect/components/filter/filter.component';

// Enums
import { UserActivityType, userActivityTypeDescription } from 'app/shared/enums/user-activity-type.enum';

// Models
import { Filter } from 'app/models/filter/filter.model';
import { FilterConfiguration } from 'app/models/filter/filter-configuration.model';
import { ModuleDetails } from 'app/models/module-details.model';
import { UserActivityDetails } from 'app/shared/models/user-activity-details.model';


@Component({
    selector: 'app-user-activity',
    templateUrl: './user-activity.component.html',
    styleUrls: ['./user-activity.component.scss']
})

export class UserActivityComponent extends BaseComponent implements AfterViewInit {
    loading$: Observable<boolean>;
    loaded$: Observable<boolean>;

    dataSource = new MatTableDataSource(null);
    displayedColumns: string[];
    userActivity: UserActivityDetails[];
    filteredUserActivity: UserActivityDetails[];
    filterConfiguration: FilterConfiguration;

    @ViewChild('paginator')
    paginator: MatPaginator;

    @ViewChild('searchFilter')
    searchFilter: FilterComponent;

    private activityTypeOption: string = 'Activity Type';
    private organisationOption: string = 'Organisation';
    private userOption: string = 'User';

    constructor(
        private route: ActivatedRoute,
        private store: Store<fromUserActivity.State>) {
        super();
    }

    ngAfterViewInit() {
        this.loading$ = this.store.select(fromUserActivity.getUserActivityLoading);
        this.loaded$ = this.store.select(fromUserActivity.getUserActivityLoaded);

        this.route.params.pipe(
            takeUntil(this.ngUnsubscribe),
            map(param => {
                if (param.organisationId) {
                    this.store.dispatch(new GetOrganisationUserActivity(param.organisationId));
                } else if (param.userId) {
                    this.store.dispatch(new GetUserActivity(param.userId));
                }
            })).subscribe();

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromUserActivity.getUserActivityRecords))
            .subscribe((userActivity: UserActivityDetails[]) => {
                this.userActivity = userActivity;
                this.setupFilters();
                this.search(new Filter(this.filterConfiguration));
            });

        this.displayedColumns = [ 'date', 'activityType', 'organisation', 'user' ];
    }

    search(filter: Filter): void {
        if (this.userActivity) {

            const searchText = filter.searchText ? filter.searchText.toLocaleLowerCase().trim() : null;
            const activityType = parseInt(filter.getFilterOptionId(this.activityTypeOption), 10);
            const organisationId = filter.getFilterOptionId(this.organisationOption);
            const userId = filter.getFilterOptionId(this.userOption);

            this.filteredUserActivity = this.userActivity.filter(i =>
                (!searchText ||
                    i.user.email.toLocaleLowerCase().indexOf(searchText) > -1 ||
                    (i.module && i.module.title.toLocaleLowerCase().indexOf(searchText) > -1) ||
                    (i.organisation && i.organisation.title.toLocaleLowerCase().indexOf(searchText) > -1)) &&
                (isNaN(activityType) || i.activityType === activityType) &&
                (!organisationId || (i.organisation && i.organisation.id === organisationId)) &&
                (!userId || i.user.id === userId));

            this.dataSource = new MatTableDataSource(this.filteredUserActivity);
            this.dataSource.paginator = this.paginator;
        }
    }

    userActivityTypeDescription(value: UserActivityType, module: ModuleDetails): string {
        return userActivityTypeDescription(value, module);
    }

    private setupFilters(): void {
        if (this.userActivity) {
            this.filterConfiguration = new FilterConfiguration('Search activity');

            const addedActivities: UserActivityType[] = [];
            const addedOrganisations: string[] = [];
            const addedUsers: string[] = [];

            const activityTypeFilter = this.filterConfiguration.addOption(this.activityTypeOption);
            const organisationFilter = this.filterConfiguration.addOption(this.organisationOption);
            const userFilter = this.filterConfiguration.addOption(this.userOption);

            this.userActivity.forEach(i => {
                if (!addedActivities.includes(i.activityType)) {
                    activityTypeFilter.addValue(i.activityType.toString(), userActivityTypeDescription(i.activityType));
                    addedActivities.push(i.activityType);
                }
                if (i.organisation && !addedOrganisations.includes(i.organisation.id)) {
                    organisationFilter.addValue(i.organisation.id, i.organisation.title);
                    addedOrganisations.push(i.organisation.id);
                }
                if (!addedUsers.includes(i.user.id)) {
                    userFilter.addValue(i.user.id, i.user.email);
                    addedUsers.push(i.user.id);
                }
            });
        }
    }
}