import { Component } from '@angular/core';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-date',
    styleUrls: ['./date-observation.component.scss'],
    templateUrl: './date-observation.component.html',
    outputs: ['valueUpdated', 'selectedChanged']
})
export class DateObservationComponent extends ObservationComponent {

    public dateValue: string;

    constructor() {
        super();
    }

    init() {
        this.dateValue = this.form.get('observation').value;
    }

    onDateChanged($event: Date) {
        const newDateValue: string = $event ? $event.toObservationString() : null;

        if (this.dateValue !== newDateValue) {
            this.dateValue = newDateValue;
            this.updateValue(this.dateValue);
        }
    }
}
