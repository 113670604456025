import { Injectable } from '@angular/core';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

// store
import * as fromConnect from 'app/connect/store';

// services
import { ConnectService } from 'app/connect/services/connect.service';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// models
import { Branding } from 'app/models/branding.model';

@Injectable()
export class BrandingEffects {

    getDefaultBranding$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.GetDefaultBranding),
        withLatestFrom(fromConnect.getConnectBranding),
        switchMap((connectBranding: Branding) => {
            if (this.authenticationTokenService.usingV2()) {
                return of(fromConnect.GetDefaultBrandingSuccess({ branding: connectBranding }));
            }

            return this.connectService.getDefaultBranding$().pipe(
                map(branding => fromConnect.GetDefaultBrandingSuccess({ branding })),
                catchError(() => of(fromConnect.GetDefaultBrandingFail()))
            );
        })));

    constructor(private actions$: Actions,
        private authenticationTokenService: AuthenticationTokenService,
        private connectService: ConnectService) { }
}