import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

// ngrx / rxjs
import { Store } from '@ngrx/store';

// store
import * as fromRoot from 'app/store';

// services
import { ImageUploadService } from 'app/shared/services/image-upload.service';
import { VideoCaptureService } from 'app/shared/services/video-capture.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// utilities
import { getFileExtension } from 'app/shared/utilities/string-utilities';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { QrCodeDialogComponent } from 'app/shared/modules/mobile-photo/components/qr-code-dialog/qr-code-dialog.component';

// enums
import { ImageCaptureStage } from 'app/shared/enums/image-capture-stage.enum';
import { CameraFacingMode } from 'app/shared/enums/camera-facing-mode.enum';
import { FileExtensions, isJpg } from 'app/shared/enums/file-extensions.enum';

// models
import { ImageMetaData } from 'app/shared/models/images/image-metadata.model';
import { ImageView } from 'app/shared/models/images/image-view.model';
import { QrCodeData } from 'app/shared/modules/mobile-photo/models/qr-code-data.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-photo-capture',
    styleUrls: ['photo-capture.component.scss'],
    templateUrl: './photo-capture.component.html'
})

export class PhotoCaptureComponent extends BaseComponent implements OnInit {

    @Input() allowedFileExtensions: FileExtensions[] = [FileExtensions.Jpg, FileExtensions.Jpeg, FileExtensions.Png];
    @Input() maximumFileSizeMb: number = 10;
    @Input() downloadEnabled: boolean = true;
    @Input() disableUpload: boolean = false;
    @Input() convertToJpg: boolean = false;
    @Input() removeEnabled: boolean = false;
    @Input() alwaysHidePreview: boolean = false;
    @Input() qrCodeData: QrCodeData = null;
    @Input() useFrontFacingCamera: boolean = false;

    @Output() imageUploaded = new EventEmitter<ImageView>();
    @Output() imageLoading = new EventEmitter<boolean>();
    @Output() imageDownloadClicked = new EventEmitter<void>();
    @Output() imageRemoveClicked = new EventEmitter<void>();

    @ViewChild('videoDisplay') videoElement: ElementRef;
    @ViewChild('canvasElement') canvasElement: ElementRef;
    @ViewChild('imageDisplay') imageElement: ElementRef;
    @ViewChild('imageUpload') uploadElement: ElementRef;

    private imageFile: File;

    private videoStream: MediaStream;
    private devices: MediaDeviceInfo[];
    private deviceId: string;
    multipleDevices: boolean;
    private userSelectedDevice: boolean;

    private loading = false;
    private initialising = false;
    webcamAccess = false;
    isDesktop = false;
    cameraStarted = false;
    allowSwitchToMobile = false;
    captureType: string;

    private imageDetails: ImageView;
    stages = ImageCaptureStage;
    stage: ImageCaptureStage;

    private maximumFileSizeBytes: number;
    invalidFileExtension: boolean;
    invalidFileSize: boolean;
    processingError: boolean;
    processingErrorCode: string = '';
    imageSrc: string;

    showImagePreview: boolean = false;

    get isDebug(): boolean {
        return (location.search || '').indexOf('debug=') >= 0;
    }

    get isCanvasEmpty() {
        if (!this.canvasElement) {
            return;
        }

        const context = this.canvasElement.nativeElement.getContext('2d');

        const pixelBuffer = new Uint32Array(
            context.getImageData(0, 0, this.canvasElement.nativeElement.width, this.canvasElement.nativeElement.height).data.buffer
        );

        return !pixelBuffer.some(color => color !== 0);
    }

    get imageFileTypes(): string {
        if (!this.isDesktop) {
            return 'image/*';
        }

        if (this.allowedFileExtensions.filter(e => e === FileExtensions.Any).length > 0) {
            return FileExtensions.Any;
        }

        let types = '';
        for (const type of this.allowedFileExtensions) {
            if (types !== '') {
                types += ',';
            }

            types += `image/${type}`;
        }

        return types;
    }

    get canContinue(): boolean {
        if (this.stage === ImageCaptureStage.Confirm) {
            return !!this.imageDetails;
        }

        return true;
    }

    constructor(
        private imagesService: ImageUploadService,
        private videoService: VideoCaptureService,
        private alertService: AlertService,
        private authTokenService: AuthenticationTokenService,
        private dialogs: MatDialog,
        private store: Store<fromRoot.State>) {
        super();
    }

    ngOnInit(): void {
        this.stage = ImageCaptureStage.Intro;
        this.maximumFileSizeBytes = this.maximumFileSizeMb * 1048576;

        this.store.select(fromRoot.isMobileDevice).subscribe(isMobile => {
            this.isDesktop = !isMobile;

            if (this.isDesktop) {
                this.checkWebcamAccess();
                if (this.qrCodeData && !this.authTokenService.isClient()) {
                    this.allowSwitchToMobile = true;
                }
            }
        });

        this.captureType = this.useFrontFacingCamera ? 'user' : 'environment';
    }

    onDestroy(): void {
        this.stopWebCam();
    }

    setImage(image: Blob, name: string): void {
        this.initialising = true;
        this.processImage(image, name);
    }

    setImageBase64(base64Image: string, name: string): void {
        this.initialising = true;
        this.loadImage(base64Image, name);
    }

    clearImage(): void {
        this.imageSrc = null;
        this.showImagePreview = false;
    }

    downloadImageClicked() {
        this.imageDownloadClicked.emit();
    }

    imageRemovedClicked() {
        this.imageRemoveClicked.emit();
        this.stage = ImageCaptureStage.Intro;
    }

    submit(): void {
        this.clearImageProcessingError();
        switch (this.stage) {
            case ImageCaptureStage.Intro:
                this.stage = ImageCaptureStage.Capture;
                this.showImagePreview = false;
                this.init();
                break;

            case ImageCaptureStage.Capture:
                this.captureImage();
                this.stage = ImageCaptureStage.Intro;
                this.showImagePreview = true;
                break;
        }
    }

    upload() {
        this.clearImageProcessingError();
        this.uploadElement.nativeElement.click();
        this.showImagePreview = true;
    }

    back() {
        this.clearImageProcessingError();
        this.stage = ImageCaptureStage.Intro;
        this.showImagePreview = true;
        this.stopWebCam();
    }

    switchWebcam() {
        this.userSelectedDevice = true;
        this.stopWebCam();
        this.startSpecifiedWebcam(this.chooseNextDevice());
    }

    switchToMobileClicked(): void {
        this.dialogs.open(QrCodeDialogComponent, {
            disableClose: true,
            data: this.qrCodeData
        });
    }

    uploadImage(event: any) {
        if (event.target.files && event.target.files[0]) {

            const file: File = event.target.files[0];
            const ext = getFileExtension(file.name);
            this.invalidFileExtension = !(this.allowedFileExtensions.some(x => x === FileExtensions.Any) || this.allowedFileExtensions.some(x => x === ext));
            this.invalidFileSize = this.maximumFileSizeMb !== 0 && file.size > this.maximumFileSizeBytes;

            if (this.invalidFileExtension || this.invalidFileSize) {
                this.imageFile = null;
            } else {
                this.showImagePreview = true;
                this.imageFile = file;
                const conversionRequired = this.convertToJpg && !isJpg(ext);
                this.processImage(this.imageFile, this.imageFile.name, conversionRequired);
            }
        }
    }

    private init(): void {
        this.cameraStarted = false;
        this.imageDetails = null;

        if (this.canvasElement) {
            const context = this.canvasElement.nativeElement.getContext('2d');
            context.clearRect(0, 0, this.canvasElement.nativeElement.width, this.canvasElement.nativeElement.height);
        }

        this.startWebcam();
    }

    private checkWebcamAccess() {
        this.videoService.getAvailableVideoDevices()
            .subscribe((devices) => {
                this.devices = devices;
                this.webcamAccess = devices.length > 0;
                this.multipleDevices = devices.length > 1;
            });
    }

    private startWebcam() {
        if (this.userSelectedDevice) {
            this.userSelectedDevice = true;
            this.startSpecifiedWebcam(this.deviceId);
        } else {
            this.userSelectedDevice = true;
            this.startFrontWebcam();
        }
        this.cameraStarted = true;
    }

    private startFrontWebcam() {
        this.videoService.startWebcam(CameraFacingMode.Front)
            .subscribe((stream) => {
                this.videoElement.nativeElement.srcObject = stream;
                this.videoStream = stream;
                const track = this.videoStream.getTracks()[0];
                this.deviceId = this.deviceId = track.getSettings().deviceId;
                this.cameraStarted = true;
            }, (errorMsg) => {
                this.alertService.error(errorMsg);
                this.cameraStarted = false;
            });
    }

    private startSpecifiedWebcam(deviceId: string) {
        this.videoService.startSpecifiedWebcam(deviceId)
            .subscribe((stream) => {
                this.videoElement.nativeElement.srcObject = stream;
                this.videoStream = stream;
                this.deviceId = deviceId;
                this.cameraStarted = true;
            }, (errorMsg) => {
                this.alertService.error(errorMsg);
                this.cameraStarted = false;
            });
    }

    private stopWebCam() {
        if (this.cameraStarted) {
            this.videoService.stopWebcam(this.videoElement.nativeElement, this.videoStream);
            this.cameraStarted = false;
        }
    }

    private chooseNextDevice(): string {
        const currentDeviceIndex = this.devices.findIndex(d => d.deviceId === this.deviceId);
        const nextDeviceIndex = (currentDeviceIndex + 1 === this.devices.length) ? 0 : currentDeviceIndex + 1;
        return this.devices[nextDeviceIndex].deviceId;
    }

    private captureImage() {
        const canvas = this.canvasElement.nativeElement;
        const video = this.videoElement.nativeElement;
        const dataUrl = this.videoService.getImageFromVideo(video, canvas);
        this.loadImage(dataUrl, this.deviceId);
        this.imageDetails.metadata = new ImageMetaData(1, video.videoWidth, video.videoHeight);
        this.stopWebCam();
        this.imageUploaded.emit(this.imageDetails);
    }

    private loadImage(data: string, name: string) {
        const imageDetails = <ImageView>{};

        imageDetails.description = name;
        imageDetails.uploadedDataUrl = data;
        imageDetails.uploadedBase64 = this.imagesService.stripDataHeader(data);

        if (data !== null) {
            this.showImagePreview = true;
        }
        this.imageSrc = data;

        this.imageDetails = imageDetails;

        this.loading = true;
        this.imageLoading.emit(this.loading);
    }

    private processImage(imageFile: Blob, name: string, conversionRequired: boolean = false) {
        this.clearImageProcessingError();
        this.imagesService.getImageDataUrl(imageFile, (url) => {
            this.loadImage(url, name);
            if (!conversionRequired) {
                this.imagesService.getImageMetadataFromFile(imageFile)
                    .then((metadata: ImageMetaData) => {
                        this.completeImageProcessing(metadata);
                    })
                    .catch((error) => {
                        this.imageProcessingError(error.message);
                    });
            } else {
                this.imagesService.getBasicImageMetadata(url)
                    .then((metadata: ImageMetaData) => {
                        this.imagesService.convertToJpeg(metadata, this.canvasElement.nativeElement, url)
                            .then((convertedImageUrl) => {
                                this.loadImage(convertedImageUrl, name);
                                this.completeImageProcessing(metadata);
                            })
                            .catch((error: Error) => {
                                this.imageProcessingError(error.message);
                            });
                    })
                    .catch((error) => {
                        this.imageProcessingError(error.message);
                    });
            }
        });
    }

    private imageProcessingError(code: string) {
        this.clearImage();
        this.processingError = true;
        this.processingErrorCode = code;
        this.stage = ImageCaptureStage.Intro;
    }

    private clearImageProcessingError() {
        this.processingErrorCode = '';
        this.processingError = false;
    }

    private completeImageProcessing(metadata: ImageMetaData) {
        this.imageDetails.metadata = metadata;
        this.imageLoading.emit(false);

        if (!this.initialising) {
            this.imageUploaded.emit(this.imageDetails);
        }

        this.initialising = false;

        this.imageLoading.emit(false);
    }
}
