import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-support-text',
    templateUrl: './support-text.component.html',
    styleUrls: ['./support-text.component.scss']
})

export class SupportTextComponent {
    @Input()
    supportEmail: string;

    @Input()
    supportText: string;

    hasSupportText(): boolean {
        if (this.supportText && this.supportText !== '') {
            return true;
        }
        return false;
    }

    hasSupportEmail(): boolean {
        if (this.supportEmail && this.supportEmail !== '') {
            return true;
        }
        return false;
    }
}