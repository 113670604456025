<div id="document-wizard-step-3" fxLayout="column" fxLayoutGap="10px">
    <h2>Step 3 - Choose {{'labels.organisation.plural' | translate}} to share the document with.</h2>

    <div id="list-container">
        <h3>Signatories</h3>
        <div *ngFor="let organisation of signatureOrganisations" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="list-item button-class-hover-border">
            <img [src]="organisation.icon" />
            <div fxFlex>{{organisation.title}}</div>
            <mat-checkbox [disabled]="true" [checked]="organisation.selected"></mat-checkbox>
        </div>
        <div *ngIf="hasDataOrganisations">
            <h3>Data sources</h3>
            <div *ngFor="let organisation of dataOrganisations" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="list-item button-class-hover-border">
                <img [src]="organisation.icon" />
                <div fxFlex>{{organisation.title}}</div>
                <mat-checkbox (change)="handleSelectedChanged($event.checked, organisation)" [checked]="organisation.selected"></mat-checkbox>
            </div>
            <hr/>
        </div>
        </div>
    </div>