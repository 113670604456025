// Models
import { AssessmentProfileBadgeResponse } from './responses/assessment-profile-badge-response.model';

export class IncompleteBadgeDialogData {
    badges: AssessmentProfileBadgeResponse[];
    ignoreStatus: boolean;
    entityName: string;

    constructor(
        badges: AssessmentProfileBadgeResponse[],
        entityName: string,
        ignoreStatus: boolean = false) {

            this.badges = badges;
            this.ignoreStatus = ignoreStatus;
            this.entityName = entityName;
    }
}