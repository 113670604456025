import { Action } from '@ngrx/store';
import { AssessmentProfileTagResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-tag-response.model';

export const GET_ORGANISATION_TAGS = '[Organisation Management] Get the tags for an organisation';
export const GET_ORGANISATION_TAGS_SUCCESS = '[Organisation Management] Get the tags for an organisation success';
export const GET_ORGANISATION_TAGS_FAIL = '[Organisation Management] Get the tags for an organisation fail';

export class GetOrganisationTags implements Action {
    readonly type = GET_ORGANISATION_TAGS;
    constructor(public payload: string) { }
}

export class GetOrganisationTagsSuccess implements Action {
    readonly type = GET_ORGANISATION_TAGS_SUCCESS;
    constructor(public payload: AssessmentProfileTagResponse[]) { }
}

export class GetOrganisationTagsFail implements Action {
    readonly type = GET_ORGANISATION_TAGS_FAIL;
}

export type AllOrganisationManagementTagActions
    = GetOrganisationTags
    | GetOrganisationTagsSuccess
    | GetOrganisationTagsFail;
