import { AssessmentProfileBadgeResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-badge-response.model';

export class BadgeProfileDialogData {
    badge: AssessmentProfileBadgeResponse;
    organisationId: string;
    readonly: boolean;
    entityName: string;

    constructor(badge: AssessmentProfileBadgeResponse, organisationId: string, readonly: boolean, entityName: string) {
        this.badge = badge;
        this.organisationId = organisationId;
        this.readonly = readonly;
        this.entityName = entityName;
    }
}