import { ObservationResponse } from './observation-response.model';
import { newId } from 'app/shared/utilities/string-utilities';

export class ItemObservationResponse {
    id: string;
    lastUpdated: Date;
    observation: ObservationResponse;

    constructor(observation: ObservationResponse) {
        this.id  = newId();
        this.observation = observation;
    }
}