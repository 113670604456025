import { Injectable } from '@angular/core';

// ngrx/rxjs
import { Action } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';

// Store
import * as actions from 'app/store/actions/organisation.actions';

// Services
import { OrganisationsService } from 'app/shared/services';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// Models
import { UpdateGeneralInformationRequest } from 'app/shared/models/update-general-information-request.model';
import { RemoveBadgeRequest } from 'app/shared/models/remove-badge-request.model';

@Injectable()
export class OrganisationEffects {


    updateGeneralInformation$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(actions.UPDATE_GENERAL_INFORMATION),
        map((action: actions.UpdateGeneralInformation) => action.payload),
        switchMap((request: UpdateGeneralInformationRequest) =>
            this.organisationService.updateGeneralInformation(request).pipe(
                map(() => new actions.UpdateGeneralInformationSuccess()),
                catchError(() => of(new actions.UpdateGeneralInformationFail()))
            ))));


    updateGeneralInformationSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(actions.UPDATE_GENERAL_INFORMATION_SUCCESS),
        tap(() => this.alertService.success('The information was updated successfully.'))), { dispatch: false });


    updateGeneralInformationFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(actions.UPDATE_GENERAL_INFORMATION_FAIL),
        tap(() => this.alertService.error('The information could not be updated.'))), { dispatch: false });


    removeBadge$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(actions.REMOVE_BADGE),
        map((action: actions.RemoveBadge) => action.payload),
        switchMap((request: RemoveBadgeRequest) => this.organisationService.removeBadge(request).pipe(
                map(() => new actions.RemoveBadgeSuccess()),
                catchError(() => of(new actions.RemoveBadgeFail()))))
    ));

    constructor(
        private actions$: Actions,
        private organisationService: OrganisationsService,
        private alertService: AlertService) { }
}