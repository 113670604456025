import { User } from 'app/shared/models/user.model';

export class OrganisationUsers {
    organisationId: string;
    users: User[];

    constructor(organisationId: string, users: User[]) {
        this.organisationId = organisationId;
        this.users = users;
    }
}
