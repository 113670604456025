import { Input, Component, OnInit } from '@angular/core';

// Models
import { AddressResult } from 'app/shared/models/address-result.model';

@Component({
    selector: 'app-view-postcode-observation',
    templateUrl: 'view-postcode-observation.component.html'
})
export class ViewPostcodeObservationComponent implements OnInit {
    @Input()
    observationValue: string;

    public address: AddressResult;

    ngOnInit(): void {
        this.address = JSON.parse(this.observationValue);
    }
}