<div *ngIf="idDocuments" fxLayout="column" fxLayoutGap="10px">
    <div
        *ngFor="let document of idDocuments.documents"
        class="document"
        [ngClass]="getClass(document)">
        <div fxLayout="row" fxLayoutAlign="start top">
            <div>
                <mat-icon>{{getIcon(document)}}</mat-icon>
            </div>
            <div fxFlex fxLayout="column">
                <div fxLayout="column" fxLayout.gt-xs="row">
                    <div fxFlex.gt-xs="33.33%">
                        <h3>{{document.description}} {{document.documentSide | documentSide:''}}</h3>
                    </div>
                    <div fxFlex.gt-xs="33.33%">
                        <strong>Scan date:</strong> {{document.dateCreated | ukdate:'time' }}
                    </div>
                    <div fxFlex.gt-xs="33.33%">
                        <a (click)="viewDocument(document)">View Document</a>
                    </div>
                </div>

                <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="start center">
                    <div fxFlex.gt-xs="33.33%"><strong>Status:</strong> {{getDocumentStatusTitle(document)}}</div>
                    <div fxFlex.gt-xs="33.33%"><strong>Facial Match:</strong> {{getResultStatusTitle(document.facialMatch, document.documentSide)}}</div>
                    <div fxFlex.gt-xs="33.33%"><strong>Name Check:</strong> {{getNameCheckStatusTitle(document)}}</div>
                </div>
                <div *ngIf="viewFullDetails && isExpired(document)" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="start center">
                    <div fxFlex.gt-xs="33.33%"><strong>Expiry date:</strong> {{document.expiryDate | ukdate }}</div>
                </div>
            </div>
        </div>
    </div>
    <app-loading-card  *ngIf="loadingDocument" message="Please wait, fetching ID Document"></app-loading-card>
</div>
