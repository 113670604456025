import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { BaseComponent } from 'app/shared/base/base-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

// Store
import * as fromRoot from 'app/store';
import * as userInterfaceActions from 'app/store/actions/user-interface.actions';
import { Store, select } from '@ngrx/store';

// Components
import { AssessmentSetDialogComponent } from 'app/shared/modules/set-observations/components/assessment-set-dialog/assessment-set-dialog.component';

// Models
import { AssessmentProfileBadgeResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-badge-response.model';
import { AssessmentProfileSetResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-set-response.model';
import { IncompleteBadgeDialogData } from 'app/shared/modules/set-observations/models/incomplete-badge-dialog-data.model';

// Enums
import { BadgeStatus } from 'app/shared/enums/accreditation/badge-status.enum';

@Component({
    templateUrl: 'incomplete-mandatory-badges-dialog.component.html',
    styleUrls: ['incomplete-mandatory-badges-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IncompleteMandatoryBadgesDialogComponent extends BaseComponent implements OnInit {
    incompleteBadges: AssessmentProfileBadgeResponse[];
    currentBadge: AssessmentProfileBadgeResponse;
    currentSets: AssessmentProfileSetResponse[];
    sets: { name: string; complete: boolean }[] = [];
    icon: string;
    buttonText: string = 'Get started';
    entityName: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: IncompleteBadgeDialogData,
        private dialogRef: MatDialogRef<IncompleteMandatoryBadgesDialogComponent>,
        private dialogs: MatDialog,
        private activatedRoute: ActivatedRoute,
        private store: Store<fromRoot.State>) {
        super();
        }

    ngOnInit(): void {
        // check we have all mandatory, incomplete badges
        this.entityName = this.data.entityName;
        this.incompleteBadges = this.data.ignoreStatus ? this.data.badges : this.data.badges.filter(b => b.status !== BadgeStatus.Complete);
        this.currentBadge = this.incompleteBadges[0];
        this.getSetNames();

        this.store.pipe(
            take(1),
            select(fromRoot.getRTWShown))
            .subscribe((hasBeenShown: boolean) => {
                this.activatedRoute.queryParams.subscribe(params => {
                    if (params.result && params.badgeId && !hasBeenShown) {
                        this.currentBadge = this.incompleteBadges.find(x => x.id === params.badgeId);
                        this.runNextSets();
                        this.store.dispatch(new userInterfaceActions.SetRTWShown());
                    }
                });
            });
    }

    runNextSets(): void {
        let currentIndex = this.incompleteBadges.indexOf(this.currentBadge);
        this.store.dispatch(new userInterfaceActions.SetCurrentBadgeId(this.currentBadge.id));
        const setDialogRef = this.dialogs.open(AssessmentSetDialogComponent, {
            autoFocus: false,
            data: {
                entityName: this.entityName,
                ownerTitle: this.currentBadge.title,
                sets: this.currentSets
            },
            disableClose: true
        });

        setDialogRef.afterClosed().subscribe(() => {
            if (currentIndex === this.incompleteBadges.length - 1) {
                this.dialogRef.close();
            } else {
                currentIndex++;
                this.currentBadge = this.incompleteBadges[currentIndex];
                this.getSetNames();
                this.buttonText = 'Answer questions';
            }
        });
    }

    private getSetNames(): void {
        this.currentSets = [];
        this.sets = [];

        this.currentBadge.categories.forEach(category => {
            category.sets.forEach(set => {
                if (this.currentSets.indexOf(set) === -1) {
                    this.currentSets.push(set);
                }
            });
        });

        this.currentSets = this.currentSets.sort((set1, set2) => set1.order - set2.order);

        this.currentSets.forEach((v: AssessmentProfileSetResponse) => {
            this.sets.push({ name: v.name, complete: v.mandatoryItems === v.mandatoryCompleteItems });
        });
    }
}