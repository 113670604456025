// Angular
import { Injectable } from '@angular/core';

// ngrx/rxjs
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, tap, mergeMap } from 'rxjs/operators';

// Store
import * as fromActions from 'app/portal/modules/document-library-v1/store/actions';

// Services
import { DocumentLibraryService } from 'app/portal/modules/document-library-v1/services';
import { TemplateService } from 'app/portal/modules/document-management/services';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// Models
import { GeneratePreviewRequest } from 'app/portal/modules/document-library-v1/models/generate-preview-request.model';
import { CreateDocumentRequest } from 'app/portal/modules/document-library-v1/models/create-document-request.model';
import { User } from 'app/shared/models/user.model';
import { OrganisationUsers } from 'app/portal/modules/document-library-v1/models/organisation-users.model';

@Injectable()
export class DocumentLibraryEffects {

    getAllDocuments$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_ALL_DOCUMENTS),
        switchMap(() => this.documentLibraryService.getAllDocuments().pipe(
                map(documents => new fromActions.GetAllDocumentsSuccess(documents)),
                catchError(() => of(new fromActions.GetAllDocumentsFail()))
            ))
    ));


    getOrganisationDocuments$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_ORGANISATION_DOCUMENTS),
        map((action: fromActions.GetOrganisationDocuments) => action.payload),
        mergeMap((organisationId: string) => this.documentLibraryService.getOrganisationDocuments(organisationId).pipe(
                map(documents => new fromActions.GetAllDocumentsSuccess(documents)),
                catchError(() => of(new fromActions.GetAllDocumentsFail()))
            ))
    ));


    generatePreview$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GENERATE_PREVIEW),
        map((action: fromActions.GeneratePreview) => action.payload),
        switchMap((request: GeneratePreviewRequest) => this.templateService.getPreview(request.templateId, request.organisationIds).pipe(
                map(blob => new fromActions.GeneratePreviewSuccess(blob)),
                catchError(() => of(new fromActions.GeneratePreviewFail()))
            ))));


    createDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.CREATE_DOCUMENT),
        map((action: fromActions.CreateDocument) => action.payload),
        switchMap((request: CreateDocumentRequest) => this.documentLibraryService.createDocument(request).pipe(
                map(() => new fromActions.CreateDocumentSuccess()),
                catchError(() => of(new fromActions.CreateDocumentFail()))
            ))));


    createDocumentSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.CREATE_DOCUMENT_SUCCESS),
        tap(() => {
            this.alertService.success('The document was created successfully.');
        })), { dispatch: false });


    createDocumentFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.CREATE_DOCUMENT_FAIL),
        tap(() => {
            this.alertService.error('The document could not be created.');
        })), { dispatch: false });


    deleteDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.DELETE_DOCUMENT),
        map((action: fromActions.DeleteDocument) => action.payload),
        switchMap((documentId: string) => this.documentLibraryService.deleteDocument(documentId).pipe(
                map(() => new fromActions.DeleteDocumentSuccess(documentId)),
                catchError(() => of(new fromActions.DeleteDocumentFail()))
            ))
    ));


    deleteDocumentSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.DELETE_DOCUMENT_SUCCESS),
        tap(() => this.alertService.success('The document has been deleted.')
        )), { dispatch: false });


    deleteDocumentFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.DELETE_DOCUMENT_FAIL),
        tap(() => this.alertService.error('The document could not be deleted.')
        )), { dispatch: false });


    downloadDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.DOWNLOAD_DOCUMENT),
        map((action: fromActions.DownloadDocument) => action.payload),
        switchMap((documentId: string) => this.documentLibraryService.downloadDocument(documentId).pipe(
                map((blob: Blob) => new fromActions.DownloadDocumentSuccess(blob)),
                catchError(() => of(new fromActions.DownloadDocumentFail()))
            ))
    ));


    getOrganisationUsers$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_ORGANISATION_USERS),
        map((action: fromActions.GetOrganisationUsers) => action.payload),
        mergeMap((organisationId: string) => this.documentLibraryService.getOrganisationUsers(organisationId).pipe(
                map((users: User[]) => new fromActions.GetOrganisationUsersSuccess(new OrganisationUsers(organisationId, users))),
                catchError(() => of(new fromActions.GetOrganisationUsersFail()))
            ))
    ));


    getEsignDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_ESIGN_DOCUMENT),
        map((action: fromActions.GetEsignDocument) => action.payload),
        mergeMap((documentId: string) => this.documentLibraryService.getEsignDocument(documentId).pipe(
                map((pdf: Blob) => new fromActions.GetEsignDocumentSuccess(pdf)),
                catchError(() => of(new fromActions.GetEsignDocumentFail()))
            ))
    ));

    constructor(
        private actions$: Actions,
        private documentLibraryService: DocumentLibraryService,
        private templateService: TemplateService,
        private alertService: AlertService) {}
}
