import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromRoot from 'app/store';

// components
import { TakePhotoDialogComponent } from 'app/shared/modules/identity-verification/components/take-photo-dialog/take-photo-dialog.component';

// services
import { ImageUploadService } from 'app/shared/services';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

@Component({
    selector: 'app-idv-uap-step',
    templateUrl: './uap-step.component.html',
    styleUrls: ['./uap-step.component.scss']
})
export class UapStepComponent {

    @Input()
    uapImageUrl: string;

    @Output()
    uapTaken: EventEmitter<string> = new EventEmitter();

    @Output()
    switchToMobile: EventEmitter<void> = new EventEmitter();

    isMobileDevice$: Observable<boolean>;
    allowSwitchToMobile = false;

    constructor(
        rootStore: Store<fromRoot.State>,
        private imageUploadService: ImageUploadService,
        private authTokenService: AuthenticationTokenService,
        private dialogs: MatDialog) {
            this.isMobileDevice$ = rootStore.select(fromRoot.isMobileDevice);
            this.allowSwitchToMobile = !this.authTokenService.isClient();
        }

    handleFileUploaded(event: any): void {
        if (event.target.files && event.target.files[0]) {

            const file: File = event.target.files[0];
            this.imageUploadService.getBase64(file).then((base64Image: string) => {
                this.uapTaken.emit(base64Image);
            });
        }
    }

    handleTakePhotoClick(): void {
        const dialog = this.dialogs.open(TakePhotoDialogComponent, { disableClose: true });
        dialog.afterClosed().subscribe((base64Image: string) => {
            if (base64Image) {
                this.uapTaken.emit(base64Image);
            }
        });
    }

    handleSwitchToMobileClick(): void {
        this.switchToMobile.emit();
    }
}