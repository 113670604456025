// Models
import { OrganisationTitle } from 'app/shared/models/organisation-title.model';
import { DocumentLibrarySignature } from './document-library-signature.model';

// Enums
import { DocumentType } from 'app/portal/modules/document-management/enums/document-types.enum';

export class DocumentLibraryItem {
    id: string;
    ownerOrganisation: OrganisationTitle;
    templateId: string;

    name: string;
    templateName: string;
    revision: number;
    documentType: DocumentType;
    usesESignature: boolean;
    fullySigned: boolean;
    dateCreated: Date;
    esignDocumentUrl: string;
    isOwner: boolean;
    canDelete: boolean;
    isSignatory: boolean;
    sharedWithOrganisations: OrganisationTitle[];
    documentSignatures: DocumentLibrarySignature[];
}
