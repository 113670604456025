import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFeature from 'app/portal/modules/document-library-v1/store/reducers';
import * as fromDocumentLibrary from 'app/portal/modules/document-library-v1/store/reducers/document-library.reducer';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getDocumentsFeatureState = createFeatureSelector<fromFeature.DocumentsState>(StoreNames.documentLibrary);
const getDocumentLibraryState = createSelector(getDocumentsFeatureState, (state: fromFeature.DocumentsState) => state.documentLibraryState);

export const getAllDocuments = createSelector(getDocumentLibraryState, (state: fromDocumentLibrary.DocumentLibraryState) => state.documents);
export const getLoading = createSelector(getDocumentLibraryState, (state: fromDocumentLibrary.DocumentLibraryState) => state.loading);
export const getPreview = createSelector(getDocumentLibraryState, (state: fromDocumentLibrary.DocumentLibraryState) => state.preview);
export const getPreviewLoading = createSelector(getDocumentLibraryState, (state: fromDocumentLibrary.DocumentLibraryState) => state.previewLoading);
export const getOrganisationUsers = createSelector(getDocumentLibraryState, (state: fromDocumentLibrary.DocumentLibraryState) => state.organisationUsers);
export const getPdf = createSelector(getDocumentLibraryState, (state: fromDocumentLibrary.DocumentLibraryState) => state.pdf);
