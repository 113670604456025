// ngrx
import { ActionReducerMap } from '@ngrx/store';

// store
import * as fromApplication from './application.reducer';
import * as fromBranding from './branding.reducer';
import * as fromBreadcrumb from './breadcrumb.reducer';
import * as fromUserInterface from './user-interface.reducer';
import * as fromHelpFaq from './help-faq.reducer';
import * as fromTooltip from './tooltip.reducer';

export interface ConnectStoreState {
    application: fromApplication.ApplicationState;
    breadcrumbs: fromBreadcrumb.BreadcrumbState;
    branding: fromBranding.BrandingState;
    userInterface: fromUserInterface.UserInterfaceState;
    helpFaq: fromHelpFaq.HelpFaqState;
    tooltip: fromTooltip.TooltipState;
}

export const reducers: ActionReducerMap<ConnectStoreState> = {
    application: fromApplication.reducer,
    branding: fromBranding.reducer,
    breadcrumbs: fromBreadcrumb.reducer,
    userInterface: fromUserInterface.reducer,
    helpFaq: fromHelpFaq.reducer,
    tooltip: fromTooltip.reducer
};

export { ApplicationState } from './application.reducer';
export { BrandingState } from './branding.reducer';
export { BreadcrumbState } from './breadcrumb.reducer';
export { UserInterfaceState } from './user-interface.reducer';
export { HelpFaqState } from './help-faq.reducer';
export { TooltipState } from './tooltip.reducer';
