import { Injectable } from '@angular/core';

// ngrx/rxjs
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';

// store
import * as fromActions from 'app/portal/modules/document-management/store/actions';

// services
import { TemplateService } from 'app/portal/modules/document-management/services';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// models
import { GeneratePdfPreviewRequest } from 'app/portal/modules/document-management/models/generate-pdf-preview-request.model';
import { ShareTemplateRequest } from 'app/portal/modules/document-management/models/share-template-request.model';

@Injectable()
export class DocumentEffects {

    getDashboard$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_DOCUMENTS),
        switchMap(() => this.documentService.getTemplates().pipe(
                map(documents => new fromActions.GetDocumentsSuccess(documents)),
                catchError(() => of(new fromActions.GetDocumentsFail()))
            ))
    ));


    deleteDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.DELETE_DOCUMENT),
        map((action: fromActions.DeleteDocument) => action.payload),
        switchMap((templateId: string) => this.documentService.deleteDocument(templateId).pipe(
                map(id => new fromActions.DeleteDocumentSuccess(id)),
                catchError(() => of(new fromActions.DeleteDocumentFail()))))
    ));


    deleteDocumentSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.DELETE_DOCUMENT_SUCCESS),
        tap(() => {
            this.alertService.success('The document was deleted');
        })
    ), { dispatch: false });


    deleteDocumentFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.DELETE_DOCUMENT_FAIL),
        tap(() => {
            this.alertService.error('The document could not be deleted');
        })
    ), { dispatch: false });


    getGenerateDocumentPreview$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_HTML_AS_PDF),
        map((action: fromActions.GeneratePdf) => action.payload),
        switchMap((generateRequest: GeneratePdfPreviewRequest) => this.documentService.getDocumentPreview(generateRequest).pipe(
                map(pdf => new fromActions.GeneratePdfSuccess(pdf)),
                catchError(() => of(new fromActions.GeneratePdfFail()))
            ))
    ));


    addDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ADD_DOCUMENT),
        map((action: fromActions.AddDocument) => action.payload),
        switchMap((form: FormData) => this.documentService.addTemplate(form).pipe(
                map(() => new fromActions.SaveDocumentSuccess()),
                catchError(() => of (new fromActions.SaveDocumentFail()))
            ))
    ));


    updateDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.UPDATE_DOCUMENT),
        map((action: fromActions.UpdateDocument) => action.payload),
        switchMap((form: FormData) => this.documentService.updateTemplate(form).pipe(
                map(() => new fromActions.SaveDocumentSuccess()),
                catchError(() => of (new fromActions.SaveDocumentFail()))
            ))
    ));


    getExistingDocumentMarkup$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_EXISTING_MARKUP),
        map((action: fromActions.GetExistingMarkup) => action.payload),
        switchMap((templateId: string) => this.documentService.getTemplateDocumentMarkup(templateId).pipe(
                map((html: string) => new fromActions.GetMarkupSuccess(html)),
                catchError(() => of(new fromActions.GetMarkupFail()))
            ))
    ));


    getUploadedFileMarkup$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_MARKUP_FOR_FILE),
        map((action: fromActions.GetMarkupForFile) => action.payload),
        switchMap((uploadedFile: FormData) => this.documentService.getDocumentMarkup(uploadedFile).pipe(
                map((html: string) => new fromActions.GetMarkupSuccess(html)),
                catchError(() => of(new fromActions.GetMarkupFail()))
            ))
    ));


    shareDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.SHARE_DOCUMENT),
        map((action: fromActions.ShareDocument) => action.payload),
        switchMap((request: ShareTemplateRequest) => this.documentService.addShare(request).pipe(
                map(() => new fromActions.ShareDocumentSuccess(request)),
                catchError(() => of(new fromActions.ShareDocumentFail()))))));


    unshareDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.UNSHARE_DOCUMENT),
        map((action: fromActions.UnshareDocument) => action.payload),
        switchMap((request: ShareTemplateRequest) => this.documentService.removeShare(request).pipe(
                map(() => new fromActions.UnshareDocumentSuccess(request)),
                catchError(() => of(new fromActions.UnshareDocumentFail()))))));


    getPdfDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromActions.GET_PDF_DOCUMENT),
        map((action: fromActions.GetPdfDocument) => action.payload),
        switchMap((templateId: string) => this.documentService.getPdf(templateId).pipe(
                map((pdf: Blob) => new fromActions.GetPdfDocumentSuccess(pdf)),
                catchError(() => of(new fromActions.GetPdfDocumentFail()))
            ))
    ));

    constructor(
        private actions$: Actions,
        private documentService: TemplateService,
        private alertService: AlertService) {}
}