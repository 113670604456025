// ngrx
import { ActionReducerMap } from '@ngrx/store';

// store
import * as fromMobilePhoto from './mobile-photo.reducer';

export interface MobilePhotoStoreState {
    mobilePhoto: fromMobilePhoto.MobilePhotoState;
}

export const reducers: ActionReducerMap<MobilePhotoStoreState> = {
    mobilePhoto: fromMobilePhoto.reducer,
};

export { MobilePhotoState } from './mobile-photo.reducer';
