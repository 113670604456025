import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

// Models
import { SetResponse } from 'app/shared/modules/set-observations/models/responses/set-response.model';
import { RecordObservationsRequest } from 'app/shared/modules/set-observations/models/requests/record-observations-request.model';
import { AssessmentProfileResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-response.model';
import { GetOrganisationProfileForGroupRequest } from 'app/shared/modules/set-observations/models/requests/get-organisation-profile-for-group-request.model';
import { GetESignDocumentRequest } from 'app/shared/modules/set-observations/models/requests/get-esign-document-request.model';
import { ESignDocumentResponse } from 'app/shared/modules/set-observations/models/responses/esign-document-response.model';
import { GetOrganisationProfileByOwnerRequest } from 'app/shared/modules/set-observations/models/requests/get-organisation-profile-by-owner-request.model';
import { GetCredasRegistrationUrlRequest } from 'app/shared/modules/set-observations/models/requests/get-credas-registration-url-request.model';
import { Base64Blob } from 'app/shared/models/base64-blob.model';
import { BadgeSummary } from 'app/shared/modules/set-observations/models/responses/badge-summary.model';
import { UapImage } from 'app/shared/modules/set-observations/models/responses/uap-image.model';
import { ItemObservation } from 'app/shared/modules/set-observations/models/item-observation.model';
import { ObservationRequest } from 'app/shared/modules/set-observations/models/requests/observation-request.model';
import { IdDocumentsResponse } from 'app/shared/modules/set-observations/models/responses/id-documents-response.model';
import { AddIdDocumentRequest } from 'app/shared/modules/set-observations/models/requests/add-id-document-request.model';
import { CourseSummary } from 'app/shared/modules/set-observations/models/responses/course-summary.model';
import { CourseDetail } from 'app/shared/modules/set-observations/models/responses/course-detail.model';
import { IdDocumentUrl } from 'app/shared/modules/set-observations/models/responses/id-document-url.model';

// Utilities
import { setTimeoutHeader } from 'app/shared/utilities/request-utilities';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class SetObservationService {

    constructor(private http: HttpClient) { }

    public getSet(setId: string): Observable<SetResponse> {
        const url = `api/sets/${setId}`;
        return this.http.get<SetResponse>(url);
    }

    public recordObservations(request: RecordObservationsRequest): Observable<void> {
        const url = 'api/observations';
        return this.http.post<void>(url, request, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public recordImmediateObservation(itemObservation: ItemObservation): Observable<void> {
        const url = 'api/observations';

        const observationRequest = new ObservationRequest(
            itemObservation.observationId,
            itemObservation.itemId,
            itemObservation.value,
            itemObservation.base64File,
            itemObservation.itemType,
            itemObservation.contentType,
            itemObservation.metaData);

        const request = new RecordObservationsRequest(
            itemObservation.setId,
            itemObservation.ownerId,
            itemObservation.groupId,
            [ observationRequest ]);

        return this.http.post<void>(url, request, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getOrganisationProfile(organisationId: string): Observable<AssessmentProfileResponse> {
        const url = `api/organisations/${organisationId}/profile`;
        return this.http.get<AssessmentProfileResponse>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getUserAssessmentProfiles(): Observable<AssessmentProfileResponse[]> {
        const url = `api/user-profiles`;
        return this.http.get<AssessmentProfileResponse[]>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getGroupOrganisationProfile(request: GetOrganisationProfileForGroupRequest): Observable<AssessmentProfileResponse> {
        const url = `api/organisations/${request.organisationId}/groups/${request.groupId}/group-nodes/${request.groupNodeId}/profile`;

        return this.http.get<AssessmentProfileResponse>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getOrganisationProfileByOwner(request: GetOrganisationProfileByOwnerRequest): Observable<AssessmentProfileResponse> {
        const url = `api/organisations/${request.organisationId}/profile?ownerId=${request.ownerId}`;
        return this.http.get<AssessmentProfileResponse>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public createNewOrganisationSets(organisationId: string): Observable<boolean> {
        const url = `api/organisations/${organisationId}/create-new-sets`;
        return this.http.get<boolean>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getDocument(observationId: string): Observable<Blob> {
        const url = `api/observations/${observationId}/document`;
        return this.http.get<Blob>(url, { responseType: 'blob' as 'json', observe: 'response', headers: { timeout: '600000' }  }).pipe(map((response: HttpResponse<Blob>) => response.body));
    }

    public getDocumentBase64(observationId: string): Observable<Base64Blob> {
        const url = `api/observations/${observationId}/document-base64`;
        return this.http.get<Base64Blob>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getESignDocument(request: GetESignDocumentRequest): Observable<ESignDocumentResponse> {
        const url = `api/sets/${request.setId}/items/${request.itemId}/esign-document`;
        return this.http.get<ESignDocumentResponse>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getCredasRegistrationUrl(request: GetCredasRegistrationUrlRequest): Observable<string> {
        const url = `api/sets/${request.setId}/items/${request.itemId}/credas-registration-url?returnUrl=${request.returnUrl}`;
        return this.http.get<string>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getBadgeSummaries(setIds: string[]): Observable<BadgeSummary[]> {
        const url = 'api/observations/badge-summaries';

        // performing a POST because the setids are of indeterminate length
        return this.http.post<BadgeSummary[]>(url, setIds, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getUapImageUrl(organisationId: string): Observable<UapImage> {
        const url = `api/organisations/${organisationId}/uap-image`;
        return this.http.get<UapImage>(url, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public addIdDocument(request: AddIdDocumentRequest): Observable<IdDocumentsResponse> {
        const url = 'api/observations/add-id-document';
        return this.http.post<IdDocumentsResponse>(url, request, setTimeoutHeader(parseInt(environment.createSetsTimeout, 10)));
    }

    public getCourses(startDate: Date): Observable<CourseSummary[]> {
        const url = `api/courses?startDate=${startDate.toDateString()}`;
        return this.http.get<CourseSummary[]>(url);
    }

    public getCourseDetails(reference: string): Observable<CourseDetail> {
        const url = `api/courses/${reference}`;
        return this.http.get<CourseDetail>(url);
    }

    public getIdDocumentUrls(organisationId: string): Observable<IdDocumentUrl[]> {
        const url = `api/organisations/${organisationId}/id-documents/urls`;
        return this.http.get<IdDocumentUrl[]>(url);
    }
}