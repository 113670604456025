// Store
import * as fromActions from 'app/shared/modules/group/store/actions/manage-assessment-set.actions';

// Models
import { AssessmentSetSummary } from 'app/shared/modules/group/models/assessment-set-summary.model';
import { BadgeSummary } from 'app/shared/modules/group/models/badge-summary.model';

export interface ManageAssessmentSetState {
    assessmentSets: AssessmentSetSummary[];
    badges: BadgeSummary[];
    assessmentSetsLoading: boolean;
    badgesLoading: boolean;
}

const initialState: ManageAssessmentSetState = {
    assessmentSets: null,
    badges: null,
    assessmentSetsLoading: false,
    badgesLoading: true
};

export function reducer(state: ManageAssessmentSetState = initialState, action: fromActions.AllManageAssessmentSetActions): ManageAssessmentSetState {
    switch (action.type) {
        case fromActions.GET_ASSESSMENT_SETS:
        case fromActions.GET_PROFILE_ASSESSMENT_SETS:
        case fromActions.GET_INVITE_ASSESSMENT_SETS: {
            return {
                ...state,
                assessmentSetsLoading: true,
                assessmentSets: null
            };
        }
        case fromActions.GET_ASSESSMENT_SETS_SUCCESS: {
            return {
                ...state,
                assessmentSetsLoading: false,
                assessmentSets: action.payload
            };
        }
        case fromActions.GET_ASSESSMENT_SETS_FAIL: {
            return {
                ...state,
                assessmentSetsLoading: false,
                assessmentSets: null
            };
        }
        case fromActions.GET_BADGES:
        case fromActions.GET_PROFILE_BADGES:
        case fromActions.GET_INVITE_BADGES: {
            return {
                ...state,
                badgesLoading: true,
                badges: null
            };
        }
        case fromActions.GET_BADGES_FAIL: {
            return {
                ...state,
                badgesLoading: false,
                badges: null
            };
        }
        case fromActions.GET_BADGES_SUCCESS: {
            return {
                ...state,
                badgesLoading: false,
                badges: action.payload
            };
        }
        case fromActions.CLEAR_MANAGE_ASSESSMENT_SET_LISTS: {
            return {
                ...state,
                assessmentSets: [],
                badges: []
            };
        }
        default: {
            return state;
        }
    }
}