<app-dialog
    id="templateDialog"
    [dialogTitle]="getDialogTitle()"
    defaultWidth="900px"
    defaultHeight="900px">
    <div body>
        <app-loading-card *ngIf="busy()"></app-loading-card>
        <div class="template-dialog-upload" *ngIf="!busy() && !showTextEditor()">
            <app-file-upload *ngIf="!templateFile"
                simpleView="true"
                uploadButtonLabel="UPLOAD TEMPLATE"
                (fileUploaded)="templateUploaded($event)"
                [allowedFileExtensions]="allowedFileExtensions"></app-file-upload>
            <div *ngIf="templateFile" class="file-uploaded">
                <mat-chip-list #chipList aria-label="Uploaded File">
                    <mat-chip [removable]="true" (removed)="removeFile()">
                        {{uploadedFileName}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <form [formGroup]="templateForm" *ngIf="!busy()" novalidate fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="66" fxLayout="column">
                    <div class="font-question-label">Document Title</div>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="name">
                        <mat-error *ngIf="templateForm.get('name').hasError">Name is required</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="33" fxLayout="column">
                    <div class="font-question-label">Version Number</div>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="versionNumber">
                        <mat-error *ngIf="templateForm.get('versionNumber').hasError">Version must be a whole number greater than 0</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="container" *ngIf="showTextEditor()">
                <app-rich-text-editor [customMenu]="rteMenu" [height]="380" formControlName="templateText" [allowImages]="true"></app-rich-text-editor>
            </div>
            <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign.gt-xs="start center" fxLayoutGap="6px">
                <div fxFlex="27.5%" fxLayout="column">
                    <div class="font-question-label">Signature Type</div>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="signatureType">
                            <mat-option [value]="SignatureType.NoSigning">{{SignatureType.NoSigning | signatureTypeDescription}}</mat-option>
                            <mat-option [value]="SignatureType.SingleSign">{{SignatureType.SingleSign | signatureTypeDescription}}</mat-option>
                            <mat-option [value]="SignatureType.FullESign" *ngIf="Modules.SupplyChainAllowESignatures | moduleIsEnabled">{{SignatureType.FullESign | signatureTypeDescription}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="27.5%" fxLayout="column">
                    <div class="font-question-label">Document Type</div>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="documentType">
                            <mat-option [value]="DocumentType.Contract">{{getDocumentTypeLabel(DocumentType.Contract)}}</mat-option>
                            <mat-option [value]="DocumentType.ContractAgreement">{{getDocumentTypeLabel(DocumentType.ContractAgreement)}}</mat-option>
                            <mat-option [value]="DocumentType.GeneralConditions">{{getDocumentTypeLabel(DocumentType.GeneralConditions)}}</mat-option>
                            <mat-option [value]="DocumentType.SpecialConditions">{{getDocumentTypeLabel(DocumentType.SpecialConditions)}}</mat-option>
                            <mat-option [value]="DocumentType.GeneralPolicy">{{getDocumentTypeLabel(DocumentType.GeneralPolicy)}}</mat-option>
                            <mat-option [value]="DocumentType.HandbookGeneral">{{getDocumentTypeLabel(DocumentType.HandbookGeneral)}}</mat-option>
                            <mat-option [value]="DocumentType.StaffHandbook">{{getDocumentTypeLabel(DocumentType.StaffHandbook)}}</mat-option>
                            <mat-option [value]="DocumentType.HealthAndSafety">{{getDocumentTypeLabel(DocumentType.HealthAndSafety)}}</mat-option>
                            <mat-option [value]="DocumentType.NDA">{{getDocumentTypeLabel(DocumentType.NDA)}}</mat-option>
                            <mat-option [value]="DocumentType.PrivacyPolicy">{{getDocumentTypeLabel(DocumentType.PrivacyPolicy)}}</mat-option>
                            <mat-option [value]="DocumentType.StatementOfWork">{{getDocumentTypeLabel(DocumentType.StatementOfWork)}}</mat-option>
                            <mat-option [value]="DocumentType.SiteTerms">{{getDocumentTypeLabel(DocumentType.SiteTerms)}}</mat-option>
                            <mat-option [value]="DocumentType.ToolboxTalk">{{getDocumentTypeLabel(DocumentType.ToolboxTalk)}}</mat-option>
                            <mat-option [value]="DocumentType.Briefing">{{getDocumentTypeLabel(DocumentType.Briefing)}}</mat-option>
                            <mat-option [value]="DocumentType.RAM">{{getDocumentTypeLabel(DocumentType.RAM)}}</mat-option>
                            <mat-option [value]="DocumentType.Report">{{getDocumentTypeLabel(DocumentType.Report)}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="15%">
                    <mat-slide-toggle formControlName="enabled" title="Active">Active</mat-slide-toggle>
                </div>
                <button *ngIf="showTextEditor()" fxFlex="30%" mat-raised-button class="ghost" [disabled]="!isPreviewAvailable()" (click)="preview()">Preview Document</button>
            </div>
            <div class="signature-titles" *ngIf="templateForm.get('signatureType').value === SignatureType.FullESign" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
                <div *ngFor="let signature of signatures.controls; let i = index">
                    <div [formGroup]="signature" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                        <button mat-mini-fab title="Clear" class="ghost" (click)="clearSignatory(signature)"><mat-icon>clear</mat-icon></button>
                        <div fxFlex="220px" fxLayout="column">
                            <div class="font-question-label">{{signature.get('signatureOrder').value}} Title</div>
                            <mat-form-field appearance="outline">
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                        </div>
                        <div fxFlex="125px" fxLayout="column">
                            <div class="font-question-label">Signatory?</div>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="requiredToSign">
                                    <mat-option [value]="true">Signatory</mat-option>
                                    <mat-option [value]="false">Data Only</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="signature.get('requiredToSign').value" fxFlex="125px" fxLayout="column">
                            <div class="font-question-label">In Document?</div>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="includeInDocument">
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="signature.get('includeInDocument').value" fxFlex="90px" fxLayout="column">
                            <div class="font-question-label">Page</div>
                            <mat-form-field appearance="outline">
                                <input matInput type="number" formControlName="page" autocomplete="off">
                                <mat-error *ngIf="signature.get('page').hasError('min')">Min 1</mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="signature.get('includeInDocument').value" fxFlex="90px" fxLayout="column">
                            <div class="font-question-label">Location X</div>
                            <mat-form-field appearance="outline">
                                <input matInput type="number" formControlName="locationX" autocomplete="off">
                                <mat-error *ngIf="signature.get('locationX').hasError('max')">Max 100</mat-error>
                                <mat-error *ngIf="signature.get('locationX').hasError('min')">Min 0</mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="signature.get('includeInDocument').value" fxFlex="90px" fxLayout="column">
                            <div class="font-question-label">Location Y</div>
                            <mat-form-field appearance="outline">
                                <input matInput type="number" formControlName="locationY" autocomplete="off">
                                <mat-error *ngIf="signature.get('locationY').hasError('max')">Max 100</mat-error>
                                <mat-error *ngIf="signature.get('locationY').hasError('min')">Min 0</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" *ngIf="templateForm.get('signatures').hasError('noSignatures')"> 
                    <div class="mat-error" fxFlex="500px"> Full eSignature documents should have at least one signatory.</div> 
                </div>
            </div>
        </form>
    </div>

    <div footer-actions align="end">
        <button mat-raised-button mat-dialog-close cdkFocusInitial (click)="closeDialog()" class="ghost">Cancel</button>
        <button mat-raised-button aria-label="Save" (click)="saveTemplate()" [disabled]="templateForm?.invalid">
            <span *ngIf="!busy()">Save</span>
            <span *ngIf="busy()">Please wait.</span>
        </button>
    </div>
</app-dialog>