import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { AuthGuard, ModuleGuard, ShortUrlGuard } from './shared/guards';

// Enums
import { Modules } from './shared/enums';

// Components
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { RegisterComponent } from './authentication/components/register/register.component';
import { OutreachLandingPageComponent } from './authentication/components/outreach-landing-page/outreach-landing-page.component';

// Enums
import { GroupType } from './shared/modules/group/enums/group-type.enum';

export const routes: Routes = [
    // CONNECT
    { path: 'portal', loadChildren: () => import('app/portal/portal.module').then(m => m.PortalModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectPortal } },
    { path: 'customisation-centre', loadChildren: () => import('app/customisation-centre/customisation-centre.module').then(m => m.CustomisationCentreModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.CustomisationCentre } },
    { path: 'client-management', loadChildren: () => import('app/client-management/client-management.module').then(m => m.ClientManagementModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ClientManagementClients } },
    { path: 'connect-management', loadChildren: () => import('app/connect-management/connect-management.module').then(m => m.ConnectManagementModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectManagement } },
    { path: 'branding', loadChildren: () => import('app/branding/branding.module').then(m => m.BrandingModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.Branding } },
    { path: 'info', loadChildren: () => import('app/info/info.module').then(m => m.InfoModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectInfo } },

    { path: 'product-configuration', loadChildren: () => import('app/product-configuration/product-configuration.module').then(m => m.ProductConfigurationModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ProductConfiguration } },

    // protected
    { path: '', loadChildren: () => import('app/authentication/authentication.module').then(m => m.AuthenticationModule), pathMatch: 'full' },
    { path: 'auth/invites/:piid/register', component: RegisterComponent },
    { path: 'auth', loadChildren: () => import('app/authentication/authentication.module').then(m => m.AuthenticationModule) },
    { path: 'auth-v2', loadChildren: () => import('app/authentication-v2/authentication-v2.module').then(m => m.AuthenticationV2Module) },
    { path: 'homepage', loadChildren: () => import('app/entities-portal/homepage/homepage.module').then(m => m.HomepageModule), canActivate: [AuthGuard] },

    // v1 Portal
    { path: 'document-management', loadChildren: () => import('app/portal/modules/document-management/document-management.module').then(m => m.DocumentManagementModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectPortalDocumentsV1 } },
    { path: 'set-templates', loadChildren: () => import('app/customisation-centre/modules/set-templates/set-templates.module').then(m => m.SetTemplatesModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.CustomisationCentreFormsv1 } },
    { path: 'entity-list', loadChildren: () => import('app/portal/modules/group-list/group-list.module').then(m => m.GroupListModule), canActivate: [AuthGuard, ModuleGuard], canActivateChild: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectPortalEntitiesv1, groupType: GroupType.GroupList } },
    { path: 'document-library', loadChildren: () => import('app/portal/modules/document-library-v1/document-library.module').then(m => m.DocumentLibraryModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectPortalTemplateLibrary} },
    { path: 'roadmap', loadChildren: () => import('app/portal/modules/roadmap/roadmap.module').then(m => m.RoadmapModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectPortalRoadmap} },
    { path: 'supplier-list',  redirectTo: 'entity-list'}, // redirect from old supplier-list link, to support old bookmarks
    { path: 'telephone-numbers', loadChildren: () => import('app/connect-management/modules/telephone-numbers-v1/telephone-numbers-v1.module').then(m => m.TelephoneNumbersV1Module), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectManagementTelephoneNumbersv1} },

    // Organsiation Management
    { path: 'organisation-management', loadChildren: () => import('app/connect-management/modules/organisation-management-obsolete/organisation-management.module').then(m => m.OrganisationManagementModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectManagement } },

    // User Management
    { path: 'user-management', loadChildren: () => import('app/portal/modules/user-management/user-management.module').then(m => m.UserManagementModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectPortalUserManagement } },

    // Registrations
    { path: 'registration-documents', loadChildren: () => import('app/portal/modules/document-management/document-management.module').then(m => m.DocumentManagementModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectPortalTemplateLibrary } },

    // Service Manager
    { path: 'service-manager', loadChildren: () => import('app/connect-management/modules/service-manager-obsolete/service-manager.module').then(m => m.ServiceManagerModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectManagementApplicationsv1} },

    // Accreditation
    { path: 'accreditation', loadChildren: () => import('app/customisation-centre/modules/accreditation/accreditation.module').then(m => m.AccreditationModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.CustomisationCentre } },

    { path: 'outreach', component: OutreachLandingPageComponent },

    // Mobile Photo
    { path: 'mobile-photo', loadChildren: () => import('app/shared/modules/mobile-photo/mobile-photo.module').then(m => m.MobilePhotoModule) },

    // Short Link Redirect
    {path: 'u/:link', pathMatch: 'full', canActivate: [ShortUrlGuard]},

    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ],
    providers: []
})
export class AppRoutingModule { }
