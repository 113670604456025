import { Pipe, PipeTransform } from '@angular/core';
import { DocumentSide } from 'app/shared/modules/set-observations/enums/document-side.enum';

@Pipe({
    name: 'documentSide'
})
export class DocumentSidePipe implements PipeTransform {
    public transform(value: DocumentSide): any {
        return value === DocumentSide.Back ? '(Back)' : '(Front)';
     }
}