<div class="option-selector" fxFlex fxLayout="column">
    <div>
        <div *ngFor="let optionRow of optionRows" class="option-container">
            <button
                mat-raised-button
                class="ghost small {{buttonSizeClass()}}"
                *ngFor="let option of optionRow.options; let optionIndex = index"
                (click)="selectOption(option)"
                [ngClass]="{'selected': isSelected(option)}"
                [title]="option.label">
                    {{option.label}}
            </button>
        </div>
    </div>
</div>