import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

// ngrx | rxjs
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

// store
import * as fromStore from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { Tooltip } from 'app/connect/models/tooltip.model';
import { ModuleDetails } from 'app/models/module-details.model';

// enums
import { TooltipPage } from 'app/connect/enums/tooltip-page.enum';

declare type TooltipPosition = 'right' | 'left' | 'top' | 'bottom';

@Component({
    selector: 'app-connect-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent extends BaseComponent implements OnInit {
    @Input() position: TooltipPosition = 'bottom';
    @Input() id: string;

    @Output() buttonClicked = new EventEmitter();

    private tooltips: Tooltip[] = [];
    private isTooltipsTour: boolean = false;
    private service: ModuleDetails;
    tooltip: Tooltip;

    constructor(private store: Store<fromStore.ConnectStoreState>) {
        super();
    }

    ngOnInit(): void {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getService))
            .subscribe((service: ModuleDetails) => this.service = service);

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getTooltipsIsTour))
            .subscribe((isTour: boolean) => this.isTooltipsTour = isTour);

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getTooltips))
            .subscribe((tooltips: Tooltip[]) => {
                if (tooltips) {
                    this.tooltips = tooltips;

                    this.tooltip = tooltips.find(t => t.tag === this.id);
                }
            });
    }

    onNextTooltipClick() {
        this.buttonClicked.emit();

        this.store.dispatch(fromStore.SetTooltipVisibility({ id: this.tooltip.id, visible: false }));

        const nextTooltip = this.tooltips.find(t => t.order > this.tooltip.order && document.getElementById(t.tag));

        if (nextTooltip) {
            this.store.dispatch(fromStore.SetTooltipVisibility({ id: nextTooltip.id, visible: true }));
            this.store.dispatch(fromStore.CreateTooltipUserRecord({ tooltip: nextTooltip }));
        } else {
            if (this.tooltip.page === TooltipPage.ClientPortalPage && TooltipPage[this.service?.id.valueOf()]) {
                this.store.dispatch(fromStore.GetTooltips({ page: this.service.id.valueOf(), isTour: this.isTooltipsTour }));
            }
        }
    }

    onPrevTooltipClick() {
        this.buttonClicked.emit();

        this.store.dispatch(fromStore.SetTooltipVisibility({ id: this.tooltip.id, visible: false }));

        const tooltipsForSorting = [...this.tooltips];
        const prevTooltip = tooltipsForSorting.sort((a,b) => b.order - a.order).find(t => t.order < this.tooltip.order && document.getElementById(t.tag));
        if (prevTooltip) {
            this.store.dispatch(fromStore.SetTooltipVisibility({ id: prevTooltip.id, visible: true }));
        }
    }

    onCloseTooltipClick() {
        this.buttonClicked.emit();
        this.store.dispatch(fromStore.SetTooltipVisibility({ id: this.tooltip.id, visible: false }));
    }
}
