import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// ngrx
import { ActionsSubject, Store } from '@ngrx/store';
import { filter, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { interval } from 'rxjs';

// Store
import * as fromSetsStore from 'app/shared/modules/set-observations/store';

// Services
import { ImageUploadService } from 'app/shared/services';

// Components
import { PhotoCaptureComponent } from 'app/shared/components/photo-capture/photo-capture.component';
import { ConfirmationRequiredDialogComponent } from 'app/shared/components/confirmation-required-dialog/confirmation-required-dialog.component';
import { BaseComponent } from 'app/shared/base/base-component';

// Models
import { ImageView } from 'app/shared/models/images/image-view.model';
import { SetDocumentDownloadClick } from 'app/shared/models/set-document-download-click.model';
import { Item } from 'app/shared/modules/set-observations/models/item.model';
import { ItemObservation } from 'app/shared/modules/set-observations/models/item-observation.model';
import { AddImageListValueRequest } from 'app/shared/modules/set-observations/models/requests/add-image-list-value-request.model';
import { QrCodeData } from 'app/shared/modules/mobile-photo/models/qr-code-data.model';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-image-list',
    styleUrls: ['./image-observation-list.component.scss'],
    templateUrl: './image-observation-list.component.html',
    outputs: ['valueUpdated', 'selectedChanged']
})
export class ImageObservationListComponent extends BaseComponent implements OnInit {

    @ViewChild(PhotoCaptureComponent, { static: true }) imageUpload: PhotoCaptureComponent;

    @Input()
    item: Item;

    @Input()
    ownerId: string;

    @Output()
    observationAdded: EventEmitter<AddImageListValueRequest> = new EventEmitter();

    @Output()
    observationRemoved: EventEmitter<string> = new EventEmitter();

    observations: ItemObservation[];

    loading = false;
    numberImagesLoaded = 0;
    numberImagesExpected = 0;
    qrCodeData: QrCodeData = null;

    constructor(
        private store: Store<fromSetsStore.SetObservationsState>,
        private actionsSubject: ActionsSubject,
        private imagesService: ImageUploadService,
        private dialogs: MatDialog) {
            super();
    }

    ngOnInit(): void {

        this.numberImagesExpected = this.item.itemObservations.filter(i => i.hasValue()).length;
        this.updateLoading();

        // get the image files
        for (const itemObservation of this.item.itemObservations) {
            if (itemObservation.hasValue()) {
                this.store.dispatch(new fromSetsStore.GetDocument(itemObservation.observationId));
            }
        }

        // listen for images arriving
        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromSetsStore.GET_DOCUMENT_SUCCESS))
            .subscribe((action: fromSetsStore.GetDocumentSuccess) => {
                const observation = this.item.itemObservations.find(x => x.observationId === action.payload.observationId);
                if (observation) {
                    this.imagesService.getImageDataUrl(action.payload.document, (url: string) => {
                        observation.base64File = this.imagesService.stripDataHeader(url);
                        this.numberImagesLoaded++;
                        this.updateLoading();
                    });
                }
            });

            this.qrCodeData = new QrCodeData(this.ownerId, this.item.setId, this.item.id, null, this.item.type);
    }

    onDestroy() {
        if (this.imageUpload) {
            this.imageUpload.onDestroy();
        }
    }

    getUrl(itemObservation: ItemObservation) {
        if (itemObservation.base64File) {
            return `data:image/jpeg;base64,${itemObservation.base64File}`;
        } else {
            return '';
        }
    }

    imageUploaded(image: ImageView) {
        if (!image || !image.uploadedBase64) {
            image = null;
        }
        this.imagesService.compressFile(image, (newImage: ImageView) => {
            const newImageRequest = new AddImageListValueRequest(this.item.id, newImage.description, newImage.uploadedBase64, 'image/jpeg');
            this.observationAdded.emit(newImageRequest);
            this.imageUpload.clearImage();
        });
    }

    imageDownloadClicked(observationId: string) {
        const observation = this.item.itemObservations.find(x => x.observationId === observationId);
        this.store.dispatch(new fromSetsStore.DownloadDocument(new SetDocumentDownloadClick(observation.value, observationId)));
    }

    imageDeleteClicked(observationId: string) {
        const confirmDialog = this.dialogs.open(ConfirmationRequiredDialogComponent, {
            data: {
                confirmationMessage: `Are you sure you want to remove this image?`
            },
            disableClose: true
        });

        confirmDialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed === true) {
                this.observationRemoved.emit(observationId);
            }
        });
    }

    updateLoading() {
        this.loading = this.numberImagesLoaded < this.numberImagesExpected;
    }

    showLeftRight() {
        return this.item.itemObservations.length > 1;
    }

    scrollLeft(el: Element) {
        const animTimeMs = 400;
        const pixelsToMove = 200;
        const stepArray = [0.001, 0.021, 0.136, 0.341, 0.341, 0.136, 0.021, 0.001];
        interval(animTimeMs / 8)
            .pipe(
                takeWhile(value => value < 8),
                tap(value => el.scrollLeft -= (pixelsToMove * stepArray[value])),
            )
            .subscribe();
    }

    scrollRight(el: Element) {
        const animTimeMs = 400;
        const pixelsToMove = 200;
        const stepArray = [0.001, 0.021, 0.136, 0.341, 0.341, 0.136, 0.021, 0.001];
        interval(animTimeMs / 8)
            .pipe(
                takeWhile(value => value < 8),
                tap(value => el.scrollLeft += (pixelsToMove * stepArray[value])),
            )
            .subscribe();
    }
}
