<app-dialog [showClose]="true" id="badge-wizard" dialogTitle="{{currentBadge.title}}" defaultWidth="500px" defaultHeight="750px">
    <div sub-header class="entity-name accent-bg">{{entityName}}</div>
    <div body>
        <div fxLayout="column" class="container" fxFlex fxLayoutGap="16px" fxLayoutGap.gt-xs="24px">
            <div>
                <div><strong>{{currentBadge.subtitle}}</strong></div>
                <div>{{currentBadge.description}}</div>
            </div>
            <div fxLayout="column" fxLayoutGap="4px" class="form-list" fxFlex>
                <div *ngFor="let set of sets" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <mat-icon *ngIf="!set.complete" class="rag-amber-text">circle</mat-icon>
                    <mat-icon *ngIf="set.complete" class="rag-green-text">check_circle</mat-icon>
                    <h4>{{set.name}}</h4>
                </div>
            </div>
        </div>
    </div>
    <div footer-actions>
        <button mat-raised-button  (click)="runNextSets()">{{buttonText}}</button>
    </div>
</app-dialog>