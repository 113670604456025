export function getColumnNumber(): number {
    if (window.innerWidth <= 599) {
        return 1;
    } else if (window.innerWidth > 599 && window.innerWidth <= 959) {
        return 2;
    } else if (window.innerWidth > 959 && window.innerWidth <= 1279) {
        return 3;
    } else {
        return 4;
    }
}

export function onPageResize(): number {
    if (window.innerWidth <= 599) {
        return 1;
    } else if (window.innerWidth > 599 && window.innerWidth <= 959) {
        return 2;
    } else if (window.innerWidth > 959 && window.innerWidth <= 1279) {
        return 3;
    } else {
        return 4;
    }
}

export function getColumnNumberSix(): number {
    if (window.innerWidth <= 599) {
        return 1;
    } else if (window.innerWidth > 599 && window.innerWidth <= 959) {
        return 2;
    } else if (window.innerWidth > 959 && window.innerWidth <= 1919) {
        return 3;
    } else {
        return 6;
    }
}

export function getColumnSpanSix(): number {
    if (window.innerWidth <= 599) {
        return 1;
    } else if (window.innerWidth > 599 && window.innerWidth <= 959) {
        return 2;
    } else if (window.innerWidth > 959 && window.innerWidth <= 1919) {
        return 3;
    } else {
        return 6;
    }
}

export function onPageResizeSix(): number {
    if (window.innerWidth <= 599) {
        return 1;
    } else if (window.innerWidth > 599 && window.innerWidth <= 959) {
        return 2;
    } else if (window.innerWidth > 959 && window.innerWidth <= 1919) {
        return 3;
    } else {
        return 6;
    }
}

export function getColumnNumberThree(): number {
    if (window.innerWidth <= 599) {
        return 1;
    } else if (window.innerWidth > 599 && window.innerWidth <= 959) {
        return 2;
    } else {
        return 3;
    }
}

export function onPageResizeThree(): number {
    if (window.innerWidth <= 599) {
        return 1;
    } else if (window.innerWidth > 599 && window.innerWidth <= 959) {
        return 2;
    } else {
        return 3;
    }
}