import { Pipe, PipeTransform } from '@angular/core';

// Services
import { ModuleService } from 'app/shared/services/module.service';

// Enums
import { Modules } from 'app/shared/enums';

@Pipe({ name: 'moduleIsEnabled' })
export class ModuleIsEnabledPipe implements PipeTransform {
    transform(modulesToCheck: Modules): boolean {
        return ModuleService.isEnabled(modulesToCheck);
    }
}