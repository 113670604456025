export class Option {
    value: string;
    label: string;
    code: string;

    constructor(value: string, label: string, code: string = null) {
        this.value = value;
        this.label = label;
        this.code = code;
    }

}