import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// ngrx
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

// Store
import * as fromStore from 'app/shared/modules/set-observations/store';
import * as userInterfaceActions from 'app/store/actions/user-interface.actions';
import { getCurrentBadgeId } from 'app/store';

// Models
import { GetCredasRegistrationUrlRequest } from 'app/shared/modules/set-observations/models/requests/get-credas-registration-url-request.model';

// Enums
import { VerifyIdentityStage } from 'app/shared/modules/set-observations/enums/verify-identity-stage.enum';

@Component({
    selector: 'app-observation-right-to-work',
    templateUrl: './right-to-work-check-observation.component.html',
    styleUrls: ['./right-to-work-check-observation.component.scss']
})
export class RightToWorkObservationComponent extends ObservationComponent {
    url: string;
    stage = VerifyIdentityStage.Initial;
    stages = VerifyIdentityStage;

    constructor(private store: Store<fromStore.SetObservationsState>,
        private activatedRoute: ActivatedRoute,
        private router: Router) {
        super();
        this.activatedRoute.queryParams.subscribe(params => {
            if (params.result) {
                this.stage = parseInt(params.result, 10);
            }
        });
    }

    init() {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(getCurrentBadgeId))
            .subscribe((badgeId: any) => {
                if (this.itemObservation.ownerId) {
                    let returnUrl = this.router.url;
                    if (badgeId) {
                        returnUrl += '?badgeId=' + badgeId;
                    }
                    const request = new GetCredasRegistrationUrlRequest(this.item.setId, this.item.id, returnUrl);
                    this.store.dispatch(new fromStore.GetCredasRegistrationUrl(request));
                    this.store.pipe(
                        takeUntil(this.ngUnsubscribe),
                        select(fromStore.getCredasRegistrationUrl))
                        .subscribe((url: string) => {
                            this.url = url;
                        });
                } else {
                    this.url = window.location.href;
                }
            });
    }

    getStarted() {
        if (this.itemObservation.ownerId) {
            window.location.href = this.url;
            this.store.dispatch(new userInterfaceActions.EmptyRTW());
        }
    }
}