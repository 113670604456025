<div>
    <app-option-input 
        [options]="options"
        (valuesChanged)="onValuesChanged($event)"
        [values]="values"
        [mandatory]="item.mandatory"
        [readonly]="item.readonly"
        [multiple]="true"
        [description]="item.description">
    </app-option-input>
</div>
