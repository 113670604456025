import { Injectable } from '@angular/core';

// ngrx/rxjs
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

// Actions
import * as OrganisationTagActions from 'app/shared/modules/group/store/actions/organisation-tag.actions';

// Services
import { OrganisationsService } from 'app/shared/services';

// Models
import { Tag } from 'app/shared/models/entities/tag.model';
import { AddOrganisationTagRequest } from 'app/shared/models/add-organisation-tag-request.model';
import { DeleteOrganisationTagRequest } from 'app/shared/models/remove-organisation-tag-request';

@Injectable()
export class OrganisationTagEffects {


    getAvailableTags$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(OrganisationTagActions.GET_AVAILABLE_TAGS),
        map((action: OrganisationTagActions.GetAvailableTags) => action.payload),
        switchMap((payload: string) => this.organisationsService.getAvailableTags(payload).pipe(
                map((tags: Tag[]) => new OrganisationTagActions.GetAvailableTagsSuccess(tags)),
                catchError(() => of(new OrganisationTagActions.GetAvailableTagsFail()))))
    ));


    addOrganisationTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(OrganisationTagActions.ADD_ORGANISATION_TAG),
        map((action: OrganisationTagActions.AddOrganisationTag) => action.payload),
        switchMap((payload: AddOrganisationTagRequest) => this.organisationsService.addOrganisationTag(payload).pipe(
                map(() => new OrganisationTagActions.AddOrganisationTagSuccess()),
                catchError(() => of(new OrganisationTagActions.AddOrganisationTagFail()))))
    ));


    deleteOrganisationTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(OrganisationTagActions.DELETE_ORGANISATION_TAG),
        map((action: OrganisationTagActions.DeleteOrganisationTag) => action.payload),
        switchMap((payload: DeleteOrganisationTagRequest) => this.organisationsService.deleteOrganisationTag(payload.organisationId, payload.organisationTagId).pipe(
                map(() => new OrganisationTagActions.DeleteOrganisationTagSuccess()),
                catchError(() => of(new OrganisationTagActions.DeleteOrganisationTagFail()))))
    ));


    constructor(
        private actions$: Actions,
        private organisationsService: OrganisationsService) { }
}