<div class="multi-option-search" fxLayout="column" fxLayoutGap="16px" *ngIf="!readonly">
    <div>
        <div class="font-question-label" *ngIf="label">{{label}}</div>
        <mat-form-field appearance="outline" >
            <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplay">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    <div (click)="onMultiOptionSelected($event, option)">
                        <mat-checkbox [checked]="option.selected" (change)="toggleSelection(option)" (click)="$event.stopPropagation()">
                            {{option.value}}
                        </mat-checkbox>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div *ngIf="showSelectedOptions" fxLayout="row" fxLayoutGap="8px">
        <button *ngFor="let selectedOption of selectedOptions" mat-raised-button class="tag xsmall" 
                (click)="onMultiOptionRemoved(selectedOption)">
            {{selectedOption.value}}
            <mat-icon class="remove-option">close</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="readonly && showSelectedOptions">
    <div fxLayout="row" fxLayoutGap="8px">
        <button *ngFor="let selectedOption of selectedOptions" mat-raised-button class="xsmall primary">
            {{selectedOption.value}}
        </button>
    </div>
</div>