import { Action } from '@ngrx/store';

// Models
import { GetTermsOverviewRequest } from 'app/shared/modules/terms-obsolete/models/requests/get-terms-overview-request.model';
import { TermsOverview } from 'app/shared/modules/terms-obsolete/models/responses/terms-overview.model';
import { TermsOverviewItem } from 'app/shared/modules/terms-obsolete/models/responses/terms-overview-item.model';
import { AcceptPendingTermRequest } from 'app/shared/modules/terms-obsolete/models/requests/accept-term-request.model';

// Action Types
export const ACCEPT_PENDING_TERMS = '[Terms] Accept pending terms';
export const ACCEPT_PENDING_TERMS_SUCCESS = '[Terms] Accept pending terms success';
export const ACCEPT_PENDING_TERMS_FAIL = '[Terms] Accept pending terms fail';

export const REJECT_TERMS = '[Terms] Reject terms';

export const GET_TERMS_OVERVIEW = '[Terms] Get terms overview';
export const GET_TERMS_OVERVIEW_SUCCESS = '[Terms] Get terms overview success';
export const GET_TERMS_OVERVIEW_FAIL = '[Terms] Get terms overview fail';

export const GET_TERMS_OVERVIEW_ADMIN = '[Terms] Get terms overview admin';

export const GET_MY_TERMS_OVERVIEW = '[Terms] Get my terms overview';
export const GET_MY_TERMS_OVERVIEW_SUCCESS = '[Terms] Get my terms overview success';
export const GET_MY_TERMS_OVERVIEW_FAIL = '[Terms] Get terms my overview fail';

export const ACCEPT_PENDING_TERM = '[Terms] Accept pending term';
export const ACCEPT_PENDING_TERM_SUCCESS = '[Terms] Accept pending term success';
export const ACCEPT_PENDING_TERM_FAIL = '[Terms] Accept pending term fail';

export const RESET_STATE = '[Terms] Reset terms overview state';

// Action initiators
export class AcceptPendingTerms implements Action {
    readonly type = ACCEPT_PENDING_TERMS;
}

export class AcceptPendingTermsSuccess implements Action {
    readonly type = ACCEPT_PENDING_TERMS_SUCCESS;
}

export class AcceptPendingTermsFail implements Action {
    readonly type = ACCEPT_PENDING_TERMS_FAIL;
}

export class RejectTerms implements Action {
    readonly type = REJECT_TERMS;
}

export class GetTermsOverview implements Action {
    readonly type = GET_TERMS_OVERVIEW;
    constructor(public payload: GetTermsOverviewRequest) { }
}

export class GetTermsOverviewAdmin implements Action {
    readonly type = GET_TERMS_OVERVIEW_ADMIN;
    constructor(public payload: string) { }
}

export class GetTermsOverviewSuccess implements Action {
    readonly type = GET_TERMS_OVERVIEW_SUCCESS;
    constructor(public payload: TermsOverview) { }
}

export class GetTermsOverviewFail implements Action {
    readonly type = GET_TERMS_OVERVIEW_FAIL;
}

export class GetMyTermsOverview implements Action {
    readonly type = GET_MY_TERMS_OVERVIEW;
}

export class GetMyTermsOverviewSuccess implements Action {
    readonly type = GET_MY_TERMS_OVERVIEW_SUCCESS;
    constructor(public payload: TermsOverview) { }
}

export class GetMyTermsOverviewFail implements Action {
    readonly type = GET_MY_TERMS_OVERVIEW_FAIL;
}

export class ResetState implements Action {
    readonly type = RESET_STATE;
}

export class AcceptPendingTerm implements Action {
    readonly type = ACCEPT_PENDING_TERM;
    constructor(public payload: AcceptPendingTermRequest) { }
}

export class AcceptPendingTermSuccess implements Action {
    readonly type = ACCEPT_PENDING_TERM_SUCCESS;
    constructor(public payload: TermsOverviewItem) { }
}

export class AcceptPendingTermFail implements Action {
    readonly type = ACCEPT_PENDING_TERM_FAIL;
}

export type AllTermsOverviewActions
    = AcceptPendingTerms
    | AcceptPendingTermsSuccess
    | AcceptPendingTermsFail
    | RejectTerms
    | GetTermsOverview
    | GetTermsOverviewAdmin
    | GetTermsOverviewSuccess
    | GetTermsOverviewFail
    | GetMyTermsOverview
    | GetMyTermsOverviewSuccess
    | GetMyTermsOverviewFail
    | ResetState
    | AcceptPendingTerm
    | AcceptPendingTermSuccess
    | AcceptPendingTermFail;

