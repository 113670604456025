import { Action } from '@ngrx/store';

// Models
import { UpdateAssessmentsRequest } from 'app/shared/modules/group/models/update-assessments-request.model';
import { AssessmentSetSummary } from 'app/shared/modules/group/models/assessment-set-summary.model';
import { BadgeSummary } from 'app/shared/modules/group/models/badge-summary.model';
import { ManageAssessmentSetInfo } from 'app/shared/modules/group/models/manage-assessment-set-info.model';

// Action types
export const GET_ASSESSMENT_SETS = '[Manage Assessments] Get assessment sets';
export const GET_PROFILE_ASSESSMENT_SETS = '[Manage Assessments] Get assessment sets for a profile';
export const GET_INVITE_ASSESSMENT_SETS = '[Manage Assessments] Get assessment sets for an invite';
export const GET_INDIVIDUAL_INVITE_ASSESSMENT_SETS = '[Manage Assessments] Get assessment sets for an individual invite';
export const GET_ASSESSMENT_SETS_SUCCESS = '[Manage Assessments] Get assessment sets success';
export const GET_ASSESSMENT_SETS_FAIL = '[Manage Assessments] Get assessment sets fail';

export const UPDATE_ASSESSMENT_SETS = '[Manage Assessments] Update assessment sets';
export const UPDATE_ASSESSMENT_SETS_SUCCESS = '[Manage Assessments] Update assessment sets success';
export const UPDATE_ASSESSMENT_SETS_FAIL = '[Manage Assessments] Update assessment sets fail';

export const GET_BADGES = '[Manage Assessments] Get badges';
export const GET_PROFILE_BADGES = '[Manage Assessments] Get badges for a profile';
export const GET_INVITE_BADGES = '[Manage Assessments] Get badges for an invite';
export const GET_INDIVIDUAL_INVITE_BADGES = '[Manage Assessments] Get badges for an individual invite';
export const GET_BADGES_SUCCESS = '[Manage Assessments] Get badges success';
export const GET_BADGES_FAIL = '[Manage Assessments] Get badges fail';

export const CLEAR_MANAGE_ASSESSMENT_SET_LISTS = '[Manage Assessments] Clears the lists used by the manage assessment set dialog';

// Action initialisers
export class GetAssessmentSets implements Action {
    readonly type = GET_ASSESSMENT_SETS;
    constructor(public payload: string) { }
}

export class GetProfileAssessmentSets implements Action {
    readonly type = GET_PROFILE_ASSESSMENT_SETS;
}

export class GetInviteAssessmentSets implements Action {
    readonly type = GET_INVITE_ASSESSMENT_SETS;
    constructor(public payload: string) { }
}

export class GetIndividualInviteAssessmentSets implements Action {
    readonly type = GET_INDIVIDUAL_INVITE_ASSESSMENT_SETS;
    constructor(public payload: ManageAssessmentSetInfo) { }
}

export class GetAssessmentSetsSuccess implements Action {
    readonly type = GET_ASSESSMENT_SETS_SUCCESS;
    constructor(public payload: AssessmentSetSummary[]) { }
}

export class GetAssessmentSetsFail implements Action {
    readonly type = GET_ASSESSMENT_SETS_FAIL;
}

export class UpdateAssessmentSets implements Action {
    readonly type = UPDATE_ASSESSMENT_SETS;
    constructor(public payload: UpdateAssessmentsRequest) { }
}

export class UpdateAssessmentSetsSuccess implements Action {
    readonly type = UPDATE_ASSESSMENT_SETS_SUCCESS;
}

export class UpdateAssessmentSetsFail implements Action {
    readonly type = UPDATE_ASSESSMENT_SETS_FAIL;
}

export class GetBadges implements Action {
    readonly type = GET_BADGES;
    constructor(public payload: string) { }
}

export class GetProfileBadges implements Action {
    readonly type = GET_PROFILE_BADGES;
}

export class GetInviteBadges implements Action {
    readonly type = GET_INVITE_BADGES;
    constructor(public payload: string) { }
}

export class GetIndividualInviteBadges implements Action {
    readonly type = GET_INDIVIDUAL_INVITE_BADGES;
    constructor(public payload: ManageAssessmentSetInfo) { }
}

export class GetBadgesSuccess implements Action {
    readonly type = GET_BADGES_SUCCESS;
    constructor(public payload: BadgeSummary[]) { }
}

export class GetBadgesFail implements Action {
    readonly type = GET_BADGES_FAIL;
}

export class ClearManageAssessmentSetLists implements Action {
    readonly type = CLEAR_MANAGE_ASSESSMENT_SET_LISTS;
}

export type AllManageAssessmentSetActions
    = GetAssessmentSets
    | GetProfileAssessmentSets
    | GetInviteAssessmentSets
    | GetIndividualInviteAssessmentSets
    | GetAssessmentSetsSuccess
    | GetAssessmentSetsFail
    | UpdateAssessmentSets
    | UpdateAssessmentSetsSuccess
    | UpdateAssessmentSetsFail
    | GetBadges
    | GetProfileBadges
    | GetInviteBadges
    | GetBadgesSuccess
    | GetBadgesFail
    | ClearManageAssessmentSetLists
    | GetIndividualInviteBadges;
