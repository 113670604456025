import { Injectable } from '@angular/core';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

// store
import * as fromIdv from 'app/shared/modules/identity-verification/store';

// services
import { IdentityVerificationService } from 'app/shared/modules/identity-verification/services/identity-verification.service';

@Injectable()
export class IdentityVerificationEffects {

    getIdvData$ = createEffect(() => this.actions$.pipe(
        ofType(fromIdv.GetIdvData),
        switchMap(action => this.identityVerificationService.getIdvData$(action.observationId, action.token).pipe(
                map(response => fromIdv.GetIdvDataSuccess({ response })),
                catchError(() => of(fromIdv.GetIdvDataFail()))
            ))
    ));

    getIdvConfiguration$ = createEffect(() => this.actions$.pipe(
        ofType(fromIdv.GetIdvConfiguration),
        switchMap(action => this.identityVerificationService.getIdvConfiguration$(action.setId, action.itemId, action.observationId, action.token).pipe(
                map(response => fromIdv.GetIdvConfigurationSuccess({ response })),
                catchError(() => of(fromIdv.GetIdvConfigurationFail()))
            ))
    ));

    addSelfie$ = createEffect(() => this.actions$.pipe(
        ofType(fromIdv.AddSelfie),
        switchMap(action => this.identityVerificationService.addIdvSelfie$(action.request, action.token).pipe(
                map(response => fromIdv.AddSelfieSuccess({ response })),
                catchError(() => of(fromIdv.AddSelfieFail()))
            ))
    ));

    addUap$ = createEffect(() => this.actions$.pipe(
        ofType(fromIdv.AddUap),
        switchMap(action => this.identityVerificationService.addIdvUap$(action.request, action.token).pipe(
                map(response => fromIdv.AddUapSuccess({ response })),
                catchError(() => of(fromIdv.AddUapFail()))
            ))
    ));

    addIdDocument$ = createEffect(() => this.actions$.pipe(
        ofType(fromIdv.AddIdDocument),
        switchMap(action => this.identityVerificationService.addIdvIdDocument$(action.request, action.token).pipe(
                map(response => fromIdv.AddIdDocumentSuccess({ response })),
                catchError(() => of(fromIdv.AddIdDocumentFail()))
            ))
    ));

    constructor(private actions$: Actions,
        private identityVerificationService: IdentityVerificationService) { }
}