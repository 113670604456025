import { Component, Input, OnInit } from '@angular/core';

// models
import { IdentityVerificationConfiguration } from 'app/shared/modules/identity-verification/models/identity-verification-configuration.model';
import { IdentityVerificationResponse } from 'app/shared/modules/identity-verification/models/identity-verification-response.model';

// enums
import { CheckResult } from 'app/shared/enums';
import { VisualAnalysisResult } from 'app/shared/modules/set-observations/enums/visual-analysis-result.enum';
import { getLivenessMethodTitle } from 'app/shared/modules/identity-verification/enumerations/liveness-method.enum';

@Component({
    selector: 'app-identity-verification-data',
    templateUrl: './identity-verification-data.component.html',
    styleUrls: ['./identity-verification-data.component.scss']
})
export class IdentityVerificationDataComponent implements OnInit {
    @Input()
    observationId: string;

    @Input()
    idvDataJson: string;

    @Input()
    idvConfigurationJson: string;

    idvData: IdentityVerificationResponse;
    idvConfiguration: IdentityVerificationConfiguration;
    selfieSource: string;
    uapSource: string;

    livenessMethodTitle: string;
    CheckResult = CheckResult;
    VisualAnalysisResult = VisualAnalysisResult;

    ngOnInit(): void {
        if (this.idvConfigurationJson && this.idvConfigurationJson.length > 0) {
            this.idvConfiguration = JSON.parse(this.idvConfigurationJson);
        }

        if (this.idvDataJson && this.idvDataJson.length > 0) {
            this.idvData = JSON.parse(this.idvDataJson);
            this.livenessMethodTitle = getLivenessMethodTitle(this.idvData.livenessMethod);
            if (this.idvData.hasSelfie) {
                this.selfieSource = `/api/observations/${this.observationId}/identity-verification/selfie`;
            }

            if (this.idvData.hasUap) {
                this.uapSource = `/api/observations/${this.observationId}/identity-verification/uap`;
            }
        }
    }
}