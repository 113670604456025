<header id="connect-header" *ngIf="showHeader$ | async" fxLayout="column" fxLayoutGap="15px">
    <div class="connect-header-applications" fxLayout="column">
        <div id="application-header" class="header-bg" fxLayout="column" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
                <img id="logo" *ngIf="logoUrl" [src]="logoUrl | safe: 'resourceUrl'" class="pointer" (click)="onLogoClicked()"/>
                <app-powered-by-credas *ngIf="credasLogoColor$ | async" [color]="credasLogoColor$ | async"></app-powered-by-credas>
                <div *ngIf="applications$ | async" id="applications" fxLayout="row" fxFlex fxLayoutAlign="start start" fxLayoutGap="8px">
                    <span *ngFor="let application of applications$ | async" class="position-relative">
                        <button mat-raised-button
                            class="lozenge"
                            [ngClass]="{ 'selected': application.id === (application$ | async)?.id }"
                            (click)="onApplicationClick(application.id)">
                            {{application.title}}
                        </button>
                        <app-connect-tooltip id="client-portal-header-{{application.id}}"></app-connect-tooltip>
                    </span>
                </div>
            </div>

            <!-- todo - use credas logo colour -->
            <div id="tooltip-tour-button" *ngIf="showTooltipsTour$ | async" class="pointer" (click)="onShowTooltipsTourClick()"><mat-icon class="secondary-text">info</mat-icon></div>
            <div *ngIf="showHelpFaqs$ | async" id="help-faqs" class="position-relative">
                <mat-icon class="secondary-text pointer" (click)="onOpenHelpFaqSearchClick()">help_outline</mat-icon>
                <app-connect-tooltip id="client-portal-header-help-faqs-icon" position="left"></app-connect-tooltip>
            </div>
            <div *ngIf="(userGroup$ | async)" class="text primary-reverse-text font-body-large">{{(userGroup$ | async).name}}</div>
            <div fxFlex="48px" class="position-relative">
                <div id="user-initials" class="pointer secondary-bg" [title]="userName" (click)="onUserMenuClick()">{{userInitials}}</div>
                <app-connect-tooltip id="client-portal-header-my-account-icon" position="left"></app-connect-tooltip>
            </div>
        </div>        
    </div>

    <app-breadcrumb></app-breadcrumb>

</header>