// ngrx
import { createFeatureSelector, createSelector } from '@ngrx/store';

// store
import * as reducers from 'app/connect/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<reducers.ConnectStoreState>(StoreNames.connectCommon);
const reducerState = createSelector(storeState, (state: reducers.ConnectStoreState) => state.userInterface);

export const getUserInterfaceState = reducerState;
export const getShowNewButton = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.showNewButton);
export const getShowClientSelector = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.showClientSelector);
export const getShowUserMenu = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.showUserMenu);
export const getShowHeader = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.showHeader);
export const getPageViewType = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.pageViewType);
export const getPageTitle = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.pageTitle);
export const getAllowExport = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.allowExport);
export const getShowShowMoreButton = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.showShowMoreButton);
export const getShowMore = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.showMore);
export const getAllowSwitchView = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.allowSwitchView);
export const getFilterConfiguration = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.filterConfiguration);
export const getHideNavigation = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.hideNavigation);
export const getCustomMenus = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.customMenus);
export const getIsReadOnly = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.readOnlyAccess);
export const getShowUserGroupSelector = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.showUserGroupSelector);
export const getShowHelpFaqSearch = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.showHelpFaqSearch);
export const getCanEditFoldersPermission = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.canEditFoldersPermission);
export const getCanDeleteFoldersPermission = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.canDeleteFoldersPermission);
export const getIsEntitySuperUserView = createSelector(reducerState, (state: reducers.UserInterfaceState) => state.entitySuperUserView);

