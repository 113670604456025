import { Injectable } from '@angular/core';

// ngrx/rxjs
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

// Actions
import * as OrganisationManagementTagsActions from 'app/shared/modules/group/store/actions/organisation-management-tags.actions';

// Services
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { GroupService } from 'app/shared/modules/group/services/group.service';


@Injectable()
export class OrganisationManagementTagsEffects {


    getOrganisationTags$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(OrganisationManagementTagsActions.GET_ORGANISATION_TAGS),
        map((action: OrganisationManagementTagsActions.GetOrganisationTags) => action.payload),
        switchMap((organisationId: string) => this.groupService.getOrganisationTags(organisationId).pipe(
                map(organisationTags => new OrganisationManagementTagsActions.GetOrganisationTagsSuccess(organisationTags)),
                catchError(() => of(new OrganisationManagementTagsActions.GetOrganisationTagsFail()))))
    ));


    getOrganisationTagsFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(OrganisationManagementTagsActions.GET_ORGANISATION_TAGS_FAIL),
        tap(() => {
            this.alertService.error('The Tags could not be retrieved.');
        })
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private groupService: GroupService,
        private alertService: AlertService) { }
}