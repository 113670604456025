import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

// Models
import { BadgeSummary } from 'app/shared/modules/group/models/badge-summary.model';

// Component
import { getColumnNumber, onPageResize } from 'app/shared/components/grid-container/grid-container.component';

@Component({
    selector: 'app-badge-selection-tab',
    templateUrl: './badge-selection-tab.component.html',
    styleUrls: ['./badge-selection-tab.component.scss']
})
export class BadgeSelectionTabComponent implements OnInit, OnChanges {
    @Input()
    badges: BadgeSummary[];

    @Input()
    iconSource: string;

    @Input()
    lockExistingBadges: boolean;

    @Output()
    badgeSelectionChanged: EventEmitter<BadgeSummary> = new EventEmitter();

    existingBadges: BadgeSummary[];
    columnNum: number;

    ngOnInit(): void {
        this.columnNum = getColumnNumber();
        this.existingBadges = this.badges.filter(b => b.enabled);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.badges) {
            this.existingBadges = this.badges.filter(b => b.enabled);
        }
    }

    onResize() {
        this.columnNum = onPageResize();
    }

    handleBadgeEnabledChanged(badge: BadgeSummary, enabled: boolean): void {
        badge.enabled = enabled;
        this.badgeSelectionChanged.emit(badge);
    }

    isLocked(badge: BadgeSummary) {
        if (this.lockExistingBadges) {
            return this.existingBadges.includes(badge);
        }
        return false;
    }
}