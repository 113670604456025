import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// ngrx/rxjs
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

// Store
import * as fromSetsStore from 'app/shared/modules/set-observations/store';
import * as fromConnect from 'app/connect/store';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Models
import { AssessmentProfileBadgeResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-badge-response.model';
import { AssessmentProfileResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-response.model';
import { BadgeProfileDialogData } from 'app/shared/modules/set-observations/models/badge-profile-dialog-data.model';
import { User } from 'app/models/user.model';

@Component({
    templateUrl: './badge-profile-dialog.component.html',
    styleUrls: ['./badge-profile-dialog.component.scss']
})
export class BadgeProfileDialogComponent extends BaseComponent implements OnInit {
    badge: AssessmentProfileBadgeResponse;
    badgeId: string;
    organisationId: string;
    readonly: boolean;
    iconSource: string;
    isEntityUser: boolean;
    entityName: string;

    constructor(
        private store: Store<fromSetsStore.SetObservationsState>,
        @Inject(MAT_DIALOG_DATA) data: BadgeProfileDialogData) {
            super();
            this.badge = data.badge;
            this.badgeId = data.badge.id;
            this.organisationId = data.organisationId;
            this.readonly = data.readonly;
            this.entityName = data.entityName;
    }

    ngOnInit() {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromSetsStore.getAssessmentProfile))
            .subscribe((assessmentProfiles: AssessmentProfileResponse[]) => {
                const assessmentProfile = assessmentProfiles ? assessmentProfiles[0] : null;
                if (assessmentProfile?.badges) {
                    const selectedBadges = assessmentProfile.badges.filter(b => b.id === this.badgeId);

                    if (selectedBadges.length > 0) {
                        this.badge = selectedBadges[0];
                    }
                }
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getUser))
            .subscribe((user: User) => {
                this.isEntityUser = !user?.clients?.length;
            });
    }

    assessmentSetsModified() {
        if (!this.isEntityUser) {
            this.store.dispatch(new fromSetsStore.GetOrganisationAssessmentProfile(this.organisationId));
        } else {
            this.store.dispatch(new fromSetsStore.GetUserAssessmentProfiles());
        }
    }
}