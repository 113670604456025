export class Signature {
    name: string;
    base64Image: string;
    signedDate: Date;

    constructor(name: string, signedDate: Date) {
        this.name = name;
        this.signedDate = signedDate;
    }
}
