import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// models
import { IdentityVerificationResponse } from 'app/shared/modules/identity-verification/models/identity-verification-response.model';
import { IdentityVerificationRequest } from 'app/shared/modules/identity-verification/models/identity-verification-request.model';
import { IdentityVerificationConfiguration } from 'app/shared/modules/identity-verification/models/identity-verification-configuration.model';

@Injectable()
export class IdentityVerificationService {
    constructor(private http: HttpClient) { }

    getIdvConfiguration$(setId: string, itemId: string, observationId: string, token: string): Observable<IdentityVerificationConfiguration> {
        const url = `api/observations/${token ? 'mobile/' : ''}${observationId}/identity-verification/configuration?setId=${setId}&itemId=${itemId}${token ? '&token=' + token : ''}`;
        return this.http.get<IdentityVerificationConfiguration>(url, { headers: { timeout: '600000' } });
    }

    getIdvData$(observationId: string, token: string): Observable<IdentityVerificationResponse> {
        const url = `api/observations/${token ? 'mobile/' : ''}${observationId}/identity-verification${token ? '?token=' + token : ''}`;
        return this.http.get<IdentityVerificationResponse>(url, { headers: { timeout: '600000' } });
    }

    addIdvSelfie$(request: IdentityVerificationRequest, token: string): Observable<IdentityVerificationResponse> {
        const url = `api/observations/${token ? 'mobile/' : ''}${request.observationId}/identity-verification/selfie${token ? '?token=' + token : ''}`;
        return this.http.post<IdentityVerificationResponse>(url, request, { headers: { timeout: '600000' } });
    }

    getIdvSelfie$(observationId: string): Observable<Blob> {
        const url = `api/observations/${observationId}/identity-verification/selfie`;
        return this.http.get<Blob>(url, { responseType: 'blob' as 'json', observe: 'response', headers: { timeout: '600000' }  }).pipe(map((response: HttpResponse<Blob>) => response.body));
    }

    addIdvUap$(request: IdentityVerificationRequest, token: string): Observable<IdentityVerificationResponse> {
        const url = `api/observations/${token ? 'mobile/' : ''}${request.observationId}/identity-verification/uap${token ? '?token=' + token : ''}`;
        return this.http.post<IdentityVerificationResponse>(url, request, { headers: { timeout: '600000' } });
    }

    getIdvUap$(observationId: string): Observable<Blob> {
        const url = `api/observations/${observationId}/identity-verification/uap`;
        return this.http.get<Blob>(url, { responseType: 'blob' as 'json', observe: 'response', headers: { timeout: '600000' }  }).pipe(map((response: HttpResponse<Blob>) => response.body));
    }

    addIdvIdDocument$(request: IdentityVerificationRequest, token: string): Observable<IdentityVerificationResponse> {
        const url = `api/observations/${token ? 'mobile/' : ''}${request.observationId}/identity-verification/id-documents${token ? '?token=' + token : ''}`;
        return this.http.post<IdentityVerificationResponse>(url, request, { headers: { timeout: '600000' } });
    }

    getIdvIdDocument$(observationId: string, idDocumentId: string): Observable<Blob> {
        const url = `api/observations/${observationId}/identity-verification/id-document/${idDocumentId}`;
        return this.http.get<Blob>(url, { responseType: 'blob' as 'json', observe: 'response', headers: { timeout: '600000' }  }).pipe(map((response: HttpResponse<Blob>) => response.body));
    }
}
