import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';

// enums
import { FileExtension } from 'app/portal/modules/inputs/enumerations/file-extension.enum';

// extensions
import { getFileExtension } from 'app/shared/utilities/string-utilities';

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss']

})
export class FileInputComponent implements OnInit {

    @Input()
    disableUpload = false;

    @Input()
    downloadEnabled = false;

    @Input()
    allowedFileExtensions: FileExtension[] = [FileExtension.Csv];

    @Input()
    maximumFileSizeMb = 0;

    @Input()
    uploadButtonLabel = 'Upload';

    @Input()
    uploadText = 'Upload document';

    @Input()
    fileName: string;

    @Input()
    useCamera = false;

    @Input()
    simpleView = false;

    @Input()
    layoutAlign = 'center center';

    @Output()
    fileUploaded = new EventEmitter<File>();

    @Output()
    fileDownloadClicked = new EventEmitter<void>();

    @ViewChild('upload')
    uploadElement;

    maximumFileSizeBytes: number;
    working: boolean;
    invalidFileExtension: boolean;
    invalidFileSize: boolean;
    uploadedFile: File;

    ngOnInit() {
        this.maximumFileSizeBytes = this.maximumFileSizeMb * 1048576;
    }

    onDestroy() {
        this.clearFile();
    }

    addFile() {
        this.invalidFileExtension = false;
        this.invalidFileSize = false;
        this.uploadElement.nativeElement.click();
    }

    onFileAdded() {
        this.working = true;
        if (this.uploadElement.nativeElement.files.length <= 0) {
            this.working = false;
            return;
        }

        const file: File = this.uploadElement.nativeElement.files[0];
        const ext = getFileExtension(file.name);
        this.invalidFileExtension = ext.length === 0 || !(this.allowedFileExtensions.some(x => x === FileExtension.Any) ||
            this.allowedFileExtensions.some(x => x === ext));

        this.invalidFileSize = this.maximumFileSizeMb !== 0 && file.size > this.maximumFileSizeBytes;

        if (this.invalidFileExtension || this.invalidFileSize) {
            this.working = false;
            this.uploadedFile = null;
        } else {
            this.uploadedFile = file;
            this.fileName = file.name;
            this.fileUploaded.emit(this.uploadedFile);
        }

        this.working = false;
        if (this.simpleView) {
            this.clearFile();
        }
    }

    clearFile() {
        if (this.uploadElement) {
            this.uploadElement.nativeElement.value = '';
        }
    }

    handleFileDownloadClicked() {
        this.clearFile();
        this.fileDownloadClicked.emit();
    }

    get imageFileTypes(): string {

        if (this.allowedFileExtensions.filter(e => e === FileExtension.Any).length > 0) {
            return FileExtension.Any;
        }

        let types = '';
        for (const type of this.allowedFileExtensions) {
            if (types !== '') {
                types += ',';
            }

            types += `image/${type}`;
        }

        return types;
    }

    get fileTypes(): string {

        if (this.allowedFileExtensions.filter(e => e === FileExtension.Any).length > 0) {
            return FileExtension.Any;
        }

        let types = '';
        for (const type of this.allowedFileExtensions) {
            if (types !== '') {
                types += ',';
            }

            types += `.${type}`;
        }

        return types;
    }
}
