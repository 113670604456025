import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

// store
import * as fromActiveUser from 'app/store/actions/active-user.actions';
import * as fromConnect from 'app/connect/store';
import * as fromAuth from 'app/authentication/store';
import * as fromAuthV2 from 'app/authentication-v2/store';

// services
import { ModuleService } from 'app/shared/services/module.service';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';
import { AuthenticationV2Service } from 'app/authentication-v2/services/authentication-v2.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// models
import { ClientTokenResponse } from 'app/models/client-token-response.model';
import { TokenResponse } from 'app/shared/models';
import { UserGroup } from 'app/models/user-group.model';

@Injectable()
export class ApplicationEffects {

    setUser$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetUser),
        map((action) => {
            ModuleService.populate(action.user);
            // if we know the client from the token, then set it
            const clientId = this.authenticationTokenService.clientId();

            // Select from the token if the client is not locked
            if (clientId && action.user.clients?.find(client => client.id === clientId)?.locked === false) {
                return fromConnect.SelectClient({ clientId, performRedirect: false, setServiceFromUrl: true });
            } else if (action.user.isSuperUser) {
                // if the user is a super user, open the client selector
                return fromConnect.OpenClientSelector();
            } else if ((!action.user.entityClients || action.user.entityClients.length === 0) && action.user.clients.filter(client => !client.locked).length === 1) {
                // if the user has only one client and no entities, auto select the client
                return fromConnect.SelectClient({ clientId: action.user.clients.filter(client => !client.locked)[0].id, performRedirect: true, setServiceFromUrl: false });
            } else if (action.user.entityClients && action.user.entityClients.length === 1) {
                // if the user only has one entity and no clients, auto select the entity
                return fromConnect.SelectEntityClient({ clientId: action.user.entityClients[0].clientId });
            } else {
                // else the user has more than one entity, more than one client, or a mixture of both, open the client selector
                return fromConnect.OpenClientSelector();
            }
        })));

    selectClient$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SelectClient),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        map(([action, state]) => {
            const isSuperUser = state.user.isSuperUser;
            const client = state.user.clients.filter(c => c.id === action.clientId)[0];
            if (isSuperUser && this.authenticationTokenService.usingV2()) {
                // rehdrate user/client to populate the client details (modules/settings etc) as well as update the token
                return fromConnect.SetAndPopulateClient({ client, performRedirect: action.performRedirect, setServiceFromUrl: action.setServiceFromUrl });
            } else {
                return fromConnect.SetClient({ client, performRedirect: action.performRedirect, setServiceFromUrl: action.setServiceFromUrl });
            }
        })));

    setClient$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetClient),
        switchMap(action => {
            ModuleService.setClient(action.client);
            if (!ModuleService.anyPermissions()) {
                return [fromConnect.NoPermissionsForClientUser({clientName: action.client.name})];
            }
            const newActions = [];
            if (this.authenticationTokenService.usingV2()) {
                const userGroupCount = action.client.userGroups?.length;
                if (userGroupCount > 1) {
                    newActions.push(fromConnect.OpenUserGroupSelector());
                } else if (userGroupCount === 1) {
                    // there is a single user group, so login with the client and the user group
                    newActions.push(fromConnect.SetUserGroup({
                        clientId: action.client.id,
                        userGroup: action.client.userGroups[0],
                        performRedirect: action.performRedirect,
                        setServiceFromUrl: action.setServiceFromUrl
                    }));
                } else {
                    // there are no user groups so login with the client only
                    newActions.push(fromConnect.SetClientToken({
                        client: action.client,
                        performRedirect: action.performRedirect,
                        setServiceFromUrl: action.setServiceFromUrl
                    }));
                }
             } else {
                newActions.push(new fromActiveUser.SetSelectedClient(action.client.id, action.performRedirect, action.setServiceFromUrl, true));
            }
            newActions.push(fromConnect.SetBranding({ branding: action.client.branding}));
            return newActions;
        })
    ));

    setAndPopulateClient$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetAndPopulateClient),
        switchMap(action => this.authV2Service.setAndPopulateClient(action.client.id).pipe(
                switchMap((clientTokenResponse: ClientTokenResponse) => {
                    ModuleService.setClient(clientTokenResponse.client);
                    this.authenticationTokenService.setActiveClient(action.client.id, true);
                    this.authenticationTokenService.setAuthToken(clientTokenResponse.token);
                    return [fromConnect.SetBranding({ branding: action.client.branding}),
                        fromConnect.SetAndPopulateClientSuccess({
                            client: clientTokenResponse.client,
                            performRedirect: action.performRedirect,
                            setServiceFromUrl: action.setServiceFromUrl})];
                }),
                catchError(() => of(fromConnect.SetClientFail()))
            ))
    ));

    setAndPopulateClientSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetAndPopulateClientSuccess),
        switchMap(action => [
                fromConnect.SetBranding({ branding: action.client.branding}),
                fromConnect.SetTokenSuccess({ performRedirect: action.performRedirect, setServiceFromUrl: action.setServiceFromUrl, isClient: true })
            ]
    )));

    setClientToken$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetClientToken),
        switchMap(action => this.authV2Service.setClient(action.client.id).pipe(
                map((token: TokenResponse) => {
                    this.authenticationTokenService.setActiveClient(action.client.id, true);
                    this.authenticationTokenService.setAuthToken(token);
                    return fromConnect.SetTokenSuccess({ performRedirect: action.performRedirect, setServiceFromUrl: action.setServiceFromUrl, isClient: true });
                }),
                catchError(() => of(fromConnect.SetClientFail()))
            ))
    ));

    setClientFail$ = createEffect(() =>  this.actions$.pipe(
        ofType(fromConnect.SetClientFail),
        tap(() => this.alertService.error('An error occurred during client selection.')
        )
    ), { dispatch: false });

    selectEntityClient$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SelectEntityClient),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        switchMap(([action, state]) => {
            const entityClient = state.user.entityClients.filter(c => c.clientId === action.clientId)[0];
            return [
                new fromActiveUser.SetSelectedClient(action.clientId, undefined, undefined, false),
                fromConnect.SetBranding({ branding: entityClient.branding})
            ];
        })));

    setClientTokenSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetTokenSuccess),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        switchMap(([action, state]) => {
            const returnActions = [];
            if (this.authenticationTokenService.hideHeader()) {
                returnActions.push(fromConnect.HideHeader());
            } else {
                returnActions.push(fromConnect.ShowHeader());
            }
            returnActions.push(fromConnect.SetReadOnlyAccess({readOnlyAccess: this.authenticationTokenService.readOnlyAccess()}));
            if (action.isClient) {
                let applications =
                    state.user.isSuperUser ?
                        state.user.modules.filter(m => !m.parentId && m.showOnNavigation) :
                        state.user.modules.filter(m => !m.parentId && m.showOnNavigation && state.client.userModules.includes(m.id) && (!state.userGroup || state.userGroup.modules.includes(m.id)) && !m.superUserOnly);

                applications = applications.map(a => state.client.modules.filter(m => m.id === a.id && m.title)[0] ?? a);
                applications.sort((m1, m2) => m1.displayOrder - m2.displayOrder);
                returnActions.push(fromConnect.SetApplications({ applications, performRedirect: action.performRedirect, setServiceFromUrl: action.setServiceFromUrl }));
                return returnActions;
            } else if (!window?.location?.href?.endsWith('/homepage')) {
                returnActions.push(fromConnect.NavigateToUrl({url: '/homepage'}));
            }
            return returnActions;
        })
    ));

    setApplications$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetApplications),
        filter(action => action.performRedirect || action.setServiceFromUrl),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        switchMap(([action, state]) => {
            if (action.applications?.length) {
                if (action.performRedirect) {
                    const application = action.applications[0];
                    return [
                        fromConnect.SelectApplication({ applicationId: application.id, performRedirect: true })
                    ];
                } else {
                    const url = window.location.href.replace(window.location.origin, '');
                    const service = state.user.modules.filter(m => m.parentId && m.url && url.startsWith(m.url))[0];
                    if (service) {
                        const application = state.user.modules.filter(m => m.id === service.parentId)[0];

                        return [
                            fromConnect.SelectApplication({ applicationId: application.id, performRedirect: false }),
                            fromConnect.SelectService({ serviceId: service.id, performRedirect: false }),
                        ];
                    } else if (url.length < 3 || url.startsWith('/auth')) {
                        const application = action.applications[0];
                        return [
                            fromConnect.SelectApplication({ applicationId: application.id, performRedirect: true })
                        ];
                    } else {
                        return [];
                    }
                }
            } else {
                return [];
            }
        })
    ));

    selectApplication$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SelectApplication),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        map(([action, state]) => {
            const application = state.user.modules.filter(m => m.id === action.applicationId)[0];
            return fromConnect.SetApplication({ application, performRedirect: action.performRedirect });
        })));

    setApplication$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetApplication),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        map(([action, state]) => {
            let services =
                state.user.isSuperUser ?
                    state.user.modules.filter(m => m.parentId === action.application.id && m.showOnNavigation) :
                    state.user.modules.filter(m => m.parentId === action.application.id && m.showOnNavigation && state.client.userModules.includes(m.id) && (!state.userGroup || state.userGroup.modules.includes(m.id)) && !m.superUserOnly);

            services = services.map(a => state.client.modules.filter(m => m.id === a.id && m.title)[0] ?? a);
            services.sort((m1, m2) => m1.displayOrder - m2.displayOrder);

            return fromConnect.SetServices({ services, performRedirect: action.performRedirect });
        })));

    setServices$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetServices),
        filter(action => action.performRedirect),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        map(([action, state]) => {
            const service = state.services[0];
            return fromConnect.SetService({ service, performRedirect: action.performRedirect });
        })));

    selectService$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SelectService),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        map(([action, state]) => {
            const service = state.services?.filter(m => m.id === action.serviceId)[0];
            return fromConnect.SetService({ service, performRedirect: action.performRedirect });
        })));

    setService$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetService),
        filter(action => action.performRedirect),
        map(action => fromConnect.NavigateToUrl({ url: action.service.url }))));

    navigateToUrl$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.NavigateToUrl),
        tap(action => {
            this.router.navigateByUrl(action.url);
        })), { dispatch: false });

    logoutUser$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.LogoutUser),
        map((action) => {
            if (this.authenticationTokenService.usingV2()) {
                return fromAuthV2.Logout({options: action.options});
            } else {
                return new fromAuth.Logout(action.options);
            }
        }
    )));

    refreshToken$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.RefreshToken),
        map(() => {
            const request = this.authenticationTokenService.getRefreshTokenRequest();
            if (this.authenticationTokenService.usingV2()) {
                return fromAuthV2.RefreshToken({ request });
            } else {
                return new fromAuth.RefreshToken(request);
            }
        }
    )));

    rehydrateUser$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.RehydrateUser),
        map(() => {
            if (this.authenticationTokenService.usingV2()) {
                return fromAuthV2.RehydrateUser();
            } else {
                return new fromAuth.RehydrateUser();
            }
        }
    )));

    rehydrateUserSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.RehydrateUserSuccess),
        switchMap((action) => {
            ModuleService.populate(action.user);
            ModuleService.setClient(action.client);
            this.authenticationTokenService.setActiveClient(action.client.id, true);
            if (action.userGroup) {
                ModuleService.setUserGroup(action.userGroup);
                this.authenticationTokenService.setUserGroup(action.userGroup.id);
            }
            this.authenticationTokenService.setAuthToken(action.user.token);
            return [fromConnect.SetBranding({ branding: action.client.branding}),
                fromConnect.SetTokenSuccess({ performRedirect: false, setServiceFromUrl: true, isClient: true })];
        }
    )));

    setUserForTokenLogin$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetUserForTokenLogin),
        switchMap((action) => {
            ModuleService.populate(action.user);
            ModuleService.setClient(action.client);
            this.authenticationTokenService.setActiveClient(action.client.id, true);
            this.authenticationTokenService.setAuthToken(action.user.token);
            let userGroup: UserGroup = null;
            if (action.client.userGroups?.length === 1) {
                userGroup = action.client.userGroups[0];
            } else if (action.tokenDetails.userGroupId) {
                userGroup = action.client.userGroups.find(g=> g.id === action.tokenDetails.userGroupId);
            }
            if (userGroup) {
                ModuleService.setUserGroup(userGroup);
                this.authenticationTokenService.setUserGroup(userGroup.id);
            }
            const actions = [];
            actions.push(fromConnect.SetBranding({ branding: action.client.branding}));
            actions.push(fromConnect.SetReadOnlyAccess({readOnlyAccess: action.tokenDetails.readOnlyAccess}));
            if (action.tokenDetails.processId) {
                if (action.tokenDetails.pdfExportId) {
                    actions.push(fromConnect.NavigateToUrl({url:`/portal/processes/${action.tokenDetails.processId}/pdf-export/${action.tokenDetails.pdfExportId}`}));
                } else {
                    actions.push(fromConnect.NavigateToUrl({url:`/portal/processes/${action.tokenDetails.processId}`}));
                }
            }
            if (action.tokenDetails.hideHeader) {
                actions.push(fromConnect.HideHeader());
            } else {
                actions.push(fromConnect.ShowHeader());
            }
            return actions;
        }
    )));

    hideHeader$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.HideHeader),
        tap(() => this.authenticationTokenService.setHideHeader(true))
        ), { dispatch: false });

    showHeader$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.ShowHeader),
        tap(() => this.authenticationTokenService.setHideHeader(false))
        ), { dispatch: false });

    setReadOnlyAcccess$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetReadOnlyAccess),
        tap((action) => this.authenticationTokenService.setReadOnlyAccess(action.readOnlyAccess))
        ), { dispatch: false });

    noPermissions$ = createEffect(() =>  this.actions$.pipe(
        ofType(fromConnect.NoPermissionsForClientUser),
        tap((action) => this.alertService.error(`You do not have any permissions for ${action.clientName}.`)
        )
    ), { dispatch: false });

    setUserGroup$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetUserGroup),
        map(action =>
             fromConnect.SetUserGroupToken({
                    clientId: action.clientId,
                    userGroup: action.userGroup,
                    performRedirect: action.performRedirect,
                    setServiceFromUrl: action.setServiceFromUrl
            })
        )
    ));

    setUserGroupToken$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetUserGroupToken),
        switchMap(action => this.authV2Service.setUserGroup(action.clientId, action.userGroup.id).pipe(
                map((token: TokenResponse) => {
                    this.authenticationTokenService.setActiveClient(action.clientId, true);
                    ModuleService.setUserGroup(action.userGroup);
                    this.authenticationTokenService.setUserGroup(action.userGroup.id);
                    this.authenticationTokenService.setAuthToken(token);
                    return fromConnect.SetTokenSuccess({ performRedirect: action.performRedirect, setServiceFromUrl: action.setServiceFromUrl, isClient: true });
                }),
                catchError(() => of(fromConnect.SetUserGroupFail()))
            ))
    ));

    clearUserGroup$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.ClearUserGroup),
        switchMap(action => this.authV2Service.clearUserGroup().pipe(
                map((token: TokenResponse) => {
                    ModuleService.setUserGroup(null);
                    this.authenticationTokenService.setUserGroup(null);
                    this.authenticationTokenService.setAuthToken(token);
                    return fromConnect.SetTokenSuccess({ performRedirect: action.performRedirect, setServiceFromUrl: action.setServiceFromUrl, isClient: true });
                }),
                catchError(() => of(fromConnect.ClearUserGroupFail()))
            ))
    ));


    constructor(
        private actions$: Actions,
        private store$: Store<fromConnect.ConnectStoreState>,
        private authenticationTokenService: AuthenticationTokenService,
        private authV2Service: AuthenticationV2Service,
        private alertService: AlertService,
        private router: Router) { }
}