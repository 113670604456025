import { Injectable } from '@angular/core';

// ngrx
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, mergeMap, tap } from 'rxjs/operators';

// Store
import * as setActions from 'app/shared/modules/set-observations/store/actions';

// Services
import { SetObservationService } from 'app/shared/modules/set-observations/services/set-observation.service';
import { FileSaverService } from 'ngx-filesaver';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// Models
import { SetResponse } from 'app/shared/modules/set-observations/models/responses/set-response.model';
import { ESignDocumentResponse } from 'app/shared/modules/set-observations/models/responses/esign-document-response.model';
import { SetDocumentDownloadClickSuccess } from 'app/shared/models/set-document-download-click-success.model';
import { GetCredasRegistrationUrlRequest } from 'app/shared/modules/set-observations/models/requests/get-credas-registration-url-request.model';
import { BadgeSummary } from 'app/shared/modules/set-observations/models/responses/badge-summary.model';
import { ItemObservation } from 'app/shared/modules/set-observations/models/item-observation.model';
import { UapImage } from 'app/shared/modules/set-observations/models/responses/uap-image.model';
import { AddIdDocumentRequest } from 'app/shared/modules/set-observations/models/requests/add-id-document-request.model';
import { IdDocumentsResponse } from 'app/shared/modules/set-observations/models/responses/id-documents-response.model';
import { ObservationDocument } from 'app/shared/modules/set-observations/models/responses/observation-document.model';
import { CourseSummary } from 'app/shared/modules/set-observations/models/responses/course-summary.model';
import { CourseDetail } from 'app/shared/modules/set-observations/models/responses/course-detail.model';
import { GetESignDocumentRequest } from 'app/shared/modules/set-observations/models/requests/get-esign-document-request.model';
import { IdDocumentUrl } from 'app/shared/modules/set-observations/models/responses/id-document-url.model';

// Enums
import { isImmediateSaveItem, ItemType } from 'app/shared/enums/item-type.enum';

@Injectable()
export class SetObservationEffects {


    getSet$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_SET),
        switchMap((action: setActions.GetSet) => this.setObservationService.getSet(action.payload).pipe(
                map((set: SetResponse) => {
                    if (set) {
                        return new setActions.GetSetSuccess(set);
                    }

                    return new setActions.GetSetFail('Unable to retrieve the set');
                }),
                catchError(error => of(new setActions.GetSetFail(error)))
            ))));



    saveObservations$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.SAVE_OBSERVATIONS),
        switchMap((action: setActions.SaveObservations) => this.setObservationService.recordObservations(action.payload).pipe(
                map(() => new setActions.SaveObservationsSuccess()),
                catchError(error => of(new setActions.SaveObservationsFail(error)))
            ))));


    getBadgeSummaries$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_BADGE_SUMMARIES),
        switchMap((action: setActions.GetBadgeSummaries) => this.setObservationService.getBadgeSummaries(action.payload).pipe(
                map((badgeSummaries: BadgeSummary[]) => new setActions.GetBadgeSummariesSuccess(badgeSummaries)),
                catchError(() => of(new setActions.GetBadgeSummariesFail()))
            ))));


    getESignDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_ESIGN_DOCUMENT),
        mergeMap((action: setActions.GetESignDocument) => this.setObservationService.getESignDocument(action.payload).pipe(
                map((response: ESignDocumentResponse) => new setActions.GetESignDocumentSuccess(response)),
                catchError(() => of(new setActions.GetESignDocumentFail(action.payload)))
            ))));


    getDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_DOCUMENT),
        mergeMap((action: setActions.GetDocument) => this.setObservationService.getDocument(action.payload).pipe(
                map(blob => new setActions.GetDocumentSuccess(new ObservationDocument(action.payload, blob))),
                catchError(() => of(new setActions.GetDocumentFail()))))
    ));


    getDocumentFail$: Observable<void> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_DOCUMENT_FAIL),
        tap(() => {
            this.alertService.error('An error occurred retrieving document file');
        })
    ), { dispatch: false });


    downloadDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.DOWNLOAD_DOCUMENT),
        switchMap((action: setActions.DownloadDocument) => this.setObservationService.getDocument(action.payload.observationId).pipe(
                map(blob => new setActions.DownloadDocumentSuccess(new SetDocumentDownloadClickSuccess(blob, action.payload.observationValue))),
                catchError(() => of(new setActions.DownloadDocumentFail()))))
    ));


    downloadDocumentSuccess$: Observable<void> = createEffect(() => this.actions$.pipe(
        ofType(setActions.DOWNLOAD_DOCUMENT_SUCCESS),
        map((action: setActions.DownloadDocumentSuccess) => {
            this.fileSaverService.save(action.payload.blob, action.payload.observationValue);
        })
    ), { dispatch: false });


    downloadDocumentFail$: Observable<void> = createEffect(() => this.actions$.pipe(
        ofType(setActions.DOWNLOAD_DOCUMENT_FAIL),
        tap(() => {
            this.alertService.error('An error occurred downloading file');
        })
    ), { dispatch: false });


    getCredasRegistrationUrl$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_CREDAS_REGISTRATION_URL),
        map((action: setActions.GetCredasRegistrationUrl) => action.payload),
        switchMap((request: GetCredasRegistrationUrlRequest) => this.setObservationService.getCredasRegistrationUrl(request).pipe(
                map((url: string) => new setActions.GetCredasRegistrationUrlSuccess(url)),
                catchError(() => of (new setActions.GetCredasRegistrationUrlFail()))
            ))
    ));


    updateObservation$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.UPDATE_OBSERVATION),
        map((action: setActions.UpdateObservation) => action.payload),
        map((observation: ItemObservation) => {
            if (isImmediateSaveItem(observation.itemType)) {
                return new setActions.UpdateObservationImmediate(observation);
            }
            return new setActions.UpdateObservationCache(observation);
        })));


    updateObservationImmediate$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.UPDATE_OBSERVATION_IMMEDIATE),
        map((action: setActions.UpdateObservationImmediate) => action.payload),
        switchMap((itemObservation: ItemObservation) => this.setObservationService.recordImmediateObservation(itemObservation).pipe(
                switchMap(() => {
                    const actions: any[] = [ new setActions.UpdateObservationImmediateSuccess() ];

                    if (itemObservation.itemType === ItemType.ESignRichText || itemObservation.itemType === ItemType.ESignDocument) {
                        actions.push(new setActions.GetESignDocument(new GetESignDocumentRequest(itemObservation.setId, itemObservation.itemId)));
                    }
                    return actions;
                }),
                catchError(() => of (new setActions.UpdateObservationImmediateFail()))
            ))
    ));


    getUapImageUrl$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_UAP_IMAGE_URL),
        map((action: setActions.GetUapImageUrl) => action.payload),
        switchMap((organisationId: string) => this.setObservationService.getUapImageUrl(organisationId).pipe(
                map((uapImage: UapImage) => new setActions.GetUapImageUrlSuccess(uapImage)),
                catchError(() => of (new setActions.GetUapImageUrlFail()))
            ))
    ));


    addIdDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.ADD_ID_DOCUMENT),
        map((action: setActions.AddIdDocument) => action.payload),
        switchMap((request: AddIdDocumentRequest) => this.setObservationService.addIdDocument(request).pipe(
                map((response: IdDocumentsResponse) => new setActions.AddIdDocumentSuccess(response)),
                catchError(() => of (new setActions.AddIdDocumentFail()))
            ))
    ));


    addIdDocumentFail$: Observable<void> = createEffect(() => this.actions$.pipe(
        ofType(setActions.ADD_ID_DOCUMENT_FAIL),
        tap(() => {
            this.alertService.error('An error occurred adding document');
        })
    ), { dispatch: false });


    getCourses$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_COURSES),
        map((action: setActions.GetCourses) => action.payload),
        switchMap((startDate: Date) => this.setObservationService.getCourses(startDate).pipe(
                map((courses: CourseSummary[]) => new setActions.GetCoursesSuccess(courses)),
                catchError(() => of (new setActions.GetCoursesFail()))
            ))
    ));


    getCourseDetails$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_COURSE_DETAILS),
        map((action: setActions.GetCourseDetails) => action.payload),
        switchMap((reference: string) => this.setObservationService.getCourseDetails(reference).pipe(
                map((course: CourseDetail) => new setActions.GetCourseDetailsSuccess(course)),
                catchError(() => of (new setActions.GetCourseDetailsFail()))
            ))
    ));


    reloadSet$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.RELOAD_SET),
        switchMap((action: setActions.ReloadSet) => this.setObservationService.getSet(action.payload.setId).pipe(
                switchMap((set: SetResponse) => {
                    if (set) {
                        return [
                            new setActions.GetSetSuccess(set),
                            new setActions.ReloadSetSuccess(action.payload.currentPage)
                        ];
                    }
                    return [new setActions.ReloadSetFail()];
                }),
                catchError(() => of(new setActions.ReloadSetFail()))
            ))));


    getIdDocumentUrls$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_ID_DOCUMENT_URLS),
        map((action: setActions.GetIdDocumentUrls) => action.payload),
        switchMap((reference: string) => this.setObservationService.getIdDocumentUrls(reference).pipe(
                map((urls: IdDocumentUrl[]) => new setActions.GetIdDocumentUrlsSuccess(urls)),
                catchError(() => of (new setActions.GetIdDocumentUrlsFail()))
            ))
    ));

    constructor(
        private actions$: Actions,
        private setObservationService: SetObservationService,
        private fileSaverService: FileSaverService,
        private alertService: AlertService) { }
}