export class RegExRules {

    // Alpha Numeric
    alpha_numeric = `^[\\w]+`;

    // Alpha Numeric with Space
    alpha_numeric_space = `^[\\w\\s]+$`;

    // Alpha Numeric with Space - .
    alpha_numeric_space_dash_dot = `^[\\w\\s&.-]+$`;

    // Long Text / Blurb
    // alpha_numeric_blurb = `^[\\u2022\\u2023\\u25E6\\u2043\\u2219\\w\\s".':;*%\?,!#£$-_@]+$`;

    // Numeric
    numeric = `^([0-9])`;

    // Decimal
    decimal = `^(0|[1-9]\\d*)(\\.\\d+)?$`;

    // Name
    name = `^([A-Za-z '\`-]+)$`;

    // Company Name
    // company_name = `^([A-Za-z]+([& '-]?[A-Za-z ]+)*)$`;

    // Email validation is difficult it is recommended not to use regex for email validation and instead use a verify procedure by sending a validation email
    // email = `^[a-zA-Z0-9.!#$%&’*+/=?^_\`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$`;

    // URL
    // url = `https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)`;

    // Credas Subdomain
    // subdomain = `^(https):\/\/[.a-z]+\.credas\.co\.uk`;

    // Title
    // title = `^(Mr|Ms|Miss|Mrs|Dr|Sr|Sir|Rev|Prof)$`;

    // Mobile Phone Number
    // Optional '(' or '+' at start, spaces and brackets allowed, min 10 digits
    // mobile_number = `^(\\+?[0-9]{6,15})$`;
    mobile_number = `^\\(?\\+?([\\s()]*[0-9][\\s()]*){10,}$`;

    // Mobile Phone Number
    // Optional. Allows uk mobile numbers starting with +447 or 07
    uk_mobile_number = `^(\\+447|07)[0-9]+$`;

    number_with_prefix_plus = '^[+]?[0-9]+$';

    // Journey Code
    // journey_code = `^([0-9]{4})`;

    // Ensure that password is 9 characters long and contains a mix of upper and lower case characters and  at least one special character
    // ^	                The password string will start this way
    // (?=.*[a-z])	        The string must contain at least 1 lowercase alphabetical character
    // (?=.*[A-Z])	        The string must contain at least 1 uppercase alphabetical character
    // (?=.*[^a-zA-Z0-9])   The string must contain at least 1 non-alphanumeric character
    // (?=.{9,})	        The string must be 9 characters or longer
    password = `^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{9,}`;

    // The email address generated on the agent led solution i.e. the dummy contractor
    // agent_led_dummy_email = `(noemail[\+\\w\\d]{9}@credas.co.uk)`

    // UTR number
    // 10 Numerals, spaces allowed example "12345 67890"
    utr_number = `^(\\d *){10}$`;

    // VAT Number
    // 9 or 12 digits, with “GB” sometimes included as a prefix.
    vat_number = `^(GB|gb|gB|Gb)*(([1-9]\\d{8})|([1-9]\\d{11}))$`;

    // National Insurance Number
    // 9 characters. Only A to D are permitted as the last letter.
    // For temporary numbers F and M are permitted
    // allow spaces and lowercase
    // examples: 'AB123456C' 'ab 12 34 56 c'
    ni_number = `^[a-zA-Z]{2}\\s?[0-9]{2}\\s?[0-9]{2}\\s?[0-9]{2}\\s?[a-dfmA-DFM]{1}$`;

    // Bank sort code 6 digits with hypens: 12-34-56
    bank_sort_code = `^(\\d){2}-(\\d){2}-(\\d){2}$`;

    // Bank account number 8 digits 12345678
    bank_account_number = `^(\\d){8}$`;
}
