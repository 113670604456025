import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromRoot from 'app/store';

// services
import { ImageUploadService } from 'app/shared/services';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// components
import { TakePhotoDialogComponent } from 'app/shared/modules/identity-verification/components/take-photo-dialog/take-photo-dialog.component';

@Component({
    selector: 'app-idv-selfie-step',
    templateUrl: './selfie-step.component.html',
    styleUrls: ['./selfie-step.component.scss']
})
export class SelfieStepComponent {

    @Output()
    selfieTaken: EventEmitter<string> = new EventEmitter();

    @Output()
    switchToMobile: EventEmitter<void> = new EventEmitter();

    @ViewChild('upload') uploadElement: ElementRef;

    isMobileDevice$: Observable<boolean>;
    allowSwitchToMobile = false;

    constructor(
        rootStore: Store<fromRoot.State>,
        private imageUploadService: ImageUploadService,
        private authTokenService: AuthenticationTokenService,
        private dialogs: MatDialog) {
            this.isMobileDevice$ = rootStore.select(fromRoot.isMobileDevice);
            this.allowSwitchToMobile = !this.authTokenService.isClient();
        }


    handleFileUploaded(event: any): void {
        if (event.target.files && event.target.files[0]) {

            const file: File = event.target.files[0];
            this.imageUploadService.getBase64(file).then((base64Image: string) => {
                this.selfieTaken.emit(base64Image);
            });
        }

        this.uploadElement.nativeElement.value = null;
    }

    handleTakePhotoClick(): void {
        const dialog = this.dialogs.open(TakePhotoDialogComponent, { disableClose: true });
        dialog.afterClosed().subscribe((base64Image: string) => {
            if (base64Image) {
                this.selfieTaken.emit(base64Image);
            }
        });
    }

    handleSwitchToMobileClick(): void {
        this.switchToMobile.emit();
    }
}