import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { AssessmentProfileCategoryResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-category-response.model';
import { AssessmentProfileSetResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-set-response.model';

@Component({
    selector: 'app-profile-question-sets',
    templateUrl: './profile-question-sets.component.html',
    styleUrls: ['./profile-question-sets.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileQuestionSetsComponent {
    @Input()
    categories: AssessmentProfileCategoryResponse[];

    @Input()
    readonly: boolean;

    @Input()
    initiallyExpanded: boolean = false;

    @Input()
    showSetsBanner: boolean = false;

    @Input()
    showTabs: boolean = false;

    @Output()
    showAssessmentSet: EventEmitter<AssessmentProfileSetResponse> = new EventEmitter();

    onShowAssessmentSet(assessmentSet: AssessmentProfileSetResponse): void {
        this.showAssessmentSet.emit(assessmentSet);
    }
}