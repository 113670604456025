import { Item } from 'app/shared/modules/set-observations/models/item.model';
import { ObservationRequest } from 'app/shared/modules/set-observations/models/requests/observation-request.model';

export class ItemObservationMapperUtility {
    public static setPaging(items: Item[]): ObservationRequest[] {
        if (!items) {
            return [];
        }

        const changedObservations =
            items.filter(i => i.changed).reduce(
                (observations: ObservationRequest[], item: Item) => [
                    ...observations,
                    ...item.itemObservations
                        .map(io => new ObservationRequest(io.observationId, item.id, io.value, io.base64File, item.type, io.contentType, io.metaData))], []);

        return changedObservations;
    }
}