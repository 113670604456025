<div fxFlex="auto" fxLayout="column" id="user-activity">
    <div fxFlex="auto" fxLayout="column" class="container">
        <app-loading-card *ngIf="(loading$ | async)"></app-loading-card>

        <div fxFlex="auto" fxLayout="column" class="table-container" [hidden]="!(loaded$ | async)">
            <mat-table *ngIf="filteredUserActivity?.length" [dataSource]="dataSource" class="has-paging">
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef fxFlex="20"> Date/Time </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxFlex="20"> {{ element.dateCreated | ukdate: 'time' }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="activityType">
                    <mat-header-cell *matHeaderCellDef fxFlex="30"> Audit Event </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxFlex="30"> {{ userActivityTypeDescription(element.activityType, element.module) }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="organisation">
                    <mat-header-cell *matHeaderCellDef fxFlex="25"> Organisation </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxFlex="25" [ngClass]="{
                        'italic': !element.organisation
                    }"> {{ element.organisationTitle }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="user">
                    <mat-header-cell *matHeaderCellDef fxFlex="25"> User </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxFlex="25"> {{ element.user?.email }} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <mat-paginator [ngStyle]="!filteredUserActivity?.length || !userActivity?.length ? {'display': 'none'} : {}" #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50, 100]" [showFirstLastButtons]="true"></mat-paginator>

            <div *ngIf="!filteredUserActivity?.length && userActivity?.length" class="padding-30">Your search returned no results</div>

            <app-no-information-message *ngIf="!userActivity?.length"></app-no-information-message>
        </div>
    </div>
</div>