import { Component } from '@angular/core';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

// error state matchers
import { ShortTextErrorStateMatcher } from 'app/shared/modules/set-observations/matchers/short-text-error-state.matcher';

/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-short-text',
    templateUrl: './short-text-observation.component.html',
    outputs: ['valueUpdated', 'selectedChanged']
})
export class ShortTextObservationComponent extends ObservationComponent {
    // use the error state matcher so that the error gets displayed straight away
    matcher: ShortTextErrorStateMatcher = new ShortTextErrorStateMatcher();

    constructor() {
        super();
    }

    init(): void {
    }
}