<app-dialog
    id="document-pdf-preview"
    dialogTitle="Preview Template Document"
    showClose="true"
    defaultHeight="640px"
    defaultWidth="460px">
    <div body>
        <app-loading-card *ngIf="loadingPdf"></app-loading-card>
        <ng2-pdfjs-viewer #pdfViewer *ngIf="!loadingPdf" [pdfSrc]="blobData"></ng2-pdfjs-viewer>
    </div>
</app-dialog>