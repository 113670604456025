<div *ngIf="configuration" id="filter" fxFlex fxLayout="column">

    <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" [fxLayoutGap]="filterVisible ? '2px' : '0'">
        <div fxLayout="column" fxFlex.gt-sm>
            <mat-form-field appearance="outline" fxFlex.gt-sm class="light search" [ngClass]="{ 'square-bottom': configuration?.folders?.length, 'with-gap': !configuration?.folders?.length }">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [placeholder]="configuration?.searchLabel" #search (keyup.enter)="onSearchTextUpdated($event.target.value); applyFilters();" [value]="filter.searchText ?? ''">
                <div fxLayout="row" class="search-buttons" fxLayoutAlign="start center">
                    <mat-icon *ngIf="configuration?.options?.length" class="material-icons-outlined filter-button" (click)="$event.stopPropagation(); filterVisible = !filterVisible">filter_alt</mat-icon>
                    <button mat-raised-button class="small" (click)="onSearchTextUpdated(search.value); applyFilters();">Search</button>
                    <app-connect-tooltip id="client-portal-filter"></app-connect-tooltip>
                    <app-connect-tooltip id="client-portal-search-button"></app-connect-tooltip>
                </div>
                <div *ngIf="visibleRecordCount !== undefined" class="records-label text font-body-xsmall padding-4 round-corners table-and-tab-bg table-and-tab-text">
                    {{visibleRecordCount}} records returned
                    <app-connect-tooltip id="client-portal-records-returned" position="left"></app-connect-tooltip>
                </div>
                <app-connect-tooltip id="client-portal-search-bar"></app-connect-tooltip>
            </mat-form-field>

            <div *ngIf="configuration?.folders?.length" class="folders" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" [ngClass]="{ 'with-gap': !filter.searchText && !fromDate && !toDate && !flattenedSelectedValues?.length }">
                <mat-icon (click)="onScrollLeftClicked()" class="pointer">chevron_left</mat-icon>
                <div class="overflow-hidden folder-inner" fxFlex #folderContainer>
                    <div class="folder-inner-list animate" fxLayout="row" fxLayoutAlign="start center" fxFlex [style.left]="folderLeft + 'px'" fxLayoutGap="16px">
                        <app-folder-card
                            *ngFor="let folder of configuration.folders | orderBy: 'title'"
                            [item]="folder"
                            (openFolderClicked)="onOpenFolderClicked(folder)"
                            (deleteFolderClicked)="onDeleteFolderClicked(folder)"
                            (editFolderClicked)="onEditFolderClicked(folder)">
                        </app-folder-card>
                    </div>
                </div>
                
                <mat-icon (click)="onScrollRightClicked()" class="pointer">chevron_right</mat-icon>
            </div>
        </div>

        <div id="filter-popout" [ngClass]="{ 'visible': filterVisible }">
            <div class="title primary-bg primary-reverse-text" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="">Additional Filters</div>
                <mat-icon class="pointer" (click)="filterVisible = false">close</mat-icon>
            </div>
            <div id="filter-popout-content" class="white-bg" fxLayout="column" fxLayoutGap="16px">
                <div fxLayout="column" class="filters-inner" fxFlex fxLayoutGap="16px">
                    <div *ngIf="configuration.dateOptions?.length" fxLayout="column" fxLayoutGap="16px">
                        <div class="filter-label">Date Range</div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                            <div fxFlex.gt-sm="150px" *ngFor="let dateOption of configuration.dateOptions; let i = index;" fxLayout="column">
                                <div class="font-question-label">{{dateOption.name}}</div>
                                <mat-form-field appearance="outline">
                                    <input matInput [matDatepicker]="dateOption_i" [formControl]="dateOptionControls[i]" 
                                        [min]="dateOption.minDate" [max]="dateOption.maxDate" placeholder="Select date">
                                    <mat-datepicker-toggle matSuffix [for]="dateOption_i"></mat-datepicker-toggle>
                                    <mat-datepicker #dateOption_i></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let option of configuration.options">
                        <div fxLayout="column" fxLayoutGap="16px">
                            <div class="filter-label">{{option.name}}</div>
                            <div *ngFor="let value of option.values | orderBy: option.sort ? 'text': ''">
                                <mat-checkbox [checked]="isChecked(option, value)" (change)="toggleValueSelected(option, value)">{{value.text}}</mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end">
                    <button mat-raised-button (click)="applyFilters(); filterVisible = false;">Apply</button>
                </div>
            </div>
            
        </div>
    </div>
        
    <div *ngIf="filter.searchText || fromDate || toDate || flattenedSelectedValues?.length" class="applied-filters" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div fxLayout="row" fxFlex fxLayoutGap="8px">
            <button *ngIf="filter.searchText" mat-raised-button class="xsmall" (click)="removeSearchText()">{{filter.searchText}}<mat-icon>close</mat-icon></button>
            <button *ngFor="let value of flattenedSelectedValues" mat-raised-button class="xsmall" (click)="removeFilter(value)">{{value.text}}<mat-icon>close</mat-icon></button>
            <button *ngIf="fromDate" mat-raised-button class="xsmall" (click)="removeFromDate()">{{fromDate}}<mat-icon>close</mat-icon></button>
            <button *ngIf="toDate" mat-raised-button class="xsmall" (click)="removeToDate()">{{toDate}}<mat-icon>close</mat-icon></button>
        </div>
        <div class="pointer filters-clear-text clear-button" (click)="clearFilters()">Clear All</div>
    </div>
</div>
