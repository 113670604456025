import { IdDocumentType } from 'app/shared/enums/id-document-type.enum';
import { ItemType } from 'app/shared/enums/item-type.enum';

export class UploadImageRequest {
    token: string;
    base64Image: string;
    description: string;
    mimeType: string;
    setId: string;
    itemId: string;
    observationId: string;
    type: ItemType;
    idDocumentType: IdDocumentType;
}