import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Components
import { ConfirmationRequiredDialogComponent } from 'app/shared/components/confirmation-required-dialog/confirmation-required-dialog.component';

// Services
import { ImageUploadService } from 'app/shared/services';
import html2canvas from 'html2canvas';

// Enums
import { FileExtensions } from 'app/shared/enums/file-extensions.enum';
import { SignatureColor } from 'app/shared/enums/signature-color.enum';
import { SignatureType } from 'app/shared/enums/signature-type.enum';

@Component({
    templateUrl: './signature-dialog.component.html',
    styleUrls: ['./signature-dialog.component.scss']
})
export class SignatureDialogComponent implements OnInit, AfterViewInit {

    form: FormGroup;
    signatureType: SignatureType;
    signatureColor = SignatureColor.Blue;
    typedSignatureSelected = false;
    selectedTypedSignatureId: number;
    color: string;
    typedSignatureTimeout: any;
    redraw = false;

    SignatureType = SignatureType;
    SignatureColor = SignatureColor;
    allowedFileExtensions = [ FileExtensions.Jpeg, FileExtensions.Jpg, FileExtensions.Png ];

    constructor(
        @Inject(MAT_DIALOG_DATA) data: string,
        private dialog: MatDialogRef<SignatureDialogComponent>,
        private imageUploadService: ImageUploadService,
        private dialogs: MatDialog,
        formBuilder: FormBuilder) {

            this.form = formBuilder.group({
                name: [data, [Validators.required]],
                base64Image: [null, [Validators.required]],
                signedDate: [null]
            });
    }

    ngOnInit(): void {
        this.form.get('name').valueChanges.subscribe(() => {
            this.updateTypedSignature();
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.selectSignatureColor(SignatureColor.Blue), 20);
    }

    sign(): void {
        const confirmDialog = this.dialogs.open(ConfirmationRequiredDialogComponent, {
            data: {
                confirmationMessage: `Are you sure you want to complete the signing of this document?`
            },
            disableClose: true
        });

        confirmDialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.confirmSign();
            }
        });
    }

    confirmSign(): void {
        this.form.get('signedDate').setValue(new Date());
        this.dialog.close(this.form.value);
    }

    selectTypedSignature(id: number): void {
        this.selectedTypedSignatureId = id;
        this.typedSignatureSelected = true;
        this.updateTypedSignature();
    }

    selectSignatureColor(signatureColor: SignatureColor) {
        this.signatureColor = signatureColor;

        switch (this.signatureColor) {
            case SignatureColor.Black: this.color = '#070707'; break;
            case SignatureColor.Blue: this.color = '#2b4bff'; break;
            case SignatureColor.Green: this.color = '#007000'; break;
            case SignatureColor.Purple: this.color = '#5d1ffc'; break;
        }
    }

    changeTypedSignature(): void {
        this.selectedTypedSignatureId = null;
        this.typedSignatureSelected = false;
    }

    handleSignatureUploaded(file: File): void {
        this.imageUploadService.getBase64(file).then((base64Image: string) => {
            this.form.get('base64Image').setValue(base64Image);
        });
    }

    handleDrawnSignatureChanged(base64Image: string): void {
        setTimeout(() => this.form.get('base64Image').setValue(base64Image), 20);
    }

    handleTabChanged(): void {
        this.form.get('base64Image').setValue(null);
        this.selectedTypedSignatureId = null;
        this.typedSignatureSelected = false;
    }

    private updateTypedSignature(): void {
        this.redraw = true;
        setTimeout(() => this.redraw = false, 1);
        if (this.selectedTypedSignatureId > 0) {
            clearTimeout(this.typedSignatureTimeout);
            this.typedSignatureTimeout = setTimeout(() => {
                html2canvas(document.getElementById('typed-signature')).then((canvas) => {
                    const base64Image = canvas.toDataURL();
                    this.form.get('base64Image').setValue(base64Image);
                });
            }, 200);
        }
    }
}
