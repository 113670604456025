import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAuth from 'app/authentication-v2/store/reducers/auth.reducers';

import { AuthenticationState } from 'app/authentication-v2/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<AuthenticationState>(StoreNames.authenticationV2);
const reducerState = createSelector(storeState, (state: AuthenticationState) => state.authenticationState);

export const getAuthenticationWorking = createSelector(reducerState, (state: fromAuth.AuthenticationState) => state.working);
export const getAuthenticationMessage = createSelector(reducerState, (state: fromAuth.AuthenticationState) => state.authenticationMessage);
export const getResetPasswordOnLogin = createSelector(reducerState, (state: fromAuth.AuthenticationState) => state.passwordResetOnLogin);