import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum IdDocumentType {
    Other = 0,
    Passport = 1,
    DrivingLicence = 2,
    Visa = 3,
    CscsCard = 4,
    HomeOfficeLetter = 5,
    BirthCertificate = 6,
    NationalIdCard = 7,
    ResidencePermit = 9,
    UtilityBill = 11,
}

export const IdDocumentTypes = [
    new EnumValueTitle(IdDocumentType.Passport, 'Passport'),
    new EnumValueTitle(IdDocumentType.DrivingLicence, 'Driving Licence'),
    new EnumValueTitle(IdDocumentType.Visa, 'Visa'),
    new EnumValueTitle(IdDocumentType.CscsCard, 'CSCS Card'),
    new EnumValueTitle(IdDocumentType.HomeOfficeLetter, 'Home Office Letter'),
    new EnumValueTitle(IdDocumentType.BirthCertificate, 'Birth Certificate'),
    new EnumValueTitle(IdDocumentType.NationalIdCard, 'National ID Card'),
    new EnumValueTitle(IdDocumentType.ResidencePermit, 'Residence Permit'),
    new EnumValueTitle(IdDocumentType.UtilityBill, 'Utility Bill'),
    new EnumValueTitle(IdDocumentType.Other, 'Other')
];

export function getIdDocumentTypeTitle(idDocumentType: IdDocumentType): string {
    return IdDocumentTypes.find(s => s.value === idDocumentType)?.title;
}