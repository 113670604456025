import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/store/reducers';
import * as fromActiveUser from 'app/store/reducers/active-user.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getState = createFeatureSelector<fromFeature.State>(StoreNames.shared);
const getActiveUserState = createSelector(getState, (state: fromFeature.State) => state.activeUser);

export const getOrganisationAssessmentSets = createSelector(getActiveUserState, (state: fromActiveUser.State) => state.organisationAssessmentSets);
export const getUserInviteId = createSelector(getActiveUserState, (state: fromActiveUser.State) => state.inviteId);
export const getIsClientUser = createSelector(getActiveUserState, (state: fromActiveUser.State) => state.isClientUser);
export const getSelectedClientSet = createSelector(getActiveUserState, (state: fromActiveUser.State) => state.selectedClientSet);