// Enums
import { ManageAssessmentSetDialogType } from 'app/shared/modules/group/enums/manage-assessment-set-dialog-type.enum';

export class ManageAssessmentSetInfo {
    groupId: string;
    entityId: string;
    type: ManageAssessmentSetDialogType;

    constructor(type: ManageAssessmentSetDialogType, groupId: string = null, entityId: string) {
        this.type = type;
        this.groupId = groupId;
        this.entityId = entityId;
    }
}