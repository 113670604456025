import { TermsOverviewCollection } from 'app/shared/modules/terms-obsolete/models/responses/terms-overview-collection.model';
import { TermsOverviewItem } from 'app/shared/modules/terms-obsolete/models/responses/terms-overview-item.model';

export class TermsOverviewUtility {

    public static tryUpdateTermsInCollection(collection: TermsOverviewCollection[], terms: TermsOverviewItem): TermsOverviewCollection[] {
        if (!collection || !collection.filter(c => c.items.filter(item => item.id === terms.id).any()).any()) {
            return collection;
        }

        const newCollection: TermsOverviewCollection[] = collection.map((c: TermsOverviewCollection) => {
            const index = c.items.findIndex(i => i.id === terms.id);
            if (index === -1) {
                return c;
            }

            const newTerms: TermsOverviewItem = {
                ...c.items[index],
                signedBy: terms.signedBy,
                signedDate: terms.signedDate,
                signedTermsLabel: terms.signedTermsLabel,
                signedTermsVersionId: terms.signedTermsVersionId
            };

            const newCollectionSpliced: TermsOverviewCollection = {
                ...c,
                items: c.items
            };

            newCollectionSpliced.items.splice(index, 1, newTerms);

            return newCollectionSpliced;
        });

        return newCollection;
    }
}
