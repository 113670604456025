<div class="selfie-observation" fxLayout="column" fxLayoutAlign="start start" >
    <h3>{{item.description}}</h3>

    <div class="take-selfie-container" fxLayout="row" fxLayoutAlign="start start" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap="10px">
        
        <app-photo-capture #imageUpload
            (imageUploaded)="imageUploaded($event)"
            (imageLoading)="imageLoading($event)"
            (imageDownloadClicked)="imageDownloadClicked()"
            [downloadEnabled]="false"
            [disableUpload]="item.readonly"
            [removeEnabled]="false"
            [convertToJpg]="false"
            [qrCodeData]="qrCodeData"
            [useFrontFacingCamera]="true">
        </app-photo-capture>

        <app-image-card 
            [canDownload]="false"
            [canRemove]="false"
            [src]="selfieSrc">
        </app-image-card>
    </div>
    <div *ngIf="(recordingObservation$ | async)">
        <app-loading-card message="Please wait while we upload and analyse your selfie..."></app-loading-card>
    </div>
</div>
