// Store
import * as TermsActions from 'app/shared/modules/terms-obsolete/store/actions/terms.actions';

// Models
import { TermsResponse } from 'app/shared/modules/terms-obsolete/models/responses/terms-response.model';

export type Action = TermsActions.AllTermsActions;

export interface TermsState {
    organisationTerms: TermsResponse[];
    terms: TermsResponse;
    loading: boolean;
    loaded: boolean;
    saving: boolean;
    saved: boolean;
}

const initialState: TermsState = {
    organisationTerms: null,
    terms: null,
    loading: false,
    loaded: false,
    saving: false,
    saved: false
};

export function reducer(state = initialState, action: Action): TermsState {
    switch (action.type) {
        case TermsActions.CREATE_TERMS: {
            return {
                ...state,
                saving: true,
                saved: false
            };
        }

        case TermsActions.CREATE_TERMS_SUCCESS: {
            return {
                ...state,
                saving: false,
                saved: true,
                terms: action.payload
            };
        }

        case TermsActions.CREATE_TERMS_FAIL: {
            return {
                ...state,
                saving: false,
                saved: false
            };
        }

        case TermsActions.UPDATE_TERMS: {
            return {
                ...state,
                saving: true,
                saved: false
            };
        }

        case TermsActions.UPDATE_TERMS_SUCCESS: {
            return {
                ...state,
                saving: false,
                saved: true,
                terms: action.payload
            };
        }

        case TermsActions.UPDATE_TERMS_FAIL: {
            return {
                ...state,
                saving: false,
                saved: false
            };
        }

        case TermsActions.GET_TERMS: {
            return {
                ...state,
                loading: true,
                loaded: false
            };
        }

        case TermsActions.GET_TERMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                loaded: true,
                terms: action.payload
            };
        }

        case TermsActions.GET_TERMS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
                terms: null
            };
        }

        case TermsActions.GET_ORGANISATION_TERMS: {
            return {
                ...state,
                loading: true,
                loaded: false
            };
        }

        case TermsActions.GET_ORGANISATION_TERMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                loaded: true,
                organisationTerms: action.payload
            };
        }

        case TermsActions.GET_ORGANISATION_TERMS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
                organisationTerms: null
            };
        }
    }

    return state;
}