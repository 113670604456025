import { NgModule } from '@angular/core';


// Modules
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ngrx | rxjs
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

// modules
import { SharedModule } from 'app/shared/shared.module';
import { RichTextEditorModule } from 'app/shared/modules/rich-text-editor/rich-text-editor.module';

// Store
import { reducers, effects } from './store';

// Components
import { TermsDialogComponent } from './components/terms-dialog/terms-dialog.component';
import { TermsStatusComponent } from './components/terms-status/terms-status.component';

// Services
import { TermsService } from './services/terms.service';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

@NgModule({
    declarations: [
        TermsStatusComponent,
        TermsDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        RichTextEditorModule,
        EffectsModule.forFeature(effects),
        StoreModule.forFeature(StoreNames.termsManagement, reducers)
    ],
    exports: [
        TermsDialogComponent
    ],
    entryComponents: [
        TermsDialogComponent
    ],
    providers: [
        TermsService
    ]
})
export class TermsModule {
    constructor() { }
}