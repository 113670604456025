import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { ColorPickerDialogData } from 'app/shared/models/color-picker-dialog-data.model';

@Component({
    templateUrl: './color-picker-dialog.component.html',
})
export class ColorPickerDialogComponent extends BaseComponent {
    title: string;
    label: string;
    color: string;
    originalColor: string;

    constructor(
        private dialogRef: MatDialogRef<ColorPickerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ColorPickerDialogData) {
            super();

            this.title = data.title;
            this.label = data.label;
            this.color = data.color;

            this.originalColor = this.color;
    }

    onColorSelected(newColor: string): void {
        this.color = newColor;
    }

    onColorReset(): void {
        this.color = this.originalColor;
    }

    onSaveClick(): void {
        this.dialogRef.close(this.color);
    }
}
