// Models
import { OrganisationTitle } from 'app/shared/models/organisation-title.model';
import { TemplateSignature } from './template-signature.model';

// Enums
import { DocumentType } from 'app/portal/modules/document-management/enums';
import { DocumentCreationMode } from 'app/portal/modules/document-management/enums/document-creation-mode.enum';
import { SignatureType } from 'app/portal/modules/document-management/enums/signature-type.enum';
import { DocumentTemplateType } from 'app/portal/modules/document-management/enums/document-template-type.enum';

export class Template {
    id: string;
    name: string;
    revision: number;
    creationMode: DocumentCreationMode;
    signatureType: SignatureType;
    dateCreated: Date;
    dateUpdated: Date;
    enabled: boolean;
    canDelete: boolean;
    text: string;
    documentType: DocumentType;
    isOwner: boolean;
    ownerOrganisation: OrganisationTitle;
    sharedWithOrganisations: OrganisationTitle[];
    documentTemplateType: DocumentTemplateType;
    templateSignatures: TemplateSignature[];

    constructor() {
        this.revision = 1;
        this.creationMode = DocumentCreationMode.ReportingEngine;
        this.enabled = false;
        this.signatureType = SignatureType.NoSigning;
    }
}
