import { Injectable } from '@angular/core';

// Store
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import * as setActions from 'app/shared/modules/set-observations/store/actions';

// Services
import { SetObservationService } from 'app/shared/modules/set-observations/services/set-observation.service';

// Models
import { AssessmentProfileResponse } from 'app/shared/modules/set-observations/models/responses/assessment-profile-response.model';

@Injectable()
export class OrganisationProfileEffects {


    getOrganisationAssessmentProfile$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_ORGANISATION_ASSESSMENT_PROFILE),
        switchMap((action: setActions.GetOrganisationAssessmentProfile) => this.setObservationService.getOrganisationProfile(action.payload).pipe(
                map((profile: AssessmentProfileResponse) => {
                    if (profile) {
                        return new setActions.GetAssessmentProfileSuccess([profile]);
                    }
                    return new setActions.GetAssessmentProfileFail('Unable to retrieve the organisation profile.');
                }),
                catchError(error => of(new setActions.GetAssessmentProfileFail(error)))
            ))
    ));


    getOrganisationAssessmentProfileByOwner$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_ORGANISATION_ASSESSMENT_PROFILE_BY_OWNER),
        switchMap((action: setActions.GetOrganisationAssessmentProfileByOwner) => this.setObservationService.getOrganisationProfileByOwner(action.payload).pipe(
                map((profile: AssessmentProfileResponse) => {
                    if (profile) {
                        return new setActions.GetAssessmentProfileSuccess([profile]);
                    }
                    return new setActions.GetAssessmentProfileFail('Unable to retrieve the organisation profile.');
                }),
                catchError(error => of(new setActions.GetAssessmentProfileFail(error)))
            ))
    ));


    getOrganisationGroupAssessmentProfile$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_ORGANISATION_GROUP_ASSESSMENT_PROFILE),
        switchMap((action: setActions.GetOrganisationGroupAssessmentProfile) => this.setObservationService.getGroupOrganisationProfile(action.payload).pipe(
                map((profile: AssessmentProfileResponse) => {
                    if (profile) {
                    return new setActions.GetAssessmentProfileSuccess([profile]);
                    }
                    return new setActions.GetAssessmentProfileFail('Unable to retrieve the organisation profile');
                }),
                catchError(error => of(new setActions.GetAssessmentProfileFail(error)))
            ))
    ));


    getUserAssessmentProfiles$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.GET_USER_ASSESSMENT_PROFILES),
        switchMap(() => this.setObservationService.getUserAssessmentProfiles().pipe(
                map((profiles: AssessmentProfileResponse[]) => {
                    if (profiles) {
                        return new setActions.GetAssessmentProfileSuccess(profiles);
                    }
                    return new setActions.GetAssessmentProfileFail('Unable to retrieve the user profiles.');
                }),
                catchError(error => of(new setActions.GetAssessmentProfileFail(error)))
            ))
    ));


    createMissingOrganisationSets$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(setActions.CREATE_MISSING_ORGANISATION_SETS),
        switchMap((action: setActions.CreateMissingOrganisationSets) => this.setObservationService.createNewOrganisationSets(action.payload).pipe(
                map((newSetsAdded: boolean) => new setActions.CheckSetsAddedToProfile(newSetsAdded))
            ))
    ));

    constructor(
        private actions$: Actions,
        private setObservationService: SetObservationService) { }
}