import { Component } from '@angular/core';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

// models
import { IdentityVerificationResponse } from 'app/shared/modules/identity-verification/models/identity-verification-response.model';

@Component({
    selector: 'app-observation-identity-verification',
    templateUrl: './identity-verification-observation.component.html'
})
export class IdentityVerificationObservationComponent extends ObservationComponent {

    idvData: IdentityVerificationResponse;

    constructor() {
        super();
    }

    init(): void {
        if (this.itemObservation.value && this.itemObservation.value.length > 0) {
            this.idvData = <IdentityVerificationResponse>JSON.parse(this.itemObservation.value);
        }
    }

    onDestroy(): void {
    }
}
