<div class="folder-card padding-8 overflow-hidden" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <div class="folder-icon primary-bg primary-reverse-text pointer" fxLayout="column" fxLayoutAlign="center center" (click)="onOpenClick()">
        <mat-icon class="folder">people</mat-icon>
    </div>
    
    <div fxLayout="column" fxFlex (click)="onOpenClick()" class="overflow-hidden pointer">
        <div class="text font-body-xsmall ellipsis">{{item.title}}</div>
        <div class="text font-body-xsmall grey-600-text" fxLayout="row" fxFlex fxLayoutAlign="space-between end">
            <div>{{item.count}} {{item.count === 1 ? 'Member' : 'Members'}}</div>
        </div>
    </div>
    <mat-icon *ngIf="canEditFolders || canDeleteFolders" [matMenuTriggerFor]="menu" class="pointer">more_vert</mat-icon>
    
    <mat-menu #menu="matMenu">
        <button *ngIf="canEditFolders" mat-menu-item (click)="onEditClick()" [title]="'Edit ' + item.title">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
        </button>
        <button *ngIf="canDeleteFolders" mat-menu-item (click)="onDeleteClick()" [title]="'Delete ' + item.title">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
        </button>
    </mat-menu>
</div>
