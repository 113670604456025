// ngrx
import { Action } from '@ngrx/store';

// Models
import { DocumentLibraryItem } from 'app/shared/modules/set-observations/models/document-library-item.model';

export const GET_COMMON_DOCUMENTS = '[Document Library] Get common documents';
export const GET_COMMON_DOCUMENTS_SUCCESS = '[Document Library] Get common documents success';
export const GET_COMMON_DOCUMENTS_FAIL = '[Document Library] Get common documents fail';

export class GetCommonDocuments implements Action {
    readonly type = GET_COMMON_DOCUMENTS;
    constructor(public payload: string) { }
}

export class GetCommonDocumentsSuccess implements Action {
    readonly type = GET_COMMON_DOCUMENTS_SUCCESS;
    constructor(public payload: DocumentLibraryItem[]) { }
}

export class GetCommonDocumentsFail implements Action {
    readonly type = GET_COMMON_DOCUMENTS_FAIL;
}

export type AllOrganisationDocumentActions
    = GetCommonDocuments
    | GetCommonDocumentsSuccess
    | GetCommonDocumentsFail;
