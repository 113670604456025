<app-dialog
    id="badge-profile-dialog"
    [dialogTitle]="badge?.title"
    defaultWidth="80vw"
    defaultHeight="80vh"
    [showFooterActions]="false"
    [showClose]="true">

    <div sub-header class="entity-name accent-bg">{{entityName}}</div>
    <div body fxLayout="column" class="content">
        <app-badge-profile
            [badge]="badge"
            [readonly]="readonly"
            (assessmentSetsModified)="assessmentSetsModified()"
            [iconSource]="iconSource"
            [entityName]="entityName">
        </app-badge-profile>
    </div>
</app-dialog>