import { Modules } from 'app/shared/enums';

export class Breadcrumb {
    moduleId: Modules;
    title: string;
    url: string;
    queryParams: object;

    constructor(moduleId: Modules = null, title: string = null, url: string = null, queryParams: object = null) {
        this.moduleId = moduleId;
        this.title = title;
        this.url = url;
        this.queryParams = queryParams;
    }
}
