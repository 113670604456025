<app-page #page (search)="search($event)" id="document-library" (newClicked)="createDocument()">
    <div content fxLayout="column" fxFlex>
        <app-loading-card *ngIf="(loading$ | async)"></app-loading-card>
        <div *ngIf="!(loading$ | async)" class="" fxLayout="column">

            <app-page-information-text text="View your documents or documents that have been shared with you."></app-page-information-text>

            <div fxLayout="column" fxLayoutGap="24px">
                <app-section-wrapper sectionTitle="Signatory" [expanded]="true" *ngIf="isSignatoryFilteredDocuments?.length">
                    <app-card-container fxLayout="row" fxLayoutGap="16px">
                        <app-document-library-card
                            *ngFor="let document of isSignatoryFilteredDocuments"
                            fxFlex="97%"
                            fxFlex.gt-xs="47%"
                            fxFlex.gt-md="30.33%"
                            fxFlex.gt-lg="22%"
                            [document]="document"
                            (downloadClicked)="downloadDocumentClick($event)"
                            (deleteClicked)="deleteDocumentClick($event)"
                            (cardClicked)="editDocumentClick($event)"
                            (signClicked)="signDocumentClick($event)">
                        </app-document-library-card>
                    </app-card-container>
                </app-section-wrapper>

                <app-section-wrapper sectionTitle="Not Signatory" [expanded]="true" *ngIf="notSignatoryFilteredDocuments?.length">
                    <app-card-container fxLayout="row" fxLayoutGap="16px">
                        <app-document-library-card
                            *ngFor="let document of notSignatoryFilteredDocuments"
                            fxFlex="97%"
                            fxFlex.gt-xs="47%"
                            fxFlex.gt-md="30.33%"
                            fxFlex.gt-lg="22%"
                            [document]="document"
                            (downloadClicked)="downloadDocumentClick($event)"
                            (deleteClicked)="deleteDocumentClick($event)"
                            (cardClicked)="editDocumentClick($event)"
                            (signClicked)="signDocumentClick($event)">
                        </app-document-library-card>
                    </app-card-container>
                </app-section-wrapper>
            </div>
            <app-no-information-message *ngIf="!hasData()"></app-no-information-message>
        </div>
    </div>
</app-page>