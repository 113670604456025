import { Injectable } from '@angular/core';

// ngrx
import { Store } from '@ngrx/store';

// store
import * as fromConnect from 'app/connect/store';
import * as fromApp from 'app/store';
import * as fromUserInterface from 'app/store/actions/user-interface.actions';

// models
import { RefreshTokenRequest, TokenResponse } from 'app/shared/models';

@Injectable()
export class AuthenticationTokenService {

    private tokenName: string = 'token';
    private refreshTokenName: string = 'refresh-token';
    private clientIdName: string = 'clientId';
    private isClientName: string = 'isClient';
    private userIdName: string = 'userId';
    // TODO - following property is temporary while we have 2 versions of login/authentication
    private authVersion: string = 'authVersion';
    private hideHeaderName = 'hideHeader';
    private readOnlyAccessName = 'readOnly';
    private userGroupIdName = 'userGroupId';
    private expiresTimestamp = 'expiresTimestamp';
    private storage: Storage = sessionStorage;

    constructor(
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private appStore: Store<fromApp.State>) { }

    getToken(): string {
        return this.storage.getItem(this.tokenName);
    }

    getRefreshToken(): string {
        return this.storage.getItem(this.refreshTokenName);
    }

    clear(): void {
        this.appStore.dispatch(new fromUserInterface.ClearSessionTimeout());
        this.storage.removeItem(this.tokenName);
        this.storage.removeItem(this.refreshTokenName);
        this.storage.removeItem(this.userIdName);
        this.storage.removeItem(this.clientIdName);
        this.storage.removeItem(this.authVersion);
        this.storage.removeItem(this.isClientName);
        this.storage.removeItem(this.hideHeaderName);
        this.storage.removeItem(this.readOnlyAccessName);
        this.storage.removeItem(this.userGroupIdName);
    }

    setAuthToken(newToken: TokenResponse): void {
        this.storage.setItem(this.tokenName, newToken.auth_token);
        this.storage.setItem(this.refreshTokenName, newToken.refresh_token);
        this.storage.setItem(this.userIdName, newToken.id);
        this.storage.setItem(this.authVersion, newToken.version);

        // Add the expiry (seconds) to the timestamp (milliseconds)
        this.storage.setItem(this.expiresTimestamp, (new Date().getTime() + (newToken.expires_in * 1000)).toString());

        this.connectStore.dispatch(fromConnect.SetVersion({ isConnect: this.usingV2() }));

        this.appStore.dispatch(new fromUserInterface.StartSessionTimeout());
    }

    clientId(): string {
        return this.storage.getItem(this.clientIdName);
    }

    isClient(): boolean {
        return this.storage.getItem(this.isClientName) === 'true';
    }

    userId(): string {
        return this.storage.getItem(this.userIdName);
    }

    isLoggedIn(): boolean {
        return this.getToken() != null;
    }

    version(): string {
        return this.storage.getItem(this.authVersion);
    }

    usingV2(): boolean {
        const version = this.version();
        if (version) {
            return version === 'v2';
        }
        return false;
    }

    getLoginUrl(): string {
        if (this.usingV2()) {
            return '/auth-v2/login';
        } else {
            return '/auth/login';
        }
    }

    setActiveClient(clientId: string, isClient: boolean): void {
        this.storage.setItem(this.clientIdName, clientId);
        this.storage.setItem(this.isClientName, isClient ? 'true' : 'false');
    }

    hideHeader(): boolean {
         return this.storage.getItem(this.hideHeaderName)?.toLowerCase() === 'true';
    }

    setHideHeader(hideHeader: boolean): void {
        this.storage.setItem(this.hideHeaderName, hideHeader ? 'true' : 'false');
    }

    readOnlyAccess(): boolean {
        return this.storage.getItem(this.readOnlyAccessName)?.toLowerCase() === 'true';
    }

    setReadOnlyAccess(readOnlyAccess: boolean): void {
        this.storage.setItem(this.readOnlyAccessName, readOnlyAccess ? 'true' : 'false');
    }

    userGroupId(): string {
        return this.storage.getItem(this.userGroupIdName);
    }

    setUserGroup(userGroupId: string): void {
        this.storage.setItem(this.userGroupIdName, userGroupId);
    }

    tokenHasExpired(): boolean {
        const expires = parseInt(this.storage.getItem(this.expiresTimestamp), 10);
        const current = new Date().getTime();
        return expires < current;
    }

    getRefreshTokenRequest(): RefreshTokenRequest {
        return new RefreshTokenRequest(
            this.userId(),
            this.getRefreshToken(),
            this.getToken());
    }
}