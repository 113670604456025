import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';

// Utilities
import { getFileExtension } from 'app/shared/utilities/string-utilities';

// Enums
import { FileExtensions } from 'app/shared/enums/file-extensions.enum';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']

})
export class FileUploadComponent implements OnInit {

    @Input()
    disableUpload: boolean = false;

    @Input()
    downloadEnabled: boolean = false;

    @Input()
    allowedFileExtensions: FileExtensions[] = [FileExtensions.Csv];

    @Input()
    maximumFileSizeMb: number = 0;

    @Input()
    uploadButtonLabel: string = 'Upload';

    @Input()
    uploadText: string = 'Upload document';

    @Input()
    fileName: string;

    @Input()
    useCamera: boolean = false;

    @Input()
    simpleView: boolean = false;

    @Input()
    layoutAlign: string = 'center center';

    @Output()
    fileUploaded = new EventEmitter<File>();

    @Output()
    fileDownloadClicked = new EventEmitter<void>();

    @Output()
    invalidFileUploaded = new EventEmitter();

    @ViewChild('upload')
    uploadElement;

    maximumFileSizeBytes: number;
    working: boolean;
    invalidFileExtension: boolean;
    invalidFileSize: boolean;
    uploadedFile: File;

    constructor() { }

    ngOnInit() {
        this.maximumFileSizeBytes = this.maximumFileSizeMb * 1048576;
    }

    onDestroy() {
        this.clearFile();
    }

    addFile() {
        this.invalidFileExtension = false;
        this.invalidFileSize = false;
        this.uploadElement.nativeElement.click();
    }

    onFileAdded() {
        this.working = true;
        if (this.uploadElement.nativeElement.files.length <= 0) {
            this.working = false;
            return;
        }

        const file: File = this.uploadElement.nativeElement.files[0];
        const ext = getFileExtension(file.name);
        this.invalidFileExtension = ext.length === 0 || !(this.allowedFileExtensions.some(x => x === FileExtensions.Any) || this.allowedFileExtensions.some(x => x === ext));
        this.invalidFileSize = this.maximumFileSizeMb !== 0 && file.size > this.maximumFileSizeBytes;

        if (this.invalidFileExtension || this.invalidFileSize) {
            this.uploadedFile = null;
            this.invalidFileUploaded.emit();
        } else {
            this.uploadedFile = file;
            this.fileName = file.name;
        }

        if (this.uploadedFile && !this.invalidFileExtension && !this.invalidFileSize) {
            this.fileUploaded.emit(this.uploadedFile);
        }

        this.working = false;

        if (this.simpleView) {
            this.clearFile();
        }
    }

    clearFile() {
        if (this.uploadElement) {
            this.uploadElement.nativeElement.value = '';
        }
    }

    handleFileDownloadClicked() {
        this.clearFile();
        this.fileDownloadClicked.emit();
    }

    get imageFileTypes(): string {

        if (this.allowedFileExtensions.filter(e => e === FileExtensions.Any).length > 0) {
            return FileExtensions.Any;
        }

        let types = '';
        for (const type of this.allowedFileExtensions) {
            if (types !== '') {
                types += ',';
            }

            types += `image/${type}`;
        }

        return types;
    }

    get fileTypes(): string {

        if (this.allowedFileExtensions.filter(e => e === FileExtensions.Any).length > 0) {
            return FileExtensions.Any;
        }

        let types = '';
        for (const type of this.allowedFileExtensions) {
            if (types !== '') {
                types += ',';
            }

            types += `.${type}`;
        }

        return types;
    }
}
