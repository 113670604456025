<div class="right-to-work-check">
    <app-loading-card *ngIf="!url && stage !== stages.Success"></app-loading-card>
    <div *ngIf="url && stage === stages.Initial">
        <div class="spacer">Our automated systems powered by <img src="/assets/credas-logo.svg" style="vertical-align: middle;width: 60px;"> will be used to carry out the right to work check.</div>
        <div class="spacer">Press the continue button below to begin the process, you will be returned to this application when you complete the process.</div>
        <button (click)="getStarted()" class="mat-raised-button mat-button-base spacer">Continue</button>
    </div>
    <div *ngIf="stage === stages.Success">
        <h2><img src="/assets/hub/navigation/services/supply-chain.svg" style="width:40px">&nbsp; Thank you for submitting your right to work check.</h2>
        <div>Our automated systems powered by <img src="/assets/credas-logo.svg" style="vertical-align: middle;width: 60px;"> will check your <strong>selfie</strong>, <strong>liveness test</strong> and <strong>ID Documents</strong> and will update the result in your question set automatically.</div>
        <div>We usually expect this to only take a short time. Should any of the tests be inconclusive, you may need to come back and re-do some or all of the tests.</div>
        <div>You can also re-do any of the tests at any time by returning to this question set.</div>
        <div>Hit <strong>Save</strong> or <strong>Next</strong> below to continue.</div>
    </div>
    <div *ngIf="url && stage === stages.Cancelled">
        <div class="spacer">Identity verification has not been completed.</div>
        <div class="spacer">Hit try again to return to <img src="/assets/credas-logo.svg" style="vertical-align: middle;width: 60px;"> and complete the journey.</div>
        <button (click)="getStarted()" class="mat-raised-button mat-button-base spacer">Try Again</button>
    </div>
</div>
