import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// ngrx | rxjs
import { Store, select } from '@ngrx/store';
import { takeUntil, map } from 'rxjs/operators';

// store
import * as fromAuth from 'app/authentication/store';
import * as fromConnect from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// services
import { AuthenticationEventTrackingService } from 'app/authentication/services/authentication-event-tracking.service';
import { User } from 'app/models/user.model';

@Component({
    templateUrl: 'login.component.html'
})
export class LoginComponent extends BaseComponent implements OnInit {

    inviteId: string;
    working: boolean;
    authenticationMessage: string;
    hidePassword: boolean = true;
    form: FormGroup;
    loggedIn: boolean = false;

    constructor(
        private store: Store<fromAuth.AuthenticationState>,
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public authenticationEventTrackingService: AuthenticationEventTrackingService) {
        super();
    }

    ngOnInit(): void {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationWorking))
            .subscribe((working: boolean) => this.working = working);

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationMessage))
            .subscribe((authenticationMessage: string) => this.authenticationMessage = authenticationMessage);

        this.form = this.fb.group({
            email: [null, [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(255)]],
            password: [null, [Validators.required, Validators.minLength(8)]],
            rememberMe: [false]
        });

        this.route.queryParams.pipe(
            map(param => {
                if (param.piid) {
                    this.inviteId = param.piid;
                }
            }),
            takeUntil(this.ngUnsubscribe))
            .subscribe();

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getUser))
            .subscribe((user: User) => {
                this.loggedIn = user !== null;
            });
    }

    loginUser() {
        this.authenticationEventTrackingService.loginClicked();
        this.store.dispatch(new fromAuth.Login(this.form.value));
    }
}