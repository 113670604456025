<div fxLayout="column" id="toolbar" fxLayoutGap="16px">
    <div fxLayout="row" fxLayout="start center" class="application-header-info">
        <div *ngIf="pageTitle$ | async" fxFlex  fxLayout="column" fxLayoutGap="10px">
            <h3 class="application-header-title">
                {{ pageTitle$ | async }}
                <app-connect-tooltip id="client-portal-app-title"></app-connect-tooltip>
            </h3>
            <div *ngIf="(service$ | async)?.helpText || (application$ | async)?.helpText" class="application-header-description">
                {{ (service$ | async)?.helpText ? (service$ | async)?.helpText : (application$ | async)?.helpText }}
            </div>
        </div>
    
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
            
            <ng-content select="[standalone-buttons]"></ng-content>

            <button mat-raised-button class="ghost" *ngIf="showShowMoreButton$ | async" (click)="onShowMoreButtonClick()">More Info<mat-icon>more_vert</mat-icon></button>

            <div *ngIf="showNewButton$ | async" class="position-relative">
                <button mat-raised-button (click)="onNewButtonClick()">Add new</button>
                <app-connect-tooltip id="client-portal-add-new" position="left"></app-connect-tooltip>
            </div>

            <div *ngIf="showAdditionalOptions || (allowSwitchView$ | async) || (allowExport$ | async)" class="position-relative">
                <button mat-raised-button class="ghost" [matMenuTriggerFor]="additionalMenu" fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex>Additional options</div>
                    <mat-icon>expand_more</mat-icon>
                </button>
                <app-connect-tooltip id="client-portal-additional-options" position="left"></app-connect-tooltip>
            </div>

            <mat-menu #additionalMenu="matMenu">
                <div id="toolbar-popup-menu">
                    <ng-content></ng-content>
                    <button mat-menu-item *ngIf="allowSwitchView$ | async" (click)="handleSwitchViewClick(ViewType.Card)" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                        <span>Card View</span>
                        <mat-icon *ngIf="currentViewType === ViewType.Card" class="rag-green-text">check</mat-icon>
                    </button>
                    <button mat-menu-item *ngIf="allowSwitchView$ | async" (click)="handleSwitchViewClick(ViewType.List)" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                        <span>Table View</span>
                        <mat-icon *ngIf="currentViewType === ViewType.List" class="rag-green-text">check</mat-icon>
                    </button>
                    <button mat-menu-item *ngIf="allowExport$ | async" (click)="handleExportClick()">
                        Export CSV
                    </button>
                </div>
            </mat-menu>
        </div>
    </div>
    <div fxFlex fxLayout="row">     
        <div *ngIf="showHeader$ | async" class="connect-header-services" [ngClass]="{ 'with-underline': !((services$ | async)?.length) || !customMenus?.length }" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
            <div *ngIf="!(hideNavigation$ | async)" id="services" fxLayout="row" fxLayoutAlign="start end" class="primary-text">
                <span *ngFor="let service of services$ | async" class="service position-relative">
                    <div
                        fxLayout="column"
                        fxLayoutAlign="center center"
                        class="service-link"
                        (click)="onServiceClick(service.id)"
                        [ngClass]="{ 'active': service.id === (service$ | async)?.id}">
                        {{service.title}}
                    </div>
                    <app-connect-tooltip id="client-portal-module-{{ service.id }}"></app-connect-tooltip>
                </span>
            </div>
            <div *ngIf="(hideNavigation$ | async) && customMenus?.length > 0" id="services" fxLayout="row" fxLayoutAlign="start end" class="primary-text">
                <div
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    *ngFor="let menu of customMenus"
                    class="service-link"
                    (click)="onCustomMenuClick(menu)"
                    [ngClass]="{ 'active': menu.id === currentMenu.id}">
                    {{menu.label}}
                </div>
            </div>            
        </div>

        
    </div>
</div>