<app-dialog id="badge-dialog" dialogTitle="My Profile" defaultWidth="600px" defaultHeight="600px">
    <div body id="user-profile-container"> 
        <form name="userDetailsForm" [formGroup]="userDetailsForm" novalidate>
            <div>
                <div class="font-question-label">First Name</div>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="firstname">
                    <mat-error *ngIf="validationService.hasError(userDetailsForm.controls['firstname'])">
                        {{ validationService.getError(userDetailsForm.controls['firstname']) }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div>
                <div class="font-question-label">Surname</div>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="surname">
                    <mat-error *ngIf="validationService.hasError(userDetailsForm.controls['surname'])">
                        {{ validationService.getError(userDetailsForm.controls['surname']) }}
                    </mat-error>
                </mat-form-field>
            </div>
        </form>

        <form name="passwordForm" [formGroup]="passwordForm" novalidate>
            <div>
                <div class="font-question-label">Current Password (required for password change)</div>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="currentPassword" [type]="hideCurrent ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hideCurrent = !hideCurrent">{{hideCurrent ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </div>

            <div>
                <div class="font-question-label">New Password</div>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="password" [type]="hideNew ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hideNew = !hideNew">{{hideNew ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="validationService.hasError(passwordForm.controls['password'])">
                        {{ validationService.getError(passwordForm.controls['password']) }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div>
                <div class="font-question-label">Confirm New Password</div>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="confirmPassword" [type]="hideConfirm ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="validationService.hasError(passwordForm.controls['confirmPassword'])">
                        {{ validationService.getError(passwordForm.controls['confirmPassword']) }}
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div footer-actions>
        <button id="close-button" mat-raised-button mat-dialog-close cdkFocusInitial class="ghost">Cancel</button>
        <button id="save-button" mat-raised-button [disabled]="saveDisabled()" (click)="save()">Save</button>
    </div>
</app-dialog>
