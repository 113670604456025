import { Item } from 'app/shared/modules/set-observations/models/item.model';

export class ItemsUtility {
    public static setPaging(pageNumber: number, currentPage: number, items: Item[]): Item[] {
        currentPage = pageNumber;

        if (items) {
            return items.filter(io => io.pageNumber === currentPage);
        }

        return items;
    }
}