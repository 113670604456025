import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// ngrx
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

// Store
import * as fromStore from 'app/portal/modules/document-library-v1/store';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Models
import { DocumentLibraryItem } from 'app/shared/modules/set-observations/models/document-library-item.model';

// Enums
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

@Component({
    templateUrl: './document-dialog.component.html',
    styleUrls: ['./document-dialog.component.scss']
})
export class DocumentDialogComponent extends BaseComponent {

    documentLink: string;
    title: string;
    isSignatory = false;
    pdf: Blob;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: DocumentLibraryItem,
        authenticationTokenService: AuthenticationTokenService,
        private store: Store<fromStore.DocumentsState>) {
        super();

        this.documentLink = data.esignDocumentUrl;
        const myOrganisationId = authenticationTokenService.clientId();
        const signedSignature = data.documentSignatures.filter(s => s.organisation.id === myOrganisationId && s.signedDate)[0];
        const unsignedSignature = data.documentSignatures.filter(s => s.organisation.id === myOrganisationId && !s.signedDate)[0];
        const mySignature = unsignedSignature || signedSignature;

        if (mySignature && mySignature.esignSignatureId) {
            this.isSignatory = true;
            this.documentLink += `&s=${mySignature.esignSignatureId}`;
        } else {
            this.store.dispatch(new fromStore.GetEsignDocument(data.id));
            this.store.pipe(
                takeUntil(this.ngUnsubscribe),
                select(fromStore.getPdf))
                .subscribe((pdf: Blob) => this.pdf = pdf);
        }

        this.title = data.name;
    }
}