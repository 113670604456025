import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';

// ngrx
import { Store, select } from '@ngrx/store';

// Store
import * as fromStore from 'app/portal/modules/document-library-v1/store';

// Models
import { OrganisationTitle } from 'app/shared/models/organisation-title.model';
import { CreateDocumentSignatureRequest } from 'app/portal/modules/document-library-v1/models/create-document-signature.model';
import { User } from 'app/shared/models/user.model';

import { OrganisationUsers } from 'app/portal/modules/document-library-v1/models/organisation-users.model';

// Components
import { BaseComponent } from 'app/shared/base/base-component';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-document-wizard-step-2',
    templateUrl: './document-wizard-step-2.component.html',
    styleUrls: ['./document-wizard-step-2.component.scss']
})
export class DocumentWizardStep2Component extends BaseComponent implements OnChanges {
    @Input()
    form: FormGroup;

    @Input()
    organisations: OrganisationTitle[];

    @Input()
    signatureEmailsValid: boolean;

    organisationUsers: OrganisationUsers[];

    documentSignatures: FormGroup[];

    constructor(private store: Store<fromStore.DocumentsState>) {
        super();

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getOrganisationUsers))
            .subscribe((organisationUsers: OrganisationUsers[]) => this.organisationUsers = organisationUsers);
    }

    ngOnChanges(): void {
        const signatures = (<FormGroup[]>this.form.get('documentSignatures').value).map(f => <CreateDocumentSignatureRequest>f.value);
        signatures.forEach(s => {
            if (s.organisationId) {
                this.store.dispatch(new fromStore.GetOrganisationUsers(s.organisationId));
            }
        });
    }

    handleSelectionChange(organisationId: string): void {
        const signatures = (<FormGroup[]>this.form.get('documentSignatures').value).map(f => <CreateDocumentSignatureRequest>f.value);
        const documentShares = signatures.filter(s => s.organisationId && s.requiredToSign).map(s => s.organisationId);
        this.organisations.filter(o => documentShares.includes(o.id)).forEach(organisation => organisation.selected = true);
        this.form.get('documentShares').setValue(documentShares);

        if (!this.organisationUsers || this.organisationUsers.filter(o => o.organisationId === organisationId).length === 0) {
            this.store.dispatch(new fromStore.GetOrganisationUsers(organisationId));
        }
    }

    handleOrganisationUserSelected(option: MatOption, documentSignatureForm: FormGroup): void {
        if (option && option.value) {
            const user = <User>option.value;
            documentSignatureForm.get('name').setValue(`${user.firstName} ${user.surname}`);
            documentSignatureForm.get('email').setValue(user.email);
        }
    }

    getOrganisationUsers(organisationId: string): User[] {
        if (!this.organisationUsers) {
            return null;
        }

        const organisationUsers = this.organisationUsers.filter(o => o.organisationId === organisationId)[0];
        if (!organisationUsers) {
            return null;
        }

        return organisationUsers.users;
    }
}
