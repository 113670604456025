import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TemplateIndexComponent } from './components/template-index/template-index.component';

const routes: Routes = [
    { path: '', component: TemplateIndexComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DocumentManagementRoutingModule {}
