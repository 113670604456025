import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

// models
import { Option } from 'app/portal/modules/inputs/models/option.model';
import { SearchableOption } from 'app/shared/models/searchable-option.model';

@Component({
    selector: 'app-option-input',
    templateUrl: 'option-input.component.html',
    styleUrls: ['option-input.component.scss']
})
export class OptionInputComponent implements OnInit {

    @Input()
    label: string;

    @Input()
    description: string;

    @Input()
    options: Option[];

    @Input()
    value: string;

    @Input()
    values: string[];

    @Input()
    mandatory = false;

    @Input()
    readonly = false;

    @Input()
    multiple = false;

    @Output()
    valueChanged: EventEmitter<string> = new EventEmitter();

    @Output()
    valuesChanged: EventEmitter<string[]> = new EventEmitter();

    selectedOptions: Option[] = [];

    searchableOptions: SearchableOption[];

    constructor() { }

    ngOnInit(): void {
        if (this.multiple && this.values) {
            for (const value of this.values) {
                const selectedOption = this.options.filter(o => o.value === value);
                if (selectedOption.length === 1) {
                    this.selectedOptions.push(selectedOption[0]);
                }
            }
        }

        if (!this.multiple && this.value) {
            const selectedOption = this.options.filter(o => o.value === this.value);
            if (selectedOption.length === 1) {
                this.selectedOptions.push(selectedOption[0]);
            }
        }

        const tmpOptions = this.options;
        this.options = [];
        this.options.push(...tmpOptions);

        this.searchableOptions = this.options.map(o => ({ id: o.value, value: o.label, selected: !!this.selectedOptions.find(so => so.value === o.value) })) as SearchableOption[];
    }

    onSelectOption(option: Option) {
        if (this.readonly) {
            return;
        }

        if (this.selectedOptions.filter(o => o === option).length > 0) {
            this.selectedOptions = this.selectedOptions.filter(o => o !== option);
        } else {
            if (this.multiple) {
                this.selectedOptions.push(option);
            } else {
                this.selectedOptions = [option];
            }
        }

        if (this.multiple) {
            if (this.selectedOptions.length === 0) {
                this.valuesChanged.emit([]);
            } else {
                this.valuesChanged.emit(this.selectedOptions.map(o => o.value));
            }
        } else {
            if (this.selectedOptions.length === 1) {
                this.valueChanged.emit(this.selectedOptions.map(o => o.value)[0]);
            } else {
                this.valueChanged.emit(null);
            }
        }
    }

    isSelected(option: Option) {
        return this.selectedOptions && option && this.selectedOptions.filter(o => o === option).length > 0;
    }

    onDropdownSelectionChange(values: SearchableOption[] | SearchableOption): void {
        if (Array.isArray(values)) {
            this.setValues(this.options.filter(o => values.map(v => v.id).includes(o.value)));
        } else {
            this.setValues(this.options.filter(o => values.id === o.value));
        }
    }

    onRadioChange(value: string): void {
        if (value) {
            this.setValues(this.options.filter(o => o.value === value));
        } else {
            this.setValues([]);
        }
    }

    onCheckboxChange(checked: boolean, value: string): void {
        const option = this.options.find(o => o.value === value);
        if (checked) {
            this.setValues([...this.selectedOptions, option]);
        } else {
            this.setValues(this.selectedOptions.filter(o => o !== option));
        }
    }

    private setValues(options: Option[]): void {
        if (this.readonly) {
            return;
        }

        this.selectedOptions = options;

        if (this.multiple) {
            if (this.selectedOptions.length === 0) {
                this.valuesChanged.emit([]);
            } else {
                this.valuesChanged.emit(this.selectedOptions.map(o => o.value));
            }
        } else {
            if (this.selectedOptions.length === 1) {
                this.valueChanged.emit(this.selectedOptions.map(o => o.value)[0]);
            } else {
                this.valueChanged.emit(null);
            }
        }
    }
}
