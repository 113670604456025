<div id="document-wizard-step-1" fxLayout="column" fxLayoutGap="10px" [formGroup]="form">
    <h2>Step 1 - Select a template and a title for the document.</h2>
    <div>
        <div class="font-question-label">Document template</div>
        <mat-form-field appearance="outline">
            <mat-select formControlName="templateId" (selectionChange)="handleTemplateChanged($event.value)">
                <mat-option *ngFor="let template of templates" [value]="template.id">
                    {{template.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('templateId').hasError('required')">Template is a required field.</mat-error>
        </mat-form-field>
    </div>
    <div>
        <div class="font-question-label">Document title</div>
        <mat-form-field appearance="outline">
            <input matInput formControlName="title" />
            <mat-error *ngIf="form.get('title').hasError('required')">Title is a required field.</mat-error>
        </mat-form-field>
    </div>
</div>