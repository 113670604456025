import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, tap } from 'rxjs/operators';

// store
import * as AuthActions from 'app/authentication/store/actions/auth.actions';
import * as ActiveUserActions from 'app/store/actions/active-user.actions';
import * as UserActivityActions from 'app/store/actions/user-activity.actions';
import * as ConnectActions from 'app/connect/store/actions/application.actions';

// services
import { AuthenticationService } from 'app/authentication/services/authentication.service';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';
import { AuthenticationEventTrackingService } from 'app/authentication/services/authentication-event-tracking.service';

// models
import { User } from 'app/models/user.model';
import { TokenResponse } from 'app/shared/models/token-response.model';
import { InviteDetails } from 'app/authentication/models/invite-details.model';
import { RefreshTokenRequest, UserActivityRequest} from 'app/shared/models';
import { LogoutOptions } from 'app/models/logout-options.model';
import { TemporaryPasswordReset } from 'app/authentication/models/temporary-password-reset.model';

// components
import { TimeoutExplanationDialogComponent } from 'app/authentication/dialogs/timeout-explanation-dialog/timeout-explanation-dialog.component';

// enums
import { TermsType } from 'app/shared/enums/terms-type.enum';
import { UserActivityType } from 'app/shared/enums/user-activity-type.enum';
import { RegistrationStatus } from 'app/authentication/enums/registration-status.enum';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

@Injectable()
export class AuthEffects {

    timeoutPopup: MatDialogRef<TimeoutExplanationDialogComponent>;

    login$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.LOGIN),
        map((action: AuthActions.Login) => action.payload),
        mergeMap(payload =>
            this.authService.login(payload).pipe(
                map((user: User) => {
                    if (user) {
                        if (user.token.password_reset_required) {
                            const tempPasswordReset = new TemporaryPasswordReset(payload.email, user.token.reset_password_token);
                            return new AuthActions.PasswordResetRequired(tempPasswordReset);
                        } else {
                            return new AuthActions.LoginSuccess(user);
                        }
                    } else {
                        this.authenticationEventTrackingService.loginFailed();
                        return new AuthActions.LoginFailed('Your username or password may be incorrect. Please check and try again');
                    }
                }),
                catchError(() => of(new AuthActions.LoginFailed('Error occurred on login attempt.'))))
        )));

    resetPassword$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.PASSWORD_RESET_REQUIRED),
        tap(() => this.router.navigate(['/auth/reset-password']))),
        { dispatch: false });


    loginSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.LOGIN_SUCCESS),
        map((action: AuthActions.LoginSuccess) => action.payload),
        switchMap((payload) => {
            const actions: any[] = [
                new AuthActions.SetToken(payload.token),
                new AuthActions.CheckTermsShutOutRequired(),
                new UserActivityActions.LogUserActivity(new UserActivityRequest(UserActivityType.Login)),
                ConnectActions.SetUser({ user: payload, performRedirect: true, setServiceFromUrl: false})
            ];

            return actions;
        })));

    rehydrateUser$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.REHYDRATE_USER),
        switchMap(() => this.authService.rehydrateUser().pipe(
                map((user: User) => new AuthActions.RehydrateUserSuccess(user)),
                catchError(() => of(new AuthActions.RehydrateUserFail()))
            ))
    ));

    rehydrateUserSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.REHYDRATE_USER_SUCCESS),
        map((action: AuthActions.RehydrateUserSuccess) => action.payload),
        switchMap((user) => [
            new AuthActions.SetToken(user.token),
            ConnectActions.SetUser({ user, performRedirect: false, setServiceFromUrl: true })
        ]
    )));

    rehydrateUserFail$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.REHYDRATE_USER_FAIL),
        map(() => new AuthActions.Logout(new LogoutOptions(false, true, null))
    )));

    refreshToken$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.REFRESH_TOKEN),
        map((action: AuthActions.RefreshToken) => action.payload),
        mergeMap((payload: RefreshTokenRequest) => {
            if (payload.accessToken === 'undefined' ||
                payload.refreshToken === 'undefined' ||
                payload.userId === 'undefined') {
                    return of(new AuthActions.RefreshTokenFailed());
                }

            return this.authService.refreshToken(payload).pipe(
                map((tokenResponse: TokenResponse) => {
                    if (tokenResponse && tokenResponse.success) {
                        return new AuthActions.RefreshTokenSuccess(tokenResponse);
                    }
                    return new AuthActions.RefreshTokenFailed();
                }),
                catchError(() => of(new AuthActions.RefreshTokenFailed())));
            }
        ),
        catchError(() => of(new AuthActions.RefreshTokenFailed()))));

    refreshTokenSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.REFRESH_TOKEN_SUCCESS),
        map((action: AuthActions.RefreshTokenSuccess) => action.payload),
        switchMap((payload) => [new AuthActions.SetToken(payload)])));

    refreshTokenFailed$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.REFRESH_TOKEN_FAIL),
        switchMap(() => {
            if (!this.timeoutPopup) {
                this.timeoutPopup = this.dialog.open(TimeoutExplanationDialogComponent, {
                    disableClose: true
                });

                this.timeoutPopup.afterClosed().subscribe(() => this.timeoutPopup = null);
            }
            return [new AuthActions.Logout(new LogoutOptions(false, true, null))];
        })));

    setToken$ = createEffect(() =>   this.actions$.pipe(
        ofType(AuthActions.SET_TOKEN),
        tap((action: AuthActions.SetToken) => this.authenticationToken.setAuthToken(action.payload))),
        { dispatch: false });

    logout$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.LOGOUT),
        map((action: AuthActions.Logout) => action.payload),
        switchMap((options: LogoutOptions) => {
            const actions: any[] = [];

            if (options.logActivity) {
                actions.push(new UserActivityActions.LogUserActivity(new UserActivityRequest(UserActivityType.Logout)));
            }

            actions.push(
                new ActiveUserActions.EmptyState(),
                new AuthActions.LogoutSuccess(options)
            );

            return actions;
        })));

    logoutSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.LOGOUT_SUCCESS),
        tap((action: AuthActions.LogoutSuccess) => {
            this.authenticationToken.clear();

            if (action.payload.redirectUrl) {
                window.location.href = action.payload.redirectUrl;
            } else if (action.payload.redirectToLoginPage) {
                window.location.href = '/auth/login';
            }
        })),
        { dispatch: false });

    register$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.REGISTER),
        map((action: AuthActions.Register) => action.payload),
        switchMap(payload =>
            this.authService.register(payload).pipe(
                map((user: User) => {
                    if (user) {
                        return new AuthActions.RegisterSuccess(user);
                    } else {
                        return new AuthActions.RegisterFailed('There was a problem registering your account.');
                    }
                }),
                catchError(() => of(new AuthActions.RegisterFailed('There was a problem registering your account.'))))
        )));

    registerSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.REGISTER_SUCCESS),
        map((action: AuthActions.RegisterSuccess) => action.payload),
        switchMap((user) => [
            new AuthActions.SetToken(user.token),
            ConnectActions.SetUser({ user, performRedirect: true, setServiceFromUrl: false })
        ])));

    getInviteDetails$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.GET_INVITE_DETAILS),
        map((action: AuthActions.GetInviteDetails) => action.payload),
        switchMap((inviteId: string) => this.authService.getInviteDetails(inviteId).pipe(
                map((inviteDetails: InviteDetails) => new AuthActions.GetInviteDetailsSuccess(inviteDetails)),
                catchError(() => of(new AuthActions.GetInviteDetailsFail()))
            ))));

    acceptInvite$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.ACCEPT_INVITE),
        map((action: AuthActions.AcceptInvite) => action.payload),
        switchMap((inviteId: string) => this.authService.acceptInvite(inviteId).pipe(
                map(() => new AuthActions.AcceptInviteSuccess()),
                catchError(() => of(new AuthActions.AcceptInviteFail()))
            ))));

    checkTermsShutOutRequired$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.CHECK_TERMS_SHUTOUT_REQUIRED),
        switchMap(() => this.authService.checkTermsShutOutRequired().pipe(
                map((response: any) => new AuthActions.CheckTermsShutOutRequiredSuccess(response)),
                catchError(() => of(new AuthActions.CheckTermsShutOutRequiredFail()))
            ))));

    getRegistrationTerm$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.GET_REGISTRATION_TERM),
        map((action: AuthActions.GetRegistrationTerm) => action.payload),
        switchMap((type: TermsType) => this.authService.getRegistrationTerm(type).pipe(
                map((response: any) => new AuthActions.GetRegistrationTermSuccess(response)),
                catchError(() => of(new AuthActions.CheckTermsShutOutRequiredFail()))
            ))));

    checkTermsShutOutRequiredSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.CHECK_TERMS_SHUTOUT_REQUIRED_SUCCESS),
        tap((action: AuthActions.CheckTermsShutOutRequiredSuccess) => {
            const overview = action.payload;
            if (!overview.termsUpToDate) {
            }
        })),
        { dispatch: false });

    validateRegistrationCode$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.VALIDATE_REGISTRATION_CODE),
        map((action: AuthActions.ValidateRegistrationCode) => action.payload),
        switchMap((registrationCode: string) => this.authService.validateRegistrationCode(registrationCode).pipe(
                map((status: RegistrationStatus) => new AuthActions.ValidateRegistrationCodeSuccess(status)),
                catchError(() => of(new AuthActions.ValidateRegistrationCodeFail()))
            ))));

    validateEmailAddress$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.VALIDATE_EMAIL_ADDRESS),
        map((action: AuthActions.ValidateEmailAddress) => action.payload),
        switchMap((email: string) => this.authService.validateEmailAddress(email).pipe(
                map((isValid: boolean) => new AuthActions.ValidateEmailAddressSuccess(isValid)),
                catchError(() => of(new AuthActions.ValidateEmailAddressFail()))
            ))));

    setPassword$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.SET_PASSWORD),
        map((action: AuthActions.SetPassword) => action.payload),
        switchMap(request => this.authService.resetPassword(request).pipe(
                map((user: User) => {
                    if (user) {
                        return new AuthActions.SetPasswordSuccess(user);
                    }

                    return new AuthActions.SetPasswordFail();
                }),
                catchError(() => of(new AuthActions.SetPasswordFail()))
            ))
    ));

    setPasswordSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.SET_PASSWORD_SUCCESS),
        switchMap((action: AuthActions.SetPasswordSuccess) => {
            const doRedirect = window.location.pathname.startsWith('/auth/');
            return [
                new AuthActions.SetToken(action.payload.token),
                ConnectActions.SetUser({ user: action.payload, performRedirect: doRedirect, setServiceFromUrl: false })
            ];
        })
    ));

    setPasswordFail$ = createEffect(() =>  this.actions$.pipe(
        ofType(AuthActions.SET_PASSWORD_FAIL),
        tap(() =>
            this.alertService.error('We were unable to set your password at this time.')
        )
    ),
    { dispatch: false });

    constructor(
        private dialog: MatDialog,
        private actions$: Actions,
        private authService: AuthenticationService,
        private router: Router,
        private alertService: AlertService,
        private authenticationToken: AuthenticationTokenService,
        private authenticationEventTrackingService: AuthenticationEventTrackingService) { }
}
