import { Component} from '@angular/core';
import { FormControl } from '@angular/forms';

// NGRX
import { Observable} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, startWith, takeUntil } from 'rxjs/operators';

// components
import { ObservationComponent } from 'app/shared/modules/set-observations/components/observations/observation/observation.component';

// Models
import { CourseSummary } from 'app/shared/modules/set-observations/models/responses/course-summary.model';
import { CourseDetail } from 'app/shared/modules/set-observations/models/responses/course-detail.model';
import { Skill } from 'app/shared/modules/set-observations/models/responses/skill.model';

// Store
import * as fromStore from 'app/shared/modules/set-observations/store';


/* eslint-disable @angular-eslint/no-outputs-metadata-property */
@Component({
    selector: 'app-observation-course-selector',
    templateUrl: './course-selector-observation.component.html',
    styleUrls: ['./course-selector-observation.component.scss'],
    outputs: ['valueUpdated', 'selectedChanged']
})
export class CourseSelectorObservationComponent extends ObservationComponent {

    courses: CourseSummary[];
    loadingCourses$: Observable<boolean>;
    loadingCourseDetail$: Observable<boolean>;
    selectedCourse: CourseDetail;
    startDate: Date;
    learningTypes = [
        'B1 - Learning completed in a bilingual context',
        'B2 - A significant amount of Welsh-medium context',
        'B3 - A small amount of Welsh-medium learning',
        'C1 - Learning completed in a Welsh-medium context',
        'E1 - Delivered through English only'
    ];
    firstCourseSelection = true;
    courseSelectorControl = new FormControl();
    currentLevelControl = new FormControl('', { updateOn: 'blur'});
    filteredCourses: Observable<CourseSummary[]>;

    constructor(private store: Store<fromStore.SetObservationsState>) {
        super();
    }

    init() {
        this.loadingCourses$ = this.store.select(fromStore.getLoadingCourses);
        this.loadingCourseDetail$ = this.store.select(fromStore.getLoadingCourseDetail);

        if (this.itemObservation.hasValue()) {
            this.selectedCourse = JSON.parse(this.itemObservation.value);
            this.startDate = new Date(this.selectedCourse.startDate);
            this.currentLevelControl.setValue(this.selectedCourse.currentLevel);
            this.getCourses();
        }

        this.filteredCourses = this.courseSelectorControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this.filterCourses(value))
            );

        this.currentLevelControl.valueChanges.subscribe(value => {
            if (this.selectedCourse) {
                this.selectedCourse.currentLevel = value;
                this.updateObservation();
            }
        });

    }

    handleDateChanged(date: Date): void {
        this.startDate = date;
        this.getCourses();
        if (!this.startDate) {
            this.selectedCourse = null;
        }
    }

    updateObservation(): void {
        this.updateValue(JSON.stringify(this.selectedCourse));
    }

    handleSkillChanged(skill: Skill, checked: boolean): void {
        skill.included = checked;

        if (!checked) {
            skill.learningMedium = null;
        }

        this.updateObservation();
    }

    handleSkillMediumChanged(skill: Skill, learningMedium: string): void {
        skill.learningMedium = learningMedium;
        this.updateObservation();
    }

    handleCourseSelected(selectedCourse: CourseSummary): void {
        const reference = selectedCourse ? selectedCourse.reference : null;
        this.store.dispatch(new fromStore.GetCourseDetails(reference));

        if (this.firstCourseSelection) {
            this.firstCourseSelection = false;
            this.store.pipe(
                takeUntil(this.ngUnsubscribe),
                select(fromStore.getCourseDetail))
                .subscribe((course: CourseDetail) => {
                    this.selectedCourse = course;
                    if (this.selectedCourse) {
                        this.selectedCourse.skills.forEach(s => s.included = true);

                        this.selectedCourse.startDate = this.startDate;
                        this.currentLevelControl.setValue(this.selectedCourse.currentLevel);

                        this.updateObservation();
                    }
                });
        }
    }

    getCourseDisplay(course: CourseSummary): string {
        return course && course.frameworkTitle ? course.frameworkTitle : '';
    }

    filterCourses(value: string): CourseSummary[] {
        if (value && typeof value === 'string' && this.courses) {
            const filterValue = value.toLowerCase();
            return this.courses.filter(course => course.frameworkTitle.toLowerCase().includes(filterValue));
        } else {
            return this.courses;
        }
    }

    private getCourses(): void {
        if (!this.startDate) {
            return;
        }

        this.store.dispatch(new fromStore.GetCourses(this.startDate));

        if (!this.courses) {
            this.store.pipe(
                takeUntil(this.ngUnsubscribe),
                select(fromStore.getCourses))
                .subscribe((courses: CourseSummary[]) => {
                    this.courses = courses;
                    this.initCourseLookup();
                });
        }
    }

    private initCourseLookup() {
        if (this.courses) {
            if (this.selectedCourse) {
                const matchingCourse = this.courses.find(c => c.reference === this.selectedCourse.reference);
                this.courseSelectorControl.setValue(matchingCourse);
            } else {
                this.courseSelectorControl.setValue('');
            }
        }
    }

}