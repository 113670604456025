// ngrx
import { createReducer, on, Action } from '@ngrx/store';

// store
import * as actions from 'app/shared/modules/identity-verification/store/actions/identity-verification.actions';

// models
import { IdentityVerificationConfiguration } from 'app/shared/modules/identity-verification/models/identity-verification-configuration.model';
import { IdentityVerificationResponse } from 'app/shared/modules/identity-verification/models/identity-verification-response.model';

export interface IdentityVerificationState {
    recordingSelfie: boolean;
    recordingUap: boolean;
    recordingIdDocument: boolean;
    identityVerification: IdentityVerificationResponse;
    configuration: IdentityVerificationConfiguration;
}

const initialState: IdentityVerificationState = {
    recordingSelfie: false,
    recordingUap: false,
    recordingIdDocument: false,
    identityVerification: null,
    configuration: null
};

const identityVerificationReducer = createReducer(
    initialState,
    on(actions.SetIdvData, (state, action) => ({
        ...state,
        identityVerification: action.idvData
    })),
    on(actions.GetIdvData, (state) => ({
        ...state,
        identityVerification: null
    })),
    on(actions.GetIdvDataSuccess, (state, action) => ({
        ...state,
        identityVerification: action.response
    })),
    on(actions.GetIdvConfiguration, (state) => ({
        ...state,
        configuration: null
    })),
    on(actions.GetIdvConfigurationSuccess, (state, action) => ({
        ...state,
        configuration: action.response
    })),
    on(actions.AddSelfie, (state) => ({
        ...state,
        recordingSelfie: true
    })),
    on(actions.AddSelfieSuccess, (state, action) => ({
        ...state,
        recordingSelfie: false,
        identityVerification: action.response
    })),
    on(actions.AddSelfieFail, (state) => ({
        ...state,
        recordingSelfie: false
    })),
    on(actions.AddUap, (state) => ({
        ...state,
        recordingUap: true
    })),
    on(actions.AddUapSuccess, (state, action) => ({
        ...state,
        recordingUap: false,
        identityVerification: action.response
    })),
    on(actions.AddUapFail, (state) => ({
        ...state,
        recordingUap: false
    })),
    on(actions.AddIdDocument, (state) => ({
        ...state,
        recordingIdDocument: true
    })),
    on(actions.AddIdDocumentSuccess, (state, action) => ({
        ...state,
        recordingIdDocument: false,
        identityVerification: action.response
    })),
    on(actions.AddIdDocumentFail, (state) => ({
        ...state,
        recordingIdDocument: false
    }))
);

export function reducer(state: IdentityVerificationState | undefined, action: Action) {
    return identityVerificationReducer(state, action);
}