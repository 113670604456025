import { Template } from './template.model';
import { DocumentTemplateType } from 'app/portal/modules/document-management/enums/document-template-type.enum';

export class TemplateDialogData {
    template: Template;
    documentTemplateType: DocumentTemplateType;

    constructor(template: Template, documentTemplateType: DocumentTemplateType) {
        this.template = template;
        this.documentTemplateType = documentTemplateType;
    }
}