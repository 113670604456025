import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// ngrx
import { Store, select, ActionsSubject } from '@ngrx/store';
import { takeUntil, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Store
import * as fromTemplates from 'app/portal/modules/document-management/store';
import * as fromDocuments from 'app/portal/modules/document-library-v1/store';
import * as fromGroups from 'app/shared/modules/group/store';
import * as fromConnect from 'app/connect/store';

// Models
import { Template } from 'app/portal/modules/document-management/models/template.model';
import { OrganisationTitle } from 'app/shared/models/organisation-title.model';
import { GeneratePreviewRequest } from 'app/portal/modules/document-library-v1/models/generate-preview-request.model';
import { CreateDocumentRequest } from 'app/portal/modules/document-library-v1/models/create-document-request.model';
import { CreateDocumentSignatureRequest } from 'app/portal/modules/document-library-v1/models/create-document-signature.model';
import { DocumentWizardDialogData } from 'app/portal/modules/document-library-v1/models/document-wizard-dialog-data.model';
import { User } from 'app/models/user.model';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Enums
import { SignatureType } from 'app/portal/modules/document-management/enums';

// Services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';
import { PdfViewerService } from 'app/shared/services';


@Component({
    templateUrl: './document-wizard-dialog.component.html',
    styleUrls: ['./document-wizard-dialog.component.scss']
})
export class DocumentWizardDialogComponent extends BaseComponent implements OnInit {
    stepIndex: number = 0;
    finished: boolean = false;
    form: FormGroup;

    templates: Template[];
    organisations: OrganisationTitle[];
    preview: Blob;
    previewAsBase64: string;
    previewLoading$: Observable<boolean>;
    signatureEmailsValid = true;
    loggedInUser: User;

    private entities: OrganisationTitle[];
    private clients: OrganisationTitle[];

    constructor(
        private templateStore: Store<fromTemplates.DocumentsState>,
        private groupStore: Store<fromGroups.GroupsState>,
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private documentStore: Store<fromDocuments.DocumentsState>,
        private formBuilder: FormBuilder,
        private actionsSubject: ActionsSubject,
        private dialogRef: MatDialogRef<DocumentWizardDialogComponent>,
        private authenticationTokenService: AuthenticationTokenService,
        private pdfViewerService: PdfViewerService,
        @Inject(MAT_DIALOG_DATA) private data: DocumentWizardDialogData ) {

            super();

            this.form = this.formBuilder.group({
                title: [null, [Validators.required]],
                templateId: [null, [Validators.required]],
                documentShares: [[]],
                documentSignatures: [[]]
        });
    }

    ngOnInit(): void {
        this.templateStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromTemplates.getDocuments))
            .subscribe((templates: Template[]) => {
                this.templates = null;
                if (templates) {
                    this.templates = templates.filter(t => t.signatureType === SignatureType.FullESign);
                    this.setupInitialData();
                }
            });

        this.documentStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromDocuments.getPreview))
            .subscribe((preview: Blob) => {
                if (preview) {
                    this.preview = preview;
                    this.pdfViewerService.getPdfBase64(preview, false).subscribe((base64) => {
                        this.previewAsBase64 = base64;
                    });
                }
            });

        this.previewLoading$ = this.documentStore.select(fromDocuments.getPreviewLoading);

        this.groupStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromGroups.getEntities))
            .subscribe((entities: OrganisationTitle[]) => {
                this.entities = entities;
                this.setupOrganisations();
            });

        this.groupStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromGroups.getClients))
            .subscribe((clients: OrganisationTitle[]) => {
                this.clients = clients;
                this.setupOrganisations();
            });

        this.templateStore.dispatch(new fromTemplates.GetDocuments());
        this.groupStore.dispatch(new fromGroups.GetEntitiesToShareWith(true));
        this.groupStore.dispatch(new fromGroups.GetClientsToShareWith(true));

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromDocuments.CREATE_DOCUMENT_SUCCESS))
            .subscribe(() => {
                this.close(true);
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(action => action.type === fromDocuments.CREATE_DOCUMENT_FAIL))
            .subscribe(() => {
                this.stepIndex--;
            });

        this.connectStore.pipe(
                takeUntil(this.ngUnsubscribe),
                select(fromConnect.getUser)
            ).subscribe((data: User) => {
                this.loggedInUser = data;
            });
    }

    previous(): void {
        this.stepIndex--;
    }

    next(): void {
        if (this.stepIndex === 1) {
            const organisationIds = (<FormGroup[]>this.form.get('documentSignatures').value).map(form => <string>form.get('organisationId').value);
            const request = new GeneratePreviewRequest(
                <string>this.form.get('templateId').value,
                organisationIds);

            this.documentStore.dispatch(new fromDocuments.GeneratePreview(request));
        }

        this.stepIndex++;
    }

    close(created: boolean): void {
        this.dialogRef.close(created);
    }

    save(): void {
        this.stepIndex++;
        this.finished = true;

        const request  = <CreateDocumentRequest>this.form.value;
        request.documentSignatures = (<FormGroup[]>this.form.get('documentSignatures').value).map(f => <CreateDocumentSignatureRequest>f.value);
        request.generatedPreviewBase64 = this.previewAsBase64;
        this.documentStore.dispatch(new fromDocuments.CreateDocument(this.form.value));
    }

    handleTemplateChanged(templateId: string): void {
        const template = this.templates.filter(t => t.id === templateId)[0];
        const documentSignatures = template.templateSignatures
            .sort((t1, t2) => t1.signatureOrder - t2.signatureOrder)
            .map(t => this.formBuilder.group({
                organisationId: [null, [Validators.required]],
                title: [t.title, [Validators.required]],
                name: [null, [Validators.required]],
                email: [null, [Validators.email, Validators.required]],
                requiredToSign: [t.requiredToSign],
                includeInDocument: [t.includeInDocument],
                page: [t.page],
                locationX: [t.locationX],
                locationY: [t.locationY]
            }));

        this.form.get('documentSignatures').setValue(documentSignatures);
    }

    handleSelectedSharedOrganisationChanged(organisation: OrganisationTitle): void {
        const currentSelected = <string[]>this.form.get('documentShares').value;
        if (organisation.selected) {
            const newSelected = [...currentSelected, organisation.id];
            this.form.get('documentShares').setValue(newSelected);
        } else {
            const newSelected = currentSelected.filter(o => o !== organisation.id);
            this.form.get('documentShares').setValue(newSelected);
        }
    }

    get stepValid(): boolean {
        switch (this.stepIndex) {
            case 0: return this.step1Valid;
            case 1: return this.step2Valid;
            case 2: return this.step3Valid;
            default: return this.form.valid;
        }
    }

    get step1Valid(): boolean {
        return this.form.get('templateId').valid &&
            this.form.get('title').valid;
    }

    get step2Valid(): boolean {
        const signatures = <FormGroup[]>this.form.get('documentSignatures').value;
        if (signatures.filter(f => f.invalid).length !== 0) {
            return false;
        }

        // validate all emails are unique
        const emails: string[] = [];
        for (const signature of signatures) {
            let email = <string>signature.get('email').value;
            if (email) {
                email = email.toLocaleLowerCase();

                if (emails.includes(email)) {
                    this.signatureEmailsValid = false;
                    return false;
                }

                emails.push(email);
            }
        }

        this.signatureEmailsValid = true;

        return true;
    }

    get step3Valid(): boolean {
        return (<string[]>this.form.get('documentShares').value).any();
    }

    private setupInitialData(): void {
        if (this.data && this.templates && this.organisations) {
            this.handleTemplateChanged(this.data.templateId);

            const template = this.templates.filter(t => t.id === this.data.templateId)[0];
            this.form.get('templateId').setValue(this.data.templateId);
            this.form.get('title').setValue(`${template.name} - ${this.data.organisationTitle}`);
            this.stepIndex = 1;

            const signatures = <FormGroup[]>this.form.get('documentSignatures').value;
            if (signatures.length > 0) {
                signatures[0].get('organisationId').setValue(this.data.firstSignatoryId);
                signatures[0].get('name').setValue(this.data.organisationTitle);
                signatures[0].get('email').setValue(this.data.organisationEmail);

                if (signatures.length > 2) {
                    signatures[2].get('organisationId').setValue(this.authenticationTokenService.clientId());
                    if (this.loggedInUser) {
                        signatures[2].get('name').setValue(`${this.loggedInUser.firstName} ${this.loggedInUser.surname}`);
                        signatures[2].get('email').setValue(this.loggedInUser.email);
                    }
                }
            }

            this.form.get('documentSignatures').setValue(signatures);
        }
    }

    private setupOrganisations(): void {
        if (!this.clients || !this.entities) {
            return;
        }

        const myOrganisationId = this.authenticationTokenService.clientId();

        // remove any clients who are also contractors and combine the lists.
        this.entities = this.entities.filter(s => s.id !== myOrganisationId);

        this.organisations = [
            ...this.clients.filter(c => this.entities.filter(s => s.id === c.id).length === 0),
            ...this.entities
        ].sort((o1, o2) => o1.title.localeCompare(o2.title));

        this.setupInitialData();
    }
}
