import { IdDocumentType } from 'app/shared/enums/id-document-type.enum';

export class AddIdDocumentRequest {
    setId: string;
    idDocumentType: IdDocumentType;
    base64Image: string;
    ownerId: string;

    constructor(setId: string, idDocumentType: IdDocumentType, base64Image: string, ownerId: string) {
        this.setId = setId;
        this.idDocumentType = idDocumentType;
        this.base64Image = base64Image;
        this.ownerId = ownerId;
    }
}