import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// ngrx | rxjs
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

// store
import * as fromAuth from 'app/authentication-v2/store';
import * as fromConnect from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// services
import { AuthenticationEventTrackingService } from 'app/authentication-v2/services/authentication-event-tracking.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// models
import { User } from 'app/models/user.model';
import { TokenLoginDetails } from 'app/authentication-v2/models/token-login-details.model';

@Component({
    templateUrl: 'login.component.html'
})
export class LoginComponent extends BaseComponent implements OnInit {

    working: boolean;
    authenticationMessage: string;
    hidePassword: boolean = true;
    form: FormGroup;
    loggedIn: boolean = false;
    isIframe: boolean = false;

    constructor(
        private store: Store<fromAuth.AuthenticationState>,
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private alertService: AlertService,
        public authenticationEventTrackingService: AuthenticationEventTrackingService) {
        super();
    }

    ngOnInit(): void {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationWorking))
            .subscribe((working: boolean) => this.working = working);

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationMessage))
            .subscribe((authenticationMessage: string) => {
                this.authenticationMessage = authenticationMessage;
                if (this.isIframe) {
                    this.alertService.error(this.authenticationMessage);
                }
            });

        this.form = this.fb.group({
            email: [null, [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(255)]],
            password: [null, [Validators.required, Validators.minLength(8)]],
            rememberMe: [false]
        });

        this.checkRequestParameters();

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getUser))
            .subscribe((user: User) => {
                this.loggedIn = user !== null;
            });
    }

    loginUser() {
        this.authenticationEventTrackingService.loginClicked();
        const request = this.form.value;
        this.store.dispatch(fromAuth.Login({request}));
    }

    checkRequestParameters() {
        const params = this.route.snapshot.queryParams;
        if (!params.token) {
            return;
        }
        const tokenLogin = new TokenLoginDetails();
        tokenLogin.token = params.token;
        if (params.client) {
            tokenLogin.clientId = params.client;
        }
        if (params.process) {
            tokenLogin.processId = params.process;
            if (params.pdfExportId) {
                tokenLogin.pdfExportId = params.pdfExportId;
            }
        }
        if (params.hideHeader) {
            tokenLogin.hideHeader = true;
            this.isIframe = true;
        }
        if (params.readOnly) {
            tokenLogin.readOnlyAccess = params.readOnly;
        } else {
            tokenLogin.readOnlyAccess = false;
        }
        if (params.userGroup) {
            tokenLogin.userGroupId = params.userGroup;
        }
        this.store.dispatch(fromAuth.TokenLogin({request: tokenLogin}));
    }
}