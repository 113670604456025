<div class="address-input" fxLayout="column">
    <div *ngIf="!showAddress" [formGroup]="postcodeForm">
        <mat-form-field appearance="outline">
            <input matInput class="postcode" autocomplete="off" formControlName="postcode" placeholder="Search using a postcode"/>
            <mat-error *ngIf="postcodeForm.get('postcode').hasError('pattern')">Postcode does not match required UK format</mat-error>
            <mat-error *ngIf="postcodeForm.get('postcode').hasError('required')">Postcode is required</mat-error>
        </mat-form-field>
    </div>
    <mat-error *ngIf="lookupError.length > 0">
        {{lookupError}}
    </mat-error>
    <mat-form-field appearance="outline" *ngIf="hasAddresses">
        <mat-select #address placeholder="Addresses" (selectionChange)="updateAddress(address.value)" >
            <mat-option *ngFor="let address of addresses" [value]="address">{{formatAddress(address)}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="showAddress" name="addressForm" [formGroup]="addressForm" novalidate fxLayout="column">
        <div>
            <div class="font-question-label">Postcode *</div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Postcode *" formControlName="postcode">
            </mat-form-field>
        </div>
        <div>
            <div class="font-question-label">First Line *</div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="First line of the address *" formControlName="addressLine1">
            </mat-form-field>
        </div>
        <div>
            <div class="font-question-label">Second Line</div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Second line of the address" formControlName="addressLine2">
            </mat-form-field>
        </div>
        <div>
            <div class="font-question-label">Town *</div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Town *" formControlName="town">
            </mat-form-field>
        </div>
        <div>
            <div class="font-question-label">County</div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="County" formControlName="county">
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="!disabled && !showAddress" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="15px">
        <button  mat-raised-button [disabled]="postcodeForm.invalid" (click)="onSearchClicked()">Search</button>
        <button  mat-raised-button (click)="toggleDataEntry(true);">Enter Manually</button>
    </div>
    <div *ngIf="!disabled && showAddress" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="15px">
        <button  mat-raised-button (click)="toggleDataEntry(false)">Search Again</button>
        <button  mat-raised-button (click)="clearAddress()">Clear Address</button>
    </div> 
</div>