import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// Models
import { GroupSummary } from 'app/shared/models/groups/group-summary.model';
import { ManageAssessmentSetInfo } from 'app/shared/modules/group/models/manage-assessment-set-info.model';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Services
import { ManageAssessmentSetsDialogComponent } from 'app/shared/modules/group/dialogs/manage-assessment-sets-dialog/manage-assessment-sets-dialog.component';
import { ManageAssessmentSetDialogType } from 'app/shared/modules/group/enums/manage-assessment-set-dialog-type.enum';

@Injectable()
export class GroupDialogService extends BaseComponent {

    projectNodes: GroupSummary[];

    constructor(private dialogs: MatDialog) {
        super();
    }

    showManageAssessmentSetsDialog(
        type: ManageAssessmentSetDialogType,
        groupId: string,
        entityId: string): MatDialogRef<ManageAssessmentSetsDialogComponent> {

        return this.dialogs.open(ManageAssessmentSetsDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data: new ManageAssessmentSetInfo(type, groupId, entityId)
        });
    }
}