// ngrx
import { createFeatureSelector, createSelector } from '@ngrx/store';

// store
import * as reducers from 'app/connect/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<reducers.ConnectStoreState>(StoreNames.connectCommon);
const reducerState = createSelector(storeState, (state: reducers.ConnectStoreState) => state?.branding);

export const getLogoUrl = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'logoUrl'));
export const getBackgroundUrl = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'backgroundUrl'));
export const getCredasLogoColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'credasLogoColor'));
export const getTextColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'textColor'));
export const getHeaderColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'headerColor'));
export const getPrimaryColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'primaryColor'));
export const getPrimaryReverseColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'primaryReverseColor'));
export const getSecondaryColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'secondaryColor'));
export const getSecondaryColorDark = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'secondaryColorDark'));
export const getAccentColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'accentColor'));
export const getTitle = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'title'));
export const getTagLine = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'tagLine'));
export const getSupportEmail = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'supportEmail'));
export const getSupportText = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'supportText'));
export const getPrimaryButtonColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'primaryButtonColor'));
export const getPrimaryButtontextColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'primaryButtonTextColor'));
export const getSecondaryButtonColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'secondaryButtonColor'));
export const getSecondaryButtontextColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'secondaryButtonTextColor'));
export const getAuthenticationLogoUrl = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'authenticationLogoUrl'));
export const getFavIcon = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'favIcon'));
export const getTableAndTabColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'tableAndTabColor'));
export const getTableAndTabTextColor = createSelector(reducerState, (state: reducers.BrandingState) => getProperty(state, 'tableAndTabTextColor'));

export const getCredasBranding = createSelector(reducerState, (state: reducers.BrandingState) => state.credasBranding);
export const getConnectBranding = createSelector(reducerState, (state: reducers.BrandingState) => state?.connectBranding);
export const getEntityBranding = createSelector(reducerState, (state: reducers.BrandingState) => state.entityBranding);

export const isConnect = createSelector(reducerState, (state: reducers.BrandingState) => state.isConnect);

function getProperty(state: reducers.BrandingState, property: string): string {
    if (!state.branding || !state.branding[property] || state.branding[property] === '') {
        if (state.isConnect) {
            return state.connectBranding ? state.connectBranding[property] : undefined;
        } else {
            return state.credasBranding ? state.credasBranding[property] : undefined;
        }
    }

    return state.branding[property];
}