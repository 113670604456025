import { DocumentTemplateType } from 'app/portal/modules/document-management/enums/document-template-type.enum';

export class TemplatePreview {
    markup: string;
    documentTemplateType: DocumentTemplateType;
    blobData: Blob;

    constructor(markup: string, documentTemplateType: DocumentTemplateType, blobData: Blob) {
        this.markup = markup;
        this.documentTemplateType = documentTemplateType;
        this.blobData = blobData;
    }
}