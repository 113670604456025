import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/shared/base/base-component';
import { ItemType, isEditable } from 'app/shared/enums/item-type.enum';

@Component({
    selector: 'app-dialog-field',
    templateUrl: './dialog-field.component.html',
    styleUrls: ['./dialog-field.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogFieldComponent extends BaseComponent implements OnInit {

    @Input()
    label: string;

    @Input()
    description: string;

    @Input()
    readonly: boolean = false;

    @Input()
    mandatory: boolean = false;

    @Input()
    showLabel: boolean = true;

    @Input()
    selected: boolean = false;

    @Input()
    type: ItemType = ItemType.None;

    @Input()
    visible: boolean = true;

    @Output()
    fieldActive: EventEmitter<string> = new EventEmitter();

    constructor() {
        super();
    }

    ngOnInit(): void {

    }

    withHover(): boolean {
        return isEditable(this.type);
    }

    handleMouseOver(): void {
        this.fieldActive.emit(this.description);
    }
}