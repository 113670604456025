<div class="id-document-results">
    <div *ngFor="let idDocument of idDocuments | orderBy: 'dateCreated'" fxLayout="row" fxLayoutAlign="start top" fxLayoutGap="32px">
        <div fxFlex="50%">
            <app-image *ngIf="!urlImages" 
                [src]="getBlobIdDocumentSource(idDocument)" 
                (imageClick)="onSecureImageClicked($event, idDocument)">
            </app-image>
            <div *ngIf="urlImages">
                <img *ngIf="imageLoaded || !(urlsLoading$ | async)" class="id-doc-image" 
                    [src]="getUrlIdDocumentSource(idDocument) | safe: 'resourceUrl'" 
                    (click)="onImageClicked(idDocument)" (load)="onImageLoaded()"/>
                <app-loading-card *ngIf="!imageLoaded || (urlsLoading$ | async)" [useSpinner]="true"></app-loading-card>
            </div>
        </div>
        <div class="result-section" fxLayout="column" fxLayoutGap="16px" fxFlex >
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start top" class="property">
                <div fxFlex="200px"><strong>Date of scan</strong></div>
                <div fxFlex>{{idDocument.dateCreated | ukdate: 'time'}}</div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start top" class="property">
                <div fxFlex="200px"><strong>Type</strong></div>
                <div fxFlex>{{getIdDocumentTypeDescription(idDocument)}}</div>
            </div>
            
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start top" class="property">
                <div fxFlex="200px"><strong>Visual Analysis Result</strong></div>
                <div fxFlex [ngClass]="getAnalysisResultClass(idDocument.visualAnalysisResult)">{{VisualAnalysisResult[idDocument.visualAnalysisResult]}}</div>
            </div>
            
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start top" class="property">
                <div fxFlex="200px"><strong>Facial Match Result</strong></div>
                <div fxFlex [ngClass]="{ 'pass': idDocument.facialMatch, 'fail': !idDocument.facialMatch }">{{idDocument.facialMatch ? 'Pass': 'Fail'}}</div>
            </div>

            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start top" class="property">
                <div fxFlex="200px"><strong>Name Check Result</strong></div>
                <div fxFlex [ngClass]="{ 'pass': idDocument.nameCheck, 'fail': !idDocument.nameCheck }">{{getNameCheckStatusTitle(idDocument)}}</div>
            </div>
            <div class="overall-status padding-8 round-corners" [ngClass]="getCheckResultClass(idDocument.documentStatus)" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
                <mat-icon *ngIf="idDocument.documentStatus === CheckResult.Pass">check_circle_outline</mat-icon>
                <mat-icon *ngIf="idDocument.documentStatus === CheckResult.Fail">highlight_off</mat-icon>
                <mat-icon *ngIf="idDocument.documentStatus === CheckResult.Refer || idDocument.documentStatus === CheckResult.Unknown">schedule</mat-icon>
                <h3>{{getCheckResultDescription(idDocument.documentStatus)}}</h3>
            </div>
        </div>
    </div>
</div>