import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// Models
import { ImagePopupData } from 'app/shared/models/image-popup-data.model';

@Component({
    templateUrl: './image-popup.component.html',
    styleUrls: ['./image-popup.component.scss']
})
export class ImagePopupComponent {
    imageUrl: string;
    title: string;
    subTitle: string;
    requiresAuthorisation = false;

    constructor(@Inject(MAT_DIALOG_DATA) data: ImagePopupData) {
        this.imageUrl = data.imageUrl;
        this.title = data.title;
        this.subTitle = data.subTitle;
        this.requiresAuthorisation = data.requiresAuthorisation;
    }
}